import { Injectable, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastMessage } from '../../shared/types/toast-message';
import { CoreModule } from '../core.module';

@Injectable()
export class ToastService {
    public toastMessage: ToastMessage | null;

    @Output()
    public gotoRequested: EventEmitter<void> = new EventEmitter<void>();

    public show(message: string, buttonText: string): void {
        this.toastMessage = null;

        const msg = new ToastMessage();
        msg.message = message;
        msg.gotoButtonText = buttonText;
        this.toastMessage = msg;

        setTimeout(() => {
            this.toastMessage = null;
        }, 3600); // 3 seconds, plus 600ms for the open animation to complete.
    }

    public requestGoto(): void {
        this.gotoRequested.emit();
    }

    public clear() {
        this.toastMessage = null;
    }



}
