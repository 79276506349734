import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { OutcomeDetailsModel } from '../../../../../hub_schema/hubTypes';
import { HttpService } from '../../../../core/services/http.service';

@Injectable({
    providedIn: 'root'
})
export class OutcomeDetailService {

    constructor(private httpService: HttpService) { }

    public getOutcomeDetails(projectOutcomeId: number, isStrategy: boolean): Observable<OutcomeDetailsModel> {
        const url = environment.endpoints.base + `/projectOutcomes/outcomeDetails/${projectOutcomeId}/${isStrategy}`;
        return this.httpService.get<OutcomeDetailsModel>(url);
    }


}
