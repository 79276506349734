<div class="create-record-dialog-container" *ngIf="createProjectForm">

    <div class="header">
        <h5 class="modal-title text-success">Create a new Conservation Record</h5>
    </div>

    <app-hub-stepper [formGroup]="createProjectForm">
        <cdk-step label="Conservation Record Type" [completed]="isRecordTypeTabCompleted()">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <p class="text-center font-weight-bold sb-b p-1">
                            Select the type of Conservation Record you want to create
                        </p>
                        <div class="d-flex form-group">
                            <div>
                                <span class="select-label text-info-800">Conservation Record Type</span>
                                <div class="mt-2">
                                    <mat-radio-group formControlName="projectTypeId" class="project-types-radio-list">
                                        <mat-radio-button color="primary" class="project-type-radio" 
                                            *ngFor="let recordType of authorizedRecordTypes" [value]="recordType.projectTypeId">
                                                {{recordType.name}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="action-buttons">
                <button disabled class="hub-icon-button">
                    <b><i class="icon-arrow-left13 mr-2"></i></b>
                    <span>Previous</span>
                </button>
                <button [disabled]="!isRecordTypeTabCompleted()" class="hub-icon-button mirrored" matStepperNext>
                    <span>Next</span>
                    <b><i class="icon-arrow-right14 ml-2"></i></b>
                </button>
            </div>

        </cdk-step>

        <cdk-step label="Conservation Record Name">

            <div class="unique-admonition">
                Record Name must be unique.
                If your record name appears in the list below, it may already exist.
                Please select it to view and edit the existing record
            </div>

            <div class="container">
                <div class="row">

                    <div class="col-md-12 pt-2">
                        <div class="d-flex justify-content-center form-group">
                            
                            <div class="col-md-8">
                                <span class="select-label text-dark">Conservation Record Name</span>  

                                <app-project-name-suggestions #projectNameSuggestions [formGroup]="createProjectForm"></app-project-name-suggestions>
                            </div>
                        </div>
                    </div>
            

                </div>
            </div>

            <div class="action-buttons">
                <button class="hub-icon-button" matStepperPrevious>
                    <b><i class="icon-arrow-left13 mr-2"></i></b>
                    <span>Previous</span>
                </button>
                <button [disabled]="!projectNameSuggestions.isValid" class="hub-icon-button mirrored" matStepperNext>
                    <span>Next</span>
                    <b><i class="icon-arrow-right14 ml-2"></i></b>
                </button>
            </div>

        </cdk-step>

        <cdk-step label="Additional Data">

            <div class="row">
                <div class="col-md-6 summary-date">
                    <span class="select-label text-info-700 pb-2 d-inline-block">Start Date</span>
                    <app-help-selector [selector]="1005"></app-help-selector>

                    <mat-form-field style="margin-left: 10px">
                        <input matInput readonly (click)="startDateCalendar.open()" [matDatepicker]="startDateCalendar" formControlName="startDate" placeholder="yyyy-mm-dd">
                        <mat-datepicker-toggle matIconSuffix [for]="startDateCalendar"></mat-datepicker-toggle>
                        <mat-datepicker #startDateCalendar></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-6 summary-date">
                    <span class="select-label text-info-700">Status</span>
                    <app-help-selector [selector]="1001"></app-help-selector>
                    <div class="pt-3">Proposed</div>
                    <input type="hidden" formControlName="statusId">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <span class="select-label text-info-700 pb-2 mt-2 d-inline-block">
                        Lead Business Unit
                    </span>
                    <app-help-selector [selector]="1026"></app-help-selector>
                    <div class="input-group">

                        <div class="hub-select-container">
                            <mat-form-field>
                                <mat-select disableOptionCentering [formControl]="businessUnitIdControl">
                                    <mat-option *ngFor="let bu of businessUnits"
                                        [value]="bu.businessUnitId">
                                        {{bu.name}}
                                    </mat-option>
                                </mat-select>

                            </mat-form-field>
                            <div class="mt-1 text-muted font-size-sm">
                                Enter one Lead Business Unit here. Other participating business units may be
                                added later.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <span class="select-label text-info-700 pb-2 mt-2 d-inline-block">Brief Description</span>
                    <app-help-selector [selector]="1003"></app-help-selector>
                    
                    <textarea formControlName="briefDescription" rows="2" cols="3"
                        class="form-control mb-2 record-description"
                        placeholder="Brief Description"></textarea>
                </div>
            </div>

            <div class="action-buttons">
                <button class="hub-icon-button" matStepperPrevious>
                    <b><i class="icon-arrow-left13 mr-2"></i></b>
                    <span>Previous</span>
                </button>

                <button type="button" class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
                    [disabled]="createProjectForm.invalid || !projectNameSuggestions.isValid || isSaving" (click)="createHubRecord()">
                    <b>
                        <i class="icon-floppy-disk"></i>
                        <i class="icon-spinner4"></i>
                    </b>
                    Create Record
                </button>
            </div>

        </cdk-step>
    </app-hub-stepper>

    <div class="dialog-footer">
        <button type="button" class="cancel-button" (click)="cancelDialog()">Cancel</button>
    </div>

</div>
