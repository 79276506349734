import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material-module';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProjectNotFoundComponent } from './components/project-not-found/project-not-found.component';
import { DeleteConfirmationDialogComponent } from './components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { InnerHtmlPipe } from './pipes/inner-html.pipe';
import { KeepHtmlPipe } from './pipes/keep-html.pipe';
import { ProjectCardGraphQlComponent } from './components/project-card/project-card-graphql/project-card-graphql.component';
import { NumberWithoutCommasPipe } from './pipes/number-without-commas.pipe';
import { ModalSpinnerComponent } from './components/modal-spinner/modal-spinner.component';
import { HelpSelectorComponent } from './components/help-selector/help-selector.component';
import { MessagedModalSpinnerComponent } from './components/messaged-modal-spinner/messaged-modal-spinner.component';
import { ProjectCardAlgoliaComponent } from './components/project-card/project-card-algolia/project-card-algolia.component';
import { AddProjectToCollectionButtonComponent } from './components/record-collections/add-project-to-collection/add-project-to-collection-button/add-project-to-collection-button.component';
import { AddProjectToCollectionDialogComponent } from './components/record-collections/add-project-to-collection/add-project-to-collection-dialog/add-project-to-collection-dialog.component';
import { AddEditCollectionDialogComponent } from './components/record-collections/add-edit-collection-dialog/add-edit-collection-dialog.component';
import { MapContainerComponent } from './components/map-container/map-container.component';
import { HubMessageBoxComponent } from './components/hub-message-box/hub-message-box.component';
import { HubStepperComponent } from './components/hub-stepper/hub-stepper.component';
import { HelpSelectorDialogComponent } from './components/help-selector/help-selector-dialog/help-selector-dialog.component';
import { HubPaginatorComponent } from './components/hub-paginator/hub-paginator.component';
import { ProjectNameSuggestionsComponent } from './components/project-name-suggestions/project-name-suggestions.component';
import { ProjectNameLookupService } from './components/project-name-suggestions/services/project-name-lookup.service';

@NgModule({
  declarations: [
    NotFoundComponent,
    ProjectNotFoundComponent,
    DeleteConfirmationDialogComponent,
    InnerHtmlPipe,
    KeepHtmlPipe,
    ProjectCardGraphQlComponent,
    ProjectCardAlgoliaComponent,
    NumberWithoutCommasPipe,
    ModalSpinnerComponent,
    HelpSelectorComponent,
    HelpSelectorDialogComponent,
    MessagedModalSpinnerComponent,
    AddProjectToCollectionButtonComponent,
    AddProjectToCollectionDialogComponent,
    AddEditCollectionDialogComponent,
    MapContainerComponent,
    HubMessageBoxComponent, 
    HubStepperComponent,
    HubPaginatorComponent,
    ProjectNameSuggestionsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    NgbModule,
    CKEditorModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    NgbModule,
    CKEditorModule,
    InnerHtmlPipe,
    KeepHtmlPipe,
    ProjectCardGraphQlComponent,
    ProjectCardAlgoliaComponent,
    NumberWithoutCommasPipe,
    ModalSpinnerComponent,
    HelpSelectorComponent,
    NotFoundComponent,
    MessagedModalSpinnerComponent,
    AddProjectToCollectionButtonComponent,
    MapContainerComponent,
    HubMessageBoxComponent,
    HubStepperComponent,
    HubPaginatorComponent,
    ProjectNameSuggestionsComponent
  ],
  providers: [ProjectNameLookupService]
})
export class SharedModule { }
