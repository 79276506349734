<div class="step-container">
    <header class="step-header wizard wizard-form steps-basic">

        <div class="steps clearfix">
            <ul role="tablist">

                <li *ngFor="let step of steps; let i = index" role="tab" 
                    [ngClass]="{
                        'first': i === 0,
                        'current': i === selectedIndex,
                        'done': step.completed,
                    }" >
                    <a *ngIf="!step.completed" aria-controls="steps-uid-2-p-0"><span class="number">{{i + 1}}</span>
                        <span [innerHtml]="step.label">
                        </span>
                    </a>
                    
                    <a *ngIf="step.completed" aria-controls="steps-uid-2-p-0"><span (click)="selectedIndex = i" style="cursor: pointer;" class="number">{{i + 1}}</span>
                        <span [innerHtml]="step.label">
                        </span>
                    </a>
                </li>
            </ul>
        </div>

  </header>

  <div class="step-body-container" [ngTemplateOutlet]="selected ? selected.content : null"></div>
</div>
