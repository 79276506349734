import { ProjectStatuses } from 'src/app/shared/types/project-statuses';

export const getClassFromStatus = (status: ProjectStatuses) => {
    switch (status) {
        case ProjectStatuses.Active:
            return 'text-indigo';
        case ProjectStatuses.Approved:
            return 'text-primary';
        case ProjectStatuses.Completed:
            return 'text-success';
        case ProjectStatuses.Canceled:
            return 'text-danger';
        case ProjectStatuses.Proposed:
            return 'text-grey-600';
    }
}
