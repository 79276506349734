import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RecordCollectionModel, RecordCollectionsSearchModel, RecordCollectionsSearchResultModel } from '../../../../hub_schema/hubTypes';
import { RecordCollectionService } from '../../../core/services/record-collection.service';
import { Subscription, debounceTime, distinctUntilChanged, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '../../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { Location } from '@angular/common';
import { AddEditCollectionDialogComponent } from '../../../shared/components/record-collections/add-edit-collection-dialog/add-edit-collection-dialog.component';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit, OnDestroy {
    private recordCollectionService: RecordCollectionService = inject(RecordCollectionService);
    private dialogService: MatDialog = inject(MatDialog);
    private location: Location = inject(Location);
    private titleService: Title = inject(Title);

    public recordCollectionsSearchResults: RecordCollectionsSearchResultModel;
    public collectionsFilterForm: FormGroup;

    public get filtersArray(): FormArray {
        return this.collectionsFilterForm?.controls.collectionTypeFilters as FormArray;
    }

    private filterFormChangedSubscription: Subscription;

    public ngOnInit(): void {

        const title = 'Hub - Collections - All';
        this.titleService.setTitle(title);
 
        this.collectionsFilterForm = new FormGroup({
            searchText: new FormControl(''),
            isPrivate: new FormControl(false),
            collectionTypeFilters: new FormArray([]),
            page: new FormControl(0)
        });

        this.populateCollections();

        this.filterFormChangedSubscription = this.collectionsFilterForm.valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged()
            )
            .subscribe(() => {
                this.populateCollections();
            });
    }


    private populateCollections(newPageIndex: number = 0): void {
        const search = this.collectionsFilterForm.value;
        search.page = newPageIndex;

        this.recordCollectionService.searchRecordCollections(search).pipe(take(1)).subscribe((results: RecordCollectionsSearchResultModel) => {
            this.recordCollectionsSearchResults = results;

            // populate collection type filters
            this.filtersArray.clear({emitEvent: false});

            for (let filter of results.availableFilters) {
                const filterGroup = new FormGroup({
                    recordCollectionTypeId: new FormControl(filter.recordCollectionTypeId),
                    isSelected: new FormControl(filter.isSelected)
                });
                this.filtersArray.push(filterGroup, {emitEvent: false});
            }            
        });
    }

    public getCollectionTypeName(filterGroup: FormGroup): string {
        const recordCollectionTypeId = filterGroup.value.recordCollectionTypeId;
        const filter = this.recordCollectionsSearchResults.availableFilters.find(f => f.recordCollectionTypeId === recordCollectionTypeId);
        return filter?.collectionTypeName!;
    }

    public getNumberOfResults(filterGroup: FormGroup): number {
        const recordCollectionTypeId = filterGroup.value.recordCollectionTypeId;
        const filter = this.recordCollectionsSearchResults.availableFilters.find(f => f.recordCollectionTypeId === recordCollectionTypeId);
        return filter?.numberOfResults!;
    }

    public pageRequested(page: number): void {
        this.collectionsFilterForm.controls.page.setValue(page, {emitEvent: false});
        this.populateCollections(page);
    }

    public openProjectCollectionCreateDialog(): void {
        const dialogRef = this.dialogService.open(AddEditCollectionDialogComponent, {
            width: '800px',
            data: {
                existingCollection: null
            }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((newCollection: RecordCollectionModel) => {
            if (newCollection) {
                this.populateCollections();
            }            
        });
    }

    public deleteRecordCollectionRequested(recordCollection: RecordCollectionModel): void {
        const dialogRef = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: 'You will not be able to recover this collection.'
            }
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed
            .pipe(take(1)).subscribe(() => {
                deleteConfirmationDialog.isBusy = true;

                this.recordCollectionService.deleteRecordCollection(recordCollection).pipe(take(1)).subscribe(() => {
                    this.populateCollections();
                    dialogRef.close();
                });
            });

    }

    public ngOnDestroy(): void {
        if (this.filterFormChangedSubscription) {
            this.filterFormChangedSubscription.unsubscribe();
        }
    }
}
