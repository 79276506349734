import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

enum titles {
  notFound = 'Hub - Project Not Found',
  deleted = 'Hub - Project Deleted',
}

@Component({
  selector: 'app-project-not-found',
  templateUrl: './project-not-found.component.html',
  styleUrls: ['./project-not-found.component.scss'],
})
export class ProjectNotFoundComponent implements OnInit {

  public isDeleted = false;

  constructor(private titleService: Title, private route: ActivatedRoute) { }

  ngOnInit() {
    this.isDeleted = this.route.snapshot.url[0].path === 'project-deleted';
    this.titleService.setTitle(this.isDeleted ? titles.deleted : titles.notFound);
  }
}
