<div aria-hidden="false" tabindex="0" class="app-attributes" *ngIf="projectEditForm">
    <div class="p-3">
        <h5 class="edit-section-header">Additional Information</h5>
        <div class="row" >
            <div class="col-md-12">
                <div class="form-group">

                    <ul class="nav nav-tabs nav-tabs-bottom mb-1">
                        <li class="nav-item">
                            <a href="#" (click)="setTabTo(tabs.donorkeywords, $event)"
                                [ngClass]="{active: activeTab === tabs.donorkeywords}"
                                class="nav-link">Donor Keywords</a>
                        </li>
                    </ul>
                    
                    <div class="tab-content">

                        <!-- DONOR KEYWORDS -->
                        <app-donor-keywords-edit *ngIf="activeTab === tabs.donorkeywords"></app-donor-keywords-edit>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
