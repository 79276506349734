import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-anchor-record-confirmation-dialog',
  templateUrl: './anchor-record-confirmation-dialog.component.html',
  styleUrls: ['./anchor-record-confirmation-dialog.component.scss']
})
export class AnchorRecordConfirmationDialogComponent implements OnInit {
    private dialogRef: MatDialogRef<AnchorRecordConfirmationDialogComponent> = inject(MatDialogRef<AnchorRecordConfirmationDialogComponent>);
    private data: any = inject(MAT_DIALOG_DATA);

    public isFinanceContactMismatched: boolean;
    public isBusinessUnitMismatched: boolean;

    public ngOnInit(): void {
        this.isFinanceContactMismatched = this.data.isFinanceContactMismatched;
        this.isBusinessUnitMismatched = this.data.isBusinessUnitMismatched;
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public confirm(): void {
        this.dialogRef.close(true);
    }
}
