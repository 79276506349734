import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { _ } from 'ajv';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../../core/services/auth/auth.service';
import { TypeAheadService } from '../../core/services/type-ahead.service';
import { ProjectModel } from '../../../hub_schema/hubTypes';
import { BulkEditService } from 'src/app/core/services/bulk-edit.service';

enum step {
    records = 1,
    action,
    confirm
  }

@Component({
  selector: 'app-bulk-edit-wizard',
  templateUrl: './bulk-edit-wizard.component.html',
  styleUrls: ['./bulk-edit-wizard.component.scss']
})
export class BulkEditWizardComponent implements OnInit {
    private bulkEditService:BulkEditService = inject(BulkEditService);
    private dialogRef: MatDialogRef<BulkEditWizardComponent> = inject(MatDialogRef<BulkEditWizardComponent>);
    private typeAheadService: TypeAheadService = inject(TypeAheadService);
    private authService: AuthService = inject(AuthService);

  public currentStep = step.records;
  public step = step;
  public bulkEditWizard: FormGroup;
  public hubRecordsQuery: ProjectModel[] = [];
  public filteredRecords: Observable<ProjectModel[]>;
  public editorPermissions: string = '';

  ngOnInit() {
      this.bulkEditWizard = new FormGroup({
          hubRecordTypeAheadInput: new FormControl(''),
          selectedHubRecords: new FormControl([], Validators.required),
          hubRecordStatus: new FormControl(null, Validators.required)
      })
      this.editorPermissions = this.authService.getEditorPermissions();


this.filteredRecords = this.bulkEditWizard.controls.hubRecordTypeAheadInput.valueChanges
.pipe(
    startWith(''),
    distinctUntilChanged(),
    switchMap((term) => this.typeAheadService.getBulkEditProjectsData(term, false, this.editorPermissions)
        .pipe(
            catchError(() => of([])), // empty list on error
            map((result) => {
                let temp = this.bulkEditWizard.controls.selectedHubRecords.value
                if (temp.length > 0) {
                    result = result.filter(record => {
                        let val = !!this.bulkEditWizard.controls.selectedHubRecords.value.some(
                            (result) => {
                                return result.projectId === record.projectId
                            });
                        return !val
                    });
                    result.push(...temp)
                }
                return result;
            }),
        )
    ),
);
}
public status = [{name: "Cancel", statusId: 1005}, {name: "Delete", statusId: -1}];

public cancelDialog(): void {
    this.currentStep = 1;

    this.dialogRef.close();
}

 public nextStep() {
    this.currentStep++;
  }

  public prevStep($event) {
    $event.preventDefault();
    this.currentStep--;
  }

  public stepTo(stepNum: step, $event) {
    $event.preventDefault();
    if (!this.currentStepIsValid()) {
      return;
    }
    this.currentStep = stepNum;
  }

  public currentStepIsValid(id: number = this.currentStep) {
    let isValid = false;
    switch (id) {
      case this.step.records:
        isValid =  this.bulkEditWizard.controls.selectedHubRecords.valid;
        break;

      case this.step.action:
        isValid = this.bulkEditWizard.controls.hubRecordStatus.valid;
        break;

      case this.step.confirm:
        isValid = this.bulkEditWizard.controls.selectedHubRecords.valid && this.bulkEditWizard.controls.hubRecordStatus.valid;
        break;
    }

    return isValid;
  }


  public addRecord(e: MatAutocompleteSelectedEvent, record: HTMLOrSVGElement) {
    let tempRecord = this.bulkEditWizard.controls.selectedHubRecords.value;
    tempRecord.push(e.option.value);
    this.bulkEditWizard.controls.selectedHubRecords.setValue(tempRecord);
    this.bulkEditWizard.controls.selectedHubRecords.markAsDirty();
 }

 public unselectRecord(record: ProjectModel){
    let tempRecord = this.bulkEditWizard.controls.selectedHubRecords.value;
    let indexToRemove = (tempRecord as Array<any>).findIndex(controlRecord => record.projectId === controlRecord.projectId);
    if(indexToRemove !== -1) {
        (tempRecord as Array<any>).splice(indexToRemove, 1);
        this.bulkEditWizard.controls.selectedHubRecords.setValue(tempRecord);
        this.bulkEditWizard.controls.selectedHubRecords.markAsDirty();
    }
 }

public cancel() {
    this.bulkEditWizard.controls.hubRecordTypeAheadInput.setValue('');
    this.bulkEditWizard.controls.selectedHubRecords.setValue([]);
    this.bulkEditWizard.controls.hubRecordStatus.setValue(null);

    this.bulkEditWizard.controls.hubRecordTypeAheadInput.markAsUntouched();
    this.bulkEditWizard.controls.selectedHubRecords.markAsUntouched();
    this.bulkEditWizard.controls.hubRecordStatus.markAsUntouched();
}

public saveEdit(){
    const selectedProjectsFC = this.bulkEditWizard.get("selectedHubRecords");
    const hubRecordStatusFC = this.bulkEditWizard.get("hubRecordStatus");
    this.bulkEditService.addUpdateProjectStatusToBulkEditQueue(
        {
            projectIds: `${selectedProjectsFC.value.map((project)=> project.projectId).join(',')}`,
            statusId: hubRecordStatusFC.value,

        }).pipe(
            tap(
                (result) => {
                    if (result) {
                        this.cancel();
                        this.currentStep = step.records;
                    } else {
                        console.error('');
                    }
                }),
                (take(1))
        ).subscribe();
}

}
