import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpService } from '../../core/services/http.service';
import { Goal2030Model } from '../../../hub_schema/hubTypes';

@Injectable()
export class GoalsService {

    constructor(private httpService: HttpService) { }

    private goals: Goal2030Model[];

    public get2030Goals(): Observable<Goal2030Model[]> {
        if (this.goals) {
            return of(this.goals);
        }

        const url = environment.endpoints.base + '/goal2030/getGoals';

        return this.httpService.get<any[]>(url).pipe(
            tap((results: any[]) => {
                this.goals = results.sort((a, b) => {
                    if (a.sortOrder < b.sortOrder) {
                        return -1;
                    }
                    if (a.sortOrder > b.sortOrder) {
                        return 1;
                    }
                    return 0;
                });
            })
        );
    }

    public getGoalDetails(themeName: string): Observable<any> {

        const url = environment.endpoints.base + '/goal2030/getGoalsDetails/' + themeName;

        return this.httpService.get<any>(url).pipe(
            map(result => {
                return {
                    goal: result.goal,
                    theme: result.theme,
                    subThemes: result.subThemes
                        .map(subTheme => {
                            return {
                                name: subTheme.subTheme,
                                sortOrder: subTheme.sortOrder,
                                metrics: subTheme.metrics.map(metric => {
                                    return {
                                        isExpanded: subTheme.metrics.length === 1,
                                        metric: metric
                                    };
                                })
                            };
                        })
                        .sort((a, b) => {
                            return a.sortOrder - b.sortOrder;
                        })
                };
            })
        );
    }
}
