import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { AuthorizationFailedComponent } from './authorization-failed/authorization-failed.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

const routes: Routes = [
  { path: 'auth-callback', component: AuthCallbackComponent, pathMatch: 'full' },
  { path: 'authorization-failed', component: AuthorizationFailedComponent, pathMatch: 'full' },
  { path: 'logged-out', component: LoggedOutComponent, pathMatch: 'full' },
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule
  ],  
  declarations: [
    AuthCallbackComponent,
    AuthorizationFailedComponent,
    LoggedOutComponent
  ]
})
export class AuthModule { }
