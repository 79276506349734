<div class="hub-edit-outcomes-container" *ngIf="project && projectEditForm">
    <div class="outcomes-list-panel hub-custom-scrollbar">

        <div class="outcomes-list-header-panel">
            <h5 class="edit-section-header">Outcomes
                ({{project.projectOutcomes.length}})<app-help-selector [selector]="1187">
                </app-help-selector>
            </h5>
            <button type="button" class="btn-outline bg-primary-300 text-primary btn-icon btn rounded-round ml-2"
                (click)="openCreateOutcomeDialog()" title="" tabindex="0" *ngIf="!isWholeSystem">
                <i class="icon-plus-circle2 mr-2"></i> Create Outcome
            </button>
        </div>

        <div *ngIf="!project.projectOutcomes?.length" class="p-2 pl-3">
            <div class="d-block alert alert-info alert-styled-left shadow-0">
                There are no outcomes defined yet.
            </div>
        </div>

        <div class="outcomes-list section-list" id="lstResults">

            <h6 *ngIf="outcomes.length" class="organizational-metrics-heading">
                2030 Goals Metrics
                <app-help-selector [selector]="1186"></app-help-selector>
            </h6>

            <div cdkDropList (cdkDropListDropped)="outcomeReordered($event)">
                <app-outcome-display *ngFor="let outcome of outcomes" [projectOutcome]="outcome"
                    [hubRecord]="project" outcomeType="outcome" (click)="selectProjectOutcome(outcome)"
                    (deleteOutcomeRequested)="deleteProjectOutcomeRequested(outcome)" cdkDrag
                    class="org-metric-sca-outcome-list" (editOutcomeRequested)="openEditOutcomeDialog($event)"
                    [isSelected]="outcome === selectedProjectOutcome">
                </app-outcome-display>
            </div>


            <h6 *ngIf="customOutcomes.length" class="non-sca-metrics-heading">
                Custom Metrics
                <app-help-selector [selector]="1189"></app-help-selector>
            </h6>

            <div cdkDropList (cdkDropListDropped)="customOutcomeReordered($event)">
                <app-custom-outcome-display *ngFor="let customOutcome of customOutcomes" [projectOutcome]="customOutcome"
                    (click)="selectProjectOutcome(customOutcome)"
                    (deleteOutcomeRequested)="deleteProjectOutcomeRequested(customOutcome)" cdkDrag
                    class="non-sca-outcome-list" (editOutcomeRequested)="openEditOutcomeDialog($event)"
                    [isSelected]="customOutcome === selectedProjectOutcome">
                </app-custom-outcome-display>
            </div>

        </div>
    </div>

    <div class="outcome-detail-container project-edit-items-details" *ngIf="selectedProjectOutcome">
        <app-outcome-details [projectOutcome]="selectedProjectOutcome"></app-outcome-details>
    </div>

</div>
