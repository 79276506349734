<div class="ncs-intervention-panel" [formGroup]="outcomeForm">

    <div class="select-label text-info-800 d-block">
        NCS Pathway <app-help-selector class="app-help-selector-raised"
            selector="1208"></app-help-selector>
    </div>

    <div class="hub-select-container">
        <mat-form-field>
            <mat-select [(ngModel)]="outcomeState.selectedNcsPathwayId" [ngModelOptions]="{standalone: true}"
                (selectionChange)="pathwaySelectionChanged($event)"
                placeholder="Select an NCS Pathway" disableOptionCentering>

                <div class="organizational-metrics-info"></div>

                <mat-option *ngFor="let pathway of ncsPathways" [value]="pathway.pathwayId">
                    <span>{{pathway.name}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="select-label text-info-800 d-block" style="margin-top: 20px">
        Intervention <app-help-selector class="app-help-selector-raised"
            selector="1209"></app-help-selector>
    </div>

    <div class="hub-select-container">
        <mat-form-field>
            <mat-select formControlName="ncsInterventionId"
                [placeholder]="interventionPlaceholderText"
                disableOptionCentering>

                <div class="organizational-metrics-info"></div>

                <mat-option
                    *ngFor="let intervention of outcomeState.selectedNcsPathway?.interventions"
                    [value]="intervention.interventionId">
                    <span>{{intervention.name}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="hub-validation-error-message">
        {{getNcsInterventionErrorMessage()}}
    </div>
</div>
