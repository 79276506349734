<div class="view-section" id="partners" *ngIf="project">
    <h3 class="view-section-header">
        Partners <span class="text-muted">({{project.projectPartners.length}})</span>
    </h3>
    <div class="details">
        <ul class="media-list custom-list p-0 pr-1">
            <li *ngFor="let partner of project.projectPartners; trackBy: trackByFn" class="media">
                <div class="mr-2">
                    <span class="btn btn-success rounded-round btn-icon legitRipple">
                        <i class="icon-collaboration"></i>
                    </span><br>
                </div>
                <div class="media-body">
                    <div>
                        <span class="media-title font-weight-bold font-size-xl">{{partner.name}}</span>
                        <span class="text-muted ml-1">({{partner.partnerCategory.name}})</span>
                        <span>, partnered on {{partner.partnerRole.name}}</span>
                        <span class="partner-poc" *ngIf="partner.isPoc">
                            (Point of Contact)
                        </span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div *ngIf="partner.email" class="media-title mr-3"><b>Contact: </b><a
                                href="mailto:{{partner.email}}">{{partner.contactInfo}}</a></div>
                        <div *ngIf="partner.email === ''" class="media-title mr-3"><b>Contact:
                            </b>{{partner.contactInfo}}</div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="alert alert-info alert-styled-left shadow-0 mr-3" *ngIf="project.projectPartners.length === 0">
            This record has not registered any partnerships yet. Contact the Lead on the record to inquire about
            partnerships.
        </div>
    </div>
</div>
