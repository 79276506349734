import { Component, OnInit, inject } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HubRecordEditorBase } from '../_hub_record_editor_base';
import { IntermediateResultDetailsModel, IntermediateResultProgressModel, IntermediateResultTargetModel, ProjectIntermediateResultModel } from '../../../../hub_schema/hubTypes';
import { IntermediateResultsEditService } from './services/intermediate-results-edit.service';
import { AddEditIntermediateResultDialogComponent } from './add-edit-intermediate-result-dialog/add-edit-intermediate-result-dialog.component';
import { DeleteConfirmationDialogComponent } from '../../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorService } from '../../../core/services/error.service';
import { take } from 'rxjs';
import { CheckableIntermediateResultModel } from './types/checkable-intermediate-result-model';
import { AuthService } from '../../../core/services/auth/auth.service';

enum tabs {
    active = 1,
    archived,
}

@Component({
    selector: 'app-intermediate-results-edit-container',
    templateUrl: './intermediate-results-edit-container.component.html',
    styleUrls: ['./intermediate-results-edit-container.component.scss']
})
export class IntermediateResultsEditContainerComponent extends HubRecordEditorBase implements OnInit {

    // injected dependencies
    private intermediateResultsEditService: IntermediateResultsEditService = inject(IntermediateResultsEditService);
    private errorService: ErrorService = inject(ErrorService);
    private dialogService: MatDialog = inject(MatDialog);
    private authService: AuthService = inject(AuthService);

    public activeIntermediateResults: CheckableIntermediateResultModel[];
    public archivedIntermediateResults: CheckableIntermediateResultModel[];
    public selectedIntermediateResult: ProjectIntermediateResultModel | undefined;

    public ngOnInit(): void {
        this.populateIrLists();
        this.selectTopIntermediateResult();
    }

    private selectTopIntermediateResult(): void {
        if (this.activeTab == tabs.active) {
            if (this.activeIntermediateResults.length > 0) {
                this.selectIntermediateResult(this.activeIntermediateResults[0]);
            }
            else {
                this.selectedIntermediateResult = undefined;
            }            
        }
        else {
            if (this.archivedIntermediateResults.length > 0) {
                this.selectIntermediateResult(this.archivedIntermediateResults[0]);
            }
            else {
                this.selectedIntermediateResult = undefined;
            }            
        }
    }

    private populateIrLists(): void {
        this.activeIntermediateResults =
            (this.project.intermediateResults! as CheckableIntermediateResultModel[])
                .filter(ir => !ir.isArchived);

        this.archivedIntermediateResults =
            (this.project.intermediateResults! as CheckableIntermediateResultModel[])
                .filter(ir => ir.isArchived);
    }

    public selectIntermediateResult(ir: ProjectIntermediateResultModel) {
        this.selectedIntermediateResult = ir;

        // selecting an IR will fetch its details if they're not already present
        // null means not fetched yet, empty means fetched, but none present
        if (this.selectedIntermediateResult.targets == null || this.selectedIntermediateResult.progress == null) {
            this.intermediateResultsEditService.getIntermediateResultDetails(this.selectedIntermediateResult.projectIntermediateResultId)
                .pipe(take(1)).subscribe((irDetails: IntermediateResultDetailsModel) => {

                    // hack: API dates come back as stings. We convert them here
                    irDetails.targets.forEach((target: IntermediateResultTargetModel) => {
                        target.targetDate = new Date(target.targetDate!);
                    });
                    irDetails.progress.forEach((progress: IntermediateResultProgressModel) => {
                        progress.progressDate = new Date(progress.progressDate!);
                    });

                    this.selectedIntermediateResult!.targets = irDetails.targets;
                    this.selectedIntermediateResult!.progress = irDetails.progress;
                });
        }
    }

    private getCurrentIntermediateResult(): CheckableIntermediateResultModel {
        const currentIr = this.activeTab === tabs.active
            ? this.activeIntermediateResults.find(ir => ir.isChecked)
            : this.archivedIntermediateResults.find(ir => ir.isChecked);

        return currentIr!;
    }

    // #region Tabs

    public tabs = tabs;

    private _activeTab: tabs = tabs.active;

    public get activeTab(): tabs {
        return this._activeTab;
    }

    public set activeTab(value: tabs) {
        this._activeTab = value;
        this.isAllChecked = false;
    }

    public setTabTo(tab: tabs, e: Event): void {
        this.activeTab = tab;
        this.selectTopIntermediateResult();
        e.preventDefault();
    }

    // #endregion Tabs

    // #region Checkbox Selection

    private _isAllChecked: boolean;

    public get isAllChecked(): boolean {
        if (this.activeTab === tabs.active) {
            return this.activeIntermediateResults.length > 0
                && this.activeIntermediateResults.every(ir => ir.isChecked);
        }
        else {
            return this.archivedIntermediateResults.length > 0
                && this.archivedIntermediateResults.every(ir => ir.isChecked);
        }
    }

    public set isAllChecked(value: boolean) {
        this._isAllChecked = value;

        this.activeIntermediateResults.forEach((ir) => {
            ir.isChecked = value;
        });

        this.archivedIntermediateResults.forEach((ir) => {
            ir.isChecked = value;
        });
    }

    public get isCheckAllDisabled(): boolean {
        if (this.activeTab === tabs.active) {
            return this.activeIntermediateResults.length === 0;
        }
        return this.archivedIntermediateResults.length === 0;
    }

    public get checkedIntermediateResultCount(): number {
        if (this.activeTab === tabs.active) {
            return this.activeIntermediateResults.filter(ir => ir.isChecked).length;
        }
        else {
            return this.archivedIntermediateResults.filter(ir => ir.isChecked).length;
        }
    }

    // #endregion Checkbox Selection

    // #region Create/Edit

    public editCurrentIntermediateResult(): void {
        const currentIr = this.getCurrentIntermediateResult();
        this.openAddEditIntermediateResultDialog(currentIr!);
    }

    public openAddEditIntermediateResultDialog(ir: CheckableIntermediateResultModel | null): void {
        const dialogRef = this.dialogService.open(AddEditIntermediateResultDialogComponent, {
            width: '800px',
            data: {
                existingIntermediateResult: ir,
                project: this.project
            }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((intermediateResult: CheckableIntermediateResultModel) => {
            if (intermediateResult) {
                if (!ir) {
                    this.project.intermediateResults?.push(intermediateResult);
                    this.populateIrLists();
                    this.selectIntermediateResult(intermediateResult);
                }
                else {
                    // replace existing IR with this one, first in the project.
                    let index = this.project.intermediateResults!.findIndex(ir => ir.projectIntermediateResultId === intermediateResult.projectIntermediateResultId);
                    this.project.intermediateResults![index] = intermediateResult;

                    if (!ir.isArchived) {
                        index = this.activeIntermediateResults.findIndex(ir => ir.projectIntermediateResultId === intermediateResult.projectIntermediateResultId);
                        this.activeIntermediateResults[index] = intermediateResult;
                    }
                    else {
                        index = this.archivedIntermediateResults.findIndex(ir => ir.projectIntermediateResultId === intermediateResult.projectIntermediateResultId);
                        this.archivedIntermediateResults[index] = intermediateResult;
                    }

                    intermediateResult.isChecked = false;
                    this.selectIntermediateResult(intermediateResult);
                }
            }
        });
    }

    // #endregion Create/Edit

    // #region Deletion

    public deleteCurrentIntermediateResult(): void {
        const ir = this.getCurrentIntermediateResult();

        if (this.isStrategy && ir.irStatistics!.hasProgress) {
            const deleteDialogRef = this.dialogService.open(DeleteConfirmationDialogComponent, {
                data: {
                    warningMessage: 'This Intermediate Result shows progress from linked Projects. Deleting the Strategy IR will break the link to the Project. Do you want to continue?',
                    confirmButtonText: 'Yes',
                    showSaveIcon: false
                }
            });

            const confirmationDialog = deleteDialogRef.componentInstance as DeleteConfirmationDialogComponent;

            confirmationDialog.actionConfirmed.pipe(take(1)).subscribe(() => {
                this.confirmIrDeletion(ir);
                deleteDialogRef.close();
            });
            confirmationDialog.actionCanceled.pipe(take(1)).subscribe(() => {
                deleteDialogRef.close();
            });
        }
        else {
            this.confirmIrDeletion(ir);
        }
    }

    private confirmIrDeletion(ir: ProjectIntermediateResultModel): void {
        const dialogRef: MatDialogRef<DeleteConfirmationDialogComponent> = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: 'You will not be able to undo deleting this Intermediate Result.'
            }
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed
            .pipe(take(1)).subscribe(() => {
                deleteConfirmationDialog.isBusy = true;
                this.intermediateResultsEditService.deleteProjectIntermediateResult(ir.projectIntermediateResultId).pipe(take(1)).subscribe({
                    next: () => {
                        this.removeIntermediateResult(ir);
                    },
                    error: (err) => {
                        this.errorService.addError(err, true);
                    },
                    complete: () => {
                        dialogRef.close();
                    }
                });
            });
    }

    private removeIntermediateResult(ir: ProjectIntermediateResultModel): void {
        const index = this.project.intermediateResults!.findIndex(i => i.projectIntermediateResultId === ir.projectIntermediateResultId);
        this.project.intermediateResults!.splice(index, 1);
        this.populateIrLists();

        let newCurrentIr: ProjectIntermediateResultModel = this.activeTab === tabs.active
            ? this.activeIntermediateResults[0]
            : this.archivedIntermediateResults[0];

        if (newCurrentIr) {
            this.selectIntermediateResult(newCurrentIr);
        }
        else {
            this.selectedIntermediateResult = undefined;
        }
    }

    // #endregion

    // #region Re-ordering

    public irReordered(event: CdkDragDrop<string[]>): void {
        const currentArray = this.activeTab === tabs.active 
            ? this.activeIntermediateResults 
            : this.archivedIntermediateResults;

        moveItemInArray(currentArray, event.previousIndex, event.currentIndex);
        this.persistIrSortOrder(currentArray);
    }

    private persistIrSortOrder(irList: ProjectIntermediateResultModel[]): void {
        const orderedIds = irList.map((r) => r.projectIntermediateResultId!);

        this.intermediateResultsEditService.updateIntermediateResultOrder(this.project.projectId!, orderedIds).pipe(take(1))
            .subscribe({
                next: (successful: boolean) => {
                    // nothing to do
                },
                error: (err) => {
                    this.errorService.addError(err, true);
                }
            });
    }

    // #endregion Re-ordering

    // #region Archiving / Activating

    public isAllowedToArchive(): boolean {
        this.authService.userIsBusinessUnitEditorFor(this.project) ||
            this.authService.userIsDivisionEditorFor(this.project) ||
            this.authService.userIsRegionEditorFor(this.project) ||
            this.authService.userIsAdmin() ||
            this.authService.userIsITAdmin() ||
            this.authService.userIsFinanceAdmin() ||
            this.authService.isAllowedToEditAsRecordLead(this.project);
        return false;
    }

    public archiveIntermediateResultsClicked(): void {

        const dialogRef: MatDialogRef<DeleteConfirmationDialogComponent> = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: 'If you archive an Intermediate Result you will no longer be able to add values to or edit the Intermediate Result. Do you want to continue?',
                confirmButtonText: 'CONTINUE'                
            }
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed
            .pipe(take(1)).subscribe(() => {
                deleteConfirmationDialog.isBusy = true;
                const checkedIrs = this.activeIntermediateResults.filter(ir => ir.isChecked);
                const ids = checkedIrs.map(ir => ir.projectIntermediateResultId);
        
                this.intermediateResultsEditService.archiveIntermediateResults(ids).subscribe({
                    next: () => {
                        checkedIrs.forEach((ir: CheckableIntermediateResultModel) => {
                            ir.isChecked = false;
                            ir.isArchived = true;
                        });
        
                        this.populateIrLists();
                    },
                    error: (err) => {
                        this.errorService.addError(err, true);
                    },
                    complete: () => {
                        dialogRef.close();
                    }
                });        
            });
    }

    public activateIntermediateResultClicked(): void {
        const checkedIrs = this.archivedIntermediateResults.filter(ir => ir.isChecked);
        const ids = checkedIrs.map(ir => ir.projectIntermediateResultId);

        this.intermediateResultsEditService.activateIntermediateResults(ids).subscribe({
            next: () => {
                checkedIrs.forEach((ir: CheckableIntermediateResultModel) => {
                    ir.isChecked = false;
                    ir.isArchived = false;
                });

                this.populateIrLists();

            },
            error: (err) => {
                this.errorService.addError(err, true);
            }
        });
    }

    // #endregion Archiving / Activating

}
