import { Component } from '@angular/core';
import { HubRecordEditorBase } from '../_hub_record_editor_base';

@Component({
  selector: 'app-relations-edit-container',
  templateUrl: './relations-edit-container.component.html',
  styleUrls: ['./relations-edit-container.component.scss']
})
export class RelationsEditContainerComponent extends HubRecordEditorBase {
    
}
