import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { OpenCreateRecordDialogService } from 'src/app/core/services/open-create-record-dialog.service';
import { environment } from 'src/environments/environment';
import { UsageService } from '../../core/services/usage.service';
import { GoalsService } from '../services/goals.service';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    constructor(
        private titleService: Title,
        private usageService: UsageService,
        private openCreateHubRecordDialogService: OpenCreateRecordDialogService,
        private goalsService: GoalsService,
        private router: Router
    ) { }

    public goals: any[];

    ngOnInit(): void {
        this.usageService.logHubUsage('Home', 'N/A', 'N/A', 'N/A');

        const title = environment.name;
        this.titleService.setTitle(title);

        this.goalsService.get2030Goals().pipe(take(1)).subscribe(
            (goals: any[]) => {
                this.goals = goals;
            }
        );
    }

    public openCreateRecordDialog(e: Event): void {
        this.openCreateHubRecordDialogService.requestHubRecordCreateDialog();
        e.preventDefault();
    }

    public gotoGoalDetails(theme: string) {
        this.router.navigate(['/goal-details', theme]);
    }
}
