import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '../core/guards/role-guard';
import { GoalDetailsComponent } from './goal-details/goal-details.component';
import { GoalDonutChartComponent } from './home/goal-display/goal-donut-chart/goal-donut-chart.component';
import { GoalDisplayComponent } from './home/goal-display/goal-display.component';
import { MetricDonutChartComponent } from './goal-details/metric-donut-chart/metric-donut-chart.component';
import { MetricProgressByRegionChartComponent } from './goal-details/metric-progress-by-region-chart/metric-progress-by-region-chart.component';
import { GoalsService } from './services/goals.service';

const routes: Routes = [
  { path: 'goal-details/:theme', component: GoalDetailsComponent, canActivate: [RoleGuard] },
  { path: 'home', component: HomeComponent, canActivate: [RoleGuard] },
  { path: '', component: HomeComponent, canActivate: [RoleGuard] }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    HomeComponent,
    GoalDonutChartComponent,
    GoalDetailsComponent,
    GoalDisplayComponent,
    MetricDonutChartComponent,
    MetricProgressByRegionChartComponent
  ],
  providers: [GoalsService]
})
export class HomeModule { }
