import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '../core/guards/role-guard';
import { SharedModule } from '../shared/shared.module';
import { HubRecordEditContainerComponent } from './hub-record-edit-container/hub-record-edit-container.component';
import { CloseEditGuard } from './guards/close-edit.guard';
import { SummaryEditComponent } from './hub-record-edit-container/summary-edit/summary-edit.component';
import { AssociatedFilesEditContainerComponent } from './hub-record-edit-container/associated-files-edit-container/associated-files-edit-container.component';
import { AdditionalInformationEditContainerComponent } from './hub-record-edit-container/additional-information-edit-container/additional-information-edit-container.component';
import { FinancialsEditContainerComponent } from './hub-record-edit-container/financials-edit-container/financials-edit-container.component';
import { IntermediateResultsEditContainerComponent } from './hub-record-edit-container/intermediate-results-edit-container/intermediate-results-edit-container.component';
import { LocationEditContainerComponent } from './hub-record-edit-container/location-edit-container/location-edit-container.component';
import { OutcomesEditContainerComponent } from './hub-record-edit-container/outcomes-edit-container/outcomes-edit-container.component';
import { OutputsEditContainerComponent } from './hub-record-edit-container/outputs-edit-container/outputs-edit-container.component';
import { RecordStatusEditComponent } from './hub-record-edit-container/record-status-edit/record-status-edit.component';
import { RelationsEditContainerComponent } from './hub-record-edit-container/relations-edit-container/relations-edit-container.component';
import { StrategicPillarsEditContainerComponent } from './hub-record-edit-container/strategic-pillars-edit-container/strategic-pillars-edit-container.component';
import { TeamPartnersEditContainerComponent } from './hub-record-edit-container/team-partners-edit-container/team-partners-edit-container.component';
import { UnauthorizedComponent } from './hub-record-edit-container/unauthorized/unauthorized.component';
import { LocationLovService } from './hub-record-edit-container/location-edit-container/services/location-lov-service';
import { IntermediateResultListItemComponent } from './hub-record-edit-container/intermediate-results-edit-container/intermediate-result-list-item/intermediate-result-list-item.component';
import { IntermediateResultDetailComponent } from './hub-record-edit-container/intermediate-results-edit-container/intermediate-result-detail/intermediate-result-detail.component';
import { IntermediateResultsEditService } from './hub-record-edit-container/intermediate-results-edit-container/services/intermediate-results-edit.service';
import { AddEditIrTargetDialogComponent } from './hub-record-edit-container/intermediate-results-edit-container/intermediate-result-detail/add-edit-ir-target-dialog/add-edit-ir-target-dialog.component';
import { AddEditIrProgressDialogComponent } from './hub-record-edit-container/intermediate-results-edit-container/intermediate-result-detail/add-edit-ir-progress-dialog/add-edit-ir-progress-dialog.component';
import { IntermediateResultsLovService } from './hub-record-edit-container/intermediate-results-edit-container/services/intermediate-results-lov.service';
import { AddEditIntermediateResultDialogComponent } from './hub-record-edit-container/intermediate-results-edit-container/add-edit-intermediate-result-dialog/add-edit-intermediate-result-dialog.component';
import { ProjectRelationsEditComponent } from './hub-record-edit-container/relations-edit-container/project-relations-edit/project-relations-edit.component';
import { RelationsTypeaheadService } from './hub-record-edit-container/relations-edit-container/services/relations-typeahead.service';
import { StrategyRelationsEditComponent } from './hub-record-edit-container/relations-edit-container/strategy-relations-edit/strategy-relations-edit.component';
import { OutcomeDisplayComponent } from './hub-record-edit-container/outcomes-edit-container/outcome-display/outcome-display.component';
import { CustomOutcomeDisplayComponent } from './hub-record-edit-container/outcomes-edit-container/custom-outcome-display/custom-outcome-display.component';
import { OutcomesEditService } from './hub-record-edit-container/outcomes-edit-container/services/outcomes-edit.service';
import { OutcomeDetailsComponent } from './hub-record-edit-container/outcomes-edit-container/outcome-details/outcome-details.component';
import { AddEditProjectOutcomeTargetComponent } from './hub-record-edit-container/outcomes-edit-container/outcome-details/add-edit-project-outcome-target/add-edit-project-outcome-target.component';
import { OutcomesLovService } from './hub-record-edit-container/outcomes-edit-container/services/outcomes-lov.service';
import { AddEditOutcomeProgressDialogComponent } from './hub-record-edit-container/outcomes-edit-container/outcome-details/add-edit-outcome-progress-dialog/add-edit-outcome-progress-dialog.component';
import { NcsEstimatesPanelComponent } from './hub-record-edit-container/outcomes-edit-container/ncs-estimates-panel/ncs-estimates-panel.component';
import { UnsavedChangesDialogComponent } from './hub-record-edit-container/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { TeamPanelComponent } from './hub-record-edit-container/team-partners-edit-container/team-panel/team-panel.component';
import { PartnersPanelComponent } from './hub-record-edit-container/team-partners-edit-container/partners-panel/partners-panel.component';
import { TeamLovService } from './hub-record-edit-container/team-partners-edit-container/team-panel/services/team-lov.service';
import { TeamMemberDialogComponent } from './hub-record-edit-container/team-partners-edit-container/team-panel/team-member-dialog/team-member-dialog.component';
import { PartnerDialogComponent } from './hub-record-edit-container/team-partners-edit-container/partners-panel/partner-dialog/partner-dialog.component';
import { FinancialsLovService } from './hub-record-edit-container/financials-edit-container/services/financials-lov.service';
import { FinancialsEditPanelComponent } from './hub-record-edit-container/financials-edit-container/financials-edit-panel/financials-edit-panel.component';
import { InsightRecordsPanelComponent } from './hub-record-edit-container/financials-edit-container/insight-records-panel/insight-records-panel.component';
import { AnchorRecordSelectionPanelComponent } from './hub-record-edit-container/financials-edit-container/anchor-record-selection-panel/anchor-record-selection-panel.component';
import { NotesPanelComponent } from './hub-record-edit-container/financials-edit-container/notes-panel/notes-panel.component';
import { InsightLookupComponent } from './hub-record-edit-container/financials-edit-container/anchor-record-selection-panel/insight-lookup/insight-lookup.component';
import { AnchorRecordConfirmationDialogComponent } from './hub-record-edit-container/financials-edit-container/anchor-record-selection-panel/insight-lookup/anchor-record-confirmation-dialog/anchor-record-confirmation-dialog.component';
import { DonorKeywordsEditComponent } from './hub-record-edit-container/additional-information-edit-container/donor-keywords-edit/donor-keywords-edit.component';
import { ProgramManagementRelationsEditComponent } from './hub-record-edit-container/relations-edit-container/program-management-relations-edit/program-management-relations-edit.component';
import { WholeSystemRelationsEditComponent } from './hub-record-edit-container/relations-edit-container/whole-system-relations-edit/whole-system-relations-edit.component';
import { AssociatedFilesEditService } from './hub-record-edit-container/associated-files-edit-container/services/associated-files-edit.service';
import { AddEditAssociatedFileDialogComponent } from './hub-record-edit-container/associated-files-edit-container/add-edit-associated-file-dialog/add-edit-associated-file-dialog.component';
import { OutputListComponent } from './hub-record-edit-container/outputs-edit-container/output-list/output-list.component';
import { OutputDetailComponent } from './hub-record-edit-container/outputs-edit-container/output-detail/output-detail.component';
import { OutputsEditService } from './hub-record-edit-container/outputs-edit-container/services/outputs-edit.service';
import { CreateUpdateOutputLinkDialogComponent } from './hub-record-edit-container/outputs-edit-container/output-detail/create-update-output-link-dialog/create-update-output-link-dialog.component';
import { CreateUpdateOutputProgressDialogComponent } from './hub-record-edit-container/outputs-edit-container/output-detail/create-update-output-progress-dialog/create-update-output-progress-dialog.component';
import { CreateUpdateOutputDialogComponent } from './hub-record-edit-container/outputs-edit-container/create-update-output-dialog/create-update-output-dialog.component';
import { StrategicPillarListItemComponent } from './hub-record-edit-container/strategic-pillars-edit-container/strategic-pillar-list-item/strategic-pillar-list-item.component';
import { StrategicPillarDetailComponent } from './hub-record-edit-container/strategic-pillars-edit-container/strategic-pillar-detail/strategic-pillar-detail.component';
import { StrategicPillarEditService } from './hub-record-edit-container/strategic-pillars-edit-container/services/strategic-pillar-edit.service';
import { CreateStrategicPillarDialogComponent } from './hub-record-edit-container/strategic-pillars-edit-container/create-strategic-pillar-dialog/create-strategic-pillar-dialog.component';
import { CreateOutcomeDialogComponent } from './hub-record-edit-container/outcomes-edit-container/create-outcome-dialog/create-outcome-dialog.component';
import { OutcomeTypeTabComponent } from './hub-record-edit-container/outcomes-edit-container/create-outcome-dialog/outcome-type-tab/outcome-type-tab.component';
import { OutcomeDetailsTncGoalsPanelComponent } from './hub-record-edit-container/outcomes-edit-container/create-outcome-dialog/outcome-details-tnc-goals-panel/outcome-details-tnc-goals-panel.component';
import { OutcomeDetailsCustomPanelComponent } from './hub-record-edit-container/outcomes-edit-container/create-outcome-dialog/outcome-details-custom-panel/outcome-details-custom-panel.component';
import { TncGoalsStandalonePanelComponent } from './hub-record-edit-container/outcomes-edit-container/create-outcome-dialog/outcome-details-tnc-goals-panel/tnc-goals-standalone-panel/tnc-goals-standalone-panel.component';
import { TncGoalsRelatedPanelComponent } from './hub-record-edit-container/outcomes-edit-container/create-outcome-dialog/outcome-details-tnc-goals-panel/tnc-goals-related-panel/tnc-goals-related-panel.component';
import { OutcomeTargetPanelComponent } from './hub-record-edit-container/outcomes-edit-container/outcome-target-panel/outcome-target-panel.component';
import { NcsInterventionPanelComponent } from './hub-record-edit-container/outcomes-edit-container/create-outcome-dialog/outcome-details-tnc-goals-panel/tnc-goals-standalone-panel/ncs-intervention-panel/ncs-intervention-panel.component';
import { LocationAndSpatialExtentPanelComponent } from './hub-record-edit-container/outcomes-edit-container/create-outcome-dialog/outcome-details-tnc-goals-panel/tnc-goals-standalone-panel/ncs-intervention-panel/location-and-spatial-extent-panel/location-and-spatial-extent-panel.component';
import { CalculationsInfoDialogComponent } from './hub-record-edit-container/outcomes-edit-container/outcome-target-panel/calculations-info-dialog/calculations-info-dialog.component';
import { CustomOutcomeRelatedPanelComponent } from './hub-record-edit-container/outcomes-edit-container/create-outcome-dialog/outcome-details-custom-panel/custom-outcome-related-panel/custom-outcome-related-panel.component';
import { CustomOutcomeStandalonePanelComponent } from './hub-record-edit-container/outcomes-edit-container/create-outcome-dialog/outcome-details-custom-panel/custom-outcome-standalone-panel/custom-outcome-standalone-panel.component';
import { OutcomesTypeaheadService } from './hub-record-edit-container/outcomes-edit-container/services/outcomes-typeahead.service';
import { EditOutcomeDialogComponent } from './hub-record-edit-container/outcomes-edit-container/edit-outcome-dialog/edit-outcome-dialog.component';
import { TncGoalsParentSelectionPanelComponent } from './hub-record-edit-container/outcomes-edit-container/edit-outcome-dialog/tnc-goals-parent-selection-panel/tnc-goals-parent-selection-panel.component';
import { CustomParentSelectionPanelComponent } from './hub-record-edit-container/outcomes-edit-container/edit-outcome-dialog/custom-parent-selection-panel/custom-parent-selection-panel.component';
import { CreateOutcomeStateService } from './hub-record-edit-container/outcomes-edit-container/create-outcome-dialog/services/create-outcome-state.service';
import { NcsPanelComponent } from './hub-record-edit-container/outcomes-edit-container/create-outcome-dialog/outcome-details-tnc-goals-panel/ncs-panel/ncs-panel.component';

const routes: Routes = [
  { path: 'portfolio/:id/edit', component: HubRecordEditContainerComponent, canActivate: [RoleGuard], canDeactivate: [CloseEditGuard] },
  { path: 'portfolio/\?[a-zA-z]+', component: HubRecordEditContainerComponent, canActivate: [RoleGuard] }
];


@NgModule({
  declarations: [
    HubRecordEditContainerComponent,
    SummaryEditComponent,
    AssociatedFilesEditContainerComponent,
    AdditionalInformationEditContainerComponent,
    FinancialsEditContainerComponent,
    IntermediateResultsEditContainerComponent,
    LocationEditContainerComponent,
    OutcomesEditContainerComponent,
    OutputsEditContainerComponent,
    RecordStatusEditComponent,
    RelationsEditContainerComponent,
    ProjectRelationsEditComponent,
    StrategicPillarsEditContainerComponent,
    TeamPartnersEditContainerComponent,
    UnauthorizedComponent,
    IntermediateResultListItemComponent,
    IntermediateResultDetailComponent,
    AddEditIrTargetDialogComponent,
    AddEditIrProgressDialogComponent,
    AddEditIntermediateResultDialogComponent,
    StrategyRelationsEditComponent,
    OutcomeDisplayComponent,
    CustomOutcomeDisplayComponent,
    OutcomeDetailsComponent,
    AddEditProjectOutcomeTargetComponent,
    AddEditOutcomeProgressDialogComponent,
    NcsEstimatesPanelComponent,
    UnsavedChangesDialogComponent,
    TeamPanelComponent,
    PartnersPanelComponent,
    TeamMemberDialogComponent,
    PartnerDialogComponent,
    FinancialsEditPanelComponent,
    InsightRecordsPanelComponent,
    AnchorRecordSelectionPanelComponent,
    NotesPanelComponent,
    InsightLookupComponent,
    AnchorRecordConfirmationDialogComponent,
    DonorKeywordsEditComponent,
    ProgramManagementRelationsEditComponent,
    WholeSystemRelationsEditComponent,
    AddEditAssociatedFileDialogComponent,
    OutputListComponent,
    OutputDetailComponent,
    CreateUpdateOutputLinkDialogComponent,
    CreateUpdateOutputProgressDialogComponent,
    CreateUpdateOutputDialogComponent,
    StrategicPillarListItemComponent,
    StrategicPillarDetailComponent,
    CreateStrategicPillarDialogComponent,
    CreateOutcomeDialogComponent,
    OutcomeTypeTabComponent,
    OutcomeDetailsTncGoalsPanelComponent,
    OutcomeDetailsCustomPanelComponent,
    TncGoalsStandalonePanelComponent,
    TncGoalsRelatedPanelComponent,
    OutcomeTargetPanelComponent,
    NcsInterventionPanelComponent,
    LocationAndSpatialExtentPanelComponent,
    CalculationsInfoDialogComponent,
    CustomOutcomeRelatedPanelComponent,
    CustomOutcomeStandalonePanelComponent,
    EditOutcomeDialogComponent,
    TncGoalsParentSelectionPanelComponent,
    CustomParentSelectionPanelComponent,
    NcsPanelComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  providers: [
    LocationLovService,
    IntermediateResultsEditService,
    IntermediateResultsLovService,
    RelationsTypeaheadService,
    OutcomesEditService,
    OutcomesLovService,
    TeamLovService,
    FinancialsLovService,
    AssociatedFilesEditService,
    OutputsEditService,
    StrategicPillarEditService,
    OutcomesTypeaheadService,
    CreateOutcomeStateService
  ]
})
export class HubRecordEditModule { }
