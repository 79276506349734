<div class="collection-dialog-container" *ngIf="collectionForm">
    <div class="hub-modal-header-text">
        <h5 *ngIf="!existingCollection" class="modal-title text-success">Create a new Collection</h5>
        <h5 *ngIf="existingCollection" class="modal-title text-success">Edit Collection</h5>
    </div>

    <div class="content">
        <form [formGroup]="collectionForm">
            <div class="row mb-2">
                <div class="col-md-12">
                    <span class="select-label text-info-800 d-block">Type<app-help-selector [selector]="1155">
                        </app-help-selector></span>

                    <div class="hub-select-container">
                        <mat-form-field>
                            <mat-select placeholder="Select a Collection Type" disableOptionCentering
                                formControlName="recordCollectionTypeId">
                                <mat-option *ngFor="let ct of recordCollectionTypes" [value]="ct.recordCollectionTypeId">
                                    {{ct.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="hub-validation-error-message">
                        {{getValidationErrorMessages("recordCollectionTypeId")}}
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-12">
                    <span class="select-label text-info-800 d-block">Name<app-help-selector [selector]="1156">
                        </app-help-selector></span>
                    <input type="text" class="form-control mb-2" formControlName="name" placeholder="Collection Name">
                    <div class="hub-validation-error-message">
                        {{getValidationErrorMessages("name")}}
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-12">
                    <span class="select-label text-info-800 d-block">Description<app-help-selector [selector]="1157">
                        </app-help-selector></span>
                    <textarea rows="3" cols="3" class="form-control mb-2"
                        placeholder='Enter a description for your Collection' formControlName="description">
                    </textarea>
                    <div class="hub-validation-error-message">
                        {{getValidationErrorMessages("description")}}
                    </div>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-md-12">
                    <span class="select-label text-info-800 d-block">Privacy<app-help-selector [selector]="1158">
                        </app-help-selector></span>
                    <div>
                        <mat-checkbox color="primary" id="isPrivate" formControlName="isPrivate"></mat-checkbox>
                        <label for="isPrivate">Private Collection</label>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="footer">
        <button (click)="close()" class="cancel-button">Cancel</button>

        <button type="button" class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
            [disabled]="collectionForm.invalid || isSaving" (click)="save()">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>
            {{existingCollection ? 'Save' : 'Add'}}
        </button>

    </div>

</div>
