import { inject, Injectable } from '@angular/core';
import { HttpService } from '../../core/services/http.service';
import { Observable } from 'rxjs';
import { ProjectModel } from '../../../hub_schema/hubTypes';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HubRecordService {
    private httpService: HttpService = inject(HttpService);

    public getProject(projectId: number): Observable<ProjectModel> {
        const url = environment.endpoints.base + '/projects/' + projectId + '/true';
        return this.httpService.get<ProjectModel>(url);
    }
}
