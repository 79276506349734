import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-logout-notification',
  templateUrl: './logout-notification.component.html',
  styleUrls: ['./logout-notification.component.scss']
})
export class LogoutNotificationComponent {

  constructor(
    private dialogRef: MatDialogRef<LogoutNotificationComponent>,
    private authService: AuthService
  ) { }

  public continue(): void {
    this.authService.continueSession();
    this.dialogRef.close();
  }
}
