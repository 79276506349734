import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OutcomesEditService } from '../services/outcomes-edit.service';
import { ProjectModel, ProjectOutcomeModel } from '../../../../../hub_schema/hubTypes';

@Component({
    selector: 'app-outcome-display',
    templateUrl: './outcome-display.component.html',
    styleUrls: ['./outcome-display.component.scss'],
})
export class OutcomeDisplayComponent {

    @Input()
    public projectOutcome: ProjectOutcomeModel;

    @Input() isSelected: boolean;

    @Output()
    public deleteOutcomeRequested: EventEmitter<void> = new EventEmitter();

    @Output()
    public editOutcomeRequested: EventEmitter<ProjectOutcomeModel> = new EventEmitter();

    public deleteOutcomeClicked(): void {
        this.deleteOutcomeRequested.emit();
    }

    public editOutcomeClicked(): void {
        this.editOutcomeRequested.emit(this.projectOutcome);
    }
}
