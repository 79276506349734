<!-- Page header -->
<div class="page-header p-0 sb-l">
  <div class="page-header-content header-elements-md-inline">
    <div class="page-title d-flex">
      <h4><span class="font-weight-semibold text-success-800" id="PageTitle">Record not found</span></h4>
      <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-close2 icon-3x"></i></a>
    </div>
  </div>
</div>
<!-- /page header -->
<!-- Content area -->
<div class="content p-0 mt-0 pt-0 d-flex flex-wrap align-content-stretch" style="bottom: 0px;">
  <div class="col-lg-12 p-2 pl-4 pr-4 bg-white sb-l sb-b">

    <div class="media">
      <div class="mr-3">
        <i class="icon-close2 icon-3x text-danger"></i>
      </div>
      <div class="media-body" *ngIf="!isDeleted">
        <h3 class="media-title text-danger">Record not found</h3>
        <p class="narrative"> The record you are trying to access was not found, please visit <a routerLink="/portfolio" class="font-weight-bold">Portfolio Search</a> to search for another record.</p>
      </div>
      <div class="media-body" *ngIf="isDeleted">
        <h3 class="media-title text-danger">Record was deleted</h3>
        <p class="narrative"> The record you are trying to access was deleted, please visit <a routerLink="/portfolio" class="font-weight-bold">Portfolio Search</a> to search for another record, or contact <a href="mailto:hub@tnc.org">hub@tnc.org</a> for support.</p>
      </div>
    </div>
  </div>

</div>
