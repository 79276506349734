import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttributionLevelModel, IntermediateResultProgressModel, ProjectIntermediateResultModel, ScaleMeasureDefinitionModel } from '../../../../../../hub_schema/hubTypes';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { IntermediateResultsEditService } from '../../services/intermediate-results-edit.service';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { IntermediateResultsLovService } from '../../services/intermediate-results-lov.service';
import { take } from 'rxjs';

@Component({
    selector: 'app-add-edit-ir-progress-dialog',
    templateUrl: './add-edit-ir-progress-dialog.component.html',
    styleUrls: ['./add-edit-ir-progress-dialog.component.scss'],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class AddEditIrProgressDialogComponent implements OnInit {

    // injected dependencies
    private intermediateResultsEditService: IntermediateResultsEditService = inject(IntermediateResultsEditService);
    private intermediateResultsLovService: IntermediateResultsLovService = inject(IntermediateResultsLovService);
    private dialogRef: MatDialogRef<AddEditIrProgressDialogComponent> = inject(MatDialogRef<AddEditIrProgressDialogComponent>);
    private data: any = inject(MAT_DIALOG_DATA);

    // page properties
    public intermediateResult: ProjectIntermediateResultModel;
    public existingProgress: IntermediateResultProgressModel | null;
    public attributionLevels: AttributionLevelModel[];
    public progressForm: FormGroup;
    public isStart: boolean;
    public isSaving: boolean = false;

    public ngOnInit(): void {
        this.existingProgress = this.data.existingProgress;
        this.intermediateResult = this.data.intermediateResult;
        this.isStart = this.data.isStart;

        this.progressForm = new FormGroup({
            intermediateResultProgressId: new FormControl({
                value: this.existingProgress ? this.existingProgress.intermediateResultProgressId : undefined, 
                disabled: !this.existingProgress}),
            projectIntermediateResultId: new FormControl(this.intermediateResult.projectIntermediateResultId),
            progressDate: new FormControl(this.existingProgress ? this.existingProgress.progressDate : null, [Validators.required, this.validateProgressDate.bind(this)]),
            progressValue: new FormControl(this.existingProgress ? this.existingProgress.progressValue : null, Validators.required),
            attributionDescription: new FormControl(this.existingProgress ? this.existingProgress.attributionDescription : '', Validators.required),
            attributionLevelId: new FormControl(this.existingProgress ? this.existingProgress.attributionLevelId : null, Validators.required)
        });

        this.intermediateResultsLovService.getAttributionLevels().pipe(take(1)).subscribe({
            next: (attributionLevels: AttributionLevelModel[]) => {
                this.attributionLevels = attributionLevels;
            }
        });
    }

    private validateProgressDate(progressDateControl: FormControl): ValidationErrors | null {
        if (progressDateControl.pristine) {
            return null;
        }

        const progressDate = progressDateControl.value as Date;
        const today = new Date();

        if (this.isStart) {

            // Validation Rule 1 - Start/Progress dates must be in the past 
            if (progressDate.getTime() > today.getTime()) {
                return {
                    'invalid': 'Start dates must be in the past.'
                };
            }

            // Validation Rule 2 - Starts must be before existing progress
            if (this.intermediateResult.progress?.some(p => p !== this.existingProgress && p.progressDate!.getTime() < progressDate.getTime())) {
                return {
                    'invalid': 'Start dates must occur before existing progress dates.'
                }
            }

            // Validation Rule 3 - Starts must be before existing targets
            if (this.intermediateResult.targets?.some(t => t.targetDate!.getTime() < progressDate.getTime())) {
                return {
                    'invalid': 'Start dates must occur before existing target dates.'
                }
            }
        }
        else {
            // Validation Rule 1 - Start/Progress dates must be in the past 
            if (progressDate.getTime() > today.getTime()) {
                return {
                    'invalid': 'Progress dates must be in the past.'
                };
            }
            
            // Validation Rule 4 - Progress must be after Start
            const start = this.intermediateResult.progress?.find(p => p.isStart);

            if (!!start) {
                if (progressDate.getTime() < start.progressDate!.getTime()) {
                    return {
                        'invalid': 'The date entered is before the Intermediate Result start date.'
                    };
                }
            }
        }

        return null;
    }

    public getValidationErrorMessages(formControlName: string): string {
        const formControl = this.progressForm!.controls[formControlName];

        if (!formControl.touched) {
            return '';
        }

        if (formControl.errors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(formControl.errors)) {
                if (key == 'required') {
                    errorMessages.push(key);
                }
                else if (key === 'min' || key === 'max') {
                    errorMessages.push('Value must be 1-5')
                }
                else {
                    errorMessages.push(formControl.errors[key]);
                }
            }
            return errorMessages.join(', ');
        }

        return '';
    }

    // #region Scale Measures

    public get isScaleMeasure(): boolean {
        return this.intermediateResult.scaleMeasureDefinitions!.length > 0;
    }

    public getScaleDefinitions(): ScaleMeasureDefinitionModel[] {
        if (this.intermediateResult.parentIntermediateResult) {
            return this.intermediateResult.parentIntermediateResult.scaleMeasureDefinitions!;
        }
        return this.intermediateResult.scaleMeasureDefinitions!;
    }

    // #endregion Scale Measures

    public cancel(): void {
        this.dialogRef.close();
    }

    public save(): void {
        const irProgress: IntermediateResultProgressModel = this.progressForm.value;
        irProgress.isStart = this.isStart;

        this.isSaving = true;

        if (!this.existingProgress) {
            this.intermediateResultsEditService.createProgress(irProgress).subscribe((savedIrProgress: IntermediateResultProgressModel) => {
                this.isSaving = false;
                this.dialogRef.close(savedIrProgress);
            });
        }
        else {
            this.intermediateResultsEditService.updateProgress(irProgress).subscribe((savedIrProgress: IntermediateResultProgressModel) => {
                this.isSaving = false;
                this.dialogRef.close(savedIrProgress);
            });
        }
    }
}
