import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CoreModule } from '../core.module';

@Injectable({
    providedIn: CoreModule
})
export class TeamService {
    constructor(private sanitizer: DomSanitizer) { }

    public generateTeamsUri(email) {
        const uri = `https://teams.microsoft.com/l/chat/0/0?users=${email}`;
        return this.sanitizer.bypassSecurityTrustResourceUrl(uri);
    }
}
