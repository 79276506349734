import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../core/services/auth/auth.service';

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss']
})
export class LoggedOutComponent implements OnInit {
  title = 'Hub - Logged Out';

  constructor(
    private titleService: Title,
    private authService: AuthService) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.authService.clearAuthInfo();
  }
}
