import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UserDataService } from '../../../core/services/user-data.service';
import { TeamService } from '../../../core/services/team-service';
import { ProjectModel, TeamMemberModel, UserModel } from '../../../../hub_schema/hubTypes';
import { AvataredUserModel } from '../../../../hub_schema/model-extensions/avatared-user-model';

@Component({
    selector: 'app-team-container',
    templateUrl: './team-container.component.html',
    styleUrls: ['./team-container.component.scss'],
})
export class TeamContainerComponent {

    private _project: ProjectModel;

    public get project(): ProjectModel {
        return this._project;
    }

    public team: TeamMemberModel[];

    @Input()
    public set project(project: ProjectModel) {
        this._project = project;

        if (project) {
            this.team = this.sortByBusinessRole(this.project.team!)
                .map(teamMember => {
                    (teamMember.user as AvataredUserModel)!.avatarObservable = this.getPicture(teamMember.user!.email);
                    return teamMember;
                });
        }
    }

    constructor(
        private sanitizer: DomSanitizer,
        public userDataService: UserDataService,
        private teamService: TeamService) { }

    private sortByBusinessRole(team: TeamMemberModel[]): TeamMemberModel[] {
        // todo (ACE 9-6-2023) let's sort this on the server
        return team.sort((a, b) => {
            const minOrderA = Math.min(...a.userBusinessRoles!.map((role) => role.businessRole!.displayOrder));
            const minOrderB = Math.min(...b.userBusinessRoles!.map((role) => role.businessRole!.displayOrder));

            if (minOrderA - minOrderB !== 0) {
                return minOrderA - minOrderB;
            } else {
                const lNameA = a.user!.lastName.toLowerCase();
                const fNameA = a.user!.firstName!.toLowerCase();
                const lNameB = b.user!.lastName.toLowerCase();
                const fNameB = b.user!.firstName!.toLowerCase();
                if (lNameA < lNameB) {
                    return -1;
                } else if (lNameA > lNameB) {
                    return 1;
                } else if (fNameA < fNameB) {
                    return -1;
                } else if (fNameA > fNameB) {
                    return 1;
                }
                return 0;
            }
        });
    }

    public isFinanceEditor(user: UserModel): boolean {
        return user.userApplicationRoles?.some(uar => uar.applicationRole?.name === 'Finance Editor') || false;
    }

    public getPicture(userEmail: string) {
        return this.userDataService.getUserProfilePicture(userEmail, null);
    }
}
