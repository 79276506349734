import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { formatDistance } from 'date-fns';
import { UserDataService } from '../../../../core/services/user-data.service';
import { ProjectStatuses } from '../../../types/project-statuses';
import { ProjectCardService } from '../services/project-card.service';
import { ProjectInfo } from '../types/project-info';
import { GoalsService } from '../../../../home/services/goals.service';
import { take } from 'rxjs/operators';
import { ToastService } from '../../../../core/services/toast.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '../../delete-confirmation-dialog/delete-confirmation-dialog.component';
import { RecordCollectionService } from '../../../../core/services/record-collection.service';

@Component({
    selector: 'app-project-card-algolia',
    templateUrl: './project-card-algolia.component.html',
    styleUrls: ['./project-card-algolia.component.scss']
})
export class ProjectCardAlgoliaComponent implements OnInit {

    constructor(
        public userDataService: UserDataService,
        private projectCardService: ProjectCardService,
        private projectCollectionService: RecordCollectionService,
        private goalsService: GoalsService,
        private toastService: ToastService,
        private router: Router,
        private dialogService: MatDialog
    ) {
        this.currentFiscalYear = this.projectCardService.currentFiscalYear;

        this.goalsService.get2030Goals().pipe(take(1)).subscribe((goals: any[]) => {
            this.goals = goals;
        });
    }

    private _projectInfo: ProjectInfo;
    private goals: any[];

    @Input()
    public set projectInfo(value: ProjectInfo) {
        this._projectInfo = value;

        if (!value.businessUnits) {
            return;
        }

        if (value.objectID) { // from algolia
            const leadBuKey = this._projectInfo.businessUnits.findIndex((bu) => bu === this._projectInfo.leadBusinessUnit);
            this.leadBusinessUnitId = this._projectInfo.businessUnitIds[leadBuKey];
        }
        else { // from graphQl
            const leadProjectBu = (value as any).businessUnits.find((pbu) => pbu.leadBusinessUnit);

            if (leadProjectBu) {
                this.leadBusinessUnitId = leadProjectBu.businessUnit.businessUnitId;
            }
        }
    }

    public get projectInfo() {
        return this._projectInfo;
    }

    public get isAlgoliaCard(): boolean {
        return this.projectInfo.objectID != null;
    }

    public get hubRecordId(): number {
        // hack (ACE - 8/16/2022).  This card is for Algolia data.  Won't this.isAlgoliaCard always be true???
        if (this.isAlgoliaCard) {
            return parseInt(this.projectInfo.objectID.toString());
        }
        else {
            return (this.projectInfo as any).projectId;
        }
    }

    public get recordTypeName(): string {
        if (this.isAlgoliaCard) {
            return this.projectInfo.recordType;
        }
        else {
            return (this.projectInfo as any).projectType.name;
        }
    }

    public get statusName(): string {
        if (this.isAlgoliaCard) {
            return this.projectInfo.status;
        }
        else {
            return (this.projectInfo as any).status.name;
        }
    }

    public get dateModified(): string {
        if (this.isAlgoliaCard) {
            return this.projectInfo.modifiedOn_gmt;
        }
        else {
            return (this.projectInfo as any).modifiedOn;
        }
    }

    @Input() showCardDetails: boolean;
    @Input() showFinanceDetails: boolean;
    @Input() userIsCreator = false;
    @Input() isUserCanEdit = false;
    @Input() addToCollection = true;
    @Input() isZoomButtonVisible = false;

    @Output()
    public deleteRecordCollectionProjectRequested: EventEmitter<any> = new EventEmitter();

    currentFiscalYear: number;
    progressBarStyle: { width: string; }; // Where is this used? I see the assignment but that is it! (ecoffman)
    public leadBusinessUnitId: string;

    ngOnInit() {
        this.progressBarStyle = {
            width: 33 + '%',
        };

        // todo: had to comment this out as it was sending too many requests (one for each card)
        // this.projectCollectionService.getProjectCollectionLovs();
    }

    public getDistanceInWordsToNow(): string {
        const date = new Date(this.dateModified);
        const today = new Date();
        return formatDistance(date, today, { addSuffix: true });
    }

    // TODO: This method is in the code base at 3 different locations. Consolidate them! (ecoffman - 7/13/21)
    private numberWithCommas(x) {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return '0';
    }

    getClassFromStatus(status: ProjectStatuses) {
        switch (status) {
            case ProjectStatuses.Active:
                return 'text-success';
            case ProjectStatuses.Approved:
                return 'text-indigo';
            case ProjectStatuses.Completed:
                return 'text-grey-600';
            case ProjectStatuses.Canceled:
                return 'text-danger';
            case ProjectStatuses.Proposed:
                return 'text-primary';
        }
    }

    deleteRecordCollectionProjectClicked(): void {
        const dialogRef: MatDialogRef<DeleteConfirmationDialogComponent> = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: 'Do you want to delete this record from this collection?'
            },
            disableClose: true
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed.pipe(take(1)).subscribe((res) =>
        {
            this.removeProjectFromCollection()
            dialogRef.close();
        });

        deleteConfirmationDialog.actionCanceled.pipe(take(1)).subscribe(() => {            
            dialogRef.close();
        });
    }

    removeProjectFromCollection() {
        const collectionProjectId = this.projectInfo.recordCollectionProjectId;

        this.projectCollectionService.deleteProjectFromCollection(collectionProjectId).subscribe({
            next: () => {
                this.deleteRecordCollectionProjectRequested.emit(collectionProjectId);
            },
            error: (err) => {
                console.log(err);
            }
        });
    }

    public toggleFavorite(projectId: number, e: Event): void {
        const isFavorite = this.userDataService.isFavorite(projectId);
        this.userDataService.toggleFavorite(projectId);

        if (!isFavorite) {
            this.toastService.show('Saved to Favorites', 'Go to Favorites');

            this.toastService.gotoRequested.pipe(take(1)).subscribe(() => {
                this.toastService.clear();
                const stateObj = { state: {} };
                stateObj.state['tab'] = 'favorites';
                this.router.navigateByUrl('/user', stateObj);
            });
        }

        e.preventDefault();
        e.stopPropagation();
    }

    public getGoals(): any[] {
        if (!this.goals) {
            return [];
        }

        let projectThemes: string[];

        const orgMetrics = this.projectInfo['organizationalScaMetrics'];

        if (orgMetrics) {
            projectThemes = orgMetrics.lvl0;
        }

        if (!projectThemes) {
            return [];
        }

        const result = this.goals.filter(g => projectThemes.includes(g.theme));
        return result;
    }

}
