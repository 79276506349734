<div class="card card-record collection-card" *ngIf="collection">
    <div class="media">
        <div class="mr-sm-3 mb-2 mb-sm-0">
            <i class="icon-books icon-3x text-primary"></i>
        </div>
        <div class="media-body">
            <div class="d-md-flex justify-content-between">
                <h6 class="media-title">
                    <a
                        [routerLink]="'/collection/' + collection.recordCollectionId" [state]="{route: '/user', tab: 'collections'}">{{collection.name}}</a>
                </h6>
                <button
                    *ngIf="!!employeeId && employeeId === userId"
                    type="button"
                    (click)="requestCollectionDeletion()"
                    class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                    data-popup="tooltip" title="" data-placement="right"
                    data-original-title="Delete Collection" tabindex="0">
                    <i class="icon-trash"></i>
                </button>
            </div>
            <ul class="list-inline list-inline-dotted text-muted mb-2">
                <li class="list-inline-item font-weight-semibold">
                    {{collection.recordCollectionType?.name}}
                </li>
                <li class="list-inline-item">
                    <i class="icon-user mr-2"></i>{{collection.owner.fullName}}
                </li>
            </ul>
            <span class="d-block text-info-800 font-weight-semi-bold pb-2 mb-2 sb-b">
                {{collection.numberOfProjects}}
                Projects
            </span>
            <p class="card-description" [innerHtml]="collection?.description | innerHtml"></p>
        </div>
    </div>
</div>
