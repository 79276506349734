import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FinancialContactTypeAheadModel } from 'src/hub_schema/hubTypes';

@Injectable()
export class HttpService {
    private ephemeralKeyTimeToLiveHours = 8;
    private ephemeralKeys = {};

    constructor(
        private http: HttpClient
    ) { }

    // todo (ACE 8/17/2022) - This service should really be about making http calls.  Only.
    // other domain-level services should live in their domain, and consume this service for their HTTP calls.
    // ideally, we shouldn't be making any HTTP calls directly with the Angular HttpClient, but going through this service

    // #region Making http calls

    public getText(url: string) {
        return this.http.get(url, { responseType: 'text' });
    }

    public get<T>(url: string, options: any = null, doNotLog: boolean = false): Observable<T> {
        const startTime = new Date();

        return this.http.get<T>(url).pipe(tap(() => {
            const endTime = new Date();
            const timeTaken = endTime.getTime() - startTime.getTime();
        }));
    }

    public post<T>(url: string, data: any, doNotLog: boolean = false): Observable<T> {
        const startTime = new Date();

        return this.http.post<T>(url, data).pipe(tap(() => {
            const endTime = new Date();
            const timeTaken = endTime.getTime() - startTime.getTime();
        }));
    }

    public patch<T>(url: string, data: any, doNotLog: boolean = false): Observable<T> {
        const startTime = new Date();

        return this.http.patch<T>(url, data).pipe(tap(() => {
            const endTime = new Date();
            const timeTaken = endTime.getTime() - startTime.getTime();
        }));
    }

    public put<T>(url: string, data: any, doNotLog: boolean = false): Observable<T> {
        const startTime = new Date();

        return this.http.put<T>(url, data).pipe(tap(() => {
            const endTime = new Date();
            const timeTaken = endTime.getTime() - startTime.getTime();
        }));
    }

    public delete(url: string): Observable<any> {
        return this.http.delete(url);
    }

    // #endregion

    // #region Algolia-Hub Backend

    getEphemeralAlgoliaKeyFor(serviceName: string): Observable<any> {
        if (this.ephemeralKeys.hasOwnProperty(serviceName) && this.ephemeralKeys[serviceName].expires > new Date().getTime()) {
            return of(this.ephemeralKeys[serviceName].key);
        }
        if (environment.algolia.hasOwnProperty(serviceName)) {
            const endpoint = environment.algolia[serviceName].temporarySecretKeyEndpoint;

            return this.http.get(endpoint, { responseType: 'text' })
                .pipe(
                    tap((ephemeralKey: string) => {
                        this.ephemeralKeys[serviceName] = {
                            key: ephemeralKey,
                            expires: this.addHours(new Date(), this.ephemeralKeyTimeToLiveHours)
                        };
                    })
                );
        }
        else {
            throwError(`'${serviceName}' is not a key known to Hub Algolia environment (environment.ts).`);
        }
    }

    // todo: doesn't belong in this service
    private addHours(now: Date, hoursToAdd: number): number {
        return now.getTime() + (hoursToAdd * 60 * 60 * 1000);
    }

    // #endregion Algolia-Hub Backend

    // #region User Backend

    // todo (ACE 11/25/2022): should go in auth service
    getRefreshedAccessToken(refreshToken: string): Observable<any> {
        const endpoint = environment.endpoints.base + '/token/refreshToken';
        return this.http.post(endpoint, { refreshToken });
    }

    verifyEmailAddress(emailAddress: string): Observable<string> {
        const endpoint = environment.endpoints.base + '/verify/email?emailAddress=' + emailAddress;
        return this.http.get(endpoint, { responseType: 'text' });
    }

    getBulkEditTypeAheadData(needle: string, haystack: string, includeInactive?: boolean, businessUnitFilter?: string) {
        let endpoint = `${environment.endpoints.base}/typeahead/${haystack}/${needle}`;
        if (!!includeInactive) {
            endpoint = endpoint + `?includeInactive=${includeInactive}`;
        }

        //just make another param
        if (!!businessUnitFilter) {
            endpoint = endpoint + `${!!includeInactive ? '&' : '?'}businessUnitFilter=${businessUnitFilter}`;
            //if businessUnits and if units length is more than 1, then loop and append: &businessUnitFilter
        }
        return this.http.get<any[]>(endpoint, { responseType: 'json' });
    }

    getBulkEditFinanceContactsAdminTypeAhead(prefix: string) {
        let endpoint = `${environment.endpoints.base}/typeahead/FinanceContact/Admin/${prefix}`;

        //just make another para
        return this.http.get<any[]>(endpoint, { responseType: 'json' });
    }

    getBulkEditFinanceContactsNonAdminTypeAhead(prefix: string, permissions: FinancialContactTypeAheadModel) {
        let endpoint = `${environment.endpoints.base}/typeahead/FinanceContact/NonAdmin/${prefix}`;

        //just make another para
        return this.http.post<any[]>(endpoint, permissions, { responseType: 'json' });
    }

    getHubRecordsTypeAheadData(needle: string, haystack: string, includeDeleted?: boolean, businessUnitFilter?: string) {
        let endpoint = `${environment.endpoints.base}/typeahead/${haystack}/${needle}`;

        if (!!includeDeleted) {
            endpoint = endpoint + `?includeDeleted=${includeDeleted}`;
        }

        //just make another param
        if (!!businessUnitFilter) {
            endpoint = endpoint + `${!!includeDeleted ? '&' : '?'}businessUnitFilter=${businessUnitFilter}`;
            //if businessunits and if bunits length more than 1, then loop and append: &businessUnitFilter
        }

        return this.http.get<any[]>(endpoint, { responseType: 'json' })

    }

    getBulkEditTypeAheadTncUsersAndNonTncUsers(needle: string, includeInactive?: boolean, businessUnitFilter?: string) {
        const haystack = 'BulkEditTncUsersAndNonTncUsers';
        return this.getBulkEditTypeAheadData(needle, haystack, includeInactive, businessUnitFilter);
    }

    getBulkEditTypeAheadHubRecords(needle: string, includeDeleted?: boolean, businessUnitFilter?: string) {
        const haystack = 'hubRecords';

        return this.getHubRecordsTypeAheadData(needle, haystack, includeDeleted, businessUnitFilter);
    }

    getCustomOutcomesNamesAndUnits(needle: string) {
        const haystack = 'customOutcomes'
        return this.getBulkEditTypeAheadData(needle, haystack);
    }

    // #endregion User Backend

    //#region MS Graph User

    /*
        These are the current properties that are returned in this service:
            - userType,
            - givenName,
            - surname,
            - displayName,
            - department,
            - id, (aka ObjectID and oID)
            - extension_3fba2941f0c44d96b41458bf20cbae2f_employeeID,
            - JobTitle

        If additional properties are needed, you have to update them in the BE.
     */

    verifyNonTncEmployee(emailAddress: string): Observable<any> {
        const salt = (new Date()).getTime();
        const endpoint = `${environment.endpoints.base}/User/userProfile?email=${emailAddress}&${salt}`;
        return this.http.get(endpoint, { responseType: 'json' });
    }

    //#endregion
}
