<div class="hub-error-container" *ngIf="errorService.isErrorPanelVisible">

    <div *ngIf="errorService.areControlButtonsVisible" class="control-buttons">
        <button (click)="closeErrorPanel()" style="margin-right: 5px;">Close</button>
        <button (click)="clearErrors()">Clear</button>
    </div>

    <div class="error-list hub-custom-scrollbar">

        <div *ngFor="let err of errorService.visibleErrors" class="alert alert-danger" (close)="closeError(err)">
            
            <div class="error-date">{{err.timestamp?.toLocaleString('en-US')}}</div>
            <div class="error-info">
                <div class="error-message">{{err.error}}</div>
                <div class="error-detail">{{err.message}}</div>
            </div>
        </div>

    </div>

</div>