<div class="create-record content-flex-container">
<!-- Page Header -->
    <div class="hub-modal-header-text">
      <h5 class="modal-title text-success">Bulk Edit Record Status</h5>
    </div>
  <!-- Page Header End -->

  <!-- Content -->
  <div class="content">
    <div class="wizard-form steps-basic wizard clearfix height-100 content-flex-container" action="#" data-fouc="" id="steps-uid-2">
      <div class="steps clearfix">
        <ul role="tablist">
            <li [ngClass]="{done: currentStepIsValid(step.records), current: currentStep === step.records}" role="tab"
                class="first" aria-disabled="false" aria-selected="true">
              <a (click)="stepTo(step.records, $event)" id="steps-uid-2-t-0" href="#steps-uid-2-h-0"
                 aria-controls="steps-uid-2-p-0" class="">
                <span class="current-info audible">current step: </span>
                <span class="number">1</span> Hub Records
              </a>
            </li>
            <li [ngClass]="{done: currentStepIsValid(step.action), current: currentStep === step.action}" role="tab"
                class="current" aria-disabled="false" aria-selected="false">
              <a (click)="stepTo(step.action, $event)" id="steps-uid-2-t-1" href="#steps-uid-2-h-1"
                 aria-controls="steps-uid-2-p-1" class="">
                <span class="number">2</span> Bulk Action
              </a>
            </li>
            <li [ngClass]="{done: currentStepIsValid(step.confirm), current: currentStep === step.confirm}"
                role="tab" class="last" aria-disabled="false" aria-selected="false">
              <a id="steps-uid-2-t-3" (click)="stepTo(step.confirm, $event)" href="#steps-uid-2-h-3"
                 aria-controls="steps-uid-2-p-3" class="">
                <span class="number">3</span>
                Confirm
              </a>
            </li>
          </ul>
      </div>


      <form [formGroup]="bulkEditWizard" class="height-100 content-flex-container" >
        <div>
        <fieldset [ngClass]="{current: currentStep === step.records}" *ngIf="currentStep === step.records"
                  class="mb-2 p-1 sb-b body height-100" id="steps-uid-2-p-0" role="tabpanel"
                  aria-labelledby="steps-uid-2-h-0" aria-hidden="true">
              <p class="text-center font-weight-bold sb-b p-1">
                Select Hub Records
              </p>


              <div class="content-flex-container" >
                  <span class="select-label text-info-800">Hub Records</span>
                    <div class="custom-control custom-radio textSearch content-flex-container content-flex-container-space-between">
                        <div class="tokenfield form-control hub-custom-scrollbar height-20vh">
                            <div class="height-100">
                                <div class="token"
                                    *ngFor="let record of bulkEditWizard.controls.selectedHubRecords.value">
                                    <span class="token-label"><i class="icon-coin-dollar" *ngIf="record?.insightFinancials.length > 0"></i>
                                        {{record.name}} - {{record.projectId}}</span>
                                    <span class="close" (click)="unselectRecord(record)" aria-hidden="true">×</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <mat-form-field class="textSearch" >
                                <input matInput placeholder="Search Hub Records for Bulk Edit" class="textSearch input-text"  formControlName="hubRecordTypeAheadInput"/>
                            </mat-form-field>

                            <mat-form-field class="textSearch">
                                <mat-select multiple class="textSearch" formControlName="selectedHubRecords" placeholder="Select Hub Records">
                                    <mat-select-trigger class="textSearch">Select Hub Records</mat-select-trigger>
                                    <mat-option *ngFor="let record of filteredRecords | async" [value]="record">
                                        <i class="icon-coin-dollar" *ngIf="record?.insightFinancials.length > 0"></i> {{record?.name}} - {{record?.projectId}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                  <div>
                    <i class="icon-coin-dollar"></i>: indicates that there are related Active Insight Records
                </div>
              </div>
        </fieldset>
        <fieldset [ngClass]="{current: currentStep === step.action}" *ngIf="currentStep === step.action"
                    class="mb-2 p-1 sb-b body" id="steps-uid-2-p-0" role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0" aria-hidden="true">
            <div class="row">
              <div class="col-md-12">
                <p class="text-center font-weight-bold sb-b p-1">
                  Select Action
                </p>
                <div class="content-flex-containerform-group">
                  <div>
                    <span class="select-label">This Action will affect the {{bulkEditWizard.controls.selectedHubRecords.value.length}} selected records</span>
                    <div class="mt-2">

                        <div class="custom-control custom-radio"
                        *ngFor="let stat of status">
                            <input type="radio" [value]="stat.statusId"
                                class="custom-control-input" formControlName="hubRecordStatus" name="hubRecordStatus"
                                [id]="stat.statusId + 'status'">
                            <label class="custom-control-label" [for]="stat.statusId + 'status'">{{stat.name}} Selected Records</label>
                        </div>
                    </div>

                  </div>
                  <div class="mt-2 select-label">Both actions will result in a closed Hub Record and cannot be used for a new Insight Record relation.  Additional Insight record clean up may be needed.  Canceled Record information will still display/rollup in the application and the Hub Dashboards</div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset [ngClass]="{current: currentStep === step.confirm}" *ngIf="currentStep === step.confirm"
                    class="mb-2 p-1 sb-b body height-100" id="steps-uid-2-p-0" role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0" aria-hidden="true">
                 <div class="col-md-12 height-100 content-flex-container">
                    <p class="text-center font-weight-bold sb-b p-1">
                        The following {{bulkEditWizard.controls.selectedHubRecords.value.length}} records will be {{!!bulkEditWizard.controls.hubRecordStatus.value ? (bulkEditWizard.controls.hubRecordStatus.value === 1005 ? 'Cancelled' : 'Deleted') : '' }}.
                    </p>
                    <div class="confirmation-scrollbar-container">
                        <div class="hub-custom-scrollbar">
                            <div *ngFor="let record of bulkEditWizard.controls.selectedHubRecords.value">
                                <span *ngIf="record?.insightFinancials > 0"><i class="icon-coin-dollar"></i></span>{{record?.name}} - {{record?.projectId}}
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <i class="icon-coin-dollar"></i>: indicates that there are related Active Insight Records
                    </div>
                </div>
          </fieldset>
        </div>

          <div class="actions clearfix p-1">
            <ul role="menu" class="flex-button-container"aria-label="Pagination">
              <li aria-disabled="false" class="float-left">
                <button (click)="prevStep($event)" href="#previous" [disabled]="currentStep === step.records"
                        role="menuitem">
                  <b>
                    <i class="icon-arrow-left13 mr-2"></i> Previous
                  </b>
                </button>
              </li>
              <li *ngIf="currentStep!==step.confirm" aria-hidden="false" aria-disabled="false"
                  class="float-right">
                <button (click)="nextStep()" [disabled]="!currentStepIsValid()"
                        class="hub-icon-button mirrored">
                  Next
                  <b>
                    <i class="icon-arrow-right14 ml-2"></i>
                  </b>

                </button>
              </li>
              <li *ngIf="currentStep===step.confirm" class="float-right">
                <button (click)="saveEdit()" className="hub-icon-button SaveButton" [disabled]="this.bulkEditWizard.invalid">
                    Complete Edit
                </button>
              </li>
            </ul>
          </div>

    </form>
    <div class="modal-footer hub-modal-footer-text">
        <button type="button" class="cancel-button" (click)="cancelDialog()">Cancel</button>
      </div>
    </div>


    </div>
    </div>
