<div class="tnc-goals-parent-selection-panel-container" *ngIf="parentOutcomeIdControl && availableParents" >
    <div class="row">
        <div class="col-md-12">
            <mat-radio-group [formControl]="parentOutcomeIdControl">
                <mat-radio-button class="parent-outcome-radio-button" name="parent" [value]="null" color="primary">Not Linked to a Strategy Outcome</mat-radio-button>    
                <hr />
    
                <div *ngIf="availableParents.length; else noRelations">
    
                    <div class="mb-2">
                        <strong>Related Strategies and their Outcomes</strong>
                    </div>
    
                    <div *ngFor="let availableParent of availableParents">
                        <h4 class="text-success">
                            <strong>{{availableParent.strategyName}}</strong> 
                            ({{availableParent.strategyType}}) 
                            <span class="text-muted" style="font-size: 11pt;">{{availableParent.relationShipType}}</span>
                        </h4>
    
                        <mat-radio-button class="parent-outcome-radio-button" *ngFor="let projectOutcome of availableParent.projectOutcomes"
                            name="parent" color="primary" [value]="projectOutcome.outcome.outcomeId">
                            <strong>{{projectOutcome.outcome.outcomeMetric.name}}</strong> ({{projectOutcome.outcome.outcomeUnit.name}})<br>
                            <span class="text-muted">{{projectOutcome.statement}}</span>
                        </mat-radio-button>
                    </div>
    
                </div>
            </mat-radio-group>

            <ng-template #noRelations>

                <div class="no-relations">
                    <div class="alert alert-info alert-styled-left shadow-0 mr-3 ng-star-inserted" style="margin-top: 10px;">
                        You currently do not have any Related Strategies and/or your Related Strategies have no Outcomes of the same type as yours.
                    </div>
                </div>

            </ng-template>

            
            
        </div>
    </div>
</div>
