<div class="p-2">
    <h5 class="edit-section-header">Mapped Insight Records <app-help-selector [selector]="1135"></app-help-selector>
    </h5>
    <p *ngIf="project.insightRecords.length">The list below displays all currently associated Insight Records to the
        Conservation Record. </p>
</div>

<div class=" p-2 ">
    <div *ngIf="!project.insightRecords.length" class="d-block alert alert-info alert-styled-left shadow-0">
        There are no Insight records associated yet with this Conservation Hub Record.
    </div>
</div>

<ul class="section-list left ml-1 mr-2" id="lstInsightRecords">
    <li class="sb-b" *ngFor="let record of project.insightRecords; let i = index;" style="cursor: default;">
        <div class="mr-2" style="display: flex; flex-direction: column;">
            <div *ngIf="record.anchorRecordFlag === true" class="btn btn-dark rounded-round btn-icon legitRipple" style="cursor: default;">
                <i class="icon-anchor"></i>
            </div>
            <div class="btn rounded-round btn-icon legitRipple mt-1" style="cursor: default;"
                [ngClass]="{'btn-light': record.insightProjectStatus ==='Closed', 'btn-primary': record.insightProjectStatus === 'Active'}">
                <i class="icon-coins"></i>
            </div>
        </div>
        <div class="media-body">
            <div class="d-md-flex justify-content-between">
                <div class="media-title">
                    <span *ngIf="record.anchorRecordFlag === true" class="badge badge-dark mb-1">Anchor Record</span>
                    <span class="d-block font-weight-semibold"
                        [ngClass]="{'text-muted': record.insightProjectStatus ==='Closed', 'text-primary': record.insightProjectStatus === 'Active'}">{{record.insightProjectId}}
                        - {{record.insightProjectName}}</span>
                    <span class="d-block"><span class="text-muted">Owned by Business Unit:</span>
                        {{record.insightProjectBusinessUnit}}</span>
                    <span class="text-success d-block font-weight-bold">{{record.insightProjectRecordType}} <span
                            class="text-dark font-weight-bold">({{record.insightProjectStatus}})</span></span>
                </div>
                <div class="align-self-center ml-3"> </div>
            </div>
        </div>
    </li>
</ul>
