export class MapLocation {
  public latitude: number;
  public longitude: number;
  public address: string;

  constructor() {
    this.latitude = 0;
    this.longitude = 0;
    this.address = '';
  }
}
