<div class="save-filter-container">
    <div class="hub-modal-header-text">
        <h2 class="modal-title">
            <span class="swal2-title" id="swal2-title">Save your current filters</span>
        </h2>
    </div>
    <div class="content" [formGroup]="saveFiltersForm">
        <input class="form-control" formControlName="name" placeholder="Filter name" type="text">
    </div>
    <div class="hub-modal-footer-text">
        <button (click)="cancel()" class="cancel-button">Cancel</button>
        <button (click)="save()" [disabled]="saveFiltersForm.invalid">Save</button>
    </div>
</div>
