import { Injectable, inject } from '@angular/core';
import { HubLovService } from '../../../../core/services/hub-lov.service';
import { AttributionLevelModel } from '../../../../../hub_schema/hubTypes';
import { Observable } from 'rxjs';

@Injectable()
export class OutcomesLovService {
    private hubLovService: HubLovService = inject(HubLovService);

    public getAttributionLevels(): Observable<AttributionLevelModel[]> {
        return this.hubLovService.getAttributionLevels();
    }
}
