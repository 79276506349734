import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ProjectInfoModel, ProjectTypeModel, StatusModel } from '../../../hub_schema/hubTypes';
import { HttpService } from './http.service';
import { HubLovService } from './hub-lov.service';

@Injectable()
export class ProjectLovService {
    private hubLovService: HubLovService = inject(HubLovService);

    private _records: any;
    private _metrics: any;
    private _projectCollections: any;

    public getProjectStatuses(): Observable<StatusModel[]> {
        return this.hubLovService.getStatuses();
    }

    public getProjectTypes(): Observable<ProjectTypeModel[]> {
        return this.hubLovService.getProjectTypes();
    }

}
