                <!-- * When Greenhouse Gas Mitigation is selected, then show the Yes/No toggle that offers
                        * up the NCS Calculator -->
                        <div *ngIf="isNcsCalculatorToggleShowing" class="current mb-2 mt-2 p-1 body" role="tabpanel" aria-hidden="true">                    
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="ncs-calculator-toggle-panel">
                                        <div class="select-label text-info-800 d-block">
                                            Would you like to use the Hub's NCS Calculator? <app-help-selector
                                                class="app-help-selector-raised"
                                                selector="1207"></app-help-selector>
                                        </div>
                                        <div class="toggle-panel">
                                            <span>NO</span>
                                            <mat-slide-toggle color="primary" [(ngModel)]="isUsingNcsCalculator" [ngModelOptions]="{standalone: true}">
                                            </mat-slide-toggle>
                                            <span>YES</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <!-- * Get NCS Pathway and Intervention -->
                        <div *ngIf="areNcsCalculatorControlsShowing" class="mb-2 mt-2 p-1 body"
                            role="tabpanel" aria-hidden="true">
        
                            <div class="row">
                                <div class="col-md-12">
                                    <app-ncs-intervention-panel></app-ncs-intervention-panel>
                                </div>
                            </div>
                        </div>
        