import { ProjectModel } from "../../../hub_schema/hubTypes";
import { HubRecordTypes } from '../../shared/types/hub-record-types';

export class HubRecordPresenterBase {
    protected project: ProjectModel;

    public get isProject(): boolean {
        return this.project?.projectType.name == HubRecordTypes.Project;
    }
    
    public get isStrategy(): boolean {
        return this.project?.projectType.name == HubRecordTypes.Strategy;
    }

    public get isWholeSystem(): boolean {
        return this.project?.projectType.name == HubRecordTypes.WholeSystem;
    }
    
    public get isProgramManagement(): boolean {
        return this.project?.projectType.name == HubRecordTypes.ProgramManagement;
    }
}