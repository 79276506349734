import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NcsPathwayModel } from '../../../../../../hub_schema/hubTypes';
import { OutcomeState } from '../types/outcome-state';

@Injectable()
export class CreateOutcomeStateService {
    public outcomeState: OutcomeState | undefined;

    public initialize(data: any): OutcomeState {
        this.outcomeState = new OutcomeState(data.projectId, data.isStrategy);
        return this.outcomeState;
    }

    public dispose(): void {
        this.outcomeState!.dispose();
        this.outcomeState = undefined;
    }
}
