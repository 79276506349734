export const getCurrentFiscalYear = (d = new Date()): number => {
    // const d = new Date();
    const curYear = d.getFullYear();
    const curMonth = d.getMonth();

    if (curMonth > 5) {
        return curYear + 1;
    } else {
        return curYear;
    }
};
