import { Component, Input, OnInit, inject } from '@angular/core';
import { OutcomesEditService } from '../../services/outcomes-edit.service';
import { FormControl, FormGroup } from '@angular/forms';
import { AvailableParentProjectOutcomesModel, ProjectOutcomeModel } from '../../../../../../hub_schema/hubTypes';
import { take } from 'rxjs';

@Component({
  selector: 'app-tnc-goals-parent-selection-panel',
  templateUrl: './tnc-goals-parent-selection-panel.component.html',
  styleUrls: ['./tnc-goals-parent-selection-panel.component.scss']
})
export class TncGoalsParentSelectionPanelComponent implements OnInit {
    private outcomesEditService: OutcomesEditService = inject(OutcomesEditService);

    @Input()
    public projectOutcome: ProjectOutcomeModel;

    @Input()
    public outcomeWizardForm: FormGroup;

    public get parentOutcomeIdControl(): FormControl {
        return (this.outcomeWizardForm.controls.outcome as FormGroup).controls.parentOutcomeId as FormControl;
    }

    public availableParents: AvailableParentProjectOutcomesModel[];

    public ngOnInit(): void {
        this.outcomesEditService.getAvailableParentOutcomes(this.projectOutcome.projectId, this.projectOutcome.outcome!.outcomeId)
            .pipe(take(1)).subscribe((availableParents: AvailableParentProjectOutcomesModel[]) => {
                this.availableParents = availableParents;
            });
    }

    public getParentOutcomeStatement(): string {
        const parentOutcomeId = this.parentOutcomeIdControl.value;

        if (!this.availableParents) {
            return '';
        }

        if (parentOutcomeId) {
            const projectOutcomes = this.availableParents.flatMap(ap => ap.projectOutcomes);
            const projectOutcome = projectOutcomes.find(po => po.outcome?.outcomeId === parentOutcomeId);
            return projectOutcome?.statement || '';
        }

        return '';
    }
}
