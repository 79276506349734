import { Injectable, inject } from '@angular/core';
import { HttpService } from '../../../../core/services/http.service';
import { environment } from '../../../../../environments/environment';
import { ProjectStrategicPillarModel } from '../../../../../hub_schema/hubTypes';
import { Observable } from 'rxjs';

@Injectable()
export class StrategicPillarEditService {
    private httpService: HttpService = inject(HttpService);

    public createProjectStrategicPillar(pillar: ProjectStrategicPillarModel): Observable<ProjectStrategicPillarModel> {
        const url = environment.endpoints.base + '/ProjectStrategicPillar/';
        return this.httpService.post<ProjectStrategicPillarModel>(url, pillar);
    }

    public updateProjectStrategicPillar(pillar: ProjectStrategicPillarModel): Observable<ProjectStrategicPillarModel> {
        const url = environment.endpoints.base + '/ProjectStrategicPillar/';
        return this.httpService.patch<ProjectStrategicPillarModel>(url, pillar);
    }

    public deleteProjectStrategicPillar(pillar: ProjectStrategicPillarModel): Observable<void> {
        const url = environment.endpoints.base + '/ProjectStrategicPillar/' + pillar.projectStrategicPillarId;
        return this.httpService.delete(url);
    }
  
}
