// tslint:disable: max-classes-per-file
import { ISavedFacet } from 'src/app/shared/types/interfaces';
import { Facet } from '../../shared/types/algolia-api/facet';
import { CheckboxFilter } from '../../shared/types/algolia-api/filter-checkbox';
import { OutcomeMetricModel } from '../../../hub_schema/hubTypes';

export class Outcome2030GoalsMetricFacet extends Facet {
    private outcomeMetrics: OutcomeMetricModel[];
    public metricTree: OutcomeMetricTree;

    constructor(isExpanded: boolean, outcomeMetrics: OutcomeMetricModel[]) {
        super('advanced', 'organizationalScaMetrics.lvl1', '2030 Goals', 'checkbox', isExpanded, true);

        this.outcomeMetrics = this.populateSubArea(outcomeMetrics);
        this.metricTree = new OutcomeMetricTree();

        // build up hierarchical list of subareas / metrics
        for (const metric of this.outcomeMetrics) {

            let subArea = this.metricTree.subAreas.find((sa) => sa.name === metric.focalThemeSubArea);

            if (!subArea) {
                subArea = new SubArea(metric.focalThemeSubArea);
                this.metricTree.subAreas.push(subArea);
            }

            subArea.metrics.push(new SelectableMetric(metric.name, subArea));
        }
    }

    /**
     * Method to select all options when the user clicks "Select All.""
     */
    public override selectAll(): void {
        super.selectAll();
        for (const subArea of this.metricTree.subAreas) {
            for (const metric of subArea.metrics) {
                metric.isChecked = true;
            }
        }
    }

    public clearMetric(metric): void {

        // clear from facet
        const existingFilter = this.filters.find(f => f.title === metric.subArea.name + ' > ' + metric.name);

        if (existingFilter) {
            (existingFilter as CheckboxFilter).isChecked = false;
        }

        // clear from metric tree
        const subArea = this.metricTree.subAreas.find(sa => sa.name === metric.subArea.name);
        const existingMetric = subArea.metrics.find(m => m.name === metric.name);

        if (existingMetric) {
            (existingMetric as any).isChecked = false;
        }

    }

    /**
     * Method to deselect all options when the user clicks "Clear All.""
     */
    public override clearAll(): void {
        super.clearAll();

        for (const subArea of this.metricTree.subAreas) {
            for (const metric of subArea.metrics) {
                metric.isChecked = false;
            }
        }
    }

    /**
     * Method that overrides the same in the facet module. In this override
     * the checkboxes are re-checked after a user has left the portfolio search
     * and then returns to it.
     * @param savedFacet Saved facet value (ISavedFacet)
     */
    public override loadFromSaved(savedFacet: ISavedFacet): void {

        for (const savedFilter of savedFacet.activeFilters) {
            const existingFilter = this.filters.find((f) => f.title === savedFilter.title);
            if (existingFilter) {
                existingFilter.makeActive();

                // Need to check the checkboxes of the items that are not directly tied to facets from Algolia
                const parts = existingFilter.title.split('>');
                const lovLvl0 = parts[0].trim();
                const lovLvl1 = parts[1].trim();
                const subArea = this.metricTree.subAreas.find((sa) => lovLvl0 === sa.name);
                if (subArea) {
                    const metric = subArea.metrics.find((m) => lovLvl1 === m.name);
                    metric.isChecked = true;
                }
            }
        }
    }

    private populateSubArea(outcomeMetrics: OutcomeMetricModel[]): OutcomeMetricModel[] {
        const metrics: OutcomeMetricModel[] = [...outcomeMetrics];
        for (const metric of metrics) {
            if (!metric.focalThemeSubArea) {
                metric.focalThemeSubArea = metric.focalTheme;
            }
        }
        return metrics;
    }
}


export class SelectableMetric {
    public name: string;
    public isChecked: boolean;
    public subArea: SubArea;

    public constructor(name: string, subArea: SubArea) {
        this.name = name;
        this.isChecked = false;
        this.subArea = subArea;
    }
}

export class SubArea {
    public name: string;
    public metrics: SelectableMetric[];

    public constructor(name: string) {
        this.name = name;
        this.metrics = [];
    }
}

export class OutcomeMetricTree {
    public subAreas: SubArea[] = [];
}
