<div *ngIf="addProjectToCollectionForm" class="add-project-to-collection-dialog-container">
    <div class="hub-modal-header-text">
        <h5 class="modal-title text-success">Add '{{projectName}}' to a Record Collection</h5>
    </div>

    <div class="content">
        <form [formGroup]="addProjectToCollectionForm">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div *ngIf="collections">
                            <span class="select-label text-info-700 d-block">Add this record to an existing Record
                                Collection</span>

                            <div class="hub-select-container">
                                <mat-form-field *ngIf="collections.length; else empty">
                                    <mat-select placeholder="Select a Record Collection..." disableOptionCentering
                                        formControlName="recordCollectionId">
                                        <mat-option *ngFor="let collection of collections" [value]="collection.recordCollectionId">
                                            <span>
                                                {{collection.name}} ({{collection.recordCollectionType.name}})
                                            </span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <ng-template #empty>
                                <span class="text-danger d-block">You currently do not have any Record Collections, you
                                    have to create one first before you can add a record.</span>
                            </ng-template>

                            <div class="hub-validation-error-message">
                                {{getValidationErrorMessages("recordCollectionId")}}
                            </div>


                        </div>
                        <span class="select-label text-info-700 d-block mt-3 pt-2 sb-t">Create a new Record
                            Collection</span>

                        <button (click)="openCreateRecordCollectionDialog()"
                            [disabled]="addProjectToCollectionForm.controls.recordCollectionId.value" type="button"
                            style="margin-top: .625rem;">Create Collection</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="hub-modal-footer-text">
        <button type="button" class="cancel-button" (click)="close()">Cancel</button>
        
        <button type="button" [disabled]="addProjectToCollectionForm.invalid"
            (click)="save()">Add</button>
    </div>

</div>