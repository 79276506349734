import { Component, inject, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subscription, debounceTime, distinctUntilChanged, map, of, switchMap, take, tap } from 'rxjs';
import { ProjectNameLookupService } from './services/project-name-lookup.service';
import { ProjectInfoModel } from '../../../../hub_schema/hubTypes';

@Component({
  selector: 'app-project-name-suggestions',
  templateUrl: './project-name-suggestions.component.html',
  styleUrls: ['./project-name-suggestions.component.scss']
})
export class ProjectNameSuggestionsComponent implements OnInit, OnDestroy {
    // dependencies
    private projectNameLookupService: ProjectNameLookupService = inject(ProjectNameLookupService);

    @Input()
    public initialValue: string;

    @Input()
    public formGroup: FormGroup;

    private get isNewTabOpeningEnabled(): boolean {
        return !this.initialValue;
    }

    private projectNameStatusChangeSubscription: Subscription;

    @Output()
    public projectNameValidityChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    public get isValid(): boolean {
        return this.projectNameControl.valid;
    }

    private projectsFound: ProjectInfoModel[];

    public get projectNameControl(): FormControl {
        return this.formGroup.controls.name as FormControl;
    }

    public projectSuggestions$: Observable<string[]>;

    public ngOnInit(): void {
        if (!this.initialValue) {
            this.initialValue = '';
        }

        this.projectNameControl.clearValidators();
        this.projectNameControl.addValidators([Validators.required, Validators.maxLength(100), this.validateUniqueRecordName.bind(this)])

        this.projectSuggestions$ = this.projectNameControl.valueChanges.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap((value) => {                
                return !value || value.projectId || value.length < 2 || value.trim().toLowerCase() === this.initialValue.trim().toLowerCase()
                    ? of([])
                    : this.projectNameLookupService.getProjectNameSuggestions(value).pipe(
                        tap((result: ProjectInfoModel[]) => {
                        this.projectsFound = result;
                    }),
                    map(projects => projects.map(p => p.name))
                )
            })
        );
        
        this.projectNameStatusChangeSubscription = this.projectNameControl.statusChanges.subscribe((status: string) => {
            this.projectNameValidityChanged.emit(status === 'VALID');
        });
    }
    
    private validateUniqueRecordName(formControl): ValidationErrors | null {
        if (!this.projectsFound) {
            return null;
        }

        const recordName = formControl.value.name ?
            formControl.value.name.toLowerCase()
            : formControl.value.toLowerCase();

        if (recordName === this.initialValue?.toLowerCase()) {
            return null;
        }

        const matchingRecordNames = this.projectsFound.map(h => h.name.toLowerCase());

        if (matchingRecordNames.includes(recordName)) {
            return {
                inUse: 'Record name already in use'
            };
        }

        return null;
    }

    public getProjectNameErrorMessage(): string {           
        if (this.projectNameControl.dirty) {
            if (this.projectNameControl.invalid) {
                const errors = this.projectNameControl.errors!;
                if (errors.maxlength) {
                    return 'Name cannot exceed 100 characters';
                }
                if (errors?.inUse) {
                    return errors.inUse;
                }
                return Object.keys(errors)[0]
            }
        }
        return '';
    }

    public projectNameClicked(e: MatAutocompleteSelectedEvent) {
        const projectName = e.option.value.trim().toLowerCase();
        if (this.isNewTabOpeningEnabled && projectName !== this.initialValue.trim().toLowerCase()) {
            const project = this.projectsFound.find(p => p.name.trim().toLowerCase() === projectName);

            if (project) {
                this.openInNewTab('/portfolio/' + project.projectId);
            }            
        }        
    }

    public openInNewTab(url): void {
        const win = window.open(url, '_blank');
        win!.focus();
    }

    public ngOnDestroy(): void {
        if (this.projectNameStatusChangeSubscription) {
            this.projectNameStatusChangeSubscription.unsubscribe();
        }
    }
}
