import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { OutcomesEditService } from '../services/outcomes-edit.service';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { NcsGeographyInterventionModel, NcsInterventionModel } from '../../../../../hub_schema/hubTypes';

@Component({
  selector: 'app-ncs-estimates-panel',
  templateUrl: './ncs-estimates-panel.component.html',
  styleUrls: ['./ncs-estimates-panel.component.scss']
})
export class NcsEstimatesPanelComponent implements OnInit, OnDestroy {
    // dependencies
    private outcomesEditService: OutcomesEditService = inject(OutcomesEditService);
    private dialogService: MatDialog = inject(MatDialog);

    private _ncsInterventionId: number;

    public get ncsInterventionId(): number {
        return this._ncsInterventionId;
    } 

    @Input()
    public set ncsInterventionId(value: number) {
        this._ncsInterventionId = value;
    }

    public intervention: NcsInterventionModel;
    public ncsGeographyIntervention: NcsGeographyInterventionModel;

    @Input()
    public ncsCountryId: number;

    private _extent: number;

    public get extent(): number {
        return this._extent;
    }

    @Input()
    public set extent(value) {
        this._extent = value;
        this.recalculate();
    }

    @Input()
    public calculateMessage = 'RECALCULATE';

    @Input()
    public metricType: string; // 'Start', 'Progress' or 'Target'

    private metricValue: number;

    @Output()
    public metricValueChanged: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    public pendingRecalculationChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    public estimatesForm: FormGroup;
    private maxPrecision: number = 0;

    public geographyIntervention: NcsGeographyInterventionModel | null;
    public baselineValue: number | null;

    private formValueChangeSubscription: Subscription;

    public ngOnInit(): void {
        this.outcomesEditService.getNcsGeographyInterventionData(this.ncsCountryId, this.ncsInterventionId).pipe(take(1)).subscribe((result: any) => {
            this.intervention = result.ncsIntervention;
            this.geographyIntervention = result.ncsGeographyIntervention;
            this.baselineValue = this.geographyIntervention ? this.geographyIntervention.baselineValue! : 0;

            this.estimatesForm = new FormGroup({
                pools: new FormArray([]),
                baselinePercent: new FormControl(this.geographyIntervention ? this.geographyIntervention.baselineValue : 0, [Validators.min(0), Validators.max(100)])
            });

            for (const pool of this.intervention.pools!) {
                const geoPool = this.geographyIntervention
                    ? this.geographyIntervention.geographyInterventionPools!.find(gip => gip.pool!.poolId === pool.poolId)
                    : null;

                const poolGroup = new FormGroup({
                    poolId: new FormControl(pool.poolId),
                    poolName: new FormControl(pool.name),
                    poolTitle: new FormControl(`${pool.description} (${pool.unit})`),
                    estimate: new FormControl(geoPool ? geoPool.defaultValue : null)
                });
                (this.estimatesForm.controls.pools as FormArray).push(poolGroup);
            }

            const calculatedTargetValue = this.calculateTargetValue();
            this.metricValue = calculatedTargetValue;
            this.metricValueChanged.emit(this.metricValue);
            this.isRecalculateButtonDisabled = true;
            this.pendingRecalculationChanged.emit(false);

            this.formValueChangeSubscription = this.estimatesForm.valueChanges.subscribe(() => {
                this.isRecalculateButtonDisabled = false;
                this.pendingRecalculationChanged.emit(true);
            });
        });
    }

    public get poolsArray(): FormArray {
        return this.estimatesForm.controls.pools as FormArray;
    }

    public isEstimateRevertButtonVisible(estimateForm: FormGroup): boolean {
        const estimate = estimateForm.controls.estimate.value;
        const poolId = estimateForm.controls.poolId.value;
        const defaultValue = this.geographyIntervention ? this.geographyIntervention.geographyInterventionPools!.find(p => p.pool!.poolId == poolId)?.defaultValue : null;
        return estimate != defaultValue;
    }

    public revertEstimate(estimateForm): void {
        const poolId = estimateForm.controls.poolId.value;
        const defaultValue = this.geographyIntervention ? this.geographyIntervention.geographyInterventionPools!.find(p => p.pool!.poolId == poolId)?.defaultValue : null;
        estimateForm.controls.estimate.setValue(defaultValue);
    }

    public get editedBaseline(): number {
        const baseline = this.estimatesForm.controls.baselinePercent.value;
        return baseline ? parseFloat(baseline) : 0;
    }

    public getEditedPool(key: string): any {
        const editedPools: any[] = this.estimatesForm.controls.pools.value;
        return editedPools.find(p => p.poolName === key);
    }

    public getEditedPoolValue(key: string): number {
        const editedPool = this.getEditedPool(key);
        return editedPool?.estimate ? parseFloat(editedPool.estimate) : 0;
    }

    public isBaselineRevertButtonVisible(): boolean {
        var editedBaseline = this.editedBaseline;
        return editedBaseline != this.baselineValue;
    }

    public revertBaseline(): void {
        this.estimatesForm.controls.baselinePercent.setValue(this.baselineValue);
    }

    public isRecalculateButtonDisabled: boolean;

    private calculateTargetValue(): number {
        let total = 0;
        let maxPrecision: number = 0;

        if (!this.intervention) {
            return 0;
        }

        switch (this.intervention.interventionId) {
            case 1000: {
                // User defined extent x historic loss/100 x SUM[(AGB+BGB+SOC)*44/12]

                const agb = this.getEditedPoolValue('AGB');
                maxPrecision = this.getNumberOfSignificantDigits(agb);

                const bgb = this.getEditedPoolValue('BGB');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(bgb));

                const soc = this.getEditedPoolValue('SOC');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(soc));

                total = this.extent * (this.editedBaseline / 100) * ((agb + bgb + soc) * (44 / 12));
                break;
            }
            case 1001:
            case 1009:
            case 1015:
            case 1018: {
                // User defined extent x historic loss/100 x SUM[(AGB+BGB+SOC)*44/12]
                const agb = this.getEditedPoolValue('AGB');
                maxPrecision = this.getNumberOfSignificantDigits(agb);

                const bgb = this.getEditedPoolValue('BGB');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(bgb));

                const soc = this.getEditedPoolValue('SOC');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(soc));

                const ch4 = this.getEditedPoolValue('CH4');
                maxPrecision = Math.max(this.getNumberOfSignificantDigits(ch4));

                const n2o = this.getEditedPoolValue('N2O');
                maxPrecision = Math.max(this.getNumberOfSignificantDigits(n2o));

                total = this.extent * (this.editedBaseline / 100) * (((agb + bgb + soc) * (44 / 12)) + (ch4 * 34) + (n2o * 298));
                break;
            }
            case 1002:
            case 1003:
            case 1004: {
                // User defined extent x historic loss/100 x SUM[(SOC)*44/12+CH4*34]
                const soc = this.getEditedPoolValue('SOC');
                maxPrecision = this.getNumberOfSignificantDigits(soc);

                const ch4 = this.getEditedPoolValue('CH4');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(ch4));

                total = this.extent * (this.editedBaseline / 100) * (soc * (44 / 12) + (ch4 * 34));
                break;
            }
            case 1005:
            case 1016:
            case 1019: {
                // User defined extent x [100-historic adoption]/100 x SUM[SOC*44/12+CH4*34 + N2O*298]
                const soc = this.getEditedPoolValue('SOC');
                maxPrecision = this.getNumberOfSignificantDigits(soc);

                const ch4 = this.getEditedPoolValue('CH4');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(ch4));

                const n2o = this.getEditedPoolValue('N2O');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(n2o));

                total = this.extent * ((100 - this.editedBaseline) / 100) * ((soc * (44 / 12)) + (ch4 * 34) + (n2o * 298));
                break;
            }
            case 1006:
            case 1007:
            case 1008:
            case 1011:
            case 1012:
            case 1013:
            case 1017:
            case 1020: {
                // User defined extent x [100-historic adoption]/100 x SUM[(AGB+BGB+SOC)*44/12]
                const agb = this.getEditedPoolValue('AGB');
                maxPrecision = this.getNumberOfSignificantDigits(agb);

                const bgb = this.getEditedPoolValue('BGB');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(bgb));

                const soc = this.getEditedPoolValue('SOC');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(soc));

                total = this.extent * ((100 - this.editedBaseline) / 100) * ((agb + bgb + soc) * (44 / 12));
                break;
            }

            case 1010: {
                // User defined extent x (100-background recovery)/100 x SUM[(AGB+BGB+SOC)*44/12+CH4*34+N2O*298]
                const agb = this.getEditedPoolValue('AGB');
                maxPrecision = this.getNumberOfSignificantDigits(agb);

                const bgb = this.getEditedPoolValue('BGB');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(bgb));

                const soc = this.getEditedPoolValue('SOC');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(soc));

                const ch4 = this.getEditedPoolValue('CH4');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(ch4));

                const n2o = this.getEditedPoolValue('N2O');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(n2o));

                total = this.extent * ((100 - this.editedBaseline) / 100) * ((agb + bgb + soc) * (44 / 12) + (ch4 * 34) + (n2o * 298));
                break;
            }
            case 1014: {
                // User defined extent x [100-background recovery]/100 x SUM[SOC*44/12+CH4*34]
                const soc = this.getEditedPoolValue('SOC');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(soc));

                const ch4 = this.getEditedPoolValue('CH4');
                maxPrecision = Math.max(maxPrecision, this.getNumberOfSignificantDigits(ch4));

                total = this.extent * ((100 - this.editedBaseline) / 100) * ((soc * (44 / 100)) + (ch4 * 34));
                break;
            }

            default:  // User defined extent x historic loss x SUM[(AGB+BGB+SOC)*44/12+CH4*34]
                total = 0;
                break;
        }

        this.maxPrecision = maxPrecision;
        const result = this.roundToNDecimalPlaces(total, maxPrecision);
        return result;
    }

    public recalculate(): void {
        const calculatedTargetValue = this.calculateTargetValue();
        this.metricValue = calculatedTargetValue;
        this.metricValueChanged.emit(calculatedTargetValue);
        this.isRecalculateButtonDisabled = true;
        this.pendingRecalculationChanged.emit(false);
    }

    private getNumberOfSignificantDigits(estimate: number): number {
        if (Number.isInteger(estimate)) {
            return 0;
        }
        return estimate.toString().split('.')[1].length;
    }

    private roundToNDecimalPlaces(number, decimalPlaces): number {
        return parseFloat(number.toFixed(decimalPlaces));
    }

    public ngOnDestroy(): void {
        if (this.formValueChangeSubscription) {
            this.formValueChangeSubscription.unsubscribe();
        }
    }
}
