<div class="detail-panel p-2 pl-3" style="height: calc(100vh - 257px);">
    <h3 class="text-success"><b>Strategic Pillar</b> - Details</h3>

    <div class="d-flex flex-wrap align-content-stretch" style="bottom: 0px;">
      <div class="bg-white p-1" style="flex: 100%;" >

        <div [formGroup]="strategicPillarForm" *ngIf="strategicPillarForm">
          <div class="col-md-12 pt-2">
            <span class="select-label text-info-700 pb-2 d-block">Strategic Pillar Title<app-help-selector [selector]="1049"></app-help-selector></span>
            <input type="text" class="form-control mb-2" formControlName="name" placeholder="Add a Strategic Pillar Title" tabindex="0">
          </div>

          <div class="hub-validation-error-message">
            {{getTitleErrorMessage()}}
          </div>

          <div class="col-md-12">
            <span class="select-label text-info-700 pb-2 d-block">Strategic Pillar Description<app-help-selector [selector]="1050"></app-help-selector></span>
            <textarea class="record-description" rows="3" cols="50" placeholder="Enter a description here" formControlName="description" maxlength="500" class="form-control mb-2"></textarea>
          </div>

          <div class="hub-validation-error-message">
            {{getDescriptionErrorMessage()}}
          </div>
          <div class="col-md-12">
              <span class="select-label text-info-700 pb-2 d-block">Strategic Pillar Related Projects<app-help-selector [selector]="1051"></app-help-selector></span>

              <div class="tokenfield form-control">
                  <div class="token" *ngFor="let item of getRelatedProjects()">
                      <span class="token-label">{{item.relatedProject.name}}</span>
                      <span class="close" (click)="removeRelatedProject(item)" aria-hidden="true">×</span>
                  </div>
              </div>

              <div class="hub-select-container">
                  <mat-form-field>
                      <input type="text"
                             #searchBox
                             matInput
                             [formControl]="relatedProjectsTypeahead"
                             [matAutocomplete]="auto"
                             placeholder="Enter a name of related project here..." />
                  </mat-form-field>

                  <mat-autocomplete #auto="matAutocomplete"
                                    [displayWith]="displayRelatedProject"
                                    (optionSelected)="relatedProjectSelected($event)">
                      <mat-option [disabled]="isRelatedProjectDisabled(proj)"
                                  *ngFor="let proj of relatedProjectsSuggestions$ | async" [value]="proj">
                          {{proj.name}}
                      </mat-option>
                  </mat-autocomplete>
              </div>


              <div class="hub-validation-error-message" *ngIf="!hasRelations">
                  You must add a project as a core project on the Relations tab before adding it to a Strategic Pillar.
              </div>

          </div>
          <div class="p-2">
              <button class="btn btn-primary btn-labeled btn-labeled-left legitRipple SaveButton" [ngClass]="{'saving': isSaving}"
                      [disabled]="strategicPillarForm.invalid || strategicPillarForm.pristine || isSaving" (click)="updateStrategicPillar()">
                  <b>
                      <i class="icon-floppy-disk"></i>
                      <i class="icon-spinner4"></i>
                  </b>
                  Save
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
