<div class="details">
    <div class="row">
        <div class="col-md-6">
            <h4 class="hub-level-1-label">Related Strategies</h4>
            <div class="hub-pill-list" *ngIf="strategyRelations.length">
                <span *ngFor="let relation of strategyRelations; trackBy: trackByFn;">
                    <a *ngIf="!relation.isPrimary" [routerLink]="'/portfolio/' + relation.relatedProject?.projectId"
                        class="badge-prio-strategy badge font-size-lg mr-1 mb-1">{{relation.relatedProject?.name}}</a>
                </span>
            </div>

            <div class="no-relations-warning" *ngIf="!strategyRelations.length">
                This Whole System does not have any relations to Strategies.
            </div>


            <h4 class="hub-level-1-label">Related Projects</h4>

            <div class="hub-pill-list" *ngIf="projectRelations.length">
                <span *ngFor="let relation of projectRelations; trackBy: trackByFn;">
                    <a *ngIf="!relation.isPrimary" [routerLink]="'/portfolio/' + relation.relatedProject?.projectId"
                        class="badge-prio-strategy badge font-size-lg mr-1 mb-1">{{relation.relatedProject?.name}}</a>
                </span>
            </div>

            <div class="no-relations-warning" *ngIf="!projectRelations.length">
                This Whole System does not have any relations to Projects.
            </div>
        </div>

        <div class="col-md-6">

            <h5 class="hub-level-1-label">Region(s)</h5>

                <ul class="hub-bulleted-list">
                    <li *ngFor="let regionName of regionNames">
                        {{ regionName }}
                    </li>
                </ul>

            <h5 class="hub-level-1-label">Division(s)</h5>

                <ul class="hub-bulleted-list">
                    <li *ngFor="let divisionName of divisionNames">
                        {{ divisionName }}
                    </li>
                </ul>

            <h5 class="hub-level-1-label">Business Unit(s)</h5>

            <div class="hub-pill-list">
                <span *ngFor="let bu of projectBusinessUnits">
                    <a *ngIf="bu.isLeadBusinessUnit">
                        <span
                            class="badge badge-info font-size-lg mr-1 mb-1">{{ bu.businessUnit.name }}</span>
                    </a>
                    <a *ngIf="!bu.isLeadBusinessUnit">
                        <span class="badge badge-info-secondary font-size-lg mr-1 mb-1">{{ bu.businessUnit.name }}</span>
                    </a>
                </span>
            </div>

            <h5 class="hub-level-1-label">Related Program Management</h5>

            <div *ngIf="programManagementRecords.length">
                <span *ngFor="let pm of programManagementRecords">
                    <a [routerLink]="'/portfolio/' + pm.projectId">
                        <span class="badge badge-primary font-size-lg mr-1 mb-1">{{ pm.name }}</span>
                    </a>
                </span>
            </div>

            <div *ngIf="programManagementRecords.length === 0" class="no-relations-warning" style="color: #999;">
                A Program Management has not identified this record as part of their portfolio.
            </div>


        </div>

    </div>
</div>
