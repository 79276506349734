import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { GoalsService } from '../services/goals.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { UsageService } from '../../core/services/usage.service';

@Component({
    selector: 'app-goal-details',
    templateUrl: './goal-details.component.html',
    styleUrls: ['./goal-details.component.scss']
})
export class GoalDetailsComponent implements OnInit {

    constructor(
        private goalsService: GoalsService,
        private route: ActivatedRoute,
        private router: Router,
        private usageService: UsageService
    ) { }

    public theme: string;
    public goalDetails: any;

    public get goalId(): number {
        return this.goalDetails.goal.goal2030Id;
    }

    private get totalMetrics(): number {
        let totalMetrics: number = 0;
        for (let subTheme of this.goalDetails.subThemes) {
            totalMetrics += subTheme.metrics.length;
        }
        return totalMetrics;
    }

    private _labelText: string;

    public get labelText(): string {
        if (!this._labelText) {
            this._labelText = this.goalDetails.goal.name + (this.totalMetrics > 1 ? ' Metrics' : ' Metric');
        }
        return this._labelText;
    }

    ngOnInit(): void {
        this.route.params.pipe(take(1)).subscribe((params) => {
            this.theme = params['theme'];

            this.goalsService.getGoalDetails(this.theme).pipe(take(1))
                .subscribe((details: any) => {
                    this.goalDetails = details;
                });

            this.usageService.logHubUsage('User viewed Goal Metric Detail Page', 'Goal Detail', this.theme, '');
        });
    }

    public toggleExpandedState(expandableMetric: any): void {
        expandableMetric.isExpanded = !expandableMetric.isExpanded;
    }

    public numberPipePrecision(floatingPointPrecision: number) {
        return `1.${floatingPointPrecision}-${floatingPointPrecision}`;
    }

    public getProgressPercentage(region: any) {
        if (!region.regionsTargetValue) {
            return 0;
        }
        return Math.round(region.regionsProgressValue / region.regionsTargetValue * 100);
    }

    public regionHasOverage(region: any): boolean {
        return this.getProgressPercentage(region) > 100;
    }

    public getProgressWidth(region: any): number {
        const progressPercent = this.getProgressPercentage(region);
        if (progressPercent <= 100) {
            return progressPercent;
        }
        return 100;
    }

    public getProgressLeft(region: any): string {
        const progressPercent = this.getProgressWidth(region);
        let adjustment = -25;

        if (this.regionHasOverage(region)) {
            adjustment += 80;
        }

        return `calc(${progressPercent}% + ${adjustment}px)`;
    }

    public showAllStrategies(metric: any) {
        const config = {
            searchText: '',
            facets: [
                {
                    key: 'recordType',
                    activeFilters: [
                        { title: 'Strategy' }
                    ]
                },
                {
                    key: 'organizationalScaMetrics.lvl1',
                    activeFilters: [
                        { title: this.goalDetails.goal.theme + ' > ' + metric.metric }
                    ]
                },
                {
                    key: 'status',
                    activeFilters: [
                        { title: 'Active' },
                        { title: 'Proposed' },
                        { title: 'Completed' }
                    ]
                }
            ]
        };

        const encodedJsonString = JSON.stringify(config);
        LocalStorageService.setItem('lastAppliedPortfolioFilter', encodedJsonString);
        this.router.navigateByUrl('/portfolio');
    }
}
