import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators, ValidationErrors } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { ProjectModel, ProjectIntermediateResultModel, ScaleMeasureModel, ScaleMeasureItemModel, AvailableParentIntermediateResultsModel, ScaleMeasureDefinitionModel } from '../../../../../hub_schema/hubTypes';
import { ErrorService } from '../../../../core/services/error.service';
import { TypeAheadService } from '../../../../core/services/type-ahead.service';
import { escapeQuotesAndEnters } from '../../../../shared/helpers/escape';
import { IntermediateResultsEditService } from '../services/intermediate-results-edit.service';
import { IntermediateResultsLovService } from '../services/intermediate-results-lov.service';
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, throwIfEmpty } from 'rxjs/operators';
import { DeleteConfirmationDialogComponent } from '../../../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { HubMessageBoxComponent } from '../../../../shared/components/hub-message-box/hub-message-box.component';
import { UsageService } from '../../../../core/services/usage.service';
import { HubRecordTypes } from '../../../../shared/types/hub-record-types';


const universalScaleMeasureId: number = 1000;

@Component({
    selector: 'app-add-edit-intermediate-result-dialog',
    templateUrl: './add-edit-intermediate-result-dialog.component.html',
    styleUrls: ['./add-edit-intermediate-result-dialog.component.scss'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
    ],
})

export class AddEditIntermediateResultDialogComponent implements OnInit, OnDestroy {

    // #region Properties
        
    public existingIr: ProjectIntermediateResultModel;
    public project: ProjectModel;
    public isStrategy: boolean;

    public isSaving: boolean = false;
    public availableParents: AvailableParentIntermediateResultsModel[];
    // public relationsOptions = [];
    public parentStrategyOptions = [];
    public resultMetricOptions = [];
    private isScaleMeasureValueChangesSubscription: Subscription;
    private isChildIrSubscription: Subscription;
    private parentIrChangedSubscription: Subscription;
    private unitChangedSubscription: Subscription;

    // LOV tables
    public scaleMeasureItems: ScaleMeasureItemModel[];

    private get scaleMeasureItemsFormArray(): FormArray {
        if (this.isStrategy) {
            return this.intermediateResultForm.controls.scaleMeasureItems as FormArray;
        }
        return this.getFormGroupByTabIndex(1).controls.scaleMeasureItems as FormArray;
    }

    // Auto-completes
    public metricSuggestions: Observable<string[]>;
    public unitSuggestions: Observable<string[]>;

    // Form
    public intermediateResultForm: FormGroup;

    public get isKeyResultVisible(): boolean {
        return this.intermediateResultForm
            && this.getFormGroupByTabIndex(1).controls.showOnDashboard
            && this.getFormGroupByTabIndex(1).controls.showOnDashboard.value;
    }

    public get parentIr(): any {
        return this.getFormGroupByTabIndex(0).controls.parentIr.value;
    }

    private _oldParentIr: any;

    public get hasParentIr(): boolean {
        return this.parentIr != null;
    }

    public get isChildIr(): boolean {
        return this.intermediateResultForm && this.getFormGroupByTabIndex(0).controls.isChildIr.value;
    }

    // #endregion

    // #region Constructor and Page Init

    constructor(
        private usageService: UsageService,
        private intermediateResultsEditService: IntermediateResultsEditService,
        private intermediateResultsLovService: IntermediateResultsLovService,
        private errorService: ErrorService,
        private typeAheadService: TypeAheadService,
        private dialogService: MatDialog,
        private dialogRef: MatDialogRef<AddEditIntermediateResultDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any)
        { }

    ngOnInit(): void {
        this.existingIr = this.data.existingIntermediateResult;
        this.project = this.data.project;
        this.isStrategy = this.project.projectType?.name == HubRecordTypes.Strategy;
        const recordType = this.project.projectType!.name;

        this.usageService.logHubUsage(`User Initiated Creating Intermediate Result for ${recordType} ${this.project.projectId}`,
            'Intermediate Result', 'N/A', 'N/A', this.project.projectId);
        
        const existingIrId = this.existingIr ? this.existingIr.projectIntermediateResultId : undefined;

        forkJoin({
            scaleMeasureItems: this.intermediateResultsLovService.getScaleMeasureItems(),
            availableParentModels: this.intermediateResultsEditService.getAvailableParentIRs(this.project.projectId!, this.isStrategy, existingIrId)
        })
        .pipe(take(1)).subscribe(
            (results) => {
                this.scaleMeasureItems = results.scaleMeasureItems;
                this.availableParents = results.availableParentModels;

                // build up form
                const statement = this.existingIr ? this.existingIr.statement : '';
                const isScaleMeasure: boolean = this.existingIr?.scaleMeasureId ? true : false;
                const scaleMeasureId = isScaleMeasure ? this.existingIr?.scaleMeasureId : null;
        
                const isLeveragedIntermediateResult = this.existingIr ? this.existingIr.hasLeveragedIntermediateResult : false;
        
                const isKeyResult = this.existingIr ? this.existingIr.isKeyResult : false;
        
                if (this.isStrategy) {
                    const metricName = this.existingIr ? this.existingIr.indicatorName : '';
                    const unit = this.existingIr ? this.existingIr.unit : '';
                    const showOnDashboard = this.existingIr ? this.existingIr.isVisibleOnDashboard : false;
        
                    this.intermediateResultForm = new FormGroup({
                        isChildIr: new FormControl(false),
                        parentIr: new FormControl(null),
                        metricName: new FormControl({value: metricName, disabled: this.existingIr?.irStatistics!.hasChildren && this.existingIr?.irStatistics.hasProgress}, Validators.required),
                        unit: new FormControl({ value: unit, disabled: this.existingIr?.irStatistics!.hasChildren && this.existingIr?.irStatistics.hasProgress }, Validators.required),
                        isScaleMeasure: new FormControl(isScaleMeasure),
                        scaleMeasureId: new FormControl(scaleMeasureId),
                        scaleMeasureItems: new FormArray([]),
                        keyResult: new FormControl(isKeyResult),
                        leverageIntermediateResult: new FormControl(isLeveragedIntermediateResult),
                        showOnDashboard: new FormControl(showOnDashboard),
                        intermediateResultStatement: new FormControl(statement, [Validators.required, Validators.maxLength(800)])
                    });
                }
                else {
                    const allParents = this.availableParents.flatMap(a => a.intermediateResults);
                    const parentIntermediateResult = allParents.find(ir => ir.projectIntermediateResultId === this.existingIr?.parentIntermediateResultId );        
                    this._oldParentIr = parentIntermediateResult;

                    if (parentIntermediateResult) {
                        setTimeout(() => {
                            document.getElementById('parentIr_' + parentIntermediateResult.projectIntermediateResultId)!
                                .scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                        });
                    }

                    // sets the IR's name
                    let metricName: string = '';
        
                    if (this.existingIr) {
                        if(!this.existingIr.parentIntermediateResult) {
                            metricName = this.existingIr.indicatorName!;
                        } 
                        else {
                            metricName = parentIntermediateResult?.indicatorName!;
                        }
                    }
        
                    const unitName = this.existingIr ? this.existingIr.unit : '';
                    const isChildIr = this.existingIr?.parentIntermediateResultId != null;
     
                    this.intermediateResultForm = new FormGroup({
                       wizardSteps: new FormArray([
                           new FormGroup({
                               isChildIr: new FormControl(isChildIr),
                               parentIr: new FormControl(parentIntermediateResult),
                               metricName: new FormControl(metricName),
                               unit: new FormControl(unitName)
                           }, this.validateParentIrOrMetricAndUnit.bind(this)),
                           new FormGroup({
                               isScaleMeasure: new FormControl(isScaleMeasure),
                               scaleMeasureId: new FormControl(scaleMeasureId),
                               scaleMeasureItems: new FormArray([]),
                               leverageIntermediateResult: new FormControl(isLeveragedIntermediateResult),
                               keyResult: new FormControl(isKeyResult),
                           }),
                           new FormGroup({
                               intermediateResultStatement: new FormControl(statement, [Validators.required, Validators.maxLength(800)])
                           })
                       ])
                    });
                }
        
                this.setupFormChangeSubscriptions();
                this.setupTypeAheads();        
        
                // assumes only 1 scale measure in existence
                this.getFormGroupByTabIndex(1).controls.scaleMeasureId.setValue(this.scaleMeasureItems[0].scaleMeasureId);

                for (const item of this.scaleMeasureItems) {

                    const scaleMeasureDefinition = this.existingIr && this.existingIr?.scaleMeasureDefinitions?.length
                        ? this.existingIr.scaleMeasureDefinitions.find(d => d.scaleMeasureItemId === item.scaleMeasureItemId)
                        : null;

                    const definitionName = scaleMeasureDefinition ? scaleMeasureDefinition.description : '';

                    const scaleItemGroup: FormGroup = new FormGroup({
                        scaleMeasureId: new FormControl(item.scaleMeasureId),
                        scaleMeasureItem: new FormControl(item),
                        definition: new FormControl(definitionName, this.scaleMeasureDefinitionValidator.bind(this))
                    });
                    (this.getFormGroupByTabIndex(1).controls.scaleMeasureItems as FormArray).push(scaleItemGroup);
                }
            }
        );
    }

    private getFormGroupByTabIndex(tabIndex: number): FormGroup {
        if (this.isStrategy) {
            return this.intermediateResultForm;
        }
        return (this.intermediateResultForm.controls.wizardSteps as FormArray).controls[tabIndex] as FormGroup;
    }

    private setupFormChangeSubscriptions(): void {
        this.isChildIrSubscription = this.getFormGroupByTabIndex(0).controls.isChildIr.valueChanges.subscribe((val) => {
            if (!val) {
                // they swtiched from linked to unlinked.
                this.getFormGroupByTabIndex(0).controls.metricName.patchValue(this.parentIr ? this.parentIr.indicatorName : '');
                this.getFormGroupByTabIndex(0).controls.unit.patchValue(this.parentIr ? this.parentIr.unit : '');
                this.getFormGroupByTabIndex(1).controls.isScaleMeasure.patchValue(this.parentIr ? this.parentIr.scaleMeasureDefinitions.length > 0: false);

                // assumes only 1 scale measure in existence
                this.getFormGroupByTabIndex(1).controls.scaleMeasureId.setValue(universalScaleMeasureId);
                (this.getFormGroupByTabIndex(1).controls.scaleMeasureItems as FormArray).clear();

                for (const item of this.scaleMeasureItems) {

                    const scaleMeasureDefinition = this.parentIr && this.parentIr.scaleMeasureDefinitions.length
                        ? this.parentIr.scaleMeasureDefinitions.find(d => d.scaleMeasureItemId === item.scaleMeasureItemId)
                        : null;

                    const definitionName = scaleMeasureDefinition ? scaleMeasureDefinition.description : '';

                    const scaleItemGroup: FormGroup = new FormGroup({
                        scaleMeasureId: new FormControl(item.scaleMeasureId),
                        scaleMeasureItem: new FormControl(item),
                        definition: new FormControl(definitionName, this.scaleMeasureDefinitionValidator.bind(this))
                    });
                    (this.getFormGroupByTabIndex(1).controls.scaleMeasureItems as FormArray).push(scaleItemGroup);
                }
                this._oldParentIr = this.parentIr;
                this.getFormGroupByTabIndex(0).controls.parentIr.patchValue(null, {emitEvent: false});
                this.getFormGroupByTabIndex(0).updateValueAndValidity();
            }
            else {
                // switching from unlinked to linked.  If an old parent IR is present, populate with that.
                if (this._oldParentIr) {
                    this.getFormGroupByTabIndex(0).controls.parentIr.patchValue(this._oldParentIr);
                }
            }
        });

        this.parentIrChangedSubscription = this.getFormGroupByTabIndex(0).controls.parentIr.valueChanges.subscribe(val => {
            const currentMetricName = this.getFormGroupByTabIndex(0).controls.metricName.value;
            const currentUnit = this.getFormGroupByTabIndex(0).controls.unit.value;

            if (!this.existingIr) {
                this.getFormGroupByTabIndex(0).controls.metricName.setValue(this.parentIr.indicatorName);
                this.getFormGroupByTabIndex(0).controls.unit.setValue(this.parentIr.unit);
                this.getFormGroupByTabIndex(1).controls.isScaleMeasure.setValue(!!this.parentIr.scaleMeasureId);
                this.getFormGroupByTabIndex(1).controls.scaleMeasureId.setValue(this.parentIr.scaleMeasureId ? this.parentIr.scaleMeasureId : null);

                for (const scaleItemGroup of (this.getFormGroupByTabIndex(1).controls.scaleMeasureItems as FormArray).controls) {
                    const scaleItemId = scaleItemGroup.value.scaleMeasureItem.scaleMeasureItemId;
                    const scaleMeasureDefinition = this.parentIr.scaleMeasureDefinitions.find((smd) => smd.scaleMeasureItem.scaleMeasureItemId === scaleItemId);
                    const parentScaleMeasureDescription = scaleMeasureDefinition ? scaleMeasureDefinition.description : '';
                    (scaleItemGroup as FormGroup).controls.definition.setValue(parentScaleMeasureDescription);
                }
            }
            else {
                const newParentIr = this.parentIr ? this.parentIr : this._oldParentIr;

                if (currentMetricName.toLowerCase() !== newParentIr.indicatorName.toLowerCase()
                    || currentUnit.toLowerCase() !== newParentIr.unit.toLowerCase()) {

                    const deleteDialogRef = this.dialogService.open(DeleteConfirmationDialogComponent, {
                        data: {
                            warningMessage: 'This project IR metric name and/or unit does not match the selected Strategy IR metric name and unit. Do you want to change the Project metric name and unit to match?',
                            confirmButtonText: 'OK',
                            showSaveIcon: false
                        }
                    });

                    const confirmationDialog = deleteDialogRef.componentInstance as DeleteConfirmationDialogComponent;

                    confirmationDialog.actionConfirmed.pipe(take(1)).subscribe(() => {
                        this.getFormGroupByTabIndex(0).controls.metricName.patchValue(newParentIr.indicatorName);
                        const existingUnit = this.existingIr.unit;
                        this.getFormGroupByTabIndex(0).controls.unit.patchValue(newParentIr.unit, {emitEvent: false, onlySelf: true});
                        this.getFormGroupByTabIndex(1).controls.isScaleMeasure.setValue(!!this.parentIr.scaleMeasureId);

                        for (const scaleItemGroup of (this.getFormGroupByTabIndex(1).controls.scaleMeasureItems as FormArray).controls) {
                            const scaleItemId = scaleItemGroup.value.scaleMeasureItem.scaleMeasureItemId;
                            const scaleMeasureDefinition = this.parentIr.scaleMeasureDefinitions.find((smd) => smd.scaleMeasureItem.scaleMeasureItemId === scaleItemId);
                            const parentScaleMeasureDescription = scaleMeasureDefinition ? scaleMeasureDefinition.description : '';
                            (scaleItemGroup as FormGroup).controls.definition.setValue(parentScaleMeasureDescription);
                        }
        
                        this._oldParentIr = val;
                        deleteDialogRef.close();

                        if (existingUnit !== newParentIr.unit) {
                            this.showUnitChangedDialog();
                        }
                    });

                    confirmationDialog.actionCanceled.subscribe(() => {
                        // revert their selection
                        if (this._oldParentIr) {
                            this.getFormGroupByTabIndex(0).controls.parentIr.patchValue(this._oldParentIr, { emitEvent: false, onlySelf: true });
                        }
                    });
                }
            }
        });

        this.isScaleMeasureValueChangesSubscription = this.getFormGroupByTabIndex(1).controls.isScaleMeasure.valueChanges.subscribe((val) => {
            for (const formGroup of (this.getFormGroupByTabIndex(1).controls.scaleMeasureItems as FormArray).controls) {
                (formGroup as FormGroup).controls.definition.updateValueAndValidity();
            }
        });

        this.unitChangedSubscription = this.getFormGroupByTabIndex(0).controls.unit.valueChanges.subscribe((val) => {
            if (this.existingIr && this.existingIr.unit !== val) {
                this.showUnitChangedDialog();
            }
        });

    }

    private showUnitChangedDialog(): void {
        if (this.isStrategy) {
            this.dialogService.open(HubMessageBoxComponent, {
                data: {
                    title: 'Check Target Values',
                    message: 'The strategy metric name and unit have been changed. Check the values target entries to ensure the values match the unit.',
                    confirmButtonText: 'Got it'
                }
            });
        }
        else {
            this.dialogService.open(HubMessageBoxComponent, {
                data: {
                    title: 'Check Target, Progress & Start Values',
                    message: 'The project metric name and unit have been changed. Check the values on start, target or progress entries to ensure the values match the unit.',
                    confirmButtonText: 'Got it'
                }
            });
        }
    }

    private setupTypeAheads(): void {
        this.metricSuggestions = this.getFormGroupByTabIndex(0).controls.metricName
            .valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),                
                switchMap((term) => this.intermediateResultsEditService.getIrMetricNameSuggestions(escapeQuotesAndEnters(term)).pipe(
                    catchError(() => of([])), // empty list on error
                )),
            );

        this.unitSuggestions = this.getFormGroupByTabIndex(0).controls.unit
            .valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                switchMap((term) => this.intermediateResultsEditService.getIrUnitNameSuggestions(escapeQuotesAndEnters(term)).pipe(
                    catchError(() => of([])), // empty list on error
                )),
            );

    }

    // #endregion

    // #region Parent Strategies

    private getIrModelFromForm(): ProjectIntermediateResultModel {
        let result: any;
        if(this.isStrategy){
            result = {
                projectIntermediateResultId: this.existingIr?.projectIntermediateResultId,
                projectId: this.project.projectId,
                indicatorName:  this.intermediateResultForm.controls.metricName.value,
                unit: this.intermediateResultForm.controls.unit.value,
                scaleMeasureId: this.intermediateResultForm.controls.scaleMeasureId.value,
                scaleMeasureDefinitions: this.intermediateResultForm.controls.scaleMeasureItems.value,
                isKeyResult: this.intermediateResultForm.controls.keyResult.value,
                hasLeveragedIntermediateResult: this.intermediateResultForm.controls.leverageIntermediateResult.value,
                isVisibleOnDashboard: this.intermediateResultForm.controls.showOnDashboard.value,
                statement: this.intermediateResultForm.controls.intermediateResultStatement.value,                                
            }
        } 
        else {
            result = {
                projectIntermediateResultId: this.existingIr?.projectIntermediateResultId,
                projectId: this.project.projectId,
                parentIntermediateResultId: this.intermediateResultForm.controls.wizardSteps.value[0].parentIr?.projectIntermediateResultId,
                indicatorName: this.intermediateResultForm.controls.wizardSteps.value[0].metricName,
                unit: this.intermediateResultForm.controls.wizardSteps.value[0].unit,       
                scaleMeasureId: this.intermediateResultForm.controls.wizardSteps.value[1].scaleMeasureId,
                scaleMeasureDefinitions: this.intermediateResultForm.controls.wizardSteps.value[1].scaleMeasureItems,
                isKeyResult: this.intermediateResultForm.controls.wizardSteps.value[1].keyResult,
                isVisibleOnDashboard: false,
                hasLeveragedIntermediateResult: this.intermediateResultForm.controls.wizardSteps.value[1].leverageIntermediateResult,
                statement: this.intermediateResultForm.controls.wizardSteps.value[2].intermediateResultStatement
            }
        }

        if (!this.isScaleMeasure) {
            result.scaleMeasureId = null;
            result.scaleMeasureDefinitions = null;
        }
        else {
            const formDefinitions = this.scaleMeasureItemsFormArray.value;
            const scaleMeasureDefinitions: ScaleMeasureDefinitionModel[] = [];

            for (let scaleMeasureDef of formDefinitions) {
                const def = {
                    scaleMeasureItemId: scaleMeasureDef.scaleMeasureItem.scaleMeasureItemId,
                    description: scaleMeasureDef.definition,
                    projectIntermediateResultId: this.existingIr ? this.existingIr.projectIntermediateResultId : undefined
                };

                scaleMeasureDefinitions.push(def);
            }
            result.scaleMeasureDefinitions = scaleMeasureDefinitions;
        }
        return result;
    }

    public isParentStrategyOptionDisabled(parentStrategyOption) {
        const relatedProject = parentStrategyOption.theProject;

        if (relatedProject) {
            const intermediateResults = relatedProject.intermediateResults;
            return intermediateResults.length === 0;
        }

        return true;
    }

    // #endregion

    // #region Metric and Unit

    public clearMetric(): void {
        this.getFormGroupByTabIndex(0).controls.parentIr.reset();
    }

    // #endregion

    // #region Form Validation

    private validateParentIrOrMetricAndUnit(): any {
        if (!this.intermediateResultForm) {
            return null;
        }

        // if this is linked IR, then we only need to validate that it has a parent
        if (this.isChildIr) {
            const parentIr = this.getFormGroupByTabIndex(0).controls.parentIr.value;

            if (!parentIr) {
                return {
                    required: 'required'
                }
            }
            return null;
        }
        else {
            // otherwise, validate that it has a metric name and unit
            const metricName = this.getFormGroupByTabIndex(0).controls.metricName.value;
            const unit = this.getFormGroupByTabIndex(0).controls.unit.value;

            if (!metricName || !unit) {
                return {
                    required: 'required'
                }
            }
            return null;
        }
    }

    public getValidationErrorMessages(formGroupIndex: number, formControlName: string): string {
        const formGroup = this.getFormGroupByTabIndex(formGroupIndex);
        const formControl = formGroup.get(formControlName) as FormControl;
        return this.getValidationErrorMessageFromFormControl(formControl);
    }

    public getScaleMeasureDescriptionErrorMessaged(i: number): string {
        const formGroup = this.scaleMeasureItemsFormArray.controls[i] as FormGroup;
        const formControl = formGroup.controls.definition as FormControl;
        return this.getValidationErrorMessageFromFormControl(formControl);
    }

    private getValidationErrorMessageFromFormControl(formControl: FormControl): string {
        if (!formControl.touched) {
            return '';
        }

        const formErrors = formControl.errors;

        if (formErrors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(formErrors)) {

                if (key === 'maxlength') {
                    errorMessages.push(`Maximum length of ${formControl.errors[key].requiredLength} characters exceeded.`);
                } else if (key === 'required') {
                    errorMessages.push(key);
                } else {
                    errorMessages.push(formErrors[key]);
                }
            }

            return errorMessages.join('<br />');
        }

        return '';
    }


    // #endregion

    // #region Scale Measures

    public get isScaleMeasure(): boolean {
        if (!this.isStrategy) {
            return this.getFormGroupByTabIndex(1).controls.isScaleMeasure.value;
        }
        return this.intermediateResultForm.controls.isScaleMeasure.value;
    }

    public getScaleMeasureItem(i): ScaleMeasureItemModel {
        const fg: FormGroup = this.scaleMeasureItemsFormArray.controls[i] as FormGroup;
        return fg.controls.scaleMeasureItem.value;
    }

    private scaleMeasureDefinitionValidator(formControl: FormControl): ValidationErrors | null {
        if (!this.isScaleMeasure) {
            return null;
        }

        if (!formControl.value) {
            return { required: 'required' };
        }
        if ((formControl.value as string).length > 100) {
            return { 'too long': 'max 100 chars' };
        }
        return null;
    }


    // #endregion

    // #region dialog Interaction

    public save() {
        this.isSaving = true;
        const ir = this.getIrModelFromForm();        
       
        if (this.existingIr) {            
            this.intermediateResultsEditService.updateIntermediateResult(ir).pipe(take(1)).subscribe((savedIR: ProjectIntermediateResultModel) => {
                this.dialogRef.close(savedIR);
            });
        }
        else {
            this.intermediateResultsEditService.createIntermediateResult(ir).pipe(take(1))
                .subscribe({
                    next: (savedIr: ProjectIntermediateResultModel) => {
                        this.dialogRef.close(savedIr);
                    },
                    error: (err) => {
                        this.reportError(err);
                        this.dialogRef.close();
                    }
                });                
        }
    }

    public cancel() {
        this.dialogRef.close();
        this.usageService.logHubUsage(`User canceled out of creating Intermediate Result for ${this.project.projectType.name} ${this.project.projectId}`,
            'Intermediate Result', 'N/A', 'N/A', this.project.projectId);
    }

    // #endregion

    public reportError(err): void {
        const errorInfo = {
            error: err,
            timestamp: new Date()
        };
        console.error(err);
        this.errorService.addError(errorInfo, true);
    }

    public ngOnDestroy(): void {
        if (this.isScaleMeasureValueChangesSubscription) {
            this.isScaleMeasureValueChangesSubscription.unsubscribe();
        }

        if (this.isChildIrSubscription) {
            this.isChildIrSubscription.unsubscribe();
        }

        if (this.parentIrChangedSubscription) {
            this.parentIrChangedSubscription.unsubscribe();
        }

        if (this.unitChangedSubscription) {
            this.unitChangedSubscription.unsubscribe();
        }
    }
}
