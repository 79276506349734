import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, AfterViewInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as turf from '@turf/turf';
import { environment } from '../../../../environments/environment';
import { MapLocation } from '../../types/location';
import { MapMarker } from './type/map-marker-type';
import { watch } from '@arcgis/core/core/reactiveUtils';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils';
import esriConfig from '@arcgis/core/config';
import Expand from '@arcgis/core/widgets/Expand';
import Basemap from '@arcgis/core/Basemap';
import Graphic from '@arcgis/core/Graphic';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Search from '@arcgis/core/widgets/Search';
import BasemapGallery from '@arcgis/core/widgets/BasemapGallery';
import { LocalStorageService } from '../../../core/services/local-storage.service';


@Component({
    selector: 'app-map-container',
    templateUrl: './map-container.component.html',
    styleUrls: ['./map-container.component.scss']
})
export class MapContainerComponent implements OnDestroy, AfterViewInit {
    @Input() public mapType: string;

    public showingBasemapGallery: boolean = false;
    private basemapWatcher: IHandle;

    // #region Portfolio Map Properties

    private _hits: any[];

    public get hits(): any[] {
        return this._hits;
    }

    @Input()
    public set hits(value: any[]) {
        this._hits = value;
    }

    @ViewChild("mapDiv")
    private mapDiv: ElementRef;

    // #region other map properties

    private _project: any;

    public get project(): any {
        return this._project;
    }

    @Input()
    public set project(value: any) {
        this._project = value;
        this.setSingleMarker();
    }

    // #endregion

    // #endregion

    // #region MapMarkers

    private mapMarkers: MapMarker[];

    // #endregion

    @Output()
    public mapLoaded = new EventEmitter();

    @Output()
    public mapLocationSelected = new EventEmitter<MapLocation>();

    private mapView: MapView;
    private searchWidget: Search;

    // #region Constructor and Initialization

    constructor(
        public router: Router,
        private eRef: ElementRef ) { }

    public ngAfterViewInit(): void {

        // hack: ESRI mapView neds an explicit height set (in pixels) to render correctly
        const div = this.mapDiv.nativeElement;
        const height = div.parentElement.parentElement.clientHeight - 55;
        div.style.height = `${height}px`;

        if (this.mapView) {
            this.killMap();
        }

        this.buildMap();
    }

    // #endregion

    // #region Instance Methods

    public refreshMarkers() {
        if (!this.mapView) {
            return;
        }
        this.populateMarkerData();
        this.addMarkersToMap();
        this.zoomToData();
    }

    private populateMarkerData() {

        if (!this.hits) {
            return;
        }

        if (this.mapMarkers && this.mapMarkers.length) {
            this.clearAllMarkers();
        }

        this.mapMarkers = [];

        for (let hit of this.hits) {
            if (hit.hasLocation) {
                const mapMarker = new MapMarker();
                mapMarker.lng = hit._geoloc.lng;
                mapMarker.lat = hit._geoloc.lat;

                if (!mapMarker.lat || !mapMarker.lng) {
                    continue;
                }

                mapMarker.objectId = hit.objectID;
                mapMarker.dataItem = hit;
                this.mapMarkers.push(mapMarker);
            }
        }
    }

    private setSingleMarker(): void {
        if (!this.project.longitude || !this.project.latitude) {
            return;
        }

        this.mapMarkers = [];
        const mapMarker = new MapMarker();
        mapMarker.lng = this.project.longitude;
        mapMarker.lat = this.project.latitude;
        mapMarker.objectId = this.project.projectId;
        mapMarker.dataItem = this.project;
        this.mapMarkers.push(mapMarker);
    }

    private buildMap() {
        esriConfig.apiKey = "AAPK03ededd9649d408a9234263176cab404GugjTPHnXLoMy5xTr2oe5rw1W-74e7wqFXOhSYc4m8YLiIU-5mcid9pHSqCFImpI";

        const selectedBasemapId = LocalStorageService.getItem('selectedBasemapId');
        let basemap;

        if (selectedBasemapId) {
            basemap = new Basemap({ portalItem: { id: selectedBasemapId } });
        }
        else {
            // default id of 'Imagery Hybrid'
            basemap = 'arcgis-imagery';
        }

        const myMap = new Map({
            basemap: basemap
        });

        const initialCenterPoint = this.getInitialCenterPoint();

        this.mapView = new MapView({
            map: myMap,
            center: initialCenterPoint,
            zoom: this.mapType === 'edit' ? 4 : 7,
            container: "map",
            constraints: {
                snapToZoom: false
            }
        });

        this.mapView.constraints.minZoom = 2;

        const basemapGallery = new BasemapGallery({
            view: this.mapView,
            source: {
                query: {
                    title: '"World Basemaps for Developers" AND owner:esri'
                }
            }
        });

        const bgExpand = new Expand({
            view: this.mapView,
            content: basemapGallery,
            expandTooltip: 'Change basemap',
            collapseTooltip: 'Close',
            autoCollapse: true,
            closeOnEsc: true
        });

        this.mapView.ui.add(bgExpand, "bottom-right"); // Add to the view

        this.basemapWatcher = watch(
            () => this.mapView.map.basemap,
            (newValue) => {
                LocalStorageService.setItem('selectedBasemapId', newValue.portalItem.id);
            }
        );

        if (this.mapType === 'edit') {
            this.addSearchBox();
        }

        let interval;

        // wait for the map to be ready to refresh map data
        interval = setInterval(() => {
            if (this.mapView && this.mapView.ready) {
                this.refreshMarkers();
                this.zoomToData();
                this.mapLoaded.emit(true);
                clearInterval(interval);
            }
        }, 500);

        reactiveUtils.on(
            () => this.mapView.popup,
            "trigger-action",
            (event) => {
              if (event.action.id === "view-record") {
                const recordId = event.action.recordId;
                this.router.navigateByUrl('/portfolio/' + recordId);                
              }
          });
          
    }

    private handlePopupAction(e: any): void {
        if (e.action.id === 'view-record') {
            
        }
    }

    private zoomToData() {
        if (this.mapMarkers && this.mapMarkers.length > 1) {
            let graphicsLayer = this.mapView.map.findLayerById('markers') as GraphicsLayer;
            if (graphicsLayer) {
                this.mapView.goTo(graphicsLayer.graphics).catch(e => {
                    // swallow the promise rejection.
                });;
            }
        }
    }

    private getInitialCenterPoint(): number[] {
        if (this.mapMarkers && this.mapMarkers.length) {
            const marker = this.mapMarkers[0];
            return [marker.lng, marker.lat];
        }
        else {
            // no hits yet.  Show Washington, DC.
            return [-77.1142877, 38.8825732];
        }
    }

    public refreshMap() {
        if (this.mapView) {
            this.killMap();

            setTimeout(() => {
                this.buildMap();
                this.refreshMarkers();
            });
        }
    }

    private killMap() {
        this.clearAllMarkers();

        if (this.searchWidget) {
            this.searchWidget.destroy();
        }

        // todo: API has changed.  implement correct disposal
        // this.mapView.popup.clear();
        // this.mapView.popup.destroy();
        this.mapView.destroy();
    }

    ngOnDestroy() {
        this.clearAllMarkers();

        if (this.basemapWatcher) {
            this.basemapWatcher.remove();
        }

        if (this.mapView) {
            this.killMap();
        }
    }

    // #endregion


    navigateWithRouter(objectID) {
        this.router.navigateByUrl('portfolio/' + objectID);
    }

    private existingPoints = {};

    private colors = {
        'Active': '#3F51B5',
        'Approved': '#2196F3',
        'Canceled': '#CCCCCC',
        'Completed': '#4CAF50',
        'Discontinued': '#F44336',
        'Proposed': '#666666'
    };

    private addMarkersToMap(): void {
        if (!this.mapMarkers) {
            return;
        }

        let graphicsLayer = this.mapView.map.findLayerById('markers') as GraphicsLayer;

        if (!graphicsLayer) {
            graphicsLayer = new GraphicsLayer();
            graphicsLayer.id = 'markers';
        }

        this.mapView.map.add(graphicsLayer);

        for (const mapMarker of this.mapMarkers) {
            const point = {
                type: "point",
                longitude: mapMarker.lng,
                latitude: mapMarker.lat
            };

            const key = point.longitude.toString() + ',' + point.latitude.toString();

            if (this.existingPoints[key] == true) {
                continue;
            }

            this.existingPoints[key] = true;

            // we have data mismatches between Algolia and GraphQL.  Since map data can come from either, we resolve it here

            const statusName = mapMarker.dataItem.status.name
                ? mapMarker.dataItem.status.name
                : mapMarker.dataItem.status;

            const theColor = this.colors[statusName];

            const simpleMarkerSymbol = {
                type: "simple-marker",
                color: theColor,
                outline: {
                    color: theColor,
                    width: 1
                }
            };

            const recordTypeName = mapMarker.dataItem.projectType
                ? mapMarker.dataItem.projectType.name
                : mapMarker.dataItem.recordType;

            const popupHtml = `
                  <div class="hub-popup-container ${statusName}">
                    <div>
                      <span class="text-success-800 font-weight-bold">${recordTypeName}</span>
                      <span class="text-muted ml-1">(${statusName})</span>
                    </div>
                    <div class="project-title mb-0">
                        <span class="font-weight-bold">${mapMarker.dataItem.name}</span>
                    </div>
                  </div>
                `;

            const viewRecordAction: any = {
                title: 'View Record',
                id: 'view-record',
                className: 'esri-icon-review',
                recordId: parseInt(mapMarker.dataItem.objectID)
            };

            const popupTemplate = {
                title: "{hubRecordType} ({status})",
                content: "{popupHtml}",
                overwriteActions: true,
                actions: this.mapType === 'portfolio' ? [viewRecordAction] : []
            }

            const attributes = {
                popupHtml: popupHtml,
                hubRecordId: mapMarker.dataItem.projectId,
                hubRecordType: mapMarker.dataItem.recordType,
                status: statusName,
                name: mapMarker.dataItem.name,
            }

            const pointGraphic = new Graphic({
                geometry: point as any,
                symbol: simpleMarkerSymbol,
                attributes: attributes,
                popupTemplate: popupTemplate
            });

            graphicsLayer.add(pointGraphic);
        }
    }

    private clearAllMarkers(): void {
        this.mapMarkers = [];
        const markersLayer = this.mapView.map.findLayerById('markers') as GraphicsLayer;

        if (markersLayer) {
            markersLayer.removeAll();
        }

        this.existingPoints = {};
    }

    private addSearchBox(): void {

        this.searchWidget = new Search({
            view: this.mapView
        });

        this.mapView.ui.add(this.searchWidget, {
            position: 'top-right',
            index: 1
        });

        this.searchWidget.on('select-result', (e: any) => {
            const selection = new MapLocation();
            selection.address = e.result.name;
            selection.latitude = e.result.feature.geometry.latitude;
            selection.longitude = e.result.feature.geometry.longitude;

            this.clearAllMarkers();
            this.project.longitude = selection.longitude;
            this.project.latitude = selection.latitude;
            this.setSingleMarker();
            this.refreshMarkers();
            this.mapLocationSelected.emit(selection);
        });
    }

    @HostListener('document:click', ['$event'])
    private userClickedOutsideOfPanel(event) {
        if (!this.eRef) {
            return;
        }

        if (!this.eRef.nativeElement.contains(event.target)) {
            this.showingBasemapGallery = false;
        }
        if (event.target.classList.contains('esri-view-surface')) {
            this.showingBasemapGallery = false;
        }
    }
}
