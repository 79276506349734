import { Component } from '@angular/core';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';

@Component({
  selector: 'app-notes-panel',
  templateUrl: './notes-panel.component.html',
  styleUrls: ['./notes-panel.component.scss']
})
export class NotesPanelComponent extends HubRecordEditorBase {

    public getNoteErrorMessage() {
        if (this.projectEditForm?.controls.financialNotes.invalid) {
            return 'Notes cannot exceed 500 characters';
        }
        return '';
    }

}
