import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IntermediateResultTargetModel, ProjectIntermediateResultModel, ScaleMeasureDefinitionModel } from '../../../../../../hub_schema/hubTypes';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { IntermediateResultsEditService } from '../../services/intermediate-results-edit.service';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ErrorService } from '../../../../../core/services/error.service';

@Component({
    selector: 'app-add-edit-ir-target-dialog',
    templateUrl: './add-edit-ir-target-dialog.component.html',
    styleUrls: ['./add-edit-ir-target-dialog.component.scss'],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class AddEditIrTargetDialogComponent implements OnInit {

    // injected dependencies
    private intermediateResultsEditService: IntermediateResultsEditService = inject(IntermediateResultsEditService);
    private dialogRef: MatDialogRef<AddEditIrTargetDialogComponent> = inject(MatDialogRef<AddEditIrTargetDialogComponent>);
    private errorService: ErrorService = inject(ErrorService);
    private data: any = inject(MAT_DIALOG_DATA);

    // page properties
    public intermediateResult: ProjectIntermediateResultModel;
    public existingTarget: IntermediateResultTargetModel | null;
    public targetForm: FormGroup;
    public isSaving: boolean = false;

    public ngOnInit(): void {
        this.existingTarget = this.data.existingTarget;
        this.intermediateResult = this.data.intermediateResult;

        this.targetForm = new FormGroup({
            intermediateResultTargetId: new FormControl({
                value: this.existingTarget ? this.existingTarget.intermediateResultTargetId : undefined, 
                disabled: !this.existingTarget
            }),
            projectIntermediateResultId: new FormControl(this.intermediateResult.projectIntermediateResultId),
            targetDate: new FormControl(this.existingTarget ? this.existingTarget.targetDate : null, [Validators.required, this.validateTargetDate.bind(this)]),
            targetValue: new FormControl(this.existingTarget ? this.existingTarget.targetValue : null, Validators.required)
        });
    }

    private validateTargetDate(targetDateControl: FormControl): ValidationErrors | null {
        if (targetDateControl.pristine) {
            return null;
        }

        const targetDate = targetDateControl.value as Date;

        // validation rule - targets must be after existing starts
        const start = this.intermediateResult.progress?.find(p => p.isStart);
        if (start) {
            if (targetDate.getTime() < start.progressDate!.getTime()) {
                return {
                    'invalid': 'The date entered is before the Intermediate Result start date.'
                };
            }
        }
        return null;
    }

    public getValidationErrorMessages(formControlName: string): string {
        const formControl = this.targetForm!.controls[formControlName];

        if (!formControl.touched) {
            return '';
        }

        if (formControl.errors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(formControl.errors)) {
                if (key == 'required') {
                    errorMessages.push(key);
                }
                else if (key === 'min' || key === 'max') {
                    errorMessages.push('Value must be 1-5')
                }
                else {
                    errorMessages.push(formControl.errors[key]);
                }
            }
            return errorMessages.join(', ');
        }

        return '';
    }

    // #region Scale Measures

    public get isScaleMeasure(): boolean {
        return this.intermediateResult.scaleMeasureDefinitions.length > 0;
    }

    public getScaleDefinitions(): ScaleMeasureDefinitionModel[] {
        if (this.intermediateResult.parentIntermediateResult) {
            return this.intermediateResult.parentIntermediateResult.scaleMeasureDefinitions;
        }
        return this.intermediateResult.scaleMeasureDefinitions;
    }

    // #endregion Scale Measures


    public cancel(): void {
        this.dialogRef.close();
    }

    public save(): void {
        const irTarget: IntermediateResultTargetModel = this.targetForm.value;

        if (!this.existingTarget) {
            this.intermediateResultsEditService.createTarget(irTarget).subscribe({
                next: (savedIrTarget: IntermediateResultTargetModel) => {
                    this.isSaving = false;
                    this.dialogRef.close(savedIrTarget);
                },
                error: (err) => {
                    this.errorService.addError(err, true);
                    this.dialogRef.close();
                }
            });
        }
        else {
            this.intermediateResultsEditService.updateTarget(irTarget).subscribe({
                next: (savedIrTarget: IntermediateResultTargetModel) => {
                    this.isSaving = false;
                    this.dialogRef.close(savedIrTarget);
                },
                error: (err) => {
                    this.errorService.addError(err, true);
                    this.dialogRef.close();
                }
            });
        }
    }
}
