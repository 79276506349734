<div class="create-progress-modal" *ngIf="progressForm">
    <div class="hub-modal-header-text">
        <h5 class="modal-title text-success">{{existingProgressReport ? 'Update' : 'Create'}} Output Progress Report</h5>
    </div>
    <div class="content">
        <div class="modal-body p-1">
            <form [formGroup]="progressForm">
                <div class="row">
                    <div class="form-group col-md-6">
                        <span class="select-label text-info-700 pb-2">Reported Date<app-help-selector [selector]="1121">
                            </app-help-selector></span>
                        <span class="alpaca-icon-required text-danger"></span>

                        <mat-form-field>                    
                            <input matInput readonly (click)="reportedDateCalendar.open()"
                                [matDatepicker]="reportedDateCalendar" formControlName="progressDate" placeholder="Reported Date">
                            <mat-datepicker-toggle matIconSuffix [for]="reportedDateCalendar"></mat-datepicker-toggle>
                            <mat-datepicker #reportedDateCalendar></mat-datepicker>
                        </mat-form-field>
     
                        <div class="hub-validation-error-message">
                            {{getValidationErrorMessages(progressForm.controls.progressDate)}}
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-6">
                        <span class="select-label text-info-700 pb-2">Output Progress %<app-help-selector
                                [selector]="1122"></app-help-selector></span>
                        <span class="alpaca-icon-required text-danger"></span>

                        <div class="hub-select-container">
                            <mat-form-field>
                                <mat-select placeholder="Select Progress" disableOptionCentering
                                    formControlName="progressId">
                                    <mat-option *ngFor="let prog of outputProgressValues"
                                        [value]="prog.outputProgressValueId">
                                        {{prog.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="hub-validation-error-message">
                            {{getValidationErrorMessages(progressForm.controls.progressId)}}
                        </div>
                    </div>

                    <div class="col-md-6">
                        <span class="select-label text-info-700 pb-2">Output Status<app-help-selector [selector]="1123">
                            </app-help-selector></span>
                        <span class="alpaca-icon-required text-danger"></span>

                        <div class="hub-select-container">
                            <mat-form-field>
                                <mat-select formControlName="statusId" placeholder="Select a status"
                                    disableOptionCentering>
                                    <mat-option *ngFor="let stat of outputUpdateStatuses"
                                        [value]="stat.outputUpdateStatusId">
                                        <span>{{stat.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="hub-validation-error-message">
                            {{getValidationErrorMessages(progressForm.controls.statusId)}}
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <span class="select-label text-info-700 pb-2 mt-2 d-block">Progress description
                            <app-help-selector [selector]="1124"></app-help-selector></span>
                        <div class="input-group">
                            <textarea class="record-description" formControlName="description" rows="3" cols="5" class="form-control"
                                placeholder="Type a description for this progress report"></textarea>
                        </div>
                        <div class="hub-validation-error-message">
                            {{getValidationErrorMessages(progressForm.controls.description)}}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="hub-modal-footer-text">
        <button type="button" class="cancel-button mr-1" (click)="cancel()">Cancel</button>

        <button type="button" class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
            [disabled]="progressForm.invalid || isSaving" (click)="saveProgressReport()">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>
            Save
        </button>

    </div>

</div>
