import { Component, Inject, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { map, startWith } from 'rxjs/operators';
import { AuthService } from '../../../../../core/services/auth/auth.service';
import { ErrorService } from '../../../../../core/services/error.service';
import { ApplicationRoleModel, BusinessUnitModel, UserApplicationRoleModel, UserModel } from '../../../../../../hub_schema//hubTypes';
import { HubAdministrationService } from '../../../../services/hub-administration.service';
import { HubLovService } from '../../../../../core/services/hub-lov.service';
import { ApplicationRoleType } from '../../../../../shared/types/role';

@Component({
    selector: 'app-edit-user-dialog',
    templateUrl: './edit-user-dialog.component.html',
    styleUrls: ['./edit-user-dialog.component.scss']
})
export class EditUserDialogComponent implements OnInit {
    // dependencies
    private authService: AuthService = inject(AuthService);
    private errorService: ErrorService = inject(ErrorService);
    private hubAdministrationService: HubAdministrationService = inject(HubAdministrationService);
    private hubLovService: HubLovService = inject(HubLovService);
    private dialogRef: MatDialogRef<EditUserDialogComponent> = inject(MatDialogRef<EditUserDialogComponent>);
    private dialogData: any = inject(MAT_DIALOG_DATA);
    
    public isSaving: boolean = false;
    private user: UserModel;

    // variables for creating permissions checklist
    private applicationRoles: ApplicationRoleModel[];
    public applicationRoleCollection: any[] = [];
    private buEditorApplicationRoleId: number = 1000;

    // variables associated with granting and revoking BU editing permissions for a user
    public businessUnits: BusinessUnitModel[] = [];
    public businessUnitsTypeAhead: FormControl = new FormControl('');
    public businessUnitSuggestions$: Observable<BusinessUnitModel[]>;

    public get selectedBusinessUnits(): BusinessUnitModel[] {
        const buEditorRoles = this.user.userApplicationRoles!.filter(ar => ar.applicationRoleId === this.buEditorApplicationRoleId);

        if (buEditorRoles.length) {
            return buEditorRoles!.map(uar => uar.businessUnit!);
        }
        return [];            
    }

    public ngOnInit(): void {
        this.user = JSON.parse(JSON.stringify(this.dialogData.selectedUser)); 

        forkJoin({
            applicationRoles: this.hubLovService.getApplicationRoles(),
            businessUnits: this.hubLovService.getBusinessUnits()
        })
        .pipe(take(1)).subscribe((results: any) => {
            this.applicationRoles = results.applicationRoles;
            this.businessUnits = results.businessUnits;

            // populate UI version of app roles (list of checked or unchecked roles, plus list of BUs for any BU editor roles)            
            const existingApplicationRoleIds = this.user.userApplicationRoles!.map((r) => r.applicationRoleId);

            for (const role of this.applicationRoles) {
                const userHasRole: boolean = existingApplicationRoleIds.includes(role.applicationRoleId);

                const rolesWithHasRoleInfo = { 
                    role: role, 
                    hasRole: { 
                        value: userHasRole,
                        disabled: this.isRoleDisabled(role)}
                    };                        

                this.applicationRoleCollection.push(rolesWithHasRoleInfo);
            }

            this.businessUnitSuggestions$ = this.businessUnitsTypeAhead.valueChanges
                .pipe(
                    startWith(''),
                    map((value) => this.filterBUs(value))
                );
        });
    }

    private filterBUs(value: string): BusinessUnitModel[] {
        if (typeof value !== 'string') {
            return [];
        }
        return this.businessUnits.filter((bu) => bu.name.toLowerCase().includes(value));
    }

    public get hasBusinessUnitEditorRoles(): boolean {
        return this.user.userApplicationRoles!.some(uar => uar.applicationRoleId === this.buEditorApplicationRoleId);
    }

    public roleSelectedChanged(evt: MatCheckboxChange): void {
        // add or remove roles
        if (evt.checked) {
            const appRole = this.applicationRoles.find(ar => ar.name == evt.source.name)!;
            this.addRole(appRole);
        }
        else {
            this.removeRole(evt.source.name!);
        }
    }

    private addRole(appRole: ApplicationRoleModel): void {
        const userAppRole: UserApplicationRoleModel = {
            userId: this.user.userId!,
            applicationRoleId: appRole.applicationRoleId,
            applicationRole: appRole
        };

        if (userAppRole.applicationRoleId == this.buEditorApplicationRoleId) {
            const userBu = this.businessUnits.find((bu) => bu.businessUnitId === this.user.businessUnitId)!;
            userAppRole.businessUnitId = userBu.businessUnitId;
            userAppRole.businessUnit = userBu;
        }
        
        this.user.userApplicationRoles!.push(userAppRole);
    }

    private removeRole(roleName: string): void {
        const userAppRoles = this.user.userApplicationRoles!.filter(uar => uar.applicationRole!.name === roleName);

        for (let appRole of userAppRoles) {
            const index = this.user.userApplicationRoles!.findIndex(uar => uar.applicationRole!.name == roleName);
            this.user.userApplicationRoles!.splice(index, 1);
        }    
    }

    public addBu(bu: BusinessUnitModel): void {
        const userAppRole: UserApplicationRoleModel = {
            userId: this.user.userId!,
            applicationRoleId: this.buEditorApplicationRoleId,
            applicationRole: this.applicationRoles.find(ar => ar.applicationRoleId === this.buEditorApplicationRoleId),
            businessUnitId: bu.businessUnitId,
            businessUnit: bu
        };
        this.user.userApplicationRoles!.push(userAppRole);
        this.businessUnitsTypeAhead.setValue('');
    }

    public deleteBu(businessUnit: BusinessUnitModel): void {
        const indexToRemove = this.user.userApplicationRoles!.findIndex(bu => bu.businessUnitId == businessUnit.businessUnitId);
        this.user.userApplicationRoles!.splice(indexToRemove, 1);
        
        // If last BU has been removed, de-select BU Editor role
        if (!this.hasBusinessUnitEditorRoles) {
            const applicationRoleDisplay = this.applicationRoleCollection.find(ar => ar.role.name === ApplicationRoleType.BusinessUnitEditor);
            applicationRoleDisplay.hasRole.value = false;
            this.removeRole(ApplicationRoleType.BusinessUnitEditor)
        }
    }

    public isRoleDisabled(role: ApplicationRoleModel): boolean {
        if (this.authService.userIsITAdmin() || (this.authService.userIsBusinessAdmin() && this.authService.userIsFinanceAdmin())) {
            return false;
        }
        else if (this.authService.userIsBusinessAdmin()) {
            const allowedRoles: string[] = [
                ApplicationRoleType.BusinessAdministrator,
                ApplicationRoleType.BusinessUnitEditor,
                ApplicationRoleType.RegionEditor,
                ApplicationRoleType.DivisionEditor
            ];
            return !allowedRoles.includes(role.name);
        }
        else if (this.authService.userIsFinanceAdmin()) {
            const allowedRoles: string[] = [
                ApplicationRoleType.FinanceEditor,
                ApplicationRoleType.FinanceAdministrator
            ];

            return !allowedRoles.includes(role.name);
        }
        return true;
    }

    public close(): void {
        this.dialogRef.close();
    }

    public saveUser(): void {
        this.isSaving = true;

        this.hubAdministrationService.updateUser(this.user).pipe(take(1)).subscribe({
            next: (savedUser: UserModel) => {
                this.dialogRef.close(savedUser);
            },
            error: (err) => {
                this.errorService.addError(err, true);
                this.dialogRef.close();
            }
        });
    }

    public getBuName(bu: BusinessUnitModel) {
        if (bu && bu.name) {
            return bu.name;
        }
    }

    public disableBuOption(bu: BusinessUnitModel): boolean {
        if (this.selectedBusinessUnits.some((item) => item.businessUnitId === bu.businessUnitId)) {
            return true;
        }
        return false;
    }
}
