import { Component, Input } from '@angular/core';
import { ProjectModel, ProjectPartnerModel } from '../../../../hub_schema/hubTypes';


@Component({
    selector: 'app-partners-container',
    templateUrl: './partners-container.component.html',
    styleUrls: ['./partners-container.component.scss'],
})
export class PartnersContainerComponent {

    @Input()
    public project: ProjectModel;

    constructor() { }

    trackByFn(partner: ProjectPartnerModel) {
        return partner.projectPartnerId;
    }
}
