import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { OutcomeMetricModel, OutcomeUnitModel } from '../../../../../../../hub_schema/hubTypes';
import { HubLovService } from '../../../../../../core/services/hub-lov.service';
import { CreateOutcomeStateService } from '../../services/create-outcome-state.service';
import { Subject, Subscription, take } from 'rxjs';
import { FormGroup, Validators } from '@angular/forms';
import { OutcomeState } from '../../types/outcome-state';

@Component({
  selector: 'app-tnc-goals-standalone-panel',
  templateUrl: './tnc-goals-standalone-panel.component.html',
  styleUrls: ['./tnc-goals-standalone-panel.component.scss']
})
export class TncGoalsStandalonePanelComponent implements OnInit, OnDestroy {
    private hubLovService: HubLovService = inject(HubLovService);
    private createOutcomeStateService: CreateOutcomeStateService = inject(CreateOutcomeStateService);
    public outcomeState: OutcomeState;

    public outcomeMetrics: OutcomeMetricModel[];

    public get units(): OutcomeUnitModel[] {
        const selectedMetricId = this.outcomeForm.controls.outcomeMetricId.value;
        const selectedMetric = this.outcomeMetrics?.find(om => om.outcomeMetricId === selectedMetricId);

        if (selectedMetric) {
            if (selectedMetric.outcomeMetricId === 1014) {
                return [selectedMetric.compatibleUnits.find(u => u.isActive === true)];
            }
            return selectedMetric.compatibleUnits;
        }
        return [];
    }

    private metricChangedSubscription: Subscription;
    private unitChangedSubscription: Subscription;

    public get outcomeForm(): FormGroup {
        return this.outcomeState.outcomeWizardForm.controls.outcome as FormGroup;
    }

    public get unitPlaceholderText(): string {
        if (this.outcomeForm.controls.outcomeMetricId.value) {
            return 'Select Metric Unit';
        }
        return 'Select an Outcome Metric to enable this dropdown';
    }

    public ngOnInit(): void {
        this.outcomeState = this.createOutcomeStateService.outcomeState;

        this.hubLovService.getOutcomeMetrics().pipe(take(1)).subscribe((outcomeMetrics: OutcomeMetricModel[]) => {
            this.outcomeMetrics = outcomeMetrics;
        });

        this.metricChangedSubscription = this.outcomeForm.controls.outcomeMetricId.valueChanges.subscribe((metricId) => {
            const selectedMetric = this.outcomeMetrics.find(m => m.outcomeMetricId === metricId);
            const defaultUnit = selectedMetric.compatibleUnits.find(u => u.outcomeUnitId === selectedMetric.preferredUnitId);
            this.outcomeForm.controls.outcomeUnitId.setValue(defaultUnit.outcomeUnitId);
        });

        this.unitChangedSubscription = this.outcomeForm.controls.outcomeUnitId.valueChanges.subscribe((unitId: number) => {
            const unit = this.units.find(u => u.outcomeUnitId === unitId);
            this.outcomeForm.controls.outcomeUnit.setValue(unit);
        });
    }

    public getValidationErrorMessages(formControlName: string): string {

        const formControl = this.outcomeForm!.controls[formControlName];

        if (!formControl.touched) {
            return '';
        }

        const formErrors = formControl.errors;

        if (formErrors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(formErrors)) {
                if (key === 'required') {
                    errorMessages.push(key);
                }
                else {
                    errorMessages.push(formErrors[key]);
                }
            }

            return errorMessages.join('<br />');
        }

        return '';
    }

    public ngOnDestroy(): void {

        if (this.metricChangedSubscription) {
            this.metricChangedSubscription.unsubscribe();
        }
        if (this.unitChangedSubscription) {
            this.unitChangedSubscription.unsubscribe();
        }
    }
}
