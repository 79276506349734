import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ProjectIntermediateResultModel, ProjectModel } from '../../../../hub_schema/hubTypes';
import { HubRecordPresenterBase } from '../../../shared/components/_hub_record_presenter_base';

enum tabs {
    active = 1,
    archive,
  }
  
@Component({
    selector: 'app-intermediate-results-container',
    templateUrl: './intermediate-results-container.component.html',
    styleUrls: ['./intermediate-results-container.component.scss']
})
export class IntermediateResultsContainerComponent extends HubRecordPresenterBase implements OnChanges {
    public tabs = tabs;
    public activeTab: tabs = tabs.active;

    public activeIntermediateResults: ProjectIntermediateResultModel[] = [];
    public archivedIntermediateResults: ProjectIntermediateResultModel[] = [];  
  
    @Input() 
    public override project: ProjectModel;

    @Output()
    projectDataAreLoading = new EventEmitter<boolean>();

    public ngOnChanges(changes: SimpleChanges): void {
        this.populateIrLists();
    }

    private populateIrLists(): void {
        this.activeIntermediateResults = this.project.intermediateResults!.filter(ir => !ir.isArchived);
        this.archivedIntermediateResults = this.project.intermediateResults!.filter(ir => ir.isArchived);
    }

    public setTabTo(tab: tabs, e: Event): void {
        this.activeTab = tab;
        e.preventDefault();
      }    
}
