import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AncillaryTypeModel, HubUserModel, ProjectLinkModel } from '../../../../../hub_schema/hubTypes';
import { HubLovService } from '../../../../core/services/hub-lov.service';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { AssociatedFilesEditService } from '../services/associated-files-edit.service';
import { ValidatorFn } from '@angular/forms';
import { AbstractControl } from '@angular/forms';
import { urlPattern } from '../../../../shared/helpers/urlPattern';
import { limits } from '../../../../shared/types/limits';

@Component({
  selector: 'app-add-edit-associated-file-dialog',
  templateUrl: './add-edit-associated-file-dialog.component.html',
  styleUrls: ['./add-edit-associated-file-dialog.component.scss']
})
export class AddEditAssociatedFileDialogComponent implements OnInit {
    private dialogRef: MatDialogRef<AddEditAssociatedFileDialogComponent> = inject(MatDialogRef<AddEditAssociatedFileDialogComponent>);
    private hubLovService: HubLovService = inject(HubLovService);
    private associatedFileEditService: AssociatedFilesEditService = inject(AssociatedFilesEditService);
    private data: any = inject(MAT_DIALOG_DATA);

    public existingProjectLink: ProjectLinkModel;
    private projectId: number;
    private ancillaryTypes: AncillaryTypeModel[];
    public associatedFileForm: FormGroup;
    public isSaving: boolean = false;

    public ngOnInit(): void {
        this.existingProjectLink = this.data.projectLink;
        this.projectId = this.data.projectId;

        this.hubLovService.getAncillaryTypes().pipe(take(1)).subscribe((ancillaryTypes: AncillaryTypeModel[]) => {
            this.ancillaryTypes = ancillaryTypes;

            this.associatedFileForm = new FormGroup({
                projectLinkId: new FormControl({
                    value: this.existingProjectLink ? this.existingProjectLink.projectLinkId : undefined,
                    disabled: this.existingProjectLink === null
                }),
                ancillaryTypeId: new FormControl(this.existingProjectLink ? this.existingProjectLink.ancillaryTypeId : null, [Validators.required]),
                projectId: new FormControl(this.projectId),
                title: new FormControl(this.existingProjectLink ? this.existingProjectLink.title : '', [Validators.required, Validators.maxLength(100)]),
                url: new FormControl(this.existingProjectLink ? this.existingProjectLink.url : '', [Validators.required, Validators.pattern(urlPattern), Validators.maxLength(limits.maxUrlLength)])
            });
        });
    }

    public getValidationErrorMessages(formControlName: string): string {
        const formControl = this.associatedFileForm.controls[formControlName];

        if (!formControl.touched) {
            return '';
        }

        const formErrors = formControl.errors;

        if (formErrors) {
            // todo: this is weird.  Just have getMessages return an array, rather than passing an empty one to it.
            const errorMessages = [];
            this.getMessages(errorMessages, formErrors);
            return errorMessages.join('<br />');
        }

        return '';
    }

    private getMessages(errorMessages: string[], formErrors: ValidationErrors) {
        for (const key of Object.keys(formErrors)) {

            if (key === 'required') {
                errorMessages.push(key);
            }

            if (key === 'pattern') {
                errorMessages.push('Enter a valid url');
            }

            if (key === 'maxlength') {
                errorMessages.push('Max length of ' + formErrors[key].requiredLength + ' characters exceeded.');
            }

            if (key !== 'required' && key !== 'pattern' && key !== 'maxlength') {
                errorMessages.push(formErrors[key]);
            }
        }
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public save(): void {
        this.isSaving = true;
        const projectLink = this.associatedFileForm.value;

        if (this.existingProjectLink) {
            this.associatedFileEditService.updateProjectFileLink(projectLink).pipe(take(1)).subscribe((result: ProjectLinkModel) => {
                this.isSaving = false;
                this.dialogRef.close(result);
            });
        }
        else {
            this.associatedFileEditService.createProjectFileLink(projectLink).pipe(take(1)).subscribe((result: ProjectLinkModel) => {
                this.isSaving = false;
                this.dialogRef.close(result);
            });
        }
    }
}
