import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'manage-notifications-unsaved-dialog',
    templateUrl: './manage-notifications-unsaved-dialog.component.html',
    styleUrls: ['./manage-notifications-unsaved-dialog.component.scss'],
})
export class ManageNotificationsUnsavedDialog {
    public isSaving = false;
    private dialogRef: MatDialogRef<ManageNotificationsUnsavedDialog> = inject(MatDialogRef<ManageNotificationsUnsavedDialog>);

    public close(result): void {
        this.dialogRef.close(result);
    }

    public dismiss(reason): void {
        this.dialogRef.close(reason);
    }
}
