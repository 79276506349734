<div class="view-section" id="admin">
    <h3 class="view-section-header">Audit Log</h3>
    <div class="details">
        <div class="table-responsive mr-3">
            <table class="table">
                <thead>
                    <th width="20%">
                        Date
                    </th>
                    <th width="25%">
                        User
                    </th>
                    <th>
                        Update
                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let log of auditLogs; index as i;">
                        <td>{{log.actionDateStr}}</td>
                        <td><a [routerLink]="'/user/' + log.user.userId">{{log.user.fullName}}</a></td>
                        <td>{{log.action}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
