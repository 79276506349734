import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationRoleType } from '../../shared/types/role';
import { escapeQuotesAndEnters } from '../../shared/helpers/escape';
import { userLastNameFirstNameSorter } from '../../shared/helpers/sorters';
import { CoreModule } from '../core.module';
import { HttpService } from './http.service';
import { FinancialContactTypeAheadModel, UserModel } from '../../../hub_schema/hubTypes';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: CoreModule
})
export class TypeAheadService {

    constructor(
        private httpService: HttpService        
    ) { }

    //#region User

    /**
     * Method to filter Users (haystack)
     * @param needle filter by value
     * @returns Array of Users matching search criteria
     */
    getUserTypeAheadData(searchTerm: string): Observable<UserModel[]> {

        if (!searchTerm || searchTerm.length < 2) {
            return of([]);
        }

        const url = environment.endpoints.base + `/typeahead/tncUsersAndNonTncUsers/${searchTerm}`;
        return this.httpService.get<UserModel[]>(url);
    }

    getBulkEditUserTypeAheadData(needle: string, isInactiveIncluded?: boolean, businessUnitFilter?: string): Observable<any[]> {
        let includeInactive: boolean = false;

        if (isInactiveIncluded) {
            includeInactive = isInactiveIncluded;
        }

        if (!needle) {
            return of([]);
        }

        return this.httpService.getBulkEditTypeAheadTncUsersAndNonTncUsers((typeof needle === 'string') ? escapeQuotesAndEnters(needle) : needle, includeInactive, businessUnitFilter);
    }


    //#endregion User

    //#region Other

    /**
     * method to filter the Donor Keywords LOV (haystack).
     * @param needle filter by value
     * @returns Array of Donor Keywords matching search criteria.
     */
    public getDonorKeywords(needle: string): Observable<any[]> {
        return this.getTypeAheadData(needle, 'donorKeywords');
    }

    /**
     * method to filter object LOVs (haystack)
     * @param needle filter by value
     * @param haystack name of objects to search
     * @returns array of objects matching search criteria
     */
    private getTypeAheadData(needle: string, haystack: string): Observable<any[]> {
        if (!needle) {
            return of([]);
        }

        let url = `${environment.endpoints.base}/typeahead/${haystack}/${needle}`;
        return this.httpService.get<any[]>(url);
    }

    //#endregion other

    public getBulkEditProjectsData(prefix: string, includeDeletedRecord?: boolean, businessUnitFilter?: string): Observable<any[]> {
        let includeDeleted: boolean = false;

        if (!prefix) {
            return of([])
        }

        if (includeDeletedRecord) {
            includeDeleted = includeDeletedRecord;
        }

        return this.httpService.getBulkEditTypeAheadHubRecords(prefix, includeDeleted, businessUnitFilter)
    }

    public getCustomOutcomesData(prefix: string) {
        if (!prefix) {
            return of([])
        }
        return this.httpService.getCustomOutcomesNamesAndUnits(prefix)
    }

    public getBulkEditFinanceContacts(prefix, permissions: FinancialContactTypeAheadModel) {
        if (permissions.editorPermission === "Administrator") {
            return this.httpService.getBulkEditFinanceContactsAdminTypeAhead(prefix);
        } else {
            return this.httpService.getBulkEditFinanceContactsNonAdminTypeAhead(prefix, permissions)
        }
    }

}
