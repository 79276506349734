import { Component, Input, OnInit, inject } from '@angular/core';
import { OutcomesEditService } from '../../../services/outcomes-edit.service';
import { take } from 'rxjs';
import { AvailableParentProjectOutcomesModel, ProjectOutcomeModel } from '../../../../../../../hub_schema/hubTypes';
import { FormGroup } from '@angular/forms';
import { CreateOutcomeStateService } from '../../services/create-outcome-state.service';
import { OutcomeState } from '../../types/outcome-state';

@Component({
  selector: 'app-tnc-goals-related-panel',
  templateUrl: './tnc-goals-related-panel.component.html',
  styleUrls: ['./tnc-goals-related-panel.component.scss']
})
export class TncGoalsRelatedPanelComponent implements OnInit {
    private outcomesEditService: OutcomesEditService = inject(OutcomesEditService);
    private createOutcomeStateService: CreateOutcomeStateService = inject(CreateOutcomeStateService);
    public outcomeState: OutcomeState;

    private get outcomeForm(): FormGroup {
        return this.outcomeState.outcomeWizardForm!.controls.outcome as FormGroup;
    }

    public availableParents: AvailableParentProjectOutcomesModel[];

    public ngOnInit(): void {
        this.outcomeState = this.createOutcomeStateService.outcomeState!;

        this.outcomesEditService.getAvailableParentOutcomes(this.outcomeState.projectId).pipe(take(1)).subscribe((results: AvailableParentProjectOutcomesModel[]) => {
            this.availableParents = results;
        });
    }

    public relatedOutcomeSelected(projectOutcome: ProjectOutcomeModel): void {
        this.outcomeForm.controls.outcomeMetricId.setValue(projectOutcome.outcome!.outcomeMetricId);
        this.outcomeForm.controls.outcomeUnitId.setValue(projectOutcome.outcome!.outcomeUnitId);
        this.outcomeForm.controls.outcomeUnit.setValue(projectOutcome.outcome!.outcomeUnit);
        this.outcomeForm.controls.parentOutcomeId.setValue(projectOutcome.outcome!.outcomeId);
        this.outcomeState.outcomeWizardForm!.controls.isPolicyLeverageOutcome.setValue(projectOutcome.isPolicyLeverageOutcome);
    }
}
