import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { ChildIntermediateResultProgressModel, IntermediateResultChartDataModel, IntermediateResultDetailsModel, IntermediateResultProgressModel, ProjectIntermediateResultModel, ScaleMeasureDefinitionModel } from '../../../../../hub_schema/hubTypes';
import { IntermediateResultDetailService } from '../services/intermediate-result-detail.service';
import { take } from 'rxjs/operators';
import { formatNumber } from '@angular/common';

@Component({
    selector: 'app-intermediate-result',
    templateUrl: './intermediate-result.component.html',
    styleUrls: ['./intermediate-result.component.scss']
})
export class IntermediateResultComponent {

    // todo can we remove project detail service?
    constructor(
        private irDetailService: IntermediateResultDetailService
    ) { }

    // #region Members

    @Input()
    public intermediateResult: ProjectIntermediateResultModel;

    @Input()
    public isStrategy: boolean;

    @Input()
    public projectId: number;

    @Input()
    public irIndex: number;

    @ViewChild('detailsPanel')
    public detailsPanel: ElementRef;

    @Output()
    projectDataAreLoading = new EventEmitter<boolean>();

    public cummulativeProgress: IntermediateResultProgressModel[];

    // #endregion Members

    // #region IR Details
    public intermediateResultLeverage = "Policy";

    public detailsLoading: boolean = false;
    private detailsLoadingTimeout: any;
    private _areDetailsShowing: boolean = false;

    public get areDetailsShowing(): boolean {
        return this._areDetailsShowing;
    }

    public set areDetailsShowing(value: boolean) {
        this._areDetailsShowing = value;

        if (this._areDetailsShowing) {

            // for progress and targets, null means we haven't fetched them yet, empty means we fetched them and there aren't any
            if (this.cummulativeProgress === undefined) {

                // only show busy indicator if service takes more than 100ms to load
                this.detailsLoadingTimeout = setTimeout(() => {
                    this.detailsLoading = true;
                }, 100);

                this.irDetailService.getIntermediateResultDetails(this.intermediateResult.projectIntermediateResultId!, this.isStrategy)
                    .pipe(take(1)).subscribe((irDetails: IntermediateResultDetailsModel) => {
                        this.intermediateResult.targets = irDetails.targets;
                        this.cummulativeProgress = this.isStrategy ? irDetails.childProgress! : irDetails.progress;
                        this.detailsLoading = false;
                        clearTimeout(this.detailsLoadingTimeout);
                });
            }

            this.scrollDetailsIntoView();
        }
    }

    private scrollDetailsIntoView(): void {
        setTimeout(() => {
            const container = document.querySelector("#MainContentSection");
            const sectionTop = this.detailsPanel.nativeElement.offsetTop;
            container!.scroll({ top: sectionTop, left: 0, behavior: 'smooth' });
        });
    }

    // #endregion Details

    public getScaleLabel(value: number): string {
        const scaleMeasureDefinition = this.intermediateResult.scaleMeasureDefinitions.find(smd => smd.scaleMeasureItem.value === value) as ScaleMeasureDefinitionModel;

        if (scaleMeasureDefinition) {
            return scaleMeasureDefinition.scaleMeasureItem.value + '-' + scaleMeasureDefinition.scaleMeasureItem.label;
        }

        return value.toString();
    }

    public getScaleDescription(value: number): string {
        const scaleMeasureDefinition = this.intermediateResult.scaleMeasureDefinitions.find(smd => smd.scaleMeasureItem.value === value) as ScaleMeasureDefinitionModel;

        if (scaleMeasureDefinition) {
            return scaleMeasureDefinition.description;
        }
        return '';
    }

    public getCurrentPeriodProgressInfo(ir: ProjectIntermediateResultModel): string {
        let result: string;

        if (ir.irStatistics!.currentPeriodProgress) {
            const currentPeriodProgressPercent = (ir.irStatistics!.currentPeriodProgress / ir.irStatistics!.lastTargetValue) * 100;
            const currentPeriodProgressPercentString = `<b>${formatNumber(currentPeriodProgressPercent, 'en-US', '0.0-0')}%</b> toward target.`;
            result = `In the last year, progress of <b>${formatNumber(ir.irStatistics!.currentPeriodProgress, 'en-US', '0.0-0')} ${ir.unit} </b> achieved ${currentPeriodProgressPercentString}`;
        }
        else {
            result = 'No Progress Reported in the Last Year';
        }

        return result;
    }
}
