import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class OpenCreateRecordDialogService {

    @Output()
    public createHubRecordRequested: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    public requestHubRecordCreateDialog(): void {
        this.createHubRecordRequested.emit();
    }

}
