import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AlgoliaApiService } from './services/algolia-api.service';
import { AlgoliaKeyService } from './services/algolia-key.service';
import { AlgoliaRefreshService } from './services/algolia-refresh.service';
import { AuthService } from './services/auth/auth.service';
import { ErrorService } from './services/error.service';
import { HttpService } from './services/http.service';
import { LocalStorageService } from './services/local-storage.service';
import { OpenCreateRecordDialogService } from './services/open-create-record-dialog.service';
import { ProjectLovService } from './services/project-lov.service';
import { RecordCollectionService } from './services/record-collection.service';
import { UserDataService } from './services/user-data.service';
import { GraphqlAuthorizationService } from './services/auth/authorization/graphql-authorization.service';
import { AlgoliaAuthorizationService } from './services/auth/authorization/algolia-authorization.service';
import { EmployeeService } from './services/employee.service';
import { ToastService } from './services/toast.service';
import { HubLovService } from './services/hub-lov.service';
import { BulkEditService } from './services/bulk-edit.service';
import { HelpLovService } from '../shared/components/help-selector/services/help-lov.service';
import { OutputDetailService } from './services/output-detail.service';
import { NotificationsService } from './services/notifications.service';


@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
  ],
  providers: [
    HelpLovService,
    HttpService,
    ErrorService,
    AuthService,
    ProjectLovService,
    AlgoliaKeyService,
    AlgoliaApiService,
    AlgoliaRefreshService,
    UserDataService,
    RecordCollectionService,
    OpenCreateRecordDialogService,
    LocalStorageService,
    GraphqlAuthorizationService,
    AlgoliaAuthorizationService,
    EmployeeService,
    ToastService,
    HubLovService,
    BulkEditService, 
    OutputDetailService,
    NotificationsService
  ]
})
export class CoreModule { }
