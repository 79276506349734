<div class="view-section" id="summary">

    <div class="details">
        <div class="row">
            <div class="col-md-12">

                <h5 class="hub-level-1-label">Brief Description</h5>
                <p class="record-description pr-3" [innerHTML]="brief"></p>
            </div>
        </div>
        <div class="row" *ngIf="!isWholeSystem && !isProgramManagement && goal && goal.length">
            <div class="col-md-12">
                <h5 class="hub-level-1-label">Goal</h5>
                <p class="record-description pr-3" [innerHTML]="goal"></p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h5 class="hub-level-1-label">Timeline</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 narrative">
                <span class="hub-level-2-label">Start </span>{{startDate | date}}
            </div>
            <div class="col-md-4 narrative">
                <span class="hub-level-2-label">Planned Completion </span>{{plannedCompletionDate | date}}
            </div>
            <div class="col-md-4 narrative">
                <span class="hub-level-2-label">Actual Completion </span>{{actualCompletionDate| date}}
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-4" *ngIf="isStrategy">
                <h5 class="hub-level-1-label">Lobbying Strategy</h5>
                <p class="font-size-xl">{{project.isLobbyingRecord ? 'Yes' : 'No'}}</p>
            </div>
        </div>
        <div class="row" *ngIf="localProjectName && !isWholeSystem && !isStrategy">
            <div class="col-md-6">
                <h5 class="level-1-label">Local Project Name</h5>
                <span class="font-size-xl description-content">{{localProjectName}}</span>
            </div>
        </div>
    </div>
</div>
