<!-- Page header -->
<div class="page-header p-0 sb-l">
    <div class="page-header-content header-elements-md-inline">
        <div class="page-title d-flex">
            <h4><span class="font-weight-semibold text-success-800" id="PageTitle">Hub Administration</span></h4>
            <a href="#" class="header-elements-toggle text-default d-md-none"><i class="icon-more"></i></a>
        </div>

        <div class="header-elements d-none d-md-flex text-center text-md-left mb-3 mb-md-0"></div>
    </div>
</div>
<!-- /page header -->

<!-- Content area -->
<div class="content p-0 mt-0 pt-0" style="bottom: 0px;">
    <div class="column-med-12 bg-white pt-1 pl-3 section sb-l dflex">
        <div>
            <button *ngIf="isAllowedToAddNonTncUsersToHub()" style="float:right;"
                class="btn-outline bg-primary-300 text-primary btn-icon btn rounded-round ml-2 ng-star-inserted"
                (click)="addNonTncEmployee()" tabindex="0" title="" type="button"><i class="icon-plus-circle2 mr-2"></i> Add
                Non-TNC User</button>

            <ul class="nav nav-tabs nav-tabs-bottom mb-1">
                <li class="nav-item" *ngIf="isAdmin">
                    <a href="javscript: void(0)" class="nav-link legitRipple" data-toggle="tab"
                      (click)="setTabTo(tabs.system, $event)" [ngClass]="{active: activeTab === tabs.system}">System
                        Permissions
                    </a>
                </li>

                <li class="nav-item" *ngIf="isAllowedToBulkEdit">
                    <a href="javscript: void(0)" class="nav-link legitRipple" data-toggle="tab" (click)="setTabTo(tabs.bulkEdit, $event)"
                       [ngClass]="{active: activeTab === tabs.bulkEdit}">
                        User Bulk Editing
                    </a>
                </li>

                <li class="nav-item" *ngIf="isAllowedToEditNcsValues">
                    <a href="javscript: void(0)" class="nav-link legitRipple" data-toggle="tab" (click)="setTabTo(tabs.ncs, $event)"
                       [ngClass]="{active: activeTab === tabs.ncs}">
                        NCS Calculator Default Pool Values
                    </a>
                </li>
            </ul>
        </div>

        <div class="tab-content parent-min">

            <div class="tab-pane" [ngClass]="{active: activeTab === tabs.system, show: activeTab === tabs.system}">
                <app-system-permissions-tab></app-system-permissions-tab>
            </div>

            <div class="tab-pane" [ngClass]="{active: activeTab === tabs.bulkEdit, show: activeTab === tabs.bulkEdit}" id="bulkEdit">
                <app-user-bulk-edit-tab [isAdmin]="isAdmin"></app-user-bulk-edit-tab>
            </div>

            <div class="tab-pane" [ngClass]="{active: activeTab === tabs.ncs, show: activeTab === tabs.ncs}" id="ncs" *ngIf="isAllowedToEditNcsValues">
                <app-ncs-default-values-tab></app-ncs-default-values-tab>
            </div>
        </div>
    </div>
</div>
<!-- /content area -->

<app-modal-spinner *ngIf="isBusy"></app-modal-spinner>
