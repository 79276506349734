import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/core/services/http.service';
import { environment } from 'src/environments/environment';
import { CustomOutcomeModel } from 'src/hub_schema/hubTypes';

@Injectable()
export class OutcomesTypeaheadService {
    private httpService: HttpService = inject(HttpService);

    public getCustomOutcomeSuggestions(searchText: string): Observable<CustomOutcomeModel[]> {
        const url = environment.endpoints.base + '/projectOutcomes/customOutcomesSearch/' + searchText;
        return this.httpService.get<CustomOutcomeModel[]>(url);
    }

}
