 <div class="system-permissions-tab-container" *ngIf="usersSearchResult">
    <div class="header-and-pagination-panel">
        <h5 class="text-success">Hub Users ({{usersSearchResult.filteredUsersCount}})</h5>

        <app-hub-paginator 
            [currentPage]="usersSearchResult.page"
            [totalItems]="usersSearchResult.filteredUsersCount"
            [totalPages]="usersSearchResult.filteredPagesCount"
            (pageRequested)="pageRequested($event)">
        </app-hub-paginator>

        <p class="text-muted" style="margin-top: 5px;">
            All TNC Employees have default access to the Hub and the role of "General User." This
            role is not editable and allows View access to all records and Edit access to records
            where they are on the Record Team.
        </p>
    </div>

    <div class="results-panel hub-custom-scrollbar">

        <table class="table media-library">            
            <thead>
                <tr>
                    <th style="width:25%" id="user-column">User</th>
                    <th style="width:50%" id="roles-column">Role(s)</th>
                    <th style="width:10%" id="blocked-column">Blocked</th>
                    <th style="width:15%" id="action-column"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let user of usersSearchResult.users">
                    <td>
                        <div class="font-weight-bold font-size-xl">
                            <a routerLink="/user/{{user.userId}}">{{user.fullName}}</a>
                        </div>
                        <div class="text-muted">{{user.businessUnit.name}}</div>
                        <div class="text-muted">{{user.businessUnit.division.name}}</div>
                        <div>
                            <a href="mailto:{{user.email}}?Subject=Your Hub permissions">{{user.email}}</a>
                        </div>
                    </td>
                    <td>
                        <ul>
                            <li *ngFor="let uar of user.userApplicationRoles">
                                {{uar.applicationRole.name}}
                                <span style="font-style: italic;" *ngIf="uar.applicationRole.name === 'Business Unit Editor'"> - {{uar.businessUnit.name}}</span>
                            </li>
                            <li>General User</li>
                        </ul>
                    </td>
                    <td>
                        <div class="uniform-checker">
                            <mat-icon color="primary" *ngIf="user.isBlocked"
                                style="cursor: default;">done</mat-icon>
                        </div>
                    </td>
                    <td>
                        <button type="button" title="Edit User Roles" tabindex="0"
                            (click)="editUserRequested(user)"
                            class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple float-right">
                            <i class="icon-pencil"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>

    <div class="search-and-filter-panel" [formGroup]="systemPermissionsForm">

        <div class="text-search-panel">
            <div class="hub-search-textbox">
                <span class="search-icon">
                    <i class="icon-search4"></i>
                </span>

                <input type="text" formControlName="searchText" placeholder="Search" />

                <div [hidden]="!isClearSearchTextVisible" (click)="clearSearchText()" class="clear-icon" title="Reset">X</div>
            </div>

        </div>

        <div class="filter-panel hub-custom-scrollbar">
            <h6>User Roles</h6>

            <div class="form-group" formArrayName="applicationRoleFilters">
                <div *ngFor="let filterGroup of applicationRoleFiltersArray.controls; let i = index">
                    <div class="filter-row" [formGroupName]="i">
                        <mat-checkbox color="primary" formControlName="isSelected"></mat-checkbox>
                        <span>{{ getApplicationRoleName(filterGroup) }}</span>
                        <span class="number-of-hits">{{ getAppRoleNumberOfResults(filterGroup) }}</span>     
                    </div>
                </div>
            </div>

            <h6>User Business Units</h6>

            <div class="form-group" formArrayName="businessUnitFilters">
                <div *ngFor="let filterGroup of businessUnitFiltersArray.controls; let i = index">
                    <div class="filter-row" [formGroupName]="i">
                        <mat-checkbox color="primary" formControlName="isSelected"></mat-checkbox>
                        <span>{{ getBusinessUnitName(filterGroup) }}</span>
                        <span class="number-of-hits">{{ getBuNumberOfResults(filterGroup) }}</span>     
                    </div>
                </div>
            </div>

            <h6>Division</h6>

            <div class="form-group" formArrayName="divisionFilters">
                <div *ngFor="let filterGroup of divisionFiltersArray.controls; let i = index">
                    <div class="filter-row" [formGroupName]="i">
                        <mat-checkbox color="primary" formControlName="isSelected"></mat-checkbox>
                        <span>{{ getDivisionName(filterGroup) }}</span>
                        <span class="number-of-hits">{{ getDivisionNumberOfResults(filterGroup) }}</span>     
                    </div>
                </div>
            </div>

            <h6>Region</h6>

            <div class="form-group" formArrayName="regionFilters">
                <div *ngFor="let filterGroup of regionFiltersArray.controls; let i = index">
                    <div class="filter-row" [formGroupName]="i">
                        <mat-checkbox color="primary" formControlName="isSelected"></mat-checkbox>
                        <span>{{ getRegionName(filterGroup) }}</span>
                        <span class="number-of-hits">{{ getRegionNumberOfResults(filterGroup) }}</span>     
                    </div>
                </div>
            </div>

            <h6>Editor BU</h6>

            <div class="form-group" formArrayName="editorBuFilters">
                <div *ngFor="let filterGroup of editorBuFiltersArray.controls; let i = index">
                    <div class="filter-row" [formGroupName]="i">
                        <mat-checkbox color="primary" formControlName="isSelected"></mat-checkbox>
                        <span>{{ getEditorBuName(filterGroup) }}</span>
                        <span class="number-of-hits">{{ getEditorBuNumberOfResults(filterGroup) }}</span>     
                    </div>
                </div>
            </div>

        </div>


        <!-- <div class="filter-panel hub-custom-scrollbar" *ngFor="let facet of this.facets">

            <h6>{{facet.title}}</h6>

            <div *ngIf="!facet.isHidden">

                <div class="hub-facet-filter" *ngFor="let filter of facet.getFilters()">
                    <div class="hub-facet-filter-content" *ngIf="filter.numberOfHits">
                        <mat-checkbox [(ngModel)]="filter.isChecked" color="primary"></mat-checkbox>
                        <div class="filter-title">
                            {{filter.title}}&nbsp;
                            <span class="number-of-hits">{{filter.numberOfHits | number}}</span>
                        </div>
                    </div>
                </div>

                <div class="hub-show-more" *ngIf="facet.isCondensible">

                    <button *ngIf="facet.isCondensed" (click)="toggleFacetShowMore(facet)"
                        class="ais-RefinementList-showMore">Show More</button>

                    <button *ngIf="!facet.isCondensed" (click)="toggleFacetShowMore(facet)"
                        class="ais-RefinementList-showMore">Show Less</button>

                 </div>
            </div>
        </div> -->
    </div>
</div>