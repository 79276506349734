import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject, Output, EventEmitter } from '@angular/core';
import { AlgoliaApiViewModel } from '../../../../../shared/types/algolia-api/algolia-api-view-model';
import { SearchResultStatistics } from '../../../../../shared/types/algolia-api/search-result-statistics';
import { HitPager } from '../../../../../shared/types/algolia-api/hit-pager';
import { HubRecordEditorBase } from '../../../_hub_record_editor_base';
import { Facet } from '../../../../../shared/types/algolia-api/facet';
import { Observable, Subscription, concat, debounceTime, distinctUntilChanged, fromEvent, map, switchMap, take, tap } from 'rxjs';
import { AlgoliaKeyService } from '../../../../../core/services/algolia-key.service';
import { AlgoliaApiService } from '../../../../../core/services/algolia-api.service';
import { InsightRecordModel, TeamMemberModel, UserBusinessRoleModel } from '../../../../../../hub_schema/hubTypes';
import { MatDialog } from '@angular/material/dialog';
import { AnchorRecordConfirmationDialogComponent } from './anchor-record-confirmation-dialog/anchor-record-confirmation-dialog.component';

@Component({
  selector: 'app-insight-lookup',
  templateUrl: './insight-lookup.component.html',
  styleUrls: ['./insight-lookup.component.scss']
})
export class InsightLookupComponent extends HubRecordEditorBase implements OnInit, OnDestroy {
    private algoliaKeyService: AlgoliaKeyService = inject(AlgoliaKeyService);
    private algoliaApiService: AlgoliaApiService = inject(AlgoliaApiService);
    private dialogService: MatDialog = inject(MatDialog);

    private validRecordTypes: string[] = [
        'Conservation Project',
        'Cross-cutting initiative',
        'General Conservation',
        'Focal Area',
        'Priority Strategy',
        'Operation Exceptions',
        'Project Component',
        'Local Strategy',
        'Project Collection',
        'Whole System'
    ];

    public algoliaApiViewModel: AlgoliaApiViewModel;
    public searchResultStats: SearchResultStatistics = new SearchResultStatistics();

    @Output()
    public anchorRecordSelected: EventEmitter<InsightRecordModel> = new EventEmitter<InsightRecordModel>();

    @ViewChild('searchTextInput')
    searchTextInput: ElementRef;

    private typeAheadSubscription: Subscription;
    private reQueryRequestedSubscription: Subscription;

    // #region Paging Properties

    private readonly hitsPerPage: number = 1000;
    public hits: any[];

    public currentPageOfHits: any[];
    public hitPager: HitPager = new HitPager();

    // #endregion Paging Properties

    private candidateAnchorSelection: any;
    public isBusinessUnitMismatched: boolean = false;
    public isFinanceContactMismatched: boolean = false;
    
    public ngOnInit(): void {
        this.algoliaApiViewModel = new AlgoliaApiViewModel();

        this.reQueryRequestedSubscription = this.algoliaApiViewModel.reQueryRequested.subscribe(() => {
            this.refreshSearchData(null, this.algoliaApiViewModel.facets);
        });

        this.initializeFilters();

        this.algoliaApiService.initialize(this.algoliaKeyService.indexVocabularyKeys.projectAtTncSearch, () => {
            this.doInitialSearch();
        });
        
        this.setupTypeAhead();
        
    }

    private doInitialSearch() {
        this.refreshSearchData(() => {
            this.loadDefaultFilters();
        }, undefined);
    }

    private setupTypeAhead() {
        this.typeAheadSubscription = fromEvent(document.getElementById('searchTextInput')!, 'keyup')
            .pipe(
                debounceTime(200),
                map((e: any) => e.target.value),
                distinctUntilChanged(),
                switchMap((searchTerm) => this.getAlgoliaData(searchTerm)),
            ).subscribe();
    }

    private initializeFilters() {
        const buFacet = new Facet('basic', 'BusinessUnits.Name', 'Business Unit', 'checkbox', true, true);
        buFacet.isSearchable = true;
        buFacet.placeholderText = 'Search Business Units...';
        this.algoliaApiViewModel.facets.push(buFacet);

        const stateFacet = new Facet('basic', 'States.Name', 'State / Province', 'checkbox', true, true);
        stateFacet.isSearchable = true;
        stateFacet.placeholderText = 'Search States...';
        this.algoliaApiViewModel.facets.push(stateFacet);

        const financeContactFacet = new Facet('basic', 'FinanceContact.Name', 'Finance Contacts', 'checkbox', true, true);
        financeContactFacet.isSearchable = true;
        financeContactFacet.placeholderText = 'Search Finance Contacts...';
        this.algoliaApiViewModel.facets.push(financeContactFacet);

        this.algoliaApiViewModel.facets.push(new Facet('basic', 'RecordType', 'Record Type', 'checkbox', true, true));
        this.algoliaApiViewModel.facets.push(new Facet('basic', 'InsightStatus', 'Insight Status', 'checkbox', true, true));

        const relatedHubRecordIdFacet = new Facet('basic', 'RelatedHubRecordId', 'Related Hub ID', 'checkbox', true, true);
        relatedHubRecordIdFacet.isSearchable = true;
        relatedHubRecordIdFacet.placeholderText = 'Search by Hub ID...';
        this.algoliaApiViewModel.facets.push(relatedHubRecordIdFacet);
    }

    private loadDefaultFilters(): void {
        const isLobbyStrategy = this.isLobbyStrategy();
        const lobbyingRecordTypeFilter = [
            { title: 'Lobbying' }
        ];
        const otherRecordTypeFilter = [
            { title: 'Conservation Project' },
            { title: 'Cross-Cutting Initiative' },
            { title: 'General Conservation' },
            { title: 'Focal Area' },
            { title: 'Priority Strategy' },
            { title: 'Operation Exceptions' },
            { title: 'Project Component' },
            { title: 'Local Strategy' },
            { title: 'Project Collection' },
            { title: 'Whole System' }
        ];
        const recordTypeFilters = isLobbyStrategy ? lobbyingRecordTypeFilter : otherRecordTypeFilter;

        const defaultConfig = {
            facets: [
                {
                    key: 'InsightStatus',
                    activeFilters: [
                        { title: 'Active' }
                    ]
                },
                {
                    key: 'RecordType',
                    activeFilters: recordTypeFilters
                },
                {
                    key: 'RelatedHubRecordId',
                    activeFilters: [
                        { title: this.project.projectId.toString() },
                        { title: 'None' }
                    ]
                }
            ]
        };

        this.algoliaApiViewModel.loadSavedFilter(defaultConfig);
        this.refreshSearchData(null, this.algoliaApiViewModel.facets);
    }

    // #region Paging

    public goToPrevPage(e: Event): void {
        const prevGroupIndex = this.hitPager.currentGroupIndex - 1;
        if (this.prevPageIsDisabled()) {
            return;
        }
        this.hitPager.currentGroupIndex = prevGroupIndex;
        this.hitPager.currentPageIndex = this.hitPager.currentGroupIndex * this.hitPager.pagesPerGroup;
        this.updateCurrentPage();
        e.preventDefault();
        e.stopPropagation();
    }

    public prevPageIsDisabled() {
        const prevGroupIndex = this.hitPager.currentGroupIndex - 1;
        return prevGroupIndex < 0;
    }

    public nextPageIsDisabled() {
        if (!this.hitPager.pages) {
            return true;
        }
        const nextGroupIndex = this.hitPager.currentGroupIndex + 1;
        return (nextGroupIndex * this.hitPager.pagesPerGroup) >= this.hitPager.pages.length;
    }

    public goToNextPage(e: Event): void {
        const nextGroupIndex = this.hitPager.currentGroupIndex + 1;
        if (this.nextPageIsDisabled()) {
            return;
        }
        this.hitPager.currentGroupIndex = nextGroupIndex;
        this.hitPager.currentPageIndex = this.hitPager.currentGroupIndex * this.hitPager.pagesPerGroup;
        this.updateCurrentPage();
        e.preventDefault();
        e.stopPropagation();
    }

    public goToPage(page: number, e: Event) {
        this.hitPager.currentPageIndex = page;
        this.updateCurrentPage();
        e.preventDefault();
        e.stopPropagation();
    }

    getGroupsArr() {
        if (!this.hitPager.pages) {
            return [];
        }
        return this.hitPager.pages.slice(this.hitPager.currentGroupIndex * this.hitPager.pagesPerGroup,
            (this.hitPager.currentGroupIndex * this.hitPager.pagesPerGroup + this.hitPager.pagesPerGroup));
    }

    private updateCurrentPage() {
        const pageBounds = this.hitPager.getPageBounds();
        this.currentPageOfHits = this.hits.slice(pageBounds.start, pageBounds.end);
    }

    // #endregion Paging

    public isLobbyStrategy(): boolean {
        return this.project.projectType!.name.toLocaleLowerCase() === 'strategy' && this.project.isLobbyingRecord == true;
    }

    public hitsTrackByFunction(index: number, hit) {
        return hit.objectID;
    }

    public clearSearchText() {
        this.algoliaApiViewModel.clearSearchText();
        this.refreshSearchData(null, undefined);
    }

    public clearFilters() {
        this.algoliaApiViewModel.clearAllFilters();

        // setTimeout to avoid a hitch in the slider animations of our two range sliders.  Their animation duration is 300ms
        setTimeout(() => {
            this.refreshSearchData(undefined, undefined);
        }, 300);
    }

    private refreshSearchData(callback, facetsToPreserve: Facet[] | undefined): void {
        const searchSubscription = this.getAlgoliaData('', facetsToPreserve).subscribe(() => {
            searchSubscription.unsubscribe();
            if (callback) {
                callback();
            }
        });
    }

    private getAlgoliaData(searchTerm: string = '', facetsToPreserve: Facet[] | undefined = undefined): Observable<any> {
        if (!searchTerm) {
            searchTerm = this.algoliaApiViewModel.searchText;
        }

        //This looked like a maligned request object, not sure where SortFacetValueBy should go 04/01/2022 MB
        const request: any = {
            query: searchTerm,
            parameters: {
                facets: this.algoliaApiViewModel.getFacetNames(),
            //   facets: facetsToUse
            //   this.algoliaApiViewModel.getFacetNames(),
              hitsPerPage: this.hitsPerPage,
            },
            sortFacetValuesBy: 'alpha'
          };

        const filterString = this.algoliaApiViewModel.getFilterString();

        if (filterString) {
            request.parameters.filters = filterString;
        }
        return this.algoliaApiService.searchAlgolia(this.algoliaKeyService.indexVocabularyKeys.projectAtTncSearch, request).pipe(

            // INITIAL REQUEST
            tap((initialResult) => {
                this.searchResultStats.update(initialResult);
                this.hits = initialResult.hits.sort((a, b) => {
                    return a.RelatedHubRecordId.localeCompare(b.RelatedHubRecordId) || a.Name.localeCompare(b.Name);
                });

                this.hitPager.update(this.searchResultStats);
                this.updateCurrentPage();
                this.algoliaApiViewModel.synchronize(initialResult.facets, facetsToPreserve);
                this.transformHits();

                // PAGE REQUESTS

                const pageRequests$: any[] = [];

                // we no longer need facets, the first request took care of them.
                //Unsure if I should delete this code so I'm temporarily uncommenting it out 04/01/2022 MB
                // request.parameters.facets = undefined;

                for (let pg = 1; pg <= initialResult.nbPages; pg++) {
                    const getPage$ = this.algoliaApiService.searchAlgolia(this.algoliaKeyService.indexVocabularyKeys.projectAtTncSearch, { ...request, page: pg });
                    pageRequests$.push(getPage$);
                }
                const allPagesSubscription = concat(...pageRequests$).subscribe(
                    (pageResult: any) => {
                        this.hits.push(...pageResult.hits);
                    },

                    (err) => { },

                    () => {
                        if (!!allPagesSubscription) {
                            allPagesSubscription.unsubscribe();
                        }

                        this.transformHits(this.hitsPerPage);
                    }
                );
            })
        );
    }

    public filterChanged(facetToPreserve): void {
        this.getAlgoliaData('', [facetToPreserve]).pipe(take(1)).subscribe();
    }

    private transformHits(hitOffset: number = 0): void {
        const isLobbyStrategy = this.isLobbyStrategy();

        for (let i = 0 + hitOffset; i < this.hits.length; i++) {
            const item = this.hits[i];

            if (item.BusinessUnits) {
                item.multipleBus = item.BusinessUnits.length > 1;
                item.businessUnitNames = item.BusinessUnits.map((bu) => bu.Name).join();
            }
            else {
                item.multipleBus = false;
                item.businessUnitNames = '';
            }

            item.isDisabled = item.InsightStatus !== 'Active';

            if (item.RelatedHubRecordId && item.RelatedHubRecordId !== 'None') {
                const relatedHubId: number = Number(item.RelatedHubRecordId);
                item.isDisabled = item.isDisabled || relatedHubId !== this.project.projectId;
            }

            if (isLobbyStrategy) {
                item.isDisabled = item.isDisabled || item.RecordType !== 'Lobbying';
            }
            else {
                const validRecordType = this.validRecordTypes.includes(item.RecordType);
                item.isDisabled = item.isDisabled || !validRecordType;
            }
        }
    }

    // #region Filters

    public filterPanelClosed() {
    }

    public filterPanelOpened() {
    }

    public facetShowMore(facet) {
        facet.isCondensed = false;
    }

    public facetShowLess(facet) {
        facet.isCondensed = true;
    }
    
    // #endregion
    
    public selectInsightRecord(hit) {
        const projectLeadBu = this.project.projectBusinessUnits!.find((bu) => bu.isLeadBusinessUnit)!;

        const projectFinanceContact = this.project.team!.find(
            (tm: TeamMemberModel) => tm.userBusinessRoles!.find(
                (ubr: UserBusinessRoleModel) => ubr.businessRole!.name.toLowerCase() === 'finance contact'));

        this.isBusinessUnitMismatched = hit.BusinessUnits.find((bu) => bu.Name === projectLeadBu.businessUnit!.name) === undefined;

        // HUB-4700: Insight record may have a null Finance Contact
        const hitFinanceContactName: string = hit.FinanceContact.Name || '';
        this.isFinanceContactMismatched = hitFinanceContactName.toLowerCase() !== projectFinanceContact!.user!.fullName!.toLowerCase();

        if (this.isBusinessUnitMismatched || this.isFinanceContactMismatched) {            
            const dialogRef = this.dialogService.open(AnchorRecordConfirmationDialogComponent, {
                data: {
                    isBusinessUnitMismatched: this.isBusinessUnitMismatched,
                    isFinanceContactMismatched: this.isFinanceContactMismatched
                }
            });

            dialogRef.afterClosed().pipe(take(1)).subscribe((userConfirmed) => {
                if (userConfirmed) {
                    this.candidateAnchorSelection = hit;
                    this.anchorRecordSelected.emit(hit);        
                }
            });
        }
        else {
            this.anchorRecordSelected.emit(hit);
        }
    }
    
    public ngOnDestroy(): void {
        if (this.reQueryRequestedSubscription) {
            this.reQueryRequestedSubscription.unsubscribe();
        }
    }
}
