import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpService } from '../../../../core/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class AssociatedFilesDetailService {

    constructor(private httpService: HttpService) { }

    // todo: are we using this information? If not, let's stop collecting it.
    public incrementViewCount(linkId: number): Observable<void> {
        const url = environment.endpoints.base + '/projectLink/incrementViewCount/' + linkId;
        return this.httpService.post<void>(url, null);
    }
}
