import { Component, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HubRecordEditService } from '../../services/hub-record-edit.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss']
})
export class UnsavedChangesDialogComponent {
    private dialogRef: MatDialogRef<UnsavedChangesDialogComponent> = inject(MatDialogRef<UnsavedChangesDialogComponent>);
    private hubRecordEditService: HubRecordEditService = inject(HubRecordEditService);
    public isSaving: boolean = false;

    public cancel(): void {
        this.dialogRef.close(false);
    }

    public closeWithoutSaving(): void {
        this.dialogRef.close(true)
    }

    public isSaveProjectDisabled(): boolean {
        return !this.hubRecordEditService.isProjectValid || !this.hubRecordEditService.isProjectNameValid;
    }

    public getSaveButtonTooltip(): string {
        if (this.isSaveProjectDisabled()) {
            return 'The form contains invalid data that must be corrected before the record can be saved.';
        }
        return '';
    }
    
    public saveProject(): void {
        this.isSaving = true;
        this.hubRecordEditService.updateProject().pipe(take(1)).subscribe(() => {
            this.isSaving = false;
            this.dialogRef.close(true);
        });
        
    }
}
