import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuditLogModel } from '../../../../../hub_schema/hubTypes';
import { HttpService } from '../../../../core/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class AuditLogService {
  constructor(private httpService: HttpService) { }

  public getAuditLogs(id: number): Observable<any> {
    const url = environment.endpoints.base + '/projects/auditLog/' + id;
    return this.httpService.get<AuditLogModel>(url);
  }
}
