import { Component, AfterViewInit, OnDestroy, Input, NgZone } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { GoalColors } from '../../shared/goal-colors';
import { environment } from '../../../../environments/environment';

am4core.useTheme(am4themes_animated);
am4core.addLicense(environment.amCharts.license);
am4core.options.autoSetClassName = true;

@Component({
    selector: 'app-metric-donut-chart',
    templateUrl: './metric-donut-chart.component.html',
    styleUrls: ['./metric-donut-chart.component.scss']
})
export class MetricDonutChartComponent implements OnDestroy {

    constructor(
        private zone: NgZone
    ) { }

    private chart: am4charts.PieChart;

    private _metric: any;

    @Input()
    public metricIndex: number;

    public get metric(): any {
        return this._metric;
    }

    @Input()
    public set metric(value: any) {
        this._metric = value;
        this.renderChart();
    }

    @Input()
    public goalId: number;

    private renderChart(): void {
        setTimeout(() => {
            this.zone.runOutsideAngular(() => {
                this.createChart();
            });
        });
    }

    //  M.B. 12-08-22 Pulled out code in the outsideAngularZone callback
    // into separate function for readability and ease-of-unit testing
    public createChart(): void {

        let chart = am4core.create('chartdiv_metric_' + this.metricIndex, am4charts.PieChart);
        const percentComplete = Number(this.metric.progressValue) / Number(this.metric.targetValue);
        const strPercentComplete = Number(percentComplete).toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 1 });

        // Handle situation where the progress is greater than the target.
        const targetRemaining = percentComplete > 1 ? 0 : 1 - percentComplete;

        let goalColorName = 'goal_' + this.goalId;
        let goalColor = GoalColors[goalColorName];

        chart.data = [
            {
                series: 'progress',
                progress: percentComplete,
                color: am4core.color(goalColor)
            },
            {
                series: 'total',
                progress: targetRemaining,
                color: am4core.color('#ddd')
            }

        ];

        chart.innerRadius = am4core.percent(65);

        const label = chart.seriesContainer.createChild(am4core.Label);
        label.text = strPercentComplete;
        label.horizontalCenter = 'middle';
        label.verticalCenter = 'middle';
        label.fontSize = 18;
        label.fontWeight = 'bold';

        const pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = 'progress';
        pieSeries.dataFields.category = 'series';
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;
        pieSeries.slices.template.tooltipText = '';
        pieSeries.slices.template.propertyFields.fill = 'color';

        // disable default hover effect
        var hoverState = pieSeries.slices.template.states.getKey("hover");
        hoverState.properties.scale = 1;

        this.chart = chart;
    }

    public ngOnDestroy(): void {

        if (this.chart) {
            this.chart.dispose();
        }
    }

}
