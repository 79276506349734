import { Component, Input } from '@angular/core';
import { ProjectBusinessUnitModel, ProjectRelationModel } from '../../../../../hub_schema/hubTypes';
import { HubRecordTypes } from '../../../../shared/types/hub-record-types';

@Component({
    selector: 'app-program-management-relations-container',
    templateUrl: './program-management-relations-container.component.html',
    styleUrls: ['./program-management-relations-container.component.scss']
})
export class ProgramManagementRelationsContainerComponent {

    @Input()
    public relations: ProjectRelationModel[];

    public get relatedStrategies(): ProjectRelationModel[] {
        return this.relations.filter((r) => r.relatedProject!.projectTypeName === HubRecordTypes.Strategy);
    }

    public get relatedProjects(): ProjectRelationModel[] {
        return this.relations.filter((r) => r.relatedProject!.projectTypeName === HubRecordTypes.Project);
    }

    public get relatedWholeSystems(): ProjectRelationModel[] {
        return this.relations.filter((r) => r.relatedProject!.projectTypeName === HubRecordTypes.WholeSystem);
    }

    @Input()
    public projectType: string;

    private _projectBusinessUnits: ProjectBusinessUnitModel[];

    public get projectBusinessUnits(): ProjectBusinessUnitModel[] {
        return this._projectBusinessUnits;
    }

    @Input()
    public set projectBusinessUnits(value: ProjectBusinessUnitModel[]) {
        this._projectBusinessUnits = value.sort((a: ProjectBusinessUnitModel, b: ProjectBusinessUnitModel) => {
            if (!a.isLeadBusinessUnit && b.isLeadBusinessUnit) {
                return 1;
            } else if (a.isLeadBusinessUnit && !b.isLeadBusinessUnit){
                return -1;
            } else if (a.businessUnit!.name < b.businessUnit!.name){
                return -1;
            } else if (a.businessUnit!.name > b.businessUnit!.name){
                return 1;
            }
            return 0;
        });
    }

    private _regionNames: string[];

    public get regionNames(): string[] {
        if (!this._regionNames) {

            if (this.projectBusinessUnits) {
                const distinctRegionProjectBus = this.projectBusinessUnits.filter(
                    (pbu: ProjectBusinessUnitModel, i, arr) => arr.findIndex((t) => t.businessUnit!.region.regionId === pbu.businessUnit!.region.regionId) === i
                );
                this._regionNames = distinctRegionProjectBus
                    .map((r: ProjectBusinessUnitModel) => r.businessUnit!.region.name)
                    .sort();
            }

        }
        return this._regionNames;
    }

    private _divisionNames: string[];

    public get divisionNames(): string[] {
        if (!this._divisionNames) {
            if (this.projectBusinessUnits) {
                const distinctDivisionProjectBus = this.projectBusinessUnits.filter(
                    (pbu: ProjectBusinessUnitModel, i, arr) => arr.findIndex((t) => t.businessUnit!.division.divisionId === pbu.businessUnit!.division.divisionId) === i

                );
                this._divisionNames = distinctDivisionProjectBus
                    .map((r: ProjectBusinessUnitModel) => r.businessUnit!.division.name)
                    .sort();
            }
        }
        return this._divisionNames;
    }

    trackByFn(index) {
        return index;
    }
}
