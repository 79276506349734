import { Component, Input, Output, EventEmitter } from '@angular/core';

const COLLECTIONS_PER_PAGE = 8;
const PAGES_PER_GROUP: number = 10;

@Component({
  selector: 'app-hub-paginator',
  templateUrl: './hub-paginator.component.html',
  styleUrls: ['./hub-paginator.component.scss']
})
export class HubPaginatorComponent {
    
    private _currentPage: number;

    public get currentPage(): number {
        return this._currentPage;
    }

    @Input()
    public set currentPage (value: number) {
        this._currentPage = value;
        this.populateVisiblePages();
    }

    private _totalItems: number;

    public get totalItems(): number {
        return this._totalItems
    }

    @Input()
    public set totalItems(value: number) {
        this._totalItems = value;
        this.populateVisiblePages();
    }

    @Input()
    public totalPages: number;

    public pages: number[];

    @Output()
    public pageRequested: EventEmitter<number> = new EventEmitter<number>();

    private populateVisiblePages(): void {
        this.pages = [];
        const startIndex = Math.floor(this.currentPage / PAGES_PER_GROUP) * PAGES_PER_GROUP;
        let endIndex = startIndex + PAGES_PER_GROUP;

        const lastPageIndex = Math.ceil(this.totalItems / COLLECTIONS_PER_PAGE);
        
        if (endIndex > lastPageIndex) {
            endIndex = lastPageIndex;
        }
      
        for (var i = startIndex; i < endIndex; i++) {
            this.pages.push(i);
        }
    }

    public isFirstPageDisabled(): boolean {
        return this.isPreviousPageDisabled();
    }

    public goToFirstPage(): void {
        this.pageRequested.emit(0);
    }

    public isPreviousPageDisabled(): boolean {
        return this.currentPage === 0;
    }

    public goToPreviousPage(): void {
        this.pageRequested.emit(this.currentPage - 1);
    }

    public goToPage(page: number): void {
        this.pageRequested.emit(page);
    }

    public isGotoNextPageDisabled(): boolean {
        return this.currentPage === this.totalPages - 1;
    }

    public goToNextPage(): void {
        this.pageRequested.emit(this.currentPage + 1);
    }

    public isGotoLastPageDisabled(): boolean {
        return this.isGotoNextPageDisabled();
    }

    public goToLastPage(): void {
        const page = this.totalPages - 1;
        this.pageRequested.emit(page);
    }

}
