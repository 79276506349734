<div class="active show tab-pane fade p-0">
    <div class="form-group">
        <h3 class="text-info-800 mb-2 mt-2">
            Donor Keywords <app-help-selector [selector]="1136"></app-help-selector>
        </h3>
        <div class="input-group">
            <div class="tokenfield form-control">
                <div>
                    <div class="token" *ngFor="let pdk of projectDonorKeywords">
                        <span class="token-label">{{pdk.keyword}}</span>
                        <span class="close" (click)="removeKeyword(pdk)"
                            aria-hidden="true">×</span>
                    </div>
                </div>

                <div class="hub-select-container">
                    <mat-form-field>
                        <input type="text" matInput [formControl]="keywordTypeahead"
                            [matAutocomplete]="auto" placeholder="Add keyword..." />
                    </mat-form-field>

                    <mat-autocomplete #auto="matAutocomplete"
                        [displayWith]="displayKeywordFn.bind(this)"
                        (optionSelected)="keywordSelected($event)">
                        <mat-option
                            *ngFor="let keyword of keywordSuggestions$ | async"
                            [value]="keyword">
                            {{keyword}}
                        </mat-option>
                    </mat-autocomplete>
                </div>
            </div>
        </div>
    </div>
</div>

