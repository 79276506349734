<div class="hub-change-user-container" >
    <div class="user-list" *ngIf="isUserSelectionPanelVisible">

        <div class="hub-select-container">
            <mat-form-field>
                <input type="text" #typeaheadInputControl matInput [formControl]="userTypeaheadInput" [matAutocomplete]="usersAutocomplete" placeholder="Type User Name">
            </mat-form-field>

            <mat-autocomplete #usersAutocomplete="matAutocomplete" [displayWith]="displayUser.bind(this)" (optionSelected)="selectUser($event)">
                <mat-option *ngFor="let user of filteredUsers$ | async" [value]="user">
                    {{user.fullName}}
                </mat-option>
            </mat-autocomplete>
        </div>

    </div>
    <button class="hub-icon-button" (click)="toggleUserSelector()">
        <b><i class="icon-user"></i></b>Change User
    </button>

</div>
