import { environment } from 'src/environments/environment';
import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.auth.clientId,
    authority: 'https://login.microsoftonline.com/' + environment.auth.tenant,
    redirectUri: '/auth-callback',
    postLogoutRedirectUri: '/logged-out',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // we store the MSAL token here.  Our own token will be in a cookie (configured elsewhere)
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        // console.log(message); // uncomment if you are experiencing problems with MSAL
      },
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false
    }
  }
}
