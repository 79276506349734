import { Component, Input } from '@angular/core';
import { take } from 'rxjs/operators';
import { ProjectLinkModel } from '../../../../hub_schema/hubTypes';
import { AssociatedFilesDetailService } from './services/associated-files-detail.service';


@Component({
    selector: 'app-associated-files-container',
    templateUrl: './associated-files-container.component.html',
    styleUrls: ['./associated-files-container.component.scss']
})
export class AssociatedFilesContainerComponent {

    @Input()
    public links: ProjectLinkModel[];

    constructor(private associatedFilesDetailService: AssociatedFilesDetailService) { }
    
    public incrementViewCount(linkId: number): void {
        this.associatedFilesDetailService.incrementViewCount(linkId).pipe(take(1)).subscribe();
    }
}
