<div class="create-link-modal" *ngIf="outputLinkForm">
    <div class="hub-modal-header-text">
        <h5 class="modal-title text-success">{{existingLink ? 'Update' : 'Create'}} Web / File Link</h5>
    </div>
    <div class="content">
        <form [formGroup]="outputLinkForm">
            <div class="modal-body p-1">
                <div class="row">
                    <div class="col-md-12">
                        <span class="select-label text-info-700 pb-2 d-block">Link Name<app-help-selector [selector]="1126"></app-help-selector></span>
                        <div>
                            <input formControlName="name" type="text" class="form-control" placeholder="Link Name"
                                   [maxLength]="100">
                            <div class="hub-validation-error-message">
                                {{getValidationErrorMessages(outputLinkForm.get('name'))}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <span class="select-label text-info-700 pb-2 mt-2 d-block">Link URL<app-help-selector [selector]="1127"></app-help-selector></span>
                        <div>
                            <input formControlName="url" type="text" class="form-control" placeholder="Link URL">
                            <div class="hub-validation-error-message">
                                {{getValidationErrorMessages(outputLinkForm.get('url'))}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="hub-modal-footer-text">
        <button type="button" class="cancel-button mr-1" (click)="cancel()">Cancel</button>

        <button type="button" class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
                [disabled]="outputLinkForm.invalid || isSaving" (click)="saveLink()">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>
            Save
        </button>

    </div>

</div>
