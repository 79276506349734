import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '../core/guards/role-guard';
import { SharedModule } from '../shared/shared.module';
import { PortfolioComponent } from './components/portfolio.component';
import { SaveFiltersDialogComponent } from './components/save-filters-dialog/save-filters-dialog.component';
import { LeavePortfolioGuard } from './guards/leave-portfolio.guard';
import { Outcome2030GoalsMetricFacetDisplayComponent } from './components/outcome-2030-goals-metric-facet-display/outcome-2030-goals-metric-facet-display.component';

const routes: Routes = [
    { path: 'portfolio', component: PortfolioComponent, canActivate: [RoleGuard], canDeactivate: [LeavePortfolioGuard] },
];

@NgModule({
    imports: [
        MatSlideToggleModule,
        SharedModule,
        RouterModule.forChild(routes)
    ],
    providers: [RoleGuard, LeavePortfolioGuard],
    declarations: [
        PortfolioComponent,
        SaveFiltersDialogComponent,
        Outcome2030GoalsMetricFacetDisplayComponent,
    ]
})
export class PortfolioModule { }
