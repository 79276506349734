<div class="hub-team-content" *ngIf="projectEditForm && businessRoles">

    <div class="team-content-header mt-2">
        <h5 class="edit-section-header">Team</h5>

        <div class="hub-invalid-team-message" *ngIf="!isTeamValid">
            A Team Lead and Finance Contact are required.
        </div>

        <button type="button"
            class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round ml-2 legitRipple"
            style="max-height: 36px;" tooltip="Add a team member" data-original-title="Add a new Team Member"
            tabindex="0" (click)="openTeamMemberDialog()"> <em class="icon-plus-circle2 mr-2"></em>
            Add Team Member
        </button>
    </div>

    <div class="hub-team-panel" @list>
        <div class="hub-role" *ngFor="let role of getFilledBusinessRoles(); trackBy: trackByRoles" @roles>
            <h5>{{role.name}}</h5>

            <div class="hub-team-member" @team-members
                *ngFor="let teamMember of getTeamMembersByRole(role.businessRoleId); trackBy: trackByTeamMembers">

                <div class="member-info">
                    <div class="btn btn-success round">{{teamMember.user.initials}}</div>
                    <div class="team-member-info">
                        <div class="team-member-name-line">
                            <a [routerLink]="'/user/' + teamMember.user.userId" class="team-member-name"
                                [ngClass]="{'inactive': !teamMember.user.isActive}">
                                {{teamMember.user.fullName}}
                                <span *ngIf="!teamMember.user.isActive"> (Inactive)</span>
                            </a>
                            <span class="team-member-poc"
                                *ngIf="teamMember.isPoc">(Point of Contact)</span>
                            <span class="team-member-fe" *ngIf="isFinanceEditor(teamMember.user)">(Finance Editor)</span>
                        </div>
                        <div class="team-member-department">
                            {{teamMember.user.businessUnit ? teamMember.user.businessUnit.name: teamMember.user.businessUnitName}}
                        </div>
                        <div class="team-member-location">{{teamMember.user.office}}</div>
                    </div>
                </div>
                <div class="team-member-action-buttons">

                    <button (click)="openTeamMemberDialog(teamMember)"
                        matTooltip="Edit Team Member"
                        matTooltipShowDelay="500"
                        matTooltipHideDelay="200" >
                        <i class="icon-pencil"></i>
                    </button>

                    <button (click)="removeTeamMember(teamMember)"
                        matTooltip="Remove {{teamMember.user.fullName}} from '{{role.name}}' Role"
                        matTooltipShowDelay="500"
                        matTooltipHideDelay="200">
                        <i class="icon-trash"></i>
                    </button>
                </div>
            </div>
        </div>

    </div>

</div>

<div *ngIf="!anyTeamPresent()" class="alert alert-info alert-styled-left shadow-0">
    There are no team members defined yet.
</div>
