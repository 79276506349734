<li [ngClass]="{'selected-dark': isSelected}">

    <div class="mr-2">
      <a href="#" class="btn btn-success rounded-round btn-icon legitRipple" tabindex="0">
        <i class="icon-pause2"></i>
      </a>
    </div>
  
    <div class="media-body">
      <h6 class="font-weight-bold text-success-800 p-0">Strategic Pillar</h6>
  
      <button type="button" class="btn-outline bg-primary-300 text-primary btn-icon btn rounded-round float-right" style="margin-right: 8px;"
        (click)="deleteStrategicPillarClicked($event)" tabindex="0">
        <i class="icon-trash"></i>
      </button>
  
      <h5 class="text-success mb-2" [innerHTML]="strategicPillar.name | innerHtml"></h5>
      <div class="record-description" [innerHTML]="strategicPillar.description | innerHtml"></div>
    </div>
  </li>
  