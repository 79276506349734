<div 
    class="current mb-2 p-1 sb-b body hub-custom-scrollbar" role="tabpanel" aria-hidden="true" >

    <div class="row" [formGroup]="outcomeState.outcomeWizardForm" *ngIf="outcomeState.isReady && !!availableParents" >
        <div class="col-md-12" >
            
            <div *ngIf="availableParents.length > 0; else noRelations">
                <div formGroupName="outcome">
                    <mat-radio-group formControlName="parentOutcomeId">
                        <div class="mb-2">
                            <strong>Related Strategies and their 2030 Goals Outcomes</strong>
                        </div>

                        <div *ngFor="let availableParent of availableParents">
                            <h4 [ngClass]="{'text-success': availableParent.strategyType === 'Primary Strategy', 'additional-strategy-text': availableParent.strategyType === 'Additional Strategy'}">
                                <strong>{{availableParent.strategyName}}</strong> ({{availableParent.strategyType}})
                                <span class="text-muted" style="font-size: 11pt;"> {{availableParent.relationshipType}}</span>
                            </h4>

                            <label *ngFor="let projectOutcome of availableParent.projectOutcomes" class="outcomes-radio-button">

                                <mat-radio-button color="primary" [value]="projectOutcome.outcome.outcomeId"
                                    (change)="relatedOutcomeSelected(projectOutcome, availableParent)">
                                </mat-radio-button>

                                <div class="radio-button-content">
                                    <strong>{{projectOutcome.outcome.outcomeMetric.name}}</strong>
                                    ({{projectOutcome.outcome.outcomeUnit.name}})<br>
                                    <span class="text-muted">{{projectOutcome.statement}}</span>
                                </div>
                            </label>

                        </div>
                        
                    </mat-radio-group>

                    <app-ncs-panel></app-ncs-panel>

                </div>

                <span class="select-label text-info-800">Outcome Statement<app-help-selector
                    selector="1069"></app-help-selector></span>
                <div >
                    <textarea formControlName="statement" rows="3" cols="3"
                        class="form-control record-description" appAutoFocus
                        placeholder="Enter your outcome statement here..."></textarea>
                </div>
            </div>

            <ng-template #noRelations>
                <div class="no-relations">
                    You currently do not have any Related Strategies and/or your Related Strategies have
                    no 2030 Goals Outcomes.
                </div>
            </ng-template>

        </div>
    </div>
</div>
