<div class="outcome-target-panel" [formGroup]="editTargetForm" *ngIf="editTargetForm">
    <div class="row" *ngIf="showOutcomeStatement">
        <div class="col-md-12">
            <p class="text-center font-weight-bold sb-b p-1">
                Set a Target Value for your Outcome Metric
            </p>
            <p class="text-muted">
                {{ projectOutcome.statement }}
            </p>
        </div>
    </div>
    <div *ngIf="!isNcsOutcome else ncsTargetPanel">
        <div>
            <fieldset class="mb-2 p-0">
                <div class="row">

                    <div class="col-md-12">
                        <div class="target-container">

                            <div class="target-date-column">
                                <span class="select-label text-info-800">
                                    Target Date
                                    <app-help-selector [selector]="1097"></app-help-selector>
                                </span>

                                <mat-form-field>
                                    <input matInput readonly (click)="targetDatePicker.open()" [matDatepicker]="targetDatePicker" formControlName="targetDate" placeholder="Target Date">
                                    <mat-datepicker-toggle matIconSuffix [for]="targetDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #targetDatePicker></mat-datepicker>
                                </mat-form-field>

                                <div class="hub-validation-error-message"
                                    [innerHtml]="getValidationErrorMessages('targetDate')">
                                </div>
                            </div>

                            <div class="target-value-column text-right">
                                <span class="select-label text-info-800">Target Value
                                    <app-help-selector [selector]="1096"></app-help-selector>
                                </span>

                                <mat-form-field>
                                    <input matInput class="form-control" formControlName="targetValue" placeholder="Target Value" type="number" />
                                </mat-form-field>

                                <div class="hub-validation-error-message"
                                    [innerHtml]="getValidationErrorMessages('targetValue')">
                                </div>

                            </div>

                            <div class="unit-name-column">
                                <span class="select-label text-info-800 d-block">
                                    Units<app-help-selector [selector]="1078">
                                    </app-help-selector>
                                </span>

                                <div class="form-control" disabled="disabled" style="padding-top: 13px; padding-bottom: 18px">
                                    {{ getUnitName() }}
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </fieldset>
        </div>
    </div>

    <ng-template #ncsTargetPanel>
        <div>
            <div class="ncs-info-panel">
                <div class="pathway">
                    <div class="select-label">NCS Pathway</div>
                    <div class="value-label">
                        {{ ncsPathwayName }}
                    </div>
                </div>
                <div class="intervention">
                    <div class="select-label">
                        Intervention Type
                    </div>
                    <div class="value-label">
                        {{ ncsIntervention?.name }}
                    </div>
                </div>
                <div class="country">
                    <div class="select-label">Country</div>
                    <div class="value-label">
                        {{ ncsCountry.name }}
                    </div>
                </div>
            </div>

            <div class="ncs-panel">
                <div class="spatial-extent-panel" *ngIf="showSpatialExtent">
                    <label class="select-label">Spatial Extent</label>

                    <mat-form-field>
                        <input matInput type="number" [formControl]="ncsExtentControl" />
                    </mat-form-field>

                    <label class="unit-label">hectares</label>
                </div>
                <div class="spatial-extent-panel">
                    <div></div>

                    <small class="hub-validation-error-message">
                        {{ getExtentValidationErrorMessage() }}
                    </small>
                </div>

                <app-ncs-estimates-panel
                    [ncsInterventionId]="projectOutcome.outcome.ncsInterventionId"
                    [ncsCountryId]="projectOutcome.outcome.ncsCountryId"
                    [extent]="ncsExtentControl.value"
                    [metricType]="targetType"
                    (metricValueChanged)="targetValueChanged($event)"
                    (pendingRecalculationChanged)="pendingRecalculationChanged($event)">
                </app-ncs-estimates-panel>

                <div class="target-data-panel">
                    <h5>Target Data</h5>

                    <table>
                        <tr>
                            <td class="target-data-label-column">Target Date</td>
                            <td class="target-data-value-column">

                                <mat-form-field>
                                    <input matInput readonly (click)="targetDatePicker.open()" [matDatepicker]="targetDatePicker" formControlName="targetDate" placeholder="Target Date">
                                    <mat-datepicker-toggle matIconSuffix [for]="targetDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #targetDatePicker></mat-datepicker>
                                </mat-form-field>

                                <div class="hub-validation-error-message"
                                    [innerHtml]="getValidationErrorMessages('targetDate')">
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td class="target-data-label-column">Target Value</td>
                            <td class="target-data-value-column">
                                <span style="font-weight: 500; margin-right: 10px;">
                                    {{ targetValue | number : "1.3-3" }}
                                </span>
                                <span>
                                    tCO2e/yr
                                </span>
                            </td>
                            <td class="button-column">
                                <a href="javascript:void(0)" (click)="showCalculations()" style="margin-left: 20px;">
                                    Show Calculations
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </ng-template>

</div>
