<!-- HIDE IF NO LINKS DEFINED -->
<div *ngIf="links?.length > 0" class="view-section" id="associatedFiles">
    <h3 class="view-section-header">Associated Files</h3>
    <div class="details">
        <div class="row">
            <div class="col-md-12" *ngFor="let link of links">
                <a [href]="link.url" class="dropdown-item text-primary" target="_new"
                    (click)="incrementViewCount(link.projectLinkId)">
                    <i class="icon-link2"></i> {{link.title}} <span
                        class="badge badge-dark ml-auto">{{link.ancillaryType.name}}</span>
                </a>
            </div>
        </div>
    </div>
</div>
