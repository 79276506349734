import { Component, OnInit, inject, Input, ChangeDetectorRef, OnDestroy, EventEmitter } from '@angular/core';
import { OutcomesEditService } from '../services/outcomes-edit.service';
import { FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, Subscription, take } from 'rxjs';
import { ProjectOutcomeModel } from '../../../../../hub_schema/hubTypes';
import { CreateOutcomeStateService } from './services/create-outcome-state.service';
import { OutcomeState } from './types/outcome-state';

@Component({
    selector: 'app-create-outcome-dialog',
    templateUrl: './create-outcome-dialog.component.html',
    styleUrls: ['./create-outcome-dialog.component.scss']
})
export class CreateOutcomeDialogComponent implements OnInit, OnDestroy {
    // dependencies
    private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
    private outcomesEditService: OutcomesEditService = inject(OutcomesEditService);
    private dialogRef: MatDialogRef<CreateOutcomeDialogComponent> = inject(MatDialogRef<CreateOutcomeDialogComponent>);
    private dialogData: any = inject(MAT_DIALOG_DATA);
    private createOutcomeStateService: CreateOutcomeStateService = inject(CreateOutcomeStateService);

    // wizard state (todo: maybe consider a single state object in the service?)
    public outcomeState: OutcomeState;
    public currentStepNumber: number = 0;
    public isSaving: boolean = false;

    public ngOnInit(): void {
        this.outcomeState = this.createOutcomeStateService.initialize(this.dialogData);

        this.outcomeState.outcomeWizardForm = new FormGroup({
            projectId: new FormControl(this.outcomeState.projectId),
            statement: new FormControl('', Validators.required),
            isPolicyLeverageOutcome: new FormControl(false),
            outcome: new FormGroup({
                outcomeMetricId: new FormControl(null, Validators.required),
                outcomeUnitId: new FormControl(null, Validators.required),
                outcomeUnit: new FormControl(null),
                parentOutcomeId: new FormControl(null),
                ncsInterventionId: new FormControl(null),
                ncsCountryId: new FormControl(null),
                spatialExtent: new FormControl(null)
            }),
            customOutcome: new FormGroup({
                indicatorName: new FormControl('', Validators.required),
                unit: new FormControl('', Validators.required),
                parentCustomOutcomeId: new FormControl(null)
            }),
            targets: new FormArray([
                new FormGroup({
                    targetDate: new FormControl(null, [Validators.required, this.validateTargetDate]),
                    targetValue: new FormControl(null, Validators.required)
                })
            ])
        });
    }

    public get isNcsTabShowing(): boolean {
        return this.outcomeState.usingNcsCalculator && this.outcomeState.is2030Goals;
    }

    private isNcsRecalculationPending: boolean = false;

    // This just prevents the ExpressionChangedAfterItHasBeenCheckedError console error 
    public ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }
    
    public get targetForm(): FormGroup {
        return (this.outcomeState.outcomeWizardForm!.controls.targets as FormArray).controls[0] as FormGroup;
    }

    public get ncsExtentControl(): FormControl {
        return (this.outcomeState.outcomeWizardForm!.controls.outcome as FormGroup).controls.spatialExtent as FormControl;
    }

    public isOutcomeDetailsStepCompleted(): boolean {
        let isValid = true;

        if (this.outcomeState.is2030Goals) {
            const outcomeForm = this.outcomeState.outcomeWizardForm!.controls.outcome as FormGroup; 

            isValid = outcomeForm.controls.outcomeMetricId.valid
                && outcomeForm.controls.outcomeUnitId.valid
                && outcomeForm.controls.ncsInterventionId.valid;

        }
        else {
            const customOutcomeForm = this.outcomeState.outcomeWizardForm!.controls.customOutcome as FormGroup;

            isValid = customOutcomeForm.controls.indicatorName.valid 
                && customOutcomeForm.controls.unit.valid;
        }

        isValid = isValid && this.outcomeState.outcomeWizardForm!.controls.statement.valid;
        return isValid;
    }

    public isLocationAndSpatialExtentStepCompleted(): boolean {
        const isValid = (this.outcomeState.outcomeWizardForm!.controls.outcome as FormGroup).controls.ncsCountryId.valid
            && (this.outcomeState.outcomeWizardForm!.controls.outcome as FormGroup).controls.spatialExtent.valid;
        
        return isValid;
    }

    public isTargetStepCompleted(): boolean {
        return (this.outcomeState.outcomeWizardForm!.controls.targets as FormArray).valid;
    }

    private validateTargetDate(targetDateControl: FormControl): ValidationErrors | null {
        if (!targetDateControl.touched) {
            return null;
        }

        const targetDateObj = targetDateControl.value;
        const targetDate = new Date(targetDateObj.year, targetDateObj.month - 1, targetDateObj.day);

        const today = new Date();

        if (targetDate.getTime() < today.getTime()) {
            return {
                'invalid': 'Target Dates must be in the future.'
            };
        }
        return null;
    }

    public goals2020OutcomeTypeSelected(): void {
        this.outcomeState.is2030Goals = true;
        this.currentStepNumber = 1;
    }

    public customOutcomeTypeSelected(): void {
        this.outcomeState.is2030Goals = false;
        this.currentStepNumber = 1;
    }

    public isSaveDisabled(): boolean {
        return !this.isTargetStepCompleted() || (this.outcomeState.usingNcsCalculator && this.isNcsRecalculationPending);
    }

    public pendingNcsRecalculationChanged(isRecalcPending: boolean): void {
        this.isNcsRecalculationPending = isRecalcPending;
    }

    public createOutcome(): void {

        this.isSaving = true;
        const projectOutcome = this.outcomeState.outcomeWizardForm!.value;

        if (this.outcomeState.is2030Goals) {
            projectOutcome.customOutcome = undefined;
            projectOutcome.outcome.spatialExtent = undefined;
            projectOutcome.outcome.outcomeUnit = undefined;

            if (!this.outcomeState.usingNcsCalculator) {
                projectOutcome.outcome.ncsInterventionId = undefined;
                projectOutcome.outcome.ncsCountryId = undefined;
            }
        }
        else {
            projectOutcome.outcome = undefined;
        }

        this.outcomesEditService.createProjectOutcome(projectOutcome).pipe(take(1)).subscribe((newProjectOutcome: ProjectOutcomeModel) => {
            this.createOutcomeStateService.dispose();
            this.dialogRef.close(newProjectOutcome);
        });
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public ngOnDestroy(): void {
        // if (this.usingNcsCalculatorChangedSubscription) {
        //     this.usingNcsCalculatorChangedSubscription.unsubscribe();
        // }
    }
}
