<div class="p-3" *ngIf="projectEditForm" [formGroup]="projectEditForm">
    <h5 class="edit-section-header">Relations</h5>
    <div class="row">
        <div class="col-md-12">
            <span class="select-label text-info-700 mt-4 d-block">Related Strategies<app-help-selector [selector]="1145">
                </app-help-selector></span>

            <div class="hub-select-container">
                <div class="tokenfield form-control">
                    <div class="token" *ngFor="let strategy of relatedStrategies">
                        <span class="token-label">
                            {{strategy.name}}
                        </span>
                        <span class="close" (click)="deleteRelatedStrategy(strategy)"
                            aria-hidden="true">×</span>
                    </div>
                </div>
            </div>

            <div class="hub-select-container">
                <mat-form-field>
                    <input type="text" matInput [formControl]="strategyTypeahead"
                        [matAutocomplete]="relatedStrategiesAutocomplete" placeholder="Enter a name of strategy here..." />
                </mat-form-field>

                <mat-autocomplete #relatedStrategiesAutocomplete="matAutocomplete"
                    (optionSelected)="addRelatedStrategy($event)">
                    <mat-option [disabled]="isStrategyDisabled(strategy)"
                        *ngFor="let strategy of strategySuggestions$ | async" [value]="strategy">
                        {{strategy.name}}
                    </mat-option>
                </mat-autocomplete>
            </div>

        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <span class="select-label text-info-700 mt-4 d-block">Related Projects<app-help-selector [selector]="1144">
                </app-help-selector></span>

            <div class="hub-select-container">
                <div class="tokenfield form-control">
                    <div class="token" *ngFor="let project of relatedProjects">
                        <span class="token-label">
                            {{project.name}}
                        </span>
                        <span class="close" (click)="deleteRelatedProject(project)"
                            aria-hidden="true">×</span>
                    </div>
                </div>
            </div>

            <div class="hub-select-container">
                <mat-form-field>
                    <input type="text" matInput [formControl]="projectTypeahead"
                        [matAutocomplete]="relatedProjectsAutocomplete" placeholder="Enter a name of project here..." />
                </mat-form-field>

                <mat-autocomplete #relatedProjectsAutocomplete="matAutocomplete"
                    (optionSelected)="addRelatedProject($event)">
                    <mat-option [disabled]="isRelatedProjectDisabled(project)"
                        *ngFor="let project of projectsSuggestions$ | async" [value]="project">
                        {{project.name}}
                    </mat-option>
                </mat-autocomplete>
            </div>
        </div>

        <div class="col-md-12">
            <span class="select-label text-info-700 mt-4 d-block">
                Related Program Management<app-help-selector [selector]="1199"></app-help-selector>
            </span>


            <div class="hub-select-container">
                <div class="tokenfield form-control">
                    <div class="token" *ngFor="let program of relatedPrograms">
                        <span class="token-label">
                            {{program.name}}
                        </span>
                        <span class="close" (click)="deleteProgram(program)"
                            aria-hidden="true">×</span>
                    </div>
                </div>
            </div>

            <div class="hub-select-container">
                <mat-form-field>
                    <input type="text" matInput [formControl]="programTypeahead"
                        [matAutocomplete]="relatedProgramAutocomplete" placeholder="Enter a name of Program here..." />
                </mat-form-field>

                <mat-autocomplete #relatedProgramAutocomplete="matAutocomplete"
                    (optionSelected)="addProgram($event)">
                    <mat-option [disabled]="isProgramDisabled(program)"
                        *ngFor="let program of programSuggestions$ | async" [value]="program">
                        {{program.name}}
                    </mat-option>
                </mat-autocomplete>
            </div>

        </div>

    </div>

    <div class="row">
        <div class="col-md-12">
            <span class="select-label text-info-700 mt-4 d-block">Relation Notes <app-help-selector [selector]="1048">
                </app-help-selector></span>
            <textarea rows="3" cols="50" placeholder="Enter relations notes here" formControlName="relationsNotes"
                maxlength="500" class="form-control mb-2"></textarea>
            <div class="hub-validation-error-message">
                {{getValidationErrorMessages('relationsNotes')}}
            </div>
        </div>
    </div>
</div>
