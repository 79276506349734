import { AfterViewInit, Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectOutcomeModel } from '../../../../../hub_schema/hubTypes';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateOutcomeDialogComponent } from '../create-outcome-dialog/create-outcome-dialog.component';
import { OutcomesEditService } from '../services/outcomes-edit.service';
import { TncGoalsParentSelectionPanelComponent } from './tnc-goals-parent-selection-panel/tnc-goals-parent-selection-panel.component';
import { take } from 'rxjs';
import { CustomParentSelectionPanelComponent } from './custom-parent-selection-panel/custom-parent-selection-panel.component';

@Component({
  selector: 'app-edit-outcome-dialog',
  templateUrl: './edit-outcome-dialog.component.html',
  styleUrls: ['./edit-outcome-dialog.component.scss']
})
export class EditOutcomeDialogComponent implements OnInit {
    // dependencies
    private outcomesEditService: OutcomesEditService = inject(OutcomesEditService);
    private dialogRef: MatDialogRef<CreateOutcomeDialogComponent> = inject(MatDialogRef<CreateOutcomeDialogComponent>);
    private dialogData: any = inject(MAT_DIALOG_DATA);

    public projectOutcome: ProjectOutcomeModel;
    public outcomeWizardForm: FormGroup;
    public currentStepNumber: number = 0;
    public isStrategy: boolean;
    public is2030Goals: boolean;
    public isSaving: boolean = false;

    @ViewChild(TncGoalsParentSelectionPanelComponent)
    private tncGoalsPanel: TncGoalsParentSelectionPanelComponent;

    @ViewChild(CustomParentSelectionPanelComponent)
    private customGoalsPanel: CustomParentSelectionPanelComponent; 

    public get isChildProjectOutcome(): boolean {
        if (this.is2030Goals) {
            return (this.outcomeWizardForm.controls.outcome as FormGroup).controls.parentOutcomeId.value !== null;
        }
        else {
            return (this.outcomeWizardForm.controls.customOutcome as FormGroup).controls.parentCustomOutcomeId.value !== null;
        }
    }

    public getParentOutcomeStatement(): string {       
        if (this.is2030Goals) {
            if (!this.tncGoalsPanel) {
                return '';
            }
            return this.tncGoalsPanel.getParentOutcomeStatement();
        }
        else {
            if (!this.customGoalsPanel) {
                return '';
            }
            return this.customGoalsPanel.getParentOutcomeStatement();
        }
    }

    public ngOnInit(): void {
        this.isStrategy = this.dialogData.isStrategy;
        this.projectOutcome = this.dialogData.projectOutcome;
        this.is2030Goals = this.projectOutcome.outcome !== null;

        this.outcomeWizardForm = new FormGroup({
            projectOutcomeId: new FormControl(null),
            outcome: new FormGroup({
                outcomeId: new FormControl(null),
                parentOutcomeId: new FormControl(null)
            }),
            customOutcome: new FormGroup({
                customOutcomeId: new FormControl(null),
                parentCustomOutcomeId: new FormControl(null),
                indicatorName: new FormControl(''),
                unit: new FormControl('')
            }),
            statement: new FormControl('', Validators.required),
            isPolicyLeverageOutcome: new FormControl(false)
        });

        this.outcomeWizardForm.patchValue(this.projectOutcome);
    }

    public getValidationErrorMessages(formControlName: string): string {
        const formControl = this.outcomeWizardForm!.controls[formControlName];
        return formControl.errors ? 'required' : '';
    }

    public cancelDialog(): void {
        this.dialogRef.close();
    }

    public saveProjectOutcome(): void {
        this.isSaving = true;
        const projectOutcome: ProjectOutcomeModel = this.outcomeWizardForm.value;

        if (this.is2030Goals) {
            projectOutcome.customOutcome = undefined;
        }
        else {
            projectOutcome.outcome = undefined;
        }

        this.outcomesEditService.updateProjectOutcome(projectOutcome).pipe(take(1)).subscribe((savedProjectOutcome) => {
            this.isSaving = false;
            this.dialogRef.close(savedProjectOutcome);
        });
    }
}
