import { Injectable } from '@angular/core';
import { HttpService } from '../../../../core/services/http.service';
import { environment } from '../../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EmailFrequencyModel } from '../../../../../hub_schema/hubTypes';

@Injectable({
    providedIn: 'root',
})
export class ManageNotificationsAndEmailsService {
    constructor(private httpService: HttpService) { }

    private emailFrequencies: EmailFrequencyModel[];

    public getEmailFrequencies(): Observable<any[]> {
        if (this.emailFrequencies) {
            return of(this.emailFrequencies);
        }

        const url = environment.endpoints.base + '/hubLov/emailFrequencies';

        return this.httpService.get<EmailFrequencyModel[]>(url).pipe(
            tap((emailFrequencies) => {
                this.emailFrequencies = emailFrequencies;
            })
        );
    }

    public getUserNotificationPreferences(userId: number): Observable<any> {
        const url = environment.endpoints.base + '/user/' + userId + '/notificationPreference';
        return this.httpService.get<any>(url);
    }

    public saveUserNotificationPreferences(pref: any): Observable<any> {
        const url = environment.endpoints.base + '/user/' + pref.userId + '/notificationPreference';
        return this.httpService.post<any>(url, pref);
    }
}
