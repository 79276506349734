import { Injectable, Output, EventEmitter } from '@angular/core';
import algoliasearch from 'algoliasearch';
import { createNullCache } from '@algolia/cache-common';
import { from, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ErrorService } from 'src/app/core/services/error.service';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable()
export class AlgoliaApiService {

    @Output()
    public searchInitiated: EventEmitter<void> = new EventEmitter();
    public searchCompleted: EventEmitter<void> = new EventEmitter();

    private algoliaConfigurations: any;
    private algoliaClients: any;

    constructor(
        private errorService: ErrorService,
        private httpService: HttpService
    ) {
        this.algoliaConfigurations = {};
        this.algoliaClients = {};
    }

    public getAlgoliaConfiguration(indexName: string): any {
        return this.algoliaConfigurations[indexName];
    }

    private getAlgoliaClient(indexName: string): any {
        return this.algoliaClients[indexName];
    }

    public initialize(index: string, callback): void {
        if (this.algoliaConfigurations[index]) {
            callback();
            return;
        }

        const url = environment.algolia[index].temporarySecretKeyEndpoint;

        this.httpService.getText(url).subscribe((apiKey: any) => {
            // This prevents a race condition, where both the collections component (if it is the start page) and the collections widget of quick links both request the key at the same time
            if (this.algoliaConfigurations[index]) {
                callback();
                return;
            }
            
            const searchClient = algoliasearch(
                environment.algolia.appId,
                apiKey,
                {
                    responsesCache: createNullCache(),
                },
                
            );

            const config = {
                indexName: environment.algolia[index].indexName,
                searchClient
            };            

            this.algoliaConfigurations[index] = config;
            this.algoliaClients[index] = searchClient;
            callback();
        });
    }

    public searchAlgolia(index: string, request: any): Observable<any> {        
        return of(request)
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                switchMap((queryString) => this.search(index, queryString))
            );
    }

    public search(index: string, request: any): Observable<any> {
        this.searchInitiated.emit();
        const config = this.algoliaConfigurations[index];
        const client = this.algoliaClients[index];

        if (!config || !client) {
            throw new Error(`Algolia index ${index} not initialized`);
        }

        const algoliaIndex = client.initIndex(config.indexName);

        return from(algoliaIndex.search(request.searchTerm, request.parameters))
            .pipe(
                tap((result: any) => {
                    this.searchCompleted.emit();
                },
                    (err) => {
                        this.reportError(err);
                        this.searchCompleted.emit();
                    }
                )
            );
    }

    public getObject(index: string, objectId: string, showErrors: boolean = true): Observable<any> {
        const config = this.algoliaConfigurations[index];
        const client = this.algoliaClients[index];

        if (!config || !client) {
            throw new Error(`Algolia index ${index} not initialized`);
        }

        const algoliaIndex = client.initIndex(config.indexName);

        return from(algoliaIndex.getObject(objectId))
            .pipe(
                tap(() => { },
                    (err) => {
                        if (showErrors) {
                            this.reportError(err);
                        }
                    }
                )
            );
    }

    // FIXME: don't use this method yet (see note below)
    public findObject(index: string, property: string, value: string): any {
        const config = this.algoliaConfigurations[index];
        const client = this.algoliaClients[index];

        if (!config || !client) {
            throw new Error(`Algolia index ${index} not initialized`);
        }

        const algoliaIndex = client.initIndex(config.indexName);

        // TODO: algoliaIndex.findObject returns undefined, even though the method is documented in the API
        // (possibly we need to upgrade) https://www.algolia.com/doc/api-reference/api-methods/find-object/
        // So, should we reconsider this when we go to Ang 10? (ecoffman)

        return from(algoliaIndex.findObject((hit: { [x: string]: string; }) => hit[property] === value))
            .pipe(
                tap(() => { },
                    (err) => {
                        this.reportError(err);
                    }
                )
            );
    }

    public reportError(err): void {
        const errorInfo = {
            error: err,
            timestamp: new Date()
        };
        console.error(err);
        this.errorService.addError(errorInfo, true);
    }
}
