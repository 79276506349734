<div class="goal" tabindex="0" (keydown)="goalDetailsEnterKeyPress(goal, $event)">
    <div [ngClass]="{'clickable': isNavigable}" class="icon" (click)="goalDetailsClicked(goal)">
        <img class="{{ 'goal-outline-' + goal.goal2030Id }}"
            [src]="'/assets/images/home/goals/' + goal.name + '.svg'" alt="*"
            />
    </div>
    <div class="goal-info-panel {{'goal-outline-' + goal.goal2030Id }}" [ngClass]="{'clickable': isNavigable}"
        (click)="goalDetailsClicked(goal)">

        <div class="description">
            <p class="the-target" [innerHtml]="goal.target"></p>
            <p class="how-achieve">
                <strong>How: </strong>
                <span>{{goal.achievementPlan}}</span>
            </p>
        </div>

        <div class="sub-theme">
            <div class="sub-theme-item" *ngFor="let subTheme of goal.subThemeValues; let j = index">

                <div class="donut-chart">
                    <div class="sub-theme-name" *ngIf="subTheme.themeSubArea">
                        {{subTheme.themeSubArea}}
                    </div>
                    <app-goal-donut-chart [goal]="goal" [goalIndex]="goal.goal2030Id" [subThemeIndex]="j">
                    </app-goal-donut-chart>
                </div>
                <div class="target-panel">

                    <div class="target">
                        {{subTheme.organizationalTargetValueDisplay}} <br />
                        <span [innerHtml]="subTheme.unitDisplay"></span>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="metric-details-button-panel" *ngIf="isNavigable">
        <button class="metric-details-button" tabindex='-1' (click)="goalDetailsClicked(goal)">
            <span>{{goal.name}}</span>
            METRIC DETAILS
        </button>
    </div>
</div>
