import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-outcome-type-tab',
  templateUrl: './outcome-type-tab.component.html',
  styleUrls: ['./outcome-type-tab.component.scss']
})
export class OutcomeTypeTabComponent {

    @Output()
    public goals2020OutcomeTypeSelected: EventEmitter<void> = new EventEmitter<void>();
    
    @Output()
    public customOutcomeTypeSelected: EventEmitter<void> = new EventEmitter<void>();

    public goals2030OutcomeTypeClicked(e): void {
        this.goals2020OutcomeTypeSelected.emit();
    }

    public customOutcomeTypeClicked(e): void {
        this.customOutcomeTypeSelected.emit();
    }
}
