import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RecordCollectionModel } from 'src/hub_schema/hubTypes';

@Component({
  selector: 'app-collection-card',
  templateUrl: './collection-card.component.html',
  styleUrls: ['./collection-card.component.scss']
})
export class CollectionCardComponent {

  constructor() { }

  @Input()
  public collection: RecordCollectionModel;

  @Input()
  public employeeId: number;

  @Input()
  public userId: number;

  @Output()
  public deleteCollectionRequested: EventEmitter<void> = new EventEmitter<void>();

  public requestCollectionDeletion() {
      this.deleteCollectionRequested.emit();
  }

}
