<div class="view-section" id="strategicPillars">
    <h3 class="view-section-header">Strategic Pillars <span class="text-muted">({{strategicPillars?.length }})</span>
    </h3>
    <div class="details">
        <ul class="media-list custom-list p-0 pr-1">
            <li class="media" *ngFor="let strategicPillar of strategicPillars">
                <div class="mr-2">
                    <a href="#" class="btn btn-success rounded-round btn-icon legitRipple">
                        <i class="icon-stats-bars3"></i>
                    </a><br>
                </div>
                <div class="media-body">
                    <h4 class="text-success" [innerHTML]="strategicPillar.name | innerHtml"></h4>
                    <p class="record-description" [innerHtml]="strategicPillar?.description | innerHtml"></p>

                    <p>
                        <a *ngFor="let spRelatedProject of strategicPillar.strategicPillarRelatedProjects;"
                            [routerLink]="'/portfolio/' + spRelatedProject.relatedProject.projectId"
                            class="badge badge-project font-size-lg mr-1 mb-1">{{spRelatedProject.relatedProject?.name}}
                        </a>
                    </p>
                </div>
            </li>
        </ul>
        <!-- SHOW IF NO STRATEGIC PILLARS DEFINED -->
        <div class="alert alert-info alert-styled-left shadow-0 mr-3" *ngIf="strategicPillars?.length === 0">
            This record has not defined any Strategic Pillars yet. Contact the Lead on the record to inquire about
            strategic
            pillars.
        </div>
    </div>
</div>