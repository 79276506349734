<div class="view-section hub-outcomes-container" id="outcomes">
    <h3 class="view-section-header">
        Outcomes <span class="text-muted">({{outcomes?.length + customOutcomes?.length }})</span>
        <app-help-selector [selector]="1187"></app-help-selector>
    </h3>
    <div class="details">

        <ng-container *ngIf="outcomes.length">
            <h6 class="organizational-metrics-heading">
                2030 Goals Metrics
                <app-help-selector [selector]="1186"></app-help-selector>
            </h6>

            <div *ngFor="let outcome of outcomes; let outcomeIndex = index">
                <app-outcome [projectId]="project.projectId" [projectOutcome]="outcome" [isStrategy]="isStrategy"
                    (projectDataAreLoading)="projectDataAreLoading.emit($event)" [outcomeIndex]="outcomeIndex"></app-outcome>
            </div>
        </ng-container>

        <ng-container>
            <ng-container *ngIf="customOutcomes?.length">
                <h6 class="other-sca-metrics-heading">
                    Custom Metrics
                </h6>

                <div *ngFor="let outcome of customOutcomes; let outcomeIndex = index">

                    <!-- CUSTOM OUTCOMES -->
                    <app-custom-outcome [projectId]="project.projectId" [projectOutcome]="outcome"
                        [isStrategy]="isStrategy" (projectDataAreLoading)="projectDataAreLoading.emit($event)"
                        [outcomeIndex]="outcomeIndex" [indexOffset]="outcomes.length + 1">
                    </app-custom-outcome>
                </div>
            </ng-container>
        </ng-container>

        <!-- SHOW IF NO OUTCOMES DEFINED (That is, there are no outcomes and no custom outcomes) -->
        <div class="alert alert-info alert-styled-left shadow-0 mr-3"
            *ngIf="outcomes?.length === 0 && customOutcomes?.length === 0">
            This record has not defined any Outcomes yet. Contact the Lead on the record to inquire about
            Outcomes.
        </div>
    </div>
</div>
