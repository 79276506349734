import { Injectable } from '@angular/core';
import { ApplicationRoleType } from 'src/app/shared/types/role';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root'
})
export class AlgoliaAuthorizationService {

    constructor(
        private authService: AuthService) { }

    public isAllowedToEdit(algoliaHit: any): boolean {

        // todo: this prevents a bug in the server code from breaking the application.  Remove when it is fixed.
        if (!algoliaHit.businessUnitIds) {
            return false;
        }

        // Are they on this project's team?
        const userId = parseInt(this.authService.getClaimDataValue('UserId'), 10);

        if (algoliaHit.teamMembers && algoliaHit.teamMembers.length && algoliaHit.teamMembers.find((tm) => tm.userId === userId)) {
            return true;
        }

        // are they an IT Admin or Business Administrator
        if (this.authService.userIsAdmin()) {
            return true;
        }

        else {
            const roles = this.authService.getUserRoles();

            if (roles.includes(ApplicationRoleType.BusinessUnitEditor)) {
                const userBusId = this.authService.getClaimDataValue('BuEditorFor').map((item: string) => +item);
                if (this.diff(algoliaHit.businessUnitIds, userBusId).length > 0) {
                    return true;
                }
            }

            if (roles.includes(ApplicationRoleType.RegionEditor)) {

                // Same Region:
                const userRegionId = parseInt(this.authService.getClaimDataValue('RegionId'), 10);

                if (algoliaHit.regionIds.includes(userRegionId)) {
                    return true;
                }
            }

            if (roles.includes(ApplicationRoleType.DivisionEditor)) {

                // same division?
                const userDivisionId = parseInt(this.authService.getClaimDataValue('DivisionId'), 10);
                if (algoliaHit.divisionIds.includes(userDivisionId)) {
                    return true;
                }
            }
        }

        return false;
    }

    private diff(arr1, arr2) {
        const ret = [];
        arr1.sort();
        arr2.sort();
        for (let i = 0; i < arr1.length; i += 1) {
            if (arr2.indexOf(arr1[i]) > -1) {
                ret.push(this[i]);
            }
        }
        return ret;
    }

}
