import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { take} from 'rxjs/operators';
import { AuthService } from '../../core/services/auth/auth.service';
import { escapeQuotesAndEnters } from '../../shared/helpers/escape';
import { HubRecordTypes } from '../../shared/types/hub-record-types';
import { BusinessUnitModel, ProjectModel, ProjectTypeModel } from '../../../hub_schema/hubTypes';
import { CreateHubRecordService } from './services/create-hub-record.service';
import { HubLovService } from '../../core/services/hub-lov.service';
import { ErrorService } from '../../core/services/error.service';

enum step {
    type = 1,
    name,
    additionals,
}

@Component({
    selector: 'app-project-create',
    templateUrl: './create-hub-record-dialog.component.html',
    styleUrls: ['./create-hub-record-dialog.component.scss'],
})
export class CreateHubRecordDialogComponent implements OnInit, OnDestroy {
    private createHubRecordService: CreateHubRecordService = inject(CreateHubRecordService);
    private hubLovService: HubLovService = inject(HubLovService);
    private router: Router = inject(Router);
    private auth: AuthService = inject(AuthService);
    private dialogRef: MatDialogRef<CreateHubRecordDialogComponent> = inject(MatDialogRef<CreateHubRecordDialogComponent>);

    private errorService: ErrorService = inject(ErrorService);

    public recordTypes: ProjectTypeModel[];
    public businessUnits: BusinessUnitModel[];
    public authorizedRecordTypes: ProjectTypeModel[];

    private businessUnitChangedSubscription: Subscription;

    public isSaving: boolean = false;

    public createProjectForm: FormGroup;
    public businessUnitIdControl: FormControl;
    public restrictedProjectTypes = ['Whole System', 'Program Management'];

    public ngOnInit(): void {
        forkJoin({
            statuses: this.hubLovService.getStatuses(),
            businessUnits: this.hubLovService.getBusinessUnits(),
            projectTypes: this.hubLovService.getProjectTypes()
        })
        .pipe(take(1)).subscribe((result: any) => {
            this.businessUnits = result.businessUnits.filter(bu => bu.isActive);
            this.recordTypes = result.projectTypes;
            this.authorizedRecordTypes = this.getAuthorizedRecordTypes();

            this.createProjectForm = new FormGroup(
                {
                    projectTypeId: new FormControl(null, [Validators.required]),
                    name: new FormControl(''),
                    statusId: new FormControl(-1, Validators.required),
                    briefDescription: new FormControl('', [Validators.required]),
                    startDate: new FormControl(null, [Validators.required]),
                    projectBusinessUnits: new FormControl([], [Validators.required]),
                }
            );

            // Todo (ACE 11-22-2023) Why are we loading statuses, when the predefined value is "Proposed"?
            const proposedStatus = result.statuses.find(status => status.name === 'Proposed');
            this.createProjectForm.controls.statusId.setValue(proposedStatus.statusId);

            this.businessUnitIdControl = new FormControl(null);

            this.businessUnitChangedSubscription = this.businessUnitIdControl.valueChanges.subscribe((businessUnitId: number) => {
                const projectBusinessUnit = {
                    businessUnitId: businessUnitId,
                    isLeadBusinessUnit: true
                };

                this.createProjectForm.controls.projectBusinessUnits.setValue([projectBusinessUnit]);
            });
        });
    }

    private getAuthorizedRecordTypes(): ProjectTypeModel[] {
        if (this.auth.userIsAdmin()) {
            return this.recordTypes;
        }

        const typesToShow: ProjectTypeModel[] = [];

        const userIsQualified = this.auth.userIsBusinessAdmin() || this.auth.userIsRegionEditor() || this.auth.userIsDivisionEditor();

        this.recordTypes.forEach(
            (type) => {
                if ((type.name === HubRecordTypes.WholeSystem && userIsQualified) ||
                    (type.name === HubRecordTypes.ProgramManagement && userIsQualified) ||
                    type.name === HubRecordTypes.Project ||
                    type.name === HubRecordTypes.Strategy) {
                    typesToShow.push(type);
                }
            }
        );

        return typesToShow;
    }

    public isRecordTypeTabCompleted(): boolean {
        return this.createProjectForm.controls.projectTypeId.valid;
    }

    public isRecordNameTabCompleted(): boolean {
        return this.createProjectForm.controls.name.valid;
    }

    public createHubRecord(): void {
        this.isSaving = true;
        const newHubRecord = this.createProjectForm.value as ProjectModel;
        newHubRecord.briefDescription = escapeQuotesAndEnters(newHubRecord.briefDescription);

        this.createHubRecordService.createHubRecord(newHubRecord).pipe(take(1)).subscribe({
            next: (hubRecord: ProjectModel) => {
                this.isSaving = false;
                this.dialogRef.close();
                this.router.navigateByUrl('/portfolio/' + hubRecord.projectId + '/edit?new=true');
            },
            error: (err) => {
                this.errorService.addError(err, true);
                this.dialogRef.close();
            }
        });
    }

    public cancelDialog() {
        this.dialogRef.close();
    }

    public ngOnDestroy(): void {
        if (this.businessUnitChangedSubscription) {
            this.businessUnitChangedSubscription.unsubscribe();
        }
    }    
}
