<ng-container *ngIf="goals">
    <div class="card-header p-1 border-adriatic"
        [ngClass]="{strategy: recordTypeName === 'Strategy', project: recordTypeName === 'Project' }">
        <div>
            <span class="text-success-800 font-weight-bold">{{recordTypeName}}</span><span
                class="text-muted ml-1">({{statusName}})</span>
        </div>

        <h4 class="hub-project-title mb-0">
            <a [routerLink]="'/portfolio/' + hubRecordId" class="font-weight-bold">{{project?.name}}</a>
        </h4>

    </div>

    <div class="card-body m-1 p-1" *ngIf="showCardDetails">
        <div class="description">
            <p class="record-description" [innerHTML]="project?.briefDescription"></p>
        </div>
        <p class="text-muted record-description" *ngIf="!project?.briefDescription">This record does not have a description.</p>
        <hr>

        <div>
            <span *ngIf="getProjectLeader()">
                Led by
                <a [routerLink]="'/user/' + getProjectLeader().userId" class="led-by-link">
                    {{getProjectLeader().fullName}}
                </a>
            </span>
        </div>
        <div>
            <span *ngIf="getLeadBusinessUnit()">
                Managed by {{getLeadBusinessUnit().name}}
            </span>
        </div>

        <div class="supported-by-label"
            *ngIf="(recordTypeName === 'Strategy') && project.relations?.length > 0">
            Supported by
            {{project?.relations.length}} {{project?.relations.length === 1 ? 'Project' : 'Projects'}}:
        </div>
        <div class="supported-by-label" *ngIf="(recordTypeName === 'Project') && project.relations?.length > 0">
            Supporting
            {{project?.relations.length}} {{project?.relations.length === 1 ? 'Strategy' : 'Strategies'}}:
        </div>

        <div *ngIf="(recordTypeName === 'Strategy' || recordTypeName === 'Project') && project.relations?.length > 0"
            class="hub-custom-scrollbar"
            style="max-height: 200px; overflow-y: visible; overflow-x: hidden;">

            <ng-container *ngIf="recordTypeName === 'Project'">
                <a [routerLink]="'/portfolio/' + relation.relatedProject.projectId" *ngFor="let relation of project?.relations"
                    class="badge badge-primary mr-1 mb-1">{{relation.relatedProject.name}}</a>
            </ng-container>

            <ng-container *ngIf="recordTypeName === 'Strategy'">
                <a [routerLink]="'/portfolio/' + relation.relatedProject.projectId" *ngFor="let relation of project?.relations"
                    class="badge badge-primary mr-1 mb-1">{{relation.relatedProject.name}}</a>
            </ng-container>

        </div>

        <div class="details" *ngIf="showFinanceDetails">
            <h4 class="text-indigo mt-0 mb-0 p-0">
                $ {{ numberWithCommas(getFinancial('currentFyBudget')) }}
            </h4>
            <span class="text-muted d-block m-0">FY{{ currentFiscalYear }} Working Budget</span>
            <div class="progress mt-1 mb-2" style="height: 0.5rem;">
                <div class="progress-bar"
                    [ngStyle]="{'width.%': getFinancial('fundingPercent') | numberWithoutCommas}"></div>
            </div>
            <span class="text-muted">
                {{ getFinancial('fundingPercent') | numberWithoutCommas }}% Funded (of Working Budget)
            </span>
        </div>
    </div>

    <div class="goals-panel">
        <div *ngFor="let goal of getProjectGoals()" class="goal-icon {{'goal-background-' + goal.goal2030Id}}"
            [matTooltip]="goal.theme" [matTooltipShowDelay]="500" [matTooltipHideDelay]="200"></div>
    </div>

    <div class="card-footer d-flex justify-content-between align-items-center p-1">
        <div>
            <div class="card-footer-buttons">
                <a href="#" (click)="toggleFavorite(hubRecordId, $event)" class="text-primary">
                    <span *ngIf = "userDataService.isFavorite(hubRecordId)" class="material-icons-outlined">favorite</span>
                    <span *ngIf = "!userDataService.isFavorite(hubRecordId)" class="material-icons-outlined">favorite_border</span>
                </a>

                <a [routerLink]="'/portfolio/' + hubRecordId + '/edit'" *ngIf="isUserCanEdit" class="text-primary">
                    <span class="material-icons-outlined">edit</span>
                </a>

                <app-add-project-to-collection-button [projectId]="project.projectId" [projectName]="project.name"></app-add-project-to-collection-button>
                
                <a href="javascript: void(0)" *ngIf="userIsCreator" 
                    (click)="deleteRecordCollectionProjectClicked()"><i class="icon-trash"></i></a>
            </div>
            <span class="text-muted font-size-sm ml-3">
                Updated {{ getDistanceInWordsToNow() }}
            </span>
        </div>
    </div>
</ng-container>