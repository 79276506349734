import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { HttpService } from '../../../../../core/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class BuildInfoService {
  constructor(private httpService: HttpService) {}

  public getBackendBuildInfo(): Observable<string> {
    const url = environment.endpoints.base + '/buildInfo';
    return this.httpService.getText(url);
  }

  getFrontendVersionInfo(): Observable<any> {
    return this.httpService.get('/assets/json/release_info.json');
  }
}
