<div class="custom-outcome-standalone-panel-container" [formGroup]="outcomeState.outcomeWizardForm">
    <div class="row mb-2" formGroupName="customOutcome">
        <div class="col-md-12">

            <span class="select-label text-info-800 d-block">
                Custom Metric Name<app-help-selector selector="1065"></app-help-selector>
            </span>
            
            <input formControlName="indicatorName" matInput type="text" class="form-control"
                placeholder="Enter a metric name here..." [matAutocomplete]="autoCustomOutcome">
        
            <mat-autocomplete #autoCustomOutcome="matAutocomplete"
                [displayWith]="displayCustomOutcome"
                (optionSelected)="selectCustomOutcome($event)">
                <mat-option *ngFor="let customOutcome of customOutcomeSuggestions$ | async" [value]="customOutcome">
                    {{customOutcome.indicatorName}} ({{customOutcome.unit}})
                </mat-option>
            </mat-autocomplete>

            <div class="hub-validation-error-message">
                {{getValidationErrorMessages("indicatorName")}}
            </div>

            <span class="select-label text-info-800 d-block mt-2">
                Outcome Metric Unit<app-help-selector selector="1066"></app-help-selector>
            </span>

            <input formControlName="unit" type="text" class="form-control"
                placeholder="Enter a metric unit name here...">

            <div class="hub-validation-error-message">
                {{getValidationErrorMessages("unit")}}
            </div>
        </div>
    </div>

    <div class="leverage-outcomes-panel">
        <div class="leverage-outcome-heading">
            <div class="select-label text-info-800">
                Leverage Outcome<app-help-selector selector="1204"></app-help-selector>
            </div>
        </div>
        <div class="leverage-outcome-list">
            <div class="leverage-outcome">
                <!--If we ever get more than one type of leverage outcome, we will turn this into an ngFor -->
                <div class="leverage-outcome-name">Policy</div>
                
                <mat-checkbox color="primary" style="margin-left: 10px;"
                    formControlName="isPolicyLeverageOutcome">
                </mat-checkbox>
            </div>
        </div>
    </div>

    <div class="select-label text-info-800 mt-3">Outcome Statement<app-help-selector
            selector="1069"></app-help-selector></div>
            
    <textarea formControlName="statement" rows="3" cols="3" class="form-control record-description"
        appAutoFocus placeholder="Enter your outcome statement here..."></textarea>

</div>