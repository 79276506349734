import { Facet } from './facet';
import { Filter } from './filter';
import { RadioButtonFilter } from './filter-radio-button';

export class LastModifiedDateFacet extends Facet {
    public selection: any;
    public override  filters: RadioButtonFilter[];

    constructor(type, title, expanded) {
        super(type, 'modifiedOnTimestamp', title, 'lastModifiedDate', expanded, false);
    }

    public override  clearAll(): void {
        this.selection = null;
    }

    public override getFilterString(): string {
        const startDate: Date = new Date();
        const today = new Date();

        switch (this.selection) {
            case 'Within last 30 days':
                startDate.setDate(today.getDate() - 30);
                break;
            case 'Within last 3 months':
                startDate.setDate(today.getDate() - 90);
                break;
            case 'Within last 6 months':
                startDate.setDate(today.getDate() - 180);
                break;
            case 'Within last year':
                startDate.setDate(today.getDate() - 365);
                break;
        }

        // algolia stores dates in some wonky derivative of Unix Timestamp: seconds since 1/1/1970.  Javascript uses miliseconds since 1/1/1970, so convert
        const fromUnixTimestamp = Math.floor(startDate.getTime() / 1000);
        const toUnixTimestamp = Math.ceil(today.getTime() / 1000);

        return `${this.key}: ${fromUnixTimestamp} TO ${toUnixTimestamp}`;
    }

    public override stringify(): string {
        const filterState = {
            modifiedOnTimestamp: this.selection
        };
        return JSON.stringify(filterState);
    }

    public override getActiveFilters(): Filter[] {
        return this.filters.filter((f) => f.title === this.selection);
    }

    public override loadFromSaved(savedFacet: any): void {
        for (const savedFilter of savedFacet.activeFilters) {
            const existingFilter = this.filters.find((f) => f.title === savedFilter.title);

            if (existingFilter) {
                existingFilter.makeActive();
            }
        }

        this.selection = savedFacet.activeFilters[0].title;
    }
}
