<div class="dialog-container">
    <div class="dialog-icon">!</div>

    <div class="dialog-content">
        <div class="dialog-header">
            <div class="title">
                Are you sure?
            </div>
        </div>

        <div class="dialog-body hub-custom-scrollbar">
            {{warningMessage}}
        </div>

        <div class="dialog-footer">
            <button (click)="close()" type="button" class="cancel-button mr-1">Cancel</button>

            <button type="button" class="hub-icon-button SaveButton" [ngClass]="{'saving': isBusy}"
                    [disabled]="isBusy" (click)="confirmAction()">
                <b *ngIf="showSaveIcon">
                    <i class="icon-floppy-disk"></i>
                    <i class="icon-spinner4"></i>
                </b>
                {{confirmButtonText}}
            </button>
        </div>
    </div>
</div>
