import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  FavoriteProjectModel,
  ProjectModel,
  UserApplicationRoleModel,
  UserBusinessRoleModel,
  UserModel,
} from '../../../hub_schema/hubTypes';
import { HttpService } from './http.service';

@Injectable()
export class EmployeeService {
  // Developers, note: this service is for getting employee-related data.  That employee may be the currently logged in user, or it may be a different TNC employee

  constructor(private httpService: HttpService) {}

  public getEmployeeDetails(userId: number): Observable<UserModel> {
    const url = environment.endpoints.base + '/user/' + userId;
    return this.httpService.get<UserModel>(url);
  }

  public getEmployeeFavorites(userId: number) {
    const url = environment.endpoints.base + '/user/favorites/' + userId;
    return this.httpService.get<FavoriteProjectModel[]>(url);
  }

  public getUserBusinessRoles(userId: number) {
    const url =
      environment.endpoints.base + `/user/userBusinessRoles/${userId}`;
    return this.httpService.get<UserBusinessRoleModel[]>(url);
  }

  private buProjects: ProjectModel[];
  private regionProjects: ProjectModel[];
  private divisionProjects: ProjectModel[];

  public getProjects(userId: number): Observable<ProjectModel[]> {
    const url =
      environment.endpoints.base + '/projects/projectsByUser/' + userId;
    return this.httpService.get<ProjectModel[]>(url);
  }

  public getBuProjects(buId: number): Observable<ProjectModel[]> {
    if (this.buProjects) {
      return of(this.buProjects);
    }

    const url =
      environment.endpoints.base + '/projects/projectsByBusinessUnit/' + buId;

    return this.httpService.get<ProjectModel[]>(url).pipe(
      tap((buProjects: ProjectModel[]) => {
        this.buProjects = buProjects;
      })
    );
  }

  public getRegionProjects(regionId: number): Observable<ProjectModel[]> {
    if (this.regionProjects) {
      return of(this.regionProjects);
    }

    const url =
      environment.endpoints.base + '/projects/projectsByRegion/' + regionId;

    return this.httpService.get<ProjectModel[]>(url).pipe(
      tap((regionProjects: ProjectModel[]) => {
        this.regionProjects = regionProjects;
      })
    );
  }

  public getDivisionProjects(divisionId: number): Observable<any> {
    if (this.divisionProjects) {
      return of(this.divisionProjects);
    }

    const url =
      environment.endpoints.base + '/projects/projectsByDivision/' + divisionId;

    return this.httpService.get<ProjectModel[]>(url).pipe(
      tap((divisionProjects: ProjectModel[]) => {
        this.divisionProjects = divisionProjects;
      })
    );
  }
}
