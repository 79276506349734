import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http.service';

function isEmptyInputValue(value: any): boolean {
    // we don't check for string here so it also works with arrays
    return value === null || value.length === 0;
}

@Injectable({
    providedIn: 'root'
})
export class CustomUserValidator {

    constructor(
        private httpService: HttpService
    ) { }

    isNonTncEmployeeValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> => {
            if (isEmptyInputValue(control.value)) {
                return of(null);
            } else {
                return control.valueChanges.pipe(
                    debounceTime(500),
                    take(1),
                    switchMap(
                        () => this.httpService.verifyNonTncEmployee(control.value).pipe(
                            map((user) => {
                                if (user) {
                                    return null;
                                }
                                else {
                                    return of({ invalidValue: 'Unknown error ocurred!' });
                                }
                            })
                        )
                    ),
                    catchError((err: HttpErrorResponse) => {
                        let error = { invalidValue: '' };
                        if ('invalidValue' in err.error) {
                            error.invalidValue = err.error.invalidValue;
                        }
                        else if ('detail' in err.error) {
                            error.invalidValue = err.error.detail;
                        }
                        else {
                            error = err.error;
                        }

                        return of(error);
                    })
                );
            }
        };
    }
}
