import { FormGroup } from "@angular/forms";
import { NcsPathwayModel } from "../../../../../../hub_schema/hubTypes";

export class OutcomeState {
    public projectId: number;
    public isStrategy: boolean;
    public outcomeWizardForm: FormGroup | undefined;
    public is2030Goals: boolean = true;    
    public isRollupSelected: boolean;

    // NCS stuff
    public usingNcsCalculator: boolean = false;
    public selectedNcsPathwayId: number | undefined;
    public selectedNcsPathway: NcsPathwayModel | undefined;

    constructor(projectId: number, isStrategy: boolean) {
        this.projectId = projectId;
        this.isStrategy = isStrategy;
    }

    public get isReady(): boolean {
        return !!this.outcomeWizardForm;
    }

    public dispose(): void {
        this.outcomeWizardForm = undefined;
    }

}