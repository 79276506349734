<div>
  <div class="sidebar sidebar-light sidebar-main sidebar-expand" [ngClass]="{'collapsed': isSidebarCollapsed}">

    <!-- Sidebar content -->
    <div class="sidebar-content" [ngClass]="{'collapsed': !isSidebarExpanded}">

      <div class="card card-sidebar-mobile mb-0">
        <ul class="nav nav-sidebar mb-5">

          <li class="nav-item">
            <a routerLink="/" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" class="nav-link accesibility-focus"
                [matTooltip]="isSidebarCollapsed ? 'Home' : ''" matTooltipPosition="right" matTooltipShowDelay="500" matTooltipHideDelay="200">
              <img class="hub-icon" src="/assets/images/icons/Home.svg" />
              <span class="link-title">Home</span>
            </a>
          </li>

          <li class="nav-item">
            <a routerLink="/user" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" class="nav-link accesibility-focus"
                [matTooltip]="isSidebarCollapsed ? 'Your Work' : ''" matTooltipPosition="right" matTooltipShowDelay="500" matTooltipHideDelay="200">
              <img class="hub-icon" src="/assets/images/icons/Work.svg" />
              <span class="link-title">Your Work</span>
            </a>
          </li>

          <li class="nav-item">
            <a routerLink="/portfolio" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }" class="nav-link accesibility-focus"
                [matTooltip]="isSidebarCollapsed ? 'Search Portfolio' : ''" matTooltipPosition="right" matTooltipShowDelay="500" matTooltipHideDelay="200">
              <img class="hub-icon" src="/assets/images/icons/Portfolio.svg" />
              <span class="link-title">Search Portfolio</span>
            </a>
          </li>

          <li class="nav-item">
            <a href="#" (click)="requestHubRecordCreateDialog($event)" class="nav-link accesibility-focus"
                [matTooltip]="isSidebarCollapsed ? 'Create new Record' : ''" matTooltipPosition="right" matTooltipShowDelay="500" matTooltipHideDelay="200">
              <img class="hub-icon" src="/assets/images/icons/Create.svg" />
              <span class="link-title">Create new Record</span>
            </a>
          </li>

          <li class="nav-item">
            <a routerLink="/collections" class="nav-link accesibility-focus" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                [matTooltip]="isSidebarCollapsed ? 'Collections' : ''" matTooltipPosition="right" matTooltipShowDelay="500" matTooltipHideDelay="200">
              <img class="hub-icon" src="/assets/images/icons/Collections.svg" />
              <span class="link-title">Collections</span>
            </a>
          </li>

          <li class="nav-item">
            <a routerLink="/dashboards" class="nav-link accesibility-focus" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                [matTooltip]="isSidebarCollapsed ? 'Dashboards' : ''" matTooltipPosition="right" matTooltipShowDelay="500" matTooltipHideDelay="200">
              <img class="hub-icon" src="/assets/images/icons/Dashboard.svg" />
              <span class="link-title">Dashboards</span>
            </a>
          </li>

          <li class="nav-item" *ngIf="shouldAdminMenuShow()">
            <a routerLink="/administration" class="nav-link accesibility-focus" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
                [matTooltip]="isSidebarCollapsed ? 'Administration' : ''" matTooltipPosition="right" matTooltipShowDelay="500" matTooltipHideDelay="200">
              <img class="hub-icon" src="/assets/images/icons/Admin.svg" />
              <span class="link-title">Administration</span>
            </a>
          </li>

        </ul>
      </div>
    </div>

  </div>
</div>
