<h5 class="edit-section-header">
    Anchor Record Selection<app-help-selector [selector]="1174"></app-help-selector>
</h5>

<div class="d-block alert alert-info alert-styled-left shadow-0" *ngIf="anchorRecord">
    There is already an Insight Anchor Record linked to this Conservation Record, it is
    <b>{{anchorRecord.insightProjectId}} - {{anchorRecord.insightProjectName}}</b>. Please contact the Finance Resource
    Center if this needs to be changed.
</div>

<div *ngIf="!anchorRecord">

    <!-- SHOW THIS ONLY WHEN AN ANCHOR SELECTION HAS BEEN SELECTED -->
    <div class="mt-2 sb-b" *ngIf="pendingAnchorRecordSelection">

        <h5 class="text-info-800">
            Anchor Request Status<app-help-selector [selector]="1179"></app-help-selector>
        </h5>

        <div class="d-block alert alert-info alert-styled-left shadow-0">
            Anchor Record selection is pending processing by WO-Accounting. As soon as this is finished, the Insight
            Record will become visible on the <span class="text-success">Insight Records</span> of this page with the
            Anchor designation.
        </div>

        <div *ngIf="pendingAnchorRecordSelection.hasMdsCode">

            <p class="record-description">Anchor Record details of the option selected display below and will be available in Projects@TNC within
                the business day.</p>

            <ul class="section-list left ml-1 mr-2 sb-t" id="lstInsightSearchResults" style="cursor: auto;">
                <li class="sb-b">
                    <div class="mr-2" style="display: flex; flex-direction: column;">
                        <button class="btn btn-primary rounded-round btn-icon legitRipple mt-1">
                            <i class="icon-coins"></i>
                        </button>
                    </div>
                    <div class="media-body">
                        <div class="d-md-flex">
                            <div class="media-title">
                                <span
                                    class="d-block text-primary font-weight-semibold">{{pendingAnchorRecordSelection.insightId ? pendingAnchorRecordSelection.insightId + ' - ' : '' }}
                                    {{pendingAnchorRecordSelection.name}} </span>
                                <span class="d-block"><span class="text-muted">Owned by Business Unit:</span>
                                    {{pendingAnchorRecordSelection.businessUnitName}}</span>
                                <span
                                    class="text-success d-block font-weight-bold">{{pendingAnchorRecordSelection.recordTypeCode}}
                                    <span
                                        class="text-dark font-weight-bold">({{pendingAnchorRecordSelection.insightStatus}})</span></span>
                            </div>
                            <div class="align-self-center" style="margin-left: 25%;">
                                <span class="text-indigo font-weight-bold">Pending</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <!-- SHOW THIS ONLY WHEN THERE IS NO ANCHOR LINKED YET -->
    <div *ngIf="!pendingAnchorRecordSelection"
        [formGroup]="anchorRecordSelectionForm">
        <h5 class="text-info-800">
            Anchor Selection Options<app-help-selector [selector]="1176"></app-help-selector>
        </h5>

        <mat-radio-group formControlName="anchorRecordAction">
            <div *ngFor="let action of anchorSelectionActions">
                <mat-radio-button color="primary" [value]="action.value" [disabled]="action.isDisabled">{{action.title}}
                </mat-radio-button>
            </div>
        </mat-radio-group>

        <div *ngIf="isAnchorRecordActionNewSelected()" style="margin-top: 20px">
            <div class="d-block alert alert-warning alert-styled-left shadow-0" role="alert">
                You have chosen to create a new Anchor Record. The Insight Record will be automatically created and
                required data will be populated based on Hub details.<br />
                <strong>After saving this record, you will not be able to change this selection.</strong>
            </div>
        </div>

        <div *ngIf="isAnchorRecordActionSelectSelected() && isInsightRecordSelected()"
            style="margin-top: 20px">

            <div class="d-block alert alert-warning alert-styled-left shadow-0" role="alert">
                You have selected the following Insight Record to be converted to an Anchor Record for this Hub Record.
                The Insight Record Description and Lead will be updated to match Hub. For more details, review the
                Financial Management Handbook.<br />
                <strong>After saving this record, you will not be able to change this selection.</strong>
            </div>

            <ul class="section-list left ml-1 mr-2 sb-t" id="lstInsightSearchResults" style="cursor: auto;">
                <li class="sb-b">
                    <div class="mr-2" style="display: flex; flex-direction: column;">
                        <button class="btn btn-primary rounded-round btn-icon legitRipple mt-1">
                            <i class="icon-coins"></i>
                        </button>
                    </div>
                    <div class="media-body">
                        <div class="d-md-flex">
                            <div class="media-title">
                                <span class="d-block text-primary font-weight-semibold">{{selectedInsightRecord.InsightId ? selectedInsightRecord.InsightId + ' - ' : '' }}
                                    {{selectedInsightRecord.Name}} </span>
                                <span class="d-block"><span class="text-muted">Owned by Business Unit:</span>
                                    {{selectedInsightRecord.businessUnitNames}}</span>
                                <span
                                    class="text-success d-block font-weight-bold">{{selectedInsightRecord.RecordTypeCode}}
                                    <span
                                        class="text-dark font-weight-bold">({{selectedInsightRecord.InsightStatus}})</span></span>
                            </div>
                            <div class="align-self-center" style="margin-left: 25%;">
                                <button class="btn btn-sm btn-primary"
                                    (click)="clearSelectedInsightRecord();">Cancel</button>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="media-body">
                <div class="d-md-flex ">
                </div>
            </div>
        </div>

        <app-insight-lookup *ngIf="isInsightRecordSelectionEnabled()" (anchorRecordSelected)="selectAnchorRecord($event)" >
        </app-insight-lookup>
    </div>
</div>
