import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { HubAdministrationService } from '../../services/hub-administration.service';
import { Title } from '@angular/platform-browser';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../core/services/auth/auth.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { BusinessRoleModel, BusinessUnitModel, FinancialContactTypeAheadModel, UserBusinessRoleModel, UserModel } from '../../../../hub_schema/hubTypes';
import { Observable, Subscription, catchError, debounceTime, distinctUntilChanged, map, of, startWith, switchMap, take, tap } from 'rxjs';
import { HubLovService } from '../../../core/services/hub-lov.service';
import { UsageService } from '../../../core/services/usage.service';


import { TypeAheadService } from '../../../core/services/type-ahead.service';
import { AddNonTncEmployeeDialogComponent } from './add-non-tnc-employee/add-non-tnc-employee.component';

enum tabs {
    system = 1,
    bulkEdit,
    ncs
}

@Component({
    selector: 'app-hub-administration',
    templateUrl: './hub-administration.component.html',
    styleUrls: ['./hub-administration.component.scss']
})
export class HubAdministrationComponent implements OnInit {
    // dependencies
    private hubAdministrationService: HubAdministrationService = inject(HubAdministrationService);
    private hubLovService: HubLovService = inject(HubLovService);
    private titleService: Title = inject(Title);
    private typeAheadService: TypeAheadService = inject(TypeAheadService);

    private dialogService: MatDialog = inject(MatDialog);
    private authService: AuthService = inject(AuthService);
    private usageService: UsageService = inject(UsageService);

    @ViewChild('aisRefresh') aisRefresh;

    // #region Page Properties
    public tabs = tabs;
    public activeTab: tabs = tabs.system;
    public aisConfig = null;
    public aisPageSize = 8;
    public aisFacets = ['businessUnit', 'division', 'region', 'editableBUs.name', 'applicationRoles.applicationRoleName'];
    public filteredBusinessUnits: Observable<BusinessUnitModel[]>;
    public isBusy = false;

    // current user authorization properties
    public isAdmin: boolean = false;
    public isAllowedToEditNcsValues = false;
    public isAllowedToBulkEdit = false;
    public editorPermission = '';

    // #endregion Page Properties

    public ngOnInit(): void {
        this.isAllowedToEditNcsValues = this.authService.userIsNcsEditor() || this.authService.userIsAdmin();
        this.isAllowedToBulkEdit = this.authService.userIsDivisionEditor() || this.authService.userIsRegionEditor() || this.authService.userIsBusinessUnitEditor() || this.authService.userIsAdmin();

        // userIsAdmin is a catch-all for all Hub admin roles (IT and Business) but not Finance admin roles.
        this.isAdmin = this.authService.userIsAdmin() || this.authService.userIsFinanceAdmin() || this.authService.userIsITAdmin();

        if (!this.isAdmin) {
            if (this.isAllowedToEditNcsValues) {
                this.activeTab = tabs.ncs;
            }
            else if (this.isAllowedToBulkEdit) {
                // the user is a division editor or business unit editor
                this.activeTab = tabs.bulkEdit;
            }
        }
        
        const title = 'Hub - Administration - Permissions';
        this.titleService.setTitle(title);
        this.usageService.logHubUsage('Administration - Permissions', 'n/a', 'n/a', title);
    }

    public isAllowedToAddNonTncUsersToHub() {
        return this.authService.isAllowedToAddNonTncUsersToHub();
    }

    public addNonTncEmployee(): void {
        const dialogRef: MatDialogRef<AddNonTncEmployeeDialogComponent> = this.dialogService.open(AddNonTncEmployeeDialogComponent, {
            width: '600px',
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((result) => {
            if (result) {
                this.aisRefresh.refresh();
            }
        });
    }

    public setTabTo(tab: tabs, e: Event): void {
        this.activeTab = tab;
        e.preventDefault();
    }
}
