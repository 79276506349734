<div *ngIf="project">
    <div class="d-md-flex justify-content-between p-2 pl-3">
        <h5 class="edit-section-header">
            Outputs <app-help-selector [selector]="1116"></app-help-selector>
        </h5>
        <button type="button" class="btn-outline bg-primary-300 text-primary btn-icon btn rounded-round ml-2"
            (click)="openCreateUpdateOutputDialog(null)" title="" data-placement="right" data-original-title="Add new Output"
            tabindex="0">
            <i class="icon-plus-circle2 mr-2"></i>Create Output
        </button>
    </div>

    <ul class="nav nav-tabs nav-tabs-bottom mb-1">
        <li class="nav-item">
            <a class="nav-link legitRipple" data-toggle="tab" (click)="setTabTo(tabs.active, $event)"
                [ngClass]="{active: activeTab === tabs.active}">Active Outputs ({{activeOutputs?.length ? activeOutputs?.length : 0}})
            </a>
        </li>

        <li class="nav-item">
            <a class="nav-link legitRipple" data-toggle="tab" (click)="setTabTo(tabs.archived, $event)"
                [ngClass]="{active: activeTab === tabs.archived}">
                Archived Outputs ({{archivedOutputs?.length ? archivedOutputs?.length : 0}})
            </a>
        </li>
    </ul>
    <div class="tab-content parent-min">
        <div class="tab-pane" [ngClass]="{active: activeTab === tabs.active, show: activeTab === tabs.active}"
            id="output">
            <div class="p-2 pl-3">
                <div *ngIf="!activeOutputs?.length" class="alert alert-info alert-styled-left shadow-0">
                    There are no Active Outputs defined yet.
                </div>
            </div>

            <ul class="section-list ml-4" id="lstResults">

                <!-- Active Outputs -->
                <li class="media" *ngFor="let output of activeOutputs, index as i"
                    (click)="selectOutput(output)"
                    [ngClass]="{'selected-dark': output?.projectOutputId === selectedOutput?.projectOutputId}">
                    <div class="mr-2">
                        <span class="badge badge-pill badge-success rounded-round p-2"><i
                                class="legitRipple icon-file-plus2"></i></span>
                    </div>
                    <div class="media-body">
                        <h6 class="text-success-800 font-weight-bold p-0">Output</h6>
                        <div class="row">
                            <div class="col">
                                <h5 class="record-description text-success mb-0 mt-1">{{output.statement}}</h5>
                            </div>
                            <div class="col-auto text-right">
                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                    aria-label="Example icon-button with a menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button type="button"
                                        class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple btn-block"
                                        data-popup="tooltip" title="" (click)="openCreateUpdateOutputDialog(output)"
                                        data-placement="right" data-original-title="Edit Output Link"
                                        data-toggle="modal" data-target="#mdlEditOutputLink">
                                        <i class="icon-pencil"></i>
                                        <span>Edit</span>
                                    </button>
                                    <button type="button"
                                        class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple btn-block"
                                        data-popup="tooltip" title="" data-placement="right"
                                        data-original-title="Delete Output" (click)="deleteOutputRequested(output)"
                                        tabindex="0">
                                        <i class="icon-trash"></i>
                                        <span>Delete</span>
                                    </button>
                                    <button type="button" *ngIf="isAllowedToArchive"
                                        class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple btn-block"
                                        data-popup="tooltip" title="" data-placement="right"
                                        data-original-title="Delete Output" (click)="archiveOutputRequested(output)"
                                        tabindex="0">
                                        <i class="icon-drawer2"></i>
                                        <span>Archive</span>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-truncate" *ngIf="output.lead?.fullName">
                                Lead: <span class="font-weight-bold">{{output.lead?.fullName}}</span>
                            </div>
                            <div class="col-auto text-right" *ngIf="output.due">
                                Due: <strong>{{output.due | date: 'M/d/yyyy'}}</strong>
                            </div>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
        <div class="tab-pane" [ngClass]="{active: activeTab === tabs.archived, show: activeTab === tabs.archived}"
            id="archive">
            <div class="p-2 pl-3">
                <div *ngIf="!archivedOutputs?.length" class="alert alert-info alert-styled-left shadow-0">
                    There are no Archived Outputs defined yet.
                </div>
            </div>

            <ul class="section-list ml-4" id="lstResults">

                <li class="media" *ngFor="let output of archivedOutputs, index as i"
                    (click)="selectOutput(output)"
                    [ngClass]="{'selected-dark': output?.projectOutputId === selectedOutput?.projectOutputId}">
                    <div class="mr-2">
                        <span class="badge badge-pill badge-success rounded-round p-2"><i
                                class="legitRipple icon-file-plus2"></i></span>
                    </div>
                    <div class="media-body">
                        <h6 class="text-success-800 font-weight-bold p-0">Output</h6>
                        <div class="row">
                            <div class="col">
                                <h5 class="record-description text-success mb-0 mt-1">{{output.statement}}</h5>
                            </div>
                            <div class="col-auto text-right">
                                <button type="button"
                                    class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                    data-popup="tooltip" title="" data-placement="right"
                                    data-original-title="Delete Output" (click)="deleteOutputRequested(output)"
                                    tabindex="0">
                                    <i class="icon-trash"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col text-truncate" *ngIf="output.lead?.fullName">
                                Lead: <span class="font-weight-bold">{{output.lead?.fullName}}</span>
                            </div>
                            <div class="col-auto text-right" *ngIf="output.due">
                                Due: <strong>{{output.due | date: 'M/d/yyyy'}}</strong>
                            </div>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
    </div>
