import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { CoreModule } from '../core.module';
import { OutcomeMetricModel } from '../../../hub_schema/hubTypes';
import { HubLovService } from './hub-lov.service';

@Injectable({
    providedIn: CoreModule
})
export class OutcomeLovService {
    private hubLovService: HubLovService = inject(HubLovService);

    public getScaOrganizationalMetrics(): Observable<OutcomeMetricModel[]> {
        return this.hubLovService.getScaOrganizationalMetrics();
    }

    public getOutcomeLovs(): Observable<any> {
        return forkJoin({
            attributionLevels: this.hubLovService.getAttributionLevels(),
            elementTypes: this.hubLovService.getElementTypes(),
            scaMetrics: this.hubLovService.getOutcomeMetrics(),
            scaOutcomeLists: this.hubLovService.getScaOutcomeLists(),
            scaOutcomeListOptions: this.hubLovService.getScaOutcomeListOptions(),
            scaOutcomeListOptionSublists: this.hubLovService.getScaOutcomeListOptionSubLists(),
            organizationalMetrics: this.getScaOrganizationalMetrics()
        });
    }
}
