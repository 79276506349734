<div class="edit-outcome-dialog-container" *ngIf="outcomeWizardForm">

    <div class="hub-modal-header-text">
        <h5 class="modal-title text-success">Edit Outcome</h5>
    </div>

    <mat-dialog-content mat-dialog-content class="hub-custom-scrollbar">

        <app-hub-stepper [formGroup]="outcomeWizardForm" linear [(selectedIndex)]="currentStepNumber">
            <cdk-step *ngIf="!isStrategy" [label]="is2030Goals ? '2030 Goals Metrics' : 'Custom Metrics'">

                <app-tnc-goals-parent-selection-panel *ngIf="is2030Goals" 
                    [outcomeWizardForm]="outcomeWizardForm"
                    [projectOutcome]="projectOutcome">
                </app-tnc-goals-parent-selection-panel>

                <app-custom-parent-selection-panel *ngIf="!is2030Goals" 
                    [outcomeWizardForm]="outcomeWizardForm" 
                    [projectOutcome]="projectOutcome">
                </app-custom-parent-selection-panel>

                <div class="hub-step-action-buttons">
                    <button disabled class="hub-icon-button" matStepperPrevious>
                        <b><i class="icon-arrow-left13 mr-2"></i></b>
                        <span>Previous</span>
                    </button>
                    <button class="hub-icon-button mirrored" matStepperNext>
                        <span>Next</span>
                        <b><i class="icon-arrow-right14 ml-2"></i></b>
                    </button>
                </div>


            </cdk-step>
            <cdk-step label="Outcome Statement">

                <div class="row">
                    <div class="col-md-12">
                        <p class="text-center font-weight-bold sb-b p-1">Define your Outcome Statement</p>

                        <span class="select-label text-info-800">{{isStrategy ? 'Strategy' : 'Project'}} Outcome Statement
                            <app-help-selector selector="1069"></app-help-selector>
                        </span>
                        
                        <textarea formControlName="statement" rows="3" cols="3"
                            class="form-control record-description"
                            placeholder="Enter your outcome statement here..."></textarea>

                        <div class="hub-validation-error-message">
                            {{getValidationErrorMessages("statement")}}
                        </div>

                        <div class="select-label text-info-800" style="margin: 10px 0;">Leverage Outcome
                            <app-help-selector selector="1204"></app-help-selector>
                        </div>

                        <mat-checkbox style="margin-left: 0" color="primary" formControlName="isPolicyLeverageOutcome">Policy</mat-checkbox>

                        <div *ngIf="isChildProjectOutcome" class="mt-4">
                            <span class="select-label text-info-800">For Reference:</span>
                            <div class="alert-secondary p-2 record-description">
                                <div>
                                    <span class="select-label text-secondary">
                                        Parent Outcome Statement<app-help-selector selector="1161"></app-help-selector>
                                    </span>
                                </div>
                                {{getParentOutcomeStatement()}}
                            </div>
                        </div>

                    </div>
                </div>

                <div class="hub-step-action-buttons">
                    <button *ngIf="!isStrategy" class="hub-icon-button" matStepperPrevious>
                        <b><i class="icon-arrow-left13 mr-2"></i></b>
                        <span>Previous</span>
                    </button>

                    <button type="button" class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
                        [disabled]="outcomeWizardForm.invalid || isSaving" (click)="saveProjectOutcome()">
                        <b>
                            <i class="icon-floppy-disk"></i>
                            <i class="icon-spinner4"></i>
                        </b>
                        Save Changes
                    </button>

                </div>

            </cdk-step>
        </app-hub-stepper>

    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="hub-modal-footer-text">
            <button (click)="cancelDialog()" class="cancel-button">Cancel</button>
        </div>
    </mat-dialog-actions>

</div>