import { Component, Input, OnInit, inject, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StrategicPillarEditService } from '../services/strategic-pillar-edit.service';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';
import { ProjectInfoModel, ProjectRelationModel, ProjectStrategicPillarModel, StrategicPillarRelatedProjectModel } from '../../../../../hub_schema/hubTypes';

@Component({
    selector: 'app-strategic-pillar-detail',
    templateUrl: './strategic-pillar-detail.component.html',
    styleUrls: ['./strategic-pillar-detail.component.scss'],
})
export class StrategicPillarDetailComponent extends HubRecordEditorBase implements OnInit {
    private strategicPillarEditService: StrategicPillarEditService = inject(StrategicPillarEditService);

    @Output()
    public strategicPillarChanged: EventEmitter<ProjectStrategicPillarModel> = new EventEmitter<ProjectStrategicPillarModel>();

    public isSaving: boolean = false;

    public get hasRelations(): boolean {
        return this.project.relations!.some(p => p.isPrimary);
    }

    public showAddHelp = true;
    public strategicPillarForm: FormGroup;
    public relatedProjectsTypeahead: FormControl;
    public relatedProjectsSuggestions$: Observable<ProjectInfoModel[]>;

    private _selectedStrategicPillar: ProjectStrategicPillarModel;
    public get selectedStrategicPillar(): ProjectStrategicPillarModel {
        return this._selectedStrategicPillar;
    }

    @Input()
    public set selectedStrategicPillar(value: ProjectStrategicPillarModel) {
        this._selectedStrategicPillar = value;
        this.selectedPillarChanged(value);
    }

    ngOnInit() {
        if (!this.strategicPillarForm) {
            this.initializeForm();
        }
    }

    private initializeForm(): void {
        this.strategicPillarForm = new FormGroup({
            projectStrategicPillarId: new FormControl(0),
            projectId: new FormControl(this.project.projectId),
            name: new FormControl('', Validators.maxLength(100)),
            description: new FormControl('', Validators.maxLength(500)),
            strategicPillarRelatedProjects: new FormControl([])            
        });

        this.relatedProjectsTypeahead = new FormControl('');

        this.relatedProjectsSuggestions$ = this.relatedProjectsTypeahead
            .valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                switchMap((term: string) => {
                    if (!this.project || !this.project.relations) {
                        return of([]);
                    }

                    let results = this.project.relations.filter((r: ProjectRelationModel) => {
                        return r.isPrimary && r.relatedProject?.name.toLowerCase().includes(term.toLowerCase());
                    }).map((r: any) => r.relatedProject).sort((a: ProjectInfoModel, b: ProjectInfoModel) => {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    });

                    return of(results);
                })
            );
    }

    public selectedPillarChanged(strategicPillar: ProjectStrategicPillarModel) {
        if (!this.strategicPillarForm ) {
            this.initializeForm();
        }

        if (strategicPillar) {
            this.strategicPillarForm.setValue(strategicPillar);
        }

        this.strategicPillarForm.markAsPristine();
    }

    public getTitleErrorMessage() {
        if (!this.strategicPillarForm.valid && this.strategicPillarForm.controls.name.invalid) {
            return 'Title cannot exceed 100 characters';
        }
        return '';
    }

    public getDescriptionErrorMessage() {
        if (!this.strategicPillarForm.valid && this.strategicPillarForm.controls.description.invalid) {
            return 'Description cannot exceed 500 characters';
        }
        return '';
    }

    // #region Strategic Pillar Related Projects

    public getRelatedProjects(): StrategicPillarRelatedProjectModel[] {
        return this.strategicPillarForm.controls.strategicPillarRelatedProjects.value;
    }

    public displayRelatedProject(option?: any): string | undefined {
        if (option && option.name) {
            return option.name;
        }
        return undefined;
    }

    public isRelatedProjectDisabled(item: ProjectInfoModel): boolean {
        return (this.strategicPillarForm.controls.strategicPillarRelatedProjects.value as StrategicPillarRelatedProjectModel[]).find(p => p.relatedProjectId === item.projectId) != null;
    }

    public relatedProjectSelected(e: MatAutocompleteSelectedEvent): void {
        const relatedProjectsControl = this.strategicPillarForm.controls.strategicPillarRelatedProjects;
        const relatedProjects = relatedProjectsControl.value as StrategicPillarRelatedProjectModel[];
        
        const project: ProjectInfoModel = e.option.value;

        const newRelatedProject: StrategicPillarRelatedProjectModel = {
            projectStrategicPillarId: this.selectedStrategicPillar.projectStrategicPillarId,
            relatedProjectId: project.projectId,
            relatedProject: project
        };

        relatedProjects.push(newRelatedProject);
        relatedProjectsControl.markAsDirty();
        this.relatedProjectsTypeahead.setValue('');
    }

    public removeRelatedProject(item: StrategicPillarRelatedProjectModel): void {
        const relatedProjectsControl = this.strategicPillarForm.controls.strategicPillarRelatedProjects;
        
        relatedProjectsControl.setValue(
            (relatedProjectsControl.value as StrategicPillarRelatedProjectModel[]).filter(p => p.relatedProjectId !== item.relatedProjectId)
        );

        relatedProjectsControl.markAsDirty();
    }

    // #endregion

    public updateStrategicPillar(): void {
        this.isSaving = true;
        const pillar = this.strategicPillarForm.value;

        this.strategicPillarEditService.updateProjectStrategicPillar(pillar).pipe(take(1)).subscribe({
            next: (savedPillar: ProjectStrategicPillarModel) => {
                this.isSaving = false;
                this.selectedStrategicPillar = savedPillar;
                this.strategicPillarChanged.emit(savedPillar);
                this.strategicPillarForm.markAsPristine();
            },
            error: (err) => {
                console.error(err);
                this.isSaving = false;
            }
        });
    }
}
