<div class="content">
    <div class="unsaved-changes-dialog-container">
        <div class="dialog-icon">!</div>

        <div class="dialog-content">
            <div class="dialog-header">
                <div class="title">
                    Are you sure?
                </div>
            </div>

            <div class="dialog-body hub-custom-scrollbar">
                You have unsaved changes, do you want to Save?
            </div>

            <div class="dialog-footer">
                <button (click)="close('cancel')" class="btn cancel-button">Cancel</button>

                <button (click)="dismiss('dismiss')" class="btn">
                    Close without Saving
                </button>

                <button (click)="close('save')" class="btn">
                    Save Now
                </button>

            </div>
        </div>
    </div>
</div>