import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { LegalDisclosureComponent } from './legal-disclosure/legal-disclosure.component';
import { BuildInfoService } from './services/build-info.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public releaseInfo = '';
  private frontendBuildInfo = '--';
  private backendBuildInfo = '--';

  constructor(
    private authService: AuthService,
    private buildInfoService: BuildInfoService,
    public dialog: MatDialog,
  ) {}

  /**
   * The release info pill should display the current app release version that is noted in the
   * frontend release_info asset file.
   * If this asset file was processed in a BB pipeline and user is an IT Admin, the pill should also contain
   * info showing the build number and the Git branch. The Git branch info is a part of the
   * buildNumber string in the frontend and part of buildInfo in the backend.
   */
  public thisYear = new Date().getFullYear();

  public get tooltip() {
    let buildInfoTooltip = 'Tags & Branches:';

    if (this.authService.userIsITAdmin()) {
      buildInfoTooltip = `
                ${buildInfoTooltip}
                <br />Frontend: ${this.frontendBuildInfo}
                <br />Backend: ${this.backendBuildInfo}
            `;
    }
    return buildInfoTooltip;
  }

  ngOnInit() {
    this.buildInfoService
      .getFrontendVersionInfo()
      .pipe(take(1))
      .subscribe({
        next: (info: any) => {
          this.releaseInfo = info.releaseNumber;
          this.frontendBuildInfo = info.buildNumber || '--';
        },
        error: (e) => {
          console.error(e);
        },
      });

    if (this.authService.isLoggedIn()) {
      this.populateBuildInfo();
    } else {
      this.authService.authorizationComplete.pipe(take(1)).subscribe(() => {
        this.populateBuildInfo();
      });
    }
  }

  populateBuildInfo(): void {
    this.buildInfoService
      .getBackendBuildInfo()
      .pipe(take(1))
      .subscribe((buildInfo: string) => {
        this.backendBuildInfo = buildInfo || '--';
      });
  }

  public openLegalDisclaimerDialog(): void {
    this.dialog.open(LegalDisclosureComponent);
  }
}
