import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { HubRecordEditContainerComponent } from '../hub-record-edit-container/hub-record-edit-container.component';

@Injectable({
  providedIn: 'root',
})
export class CloseEditGuard implements CanDeactivate<HubRecordEditContainerComponent> {
  canDeactivate(hubRecordEditContainer: HubRecordEditContainerComponent) {
    return hubRecordEditContainer.isDirty ? hubRecordEditContainer.showUnsavedChangesDialog() : true;
  }
}
