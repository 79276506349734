<div class="hub-user-profile-container" *ngIf="!notFound && employee && goals">

    <div class="user-profile-header">

        <div class="avatar-panel">
            <div class="avatar" [ngStyle]="{'background-image': avatarUrl }">
                <span *ngIf="avatarUrl === 'none'" class="initials">{{employee?.initials}}</span>
            </div>
        </div>

        <div class="user-info-panel">

            <div class="change-user-panel">
                <app-change-user (userSelected)="newUserSelected($event)"></app-change-user>
            </div>

            <div class="user-name-panel">

                <div class="user-name">
                    <h1>
                        {{employee?.fullName}}
                        <span *ngIf="!employee.isActive"> (Inactive)</span>
                    </h1>

                    <div *ngIf="viewingOtherProfile" class="list-icons">
                        <div ngbDropdown placement="bottom-right">
                            <a id="dropdownBasic1" ngbDropdownToggle class="caret-0">
                                <i class="icon-menu9" style="font-size: 1.5em;"></i>
                            </a>

                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                <a href="mailto:{{employee?.email}}" ngbDropdownItem>
                                    <i class="icon-envelop2"></i> E-mail
                                    {{employee?.firstNameOnlyDisplay}}
                                </a>

                                <a [href]="userDataService.generateTeamsUri(employee?.email)" ngbDropdownItem target="chat">
                                    <i class="icon-comment"></i> Start Teams chat
                                </a>

                                <a href="https://nam.delve.office.com/?p={{employee.email}}&v=work"
                                    target="_blank" ngbDropdownItem>
                                    <i class="icon-user"></i> Visit Profile
                                </a>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tabs-panel">
            <ul class="nav nav-tabs nav-tabs-top">
                <li class="nav-item">
                    <a href="#" (click)="setTabTo(tabs.work, $event)" [ngClass]="{active: activeTab === tabs.work}"
                        class="nav-link">{{possessivePronoun}} Work</a>
                </li>
                <li class="nav-item">
                    <a href="#" (click)="setTabTo(tabs.favorites, $event)"
                        [ngClass]="{active: activeTab === tabs.favorites}" class="nav-link">{{possessivePronoun}}
                        Favorites</a>
                </li>
                <li class="nav-item">
                    <a href="#" (click)="setTabTo(tabs.collections, $event)"
                        [ngClass]="{active: activeTab === tabs.collections}" class="nav-link">{{possessivePronoun}}
                        Collections</a>
                </li>
            </ul>

            <div class="flex-icon-container">
            <div *ngIf="isAdmin || hasEditPermissions" class="card-button-panel" >
                <span class="btn flex-icon-container" (click)="openBulkEditWizard()">
                    <i class="icon-gear"></i>
                    <div class="bulk-edit-label">Bulk Edit</div>
                </span>
            </div>
                <div class="card-button-panel" *ngIf="activeTab !== tabs.collections">
                <span class="btn btn-icon legitRipple mr-1" [ngClass]="{'bg-teal': !showingListView}"
                    (click)="toggleListView()" matTooltipShowDelay="500"
                    [matTooltip]="(showingListView ? 'Show' : 'Hide') +  ' Cards'" id="ToggleListView">
                    <i class="icon-grid"></i>
                </span>
            </div>
            </div>

        </div>



    </div>

    <div class="user-profile-body">

        <div class="user-details-panel">

            <h3>{{possessivePronoun}} details</h3>

            <span class="d-block text-align-right mb-1">
                {{employee?.businessUnit.name}} <i class="icon-office ml-1 text-muted"></i>
            </span>

            <span class="d-block text-align-right mb-1">
                {{employee?.businessUnit?.division?.name}} <i class="icon-users4 ml-1 text-muted"></i>
            </span>
            <span class="d-block text-align-right mb-1">
                {{employee?.office}} <i class="icon-location4 ml-1
                    text-muted"></i>
            </span>
            <span class="d-block text-align-right mb-1">
                <a href="mailto:{{employee?.email}}">{{employee?.email}}</a>
                <i class="icon-envelop2 ml-1 text-muted"></i>
            </span>
            <span class="d-block text-align-right mb-1">
                <a href="tel:{{employee?.phoneWork}}">{{employee?.phoneWork}}</a>
                <i class="icon-phone2 ml-1 text-muted"></i>
            </span>
            <span  class="d-block text-align-right mb-1" >
                <div class="employee-app-roles-container">
                    <div [innerText]="employeeApplicationRoleDisplay"></div>
                    <i class="icon-gear ml-1 text-muted"></i>
                </div>
            </span>


        </div>

        <div class="user-content-panel">

            <div class="work-panel" *ngIf="activeTab === tabs.work">

                <div class="title-and-filter-bar">
                    <h3>
                        <span>{{possessivePronoun}} conservation work</span>
                        <span *ngIf="filteredProjects && filteredProjects.length"> ({{filteredProjects.length}})</span>

                    </h3>

                    <div class="filters" *ngIf="!viewingOtherProfile">
                        <mat-form-field *ngIf="projectLists?.length > 1">
                            <mat-label>Records by Role:</mat-label>
                            <mat-select [disableOptionCentering]="true" [(value)]="currentProjectList" panelWidth="250px">
                                <mat-option *ngFor="let projectList of projectLists" [value]="projectList">
                                    {{projectList}} Projects</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>Record Status</mat-label>
                            <mat-select [disableOptionCentering]="true" [(value)]="recordStatus">
                                <mat-option *ngFor="let status of statuses" [value]="status">{{status.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field >
                            <mat-label>Last Updated</mat-label>
                            <mat-select [disableOptionCentering]="true" [(value)]="selectedDateRange">
                                <mat-option *ngFor="let dateRange of dateRanges" [value]="dateRange">{{dateRange.name}}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>
                    </div>
                </div>

                <div class="results-panel hub-custom-scrollbar" [ngClass]="{'list-view': showingListView}">
                    <div class="card-columns">
                        <div *ngFor="let project of filteredProjects" class="card card-record card-shadow border-blue 1"
                            [ngClass]="{'status-Canceled': project.status.name === 'Canceled'}">

                            <!-- Todo (ACE 2/25/2022) the project card itself should manage its own styles.  All the card classes above shouldn't need to be here -->
                            <app-project-card-graphql [project]="project" [showCardDetails]="true" [goals]="goals"
                                (switchTabToFavoritesRequested)="switchTabToFavoritesRequested()"                                
                                [isUserCanEdit]="isAllowedToEdit(project)">
                            </app-project-card-graphql>
                        </div>
                    </div>

                    <app-modal-spinner *ngIf="!projects"></app-modal-spinner>
                </div>

            </div>

            <div class="favorites-panel" *ngIf="activeTab === tabs.favorites">
                <h3>
                    {{possessivePronoun}} favorite conservation work
                    <app-help-selector [selector]="1151"></app-help-selector>
                </h3>

                <div class="results-panel hub-custom-scrollbar" [ngClass]="{'list-view': showingListView}">
                    <div class="card-columns">
                        <div *ngFor="let project of myFavoriteProjects"
                            class="card card-record card-shadow status-{{project?.status}}">

                            <app-project-card-graphql [project]="project" [isUserCanEdit]="isAllowedToEdit(project)"
                                (switchTabToFavoritesRequested)="switchTabToFavoritesRequested()"
                                [goals]="goals"
                                [showCardDetails]="true">
                            </app-project-card-graphql>
                        </div>
                    </div>
                </div>

                <div class="alert alert-info alert-styled-left shadow-0" *ngIf="!myFavoriteProjects?.length">
                    <span *ngIf="!employeeId || employeeId === userId">
                        You currently do not have any Favorites registered.
                    </span>
                    <span *ngIf="employeeId && employeeId !== userId">
                        {{employee?.firstName}} currently does not have any Favorites registered.
                    </span>
                </div>
            </div>

            <div class="collections-panel" *ngIf="activeTab === tabs.collections">
                <h3>
                    {{possessivePronoun}} collections<app-help-selector [selector]="1150">
                    </app-help-selector>
                </h3>
                <div class="results-panel hub-custom-scrollbar">
                    <div class="card-columns">

                        <app-collection-card *ngFor="let collection of myCollections; let i = index"
                            [employeeId]="employeeId"
                            [userId]="userId"
                            [collection]="collection"
                            (deleteCollectionRequested)="deleteCollectionShowModal(collection.recordCollectionId, i)">
                        </app-collection-card>
                    </div>
                </div>
                <div class="alert alert-info alert-styled-left shadow-0"
                    *ngIf="!myCollections || !myCollections.length">
                    <span *ngIf="!employeeId || employeeId === userId">
                        You currently do not have any Collections.
                    </span>
                    <span *ngIf="employeeId && employeeId !== userId">
                        {{employee?.firstNameOnlyDisplay}} currently does not have any Collections.
                    </span>
                </div>
            </div>

        </div>

    </div>

</div>

<app-not-found *ngIf="notFound"></app-not-found>

<app-modal-spinner *ngIf="isBusy"></app-modal-spinner>
