import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-strategic-pillar-list-item',
    templateUrl: './strategic-pillar-list-item.component.html',
    styleUrls: ['./strategic-pillar-list-item.component.scss']
})
export class StrategicPillarListItemComponent {

    @Input() public strategicPillar: any;
    @Input() isSelected;

    @Output()
    public deleteStrategicPillarRequested: EventEmitter<void> = new EventEmitter();

    constructor() { }

    public deleteStrategicPillarClicked(e): void {
        this.deleteStrategicPillarRequested.emit();
        e.preventDefault();
    }
}
