<div class="goal-details-container" *ngIf="goalDetails">

    <div class="back-to-overview">
        <a routerLink="/">
            <img src="/assets/images/icons/BackArrow.svg">
            Back to Overview
        </a>

    </div>

    <h1>{{labelText}}</h1>

    <div class="goal">
        <div class="goal-info-panel goal-header">
            <div class="sub-theme">
                <div class="sub-theme-item">
                    <div class="target-panel">
                        <div class="target target-header">
                            TNC <br />
                            2030 Target
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <app-goal-display [goal]="goalDetails.goal" [isNavigable]="false"></app-goal-display>

    <div class="sub-themes-panel" *ngIf="goalDetails">
        <div class="sub-theme" *ngFor="let subTheme of goalDetails.subThemes">
            <div class="name" *ngIf="subTheme.name">{{subTheme.name}}</div>

            <div class="metric" *ngFor="let expandableMetric of subTheme.metrics; let i = index"
                (click)="toggleExpandedState(expandableMetric)">
                <div class="header">
                    <div class="name-panel">
                        <div class="name {{'goal-background-' + goalId }}">
                            {{expandableMetric.metric.metric}}
                        </div>
                    </div>
                    <div class="chart-panel">
                        <div class="chart">
                            <app-metric-donut-chart [metric]="expandableMetric.metric" [goalId]="goalId"
                                [metricIndex]="expandableMetric.metric.metricId"></app-metric-donut-chart>
                        </div>
                    </div>
                    <div class="target-panel">
                        <div class="target">
                            {{expandableMetric.metric.targetValueDisplay}}
                            <span [innerHtml]="expandableMetric.metric.unit"></span>
                        </div>
                    </div>
                </div>

                <div class="body" *ngIf="expandableMetric.isExpanded">
                    <div class="metric-progress-by-region-panel">

                        <h2>{{expandableMetric.metric.metric}} by region</h2>

                        <div class="description">
                            The regional targets shown are the sum of targets established after January 1, 2020. The
                            regional progress percentage is the sum of progress reported within the same time frame.
                        </div>

                        <div class="regions-chart-panel" [ngClass]="{'has-overages': true}">
                            <div class="chart-body">
                                <div class="chart-item" *ngFor="let region of expandableMetric.metric.regions"
                                    [ngClass]="{'has-overage': regionHasOverage(region)}">
                                    <div class="region-name">
                                        {{region.region}}
                                    </div>

                                    <div class="progress-percentage-container">
                                        <div class="progress-percentage-panel"
                                            [ngStyle]="{'left': getProgressLeft(region)}">
                                            <div class="progress-percentage-panel-contents">
                                                <div class="progress-percentage">
                                                    {{getProgressPercentage(region) + '%'}}
                                                </div>
                                                <div class="progress-percentage-mark"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="bar">
                                        <div class="target-series {{'goal-alt-background-' + goalId }}"></div>
                                        <div class="progress-series {{'goal-background-' + goalId }}"
                                            [ngStyle]="{'width.%': getProgressWidth(region)}"></div>
                                    </div>

                                    <div class="overage {{'goal-background-' + goalId }}"
                                        *ngIf="regionHasOverage(region)"></div>
                                    <div class="target">
                                      {{region.regionsTargetValue | number: numberPipePrecision(expandableMetric.metric.floatingPointPrecision) }}&nbsp;
                                        <span [innerHtml]="expandableMetric.metric.regionalDetailsShortUnitDisplay"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="legend-panel">
                                <div class="legend">
                                    <div class="legend-item">
                                        <div class="square-panel">
                                            <div class="square {{'goal-background-' + goalId }}"></div>
                                        </div>
                                        <div class="description">Total Progress to Date</div>
                                    </div>
                                    <div class="legend-item">
                                        <div class="square-panel">
                                            <div class="square {{'goal-alt-background-' + goalId }}"></div>
                                        </div>
                                        <div class="description">Total Project Targets</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="footnote">
                            ** Where a project touches down in multiple regions, progress will be attributed
                            to the lead region as represented in the Hub. Strategies from our global teams
                            are primarily tracked at the regional level. Progress shown includes project progress
                            dated on or after January 1, 2020.
                        </div>

                    </div>

                    <hr />

                    <div class="strategies-showing-recent-progress-panel">
                        <h2>Strategies showing recent progress</h2>

                        <div class="description">
                            The strategies listed below have been recently updated with new progress data.
                        </div>

                        <div class="strategies-list">
                            <div class="strategy" *ngFor="let strategy of expandableMetric.metric.recentStrategies">
                                <a [routerLink]="'/portfolio/' + strategy.strategyProjectId"
                                    class="name">{{strategy.strategy}}</a>
                                <div class="button-panel">
                                    <button [routerLink]="'/portfolio/' + strategy.strategyProjectId">GO TO HUB
                                        RECORD</button>
                                </div>

                            </div>
                        </div>

                        <div class="portfolio-link-panel">
                            <a href="javascript: void(0)" (click)="showAllStrategies(expandableMetric.metric)">Show all
                                Strategies for <span>{{expandableMetric.metric.metric}}</span></a>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
