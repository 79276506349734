import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { getCurrentFiscalYear } from '../../../../shared/helpers/get-current-fiscal-year';
import { ProjectFinancialModel } from '../../../../../hub_schema/hubTypes';
import { Subscription, take } from 'rxjs';

// todo (ACE 10/14/2023): is hard-coding the code any better than hard coding the ID?        
// const estimatedTncCostsFinanceMetricKey = 'estimated-tnc-costs';
// const estimatedNonTncCostsFinanceMetricKey = 'estimated-non-tnc-costs-funded-by-tnc';
const estimatedTncCostsFinanceMetricId: number = 1000;
const estimatedNonTncCostsFinanceMetricId: number = 1001;

@Component({
  selector: 'app-financials-edit-panel',
  templateUrl: './financials-edit-panel.component.html',
  styleUrls: ['./financials-edit-panel.component.scss']
})
export class FinancialsEditPanelComponent extends HubRecordEditorBase implements OnInit, OnDestroy {
    public fiscalYears: number[];
    public financialsEditForm: FormGroup;
    private valueChangeSubscriptions: Subscription[] = [];
    private financialValiditySubcription: Subscription;

    public get fiscalYearArray(): FormArray {
        return this.financialsEditForm.controls.fiscalYears as FormArray;
    }

    public ngOnInit(): void {
        this.fiscalYears = this.getFiscalYears();
        this.createfinancialsEditForm();
    }

    public getFiscalYears(): number[] {
        const today = new Date();
        let year = getCurrentFiscalYear();
        let i = 0;
        const years: number[] = [];

        while (year <= 2030) {
            years.push(year);
            year++;
        }
        return years;
    }

    private createfinancialsEditForm(): void {
        this.financialsEditForm = new FormGroup({
            fiscalYears: new FormArray([])
        });

        for (let fiscalYear of this.fiscalYears) {
            const yearFinancials = this.project.projectFinancials!.filter(f => f.year == fiscalYear);

            const estimatedTncCosts = yearFinancials?.find(f => f.financeMetricId === estimatedTncCostsFinanceMetricId);
            const estimatedNonTncCosts = yearFinancials?.find(f => f.financeMetricId === estimatedNonTncCostsFinanceMetricId);

            const formGroup = new FormGroup({
                'estimatedTncCosts': new FormGroup({
                    'projectFinancialId': new FormControl(estimatedTncCosts ? estimatedTncCosts.projectFinancialId : null),
                    'financeMetricId': new FormControl(estimatedTncCostsFinanceMetricId),
                    'year': new FormControl(fiscalYear),
                    'financeValue': new FormControl(estimatedTncCosts ? estimatedTncCosts.value : 0, {
                        updateOn: 'blur',
                        validators: [Validators.required, Validators.pattern("^[0-9]*$")]
                    })
                }),
                'estimatedNonTncCosts': new FormGroup({
                    'projectFinancialId': new FormControl(estimatedNonTncCosts ? estimatedNonTncCosts.projectFinancialId : null),
                    'financeMetricId': new FormControl(estimatedNonTncCostsFinanceMetricId),
                    'year': new FormControl(fiscalYear),
                    'financeValue': new FormControl(estimatedNonTncCosts ? estimatedNonTncCosts.value : 0, {
                        updateOn: 'blur',
                        validators: [Validators.required, Validators.pattern("^[0-9]*$")]
                    })
                })
            });

            // Keep this form synchronized with projectEditForm.projectFinancials
            const tncCostsSubscription = (formGroup.controls.estimatedTncCosts as FormGroup).controls.financeValue.valueChanges.subscribe((value: number) => {
                this.estimatedTncCostChanged(fiscalYear, value)
            });

            // Keep this form synchronized with projectEditForm.projectFinancials
            const nonTncCostsSubscription = (formGroup.controls.estimatedNonTncCosts as FormGroup).controls.financeValue.valueChanges.subscribe((value: number) => {
                this.estimatedNonTncCostChanged(fiscalYear, value);
            });

            this.valueChangeSubscriptions.push(tncCostsSubscription);
            this.valueChangeSubscriptions.push(nonTncCostsSubscription);

            (this.financialsEditForm.controls.fiscalYears as FormArray).push(formGroup);
        }

        this.financialValiditySubcription = this.financialsEditForm.statusChanges.subscribe((status: string) => {
            this.hubRecordEditService.isFinancialEditPanelValid = status === 'VALID';
        });
    }

    private estimatedTncCostChanged(fiscalYear: number, financeValue: number) {
        const allProjectFinancials = this.projectEditForm?.controls.projectFinancials.value as ProjectFinancialModel[];
        let projectFinancial = allProjectFinancials.find(f => f.year === fiscalYear && f.financeMetricId === estimatedTncCostsFinanceMetricId);
        
        if (projectFinancial) {
            projectFinancial.value = financeValue;
        }
        else {
            projectFinancial = {
                projectId: this.project.projectId,
                year: fiscalYear,
                financeMetricId: estimatedTncCostsFinanceMetricId,
                value: financeValue
            };
            allProjectFinancials.push(projectFinancial);
        }
        this.projectEditForm?.controls.projectFinancials.markAsDirty();
    }
    
    private estimatedNonTncCostChanged(fiscalYear: number, financeValue: number) {
        const allProjectFinancials = this.projectEditForm?.controls.projectFinancials.value as ProjectFinancialModel[];
        let projectFinancial = allProjectFinancials.find(f => f.year === fiscalYear && f.financeMetricId === estimatedNonTncCostsFinanceMetricId);

        if (projectFinancial) {
            projectFinancial.value = financeValue;
        }
        else {
            projectFinancial = {
                projectId: this.project.projectId,
                year: fiscalYear,
                financeMetricId: estimatedNonTncCostsFinanceMetricId,
                value: financeValue
            };
            allProjectFinancials.push(projectFinancial);
        }
        this.projectEditForm?.controls.projectFinancials.markAsDirty();
    }

    public getTotalEstimatedTncCosts(): number {
        let sum = 0;

        for (const fiscalYearGroup of this.fiscalYearArray.controls) {
            const estimatedTncCostGroup = (fiscalYearGroup as FormGroup).controls.estimatedTncCosts as FormGroup;
            sum += estimatedTncCostGroup.controls.financeValue.value;
        }

        return sum;
    }

    public getTotalEstimatedNonTncCosts(): number {
        let sum = 0;

        for (const fiscalYearGroup of this.fiscalYearArray.controls) {
            const estimatedTncCostGroup = (fiscalYearGroup as FormGroup).controls.estimatedNonTncCosts as FormGroup;
            sum += estimatedTncCostGroup.controls.financeValue.value;
        }

        return sum;
    }

    public getAllocatedWorkingBudget(fg: FormGroup) {
        const year = this.getFiscalYear(fg);
        const yearFinancials = this.project.insightFinancials!.find(f => f.fiscalYear === year);
        return yearFinancials?.budget || 0;
    }

    public getTotalAllocatedWorkingBudget() {
        let total = 0;

        for (let fg of this.fiscalYearArray.controls) {
            total += this.getAllocatedWorkingBudget(fg as FormGroup);
        }

        return total;
    }

    public getFiscalYear(fg: FormGroup): number {
        return (fg.controls.estimatedTncCosts as FormGroup).controls.year.value;
    }

    public ngOnDestroy(): void {
        for (let subsription of this.valueChangeSubscriptions) {
            subsription.unsubscribe();
        }

        if (this.financialValiditySubcription) {
            this.financialValiditySubcription.unsubscribe();
        }
    }

}
