import { Component, HostListener, OnDestroy, OnInit, inject, ChangeDetectorRef } from '@angular/core';
import { ProjectModel, StatusModel } from '../../../hub_schema/hubTypes';
import { FormControl, FormGroup } from '@angular/forms';
import { take, tap } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HubRecordEditorBase } from './_hub_record_editor_base';
import { AuthService } from '../../core/services/auth/auth.service';
import { ProjectLovService } from '../../core/services/project-lov.service';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog/unsaved-changes-dialog.component';
import { ErrorService } from '../../core/services/error.service';
import { DeleteConfirmationDialogComponent } from '../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { HubMessageBoxComponent } from '../../shared/components/hub-message-box/hub-message-box.component';
import { SummaryEditComponent } from './summary-edit/summary-edit.component';

@Component({
    selector: 'app-hub-record-edit-container',
    templateUrl: './hub-record-edit-container.component.html',
    styleUrls: ['./hub-record-edit-container.component.scss']
})
export class HubRecordEditContainerComponent extends HubRecordEditorBase implements OnInit, OnDestroy {
    // Dependencies
    private router: Router = inject(Router);
    private route: ActivatedRoute = inject(ActivatedRoute);
    private authService: AuthService = inject(AuthService);
    private projectLovService: ProjectLovService = inject(ProjectLovService);
    private dialogService: MatDialog = inject(MatDialog);
    private errorService: ErrorService = inject(ErrorService);
    private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

    // #region Page Properties

    public isBusy: boolean = false;
    public savingState: string = '';
    public unauthorized: boolean = false;
    private routeChangedSubscription: Subscription;

    public projectTabs: any = {
        summary: 'summary',
        team: 'team',
        location: 'location',
        relations: 'relations',
        strategicPillars: 'strategic-pillars',
        outcomes: 'outcomes',
        intermediate: 'intermediate',
        outputs: 'outputs',
        financials: 'financials',
        attributes: 'attributes',
        associatedFiles: 'associatedFiles',
        admin: 'admin',
    };

    public activeTab: string;

    public get isDirty(): boolean {
        return this.projectEditForm?.dirty || false;
    }

    // #endregion Page Properties

    // #region Data Properties

    public projectStatuses: StatusModel[];

    // #endregion Data Properties

    // #region Page Methods

    // This just prevents the ExpressionChangedAfterItHasBeenCheckedError console error 
    public ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    public ngOnInit(): void {
        this.routeChangedSubscription = this.route.params.subscribe((params: Params) => {
            const projectId = params['id'];
            this.activeTab = this.projectTabs.summary;
            this.isBusy = true;

            forkJoin({
                getProject: this.hubRecordEditService.getProject(projectId),
                projectStatuses: this.projectLovService.getProjectStatuses()
            })
                .pipe(take(1))
                .subscribe((results) => {
                    if (this.project.isDeleted) {
                        this.router.navigateByUrl('/project-deleted');
                    }
                    
                    this.projectStatuses = results.projectStatuses;
                    this.isBusy = false;
                });
        });
    }

    public activateTab(tab: string): void {
        setTimeout(() => {
            this.activeTab = tab;
        });
    }

    public showUnsavedChangesDialog(): Observable<boolean> {
        const dialogRef = this.dialogService.open(UnsavedChangesDialogComponent);
        return dialogRef.afterClosed();
    }

    // #endregion Page Methods

    // #region authorization methods

    public isUserAllowedToEditStatus(): boolean {
        return this.authService.userIsITAdmin() ||
            this.authService.userIsBusinessAdmin() ||
            this.authService.userIsDivisionEditorFor(this.project) ||
            this.authService.userIsRegionEditorFor(this.project) ||
            this.authService.userIsBusinessUnitEditorFor(this.project) ||
            this.authService.isTeamLead(this.project);
    }

    public canUserDeleteRecord(): boolean {
        return this.authService.isAllowedToDeleteAsRegionOrDivisionEditor(this.project);
    }

    // #endregion authorization methods

    // #region Data / Form methods

    public getSaveButtonTooltip(): string {
        if (!this.isProjectValid || !this.isProjectNameValid) {
            return 'The form contains invalid data that must be corrected before the record can be saved.';
        }
        else if (!this.isDirty) {
            return 'No pending changes to save';
        }
        return 'save changes';
    }

    private isUserAllowedToReactivateCanceledRecord(): boolean {
        return this.authService.userIsITAdmin() || this.authService.userIsBusinessAdmin();
    }

    private isStatusTransitionValid(desiredStatus: StatusModel): boolean {
        let isValid: boolean = false;
        if (this.project.status!.name === "Proposed") {
            isValid = desiredStatus.name === "Active" || desiredStatus.name === 'Canceled';
        }
        if (this.project.status!.name === "Active") {
            isValid = desiredStatus.name === "Completed" || desiredStatus.name === "Canceled";
        }
        if (this.project.status!.name === "Completed") {
            isValid = desiredStatus.name === "Active";
        }
        if (this.project.status!.name === "Canceled") {
            isValid = desiredStatus.name === "Proposed"
        }

        if (desiredStatus.name === 'Active' && isValid) {
            const hasInsightRecord = this.project.insightRecords!.length > 0;
            isValid = isValid && hasInsightRecord;
        }
        return isValid;
    }

    public isStatusDisabled(desiredStatus: StatusModel): boolean {
        const isTransitionValid = this.isStatusTransitionValid(desiredStatus);

        if (this.project.status!.name === 'Completed') {
            return !isTransitionValid || !this.isUserAllowedToReactivateCanceledRecord();
        }
        return !isTransitionValid || !this.isUserAllowedToEditStatus();
    }

    // #endregion Data / Form methods

    // #region Mutations

    public deleteHubRecordRequested(): void {
        const dialogRef = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {warningMessage: "You will not be able to recover this record. The record information will no longer display or rollup in in the Hub Application and the Hub Dashboards. If this information still needs to display and the work is no longer in progress, select Complete or Cancel under Change Record Status."}
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed.pipe(take(1)).subscribe(() => {
            deleteConfirmationDialog.isBusy = true;

            this.hubRecordEditService.deleteProject().pipe(take(1)).subscribe({
                next: () => {   dialogRef.close();

                    const dialogRefMessageBox = this.dialogService.open(HubMessageBoxComponent, {
                        data: {
                            title: "Deleted",
                            message: `${this.project.name} has successfully been deleted.`,
                            confirmButtonText:"Ok"
                        }
                    })

                    dialogRefMessageBox.afterClosed().subscribe(() => {
                        this.router.navigateByUrl('/portfolio');
                    })
                },
                error: (err) => {this.errorService.addError(err, true);}
            });
        })
    };

    public saveProject(): void {
        this.savingState = 'saving';

        this.hubRecordEditService.updateProject().pipe(take(1)).subscribe({
            next: (savedProject: ProjectModel) => {
                this.savingState = 'saved';

                setTimeout(() => {
                    this.savingState = '';
                }, 1500);
            },
            error: (err) => {
                this.errorService.addError(err, true);
                this.savingState = '';
            }
        });

    }

    // #endregion mutations

    @HostListener('window:beforeunload', ['$event'])
    public beforeUnloadHandler(event) {
        if (this.projectEditForm!.dirty) {
            event.returnValue = false;
            event.preventDefault();
            return false;
        }
    }

    public ngOnDestroy(): void {
        this.projectEditForm = undefined;

        if (this.routeChangedSubscription) {
            this.routeChangedSubscription.unsubscribe();
        }
    }
}
