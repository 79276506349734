<div class="p-3" *ngIf="projectEditForm" [formGroup]="projectEditForm">
    <h5 class="edit-section-header">Relations</h5>

    <div class="row">
        <div class="col-md-6">
            <div class="select-label text-info-700 mt-4 d-block">Core Projects<app-help-selector [selector]="1046"></app-help-selector></div>

            <div class="core-projects-panel">

                <div class="tokenfield form-control hub-custom-scrollbar core-projects-list"
                     style="overflow-x: hidden; overflow-y: auto; max-height: 200px;"
                     cdkDropList
                     id="selectedCoreProjects"
                     cdkDropListOrientation="horizontal"
                     #coreRelationsList="cdkDropList"
                     [cdkDropListConnectedTo]="[proposedRelationsList, additionalRelationsList]"
                     [cdkDropListData]="coreProjects"
                     (cdkDropListDropped)="relationDroppedOnCoreProjects($event)">

                    <div class="token" *ngFor="let item of coreProjects"
                         cdkDrag
                         [cdkDragData]="item">
                        <span class="token-label">
                            <i class="icon-loop3 mr-1" [ngClass]="{'d-none' : !isRelationReciprocal(item, true)}"></i>{{item.name}}
                        </span>
                        <span class="close" (click)="deleteCoreProject(item)" aria-hidden="true">×</span>

                        <div *cdkDragPreview class="token-preview">
                            <span class="add-icon">
                                <i class="icon-add"></i>
                            </span>
                            <span class="token-label">
                                {{item.name}}
                            </span>
                        </div>

                        <div *cdkDragPlaceholder class="token-placeholder">
                            <span class="token-label">
                                {{item.name}}
                            </span>
                        </div>

                    </div>
                </div>

                <div class="core-project-unavailable" [ngClass]="{visible: !isCoreProjectRelationAvailable && coreProjectDraggedOver}">
                    Project has already been claimed as Core by {{relationPrimaryStrategy?.name}}.  Move to Additional or confer with other Strategy.
                </div>

            </div>

            <div class="hub-select-container">
                <mat-form-field>
                    <input type="text" matInput [formControl]="coreProjectsTypeahead" [matAutocomplete]="coreRelationsAutocomplete" placeholder="Enter a name of project here..." />
                </mat-form-field>

                <mat-autocomplete #coreRelationsAutocomplete="matAutocomplete" (optionSelected)="coreProjectSelected($event)">
                    <mat-option [disabled]="isRelationOptionDisabled(proj)"
                            *ngFor="let proj of coreProjectSuggestions$ | async" [value]="proj">
                        {{proj.name}}
                    </mat-option>
                </mat-autocomplete>
            </div>

            <div class="mt-1 text-muted font-size-sm">
                <i class="icon-loop3 mr-1 ml-1 text-muted round-rounded"></i>: indicates a reciprocal relationship between Project and Strategy
            </div>


            <span class="select-label text-info-700 mt-4 d-block">Additional Projects<app-help-selector [selector]="1047"></app-help-selector></span>

            <div class="tokenfield form-control hub-custom-scrollbar"
                 style="overflow-x: hidden; overflow-y: auto; max-height: 200px;"
                 cdkDropList
                 id="selectedAdditionalProjects"
                 cdkDropListOrientation="horizontal"
                 #additionalRelationsList="cdkDropList"
                 [cdkDropListConnectedTo]="[coreRelationsList, proposedRelationsList]"
                 [cdkDropListData]="additionalProjects"
                 (cdkDropListDropped)="relationDroppedOnAdditionalProjects($event)">

                <div class="token" *ngFor="let item of additionalProjects"
                     cdkDrag
                     [cdkDragData]="item">
                    <span class="token-label">
                        <i class="icon-loop3 mr-1" [ngClass]="{'d-none' : !isRelationReciprocal(item, false)}"></i>{{item.name}}
                    </span>
                    <span class="close" (click)="deleteAdditionalProject(item)" aria-hidden="true">×</span>

                    <div *cdkDragPreview class="token-preview">
                        <span class="add-icon">
                            <i class="icon-add"></i>
                        </span>
                        <span class="token-label">
                            {{item.name}}
                        </span>
                    </div>

                    <div *cdkDragPlaceholder class="token-placeholder">
                        <span class="token-label">
                            {{item.name}}
                        </span>
                    </div>


                </div>

            </div>

            <div class="hub-select-container">
                <mat-form-field>
                    <input type="text" matInput [formControl]="additionalProjectsTypeahead" [matAutocomplete]="additionalRelationsAutocomplete" placeholder="Enter a name of project here..." />
                </mat-form-field>

                <mat-autocomplete #additionalRelationsAutocomplete="matAutocomplete"
                                  (optionSelected)="additionalProjectSelected($event)">
                    <mat-option [disabled]="isRelationOptionDisabled(proj)"
                                *ngFor="let proj of additionalProjectsSuggestions$ | async" [value]="proj">
                        {{proj.name}}
                    </mat-option>
                </mat-autocomplete>
            </div>
            <div class="mt-1 text-muted font-size-sm">
                <i class="icon-loop3 mr-1 ml-1 text-muted round-rounded"></i>: indicates a reciprocal relationship between Project and Strategy
            </div>

            <div class="select-label text-info-700 mt-4">
                Related Program Management
                <app-help-selector [selector]="1199"></app-help-selector>
            </div>

            <ul *ngIf="programManagementRelations.length">
                <li *ngFor="let pm of programManagementRelations">{{pm.name}}</li>
            </ul>

            <div *ngIf="programManagementRelations.length === 0" class="no-relations-warning" style="color: #999;">
                A Program Management has not identified this record as part of their portfolio.
            </div>

            <div class="select-label text-info-700 mt-4">
                Related Whole Systems
                <app-help-selector [selector]="1198"></app-help-selector>
            </div>

            <ul *ngIf="wholeSystemRelations.length">
                <li *ngFor="let ws of wholeSystemRelations">{{ws.name}}</li>
            </ul>

            <div *ngIf="wholeSystemRelations.length === 0" class="no-relations-warning" style="color: #999;">
                A Whole System has not identified this record as part of their portfolio.
            </div>


        </div>


        <div class="col-md-6">
            <span class="select-label text-info-700 mt-4 d-block">Relations Proposed by Projects</span>

            <div class="mt-1 text-muted font-size-sm">
                The projects below have proposed a relationship to this strategy. To confirm the relation, click a project and drag it to the Core or Additional section.
            </div>

            <div class="tokenfield proposed-relations" style="height: calc(100% - 94px)"
                 cdkDropList
                 cdkDropListOrientation="horizontal"
                 id="proposedRelations"
                 #proposedRelationsList="cdkDropList"
                 [cdkDropListConnectedTo]="[coreRelationsList, additionalRelationsList]"
                 [cdkDropListData]="proposedRelations"
                 [cdkDropListEnterPredicate]="isBackwardRelatedPredicate.bind(this)"
                 (cdkDropListDropped)="relationDroppedOnProposedRelations($event)">

                <div class="token" *ngFor="let item of proposedRelations"
                     cdkDrag
                     [cdkDragData]="item">
                    <span class="token-label">
                        {{item.name}}
                    </span>

                    <div *cdkDragPreview class="token-preview">
                        <span class="add-icon">
                            <i class="icon-add"></i>
                        </span>
                        <span class="token-label">
                            {{item.name}}
                        </span>
                    </div>

                    <div *cdkDragPlaceholder class="token-placeholder">
                        <span class="token-label">
                            {{item.name}}
                        </span>
                    </div>

                </div>

                <div>
                    <div *ngIf="!proposedRelations.length" class="mt-2 d-block alert alert-info alert-styled-left shadow-0">
                        There are no proposed relations for this strategy.
                    </div>
                </div>

            </div>

        </div>

    </div>


    <div class="row">
        <div class="col-md-12">
            <span class="select-label text-info-700 d-block mt-4">Relations Notes</span>
            <textarea class="record-description"rows="3" cols="50" placeholder="Enter relations notes here..." formControlName="relationsNotes" maxlength="500" class="form-control mb-2"></textarea>
            <div class="hub-validation-error-message">
                {{getValidationErrorMessages('relationsNotes')}}
            </div>
        </div>
    </div>

</div>
