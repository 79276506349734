// Angular Stuff
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

// Hub Stuff
import { AppComponent } from './app-components/app/app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { HeaderComponent } from './app-components/app/layout/header/header.component';
import { FooterComponent } from './app-components/app/layout/footer/footer.component';
import { LegalDisclosureComponent } from './app-components/app/layout/footer/legal-disclosure/legal-disclosure.component';
import { HubToastComponent } from './app-components/hub-toast/hub-toast.component';
import { LogoutNotificationComponent } from './app-components/logout-notification/logout-notification.component';
import { CreateHubRecordDialogComponent } from './app-components/create-hub-record-dialog/create-hub-record-dialog.component';
import { ProjectNotFoundComponent } from './shared/components/project-not-found/project-not-found.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { AuthModule } from './auth/auth.module';
import { HomeModule } from './home/home.module';
import { SidebarNavComponent } from './app-components/app/layout/sidebar-nav/sidebar-nav.component';
import { ErrorContainerComponent } from './app-components/app/layout/error-container/error-container.component';
import { UserProfileModule } from './user-profile/user-profile.module';
import { PortfolioModule } from './portfolio/portfolio.module';
import { HubRecordViewModule } from './hub-record-view/hub-record-view.module';
import { HubRecordEditModule } from './hub-record-edit/hub-record-edit.module';

// Authentication / Authorization
import { MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfig } from './msal-config';
import { AuthInterceptor } from './core/services/auth/auth.interceptor';
import { AuthResponseInterceptorService } from './core/services/auth/auth-response-interceptor.service';
import { CreateHubRecordService } from './app-components/create-hub-record-dialog/services/create-hub-record.service';
import { HubAdministrationModule } from './hub-administration/hub-administration.module';
import { CollectionsModule } from './collections/collections.module';
import { DashboardsModule } from './dashboards/dashboards.module';
import { NotificationsDialogComponent } from './app-components/app/layout/notifications-dialog/notifications-dialog.component';
import { ManageNotificationsAndEmailsComponent } from './app-components/app/manage-notifications-and-emails/manage-notifications-and-emails.component';
import { CloseEditGuard } from './hub-record-edit/guards/close-edit.guard';
//CloseNotificationsPreferencesGuard
import { CloseNotificationsPreferencesGuard } from './app-components/app/manage-notifications-and-emails/guards/close-notifications-preferences.guard';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
      interactionType: InteractionType.Redirect
  };
}

const appRoutes: Routes = [
  { path: 'project-not-found', component: ProjectNotFoundComponent, pathMatch: 'full' },
  { path: 'project-deleted', component: ProjectNotFoundComponent, pathMatch: 'full' },
  { path: 'manage-notifications-and-emails', component: ManageNotificationsAndEmailsComponent, canDeactivate: [CloseNotificationsPreferencesGuard]},
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    MsalModule,
    SharedModule,
    AuthModule,
    HomeModule,
    PortfolioModule,
    UserProfileModule,
    HubRecordViewModule,
    HubRecordEditModule,
    HubAdministrationModule,
    DashboardsModule,
    CollectionsModule
  ],
  declarations: [
    AppComponent,
    ErrorContainerComponent,
    FooterComponent,
    HeaderComponent,
    HubToastComponent,
    LegalDisclosureComponent,
    LogoutNotificationComponent,
    CreateHubRecordDialogComponent,
    SidebarNavComponent,
    NotificationsDialogComponent,
    ManageNotificationsAndEmailsComponent
  ],
  providers: [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthResponseInterceptorService,
        multi: true,
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalBroadcastService,
    CreateHubRecordService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
