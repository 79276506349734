import { Injectable, inject } from '@angular/core';
import { HttpService } from '../../../../core/services/http.service';
import { Observable } from 'rxjs';
import { OutputDetailsModel, OutputLinkModel, OutputProgressModel, ProjectOutputModel } from '../../../../../hub_schema/hubTypes';
import { environment } from '../../../../../environments/environment';
import { setProtocol } from '../../../../shared/helpers/setProtocol';

@Injectable()
export class OutputsEditService {
    private httpService: HttpService = inject(HttpService);


    // #region Output Cruds

    public createOutput(output: ProjectOutputModel): Observable<ProjectOutputModel> {
        const url = environment.endpoints.base + '/outputs'
        return this.httpService.post<ProjectOutputModel>(url, output);
    }

    public updateOutput(output: ProjectOutputModel): Observable<ProjectOutputModel> {
        const url = environment.endpoints.base + '/outputs'
        return this.httpService.patch<ProjectOutputModel>(url, output);
    }

    public deleteOutput(projectOutputId: number): Observable<void> {
        const url = environment.endpoints.base + '/outputs/' + projectOutputId;
        return this.httpService.delete(url);
    }

    // #endregion Output Cruds

    // #region Progress Cruds

    public createOutputProgress(progress: OutputProgressModel): Observable<OutputProgressModel> {
        const url = environment.endpoints.base + '/outputs/outputProgress';
        return this.httpService.post<OutputProgressModel>(url, progress);
    }

    public updateOutputProgress(progress: OutputProgressModel): Observable<OutputProgressModel> {
        const url = environment.endpoints.base + '/outputs/outputProgress';
        return this.httpService.patch<OutputProgressModel>(url, progress);
    }

    public deleteOutputProgress(outputProgressId: number): Observable<void> {
         const url = environment.endpoints.base + '/outputs/outputProgress/' + outputProgressId;
         return this.httpService.delete(url);
    }

    // #endregion Progress Cruds

    // #region Link Cruds

    public createOutputLink(link: OutputLinkModel): Observable<OutputLinkModel> {
        link.url = setProtocol(link.url);
        const url = environment.endpoints.base + '/outputs/outputLinks';
        return this.httpService.post<OutputLinkModel>(url, link);
    }

    public updateOutputLink(link: OutputLinkModel): Observable<OutputLinkModel> {
        link.url = setProtocol(link.url);
        const url = environment.endpoints.base + '/outputs/outputLinks';
        return this.httpService.patch<OutputLinkModel>(url, link);
    }

    public deleteOutputLink(outputLinkId: number): Observable<void> {
        const url = environment.endpoints.base + '/outputs/outputLinks/' + outputLinkId;
        return this.httpService.delete(url);
    }


    // #endregion Link Cruds
}
