import { AfterViewChecked, Component, inject, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, take } from 'rxjs/operators';
import { ProjectModel } from '../../../hub_schema/hubTypes';
import { AuthService } from '../../core/services/auth/auth.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { ToastService } from '../../core/services/toast.service';
import { UsageService } from '../../core/services/usage.service';
import { UserDataService } from '../../core/services/user-data.service';
import { HubRecordTypes } from '../../shared/types/hub-record-types';
import { HubRecordService } from '../services/hub-record.service';
import { ErrorService } from '../../core/services/error.service';

@Component({
    selector: 'app-view-hub-record',
    templateUrl: './hub-record-container.component.html',
    styleUrls: ['./hub-record-container.component.scss']
})
export class HubRecordContainerComponent implements AfterViewChecked, OnInit {
    // dependencies
    private route: ActivatedRoute = inject(ActivatedRoute);
    public authService: AuthService = inject(AuthService);
    public hubRecordService: HubRecordService = inject(HubRecordService);
    public router: Router = inject(Router);
    public userDataService: UserDataService = inject(UserDataService);
    private usageService: UsageService = inject(UsageService);
    private titleService: Title = inject(Title);
    private toastService: ToastService = inject(ToastService);
    private errorService: ErrorService = inject(ErrorService);

    // Properties
    private isScrolledIntoView = false;
    private fragmentSectionId: string = '';
    public project: ProjectModel;
    public availableHeight: any;
    public isTeamShowing = true;
    public areSectionLinksShowing = true;
    public notFound: boolean = false;
    public isBusy = false;
    public isFavoritesBusy = false;

    public ngOnInit(): void {
        this.isBusy = true;

        this.route.fragment.subscribe((sectionId) => {
            this.fragmentSectionId = sectionId || '';
        });

        this.route.params.subscribe((params) => {
            const projectId = params['id'];

            this.hubRecordService.getProject(projectId).pipe(take(1)).subscribe({
                next: (project: ProjectModel) => {
                    this.project = project;

                    if (this.project.isDeleted) {
                        this.router.navigateByUrl('/project-deleted');
                    }
                    const title = `Hub - ${this.project.projectType!.name} View "${this.project.name}"`;
                    this.titleService.setTitle(title);
                    this.usageService.logHubUsage('View Record', this.project.projectType!.name, this.project.name, title, this.project.projectId);
                    this.isBusy = false;
                },
                error: (err) => {
                    this.isBusy = false;
                    this.notFound = true;
                }
            });

            this.loadViewPreferences();
        });

        this.loadViewPreferences();
    }

    public ngAfterViewChecked(): void {
        if (!this.isScrolledIntoView && this.fragmentSectionId) {
            const section = document.querySelector('#' + this.fragmentSectionId);
            if (section) {
                this.isScrolledIntoView = true;
                this.scrollIntoView(this.fragmentSectionId);
            }
        }
    }

    projectDataAreLoading(value: boolean): void {
        if (value) {
            this.isBusy = true;
        }
        else {
            this.isBusy = false;
        }
    }

    private loadViewPreferences(): void {
        if (LocalStorageService.getItem('isTeamShowing') !== null) {
            this.isTeamShowing = LocalStorageService.getItem('isTeamShowing') === 'true';
        }
        if (LocalStorageService.getItem('areSectionLinksShowing') !== null) {
            this.areSectionLinksShowing = LocalStorageService.getItem('areSectionLinksShowing') === 'true';
        }
    }

    toggleShowTeam() {
        this.isTeamShowing = !this.isTeamShowing;
        LocalStorageService.setItem('isTeamShowing', this.isTeamShowing);
    }

    toggleSectionLinks() {
        this.areSectionLinksShowing = !this.areSectionLinksShowing;
        LocalStorageService.setItem('areSectionLinksShowing', this.areSectionLinksShowing);
    }

    getLocation() {
        return window.location;
    }

    scrollIntoView(sectionId) {
        const container = document.querySelector('#MainContentSection')!;

        if (!sectionId) {
            container.scroll({ top: 0, left: 0, behavior: 'smooth' });
            return;
        }

        const section = document.querySelector('#' + sectionId);
        const sectionTop = (section as HTMLElement).offsetTop;

        container.scroll({ top: sectionTop, left: 0, behavior: 'smooth' });
    }

    public toggleFavorite(projectId: number, e: Event): void {
        this.isFavoritesBusy = true;
        const isFavorite = this.userDataService.isFavorite(projectId);
        this.userDataService.toggleFavorite(projectId);

        if (!isFavorite) {
            this.toastService.show('Saved to Favorites', 'Go to Favorites');

            this.toastService.gotoRequested.pipe(take(1)).subscribe(() => {
                this.toastService.clear();
                const stateObj = { state: {} };
                stateObj.state['tab'] = 'favorites';
                this.router.navigateByUrl('/user', stateObj);
            });
        }

        this.userDataService.favoritesChanged.pipe(debounceTime(500), take(1)).subscribe(() => {
            this.isFavoritesBusy = false;
        })

        e.preventDefault();
        e.stopPropagation();
    }

    public get isProject(): boolean {
        return this.project.projectType!.name == HubRecordTypes.Project;
    }

    public get isStrategy(): boolean {
        return this.project.projectType!.name == HubRecordTypes.Strategy;
    }

    public get isWholeSystem(): boolean {
        return this.project.projectType!.name == HubRecordTypes.WholeSystem;
    }

    public get isProgramManagement(): boolean {
        return this.project.projectType!.name == HubRecordTypes.ProgramManagement;
    }
}
