import { Component } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormControl } from '@angular/forms';
import { HubRecordEditorBase } from '../_hub_record_editor_base';
  
  @Component({
    selector: 'app-summary-edit',
    templateUrl: './summary-edit.component.html',
    styleUrls: ['./summary-edit.component.scss']
})
export class SummaryEditComponent extends HubRecordEditorBase {    
    public Editor = ClassicEditor;
    public ckTabConfig = ['bold', 'italic', 'Link', '|', 'BulletedList', 'NumberedList'];
    private _anchorRecord = false;

    public get isOfRecordTypeForAnchorRecord() {
        return this.isProject || this.isStrategy || this.isWholeSystem;
    }

    public get anchorRecord() {
        if (!this._anchorRecord) {
            this._anchorRecord = this.project && this.project.insightRecords && this.project.insightRecords.filter((record) => record.anchorRecordFlag).length > 0;
        }
        return this._anchorRecord;
    }

    public projectNameChanged(projectName): void {
        const summaryControl = this.projectEditForm!.controls.name as FormControl;
        summaryControl.setValue(projectName);
        summaryControl.markAsDirty();
        summaryControl.updateValueAndValidity();
    }

    public projectNameValidityChanged(isValid: boolean): void {
        this.isProjectNameValid = isValid;
    }
}
