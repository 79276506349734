import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberWithoutCommas',
})
export class NumberWithoutCommasPipe implements PipeTransform {

    transform(value: any): string {
        return isNaN(value) ? '0' : Number(value).toString();
    }

}
