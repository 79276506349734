import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ErrorService } from '../../core/services/error.service';
import { HttpService } from '../../core/services/http.service';
import { OutcomeMetricModel } from '../../../hub_schema/hubTypes';

@Injectable({
    providedIn: 'root',
})
export class PortfolioService {

    constructor(
        private httpService: HttpService,
        private errorService: ErrorService
    ) { }

    // #region 2030 Goals Metrics

    private cachedScaOrganizationalMetricsResult: OutcomeMetricModel[];

    public getScaOrganizationalMetrics(): Observable<OutcomeMetricModel[]> {
        const url = environment.endpoints.base + '/HubLOV/outcomeMetrics';

        if (this.cachedScaOrganizationalMetricsResult) {
            return of(this.cachedScaOrganizationalMetricsResult);
        }
        else {
            return this.httpService.get<OutcomeMetricModel[]>(url).pipe(
                tap((organizationalMetrics: OutcomeMetricModel[]) => {
                    this.cachedScaOrganizationalMetricsResult = organizationalMetrics;
                })
            );
        }
    }

    // #endregion 2030 Goals Metrics

}
