import { Injectable, inject } from '@angular/core';
import { HttpService } from '../../core/services/http.service';
import { Observable } from 'rxjs';
import { NonTncUserModel, UserModel, UserSearchModel, UsersSearchResultModel } from '../../../hub_schema/hubTypes';
import { environment } from '../../../environments/environment';

@Injectable()
export class HubAdministrationService {
    private httpService: HttpService = inject(HttpService);

    public searchUsers(userSearchModel: UserSearchModel): Observable<UsersSearchResultModel> {
        const url = environment.endpoints.base + '/user/search';
        return this.httpService.post<UsersSearchResultModel>(url, userSearchModel);
    }

    public updateUser(user: any): Observable<UserModel> {
        const url = environment.endpoints.base + '/user';
        return this.httpService.patch<UserModel>(url, user);
    }

    public saveNonTncUser(newUserEmail, supervisorUserId): Observable<NonTncUserModel> {
        const url = environment.endpoints.base + '/user/nonTncUsers';
        return this.httpService.post<NonTncUserModel>(url, {Email: newUserEmail, supervisorUserId: supervisorUserId});
    }
}
