<div class="media flex-column flex-sm-row mt-0 mb-3 collection-card">
    <div class="mr-sm-3 mb-2 mb-sm-0">
        <i class="icon-books icon-2x text-primary-300"></i>
        <h2 class="text-success d-block">{{recordCollection.numberOfProjects}}</h2>
    </div>
    <div class="media-body">
        <div class="d-md-flex justify-content-between">
            <h5 class="media-title">
                <a [routerLink]="'/collection/' + recordCollection.recordCollectionId"
                    class="font-weight-bold">{{recordCollection.name}}</a>
            </h5>
            <button type="button" class="btn btn-outline bg-primary-300 text-primary btn-icon round"
                *ngIf="canUserDelete()" tooltip="Delete Collection" placement="left"
                (click)="deleteRecordCollectionClicked()">
                <i class="icon-trash"></i>
            </button>
        </div>
        <ul class="list-inline list-inline-dotted text-muted mb-2 sb-b">
            <li class="list-inline-item font-weight-semibold">{{recordCollection.recordCollectionType.name}}</li>
            <li class="list-inline-item"><i class="icon-user mr-2"></i>{{recordCollection.owner.fullName}}</li>
        </ul>
        <div class="pr-2 hub-custom-scrollbar" style="max-height: 100px; overflow-y: auto;">
            <p>{{recordCollection.description}}</p>
        </div>
    </div>
</div>
