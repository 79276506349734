<div class="view-section" id="location" *ngIf="project">
    <h3 class="view-section-header">Geographic Location</h3>
    <div class="details">
        <div class="row">
            <div class="col-md-12"
                *ngIf="!isStrategy && !isWholeSystem && !isProgramManagement">
                <h5 class="text-success">Map</h5>
                <div id="MapViewContainer">
                    <app-map-container *ngIf="project.latitude && project.longitude" [project]="project"
                        [mapType]="detail"></app-map-container>
                </div>
                <div class="p-2" *ngIf="project.address">
                    <p><span class="font-weight-bold">Geolocation</span> {{ project.address }}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6" *ngIf="project.countries.length">
                <h6 class="text-success mt-2">Country</h6>
                <span *ngFor="let country of project.countries">
                    <span class="badge badge-info font-size-lg mr-1 mb-1">
                        {{ country.geography.name }}</span>
                </span>
                <span *ngIf="project.countries.length === 0" class="text-warning">No country specified.</span>
            </div>
            <div class="col-md-6" *ngIf="project.states.length">
                <h6 class="text-success mt-2">State/Province</h6>
                <span *ngFor="let state of project.states">
                    <span class="badge badge-info font-size-lg mr-1 mb-1">
                        {{ state.geography.name }}</span>
                </span>
                <span *ngIf="project.states.length === 0" class="text-warning">No state/province specified.</span>
            </div>
        </div>

        <div class="row" *ngIf="!isStrategy && !isWholeSystem && !isProgramManagement">
            <div class="col-md-12">
                <h6 class="text-success mt-2">Spatial Extent</h6>
                <span *ngIf="project.projectSpatialExtents.length">
                    <span *ngFor="let extent of project.projectSpatialExtents">
                        <span
                            class="badge badge-flat border-info text-info-600 font-size-lg mr-1 mb-1">{{extent.spatialExtent.spatialExtentGroup.name}}:
                            {{ extent.spatialExtent.name }}</span>
                    </span>
                </span>
                <span *ngIf="!project.projectSpatialExtents.length" class="text-warning">No spatial extent specified.</span>
            </div>
        </div>
    </div>
</div>
