<div class="dialog-container">
    <div class="dialog-icon">!</div>

    <div class="dialog-content">
        <div class="dialog-header">
            <div class="title">
                {{title}}
            </div>
        </div>

        <div class="dialog-body hub-custom-scrollbar">
            {{message}}
        </div>

        <div class="dialog-footer">
            <button (click)="close()" type="button">
                {{confirmButtonText}}
            </button>
        </div>
    </div>
</div>

