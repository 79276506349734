<div class="current mb-2 p-1 sb-b body" role="tabpanel" aria-hidden="true" [formGroup]="outcomeState.outcomeWizardForm" *ngIf="outcomeState.isReady">
    <div class="row">
        <div class="col-md-12">
            <div class="form-group" formGroupName="outcome">
                <div class="select-label text-info-800 d-block">
                    2030 Goal Metric
                    <app-help-selector [selector]="1192"></app-help-selector>
                </div>

                <div class="hub-select-container">
                    <mat-form-field>
                        <mat-select formControlName="outcomeMetricId"
                            placeholder="Select an Outcome Metric" disableOptionCentering>
                            <div class="organizational-metrics-info"></div>
                            <mat-option *ngFor="let om of outcomeMetrics" [value]="om.outcomeMetricId">
                                <span>{{om.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="hub-validation-error-message">
                    {{getValidationErrorMessages("outcomeMetricId")}}
                </div>

                <div class="select-label text-info-800 d-block">
                    Outcome Metric Unit<app-help-selector selector="1193"></app-help-selector>
                </div>

                <div class="hub-select-container">
                    <mat-form-field>
                        <mat-select formControlName="outcomeUnitId"
                            [placeholder]="unitPlaceholderText" disableOptionCentering>
                            <mat-option *ngFor="let unit of units" [value]="unit.outcomeUnitId">                                
                                {{unit.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="hub-validation-error-message">
                    {{getValidationErrorMessages("outcomeUnitId")}}
                </div>
            </div>
            
            <div>
                <app-ncs-panel></app-ncs-panel>
            </div>

            <!-- * Leverage Outcome & Policy -->
            <div>
                <div class="leverage-outcomes-panel">
                    <div class="leverage-outcome-heading">
                        <div class="select-label text-info-800">
                            Leverage Outcome<app-help-selector selector="1204"></app-help-selector>
                        </div>
                    </div>
                    <div class="leverage-outcome-list">
                        <div class="leverage-outcome">
                            <!--If we ever get more than one type of leverage outcome, we will turn this into an ngFor -->
                            <div class="leverage-outcome-name">Policy</div>
                            
                            <mat-checkbox color="primary" style="margin-left: 10px;"
                                formControlName="isPolicyLeverageOutcome">
                            </mat-checkbox>
                        </div>
                    </div>
                </div>

                <span class="select-label text-info-800">Outcome Statement<app-help-selector
                        selector="1069"></app-help-selector></span>
                <textarea formControlName="statement" rows="3" cols="3"
                    class="form-control record-description" appAutoFocus
                    placeholder="Enter your outcome statement here..."></textarea>
            </div>
        </div>
    </div>
</div>
