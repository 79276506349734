import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { TypeAheadService } from '../../core/services/type-ahead.service';
import { UserModel } from 'src/hub_schema/hubTypes';

@Component({
    selector: 'app-change-user',
    templateUrl: './change-user.component.html',
    styleUrls: ['./change-user.component.scss']
})
export class ChangeUserComponent implements OnInit {

    public isUserSelectionPanelVisible: boolean = false;
    public userTypeaheadInput: FormControl;
    public filteredUsers$: Observable<UserModel[]>;

    @ViewChild('typeaheadInputControl') inputElement: ElementRef;

    @Output()
    public userSelected: EventEmitter<UserModel> = new EventEmitter<UserModel>();

    constructor(private typeAheadService: TypeAheadService) { }

    ngOnInit() {
        this.userTypeaheadInput = new FormControl('');

        this.filteredUsers$ = this.userTypeaheadInput.valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                switchMap((term) => this.typeAheadService.getUserTypeAheadData(term)
                    .pipe(
                        catchError(() => {
                            return of([]);
                        })
                    )
                )
            );
    }

    public toggleUserSelector(): void {
        this.isUserSelectionPanelVisible = !this.isUserSelectionPanelVisible;

        if (this.isUserSelectionPanelVisible) {
            setTimeout(() => {
                this.inputElement.nativeElement.focus();
            });
        }
    }

    public displayUser(user: UserModel) {
        if (user && user.userId) {
            return user.fullName;
        }
        return undefined;
    }

    public selectUser(e: MatAutocompleteSelectedEvent) {
        this.isUserSelectionPanelVisible = false;
        this.userTypeaheadInput.setValue('');
        this.userSelected.emit(e.option.value);
    }
}
