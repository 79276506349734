import { Injectable, inject } from '@angular/core';
import { HttpService } from '../../../../core/services/http.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ProjectLinkModel } from '../../../../../hub_schema/hubTypes';
import { setProtocol } from '../../../../shared/helpers/setProtocol';

@Injectable()
export class AssociatedFilesEditService {
    private httpService: HttpService = inject(HttpService);

    public deleteAssociatedFileLink(projectLinkId: number): Observable<void> {
        const url = environment.endpoints.base + '/projectLink/' + projectLinkId;
        return this.httpService.delete(url);
    }

    public createProjectFileLink(projectLink: ProjectLinkModel): Observable<ProjectLinkModel> {
        projectLink.url = setProtocol(projectLink.url);
        const url = environment.endpoints.base + '/projectLink';
        return this.httpService.post<ProjectLinkModel>(url, projectLink);
    }

    public updateProjectFileLink(projectLink: ProjectLinkModel): Observable<ProjectLinkModel> {
        projectLink.url = setProtocol(projectLink.url);
        const url = environment.endpoints.base + '/projectLink';
        return this.httpService.patch<ProjectLinkModel>(url, projectLink);
    }
}
