<app-modal-spinner *ngIf="isBusy"></app-modal-spinner>

<div aria-hidden="false" tabindex="0">

    <div class="p-3">

        <h5 class="edit-section-header">
            Location<app-help-selector [selector]="1030"></app-help-selector>
        </h5>

        <div class="row" [formGroup]="projectEditForm" *ngIf="projectEditForm && businessUnits">
            <div class="col-md-6">
                <span class="select-label text-info-700 mt-4 d-block">Lead Business Unit<app-help-selector [selector]="1026"></app-help-selector></span>

                <div class="hub-select-container">
                    <mat-form-field>
                        <mat-select placeholder="Select a Lead Business Unit..." disableOptionCentering
                            [(ngModel)]="leadBusinessUnitId" [ngModelOptions]="{standalone: true}" >
                            <mat-option *ngFor="let bu of businessUnits" [value]="bu.businessUnitId"
                                [disabled]="isLeadBuDisabled(bu)">
                                {{bu.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <span class="select-label text-info-700 mt-4 d-block">Participating Business Units<app-help-selector
                    [selector]="1027"></app-help-selector></span>

                <div class="hub-select-container">
                    <div class="tokenfield form-control">
                        <div>
                            <div class="token" *ngFor="let pbu of participatingBusinessUnits; trackBy: buIdentifier">
                                <span class="token-label">{{pbu.businessUnit.name}}</span>
                                <span class="close" (click)="removeParticipatingBusinessUnit(pbu)" aria-hidden="true">×</span>
                            </div>
                        </div>
                    </div>

                    <mat-form-field>
                        <input matInput #participatingBusTypeaheadInputBox
                            [matAutocomplete]="participatingBusAutoComplete" placeholder="Add a Business Unit..."
                            [formControl]="participatingBuTypeahead" autocomplete="do-not-autofill" />
                    </mat-form-field>

                    <mat-autocomplete #participatingBusAutoComplete="matAutocomplete"
                        [displayWith]="getBuName"
                        (optionSelected)="addParticipatingBusinessUnit($event, participatingBusTypeaheadInputBox)">
                        <mat-option *ngFor="let bu of filteredBusinessUnits$ | async" [value]="bu"
                            [disabled]="isParticipatingBuDisabled(bu)">
                            {{bu?.name}}
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <div class="hub-validation-error-message">
                    {{getValidationErrorMessages("projectBusinessUnits")}}
                </div>


                <span class="select-label text-info-700 mt-4 d-block">Country<app-help-selector [selector]="1028">
                </app-help-selector></span>

                <div class="hub-select-container">
                    <div class="tokenfield form-control">
                        <div>
                            <div class="token" *ngFor="let projectGeography of projectEditForm.controls.countries.value">
                                <span class="token-label">{{projectGeography.geography.name}}</span>
                                <span class="close" (click)="deleteCountry(projectGeography)"
                                    aria-hidden="true">×</span>
                            </div>
                        </div>
                    </div>

                    <mat-form-field>
                        <input matInput #countriesTypeaheadInputBox [matAutocomplete]="countriesAutocomplete"
                            placeholder="Enter a name of Country here..." [formControl]="countriesTypeahead"
                            autocomplete="do-not-autofill" />
                    </mat-form-field>

                    <mat-autocomplete #countriesAutocomplete="matAutocomplete" [displayWith]="getCountryName"
                        (optionSelected)="addCountry($event, countriesTypeaheadInputBox)">
                        <mat-option *ngFor="let country of filteredCountries$ | async" [value]="country"
                            [disabled]="isCountryDisabled(country)">
                            {{country?.name}}
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <div class="hub-validation-error-message">
                    {{getValidationErrorMessages("countries")}}
                </div>

                <span class="select-label text-info-700 mt-4 d-block">State<app-help-selector [selector]="1029">
                </app-help-selector></span>

                <div class="hub-select-container">
                    <div class="tokenfield form-control">
                        <div>
                            <div class="token" *ngFor="let projectGeography of projectEditForm.controls.states.value">
                                <span class="token-label">{{projectGeography.geography.name}}</span>
                                <span class="close" (click)="deleteState(projectGeography)" aria-hidden="true">×</span>
                            </div>
                        </div>
                    </div>

                    <mat-form-field>
                        <input matInput #statesTypeaheadInputBox [matAutocomplete]="statesAutocomplete"
                            placeholder="Enter a name of State here..." [formControl]="statesTypeahead"
                            autocomplete="do-not-autofill" />
                    </mat-form-field>

                    <mat-autocomplete #statesAutocomplete="matAutocomplete" [displayWith]="getStateName"
                        (optionSelected)="addState($event, statesTypeaheadInputBox)">
                        <mat-option *ngFor="let state of filteredStates$ | async" [value]="state"
                            [disabled]="isStateDisabled(state)">
                            {{state?.name}}
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <div class="hub-validation-error-message">
                    {{getValidationErrorMessages("states")}}
                </div>

                <div class="col-md-12" *ngIf="isProject">

                    <div id="spatial-extent">
                        <span class="select-label text-info-700 mt-4 pb-2 d-block">Spatial Extent<app-help-selector
                                [selector]="1031"></app-help-selector></span>

                        <mat-radio-group [(ngModel)]="selectedSpatialExtentGroupId" [ngModelOptions]="{standalone: true}">
                            <mat-radio-button color="primary" class="custom-control" *ngFor="let extentGroup of spatialExtentGroups" [value]="extentGroup.spatialExtentGroupId">
                                {{extentGroup.name}}
                            </mat-radio-button>
                        </mat-radio-group>

                    </div>
                </div>

                <div class="col-md-12 p-0" *ngIf="isSpatialExtentPanelVisible()">
                    <span class="select-label text-info-700 mt-4 d-block">
                        {{getSelectedSpatialExtentGroupName()}}
                        <app-help-selector [selector]="getSpatialExtentGroupHelperTextId()"></app-help-selector>
                    </span>

                    <div class="hub-select-container">
                        <mat-form-field>
                            <mat-select multiple disableOptionCentering placeholder="Select a spatial extent"
                            [(ngModel)]="selectedSpatialExtentIds" [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let spatialExtent of getFilteredSpatialExtents()"
                                    [value]="spatialExtent.spatialExtentId">
                                    {{spatialExtent.name}}
                                </mat-option>
                            </mat-select>
                            <span class="clear-all-button" *ngIf="isClearAllSpatialExtentsVisible()"
                                (click)="clearAllSpatialExtents($event)" matSuffix>×</span>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="col-md-6" *ngIf="isProject">
                <span class="select-label text-info-700 mt-4 d-block">Map <app-help-selector [selector]="1180">
                    </app-help-selector></span>
                <div id="MapEditContainer">
                    <app-map-container mapType="edit" [project]="project"
                        (mapLocationSelected)="mapLocationSelected($event)"></app-map-container>
                </div>
            </div>

        </div>

    </div>
</div>
