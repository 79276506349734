<div class="hub-ir-edit-container">

    <div class="left-side hub-custom-scrollbar">

        <div class="d-md-flex justify-content-between p-2 pl-3">
            <h5 class="edit-section-header">Intermediate Results</h5>

            <button *ngIf="!isWholeSystem" class="btn-outline bg-primary-300 text-primary btn-icon btn rounded-round ml-2"
                (click)="openAddEditIntermediateResultDialog(null)" tabindex="0">
                <i class="icon-plus-circle2 mr-2"></i> Create Intermediate Result
            </button>
        </div>

        <ul class="nav nav-tabs nav-tabs-bottom mb-1">
            <li class="nav-item">
                <a class="nav-link legitRipple" data-toggle="tab" (click)="setTabTo(tabs.active, $event)"
                    [ngClass]="{active: activeTab === tabs.active}">Active Intermediate Results
                    ({{ activeIntermediateResults?.length || 0 }})
                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link legitRipple" data-toggle="tab" (click)="setTabTo(tabs.archived, $event)"
                    [ngClass]="{active: activeTab === tabs.archived}">
                    Archived Intermediate Results ({{ archivedIntermediateResults?.length || 0 }})
                </a>
            </li>
        </ul>

        <div class="d-flex flex-row justify-content-between sticky">
            <div class="d-flex flex-row left-space">
                <button type="button" *ngIf="checkedIntermediateResultCount === 1 && activeTab === tabs.active"
                    (click)="editCurrentIntermediateResult()"
                    class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRiple" tabindex="0">
                    <i class="icon-pencil"></i>
                    <span class="ml-2 font">Edit</span>
                </button>
                <button type="button" *ngIf="checkedIntermediateResultCount === 1"
                    (click)="deleteCurrentIntermediateResult()"
                    class=" btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                    tabindex="0">
                    <i class="icon-trash"></i>
                    <span class="ml-2 font">Delete</span>
                </button>
                <button type="button"
                    *ngIf="checkedIntermediateResultCount > 0 && isAllowedToArchive && activeTab === tabs.active"
                    class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"                    
                    tabindex="0" (click)="archiveIntermediateResultsClicked()">
                    <i class="icon-drawer2"></i>
                    <span class="ml-2 font">Archive</span>
                </button>
                <button type="button"
                    *ngIf="checkedIntermediateResultCount > 0 && isAllowedToArchive && activeTab === tabs.archived"
                    class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"                    
                    tabindex="0" (click)="activateIntermediateResultClicked()">
                    <i class="majesticons--textbox-plus-line"></i>
                    <span class="ml-2 font">Activate</span>
                </button>
            </div>

            <mat-checkbox class="pr-3 ml-1 font" color="primary"
                [(ngModel)]="isAllChecked"
                [disabled]="isCheckAllDisabled"
                labelPosition="before">
                Select All ({{checkedIntermediateResultCount}})
            </mat-checkbox>
        </div>


        <div class="tab-content">
            <div class="tab-pane" [ngClass]="{active: activeTab === tabs.active, show: activeTab === tabs.active}" id="active">
                <div class="p-2 pl-3">
                    <div *ngIf="!activeIntermediateResults.length"
                        class="d-block alert alert-info alert-styled-left shadow-0">
                        There are no active Intermediate Results defined yet.
                    </div>
                </div>

                <ul class="section-list" id="lstResults" cdkDropList cdkDragHandle
                    (cdkDropListDropped)="irReordered($event)">
                    <app-intermediate-result-list-item *ngFor="let result of activeIntermediateResults"
                        [intermediateResult]="result" [projectType]="project?.projectType"
                        [project]="project" [isSelected]="result === selectedIntermediateResult"
                        (click)="selectIntermediateResult(result, $event)"
                        cdkDrag class="intermediate-result-list-item"
                        (checkBoxForEditingSelected)="checkBoxForEditingSelected(result, $event)">
                    </app-intermediate-result-list-item>
                </ul>
            </div>

            <div class="tab-pane" [ngClass]="{active: activeTab === tabs.archived, show: activeTab === tabs.archived}" id="archived">
                <div class="p-2 pl-3">
                    <div *ngIf="!archivedIntermediateResults.length"
                        class="d-block alert alert-info alert-styled-left shadow-0">
                        There are no archived Intermediate Results defined yet.
                    </div>
                </div>

                <ul class="section-list" id="lstResults" cdkDropList cdkDragHandle
                    (cdkDropListDropped)="irReordered($event)">
                    <app-intermediate-result-list-item *ngFor="let result of archivedIntermediateResults"
                        [intermediateResult]="result" [projectType]="project?.projectType"
                        [project]="project" [isSelected]="result === selectedIntermediateResult"
                        [isChecked]="result.isChecked" 
                        (click)="selectIntermediateResult(result, $event)"
                        cdkDrag class="intermediate-result-list-item"
                        (checkBoxForEditingSelected)="checkBoxForEditingSelected(result, $event)">
                    </app-intermediate-result-list-item>
                </ul>

            </div>            
        </div>

    </div>

    <div class="right-side project-edit-items-details" *ngIf="selectedIntermediateResult">
        <app-intermediate-result-detail [intermediateResult]="selectedIntermediateResult" [isStrategyIr]="isStrategy"></app-intermediate-result-detail>
    </div>
</div>
