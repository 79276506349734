import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-attributes-container',
    templateUrl: './attributes-container.component.html',
})
export class AttributesContainerComponent {
    public attributes: { [key: string]: any } = {};
    public levers: string[] = [];
    public donorKeywords: string[] = [];
    @Input() public project;
    @Input() openModalFromOutside: Subject<void> = new Subject();
    @Input() set setAttributes(instrumentTaxonomies) {
        // this.attributes needs to be reset because when the page does not re-render it will duplicate the attributes displayed
        // The duplicate attributes occur when switching between Hub-Records and their relations
        this.attributes = {}
        if (!Array.isArray(instrumentTaxonomies)) {
            return;
        }
        instrumentTaxonomies.forEach(({ instrumentTaxonomy }) => {
            this.insertNewValue(instrumentTaxonomy);
        });
        this.levers = this.attributes['Project Levers'];
        delete this.attributes['Project Levers'];
    }
    public Array = Array;

    @Input() set setDonorKeywords(donorKeywords) {
        if (!Array.isArray(donorKeywords)) {
            return;
        }
        this.donorKeywords = [];
        donorKeywords.forEach(({ keyword }) => {
            this.donorKeywords.push(keyword);
        });
    }

    insertNewValue(instrumentTaxonomy, attributes = this.attributes) {
        let parent = {};
        if (instrumentTaxonomy.parentInstrumentTaxonomy.parentTaxonomy) {
            parent = attributes[instrumentTaxonomy.parentInstrumentTaxonomy.parentTaxonomy.name];
            if (!parent) {
                attributes[instrumentTaxonomy.parentInstrumentTaxonomy.parentTaxonomy.name] = {};
                parent = attributes[instrumentTaxonomy.parentInstrumentTaxonomy.parentTaxonomy.name];
            }
        }
        else {
            parent = attributes;
        }

        if (!parent[instrumentTaxonomy.parentInstrumentTaxonomy.name]) {
            parent[instrumentTaxonomy.parentInstrumentTaxonomy.name] = [instrumentTaxonomy.name];
        }
        else {
            parent[instrumentTaxonomy.parentInstrumentTaxonomy.name].push(instrumentTaxonomy.name);
        }
    }

    public sortInstruments(array) {
        const order = {
            'Economic and Policy Instruments': 5,
            'Credit and Insurance': 4,
            'Investments and Funds': 3,
            'Behavioral instruments': 2,
            'Other Public Or Private Instruments': 1
        };
        return array.sort((a, b) => {

            if (order[a.key] > order[b.key]) {
                return -1;
            } else if (order[a.key] < order[b.key]) {
                return 1;
            }
            return 0;
        });
    }
}
