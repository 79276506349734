<div class="details">
    <div class="row">
        <div class="col-md-6">
            <h5 class="hub-level-1-label">Strategies</h5>
            <div class="hub-pill-list" *ngIf="relatedStrategies.length">
                <span *ngFor="let relation of relatedStrategies; trackBy: trackByFn;">
                    <a [routerLink]="'/portfolio/' + relation.relatedProject?.projectId"
                        class="badge-prio-strategy badge font-size-lg mr-1 mb-1">
                        {{relation.relatedProject?.name}}
                    </a>
                </span>
            </div>

            <div class="no-relations-warning" *ngIf="!relatedStrategies.length">
                This record does not have any relations to Strategies.
            </div>

            <h5 class="hub-level-1-label">Projects</h5>

            <div class="hub-pill-list" *ngIf="relatedProjects.length">
                <span *ngFor="let relation of relatedProjects; trackBy: trackByFn;">
                    <a [routerLink]="'/portfolio/' + relation.relatedProject?.projectId"
                        class="badge-prio-strategy badge font-size-lg mr-1 mb-1">
                        {{relation.relatedProject?.name}}
                    </a>
                </span>
            </div>

            <div class="no-relations-warning" *ngIf="!relatedProjects.length">
                This record does not have any relations to Projects.
            </div>

            <h5 class="hub-level-1-label">Whole Systems</h5>

                <div class="hub-pill-list" *ngIf="relatedWholeSystems.length">
                    <span *ngFor="let relation of relatedWholeSystems; trackBy: trackByFn;">
                        <a [routerLink]="'/portfolio/' + relation.relatedProjectId"
                            class="badge-prio-strategy badge font-size-lg mr-1 mb-1">
                            {{relation.relatedProject?.name}}
                        </a>
                    </span>
                </div>

            <div class="no-relations-warning" *ngIf="!relatedWholeSystems.length">
                This record does not have any relations to Whole Systems.
            </div>

        </div>

        <div class="col-md-6">

            <h5 class="hub-level-1-label">Region(s)</h5>

                <ul class="hub-bulleted-list">
                    <li *ngFor="let regionName of regionNames">
                        {{ regionName }}
                    </li>
                </ul>

            <h5 class="hub-level-1-label">Division(s)</h5>

                <ul class="hub-bulleted-list">
                    <li *ngFor="let divisionName of divisionNames">
                        {{ divisionName }}
                    </li>
                </ul>

            <h5 class="text-slate-600 font-weight-bold mt-2">Business Unit(s)</h5>

            <div class="hub-pill-list">
                <span *ngFor="let bu of projectBusinessUnits">
                    <a *ngIf="bu.isLeadBusinessUnit">
                        <span
                            class="badge badge-info font-size-lg mr-1 mb-1">{{ bu.businessUnit.name }}</span>
                    </a>
                    <a *ngIf="!bu.isLeadBusinessUnit">
                        <span class="badge badge-info-secondary font-size-lg mr-1 mb-1">{{ bu.businessUnit.name }}</span>
                    </a>
                </span>
            </div>

        </div>

    </div>
</div>
