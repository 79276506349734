import { Filter } from './filter';

export class CheckboxFilter extends Filter {
    private _callbackFunction: () => void;

    private _isChecked: boolean;
    public isGroupHeader: boolean;
    public nameForDisplay: string;

    public get isChecked(): boolean {
        return this._isChecked;
    }

    public set isChecked(value: boolean) {
        this._isChecked = value;

        if (this._callbackFunction) {
            this._callbackFunction();
        }
    }

    // callback function getgs called whenever isChecked changes
    constructor(callbackFunction: () => void) {
        super();
        this._callbackFunction = callbackFunction;
    }

    public override isActive() {
        return this.isChecked;
    }
    public override makeActive(): void {
        this.isChecked = true;
    }
    public override makeInactive(): void {
        this.isChecked = false;
    }
}
