import { Injectable, inject } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { HubLovService } from '../../../../core/services/hub-lov.service';

@Injectable()
export class LocationLovService {
    private hubLovService: HubLovService = inject(HubLovService);

    public getLocationLovs(): Observable<any> {
        return forkJoin({
            businessUnits: this.hubLovService.getBusinessUnits(), 
            countries: this.hubLovService.getCountries(), 
            states: this.hubLovService.getStates(), 
            spatialExtents: this.hubLovService.getSpatialExtents(), 
            spatialExtentGroups: this.hubLovService.getSpatialExtentGroups()
        });
    }
}
