<!-- PAGE HEADER -->
<div class="hub-record-view-container" *ngIf="project && !notFound">
    <div class="record-view-header">

        <div class="record-header-content">
            <div class="page-title d-flex">
                <h4>
                    <span class="font-weight-semibold text-success-800">
                        View {{ project?.projectType?.name}}
                        Record
                    </span>
                    <span class="bg-teal-300 ml-2 p-1">ID: {{project?.projectId}}</span>
                </h4>
            </div>

            <div class="header-elements text-center text-md-left mb-3 mb-md-0">
                <div style="display: flex; justify-content: space-between;">
                    <a matTooltip="Email Link" matTooltipShowDelay="500" style="margin-left: 200px;"
                        href="mailto:?subject={{ project?.projectType?.name }} - {{ project?.name }}&body={{getLocation()}}"
                        class="button">
                        <i class="icon-envelop3"></i>
                    </a>

                    <a *ngIf="!isFavoritesBusy" href="#" class="button"
                        (click)="toggleFavorite(project.projectId, $event)" matTooltipShowDelay="500"
                        matTooltip="Add/Remove Favorite">

                        <span *ngIf="userDataService.isFavorite(project?.projectId)"
                            class="material-icons">favorite</span>
                        <span *ngIf="!userDataService.isFavorite(project?.projectId)"
                            class="material-icons">favorite_border</span>
                    </a>

                    <a *ngIf="isFavoritesBusy" class="button">
                        <mat-spinner *ngIf="userDataService.isFavorite(project?.projectId)" [diameter]="14">
                        </mat-spinner>
                        <mat-spinner *ngIf="!userDataService.isFavorite(project?.projectId)" [diameter]="14">
                        </mat-spinner>
                    </a>

                    <app-add-project-to-collection-button [projectId]="project.projectId" [projectName]="project.name"
                        [isButton]="true"></app-add-project-to-collection-button>

                    <a matTooltip="Edit Record" matTooltipShowDelay="500" routerLink="edit"
                        *ngIf="authService.isAllowedToEdit(project)" class="button">
                        <span class="material-icons">edit</span>
                    </a>
                </div>
            </div>

        </div>

    </div>

    <!-- SECTION LINKS -->
    <div class="section-links-container">

        <div class="show-hide-button">
            <a href="javascript: void(0)" (click)="toggleSectionLinks()">{{areSectionLinksShowing ? 'Hide' : 'Show'}}
                Section Links</a>
        </div>

        <div class="section-links-panel hub-custom-scrollbar" id="SectionLinks"
            [ngClass]="{'not-showing': !areSectionLinksShowing}">

            <div class="section-links-content">
                <h3>Section Links</h3>

                <div class="section-links">

                    <div class="section-link">
                        <a href="javascript:void(0)" (click)="scrollIntoView('')" fragment="relations"
                            class="list-group-item list-group-item-action  text-primary legitRipple">
                            <i class="icon-bookmark2 mr-2"></i>
                            Summary
                        </a>
                    </div>

                    <div class="section-link">
                        <a *ngIf="isStrategy" href="javascript:void(0)" (click)="scrollIntoView('strategicPillars')"
                            class="list-group-item list-group-item-action  text-primary legitRipple">
                            <i class="icon-bookmark2 mr-2"></i>
                            Strategic Pillars
                        </a>
                    </div>

                    <div class="section-link">
                        <a *ngIf="!isWholeSystem && !isProgramManagement" href="javascript:void(0)"
                            (click)="scrollIntoView('outcomes')"
                            class="list-group-item list-group-item-action  text-primary legitRipple">
                            <i class="icon-bookmark2 mr-2"></i>
                            Outcomes
                        </a>
                    </div>

                    <div>
                        <a href="javascript:void(0)" (click)="scrollIntoView('intermediateResults')"
                            class="list-group-item list-group-item-action  text-primary legitRipple"
                            *ngIf="!isWholeSystem && !isProgramManagement">
                            <i class="icon-bookmark2 mr-2"></i>
                            Intermediate Results
                        </a>
                    </div>

                    <div class="section-link">
                        <a href="javascript:void(0)" (click)="scrollIntoView('outputs')"
                            class="list-group-item list-group-item-action  text-primary legitRipple">
                            <i class="icon-bookmark2 mr-2"></i>
                            Outputs
                        </a>
                    </div>

                    <div class="section-link">
                        <a href="javascript:void(0)" (click)="scrollIntoView('financials')"
                            class="list-group-item list-group-item-action  text-primary legitRipple">
                            <i class="icon-bookmark2 mr-2"></i>
                            Financials
                        </a>
                    </div>

                    <div class="section-link">
                        <a href="javascript:void(0)" (click)="scrollIntoView('partners')"
                            class="list-group-item list-group-item-action  text-primary legitRipple">
                            <i class="icon-bookmark2 mr-2"></i>
                            Partnerships
                        </a>
                    </div>

                    <div class="section-link">
                        <a href="javascript:void(0)" (click)="scrollIntoView('location')"
                            class="list-group-item list-group-item-action  text-primary legitRipple">
                            <i class="icon-bookmark2 mr-2"></i>
                            Location
                        </a>
                    </div>
                    <!--HIDE IF NO ADDITIONAL INFORMATION EXISTS ON THE RECORD  -->
                    <div class="section-link"
                        *ngIf="!!project && (project.projectDonorKeywords.length || project.projectInstrumentTaxonomies.length)">
                        <a href="javascript:void(0)" (click)="scrollIntoView('attributes')"
                            class="list-group-item list-group-item-action  text-primary legitRipple"
                            *ngIf="!isProgramManagement">
                            <i class="icon-bookmark2 mr-2"></i>
                            Additional Information
                        </a>
                    </div>
                    <!--HIDE IF NO ASSOCIATED FILES / LINKS EXISTS ON THE RECORD  -->
                    <div class="section-link" *ngIf="project?.projectLinks.length > 0">
                        <a href="javascript:void(0)" (click)="scrollIntoView('associatedFiles')"
                            class="list-group-item list-group-item-action  text-primary legitRipple">
                            <i class="icon-bookmark2 mr-2"></i>
                            Associated Files
                        </a>
                    </div>

                    <div class="section-link">
                        <a href="javascript:void(0)" (click)="scrollIntoView('admin')"
                            class="list-group-item list-group-item-action  text-primary legitRipple">
                            <i class="icon-bookmark2 mr-2"></i>
                            Audit Log
                        </a>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <!-- TEAM MEMBERS -->
    <div class="team-container">

        <div class="show-hide-button">
            <a href="javascript: void(0)" (click)="toggleShowTeam()">{{isTeamShowing ? 'Hide' : 'Show'}} Team</a>
        </div>

        <div class="team-panel hub-custom-scrollbar" [ngClass]="{'not-showing': !isTeamShowing}" id="TeamMembers">
            <app-team-container [project]="project">
            </app-team-container>
        </div>
    </div>


    <!-- CONTENT -->
    <div class="record-content hub-custom-scrollbar" id="MainContentSection">

        <h2 class="d-block sb-b m-0 pb-1 ml-1 pl-2">
            <span class="font-weight-bold text-success-800">{{ project?.name }}</span>
            <span class="text-muted ml-1">({{ project?.status?.name }})</span>
        </h2>

        <div class="view-section" id="relations">
            <h3 class="view-section-header mr-auto">Summary</h3>

            <app-project-relations-container *ngIf="isProject" 
                [relations]="project?.relations"
                [backwardRelations]="project?.backwardRelations" 
                [projectType]="project?.projectType.name"
                [projectBusinessUnits]="project?.projectBusinessUnits" >
            </app-project-relations-container>

            <app-strategy-relations-container *ngIf="isStrategy" 
                [relations]="project?.relations"
                [backwardRelations]="project?.backwardRelations" 
                [projectType]="project?.projectType.name"
                [projectBusinessUnits]="project?.projectBusinessUnits">
            </app-strategy-relations-container>

            <app-program-management-relations-container *ngIf="isProgramManagement" 
                [relations]="project?.relations"
                [projectType]="project?.projectType.name" 
                [projectBusinessUnits]="project?.projectBusinessUnits">
            </app-program-management-relations-container>

            <app-whole-system-relations-container *ngIf="isWholeSystem" 
                [relations]="project?.relations"
                [backwardRelations]="project?.backwardRelations" 
                [projectType]="project?.projectType.name"
                [projectBusinessUnits]="project?.projectBusinessUnits">
            </app-whole-system-relations-container>

            <div *ngIf="project?.briefDescription" class="col-md-12">
                <h5 class="hub-level-1-label">Brief Description</h5>
                <p class="record-description pr-3" [innerHTML]="project?.briefDescription"></p>
            </div>

            <div class="col-md-12"
                *ngIf="!isWholeSystem && !isProgramManagement && project?.goal && project?.goal.length">
                <h5 class="hub-level-1-label">Goal</h5>
                <p class="record-description pr-3" [innerHTML]="project.goal"></p>
            </div>

            <app-strategic-pillar-container *ngIf="isStrategy"
                [strategicPillars]="project?.projectStrategicPillars"></app-strategic-pillar-container>

            <app-outcomes-container *ngIf="!isWholeSystem && !isProgramManagement"
                (projectDataAreLoading)="projectDataAreLoading($event)" [project]="project"></app-outcomes-container>

            <app-intermediate-results-container *ngIf="!isWholeSystem && !isProgramManagement" [project]="project"
                (projectDataAreLoading)="projectDataAreLoading($event)"></app-intermediate-results-container>

            <app-outputs-container [outputs]="project?.outputs"></app-outputs-container>

            <app-financials-container [financials]="project?.projectFinancials"
                [setInsightFinancials]="project?.insightFinancials" [insightRecords]="project?.insightRecords"
                [financialNotes]="project?.financialNotes">
            </app-financials-container>

            <app-partners-container [project]="project"></app-partners-container>

            <app-location-container [project]="project"></app-location-container>

            <app-attributes-container [project]="project" [setAttributes]="project?.projectInstrumentTaxonomies"
                [setDonorKeywords]="project?.projectDonorKeywords" *ngIf="!isProgramManagement">
            </app-attributes-container>

            <app-associated-files-container [links]="project?.projectLinks">
            </app-associated-files-container>

            <app-audit-log-container [projectId]="project?.projectId"></app-audit-log-container>
        </div>

    </div>
</div>
<app-not-found *ngIf="notFound"></app-not-found>
<app-modal-spinner *ngIf="isBusy"></app-modal-spinner>