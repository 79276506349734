<div class="outcome-list-item">
    <div class="ikon" cdkDragHandle>
        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 14V12H2V14H0ZM4 14V12H6V14H4ZM8 14V12H10V14H8ZM0 10V8H2V10H0ZM4 10V8H6V10H4ZM8 10V8H10V10H8ZM0
                6V4H2V6H0ZM4 6V4H6V6H4ZM8 6V4H10V6H8ZM0 2V0H2V2H0ZM4 2V0H6V2H4ZM8 2V0H10V2H8Z" fill="#0073A8" />
        </svg>
    </div>

    <div class="outcome-list-item-content" [ngClass]="{'selected-dark': isSelected}">
        <div class="left-column">
            <h6 class="organizational-metric-heading">
                {{projectOutcome.outcome.outcomeMetric.name}}
                <span class="text-muted">({{projectOutcome.outcome.outcomeUnit.name}})</span>
            </h6>

            <h6 *ngIf="projectOutcome.outcome?.parentOutcomeId">
                <i class="icon-tree7"
                    style="display: inline-block; margin-bottom: 7px; margin-right: 5px; font-size: 15pt;"></i>
                Linked to: {{projectOutcome.outcome.parentOutcomeProjectName}}
            </h6>

        </div>

        <div class="button-panel">
            <button *ngIf="projectOutcome.outcomeStatistics.hasChildren" type="button"
                tooltip="Unable to delete this Strategy Outcome because there are Project Outcomes that link to it."
                placement="top"
                class="disabled btn-outline bg-primary-300 text-primary btn-icon btn rounded-round float-right"
                tabindex="0">
                <i class="icon-trash"></i>
            </button>

            <button *ngIf="!projectOutcome.outcomeStatistics.hasChildren" type="button"
                class="btn-outline bg-primary-300 text-primary btn-icon btn rounded-round float-right"
                (click)="deleteOutcomeClicked()" tabindex="0">
                <i class="icon-trash"></i>
            </button>

            <button type="button" class="btn-outline bg-primary-300 text-primary btn-icon btn rounded-round float-right"
                data-popup="tooltip" title="" data-placement="right" (click)="editOutcomeClicked()" tabindex="0">
                <i class="icon-pencil"></i>
            </button>

        </div>

    </div>
</div>
