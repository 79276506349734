import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { ErrorService } from '../../../../core/services/error.service';
import { LocalStorageService } from '../../../../core/services/local-storage.service';
import { OpenCreateRecordDialogService } from '../../../../core/services/open-create-record-dialog.service';
import { UserDataService } from '../../../../core/services/user-data.service';
import { NotificationsDialogComponent } from '../notifications-dialog/notifications-dialog.component';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { interval } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    constructor(
        private router: Router,
        private _authService: AuthService,
        public errorService: ErrorService,
        private openCreateRecordDialogService: OpenCreateRecordDialogService,
        private userDataService: UserDataService,
        private dialogService: MatDialog,
        private notificationsService: NotificationsService
    ) { }
    
    public showNonProdNotification: boolean = false;
    private getNotificationsSubscription: Subscription;
    private hasNotifications = false;

    public get userBusinessUnitId() {
        return this.userDataService.getUserBusinessUnitId();
    }

    public isSidebarExpanded: boolean = false;

    public ngOnInit(): void {
        if (this.isLoggedIn()) {
            this.getNotifications();
        }

        this._authService.authorizationComplete.subscribe(() => {
            this.getNotifications();
        });

        this.getNotificationsSubscription = interval(150000).subscribe(() => {
            this.getNotifications();
        });
    }

    private getNotifications(): void {
        this.notificationsService
            .getNotifications()
            .pipe(take(1))
            .subscribe(() => {
                this.hasNotifications = this.notificationsService.hasUnreadNotifications();
            });
    }

    public showNotifications(): void {
        this.dialogService.open(NotificationsDialogComponent, {
            width: '800px',
            height: '60vh',
        });
        this.hasNotifications = false;
    }

    @Input()
    public showBanner: boolean;

    @Input()
    public isSidebarCollapsed: boolean;

    public isLoggedIn(): boolean {
        return this._authService.isLoggedIn();
    }

    public get firstName(): string {
        return this._authService.getClaimDataValue('FirstName');
    }

    public logout(): void {
        this._authService.logout();
    }

    public goHome(): void {
        if (this.isLoggedIn()) {
            this.router.navigate(['/']);
        }
    }

    public requestHubRecordCreateDialog(e: Event): void {
        if (!this._authService.isLoggedIn()) {
            this._authService.login();
            return;
        }

        this.openCreateRecordDialogService.requestHubRecordCreateDialog();
        e.preventDefault();
    }

    public shouldAdminMenuShow(): boolean {
        return this._authService.allowedToEdit();
    }

    public toggleSidebarExpanded(): void {
        this.isSidebarExpanded = !this.isSidebarExpanded;
        LocalStorageService.setItem('isSidebarNavOpen', this.isSidebarExpanded);
    }

    public ngOnDestroy(): void {
        if (this.getNotificationsSubscription) {
            this.getNotificationsSubscription.unsubscribe();
        }
    }
}
