import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, debounceTime, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ErrorService } from 'src/app/core/services/error.service';
import { HttpService } from 'src/app/core/services/http.service';
import { TypeAheadService } from 'src/app/core/services/type-ahead.service';
import { getValidationErrorMessages } from 'src/app/shared/helpers/getValidationErrorMessages';
import {UserModel } from 'src/hub_schema/hubTypes';
import { CustomUserValidator} from 'src/app/shared/validators/customUserValidator'
import { HubAdministrationService} from '../../../services/hub-administration.service'
@Component({
    selector: 'app-add-non-tnc-employee',
    templateUrl: './add-non-tnc-employee.component.html',
    styleUrls: ['./add-non-tnc-employee.component.scss']
})
export class AddNonTncEmployeeDialogComponent implements OnInit,OnDestroy {

    private hubAdministrationService: HubAdministrationService = inject(HubAdministrationService);
    private authService: AuthService = inject(AuthService);
    private errorService: ErrorService = inject(ErrorService);
    private dialogRef: MatDialogRef<AddNonTncEmployeeDialogComponent> = inject(MatDialogRef<AddNonTncEmployeeDialogComponent>)
    private fb: FormBuilder = inject(FormBuilder);
    private customUserValidator: CustomUserValidator = inject(CustomUserValidator);
    private httpService: HttpService = inject(HttpService);
    private typeAheadService: TypeAheadService = inject(TypeAheadService);
    public isSaving: boolean = false;
    public addNonTncUserForm: FormGroup;

    public tncUsersLov: UserModel[] = [];

    public filteredSupervisors: Observable<any[]>;
    public emailChangeSubscription: any;

    public nonTncUserInfo: any;
    public supervisorSelected: any;

    get email() {
        return this.addNonTncUserForm.get('email');
    }

    get supervisor() {
        return this.addNonTncUserForm.get('supervisor');
    }

    public getValidationErrorMessages = getValidationErrorMessages;

    // constructor(
    //     private administrationService: HubAdministrationService,
    //     private authService: AuthService,
    //     private errorService: ErrorService,
    //     private dialogRef: MatDialogRef<AddNonTncEmployeeDialogComponent>,
    //     private fb: FormBuilder,
    //     private customUserValidator: CustomUserValidator,
    //     private httpService: HttpService,
    //     private typeAheadService: TypeAheadService,
    // )

    public ngOnInit(): void {
        this.addNonTncUserForm = this.fb.group({
            email: [
                '',
                [Validators.required, Validators.email, this.isTncEmailAddressValidator.bind(this)],
                [this.customUserValidator.isNonTncEmployeeValidator()]
            ],
            supervisorTypeahead: [''],
            supervisor: [
                '',
                [Validators.required]
            ]
        });

        this.filteredSupervisors = this.addNonTncUserForm.controls.supervisorTypeahead.valueChanges
            .pipe(
                debounceTime(500),
                switchMap((term) => this.typeAheadService.getUserTypeAheadData(term)
                    .pipe(
                        catchError(() => {
                            return of([]);
                        }),
                    )
                ),
                take(25)
            );

        this.emailChangeSubscription = this.addNonTncUserForm.controls.email.valueChanges
            .pipe(
                debounceTime(200)
            ).subscribe((value) => {
                this.nonTncUserInfo = null;
                if (value && value.length && value.toLowerCase().endsWith('@tnc.org')) {
                    this.getNonTncUserInfo(value);
                }
            });
    }

    ngOnDestroy() {
        this.emailChangeSubscription.unsubscribe();
    }

    public saveUser(): void {
        this.isSaving = true;
        this.verifyPermissions();

        const userValue = this.addNonTncUserForm.getRawValue();
        const newUserEmail = userValue.email;
        const supervisorUserId = userValue.supervisor.userId;
        const currentUserId = this.authService.getClaimDataValue('UserId'); // Pascal case!

        this.hubAdministrationService.saveNonTncUser(newUserEmail, supervisorUserId).subscribe({
            next: () => {
                // Is there anything to do here? For now, nothing. (ecoffman - 2021-05-10)
            },
            error: (err) => {
                this.reportError(err);
            },
            complete: () => {
                this.dialogRef.close();
            }
    });
    }

    public close(): void {
        this.dialogRef.close();
    }

    public filterSupervisors(value: string): any {
        if (typeof value !== 'string') {
            return null;
        }

        return this.tncUsersLov.filter((user) => user.email.toLowerCase() === value.toLowerCase());
    }

    private getNonTncUserInfo(email) {
        if (email && email.toLowerCase().endsWith('@tnc.org')) {
            this.httpService.verifyNonTncEmployee(email)
                .pipe(take(1))
                .subscribe((user) => {
                    this.nonTncUserInfo = user.user;
                });
        }
    }

    private verifyPermissions(): boolean {
        return this.authService.isAllowedToAddNonTncUsersToHub();
    }

    public reportError(err): void {
        const errorInfo = {
            error: err,
            timestamp: new Date()
        };
        console.error(err);
        this.errorService.addError(errorInfo, true);
    }

    private isValidEmail(email: string): boolean {
        const emailRegex = /^[^@ ]+@[^@ ]+\.[^@ ]+$/;
        return emailRegex.test(email);
    }

    private isTncEmailAddressValidator(control: FormControl) {
        const error = { invalidValue: null };
        const emailSuffix = '@tnc.org';
        const value = control && control.value;

        if (this.isValidEmail(value) && !(value.toLowerCase().endsWith(emailSuffix) && value.toLowerCase().length >= emailSuffix.length + 3)) {
            error.invalidValue = `'${control.value} is not a valid TNC Email address.'`;
            return error;
        }
        return null;
    }

    public selectSupervisor(e): void {
        this.addNonTncUserForm.controls.supervisor.setValue(e.option.value);
        this.supervisorSelected = e.option.value;
        this.addNonTncUserForm.controls.supervisor.markAsDirty();
    }

    public displayUser(option?: any): string {
        if (option && option.userId) {
            return option.fullName;
        }

        return undefined;
    }

    public showDisplayNameIfDifferent(user) {
        const fullName = `${user.firstName} ${user.lastName}`;
        if (fullName === user.displayName) {
            return null;
        }
        return fullName;
    }
}
