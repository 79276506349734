import { Injectable, inject } from '@angular/core';
import { HubLovService } from '../../../../../../core/services/hub-lov.service';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PartnersLovService {
    private hubLovService: HubLovService = inject(HubLovService);

    public getPartnerLovs(): Observable<any> {
        return forkJoin({
            partners: this.hubLovService.getPartners(),
            partnerCategories: this.hubLovService.getPartnerCategories(),
            partnerRoles: this.hubLovService.getPartnerRoles()
        });
    }
}
