<div *ngIf="project && projectEditForm" aria-hidden="false" tabindex="0">

    <app-project-relations-edit *ngIf="isProject">
    </app-project-relations-edit>
  
    <app-strategy-relations-edit *ngIf="isStrategy">
    </app-strategy-relations-edit>
  
    <app-whole-system-relations-edit *ngIf="isWholeSystem">
    </app-whole-system-relations-edit>
  
    <app-program-management-relations-edit *ngIf="isProgramManagement">
    </app-program-management-relations-edit>
  </div>
  