import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http.service';

@Injectable()
export class AlgoliaKeyService {
    constructor(private httpService: HttpService) { }

    public indexVocabularyKeys = {
        collectionSearch: 'collectionSearch',
        fundraisingMenuSearch: 'fundraisingMenuSearch',
        portfolioSearch: 'portfolioSearch',
        projectAtTncSearch: 'projectAtTncSearch',
        projectNameSearch: 'projectNameSearch',
        userAdministration: 'userAdministration'
    }

    public getEphemeralCollectionSearchAlgoliaKey() {
        return this.httpService.getEphemeralAlgoliaKeyFor(this.indexVocabularyKeys.collectionSearch);
    }

    public getEphemeralFundraisingMenuSearchAlgoliaKey() {
        return this.httpService.getEphemeralAlgoliaKeyFor(this.indexVocabularyKeys.fundraisingMenuSearch);
    }

    public getEphemeralPortfolioSearchAlgoliaKey() {
        return this.httpService.getEphemeralAlgoliaKeyFor(this.indexVocabularyKeys.portfolioSearch);
    }

    public getEphemeralProjectAtTncAlgoliaKey() {
        return this.httpService.getEphemeralAlgoliaKeyFor(this.indexVocabularyKeys.projectAtTncSearch);
    }

    public getEphemeralProjectNameSearchAlgoliaKey() {
        return this.httpService.getEphemeralAlgoliaKeyFor(this.indexVocabularyKeys.projectNameSearch);
    }

    public getEphemeralUserAdminAlgoliaKey() {
        return this.httpService.getEphemeralAlgoliaKeyFor(this.indexVocabularyKeys.userAdministration);
    }
}
