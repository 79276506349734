<div class="hub-finance-edit-panel" *ngIf="financialsEditForm" [formGroup]="financialsEditForm">

    <div class="descriptions-column">
        <div class="finance-header">
            <label>All currency is in US Dollars</label>
        </div>
        <div class="finance-description">
            <label>Estimated TNC Costs<app-help-selector [selector]="1148"></app-help-selector></label>
        </div>
        <div class="finance-description">
            <label>Allocated budgeted expenses (Insight)</label>
        </div>
        <div class="finance-description">
            <label>Estimated Non-TNC Costs funded by TNC<app-help-selector [selector]="1149"></app-help-selector></label>
        </div>

    </div>

    <div class="year-columns" formArrayName="fiscalYears">
        <div class="finance-year-column" *ngFor="let yearForm of fiscalYearArray.controls; let i = index">
            <ng-container [formGroup]="yearForm">
                <div class="finance-header">
                    <label>FY{{getFiscalYear(yearForm)}}</label>
                </div>
                <div class="finance-value">
                    <input type="number" [formControl]="yearForm.controls.estimatedTncCosts.controls.financeValue">
                </div>
                <div class="finance-value">
                    <label>{{getAllocatedWorkingBudget(yearForm)}}</label>
                </div>
                <div class="finance-value">
                    <input type="number" [formControl]="yearForm.controls.estimatedNonTncCosts.controls.financeValue">
                </div>
            </ng-container>

        </div>

    </div>

    <div class="totals-column">
        <div class="finance-header">
            <label>Totals</label>
        </div>
        <div class="finance-value">
            <label>{{getTotalEstimatedTncCosts()}}</label>
        </div>
        <div class="finance-value">
            <label>{{getTotalAllocatedWorkingBudget()}}</label>
        </div>
        <div class="finance-value">
            <label>{{getTotalEstimatedNonTncCosts()}}</label>
        </div>
    </div>

</div>
