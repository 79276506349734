import { Component } from '@angular/core';

@Component({
  selector: 'app-outputs-edit-container',
  templateUrl: './outputs-edit-container.component.html',
  styleUrls: ['./outputs-edit-container.component.scss']
})
export class OutputsEditContainerComponent {

}
