import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-attribute',
    templateUrl: './attribute.component.html',
})
export class AttributeComponent {

    @Input() title: string;
    @Input() keywords: string[];
    @Input() isDonor = false;
    openModal = new Subject();
    private _sortedKeywords: string[];

    public get sortedKeywords(): string[] {
        if (!this._sortedKeywords) {
            this._sortedKeywords = this.alphaSort(this.keywords);
        }
        return this._sortedKeywords;
    }

    constructor() { }

    private alphaSort(keywords) {
        return keywords.sort((a, b) => {

            if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
            } else if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }
}
