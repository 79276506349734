<h5 class="edit-section-header d-inline-block">Financial Notes</h5>
<app-help-selector [selector]="1134"></app-help-selector>

<div class="row">
    <div class="col-md-12">
        <form [formGroup]="projectEditForm">
            <textarea formControlName="financialNotes" rows="3" cols="5" class="form-control record-description"
                placeholder="Enter your financial notes if applicable..."></textarea>

            <div class="hub-validation-error-message">
                {{getNoteErrorMessage()}}
            </div>
        </form>
    </div>
</div>
