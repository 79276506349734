<div class="hub-footer">
  <div class="left-column">
    <div>
      &copy; 2019 - {{thisYear}} <a routerLink="/" class="accesibility-focus">Shared Conservation Hub</a> by <a href="http://www.nature.org" class="accesibility-focus" target="_blank">The
        Nature Conservancy</a>
    </div>
    <div>
      <a href="https://www.nature.org/en-us/about-us/who-we-are/accountability/privacy-policy/" class="accesibility-focus" target="" _blank>Privacy
        Policy</a>
    </div>
    <div>
      <a href="https://www.nature.org/en-us/about-us/who-we-are/accountability/terms-of-use/" class="accesibility-focus" target="_blank">Terms of
        Use</a>
    </div>
    <div>
      <a class="text-link accesibility-focus" [routerLink]="[]" (click)="openLegalDisclaimerDialog();">Legal Disclaimer</a>
    </div>
  </div>
  <div class="right-column">
    <a target="_blank" [matTooltip]="tooltip" matTooltipPosition="above" matTooltipShowDelay="500"
      placement="top" class="accesibility-focus">
      <em class="icon-versions mr-2"></em> Release {{releaseInfo}}
    </a>
  </div>
</div>
