import { Injectable, inject } from '@angular/core';
import { HubLovService } from '../../../../../core/services/hub-lov.service';
import { Observable, of, tap } from 'rxjs';
import { BusinessRoleModel, UserModel } from '../../../../../../hub_schema/hubTypes';
import { environment } from '../../../../../../environments/environment';
import { HttpService } from '../../../../../core/services/http.service';

@Injectable()
export class TeamLovService {
    private hubLovService: HubLovService = inject(HubLovService);
    private httpService: HttpService = inject(HttpService);
    private financeContacts: UserModel[];

    public getBusinessRoles(): Observable<BusinessRoleModel[]> {
        return this.hubLovService.getBusinessRoles();
    }

    public getAllFinanceContacts(): Observable<UserModel[]> {
        if (this.financeContacts) {
            return of(this.financeContacts);
        }
        const url = environment.endpoints.base + '/user/financeContacts';

        return this.httpService.get<UserModel[]>(url).pipe(tap((financeContacts: UserModel[]) => {
            this.financeContacts = financeContacts;
        }));
    }
}
