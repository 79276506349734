export class SearchResultStatistics {
  public numberOfHits: number;
  public hitsPerPage: number;
  public numberOfPages: number;
  public page: number;
  isLastPage: boolean;
  processingTimeMs: number;

  public update(algoliaResults: any) {
    this.numberOfHits = algoliaResults.nbHits;
    this.hitsPerPage = algoliaResults.hitsPerPage;
    this.numberOfPages = algoliaResults.nbPages;
    this.page = algoliaResults.page;
    this.isLastPage = algoliaResults.exhaustiveNbHits;
    this.processingTimeMs = algoliaResults.processingTimeMS;
  }

  public getInfo(): string {
    if (this.processingTimeMs === undefined) {
      return '';
    }
    return `${this.formatNumber(this.numberOfHits)} results`;
  }

  private formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
}
