<div class="logout-warning-notification">
  <h2 mat-dialog-title>
    Automatic Logout
  </h2>
  <div mat-dialog-content>
    You will be logged out in 5 minutes. If you wish to remain logged in, click the button below.
    <br />
  </div>
  <div mat-dialog-actions>
    <button (click)="continue()">Keep me logged in</button>
  </div>
</div>
