import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { OutcomesTypeaheadService } from '../../../services/outcomes-typeahead.service';
import { CreateOutcomeStateService } from '../../services/create-outcome-state.service';
import { CustomOutcomeModel } from '../../../../../../../hub_schema/hubTypes';
import { OutcomeState } from '../../types/outcome-state';

@Component({
  selector: 'app-custom-outcome-standalone-panel',
  templateUrl: './custom-outcome-standalone-panel.component.html',
  styleUrls: ['./custom-outcome-standalone-panel.component.scss']
})
export class CustomOutcomeStandalonePanelComponent implements OnInit {
    private outcomesTypeaheadService: OutcomesTypeaheadService = inject(OutcomesTypeaheadService);
    private createOutcomeStateService: CreateOutcomeStateService = inject(CreateOutcomeStateService);
    public outcomeState: OutcomeState;

    public get customOutcomeForm(): FormGroup {
        return this.outcomeState.outcomeWizardForm!.controls.customOutcome as FormGroup;
    }

    public customOutcomeSuggestions$: Observable<any[]>;

    public ngOnInit(): void {
        this.outcomeState = this.createOutcomeStateService.outcomeState!;

        this.customOutcomeSuggestions$ = this.customOutcomeForm.controls.indicatorName.valueChanges.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap((prefix: string) => {
                return prefix.length < 2 
                    ? of([])
                    : this.outcomesTypeaheadService.getCustomOutcomeSuggestions(prefix)
            })
        );
    }

    public displayCustomOutcome(customOutcome): string {
        if (customOutcome?.name) {
            return customOutcome.name;
        }
        return customOutcome;
    }

    public getValidationErrorMessages(formControlName: string): string {
        const formGroup = this.outcomeState.outcomeWizardForm!.controls.customOutcome as FormGroup;

        const formControl = formGroup.get(formControlName);

        if (!formControl?.touched) {
            return '';
        }

        const formErrors = formControl.errors;

        if (formErrors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(formErrors)) {
                if (key === 'required') {
                    errorMessages.push(key);
                }
                else {
                    errorMessages.push(formErrors[key]);
                }
            }

            return errorMessages.join('<br />');
        }

        return '';
    }

    public selectCustomOutcome(e: MatAutocompleteSelectedEvent): void {
        const selectedCustomOutcome: CustomOutcomeModel = e.option.value;
        this.customOutcomeForm.controls.indicatorName.setValue(selectedCustomOutcome.indicatorName);
        this.customOutcomeForm.controls.unit.setValue(selectedCustomOutcome.unit);
    }
}
