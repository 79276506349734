import { Injectable } from '@angular/core';
import { getCurrentFiscalYear } from '../../../helpers/get-current-fiscal-year';

@Injectable({
  providedIn: 'root',
})
export class ProjectCardService {
  private _currentFiscalYear = null;

  constructor() {
    this._currentFiscalYear = getCurrentFiscalYear();
   }

  public get currentFiscalYear(): number {
    return this._currentFiscalYear ? this._currentFiscalYear : getCurrentFiscalYear();
  }
}
