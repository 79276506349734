import { isPlatformBrowser } from '@angular/common';
import { EventEmitter, Inject, Injectable, Output, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import jwt_decode from 'jwt-decode';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, map, take, } from 'rxjs/operators';
import { ErrorService } from '../error.service';
import { ApplicationRoleType } from '../../../shared/types/role';
import { environment } from '../../../../environments/environment';
import { ITokenClaimsInformation, ITokenResponseInformation } from '../../types/IToken';
import { AuthenticationResult, EventType, SsoSilentRequest } from '@azure/msal-browser';
import { HttpService } from '../http.service';
import { ProjectModel, TeamMemberModel } from '../../../../hub_schema/hubTypes';

@Injectable()
export class AuthService {
  // DEVELOPERS NOTE: This service is for authentication and providing claims info (from JWT token) only.
  // User data is available from the User Data Service at /src/app/core/services/user-data.service

  private jwtHelper: JwtHelperService;
  private jwtData: ITokenClaimsInformation;

  private authCookiePath = '/'; // Cookie needs to be valid throughout the domain.
  private authCookieSameSite: 'Lax' | 'None' | 'Strict' = 'Lax'; // Use Lax: It allows a most common "go to URL" operation to have cookies
  public jwtLifeSpanMinutes = 60; // 60 minutes is the initial lifespan until the access token is returned. When it is, update this value with the exp time within.

  private accessTokenName = 'accessToken';
  private refreshTokenName = 'refreshToken';

  public logoutNotificationTimer: ReturnType<typeof setTimeout>;
  public logoutTimer: ReturnType<typeof setTimeout>;

  public showAutoLogoutNotification: EventEmitter<void> = new EventEmitter<void>();
  public closeAutoLogoutNotification: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: object,
    private msalBroadcastService: MsalBroadcastService,
    private _msal: MsalService,
    private httpService: HttpService,
    private errorService: ErrorService,
    private cookieService: CookieService,
    private router: Router
  ) {
    this.jwtHelper = new JwtHelperService();

    this.getJwtData();
    this.setSessionLifeSpan();
    this.logoutNotificationTimer = this.getAutoLogoutTimer();
  }

  @Output()
  public authorizationComplete: EventEmitter<void> = new EventEmitter<void>();

  public notifyAuthorizationComplete(): void {
    this.authorizationComplete.emit();
  }

  /**
   * Method to set the authCookieLifeSpanMinutes to that of the JWT.
   * @param expiration This option paramter is the JWT expiration timestamp (seconds and not milliseconds).
   */
  private setSessionLifeSpan(expiration?: number) {
    let exp = expiration;

    // The jwt also contains 'expiresIn' that holds the time to live in seconds information. However, you'd
    // have to adjust for expired time somehow if you were to change to using it.
    if (!exp && this.jwtData && this.jwtData.exp) {
      exp = this.jwtData.exp;
    }

    // To override the expiration date for testing purposes, uncomment the following and set to the minutes you'd like.
    // It should not be anything less than 6 minutes since the dialog buffer is 5 minutes!
    // When not testing, comment out following line.
    /*exp = this.getOverrideExpirationDate();*/

    if (exp) {
      this.jwtLifeSpanMinutes = Math.round((exp - Math.round(Date.now() / 1000)) / 60) - environment.timers.showWarningBeforeTimeoutInMinutes;
    }
    else {
      // We must have an initial expiration date value or else the cookie will session as soon as it is set.
      this.jwtLifeSpanMinutes = this.jwtLifeSpanMinutes || 60 /*initial time to live if none is set*/;
    }
  }

  // This should be deleted as soon as testing is done!
  private getOverrideExpirationDate() {
    const dt = new Date();
    const totalMinutes = dt.getMinutes() + environment.timers.showWarningBeforeTimeoutInMinutes + 7; // Add additional minutes to keep things simple when accounting for rounding errors
    dt.setMinutes(totalMinutes);
    return Math.round(+dt / 1000);
  }

  public getAutoLogoutTimer() {
    if (!this.isLoggedIn()) {
      return;
    }
    this.clearTimers();

    const showDialogInMs = (this.jwtLifeSpanMinutes - environment.timers.showWarningBeforeTimeoutInMinutes) * 60 * 1000;

    // If there is no time left to press the 'remain online' button, go head and proceed with logout. This
    // should only be the case where a page was refreshed using the refresh button.
    if (showDialogInMs < 1000) {
      this.logout();
    }
    return setTimeout(() => {
      this.logoutTimer = setTimeout(() => {
        this.closeAutoLogoutNotification.emit();
        this.router.navigate(['/logged-out']);
        this.logout();
      }, environment.timers.showWarningBeforeTimeoutInMinutes * 60 * 1000);
      this.showAutoLogoutNotification.emit();
    }, showDialogInMs);
  }

  public clearTimers() {
    if (this.logoutNotificationTimer) {
      clearTimeout(this.logoutNotificationTimer);
    }
    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
    }
  }

  /**
   * Method to extend current session from Logout Notification Dialog.
   */
  public continueSession(): void {
    const refreshToken = this.cookieService.get(this.refreshTokenName);
    const endpoint = environment.endpoints.base + '/token/refreshToken';
    this.makeAuthRequest(endpoint, { refreshToken }).pipe(take(1)).subscribe();
  }

  private makeAuthRequest(url: string, data: any): Observable<boolean> {
    return this.httpService.post<any/*any should be TokenResponseInformation*/>(url, data)
      .pipe(
        map((tokenResponse: ITokenResponseInformation) => {
          return this.respondToTokenRequest(tokenResponse);
        }),
        catchError((error: Error) => {
          console.error(error);
          this.reportError(error);
          return of(false);
        })
      );
  }

  private respondToTokenRequest(tokenResponse: ITokenResponseInformation) {
    this.getJwtData(tokenResponse.accessToken);
    this.setSessionLifeSpan(this.jwtData.exp);
    this.logoutNotificationTimer = this.getAutoLogoutTimer();
    this.setAccessToken(tokenResponse.accessToken);
    this.setRefreshToken(tokenResponse.refreshToken);
    return true;
  }

  private setAccessToken(accessToken: string) {
    if (accessToken && isPlatformBrowser(this.platformId)) {
      this.cookieService.set(
        this.accessTokenName,
        accessToken,
        null, // Set cookie expiration date to null so that it expires when browser is closed
        this.authCookiePath,
        environment.auth.domain,
        environment.auth.requireHttpsProtocol,
        this.authCookieSameSite
      );
    }
  }

  private setRefreshToken(refreshToken: string) {
    if (refreshToken && isPlatformBrowser(this.platformId)) {
      this.cookieService.set(
        this.refreshTokenName,
        refreshToken,
        null, // Set cookie expiration date to null so that it expires when browser is closed
        this.authCookiePath,
        environment.auth.domain,
        environment.auth.requireHttpsProtocol,
        this.authCookieSameSite);
    }
  }

  private getJwtData(initialToken?: string): void {
    const token = initialToken || this.getAccessToken();
    try {
      this.jwtData = jwt_decode(token);
      this.validateBuEditorBusList();
      this.setSessionLifeSpan();
    } catch (e) {
      this.jwtData = null;
    }
  }

  private validateBuEditorBusList() {
    if (!this.jwtData.BuEditorFor) {
      this.jwtData.BuEditorFor = [];
    }

    if (typeof this.jwtData.BuEditorFor === 'string') {
      this.jwtData.BuEditorFor = [this.jwtData.BuEditorFor];
    }
  }

  public authorize(tokenRequestVm: { idToken: string; }): Observable<boolean> {
    const url = environment.endpoints.base + '/token';
    return this.makeAuthRequest(url, tokenRequestVm);
  }

  public refreshToken(): Observable<boolean> {
    const url = environment.endpoints.base + '/token/refreshToken';
    const tokenRefreshVm = {
      RefreshToken: this.getRefreshToken()
    };
    return this.makeAuthRequest(url, tokenRefreshVm);
  }

  public getAccessToken(): string {
    return this.cookieService.get(this.accessTokenName);
  }

  private getRefreshToken(): string {
    return this.cookieService.get(this.refreshTokenName);
  }

  public getClaimDataValue(fieldName: string) {

    if (this.jwtData) {
      const claim = this.jwtData[fieldName];
      if (claim && claim.length) {
        if (fieldName === 'BuEditorFor' && typeof claim === 'string') {
          return [claim];
        }
        return claim;
      }
      if (fieldName === 'BuEditorFor') {
        return [];
      }
      return '';
    }
    return '';
  }

  public getEditorPermissions(): string {
    const isAdmin = this.userIsAdmin() || this.userIsFinanceAdmin() || this.userIsITAdmin();
    let editorPermission = '';

    if (isAdmin) {
        return "Administrator";
    }

    if (this.userIsRegionEditor()){
        editorPermission = `RegionEditor:${parseInt(this.getClaimDataValue('RegionId'))}`;
    } else if (this.userIsDivisionEditor()) {
        editorPermission = `DivisionEditor:${parseInt(this.getClaimDataValue('DivisionId'))}`;
    } else if (this.userIsBusinessUnitEditor()) {
        editorPermission = "BusinessUnitEditor";
    }

    return editorPermission;
  }

  public allowedToEdit() {
    return this.userIsAdmin() ||this.userIsFinanceAdmin() || this.userIsITAdmin() || this.userIsRegionEditor() || this.userIsDivisionEditor() || this.userIsBusinessUnitEditor()
  }

  public getUserRoles() {
    return this.getClaimDataValue('http://schemas.microsoft.com/ws/2008/06/identity/claims/role');
  }

  /// Has side effect of clearing all local storage and session storage items for this website
  public deepClearAuthInfo(): void {

    // preserve 'has-seen-4-4-welcome';
    //M.B. 06/22/2023 I removed the logic calling the pop that notifies the hub has been updated.  Wanted to be sure that the
    //LocalStorage cache setters/getters for that welcome page can be safely removed
    //   const hasSeen44WelcomeItem = localStorage['has-seen-4-4-welcome'];

      this.clearAuthInfo();
      localStorage.clear();
      sessionStorage.clear();

    //M.B. 06/22/2023 I removed the logic calling the pop that notifies the hub has been updated.  Wanted to be sure that the
    //LocalStorage cache setters/getters for that welcome page can be safely removed
    //   if (hasSeen44WelcomeItem) {
    //       localStorage['has-seen-4-4-welcome'] = hasSeen44WelcomeItem;
    //   }
  }

  public clearAuthInfo(): void {
    this.cookieService.deleteAll(this.authCookiePath, environment.auth.domain);
    this.jwtData = undefined;
  }

  public isLoggedIn(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      try {
        const jwtToken = this.getAccessToken();
        if (!this.jwtHelper.isTokenExpired(jwtToken)) {
          if (!this.logoutNotificationTimer) {
            this.setSessionLifeSpan();
          }
          return true;
        }
      }
      catch (err) {
        return false;
      }
    }
    return false;
  }

  public login(returnRoute: string = ''): void {
    this.clearAuthInfo();

    const loginRequest = {
        scopes: [],
        state: returnRoute
    }

    this._msal.loginRedirect(loginRequest);
}

  public logout(): void {
    this.clearAuthInfo();
    this._msal.logout();
  }

  public userIsITAdmin(): boolean {
    const roles = this.getUserRoles();
    return roles.includes(ApplicationRoleType.ItAdmin);
  }

  public userIsBusinessAdmin(): boolean {
    const roles = this.getUserRoles();
    return roles.includes(ApplicationRoleType.BusinessAdministrator);
  }

  public userIsBusinessUnitEditor(): boolean {
    const roles = this.getUserRoles();
    return roles.includes(ApplicationRoleType.BusinessUnitEditor)
}

  public userIsRegionEditor(): boolean {
    const roles = this.getUserRoles();
    return roles.includes(ApplicationRoleType.RegionEditor);
  }

  public userIsDivisionEditor(): boolean {
    const roles = this.getUserRoles();
    return roles.includes(ApplicationRoleType.DivisionEditor);
  }

  public userIsFinanceAdmin(): boolean {
    const roles = this.getUserRoles();
    return roles.includes(ApplicationRoleType.FinanceAdministrator);
  }

  public userIsAdmin(): boolean {
    const roles = this.getUserRoles();
    return roles.includes(ApplicationRoleType.ItAdmin) || roles.includes(ApplicationRoleType.BusinessAdministrator);
  }

  public userIsNcsEditor(): boolean {
    const roles = this.getUserRoles();
    return roles.includes(ApplicationRoleType.ItAdmin) || roles.includes(ApplicationRoleType.NcsEditor);
  }

  public userIsDivisionEditorFor(project: ProjectModel): boolean {
    const businessUnits = project.projectBusinessUnits;
    const divisions = businessUnits.map((bu) => bu.businessUnit.division.divisionId);
    return divisions.indexOf(+this.jwtData.DivisionId) >= 0;
  }

  public userIsRegionEditorFor(project): boolean {
    const businessUnits = project.projectBusinessUnits;
    const regions = businessUnits.map((bu) => bu.businessUnit.region.regionId);
    return regions.indexOf(+this.jwtData.RegionId) >= 0;
  }

  public userIsBusinessUnitEditorFor(project): boolean {
    const businessUnits = project.projectBusinessUnits;
    const allowedToEditBus: number[] = this.jwtData.BuEditorFor.map((item) => +item);

    for (const unit of businessUnits) {
      // are they a BU editor for this BU?
      if (unit.businessUnit && allowedToEditBus.indexOf(unit.businessUnit.businessUnitId) >= 0) {
        return true;
      }
    }

    return false;
  }

  public userIsGeneral(): boolean {
    // this is misleading.... a 'general user' is anybody who can authenticate! (ecoffman)
    return this.getUserRoles().length < 1;
  }

  public isAllowedToEditAsBusinessUnitEditor(project: any): boolean {
    if (!project) {
      return false;
    }

    if (this.userIsAdmin()) {
      return true;
    }

    const allowedToEditBus: number[] = this.jwtData.BuEditorFor.map((item) => +item);

    const userRegionId = parseInt(this.jwtData.RegionId, 10);
    const userDivisionId = parseInt(this.jwtData.DivisionId, 10);

    const businessUnits = project.projectBusinessUnits;

    if (!businessUnits) {
      return false;
    }

    const userRoles = this.getUserRoles();
    const isBusinessAdmin = userRoles.includes(ApplicationRoleType.BusinessAdministrator);
    const isDivisionEditor = userRoles.includes(ApplicationRoleType.DivisionEditor);
    const isRegionEditor = userRoles.includes(ApplicationRoleType.RegionEditor);

    // Business Admin can edit any record
    if (isBusinessAdmin) {
      return true;
    }

    for (const unit of businessUnits) {
      // are they a BU editor for this BU?
      if (unit.businessUnit && allowedToEditBus.indexOf(unit.businessUnit.businessUnitId) >= 0) {
        return true;
      }

      // are they a Region editor for this BUs region?
      if (unit.businessUnit && unit.businessUnit.region &&
        unit.businessUnit.region.regionId === userRegionId && isRegionEditor) {
        return true;
      }

      // are they a Division editor for this BUs division?
      if (unit.businessUnit && unit.businessUnit.division &&
        unit.businessUnit.division.divisionId === userDivisionId && isDivisionEditor) {
        return true;
      }
    }

    return false;
  }

  public isAllowedToDeleteAsRegionOrDivisionEditor(project: any): boolean {
    if (!project) {
      return false;
    }

    if (this.userIsAdmin()) {
      return true;
    }

    const userRegionId = parseInt(this.jwtData.RegionId, 10);
    const userDivisionId = parseInt(this.jwtData.DivisionId, 10);

    const businessUnits = project.projectBusinessUnits;

    if (!businessUnits) {
      return false;
    }

    const userRoles = this.getUserRoles();
    const isBusinessAdmin = userRoles.includes(ApplicationRoleType.BusinessAdministrator);
    const isDivisionEditor = userRoles.includes(ApplicationRoleType.DivisionEditor);
    const isRegionEditor = userRoles.includes(ApplicationRoleType.RegionEditor);

    // Business Admin can edit any record
    if (isBusinessAdmin) {
      return true;
    }

    for (const unit of businessUnits) {

      // are they a Region editor for this BUs region?
      if (unit.businessUnit && unit.businessUnit.region &&
        unit.businessUnit.region.regionId === userRegionId && isRegionEditor) {
        return true;
      }

      // are they a Division editor for this BUs division?
      if (unit.businessUnit && unit.businessUnit.division &&
        unit.businessUnit.division.divisionId === userDivisionId && isDivisionEditor) {
        return true;
      }
    }

    return false;
  }


  public isAllowedToEditAsRecordLead(project: any): boolean {
    const userId = parseInt(this.jwtData.UserId, 10);
    if (this.isAllowedToEditAsBusinessUnitEditor(project)) {
      return true;
    }
    const hasLeadRole = (userBusinessRoles: Array<{ businessRole: { name: string } }>) => {
      return userBusinessRoles.some((role) => {
        return role.businessRole.name === 'Lead';
      });
    };

    return (project && project.team) ?
      project.team.some((t) => ((t.user.userId === userId) && hasLeadRole(t.userBusinessRoles))) : false;
  }

  public isTeamLead(project: any): boolean {
    const userId = Number(this.getClaimDataValue('UserId'));

    const teamMembers = project.team.filter((t: TeamMemberModel) => t.user.userId === userId);

    for (const teamMember of teamMembers) {
      if (teamMember.userBusinessRoles.find((ubr: { businessRole: { name: string; }; }) => ubr.businessRole.name === 'Lead')) {
        return true;
      }
    }
    return false;
  }

  public isAllowedToEdit(project: any): boolean {
    if (this.isAllowedToEditAsBusinessUnitEditor(project)) {
      return true;
    }
    const userId = parseInt(this.jwtData.UserId, 10);
    // is the user on this project's team?
    return (project && project.team) ? project.team.some((t) => t.user.userId === userId) : false;
  }

  public isAllowedToAddNonTncUsersToHub(): boolean {
    return this.userIsAdmin() || this.userIsBusinessAdmin();
  }

  private reportError(err): void {
    const errorInfo = {
      error: err,
      timestamp: new Date()
    };
    console.error(err);
    this.errorService.addError(errorInfo, true);
  }

    private passAuthorization(returnRoute: string): void {
        this.notifyAuthorizationComplete();
        this.logoutNotificationTimer = this.getAutoLogoutTimer();
        this.router.navigate([returnRoute]);
    }

    private failAuthorization(): void {
        this.deepClearAuthInfo();
        this.router.navigate(['/authorization-failed']);
    }

    private timeout: any;

    public setupAuthorizationCallback(): void {
        this._msal.handleRedirectObservable().subscribe({
            next: (result) => {
              // nothing to do here.  We just need to subscribe to this event in order to get the msal Broadcast service to broadcast.
            },
            error: (error) => console.error(error)
        });

      this.msalBroadcastService.msalSubject$.subscribe(msg => {
          if (this.timeout) {
              clearTimeout(this.timeout);
          }

          /**
           * These are the event types as  defined by the MS Auth API:
           *
           * Unhandled event types:
           *      LOGIN_START
           *      ACQUIRE_TOKEN_START
           *      LOGOUT_START
           *      LOGOUT_END
           *      ACQUIRE_TOKEN_NETWORK_START
           *      SSO_SILENT_START
           *      HANDLE_REDIRECT_START
           *      HANDLE_REDIRECT_END
           *      POPUP_OPENED

           *      SSO_SILENT_SUCCESS
           *      SSO_SILENT_FAILURE
           *

           * Handled event types:
           *      ACQUIRE_TOKEN_SUCCESS
           *      ACQUIRE_TOKEN_FAILURE
           *      LOGIN_SUCCESS
           *      LOGIN_FAILURE

           * Event types that can be ignored. These are managed in the Azure portal:
           *      LOGOUT_SUCCESS
           *      LOGOUT_FAILURE
          */

          if (msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
              const idToken = (msg.payload as any).idToken;

              if (!idToken) {
                  this.router.navigate(['/logged-out']);
                  return;
              }

              const url = (msg.payload as any).state || '/';

              const tokenRequestVm = {
                  idToken: idToken
              };

              // call getToken endpoint in our service code.
              this.authorize(tokenRequestVm).pipe(take(1)).subscribe((wasSuccessful: boolean) => {

                  if (wasSuccessful) {
                      this.passAuthorization(url);
                  }
                  else {
                      // this means they are successfully authenticated, but authorization failed.
                      // which could happen if there is a network interruption, if they tried to hack the azure token,
                      // or if the authenticated user is not in our user table
                      this.failAuthorization();
                  }
              },
                  err => {
                      console.error(err);
                  });

          }
          else if (msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
              this.failAuthorization();
          }
      });

        // failsafe.  If no msal broadcast message in 15 seconds, try again.
        this.timeout = setTimeout(() => {
            this.router.navigate(['/']);    // re-authorizes without clearing cookies and local storage.
        }, 15000);

  }
}
