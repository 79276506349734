<div class="associated-files-edit" *ngIf="associatedFileForm">
    <div class="hub-modal-header-text">
        <h5 class="modal-title text-success">{{existingProjectLink ? 'Edit' : 'Create'}} File Link
        </h5>
    </div>

    <div class="content">
        <div class="row">
            <div class="col-md-12">

                <form [formGroup]="associatedFileForm">
                    <span class="select-label text-info-700 pb-2 d-block">File Type</span>

                    <div class="hub-select-container">
                        <mat-form-field>
                            <mat-select placeholder="Select a file type" disableOptionCentering
                                formControlName="ancillaryTypeId">
                                <mat-option *ngFor="let fileType of ancillaryTypes"
                                    [value]="fileType.ancillaryTypeId">
                                    {{fileType.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="hub-validation-error-message"
                        [innerHtml]="getValidationErrorMessages('ancillaryTypeId')"></div>

                    <span class="select-label text-info-800 d-block mt-2">File Name / Description</span>
                    <input formControlName="title" type="text" class="form-control"
                        placeholder="Enter a name for your file link..." maxlength="100">

                    <div class="hub-validation-error-message" [innerHtml]="getValidationErrorMessages('title')">
                    </div>

                    <span class="select-label text-info-800 d-block mt-2">URL</span>
                    <input formControlName="url" type="text" class="form-control" placeholder="https://...">

                    <div class="hub-validation-error-message" [innerHtml]="getValidationErrorMessages('url')"></div>

                </form>
            </div>
        </div>
    </div>

    <div class="hub-modal-footer-text">
        <button (click)="cancel()" class="cancel-button mr-1">Cancel</button>

        <button (click)="save()"
            class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
            [disabled]="associatedFileForm.invalid || isSaving" showSaveIcon="true">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>            
            Save
        </button>
    </div>    
</div>
