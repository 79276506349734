import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-hub-message-box',
  templateUrl: './hub-message-box.component.html',
  styleUrls: ['./hub-message-box.component.scss']
})
export class HubMessageBoxComponent implements OnInit {
    public title: string;
    public message: string;
    public confirmButtonText: string = 'OK';

    constructor(
        private dialogRef: MatDialogRef<HubMessageBoxComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) { }

    ngOnInit(): void {
        this.title = this.data.title;
        this.message = this.data.message;

        if (this.data.confirmButtonText) {
            this.confirmButtonText = this.data.confirmButtonText;
        }
    }

    public close(): void {
        this.dialogRef.close();
    }


}
