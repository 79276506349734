import { Injectable, inject } from '@angular/core';
import { HttpService } from '../../../core/services/http.service';
import { ProjectModel } from '../../../../hub_schema/hubTypes';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class CreateHubRecordService {
    private httpService: HttpService = inject(HttpService);
  
    public createHubRecord(newHubRecord: ProjectModel): Observable<ProjectModel> {
        const url = environment.endpoints.base + '/projects';
        return this.httpService.post<ProjectModel>(url, newHubRecord);
    }
}
