<div class="ncs-calculator-display-container">

    <div class="center-container">
        <h1>NCS Calculations</h1>
    </div>

    <div class="center-container">
        <div>
            {{intervention?.calculation}}
        </div>
    </div>

    <div class="table-div">
        <table cellpadding="10">
            <tr>
                <td class="first-column table-header">Emissions Factors</td>
            </tr>
            <tr>
                <td class="first-column"><span>AGB</span> = Aboveground Biomass Carbon</td>
                <td><span>BGB</span> = Belowground Biomass Carbon</td>
            </tr>
            <tr>
                <td class="first-column"><span>SOC</span> = Soil Organic Carbon</td>
                <td><span>CH4</span> = Methane</td>
            </tr>
            <tr>
                <td class="first-column"><span>N20</span> = Nitrous Oxide</td>
            </tr>
        </table>
    </div>


    <div class="center-container">
        <button (click)="cancelDialog()">OK</button>
    </div>

</div>
