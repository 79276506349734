import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '../core/guards/role-guard';
import { SharedModule } from '../shared/shared.module';
import { AuditLogContainerComponent } from './hub-record-container/audit-log-container/audit-log-container.component';
import { AssociatedFilesContainerComponent } from './hub-record-container/associated-files-container/associated-files-container.component';
import { AttributeComponent } from './hub-record-container/attributes-container/attribute/attribute.component';
import { AttributesContainerComponent } from './hub-record-container/attributes-container/attributes-container.component';
import { FinancialsContainerComponent } from './hub-record-container/financials-container/financials-container.component';
import { HubRecordContainerComponent } from './hub-record-container/hub-record-container.component';
import { IntermediateResultComponent } from './hub-record-container/intermediate-results-container/intermediate-result/intermediate-result.component';
import { IntermediateResultsContainerComponent } from './hub-record-container/intermediate-results-container/intermediate-results-container.component';
import { LocationContainerComponent } from './hub-record-container/location-container/location-container.component';
import { CustomOutcomeComponent } from './hub-record-container/outcomes-container/custom-outcome/custom-outcome.component';
import { OutcomesContainerComponent } from './hub-record-container/outcomes-container/outcomes-container.component';
import { OutcomeComponent } from './hub-record-container/outcomes-container/outcome/outcome.component';
import { OutputComponent } from './hub-record-container/outputs-container/output/output.component';
import { OutputsContainerComponent } from './hub-record-container/outputs-container/outputs-container.component';
import { PartnersContainerComponent } from './hub-record-container/partners-container/partners-container.component';
import { ProgramManagementRelationsContainerComponent } from './hub-record-container/relations/program-management-relations-container/program-management-relations-container.component';
import { ProjectRelationsContainerComponent } from './hub-record-container/relations/project-relations-container/project-relations-container.component';
import { StrategyRelationsContainerComponent } from './hub-record-container/relations/strategy-relations-container/strategy-relations-container.component';
import { WholeSystemRelationsContainerComponent } from './hub-record-container/relations/whole-system-relations-container/whole-system-relations-container.component';
import { StrategicPillarContainerComponent } from './hub-record-container/strategic-pillar-container/strategic-pillar-container.component';
import { SummaryContainerComponent } from './hub-record-container/summary-container/summary-container.component';
import { TeamContainerComponent } from './hub-record-container/team-container/team-container.component';
import { TitlecaseAbbreviationFriendlyPipe } from './pipes/titlecase-abbreviation-friendly.pipe';

const routes: Routes = [
    { 
        path: 'portfolio/:id', 
        component: HubRecordContainerComponent, 
        pathMatch: 'full', 
        canActivate: [RoleGuard] 
    },
];

@NgModule({
    declarations: [
        ProjectRelationsContainerComponent,
        StrategyRelationsContainerComponent,
        ProgramManagementRelationsContainerComponent,
        WholeSystemRelationsContainerComponent,
        AttributesContainerComponent,
        SummaryContainerComponent,
        StrategicPillarContainerComponent,
        OutcomesContainerComponent,
        IntermediateResultsContainerComponent,
        OutputComponent,
        OutputsContainerComponent,
        FinancialsContainerComponent,
        PartnersContainerComponent,
        LocationContainerComponent,
        AssociatedFilesContainerComponent,
        AuditLogContainerComponent,
        TeamContainerComponent,
        AttributeComponent,
        OutcomeComponent,
        CustomOutcomeComponent,
        IntermediateResultComponent,
        HubRecordContainerComponent,
        TitlecaseAbbreviationFriendlyPipe
    ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes)
    ],
    providers: [
        TitlecaseAbbreviationFriendlyPipe
    ]

})
export class HubRecordViewModule { }
