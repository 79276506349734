<div class="outputs-edit">

    <div class="hub-modal-header-text">
        <h5 class="modal-title text-success">{{existingOutput ? 'Edit': 'Create'}} Output</h5>
    </div>
    <div class="content" [formGroup]="outputForm" *ngIf="outputForm">
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">

                    <span class="select-label text-info-700 pb-2 d-block">Output Name<app-help-selector [selector]="1117">
                        </app-help-selector></span>
                    <div class="input-group">
                        <textarea class="record-description" rows="1" cols="5" class="form-control" formControlName="statement"
                            placeholder="Set a name for this output"></textarea>
                    </div>
                    <div class="record-description" class="hub-validation-error-message">
                        {{getValidationErrorMessages(outputForm.get('statement'))}}
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <span class="select-label text-info-700 pb-2 mt-2 d-block">Output Owner<app-help-selector
                            [selector]="1118"></app-help-selector></span>

                    <div class="hub-select-container">
                        <mat-form-field>
                            <input type="text" matInput [formControl]="outputOwnerTypeahead" [matAutocomplete]="auto"
                                placeholder="Select Output Owner" />
                        </mat-form-field>

                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayUser"
                            (optionSelected)="userSelected($event)">
                            <mat-option *ngFor="let user of filteredUsers$ | async" [value]="user">
                                {{user.fullName}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>

                <div class="col-md-6 ">
                    <span class="select-label text-info-700 pb-2 mt-2 d-block">Due Date</span>

                    <mat-form-field>                    
                        <input matInput readonly (click)="dueDateCalendar.open()" [matDatepicker]="dueDateCalendar" formControlName="due" placeholder="Due Date">
                        <mat-datepicker-toggle matIconSuffix [for]="dueDateCalendar"></mat-datepicker-toggle>
                        <mat-datepicker #dueDateCalendar></mat-datepicker>
                    </mat-form-field>
                    
                </div>
            </div>
        </div>
    </div>

    <div class="hub-modal-footer-text">
        <button type="button" class="cancel-button mr-1" (click)="cancel()">Cancel</button>

        <button type="button" class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
            [disabled]="outputForm.invalid || isSaving" (click)="saveOutput()">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>
            Save
        </button>

    </div>

</div>
