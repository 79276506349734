import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '../../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { HubRecordEditorBase } from '../_hub_record_editor_base';
import { ProjectLinkModel } from '../../../../hub_schema/hubTypes';
import { take } from 'rxjs';
import { AssociatedFilesEditService } from './services/associated-files-edit.service';
import { AddEditAssociatedFileDialogComponent } from './add-edit-associated-file-dialog/add-edit-associated-file-dialog.component';

@Component({
  selector: 'app-associated-files-edit-container',
  templateUrl: './associated-files-edit-container.component.html',
  styleUrls: ['./associated-files-edit-container.component.scss']
})
export class AssociatedFilesEditContainerComponent extends HubRecordEditorBase {
    private dialogService: MatDialog = inject(MatDialog);
    private associatedFilesEditService: AssociatedFilesEditService = inject(AssociatedFilesEditService);


    public openAddEditFileDialog(file: ProjectLinkModel | null): void {
        const dialogRef = this.dialogService.open(AddEditAssociatedFileDialogComponent, {
            width: '800px',
            data: {
                projectLink: file,
                projectId: this.project.projectId
            }
        });

        dialogRef.afterClosed().subscribe((savedProjectLink: ProjectLinkModel) => {
            if (savedProjectLink) {
                if (file) {                    
                    const index = this.project.projectLinks!.findIndex(pl => pl.projectLinkId === savedProjectLink.projectLinkId);
                    this.project.projectLinks![index] = savedProjectLink;
                }
                else {
                    this.project.projectLinks?.push(savedProjectLink);
                }
            }
        });
    }

    public openDeleteAssociatedFileDialog(file: ProjectLinkModel) {
        const dialogRef = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: 'You will not be able to undo this delete action.'
            }
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed
            .pipe(take(1)).subscribe(() => {
                deleteConfirmationDialog.isBusy = true;

                this.associatedFilesEditService.deleteAssociatedFileLink(file.projectLinkId!).pipe(take(1)).subscribe(() => {
                    this.project.projectLinks = this.project.projectLinks!.filter((l) => l.projectLinkId !== file.projectLinkId); 
                    dialogRef.close();                   
                });
            });

    }
}
