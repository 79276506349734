<div class="dashboards-container">

    <!-- * Dashboards -->
    <div class="heading">
        <h4><span class="font-weight-semibold text-success-800" id="PageTitle">Hub Dashboards</span></h4>
    </div>
    <div class="dashboards-panel hub-custom-scrollbar">
        <h3 class="text-success mt-2">Management Dashboards</h3>
        <div class="row p-2">
            <div class="col-md-6 p-2">
                <div class="media flex-sm-row">
                    <div class="mr-sm-3 mb-2 mb-sm-0 mr-3">
                        <div class="card-img-actions">
                            <i class="icon-chart icon-3x text-success-300"></i>
                        </div>
                    </div>
                    <div class="media-body">
                        <h3 class="media-title"><a
                                href="https://app.powerbi.com/groups/me/apps/96f23e46-f7f2-4c37-bbee-b138d1422125/reports/f5f434ff-da75-4091-b473-d8ba5d425a28/ReportSection3839cdf44129c272d574?chromeless=true"
                                target="_blank">2030 Goals Project Tracking</a></h3>
                        <ul class="list-inline list-inline-dotted text-muted mb-2">
                            <li class="list-inline-item">Dashboard</li>
                        </ul>
                        <p class="narrative">
                            Tracks progress made by teams across TNC toward our 2030 Goals.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-2">
                <div class="media flex-sm-row">
                    <div class="mr-sm-3 mb-2 mb-sm-0 mr-3">
                        <div class="card-img-actions">
                            <i class="icon-chart icon-3x text-success-300"></i>
                        </div>
                    </div>
                    <div class="media-body">
                        <h3 class="media-title"><a
                                href="https://app.powerbi.com/groups/me/apps/96f23e46-f7f2-4c37-bbee-b138d1422125/reports/060aa64a-b1ef-4854-8451-dc37f6995e24/ReportSection12102f93824e0eb46e61?chromeless=true"
                                target="_blank">Conservation Management</a></h3>
                        <ul class="list-inline list-inline-dotted text-muted mb-2">
                            <li class="list-inline-item">Dashboard</li>
                        </ul>
                        <p class="narrative">
                            Tracks project progress toward their outcomes and intermediate results.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-2">
                <div class="media flex-sm-row">
                    <div class="mr-sm-3 mb-2 mb-sm-0 mr-3">
                        <div class="card-img-actions">
                            <i class="icon-chart icon-3x text-success-300"></i>
                        </div>
                    </div>
                    <div class="media-body">
                        <h3 class="media-title"><a
                                href="https://app.powerbi.com/Redirect?action=OpenReport&appId=96f23e46-f7f2-4c37-bbee-b138d1422125&reportObjectId=c90374ba-6b57-4f78-80c4-428107dc4bf7&ctid=79be6dc1-d78e-4bbb-b22b-d994c0a417a7"
                                target="_blank">North America Targets</a></h3>
                        <ul class="list-inline list-inline-dotted text-muted mb-2">
                            <li class="list-inline-item">Dashboard</li>
                        </ul>
                        <p class="narrative">
                            Tracks progress toward 2030 Goal targets set by North America divisions.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-2">
                <div class="media flex-sm-row">
                    <div class="mr-sm-3 mb-2 mb-sm-0 mr-3">
                        <div class="card-img-actions">
                            <i class="icon-chart icon-3x text-success-300"></i>
                        </div>
                    </div>
                    <div class="media-body">
                        <h3 class="media-title"><a
                                href="https://app.powerbi.com/groups/me/apps/96f23e46-f7f2-4c37-bbee-b138d1422125/reports/8a4438f5-793e-4d1f-bd63-9c9584c33313/ReportSectionafecd3dc018906c96112?chromeless=true"
                                target="_blank">Strategy Management</a></h3>
                        <ul class="list-inline list-inline-dotted text-muted mb-2">
                            <li class="list-inline-item">Dashboard</li>
                        </ul>
                        <p class="narrative">
                            Tracks strategy progress toward their outcomes and intermediate results.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <h3 class="text-success mt-2">Data Review Dashboards</h3>
        <div class="row p-2">
            <div class="col-md-6 p-2">
                <div class="media flex-sm-row">
                    <div class="mr-sm-3 mb-2 mb-sm-0 mr-3">
                        <div class="card-img-actions">
                            <i class="icon-chart icon-3x text-success-300"></i>
                        </div>
                    </div>
                    <div class="media-body">
                        <h3 class="media-title">
                            <a href="https://app.powerbi.com/groups/me/apps/96f23e46-f7f2-4c37-bbee-b138d1422125/reports/b313a5aa-30c5-4a60-8a98-d6b0f222f0ab/ReportSection849891332fe666364777?chromeless=true"
                                target="_blank">Hub Data Completeness</a></h3>
                        <ul class="list-inline list-inline-dotted text-muted mb-2">
                            <li class="list-inline-item">Dashboard</li>
                        </ul>
                        <p class="narrative">
                            Tracks completeness of data on Hub strategies and projects.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 p-2">
                <div class="media flex-sm-row">
                    <div class="mr-sm-3 mb-2 mb-sm-0 mr-3">
                        <div class="card-img-actions">
                            <i class="icon-chart icon-3x text-success-300"></i>
                        </div>
                    </div>
                    <div class="media-body">
                        <h3 class="media-title"><a
                                href="https://app.powerbi.com/groups/me/apps/96f23e46-f7f2-4c37-bbee-b138d1422125/reports/5ffb8801-648c-446a-9ed6-35ac33f53c46/ReportSection639733bb8d8fe5f2675f?chromeless=true"
                                target="_blank">Hub Data Review</a></h3>
                        <ul class="list-inline list-inline-dotted text-muted mb-2">
                            <li class="list-inline-item">Dashboard</li>
                        </ul>
                        <p class="narrative">
                            Displays basic information on Hub records.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- * Dashboard Guidance -->
    <div class="guidance-panel">
        <div class="icon-panel">
            <i class="icon-help icon-3x text-primary-300"></i>
        </div>
        <div class="guidance-content">
            <h3 class="media-title text-success">Hub Dashboard Guidance</h3>
            <ul class="list-inline list-inline-dotted text-muted mb-2">
                <li class="list-inline-item">Training and Guidance</li>
            </ul>
            <p class="narrative">Guidance on how to navigate these dashboards can be found on the Hub Connect page.</p>
            <a href="https://thenatureconservancy462.sharepoint.com/sites/Conservation/SitePages/The-Hub.aspx" target="_blank" class="hub-icon-button" style="width: 260px">
                <b><i class="icon-help"></i></b>
            HUB CONNECT PAGE</a>
        </div>

    </div>
</div>
