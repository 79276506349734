<div class="outcome" *ngIf="projectOutcome">
    <div class="row">
        <div class="col-md-12">
            <div class="number">
                <span class="btn hub-round-button rounded-round btn-icon btn-sm legitRipple mr-3"
                    [ngClass]="{'focused-metric': projectOutcome.outcome.outcomeMetric.isFocused,
                                'non-focused-metric': !projectOutcome.outcome.outcomeMetric.isFocused }">
                    <span class="letter-icon">{{outcomeIndex + indexOffset + 1}}</span>
                </span>
            </div>
            <div class="title">
                <h5 [ngClass]="{'focused-metric': projectOutcome.outcome.outcomeMetric.isFocused,
                                'non-focused-metric': !projectOutcome.outcome.outcomeMetric.isFocused }">
                    {{projectOutcome.outcome.outcomeMetric.name}}
                    <span class="text-muted font-size-sm">({{projectOutcome.outcome.outcomeUnit.name}})</span>
                </h5>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-7">

            <div *ngIf="projectOutcome.isPolicyLeverageOutcome" class="ml-5 mt-1">
                <span class="font-weight-bold" style="color: #018899;">Leverage Outcome: </span>
                <span class="text-muted">Policy</span>
            </div>

            <p class="record-description ml-5">{{projectOutcome.statement}}</p>

            <div *ngIf="projectOutcome.outcome.parentOutcome">
                <div class="text-indigo-300 ml-5"><i class="align-baseline mr-1 icon-tree7"></i>
                    <span class="font-weight-bold">Linked to: </span>{{projectOutcome.outcome.parentOutcomeProjectName}}
                </div>
            </div>
        </div>
        <div class="col-md-5 pr-4">
            <div *ngIf="projectOutcome.outcomeStatistics.hasTargets || projectOutcome.outcomeStatistics.hasProgress"
                class="d-flex flex-nowrap mt-2 pr-4"
                style="justify-content: flex-start;">

                <!-- <mat-checkbox [(ngModel)]="isChartShowing" color="primary">Show Chart</mat-checkbox> -->

                <mat-checkbox [(ngModel)]="areDetailsShowing" color="primary">Show Details</mat-checkbox>
            </div>

            <!-- PROGRESS BAR -->
            <div *ngIf="projectOutcome.outcomeStatistics.hasTargets">

                <div class="progress mt-1 mb-2" style="height: 0.5rem;">
                    <div class="progress-bar bg-success"
                        [style.width.%]="projectOutcome.outcomeStatistics.totalProgressPercent * 100">
                    </div>
                </div>

                <div>
                    <span class="font-weight-bold">
                        {{projectOutcome.outcomeStatistics.totalProgressPercent | percent}}</span> achieved towards target of
                    <span class="font-weight-bold">{{projectOutcome.outcomeStatistics.lastTargetValue | number: '0.0-0'}}
                        {{projectOutcome.outcome.outcomeUnit.name}}</span>

                    <span *ngIf="projectOutcome.selectedMetricOptionUnit !== projectOutcome.reportingMetricOptionUnitName"
                        style="color: darkgray"> ({{projectOutcome.outcomeStatistics.lastTargetValue | number: '0.0-0'}}
                        {{outcome.reportingUnitName}})</span>
                </div>

            </div>

            <div style="margin-top: 10px;" [innerHtml]="getCurrentPeriodProgressInfo(projectOutcome)">
            </div>

        </div>
    </div>

</div>

<!-- OUTCOME DETAILS TABLE -->

<div class="row pl-5 pr-3" #detailsPanel style="margin-top: 10px;" *ngIf="areDetailsShowing">
    <div class="col-md-12" *ngIf="!detailsLoading">

        <div class="hub-detail-header-panel">
            <h5 class="text-success">Outcome Start, Progress and Target Values</h5>
            <mat-checkbox *ngIf="isShowOutcomeCharacteristicsVisible" [(ngModel)]="areOutcomeCharacteristicsShowing"
                color="primary">Show Outcome Characteristics</mat-checkbox>
        </div>

        <div class="table-responsive mb-2 mr-3">
            <table class="table table-bordered table-border-dashed metric-table">
                <thead>
                    <tr class="table-border-double">
                        <th width="15%" class="text-right">Value Type</th>
                        <th width="20%" class="text-right">Date</th>
                        <th class="text-right">Value</th>
                    </tr>
                </thead>

                <tbody *ngFor="let target of projectOutcome.targets">
                    <tr>
                        <td class="text-right">
                            <span class="date-label text-success">Target</span>
                        </td>
                        <td class="text-right">
                            <span class="d-block">
                                {{target.targetDate | date}}
                            </span>
                        </td>
                        <td class="text-right">

                            <div class="value-line">
                                <span class="value">
                                    {{target.targetValue | number: '1.0-0'}}
                                </span>
                                <span class="unit">
                                    {{projectOutcome.outcome.outcomeUnit.name}}
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tbody *ngFor="let outcomeProgress of cummulativeProgress">
                    <tr>
                        <td *ngIf="outcomeProgress.isStart" class="text-right">
                            <span class="date-label text-primary">Start</span>
                        </td>

                        <td *ngIf="!outcomeProgress.isStart" class="text-right">
                            <span class="date-label text-dark">Progress</span>
                        </td>

                        <td class="text-right">
                            <span class="d-block">
                                {{outcomeProgress.progressDate | date}}
                            </span>
                        </td>

                        <td class="text-right">

                            <ng-container *ngIf="!isStrategy || doUnitsMatch(projectOutcome, outcomeProgress); else differingUnits">
                                <div class="value-line">
                                    <span class="value">
                                        {{outcomeProgress.progressValue | number: '1.0-0'}}
                                    </span>
                                    <span class="unit">
                                        {{projectOutcome.outcome.outcomeUnit.name}}
                                    </span>
                                </div>    
                            </ng-container>

                            <ng-template #differingUnits>
                                <div class="value-line">
                                    <span class="value">
                                        {{convertToParentUnit(outcomeProgress) | number: '1.0-0'}}
                                    </span>
                                    <span class="unit">
                                        {{projectOutcome.outcome.outcomeUnit.name}}
                                    </span>
                                </div>
    
                                <div class="value-line entry-unit">
                                    <span class="value">
                                        (
                                            {{outcomeProgress.progressValue | number: '1.0-0'}}
                                    </span>
                                    <span class="unit">
                                            {{outcomeProgress.outcomeUnit.name}}
                                        )
                                    </span>
                                </div>
                            </ng-template>

                            <div *ngIf="isStrategy" class="mt-1">
                                <a class="badge-primary badge font-size-lg"
                                    [routerLink]="'/portfolio/' + outcomeProgress.childProjectId">{{outcomeProgress.childProjectName}}</a>
                            </div>


                            <div *ngIf="!isStrategy">
                                <div class="attribution">
                                    <span>{{outcomeProgress.attributionLevel?.name}}</span>
                                </div>
                                <div class="attribution-text">
                                    {{outcomeProgress.attributionDescription}}
                                </div>

                            </div>

                            <ng-container *ngIf="areOutcomeCharacteristicsShowing">
                                <div *ngFor="let outcomeProgressAttribute of outcomeProgress.outcomeProgressAttributes">
                                    <span
                                        class="attribution">{{getAttributeLabel(outcomeProgressAttribute.outcomeListId)}}:
                                    </span>
                                    <span
                                        class="attribution-text">{{getAttributeValue(outcomeProgressAttribute)}}</span>
                                </div>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <app-modal-spinner *ngIf="detailsLoading"></app-modal-spinner>
</div>
