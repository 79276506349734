import { Component } from '@angular/core';
import { ErrorService } from '../../../../core/services/error.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-error-container',
  templateUrl: './error-container.component.html',
  styleUrls: ['./error-container.component.scss']
})
export class ErrorContainerComponent {
  constructor(public errorService: ErrorService) { }

  closeError(err) {
    this.errorService.closeError(err);
  }

  closeErrorPanel() {
    this.errorService.closeErrorPanel();
  }

  clearErrors() {
    this.errorService.clearErrors();
  }
}
