<div class="ncs-edit-container">
    <div class="ncs-header">

        <div class="ncs-pathway-panel">

            <div class="select-label text-info-800 d-block">
                NCS Pathway
            </div>

            <div class="hub-select-container">
                <mat-form-field>
                    <mat-select [(ngModel)]="selectedPathway"
                        placeholder="Select an NCS Pathway"
                        disableOptionCentering>

                        <mat-option
                            *ngFor="let pathway of ncsPathways"
                            [value]="pathway">
                            <span>{{pathway.name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="ncs-intervention-panel">

            <div class="select-label text-info-800 d-block">
                Intervention
        </div>

        <div class="hub-select-container">
            <mat-form-field>
                <mat-select [(ngModel)]="selectedIntervention"
                    placeholder="Select an Intervention Type"
                    disableOptionCentering>

                    <div class="organizational-metrics-info"></div>

                    <mat-option
                        *ngFor="let intervention of selectedPathway?.interventions"
                        [value]="intervention">
                        <span>{{intervention.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        </div>


    </div>

    <div class="ncs-body hub-custom-scrollbar">

        <div *ngIf="!selectedIntervention; else ncsForm" class="d-block alert alert-info alert-styled-left shadow-0">
            Please select an NCS Pathway and Intervention Type.
        </div>

        <ng-template #ncsForm>
            <div [formGroup]="interventionForm" *ngIf="interventionForm" >
                <table border="1">
                    <thead>
                        <td style="width: 80px">ISO</td>
                        <td style="width: 280px">Country Name</td>
                        <td style="width: 180px">{{selectedIntervention.baseline.name}} %</td>
                        <td style="width: 160px" *ngFor="let pool of selectedIntervention.pools">
                            {{pool.name}} ({{pool.unit}})
                        </td>
                        <td style="width: 200px;">Last Modified</td>
                    </thead>
                    <tbody>
                        <tr *ngFor="let countryForm of interventionForm.controls.countries.controls">
                            <td class="country">
                                {{countryForm.controls.countryCode.value}}
                            </td>
                            <td class="country">
                                {{countryForm.controls.countryName.value}}
                            </td>
                            <td [ngClass]="{'invalid': countryForm.controls.baselineValue.invalid}">
                                <input [formControl]="countryForm.controls.baselineValue">

                            </td>
                            <td *ngFor="let pool of countryForm.controls.pools.controls"
                                [ngClass]="{'invalid': pool.controls.poolValue.invalid}">
                                <input [formControl]="pool.controls.poolValue">
                            </td>
                            <td>
                                {{countryForm.controls.lastModifiedByInfo.value}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-template>

    </div>

    <div class="ncs-footer">
        <button class="hub-outline-button" (click)="reset()" >Cancel</button>

        <button type="button" class="hub-icon-button SaveButton" [ngClass]="{ saving: isSaving }"
            [disabled]="isSaveButtonDisabled || isSaving" (click)="saveAll()">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>
            Save
        </button>
    </div>

</div>
