import { Component } from '@angular/core';

@Component({
  selector: 'app-metric-progress-by-region-chart',
  templateUrl: './metric-progress-by-region-chart.component.html',
  styleUrls: ['./metric-progress-by-region-chart.component.scss']
})
export class MetricProgressByRegionChartComponent {

  constructor() { }
}
