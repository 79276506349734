<div class="view-section" id="financials">
    <h3 class="view-section-header">TNC Financials</h3>
    <div class="details" *ngIf="financialsAreNotEmpty() || insightRecords?.length">
        <h5 class="text-success">Summary</h5>
        <p class="record-description pl-3">
            For the current fiscal year <span class="font-weight-bold">FY{{getCurrentFiscalYear()}}</span>, the revenue
            raised
            is <span class="font-weight-bold text-success-800 sb-b">{{getRevenue(getCurrentFiscalYear()) | number : '1.0-0'}} USD</span>.
            There
            is a funding gap of <span
                class="font-weight-bold text-danger sb-b">{{getFundingGap(getCurrentFiscalYear())}}
                USD</span> to reach the required budgeted expenses of <span
                class="font-weight-bold text-indigo sb-b">{{getAllocatedBudget(getCurrentFiscalYear())}} USD</span>.
        </p>
        <h5 class="text-success mb-2">TNC Financials <span class="text-muted">(USD)</span></h5>
        <div class="table-responsive pl-2">

            <table class="table table-columned fin-table mb-2">
                <thead>
                    <tr>
                        <th width="12%"></th>
                        <th width="28%" colspan="2">TNC Funded Estimated Costs</th>
                        <th width="50%" colspan="5" class="insight">Insight Financials</th>
                        <th></th>
                    </tr>
                    <tr class="table-border-double">
                        <th width="12%">Fiscal Year</th>
                        <th width="14%">TNC-Costs</th>
                        <th width="14%">Non-TNC-Costs</th>
                        <th width="14%" class="insight">Budgeted Expenses</th>
                        <th width="14%" class="insight">Revenue</th>
                        <th width="4%" class="insight">%</th>
                        <th width="14%" class="insight">Expenses</th>
                        <th width="4%" class="insight">%</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let year of years">
                        <td>FY{{year}}</td>
                        <td><span>{{getFinance('estimated-tnc-costs', year)}}</span></td>
                        <td><span>{{getFinance('estimated-non-tnc-costs-funded-by-tnc', year)}}</span></td>

                        <td class="insight" [ngClass]="{'future-year' : !isCurrentFiscalYear(year)}">
                            <span>{{getAllocatedBudget(year)}}</span></td>

                        <td class="insight" [ngClass]="{'future-year' : !isCurrentFiscalYear(year)}">
                            <span>{{getRevenue(year) | number : '1.0-0'}}</span>
                            <div class="progress right">
                                <div class="progress-bar bg-success"
                                    [ngStyle]="{'width.%': getRevenuePercentage(year)}">
                                </div>
                            </div>
                        </td>
                        <td class="insight" [ngClass]="{'future-year' : !isCurrentFiscalYear(year)}">
                            <span>{{Math.round(getRevenuePercentage(year))}}%</span></td>
                        <td class="insight" [ngClass]="{'future-year' : !isCurrentFiscalYear(year)}">
                            <span>{{getExpense(year)}}</span>
                            <div class="progress right">
                                <div class="progress-bar bg-success"
                                    [ngStyle]="{'width.%': getExpensePercentage(year)}">
                                </div>
                            </div>
                        </td>
                        <td class="insight" [ngClass]="{'future-year' : !isCurrentFiscalYear(year)}">
                            <span>{{Math.round(getExpensePercentage(year))}}%</span></td>
                        <td></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr class="table-border-double">
                        <th>Total</th>
                        <th>{{calculateTypeSum('estimated-tnc-costs')}}</th>
                        <th>{{calculateTypeSum('estimated-non-tnc-costs-funded-by-tnc')}}</th>
                        <th class="insight">{{calculateTypeSum('allocated-working-budget-insight')}}</th>
                        <th class="insight">{{calculateTypeSum('revenue')}}</th>
                        <th class="insight"></th>
                        <th class="insight">{{calculateTypeSum('expense')}}</th>
                        <th class="insight"></th>
                        <th></th>
                    </tr>
                </tfoot>
            </table>
        </div>


        <h5 class=" text-success mt-3 mb-2">Insight Record(s)</h5>
        <div class="row">
            <div class="col-md-12">
                <ul>
                    <li *ngFor="let record of insightRecords">
                        <span *ngIf="record.insightProjectStatus === 'Active'">
                            <span class="font-size-lg m-1">{{record.insightProjectName}}
                                ({{record.insightProjectId}}) </span>owned by BU <span
                                class="font-weight-bold">{{record.insightProjectBusinessUnit}}</span> <i
                                *ngIf="record?.anchorRecordFlag" class="icon-anchor text-indigo ml-2"></i>
                        </span>
                        <span *ngIf="record.insightProjectStatus === 'Closed'">
                            <span class="font-size-lg m-1">{{record.insightProjectName}}
                                ({{record.insightProjectId}}) </span>owned by BU <span
                                class="font-weight-bold">{{record.insightProjectBusinessUnit}}</span> <i
                                *ngIf="record?.anchorRecordFlag" class="icon-anchor text-indigo ml-2"></i>
                            <span class="text-muted ml-2">(Disabled)</span>
                        </span>
                    </li>
                </ul>
                <!-- SHOW IF NO INSIGHT RECORDS DEFINED -->
                <div class="alert alert-info alert-styled-left shadow-0 mr-3" *ngIf="insightRecords?.length === 0">
                    This record has no associated Insight financial records yet. Contact the Lead on the record to
                    inquire about
                    Insight records.
                </div>
            </div>
        </div>

        <h5 class="text-success mt-3 mb-2">Financial Notes</h5>
        <p class="record-description" [innerHTML]="financialNotes"></p>

    </div>


</div>
