import { inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { HubRecordPresenterBase } from "../../shared/components/_hub_record_presenter_base";
import { HubRecordEditService } from "../services/hub-record-edit.service";
import { ProjectModel } from "../../../hub_schema/hubTypes";
import { HubRecordTypes } from "../../shared/types/hub-record-types";


export class HubRecordEditorBase {
    protected hubRecordEditService: HubRecordEditService = inject(HubRecordEditService);
    
    public get project(): ProjectModel {
        return this.hubRecordEditService.project;
    }

    public set project(value: ProjectModel) {
        this.hubRecordEditService.project = value;
    }

    public get projectEditForm(): FormGroup | undefined {
        return this.hubRecordEditService.projectEditForm;
    }

    public set projectEditForm(value: FormGroup | undefined) {
        this.hubRecordEditService.projectEditForm = value;
    }

    // #region Validation

    public get isSummaryValid(): boolean {
        return this.hubRecordEditService.isSummaryValid;
    }

    public get isProjectNameValid(): boolean {
        return this.hubRecordEditService.isProjectNameValid;
    }

    public set isProjectNameValid(isValid: boolean) {
        this.hubRecordEditService.isProjectNameValid = isValid;
    }

    public get isLocationValid(): boolean {
        return this.hubRecordEditService.isLocationValid;
    }

    public get isTeamValid(): boolean {
        return this.hubRecordEditService.isTeamValid;
    }

    public get isFinancialsValid(): boolean {
        return this.hubRecordEditService.isFinancialsValid;
    }

    public get isProjectValid(): boolean {
        return this.hubRecordEditService.isProjectValid;
    }

    public get isProjectPristine(): boolean {
        return this.hubRecordEditService.isProjectPristine;
    }

    public hasValidationErrors(formControlName: string): boolean {
        if (!formControlName) {
            return this.projectEditForm!.invalid;
        }
        const formControl = this.projectEditForm!.controls[formControlName];
        return formControl.invalid;
    }

    public getValidationErrorMessages(formControlName: string): string {
        const formControl = this.projectEditForm!.controls[formControlName];
        
        if (formControl.errors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(formControl.errors)) {
                let errorMessage = key;
                if (key !== 'required') {
                    errorMessage = formControl.errors[key];
                }
                if (key === 'maxlength') {
                    const maxLength = formControl.errors[key].requiredLength;
                    const actualLength = formControl.errors[key].actualLength;

                    errorMessage = `Max length of ${maxLength} characters exceeded by ${actualLength - maxLength}.`;
                }
                errorMessages.push(errorMessage);
            }
            return errorMessages.join(', ');
        }

        return '';
    }

    // #endregion Validation

    // todo: factor these out or use hubRecordPresenterBase as a base class
    public get isProject(): boolean {
        return this.project?.projectType!.name == HubRecordTypes.Project;
    }
    
    public get isStrategy(): boolean {
        return this.project?.projectType!.name == HubRecordTypes.Strategy;
    }

    public get isWholeSystem(): boolean {
        return this.project?.projectType!.name == HubRecordTypes.WholeSystem;
    }
    
    public get isProgramManagement(): boolean {
        return this.project?.projectType!.name == HubRecordTypes.ProgramManagement;
    }

}