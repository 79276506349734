import { Filter } from './filter';
import { CheckboxFilter } from './filter-checkbox';
import { RadioButtonFilter } from './filter-radio-button';

export class Facet {
    public type: 'basic' | 'advanced';  // todo (ACE 4/11/2022): this is tightly coupled to Portfolio.
    public key: string;
    public title: string;
    public filterType: string;
    public isExpanded: boolean;
    public isSearchable: boolean = false;
    public placeholderText: string;
    public isYesNoFacet: boolean;

    private _searchText: string;

    public get searchText(): string {
        return this._searchText;
    }

    public set searchText(value: string) {
        this._searchText = value;

        for (const filter of this.filters) {
            if (filter.title.toLowerCase().startsWith(value.toLowerCase())) {
                filter.isHidden = false;
            }
            else {
                filter.isHidden = true;
            }
        }
    }

    public get isCondensible() {
        const unhiddenFilters = this.filters.filter((f) => !f.isHidden);
        if (unhiddenFilters.length <= 10) {
            return false;
        }

        return this.getVisibleFilters().length > 10;
    }

    public get getHierarchicalRefinements() {
        const rows = [];
        let lastGroupName = '';

        // tslint:disable-next-line: no-unused-expression
        this.filters && this.filters.length &&
            this.filters.forEach((refinement: CheckboxFilter) => {

                const groupNameParts = refinement.title.split('>');
                const groupName = groupNameParts[0].trim();
                const nameForFacet = groupNameParts[1].trim();

                // Set the Group Headers
                if (lastGroupName !== groupName) {
                    rows.push({ isGroupHeader: true, title: groupName, numberOfHits: 0, isChecked: false });
                    lastGroupName = groupName;
                }

                if (!refinement) {
                    return;
                }
                const newRefinement = new CheckboxFilter(() => { });
                Object.keys(refinement).forEach((prop) => {
                    newRefinement[prop] = refinement[prop];
                });
                newRefinement.nameForDisplay = nameForFacet;
                newRefinement.isGroupHeader = false;
                rows.push(newRefinement);
            });

        return rows;
    }

    public isCondensed: boolean;
    public isHidden: boolean;
    public isInvisible: boolean = false;
    public filters: Filter[];

    constructor(type, key, title, filterType, expanded, condensed, isYesNoFacet: boolean = false) {
        this.type = type;
        this.key = key;
        this.title = title;
        this.filterType = filterType;
        this.isExpanded = expanded;
        this.isCondensed = condensed;
        this.isYesNoFacet = isYesNoFacet;
        this.filters = [];
    }

    private getVisibleFilters(): Filter[] {
        return this.filters.filter((f) => f.numberOfHits);
    }

    public getFilters(): Filter[] {
        let filters: Filter[];

        if (!this.isCondensed || !this.isCondensible) {
            filters = this.filters;
        }
        else {
            filters = this.getVisibleFilters().slice(0, 10);
        }
        return filters.filter((f) => !f.isHidden);
    }

    public getFilter(title: string): Filter {
        return this.filters.find((f) => f.title === title);
    }

    public getFiltersSorted(): Filter[] {

        if (!this.filters || !this.filters.length) {
            return [];
        }

        let filters: Filter[];

        if (!this.isCondensed || !this.isCondensible) {
            filters = this.sortFilters(this.filters);
        }
        else {
            filters = this.sortFilters(this.getVisibleFilters()).slice(0, 9);
        }
        return filters.filter((f) => !f.isHidden);
    }

    public sortFilters(filters: Filter[]): Filter[] {
        let sortedFilter;
        
        if(Number.isNaN(+filters[0].title)){
            sortedFilter = filters.sort((a,b) => {
                if(a.title > b.title) {
                    return 1;
                } else if (b.title > a.title) {
                    return -1;
                } else {
                    return 0;
                }
            });
        } else {
            sortedFilter = filters.sort((a,b) => {
                if(parseInt(a.title) > parseInt(b.title)) {
                    return 1;
                } else if (parseInt(a.title) < parseInt(b.title)) {
                    return -1;
                } else {
                    return 0;
                }
            });
        }
        return sortedFilter;
    }


    public filterExists(title: string): boolean {
        return this.getFilter(title) !== undefined;
    }

    public getActiveFilters(): Filter[] {
        return this.filters.filter((f) => f.isActive());
    }

    public isSelectAllAvailable(): boolean {
        if (this.filterType !== 'checkbox') {
            return false;
        }
        return !this.filters.every((f) => (f as CheckboxFilter).isChecked);
    }

    public isClearAllAvailable(): boolean {
        if (this.filterType !== 'checkbox') {
            return false;
        }
        return !this.filters.every((f) => !(f as CheckboxFilter).isChecked);
    }

    public areSelectAllAndClearAllAvailable(): boolean {
        return this.isSelectAllAvailable() && this.isClearAllAvailable();
    }

    public selectAll(): void {
        if (this.filterType !== 'checkbox') {
            return;
        }
        for (const filter of this.filters) {
            (filter as CheckboxFilter).isChecked = true;
        }
    }

    public clearAll(): void {
        if (this.filterType === 'checkbox') {
            for (const filter of this.filters) {
                (filter as CheckboxFilter).isChecked = false;
            }
        }
        if (this.filterType === 'radio') {
            for (const filter of this.filters) {
                (filter as RadioButtonFilter).isChecked = false;
            }
        }
    }

    public getFilterString(): string {

        if (this.filterType === 'checkbox') {
            const filterClauses = [];

            for (const filter of this.getActiveFilters()) {
                filterClauses.push(this.key + ':' + `"${filter.title}"`);
            }

            const facetFilterClause = filterClauses.join(' OR ');
            return `(${facetFilterClause})`;
        }
        else {
            return '';
            // todo: write for other filter types if ever needed
        }
    }

    public stringify(): string {

        const filterStringList = this.filters.map((f) => f.stringify());
        return `
            {
                "key": "${this.key}",
                "filters": [${filterStringList.join()}]
            }
        `;
    }

    public loadFromSaved(savedFacet: any): void {
        for (const savedFilter of savedFacet.activeFilters) {
            const existingFilter = this.filters.find((f) => f.title === savedFilter.title);
            if (existingFilter) {
                existingFilter.makeActive();
            }
        }
    }
}
