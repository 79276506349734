<div class="project-name-suggestions-container" *ngIf="formGroup">
    <div class="project-name-search-panel" >
        <span class="search-icon" *ngIf="!initialValue">
            <i class="icon-search4"></i>
        </span>
        <input [formControl]="projectNameControl" placeholder="Search" type="text" class="form-control" [matAutocomplete]="projectNamesAutocomplete">
    </div>
                    
    <mat-autocomplete #projectNamesAutocomplete="matAutocomplete" 
        (optionSelected)="projectNameClicked($event)">
        <mat-option *ngFor="let projectName of projectSuggestions$ | async" [value]="projectName">
            <span class="project-name-hit">
                {{projectName}}
            </span>
        </mat-option>
    </mat-autocomplete>
    
    <div class="hub-validation-error-message">
        {{getProjectNameErrorMessage()}}
    </div>
</div>
