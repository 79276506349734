import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { BusinessRoleModel, TeamMemberModel, UserBusinessRoleModel, UserModel } from '../../../../../../hub_schema/hubTypes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TeamLovService } from '../services/team-lov.service';
import { Observable, Subscription, catchError, debounceTime, filter, forkJoin, map, of, startWith, switchMap, take, tap } from 'rxjs';
import { HubRecordEditorBase } from '../../../_hub_record_editor_base';
import { TypeAheadService } from '../../../../../core/services/type-ahead.service';

@Component({
  selector: 'app-team-member-dialog',
  templateUrl: './team-member-dialog.component.html',
  styleUrls: ['./team-member-dialog.component.scss']
})
export class TeamMemberDialogComponent extends HubRecordEditorBase implements OnInit, OnDestroy {

    // #region Dependencies

    private teamLovService: TeamLovService = inject(TeamLovService);
    private typeAheadService: TypeAheadService = inject(TypeAheadService);
    private dialogRef: MatDialogRef<TeamMemberDialogComponent> = inject(MatDialogRef<TeamMemberDialogComponent>);
    private data: any = inject(MAT_DIALOG_DATA);

    // #endregion Dependencies

    public teamMember: TeamMemberModel;
    public editTeamMemberForm: FormGroup;
    public businessRoles: BusinessRoleModel[];
    public roleWarningText: string;
    public hasAnchorRecord: boolean;
    public isRoleLead: boolean = false;

    public ngOnInit(): void {
        forkJoin({
            businessRoles: this.teamLovService.getBusinessRoles(),
            financeContacts: this.teamLovService.getAllFinanceContacts()
        })
        .pipe(take(1)).subscribe((results: any) => {
            this.businessRoles = results.businessRoles;
            this.financeContacts = results.financeContacts;
            this.financeContactUserIds = results.financeContacts.map(u => u.userId);

            this.teamMember = this.data.teamMember;
            const selectedRole = this.businessRoles.find(role => role.businessRoleId === this.teamMember?.userBusinessRoles![0].businessRoleId || null);

            this.editTeamMemberForm = new FormGroup({
                projectTeamId: new FormControl(this.teamMember ? this.teamMember.projectTeamId : null),
                projectId: new FormControl(this.project.projectId),
                employee: new FormControl(this.teamMember ? this.teamMember.user : null, Validators.required),
                role: new FormControl(selectedRole, Validators.required),
                isPoc: new FormControl(this.teamMember ? this.teamMember.isPoc : false)
            });

            this.hasAnchorRecord = this.project.insightRecords!.some(ar => ar.anchorRecordFlag);
            this.setupRoleChangeSubscription();
            this.setupUserTypeahead();
            this.setupFinanceContactTypeahead();
        });
    }

    private isOptionDisabled(user: UserModel, role: BusinessRoleModel): boolean {
        const selectedTeamMembers = this.projectEditForm?.controls.team.value.filter(tm => tm.user.userId == user.userId);

        if (!selectedTeamMembers.length) {
            return false;
        }

        let usedBusinessRoleIds = selectedTeamMembers.map(tm => tm.userBusinessRoles.map(ubr => ubr.businessRole.businessRoleId)).flat();

        if (this.teamMember) {
            usedBusinessRoleIds = usedBusinessRoleIds.filter(id => id !== this.teamMember.userBusinessRoles![0].businessRole!.businessRoleId);
        }

        return usedBusinessRoleIds.includes(role.businessRoleId);
    }

    // #region Role

    public isSingleUserRole(role: BusinessRoleModel) {
        const singleUserRoles = ['Lead', 'Finance Contact', 'Development Contact'];
        return singleUserRoles.includes(role.name);
    }

    public get isFinanceContactRoleSelected(): boolean {
        const selectedRole: BusinessRoleModel = this.editTeamMemberForm.controls.role.value;
        return selectedRole && selectedRole.name === 'Finance Contact';
    }

    public isRoleDisabled(role: BusinessRoleModel): boolean {
        const selectedUser = this.editTeamMemberForm.controls.employee.value;

        if (!selectedUser) {
            return false;
        }

        if (role.name === 'Finance Contact') {
            if (!this.financeContactUserIds || !this.financeContactUserIds.length) {
                return true;
            }

            if (!this.financeContactUserIds.includes(selectedUser.userId)) {
                return true;
            }
        }

        return this.isOptionDisabled(selectedUser, role);
    }

    public getRoleTooltip(role: BusinessRoleModel): string {
        if (this.isRoleDisabled(role)) {
            const selectedEmployee = this.editTeamMemberForm.controls.employee.value;

            const isFinanceContact: boolean = this.financeContactUserIds.includes(selectedEmployee.userId);

            if (role.name === 'Finance Contact' && !isFinanceContact) {
                return 'User ' + selectedEmployee.fullName + ' is not an approved Finance Contact';
            }

            return 'User ' + selectedEmployee.fullName + ' is already assigned to this role';
        }
        else {
            return '';
        }
    }

    private roleChangeSubscription: Subscription;

    private setupRoleChangeSubscription(): void {
        this.roleChangeSubscription = this.editTeamMemberForm.controls.role.valueChanges
        .subscribe((role) => {
            if (this.isSingleUserRole(role)) {
                // only clear out the employee, if the role is finance contact, and the employee isn't one.
                if (role.name === 'Finance Contact') {
                    const userId = this.editTeamMemberForm.controls.employee.value?.userId;

                    if (!this.financeContactUserIds.includes(userId)) {
                        this.editTeamMemberForm.controls.employee.setValue(null);
                        this.userTypeahead.setValue('');
                        this.financeContactTypeahead.setValue('');
                    }
                }

                this.isRoleLead = role.name === 'Lead';

                const existingTeamMember = this.getExistingSingleTeamMemberForRole(role);

                if (existingTeamMember && existingTeamMember.userId !== this.teamMember?.userId) {
                    this.roleWarningText = `There is a maximum of 1 team member for this role.<br /> ${existingTeamMember.user!.fullName} will be replaced.`;
                }
                else {
                    this.roleWarningText = '';
                }
            }
            else {
                this.roleWarningText = '';
                this.isRoleLead = false;
            }
        });
    }

    private getExistingSingleTeamMemberForRole(role: BusinessRoleModel): TeamMemberModel | null {
        const rolesTeamMembers = this.getTeamMembersByRole(role.businessRoleId);

        if (rolesTeamMembers.length) {
            return rolesTeamMembers[0];
        }

        return null;
    }

    public getTeamMembersByRole(roleId: number): TeamMemberModel[] {
        return this.project.team!
            .filter((i: TeamMemberModel) =>
                i.userBusinessRoles!.map(ubr => ubr.businessRole!.businessRoleId).includes(roleId)
            );
    }

    // #endregion Role

    public override getValidationErrorMessages(formControlName: string): string {
        const formControl = this.editTeamMemberForm.get(formControlName);

        if (!formControl) {
            return '';
        }

        // if (formControlName === 'employee') {
        //     if (!this.editTeamMemberForm.controls.employeeFinanceContactSearchText.touched && !this.editTeamMemberForm.controls.employeeNotFinanceContactSearchText.touched) {
        //         return '';
        //     }
        // }

        if (!formControl.touched) {
            return '';
        }

        const formErrors = formControl.errors;

        if (formErrors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(formErrors)) {
                let errorMessage = key;
                if (key !== 'required') {
                    errorMessage = formErrors[key];
                }
                errorMessages.push(errorMessage);
            }
            return errorMessages.join('<br />');
        }

        return '';
    }

    // #region Users/Finance Contacts

    // #region Finance Contacts

    public financeContacts: UserModel[];
    public financeContactUserIds: number[];
    public financeContactTypeahead: FormControl = new FormControl('');
    public filteredFinanceContacts$: Observable<UserModel[]>;

    private setupFinanceContactTypeahead(): void {
        this.filteredFinanceContacts$ = this.financeContactTypeahead.valueChanges
        .pipe(
            startWith(''),
            map((value) => this.filterFinanceContacts(value)),
        );

    }

    private filterFinanceContacts(term): UserModel[] {
        if (typeof term !== 'string') {
            return [];
        }
        const results = this.financeContacts.filter((financeContact: UserModel) => {
            return financeContact.fullName!.toLowerCase().startsWith(term.toLowerCase());
        });

        return results;
    }

    // #endregion Finance Contacts

    // #region Regular Users

    public userTypeahead: FormControl = new FormControl('');
    public filteredUsers$: Observable<UserModel[]>;

    private setupUserTypeahead(): void {
        this.filteredUsers$ = this.userTypeahead.valueChanges.pipe(
            debounceTime(200),
            switchMap((term) => this.typeAheadService.getUserTypeAheadData(term)
                .pipe(
                    catchError(() => of([])), // empty list on error
                )
            ),
            take(25)
        );
    }

    public get selectedUser(): UserModel {
        return this.editTeamMemberForm.controls.employee.value;
    }

    public displayUser(option?: any): string {
        if (option && option.userId) {
            return option.fullName;
        }
        return '';
    }

    public isUserDisabled(user: UserModel): boolean {
        const selectedRole = this.editTeamMemberForm.controls.role.value;

        if (!selectedRole) {
            return false;
        }

        return this.isOptionDisabled(user, selectedRole);
    }

    public getUserTooltip(user: UserModel): string {
        if (this.isUserDisabled(user)) {
            return 'User ' + user.fullName + ' is already assigned to this role';
        }
        return '';
    }

    public user_clicked(e): void {
        this.selectUser(e.option.value);
    }

    private selectUser(user: UserModel): void {
        this.editTeamMemberForm.controls.employee.setValue(user);
        this.editTeamMemberForm.controls.employee.markAsDirty();
    }

    // endregion Regular Users


    // #endregion Users/Finance Contacts

    public cancel(): void {
        this.dialogRef.close();
    }

    public submitTeamMember(): void {
        const teamMember: any = this.editTeamMemberForm.value;  // it's not a teamMemberModel
        this.dialogRef.close(teamMember);
    }

    public ngOnDestroy(): void {
        if (this.roleChangeSubscription) {
            this.roleChangeSubscription.unsubscribe();
        }
    }
}
