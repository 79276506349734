<div class="view-section" id="outputs">
    <h3 class="view-section-header">Outputs</h3>

    <ul class="nav nav-tabs nav-tabs-bottom mb-1">
        <li class="nav-item">
            <a class="nav-link legitRipple" data-toggle="tab" (click)="setTabTo(tabs.outputs, $event)"
                [ngClass]="{active: activeTab === tabs.outputs}">Active Outputs ({{activeOutputs?.length}})
            </a>
        </li>

        <li class="nav-item">
            <a class="nav-link legitRipple" data-toggle="tab" (click)="setTabTo(tabs.archive, $event)"
                [ngClass]="{active: activeTab === tabs.archive}">
                Archived Outputs ({{archivedOutputs?.length}})
            </a>
        </li>
    </ul>
    <div class="tab-content parent-min">
        <div class="tab-pane" [ngClass]="{active: activeTab === tabs.outputs, show: activeTab === tabs.outputs}"
            id="output">
            <div class="details">

                <div *ngFor="let output of activeOutputs; let i = index">
                    <app-output [output]="output" [outputIndex]="i"></app-output>
                </div>

                <!-- SHOW IF NO ACTIVE OUTPUTS DEFINED -->
                <div class="alert alert-info alert-styled-left shadow-0 mr-3" *ngIf="activeOutputs?.length === 0">
                    No Active Outputs have been defined. See Archived Outputs.
                </div>
            </div>
        </div>

        <div class="tab-pane" [ngClass]="{active: activeTab === tabs.archive, show: activeTab === tabs.archive}"
            id="archive">
            <div class="details">

                <div *ngFor="let output of archivedOutputs; let i = index">
                    <app-output [output]="output" [outputIndex]="i"></app-output>
                </div>

                <!-- SHOW IF NO ARCHIVE OUTPUTS DEFINED -->
                <div class="alert alert-info alert-styled-left shadow-0 mr-3" *ngIf="archivedOutputs?.length === 0">
                    No Archived Outputs have been defined.
                </div>
            </div>
        </div>

    </div>
