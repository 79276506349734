import { inject, Injectable } from '@angular/core';
import { HttpService } from '../../../../core/services/http.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { ProjectInfoModel } from '../../../../../hub_schema/hubTypes';

@Injectable()
export class ProjectNameLookupService {
    private httpService: HttpService = inject(HttpService);

    public getProjectNameSuggestions(searchTerm: string): Observable<ProjectInfoModel[]> {
        const url = environment.endpoints.base + `/projects/search/${searchTerm}`;
        return this.httpService.get<ProjectInfoModel[]>(url);
    }
}
