import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Observable, tap, take, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { UserModel, UsersSearchResultModel } from '../../../../../hub_schema/hubTypes';
import { HubAdministrationService } from '../../../services/hub-administration.service';


@Component({
  selector: 'app-system-permissions-tab',
  templateUrl: './system-permissions-tab.component.html',
  styleUrls: ['./system-permissions-tab.component.scss']
})
export class SystemPermissionsTabComponent implements OnInit, OnDestroy {
    // dependencies
    private dialogService: MatDialog = inject(MatDialog);
    private hubAdminService: HubAdministrationService = inject(HubAdministrationService);

    private readonly hitsPerPage: number = 8;
    public usersSearchResult: UsersSearchResultModel;

    public systemPermissionsForm: FormGroup;    
    private searchTextChangedSubscription: Subscription;
    private pageChangedSubscription: Subscription;
    private applicationRoleFiltersChangedSubscription: Subscription;
    private businessUnitFiltersChangedSubscription: Subscription;
    private divisionFiltersChangedSubscription: Subscription;
    private regionFiltersChangedSubscription: Subscription;
    private editorBuFiltersChangedSubscription: Subscription;
    
    public ngOnInit(): void {
        this.systemPermissionsForm = new FormGroup({
            searchText: new FormControl(''),
            page: new FormControl(0),
            applicationRoleFilters: new FormArray([]),
            businessUnitFilters: new FormArray([]),
            divisionFilters: new FormArray([]),
            regionFilters: new FormArray([]),
            editorBuFilters: new FormArray([])
        });

        this.getUsers();

        // we setup separate listeners on each form element.
        this.searchTextChangedSubscription = this.systemPermissionsForm.controls.searchText.valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged()
            )
            .subscribe(() => {
                this.getUsers();
            });

        this.pageChangedSubscription = this.systemPermissionsForm.controls.page.valueChanges.subscribe(() => {
            this.getUsers();
        });

        this.applicationRoleFiltersChangedSubscription = this.systemPermissionsForm.controls.applicationRoleFilters.valueChanges.subscribe(() => {
            setTimeout(() => {
                this.getUsers(0, 'applicationRoles');
            });
        });
        
        this.businessUnitFiltersChangedSubscription = this.systemPermissionsForm.controls.businessUnitFilters.valueChanges.subscribe(() => {
            setTimeout(() => {
                this.getUsers(0, 'businessUnits');
            });            
        });

        this.divisionFiltersChangedSubscription = this.systemPermissionsForm.controls.divisionFilters.valueChanges.subscribe(() => {
            setTimeout(() => {
                this.getUsers(0, 'divisions');
            });
        });

        this.regionFiltersChangedSubscription = this.systemPermissionsForm.controls.regionFilters.valueChanges.subscribe(() => {
            setTimeout(() => {
                this.getUsers(0, 'regions');
            });
        });

        this.editorBuFiltersChangedSubscription = this.systemPermissionsForm.controls.editorBuFilters.valueChanges.subscribe(() => {
            setTimeout(() => {
                this.getUsers(0, 'editorBusinessUnits');
            });
        });
    }

    private getUsers(newPageIndex: number = 0, facetClicked: string = ''): void {          
        const search = this.systemPermissionsForm.value;
        search.page = newPageIndex;
        search.facet = facetClicked;
 
        this.hubAdminService.searchUsers(search).pipe(take(1)).subscribe((results: UsersSearchResultModel) => {
            this.usersSearchResult = results;
            this.populateFilters();
        });
    }

    public pageRequested(page: number): void {
        this.systemPermissionsForm.controls.page.setValue(page, {emitEvent: false});
        this.getUsers(page);
    }

// #region Search Text

public get isClearSearchTextVisible(): boolean {
    return !!this.systemPermissionsForm.controls.searchText.value;
}

public clearSearchText(): void {
    this.systemPermissionsForm.controls.searchText.setValue('');
}

// #endregion Search Text

    private populateFilters(): void {
        this.populateApplicationRoleFilters();
        this.populateBusinessUnitFilters();
        this.populateDivisionFilters();
        this.populateRegionFilters();
        this.populateEditorBuFilters();
    }

    // #region Application Role Filters

    public get applicationRoleFiltersArray(): FormArray {
        return this.systemPermissionsForm.controls.applicationRoleFilters as FormArray;
    }

    private populateApplicationRoleFilters(): void {
        this.applicationRoleFiltersArray.clear({emitEvent: false});

        for (let arFilter of this.usersSearchResult.applicationRoleFilters) {
            const filterGroup = new FormGroup({
                applicationRoleId: new FormControl(arFilter.applicationRoleId),
                isSelected: new FormControl(arFilter.isSelected)
            });

            this.applicationRoleFiltersArray.push(filterGroup, {emitEvent: false});
        }
    }

    public getApplicationRoleName(filterGroup: FormGroup): string {
        const applicationRoleId = filterGroup.value.applicationRoleId;
        const filter = this.usersSearchResult.applicationRoleFilters.find(f => f.applicationRoleId === applicationRoleId);
        return filter!.applicationRoleName;
    }

    public getAppRoleNumberOfResults(filterGroup: FormGroup): number {
        const applicationRoleId = filterGroup.value.applicationRoleId;
        const filter = this.usersSearchResult.applicationRoleFilters.find(f => f.applicationRoleId === applicationRoleId);
        return filter?.numberOfResults!;
    }

    // #endregion ApplicationRoleFilters

    // #region Business Unit Filters

    public get businessUnitFiltersArray(): FormArray {
        return this.systemPermissionsForm.controls.businessUnitFilters as FormArray;
    }

    private populateBusinessUnitFilters(): void {
        this.businessUnitFiltersArray.clear({emitEvent: false});

        for (let buFilter of this.usersSearchResult.businessUnitFilters) {
            const filterGroup = new FormGroup({
                businessUnitId: new FormControl(buFilter.businessUnitId),
                isSelected: new FormControl(buFilter.isSelected)
            });

            this.businessUnitFiltersArray.push(filterGroup, {emitEvent: false});
        }
    }

    public getBusinessUnitName(filterGroup: FormGroup): string {
        const businessUnitId = filterGroup.value.businessUnitId;
        const filter = this.usersSearchResult.businessUnitFilters.find(f => f.businessUnitId === businessUnitId);
        return filter!.businessUnitName;
    }

    public getBuNumberOfResults(filterGroup: FormGroup): number {
        const businessUnitId = filterGroup.value.businessUnitId;
        const filter = this.usersSearchResult.businessUnitFilters.find(f => f.businessUnitId === businessUnitId);
        return filter?.numberOfResults!;
    }

    // #endregion Business Unit Filters

    // #region Division Filters

    public get divisionFiltersArray(): FormArray {
        return this.systemPermissionsForm.controls.divisionFilters as FormArray;
    }

    private populateDivisionFilters(): void {
        this.divisionFiltersArray.clear({emitEvent: false});

        for (let divFilter of this.usersSearchResult.divisionFilters) {
            const filterGroup = new FormGroup({
                divisionId: new FormControl(divFilter.divisionId),
                isSelected: new FormControl(divFilter.isSelected)
            });

            this.divisionFiltersArray.push(filterGroup, {emitEvent: false});
        }
    }

    public getDivisionName(filterGroup: FormGroup): string {
        const divisionId = filterGroup.value.divisionId;
        const filter = this.usersSearchResult.divisionFilters.find(f => f.divisionId === divisionId);
        return filter!.divisionName;
    }

    public getDivisionNumberOfResults(filterGroup: FormGroup): number {
        const divisionId = filterGroup.value.divisionId;
        const filter = this.usersSearchResult.divisionFilters.find(f => f.divisionId === divisionId);
        return filter?.numberOfResults!;
    }

    // #endregion Division Filters

    // #region Region Filters

    public get regionFiltersArray(): FormArray {
        return this.systemPermissionsForm.controls.regionFilters as FormArray;
    }

    private populateRegionFilters(): void {
        this.regionFiltersArray.clear({emitEvent: false});

        for (let regionFilter of this.usersSearchResult.regionFilters) {
            const filterGroup = new FormGroup({
                regionId: new FormControl(regionFilter.regionId),
                isSelected: new FormControl(regionFilter.isSelected)
            });

            this.regionFiltersArray.push(filterGroup, {emitEvent: false});
        }
    }

    public getRegionName(filterGroup: FormGroup): string {
        const regionId = filterGroup.value.regionId;
        const filter = this.usersSearchResult.regionFilters.find(f => f.regionId === regionId);
        return filter!.regionName;
    }

    public getRegionNumberOfResults(filterGroup: FormGroup): number {
        const regionId = filterGroup.value.regionId;
        const filter = this.usersSearchResult.regionFilters.find(f => f.regionId === regionId);
        return filter?.numberOfResults!;
    }

    // #endregion Region Filters

// #region Editor BU Filters

public get editorBuFiltersArray(): FormArray {
    return this.systemPermissionsForm.controls.editorBuFilters as FormArray;
}

private populateEditorBuFilters(): void {
    this.editorBuFiltersArray.clear({emitEvent: false});

    for (let ebuFilter of this.usersSearchResult.editorBuFilters) {
        const filterGroup = new FormGroup({
            editorBusinessUnitId: new FormControl(ebuFilter.editorBusinessUnitId),
            isSelected: new FormControl(ebuFilter.isSelected)
        });

        this.editorBuFiltersArray.push(filterGroup, {emitEvent: false});
    }
}

public getEditorBuName(filterGroup: FormGroup): string {
    const editorBusinessUnitId = filterGroup.value.editorBusinessUnitId;
    const filter = this.usersSearchResult.editorBuFilters.find(f => f.editorBusinessUnitId === editorBusinessUnitId);
    return filter!.editorBusinessUnitName;
}

public getEditorBuNumberOfResults(filterGroup: FormGroup): number {
    const editorBusinessUnitId = filterGroup.value.editorBusinessUnitId;
    const filter = this.usersSearchResult.editorBuFilters.find(f => f.editorBusinessUnitId === editorBusinessUnitId);
    return filter?.numberOfResults!;
}

// #endregion Editable BU Filters


    // #region User Editing 

    public editUserRequested(user: UserModel): void {
        const dialogRef = this.dialogService.open(EditUserDialogComponent, {
            width: '600px',
            data: {
                selectedUser: user
            }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((savedUser: UserModel) => {
            if (savedUser) {
                const index = this.usersSearchResult.users.findIndex(u => u.userId === user.userId);
                this.usersSearchResult.users[index] = savedUser;
            }
        });
    }

    // #endregion User Editing

    public ngOnDestroy(): void {
        if (this.searchTextChangedSubscription) {
            this.searchTextChangedSubscription.unsubscribe();
        }

        if (this.pageChangedSubscription) {
            this.pageChangedSubscription.unsubscribe();
        }

        if (this.applicationRoleFiltersChangedSubscription) {
            this.applicationRoleFiltersChangedSubscription.unsubscribe;
        }

        if (this.businessUnitFiltersChangedSubscription) {
            this.businessUnitFiltersChangedSubscription.unsubscribe();
        }

        if (this.divisionFiltersChangedSubscription) {
            this.divisionFiltersChangedSubscription.unsubscribe();
        }

        if (this.regionFiltersChangedSubscription) {
            this.regionFiltersChangedSubscription.unsubscribe();
        }

        if (this.editorBuFiltersChangedSubscription) {
            this.editorBuFiltersChangedSubscription.unsubscribe();
        }
    }
}
