import { Injectable, inject } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '../../../environments/environment';
import { AuthService } from './auth/auth.service';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { UserDataService } from './user-data.service';
import { NotificationModel, UserModel } from '../../../hub_schema/hubTypes';
import { ErrorService } from './error.service';

@Injectable()
export class NotificationsService {
    private httpService: HttpService = inject(HttpService);
    private authService: AuthService = inject(AuthService);
    private userDataService: UserDataService = inject(UserDataService);
    private errorService: ErrorService = inject(ErrorService);
    private notifications: NotificationModel[];

    constructor() {
        this.authService.authorizationComplete.pipe(take(1)).subscribe(() => {
            this.getNotifications();
        });
    }

    public hasUnreadNotifications(): boolean {
        return this.notifications && this.notifications.some((n) => !n.hasBeenViewed);
    }

    public getNotifications(): Observable<NotificationModel[]> {
        const userId = this.userDataService.getUserId();
        const url = environment.endpoints.base + '/user/' + userId + '/notifications';

        return this.httpService.get<NotificationModel[]>(url).pipe(
            tap((notifications) => {
                this.notifications = notifications;
            })
        );
    }

    public deleteNotification(notification: NotificationModel): Observable<void> {
        const userId = this.userDataService.getUserId();
        const url = environment.endpoints.base + '/user/' + userId + '/notifications/' + notification.notificationId;

        return this.httpService.delete(url).pipe(
            tap({
                next: () => {
                    const indexToDelete = this.notifications.findIndex((n) => n.notificationId === notification.notificationId);
                    this.notifications.splice(indexToDelete, 1);
                },
                error: (err) => {
                    this.errorService.addError(err, true);
                },
            })
        );
    }

    public markAllNotificationsAsRead(): Observable<void> {
        const userId = this.userDataService.getUserId();
        const url = environment.endpoints.base + '/user/' + userId + '/notifications/markAllAsRead';

        return this.httpService.get<void>(url).pipe(
            tap({
                next: () => {
                    for (let notification of this.notifications) {
                        notification.hasBeenViewed = true;
                    }
                },
                error: (err) => {
                    this.errorService.addError(err, true);
                },
            })
        );
    }
}
