    <div class="hub-toast-panel" @panel *ngIf="isToastShowing">
        <div class="body">
            <div class="left-column">
                <mat-icon>check_circle</mat-icon>
            </div>

            <div class="right-column">
                <div>
                    {{toastMessage.message}}
                </div>
            </div>

            <div class="actions">
                <button (click)="goto($event)">{{toastMessage.gotoButtonText}}</button>
            </div>

        </div>
    </div>

