import { limits } from '../types/limits';
import { urlPattern } from './urlPattern';

export const setProtocol = (url: string = '', protocol: string = 'https://') => {    
    if (!url.match(urlPattern)) {
        throw Error('Invalid URL');
    }

    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = `${protocol}${url}`;
    }

    return url.substr(0, limits.maxUrlLength);
}
