import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthResponseInterceptorService implements HttpInterceptor {

  private auth: AuthService;
  private currentRequest: HttpRequest<any>;

  constructor(
    private injector: Injector,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.auth = this.injector.get(AuthService);
    const token = this.auth.isLoggedIn() ? this.auth.getAccessToken() : null;

    if (token) {
      // store the current request for later retry if needed
      this.currentRequest = request;

      return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => {
          event = event as HttpResponse<any>;

          // INFO: server is returning 200s, even for errors. But the server populates body.errors if there are errors.
          if (event.body && event.body.errors && event.body.errors.length) {
            const hasAuthErrors = event.body.errors.some(
              (f: { extensions: { code: string; }; }) =>
                f.extensions && f.extensions.code && f.extensions.code === 'UNAUTHORIZED_ACCESS'
            );
            if (hasAuthErrors) {
              // INFO: Security is ok with a 24 hour login. And, after a 24 login, we should force a login.
              this.auth.login();
            }
          }
        }, (error: any) => {
          // INFO: This will currently not be reached since the server will always return a status code 200.
          // In GraphQL, errors are found in the 'error' field of the response. For more info on GraphQL errors,
          // see https://www.howtographql.com/graphql-java/7-error-handling/

          // There are times when errors are expected! Don't allow things to get messy in the view with this interceptor!
          if (request && request.url && request.url.indexOf('/api/msgraph/userProfile') > -1) {
            return next.handle(request);
          } else {
            return this.handleError(error);
          }
        })
      );
    }
    else {
      return next.handle(request);
    }
  }

  handleError(err: any) {
    if (err instanceof HttpErrorResponse && err.status === 401) {
      let previousRequest = this.currentRequest;
      this.auth.refreshToken().subscribe((refreshSuccessful) => {

        if (refreshSuccessful) {
          // resubmit failed request
          const http = this.injector.get(HttpClient);
          const token = this.auth.getAccessToken();

          previousRequest = previousRequest.clone({
            setHeaders: { Authorization: `Bearer ${token}` }
          });
          http.request(previousRequest);
        }

        else {
          // refresh failed. Take them back home, which will force a new AAD Login
          this.auth.login();
        }
      });
    }
  }
}
