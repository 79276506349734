import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class AlgoliaRefreshService {

    // we can add additional methods and events to this service as they are needed

    public algoliaRefreshed: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    public algoliaRefreshHappened(): void {
        this.algoliaRefreshed.emit();
    }
}
