import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-disclosure',
  templateUrl: './legal-disclosure.component.html',
  styleUrls: ['./legal-disclosure.component.scss']
})
export class LegalDisclosureComponent {

  constructor() { }

}
