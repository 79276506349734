<div class="hub-edit-financials-container">
    <ul class="nav nav-tabs nav-tabs-bottom mb-0">
        <li class="nav-item">
            <a href="#" (click)="setTabTo(tabs.financials, $event)" class="nav-link"
                [ngClass]="{active: activeTab === tabs.financials}">Financials</a>
        </li>
        <li class="nav-item">
            <a href="#" (click)="setTabTo(tabs.notes, $event)"  class="nav-link" [ngClass]="{active: activeTab === tabs.notes}">Notes</a>
        </li>
        <li class="nav-item">
            <a href="#" (click)="setTabTo(tabs.insightRecords, $event)"  class="nav-link" [ngClass]="{active: activeTab === tabs.insightRecords}">Insight Records</a>
        </li>
        <li class="nav-item" *ngIf="isAnchorRecordSelectionTabVisible()">
            <a href="#" (click)="setTabTo(tabs.anchorRecordSelection, $event)"  class="nav-link" [ngClass]="{active: activeTab === tabs.anchorRecordSelection}">Anchor Record Selection</a>
        </li>
    </ul>

    <div class="tab-content">
        <div class="tab-pane fade active p-0" [ngClass]="{active: activeTab === tabs.financials, show: activeTab === tabs.financials}" >
            <app-financials-edit-panel></app-financials-edit-panel>
        </div>

        <div class="tab-pane fade p-2" [ngClass]="{active: activeTab === tabs.notes, show: activeTab === tabs.notes}">
            <app-notes-panel></app-notes-panel>
        </div>

        <div class="tab-pane fade p-0" [ngClass]="{active: activeTab === tabs.insightRecords, show: activeTab === tabs.insightRecords}">
            <app-insight-records-panel></app-insight-records-panel>
        </div>

        <div class="tab-pane fade" [ngClass]="{active: activeTab === tabs.anchorRecordSelection, show: activeTab === tabs.anchorRecordSelection}"
            *ngIf="isAnchorRecordSelectionTabVisible()"
            style="padding: .625rem .625rem 0 .625rem;">
            <app-anchor-record-selection-panel></app-anchor-record-selection-panel>
        </div>
    </div>
</div>
