import { Injectable, inject } from '@angular/core';
import { HubLovService } from '../../../../core/services/hub-lov.service';
import { Observable } from 'rxjs';
import { FinanceMetricModel } from '../../../../../hub_schema/hubTypes';

@Injectable()
export class FinancialsLovService {
    private hubLovService: HubLovService = inject(HubLovService);
 
    public getFinanceMetrics(): Observable<FinanceMetricModel[]> {
        return this.hubLovService.getFinanceMetrics();
    }
}
