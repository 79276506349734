import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { CreateOutcomeStateService } from '../services/create-outcome-state.service';
import { OutcomeState } from '../types/outcome-state';

@Component({
  selector: 'app-outcome-details-tnc-goals-panel',
  templateUrl: './outcome-details-tnc-goals-panel.component.html',
  styleUrls: ['./outcome-details-tnc-goals-panel.component.scss']
})
export class OutcomeDetailsTncGoalsPanelComponent implements OnInit {
    private createOutcomeStateService: CreateOutcomeStateService = inject(CreateOutcomeStateService);
    public outcomeState: OutcomeState;

    public ngOnInit(): void {
        this.outcomeState = this.createOutcomeStateService.outcomeState!;
    }

    public get isRollupSelected(): boolean {
        return this.outcomeState.isRollupSelected;
    }
    public set isRollupSelected(value: boolean) {
        this.outcomeState.isRollupSelected = value;

        if (this.isRollupSelected) {
            (this.outcomeState.outcomeWizardForm!.controls.outcome as FormGroup).controls.parentOutcomeId.addValidators(Validators.required);
        }
        else {
            (this.outcomeState.outcomeWizardForm!.controls.outcome as FormGroup).controls.parentOutcomeId.clearValidators();
            (this.outcomeState.outcomeWizardForm!.controls.outcome as FormGroup).controls.parentOutcomeId.updateValueAndValidity();
        }
    } 
}
