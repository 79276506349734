<div class="manage-notifications-and-emails-container" *ngIf="notificationsPreferencesForm"
    [formGroup]="notificationsPreferencesForm">
    <div class="notifications-header">
        <h5 class="text-info-700">
            Manage Notifications and Emails
        </h5>
        <hr>

        <div class="save-button-panel">
            <button (click)="saveChanges()" class="save-button  {{savingState}}"
                [disabled]="notificationsPreferencesForm.pristine || notificationsPreferencesForm.invalid">
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
                <i class="icon-checkmark4"></i>

                <span>Save</span>
            </button>
        </div>

    </div>
    <div class="notifications-body hub-custom-scrollbar">

        <div class="notification-group">
            <div class="notification-group-header">
                I would like to be notified when
            </div>

            <div class="notification-group-settings">
                <mat-checkbox color="primary" formControlName="newRecordLedByMyRegion">
                    a new Hub record is created that is led by my region
                </mat-checkbox>

                <mat-checkbox color="primary" formControlName="newRecordLedByMyDivision">
                    a new Hub record is created that is led by my division
                </mat-checkbox>

                <mat-checkbox color="primary" formControlName="newRecordLedByMyBusinessUnit">
                    a new Hub record is created that is led by my business unit
                </mat-checkbox>

            </div>
        </div>

        <div class="notification-group">
            <div class="notification-group-header">
                On Hub records led by my Business Unit, I would like to be notified when
            </div>

            <div class="notification-group-settings">
                <mat-checkbox color="primary" formControlName="recordStatusChangeInMyBusinessUnit">
                    a Hub record's status is changed
                </mat-checkbox>

                <mat-checkbox color="primary" formControlName="projectOutcomeProgressValueAddedInMyBusinessUnit">
                    a new progress value is added to a project's Outcome
                </mat-checkbox>

                <mat-checkbox color="primary" formControlName="intermediateResultProgressValueAddedInMyBusinessUnit">
                    a new progress value is added to a project's Intermediate Result
                </mat-checkbox>

            </div>
        </div>

        <div class="notification-group">
            <div class="notification-group-header">
                On Hub Strategies for which I am part of the Team (in any role), I would like to be notified when
            </div>

            <div class="notification-group-settings">
                <mat-checkbox color="primary" formControlName="recordStatusChangedInMyStrategy">
                    the strategy record's status is changed
                </mat-checkbox>

                <mat-checkbox color="primary" formControlName="projectCreateRelationshipInMyStrategy">
                    a project creates a relationship with my strategy
                </mat-checkbox>

                <mat-checkbox color="primary" formControlName="projectRemovesRelationshipInMyStrategy">
                    a project removes a relationship with my strategy
                </mat-checkbox>

                <mat-checkbox color="primary" formControlName="projectRelatedToMyStrategyChangeStatus">
                    a project related to my strategy changes its status
                </mat-checkbox>
            </div>
        </div>

        <div class="notification-group">
            <div class="notification-group-header">
                On Hub Projects for which I am part of the Team (in any role), I would like to be notified when
            </div>

            <div class="notification-group-settings">
                <mat-checkbox color="primary" formControlName="recordStatusChangedInMyProject">
                    the project record's status is changed
                </mat-checkbox>

                <mat-checkbox color="primary" formControlName="strategyCreatesRelationshipInMyProject">
                    a strategy creates a relationship with my project
                </mat-checkbox>

                <mat-checkbox color="primary" formControlName="strategyRemovesRelationshipInMyProject">
                    a strategy removes a relationship with my project
                </mat-checkbox>

                <mat-checkbox color="primary" formControlName="strategyRelatedToMyProjectChangedStatus">
                    a strategy related to my project changes its status
                </mat-checkbox>

            </div>
        </div>

        <div class="notification-group">
            <div class="notification-group-header">
                Email Notifications:
            </div>

            <div class="alert alert-info alert-styled-left" *ngIf="!areAnyNotificationsActive()">
                You must select at least one notification checkbox to enable email notifications
            </div>

            <div class="notification-group-settings">
                <mat-radio-group class="hub-radio-group" formControlName="emailFrequencyId">
                    <mat-radio-button color="primary" *ngFor="let frequency of emailFrequencies"
                        [value]="frequency.emailFrequencyId">
                        {{frequency.description}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

    </div>
</div>