import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from '../core/services/auth/auth.service';
import { EmployeeService } from '../core/services/employee.service';
import { RecordCollectionService } from '../core/services/record-collection.service';
import { UserDataService } from '../core/services/user-data.service';
import { getClassFromStatus } from '../shared/helpers/getClassFromStatus';
import { ApplicationRoleType } from '../shared/types/role';
import { AlgoliaApiService } from '../core/services/algolia-api.service';
import { AlgoliaKeyService } from '../core/services/algolia-key.service';
import { GraphqlAuthorizationService } from '../core/services/auth/authorization/graphql-authorization.service';
import { DeleteConfirmationDialogComponent } from '../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { ProjectLovService } from '../core/services/project-lov.service';
import { LocalStorageService } from '../core/services/local-storage.service';
import { BulkEditWizardComponent } from './bulk-edit-wizard/bulk-edit-wizard.component';
import { UsageService } from '../core/services/usage.service';
import { FavoriteProjectModel, ProjectModel, RecordCollectionModel, StatusModel, UserApplicationRoleModel, UserModel } from '../../hub_schema/hubTypes';
import { GoalsService } from '../home/services/goals.service';

enum tabs {
    work = 1,
    favorites,
    collections
}

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    providers: [RecordCollectionService],
})
export class UserProfileComponent implements OnInit, OnDestroy {

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private graphQlAuthorizationService: GraphqlAuthorizationService,
        public recordCollectionService: RecordCollectionService,
        public userDataService: UserDataService,
        private employeeService: EmployeeService,
        private usageService: UsageService,
        private titleService: Title,
        private authService: AuthService,
        private projectCollectionsService: RecordCollectionService,
        private algoliaKeyService: AlgoliaKeyService,
        private algoliaApiService: AlgoliaApiService,
        private dialogService: MatDialog,
        private projectLovService: ProjectLovService,
        private goalsService: GoalsService
    ) {

        this.navToTabOnNgInit = !!this.router.getCurrentNavigation()!.extras.state?.tab ? this.router.getCurrentNavigation()!.extras!.state!.tab : '';

        this.favoritesChangedSubscription = this.userDataService.favoritesChanged.subscribe(() => {
            this.populateEmployeeFavorites();
        })

    }

    // #region View Modes

    public showingListView: boolean = false;

    public toggleListView(): void {
        this.showingListView = !this.showingListView;
        LocalStorageService.setItem('showListView', this.showingListView);
    }

    // #endregion

    public navToTabOnNgInit = '';
    public tabs = tabs;
    public activeTab: tabs = tabs.work;
    public algoliaConfig: any = null;
    public filters = '';
    public pageSize = 100;
    public facets = ['teamMembers.email'];
    public isSearchDone = true;

    public showFinanceDetails = false;
    public showFilterDetails = true;
    public showListView = true;
    public goals: any[];

    private employeeChangedSubscription: Subscription;
    private favoritesChangedSubscription: Subscription;

    public myProjects = [];
    public myFavoriteProjects: ProjectModel[] = [];
    public myCollections: RecordCollectionModel[];
    public getClassFromStatus = getClassFromStatus;

    public userId: number;
    public employeeId: number;
    public userEmail: string;
    public employeeApplicationRoleDisplay: string;

    public avatarUrl: string = '';

    public employee: UserModel;
    public notFound = false;
    public isBusy = false;

    public get possessivePronoun() {
        if (!this.employee) {
            return null;
        }
        if (!this.viewingOtherProfile) {
            return 'Your';
        }
        if (!this.employee.firstName) {
            return '';
        }
        const ending = this.employee.firstName.endsWith('s') ||
            this.employee.firstName.endsWith('x') ||
            this.employee.firstName.endsWith('z') ? `'` : `'s`;
        return this.employee.firstName + ending;
    }

    public get viewingOtherProfile(): boolean {
        return this.employee.userId !== this.userId;
    }

    public get isAdmin(): boolean {
        return (this.authService.userIsAdmin() || this.authService.userIsFinanceAdmin());
    }

    public get hasEditPermissions(): boolean {
        return (this.authService.getEditorPermissions() !== '');
    }

    ngOnInit() {
        this.isBusy = true;
        this.isSearchDone = false;

        // M.B. 08/04/2022 If routed here from a collection delete, then go to collection tab
        if (this.navToTabOnNgInit === 'collections') {
            this.setTabTo(tabs.collections, { preventDefault: () => { } });
        }

        else if (this.navToTabOnNgInit === 'favorites') {
            this.setTabTo(tabs.favorites, { preventDefault: () => { } });
        }

        // If an id parameter is present, then we are viewing someone else's profile
        const userId = this.activatedRoute.snapshot.params['id'];
        this.initialize(userId);

        // set up change of userId parameter
        this.employeeChangedSubscription = this.activatedRoute.params.subscribe((params) => {
            this.initialize(params.id);
        });

        this.projectLovService.getProjectStatuses().pipe(take(1)).subscribe((statuses: StatusModel[]) => {
            const showAllStatuses = new StatusModel();
            showAllStatuses.statusId = -1;
            showAllStatuses.name = '(Show All)';

            this.statuses = [showAllStatuses, ...statuses];

            this._recordStatus = this.statuses[0];
        });

        this.loadViewPreferences();

        this.goalsService.get2030Goals().pipe(take(1)).subscribe((goals: any[]) => {
            this.goals = goals;
        });
        
    }

    public switchTabToFavoritesRequested() {
        this.setTabTo(tabs.favorites, { preventDefault: () => { } });
    }

    public setTabTo(tab: tabs, e): void {
        this.activeTab = tab;
        e.preventDefault();
    }

    public newUserSelected(e: UserModel) {
        this.isBusy = true;
        if (e.userId === this.userId) {
            this.router.navigateByUrl(`/user`);
        } else {
            this.router.navigateByUrl(`/user/${e.userId}`);
        }
    }

    private initialize(userId) {
        if (userId) {
            this.employeeId = parseInt(userId, 10);
        }

        this.userId = this.userDataService.getUserId();

        this.projectLists = ['Team Member'];

        const roles: string[] = this.authService.getUserRoles();

        if (roles.includes('Business Unit Editor')) {
            this.projectLists.push('BU Editor');
        }

        if (roles.includes('Division Editor')) {
            this.projectLists.push('Division Editor');
        }

        if (roles.includes('Region Editor')) {
            this.projectLists.push('Region Editor');
        }

        if (roles.includes('ItAdmin')) {
            this.projectLists = ['Team Member', 'BU Editor', 'Division Editor', 'Region Editor'];
        }

        this.populateEmployee();
        this.loadEmployeeProjects();
        this.populateEmployeeFavorites();
        this.populateRecordCollections();
    }

    private loadViewPreferences(): void {
        const showCardDetails = LocalStorageService.getItem('showListView');

        if (showCardDetails !== null) {
            this.showingListView = LocalStorageService.getItem('showListView') === 'true';
        }
    }

    // #region Filters

    public statuses: StatusModel[];
    public projectLists = ['Team Member'];

    public dateRanges = [
        { days: 0, name: '(Show All)' },
        { days: 30, name: '1 Month' },
        { days: 90, name: '3 Months' },
        { days: 180, name: '6 Months' }
    ];

    private _selectedDateRange = this.dateRanges[0];

    public get selectedDateRange() {
        return this._selectedDateRange;
    }

    public set selectedDateRange(value) {
        this._selectedDateRange = value;
    }

    private employeeProjects: ProjectModel[];
    private buProjects: ProjectModel[];
    private regionProjects: ProjectModel[];
    private divisionProjects: ProjectModel[];
    public projects: ProjectModel[];

    private _currentProjectList: string = this.projectLists[0];
    private _recordStatus: StatusModel;

    public get recordStatus(): StatusModel {
        return this._recordStatus;
    }

    public set recordStatus(value: StatusModel) {
        this._recordStatus = value;
    }

    public get currentProjectList(): string {
        return this._currentProjectList;
    }

    public set currentProjectList(value: string) {
        this._currentProjectList = value;
        this.projects = []; // todo: why are we doing this?

        switch (value) {
            case 'Team Member':
                this.modifyProjectProperties(this.employeeProjects);
                break;
            case 'BU Editor':
                this.loadBuProjects();
                break;
            case 'Region Editor':
                this.loadRegionProjects();
                break;
            case 'Division Editor':
                this.loadDivisionProjects();
                break;
        }
    }

    // hack: I don't like adding properties to strongly typed objects,
    // but we can't do anything about this until we have a single source of truth (or both sources have data shaped the same way)
    private modifyProjectProperties(projectList: ProjectModel[]): void {
        this.projects = projectList.map((p: ProjectModel) => {
            // we need to add a few projectInfo properties that don't come from graphQl in the correct form (same form as Algolia)
            const leadBusinessUnit = p.projectBusinessUnits!.find(pbu => pbu.isLeadBusinessUnit);
            const leadBuName = leadBusinessUnit ? leadBusinessUnit.businessUnit!.name : '';

            const teamLead = p.team!.find(t => t.userBusinessRoles!.some(ubr => ubr.businessRole!.name === 'Lead'));
            const teamLeadName = teamLead ? teamLead.user!.fullName : '';
            const teamLeadUserId = teamLead ? teamLead.user!.userId : undefined;

            return {
                ...p,
                recordType: p.projectType!.name,
                leadBusinessUnit: leadBuName,
                lead: {
                    userId: teamLeadUserId,
                    name: teamLeadName
                }
            };
        });
    }

    public get filteredProjects(): ProjectModel[] {
        var results = this.projects;

        if (!results) {
            return [];
        }

        if (this.recordStatus && this.recordStatus.statusId !== -1) {
            results = results.filter(p => p.status!.statusId == this.recordStatus.statusId);
        }

        if (this.selectedDateRange.days > 0) {
            const millisecondsInADay = 3600000 * 24;

            results = results.filter((project: ProjectModel) => {
                const modifiedDate: Date = new Date(project.modifiedOn!);
                const today = new Date();
                const millisecondsElapsed = today.getTime() - modifiedDate.getTime();
                const daysElapsed = millisecondsElapsed / millisecondsInADay;
                return daysElapsed <= this.selectedDateRange.days;
            });
        }

        return results;
    }

    private loadEmployeeProjects() {
        this.isBusy = true;

        this.employeeService.getProjects(this.currentUserId).pipe(take(1)).subscribe((projects) => {
            this.employeeProjects = projects;
            this.modifyProjectProperties(this.employeeProjects);
            this.isBusy = false;
        });
    }

    private loadBuProjects() {
        this.isBusy = true;

        setTimeout(() => {
            const buId = parseInt(this.authService.getClaimDataValue('BusinessUnitId'));

            this.employeeService.getBuProjects(buId).pipe(take(1)).subscribe((buProjects: ProjectModel[]) => {
                this.buProjects = buProjects;
                this.modifyProjectProperties(this.buProjects);
                this.isBusy = false;
            });
        });
    }

    private loadRegionProjects() {
        this.isBusy = true;

        setTimeout(() => {
            const regionId = parseInt(this.authService.getClaimDataValue('RegionId'));

            this.employeeService.getRegionProjects(regionId).pipe(take(1)).subscribe((regionProjects: ProjectModel[]) => {
                this.regionProjects = regionProjects;
                this.modifyProjectProperties(this.regionProjects);
                this.isBusy = false;
            });
        });
    }

    private loadDivisionProjects() {
        this.isBusy = true;

        setTimeout(() => {
            const divisionId = parseInt(this.authService.getClaimDataValue('DivisionId'));
            this.employeeService.getDivisionProjects(divisionId).pipe(take(1)).subscribe((divisionProjects: ProjectModel[]) => {
                this.divisionProjects = divisionProjects;
                this.modifyProjectProperties(this.divisionProjects);
                this.isBusy = false;
            });
        });
    }

    private populateEmployee(): void {
        this.employeeService.getEmployeeDetails(this.currentUserId).pipe(take(1))
            .subscribe((employee: UserModel) => {
                this.employee = employee;

                this.setProfileImage();

                this.employeeApplicationRoleDisplay = this.employee.userApplicationRoles!.map((uar: UserApplicationRoleModel) => uar.applicationRole!.name).join(",\n");
                this.doPageSetup();
                this.filters = `(teamMembers.email:"${this.employee.email}")`;
                this.isBusy = false;

                this.algoliaApiService.initialize(
                    this.algoliaKeyService.indexVocabularyKeys.portfolioSearch,
                    () => {
                        this.algoliaConfig = this.algoliaApiService.getAlgoliaConfiguration(this.algoliaKeyService.indexVocabularyKeys.portfolioSearch);
                    }
                );
            },
                (err) => {
                    this.notFound = true;
                    this.isBusy = false;
                });
    }

    public isAllowedToEdit(project: ProjectModel): boolean {
        return this.graphQlAuthorizationService.isAllowedToEdit(project);
    }

    private setProfileImage() {
        if (this.employee) {
            this.userDataService.getUserProfilePicture(this.employee.email, null)
                .pipe(take(1))
                .subscribe((imageSource: any) => {
                    this.avatarUrl = imageSource ? `url(${imageSource})` : 'none';
                });
        }
    }

    private populateRecordCollections() {
        this.recordCollectionService.getRecordCollectionsByEmployee(this.currentUserId).pipe(take(1))
            .subscribe((collections: RecordCollectionModel[]) => {
                this.myCollections = collections;
            });
    }

    public doPageSetup() {
        const title = 'Hub - User Profile';
        this.titleService.setTitle(title);
        this.usageService.logHubUsage('View Profile', 'User', 'Profile Page of User', title);
    }

    public deleteCollection(recordCollectionId, removeIndex) {
        this.projectCollectionsService.deleteProjectFromCollection(recordCollectionId).pipe(take(1))
            .subscribe({
                next: () => {
                    this.myCollections.splice(removeIndex, 1);
                }
            });
    }

    deleteCollectionShowModal(recordCollectionId, removeIndex) {
        const dialogRef: MatDialogRef<DeleteConfirmationDialogComponent> = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: 'You will not be able to recover this collection'
            }
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed.pipe(take(1)).subscribe(() => {
            deleteConfirmationDialog.isBusy = true;
            this.deleteCollection(recordCollectionId, removeIndex);
            dialogRef.close();
        });
    }

    private get currentUserId(): number {
        if (this.employeeId) {
            return this.employeeId;
        }
        else {
            return this.userDataService.getUserId()
        }
    }

    private get currentUserEmail(): string {
        if (this.userEmail) {
            return this.currentUserEmail;
        }
        else {
            return this.userDataService.getUserEmail();
        }
    }

    public populateEmployeeFavorites(): void {
        this.employeeService.getEmployeeFavorites(this.currentUserId).pipe(take(1))
            .subscribe((favs: FavoriteProjectModel[]) => {
                this.myFavoriteProjects = favs.map(f => f.project!);
            });
    }

    public getUserRoles() {
        const userRolesArr: any[] = [];
        const userRoles: string = this.authService.getUserRoles();

        if (userRoles.includes(ApplicationRoleType.BusinessAdministrator)) {
            userRolesArr.push(ApplicationRoleType.BusinessAdministrator);
        }
        if (userRoles.includes(ApplicationRoleType.BusinessUnitEditor)) {
            userRolesArr.push(ApplicationRoleType.BusinessUnitEditor);
        }
        if (userRoles.includes(ApplicationRoleType.DivisionEditor)) {
            userRolesArr.push(ApplicationRoleType.DivisionEditor);
        }
        if (userRoles.includes(ApplicationRoleType.RegionEditor)) {
            userRolesArr.push(ApplicationRoleType.RegionEditor);
        }

        return userRolesArr;
    }

    public openBulkEditWizard() {
        const dialogRef: MatDialogRef<BulkEditWizardComponent> = this.dialogService.open(BulkEditWizardComponent, {
            disableClose: false
        });
    }

    ngOnDestroy() {
        if (this.employeeChangedSubscription) {
            this.employeeChangedSubscription.unsubscribe();
        }
        if (this.favoritesChangedSubscription) {
            this.favoritesChangedSubscription.unsubscribe();
        }
    }
}
