import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteConfirmationDialogComponent } from '../../../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { take } from 'rxjs/operators';
import { CheckableIntermediateResultModel } from '../types/checkable-intermediate-result-model';

@Component({
    selector: 'app-intermediate-result-list-item',
    templateUrl: './intermediate-result-list-item.component.html',
    styleUrls: ['./intermediate-result-list-item.component.scss'],
})
export class IntermediateResultListItemComponent {

    constructor(
        private dialogService: MatDialog
    ) { }

    @Input()
    public intermediateResult: CheckableIntermediateResultModel;

    @Input()
    public isStrategyIr: boolean;

    @Input() isSelected = false;
    @Input() projectType = '';

}
