import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-messaged-modal-spinner',
  templateUrl: './messaged-modal-spinner.component.html',
  styleUrls: ['./messaged-modal-spinner.component.scss']
})
export class MessagedModalSpinnerComponent {

  @Input()
  public message: string;

  constructor() { }

}
