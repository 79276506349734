import { Component, Input, OnInit } from '@angular/core';
import { ProjectBusinessUnitModel, ProjectInfoModel, ProjectRelationModel } from '../../../../../hub_schema/hubTypes';
import { HubRecordTypes } from '../../../../shared/types/hub-record-types';

@Component({
    selector: 'app-whole-system-relations-container',
    templateUrl: './whole-system-relations-container.component.html',
    styleUrls: ['./whole-system-relations-container.component.scss']
})
export class WholeSystemRelationsContainerComponent {

    @Input()
    public relations: ProjectRelationModel[];

    public get strategyRelations() {
        return this.relations.filter(r => r.relatedProject!.projectTypeName === HubRecordTypes.Strategy);
    }

    public get projectRelations() {
        return this.relations.filter(r => r.relatedProject!.projectTypeName === HubRecordTypes.Project);
    }

    @Input()
    public backwardRelations: ProjectRelationModel[];

    @Input() public projectType: string;

    private _projectBusinessUnits: ProjectBusinessUnitModel[];

    public get projectBusinessUnits(): ProjectBusinessUnitModel[] {
        return this._projectBusinessUnits;
    }

    @Input()
    public set projectBusinessUnits(value: ProjectBusinessUnitModel[]) {
        this._projectBusinessUnits = value.sort((a: ProjectBusinessUnitModel, b: ProjectBusinessUnitModel) => {
            if (!a.isLeadBusinessUnit && b.isLeadBusinessUnit) {
                return 1;
            } else if (a.isLeadBusinessUnit && !b.isLeadBusinessUnit){
                return -1;
            } else if (a.businessUnit!.name < b.businessUnit!.name){
                return -1;
            } else if (a.businessUnit!.name > b.businessUnit!.name){
                return 1;
            }
            return 0;
        });
    }

    private _regionNames: string[];

    public get regionNames(): string[] {
        if (!this._regionNames) {

            if (this.projectBusinessUnits) {
                const distinctRegionPbus = this.projectBusinessUnits.filter(
                    (pbu: ProjectBusinessUnitModel, i, arr) => arr.findIndex((t) => t.businessUnit!.region.regionId === pbu.businessUnit!.region.regionId) === i
                );
                this._regionNames = distinctRegionPbus
                    .map((r: ProjectBusinessUnitModel) => r.businessUnit!.region.name)
                    .sort();
            }

        }
        return this._regionNames;
    }

    private _divisionNames: string[];

    public get divisionNames(): string[] {
        if (!this._divisionNames) {

            if (this.projectBusinessUnits) {
                const distinctDivisionPbus = this.projectBusinessUnits.filter(
                    (pbu: ProjectBusinessUnitModel, i, arr) => arr.findIndex((t) => t.businessUnit!.division.divisionId === pbu.businessUnit!.division.divisionId) === i

                );
                this._divisionNames = distinctDivisionPbus
                    .map((r: ProjectBusinessUnitModel) => r.businessUnit!.division.name)
                    .sort();
            }

        }
        return this._divisionNames;
    }

    private _programManagementRecords: ProjectInfoModel[];

    public get programManagementRecords(): ProjectInfoModel[] {
        if (!this._programManagementRecords) {
            const allRelationsProjects: ProjectInfoModel[] = [...this.relations.map((r) => r.relatedProject!), ...this.backwardRelations.map((br) => br.project!)];
            this._programManagementRecords = allRelationsProjects.filter((arp) => arp.projectTypeName === HubRecordTypes.ProgramManagement);
        }

        return this._programManagementRecords;
    }

    trackByFn(index) {
        return index;
    }
}
