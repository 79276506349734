import { Component, OnInit, inject, Output, EventEmitter } from '@angular/core';
import { style, animate, transition, trigger, query as q } from '@angular/animations';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';
import { BusinessRoleModel, TeamMemberModel, UserModel } from '../../../../../hub_schema/hubTypes';
import { TeamLovService } from './services/team-lov.service';
import { take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TeamMemberDialogComponent } from './team-member-dialog/team-member-dialog.component';

@Component({
  selector: 'app-team-panel',
  templateUrl: './team-panel.component.html',
  styleUrls: ['./team-panel.component.scss'],
  animations: [
    trigger('list', [
        transition(':enter', [
        ]),
    ]),
    trigger('team-members', [
        transition(':enter', [
            style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
            animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
                style({ transform: 'scale(1)', opacity: 1 }))  // final
        ]),
        transition(':leave', [
            style({ transform: 'scale(1)', opacity: 1, height: '*' }),
            animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
                style({
                    transform: 'scale(0.5)', opacity: 0,
                    height: '0px', margin: '0px'
                }))
        ])
    ]),
    trigger('roles', [
        transition(':enter', [
            style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
            animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
                style({ transform: 'scale(1)', opacity: 1 }))  // final
        ]),
        transition(':leave', [
            style({ transform: 'scale(1)', opacity: 1, height: '*' }),
            animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
                style({
                    transform: 'scale(0.5)', opacity: 0,
                    height: '0px', margin: '0px'
                }))
        ])
    ])
  ]})
export class TeamPanelComponent extends HubRecordEditorBase implements OnInit {
    private teamLovService: TeamLovService = inject(TeamLovService);
    private dialogService: MatDialog = inject(MatDialog);

    @Output()
    public teamPocSelected: EventEmitter<void> = new EventEmitter<void>();

    public businessRoles: BusinessRoleModel[];

    public getFilledBusinessRoles(): BusinessRoleModel[] {
        return this.businessRoles.filter(r => this.getTeamMembersByRole(r.businessRoleId).length);
    }

    public anyTeamPresent(): boolean {
        return this.projectEditForm?.controls.team.value.length > 0;
    }

    public ngOnInit(): void {
        this.teamLovService.getBusinessRoles().pipe(take(1)).subscribe((businessRoles: BusinessRoleModel[]) => {
            this.businessRoles = businessRoles.sort((a, b) => {
                if (a.name == "Lead") {
                    return -1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                else if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
        });
    }

    public isFinanceEditor(user: UserModel): boolean {
        return user.userApplicationRoles?.some(uar => uar.applicationRole?.name === 'Finance Editor') || false;
    }
    public getTeamMembersByRole(roleId: number): TeamMemberModel[] {
        return this.projectEditForm!.controls.team.value.filter(tm => tm.userBusinessRoles[0].businessRoleId === roleId )!;
    }

    public removeTeamMember(teamMember: TeamMemberModel): void {
        const teamMembers = this.projectEditForm!.controls.team.value as TeamMemberModel[];

        for (let i = 0; i < teamMembers.length; i++) {
            if (teamMembers[i] === teamMember) {
                teamMembers.splice(i, 1);
                break;
            }
        }

        this.projectEditForm!.controls.team.markAsDirty();
    }

    public openTeamMemberDialog(existingTeamMember: TeamMemberModel | null): void {
        const dialogRef = this.dialogService.open(TeamMemberDialogComponent, {
            width: '450px',
            data: {
                teamMember: existingTeamMember,
                projectId: this.project.projectId
            }
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((result: any) => {
            if (result) {
                const singleUserRoles = ['Lead', 'Finance Contact', 'Development Contact'];
                const teamControl = this.projectEditForm?.controls.team!;

                if (result.isPoc) {
                    // make every other team member not POC
                    for (let teamMember of teamControl.value) {
                        if (teamMember !== existingTeamMember) {
                            teamMember.isPoc = false;
                        }
                    }
                    // fire an event to tell partners to do the same.
                    this.teamPocSelected.emit();
                }

                if (existingTeamMember) {

                    // if role has changed
                    if (existingTeamMember.userBusinessRoles![0].businessRoleId !== result.role.businessRoleId) {

                        // remove existing team member with this role, if it's a single-member role
                        if (singleUserRoles.includes(result.role.name)) {
                            const team = teamControl.value as TeamMemberModel[];
                            const indexToRemove = team.findIndex(tm => tm !== existingTeamMember && tm.userBusinessRoles![0].businessRoleId === result.role.businessRoleId );

                            if (indexToRemove >= 0) {
                                team.splice(indexToRemove, 1);
                            }                            
                        }
                    }

                    existingTeamMember.isPoc = result.isPoc;

                    existingTeamMember.userBusinessRoles = [{
                        businessRoleId: result.role.businessRoleId,
                        businessRole: result.role ,
                        projectTeamId: result.projectTeamId
                    }];
                }
                else {
                    // remove existing team member with this role, if it's a single-member role
                    if (singleUserRoles.includes(result.role.name)) {
                        const team = teamControl.value as TeamMemberModel[];
                        const indexToRemove = team.findIndex(tm => tm.userBusinessRoles![0].businessRoleId === result.role.businessRoleId );

                        if (indexToRemove >= 0) {
                            team.splice(indexToRemove, 1);
                        }                        
                    }

                    const newTeamMember: TeamMemberModel = {
                        projectId: this.project.projectId!,
                        userId: result.employee.userId,
                        user: result.employee,
                        isPoc: result.isPoc,
                        userBusinessRoles: [
                            {
                                businessRoleId: result.role.businessRoleId,
                                businessRole: result.role,
                                projectTeamId: undefined
                            }
                        ]
                    };

                    teamControl.value.push(newTeamMember);
                }

                this.projectEditForm?.controls.team.markAsDirty();
            }
        });
    }

    public trackByRoles(index: number, role): number {
        return role.businessRoleId;
    }

    public trackByTeamMembers(index: number, teamMember): number {
        return teamMember.user.userId;
    }

}
