import { Component, Input, OnInit, inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ProjectOutcomeModel, AvailableParentProjectOutcomesModel } from '../../../../../../hub_schema/hubTypes';
import { OutcomesEditService } from '../../services/outcomes-edit.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-custom-parent-selection-panel',
  templateUrl: './custom-parent-selection-panel.component.html',
  styleUrls: ['./custom-parent-selection-panel.component.scss']
})
export class CustomParentSelectionPanelComponent implements OnInit {
    private outcomesEditService: OutcomesEditService = inject(OutcomesEditService);

    @Input()
    public projectOutcome: ProjectOutcomeModel;

    @Input()
    public outcomeWizardForm: FormGroup;

    public get parentCustomOutcomeIdControl(): FormControl {
        return (this.outcomeWizardForm.controls.customOutcome as FormGroup).controls.parentCustomOutcomeId as FormControl;
    }

    public availableParents: AvailableParentProjectOutcomesModel[];

    public ngOnInit(): void {
        this.outcomesEditService.getAvailableParentCustomOutcomes(this.projectOutcome.projectId, this.projectOutcome.customOutcome!.customOutcomeId)
            .pipe(take(1)).subscribe((availableParents: AvailableParentProjectOutcomesModel[]) => {
                this.availableParents = availableParents;
            });
    }

    public getParentOutcomeStatement(): string {
        const parentCustomOutcomeId = this.parentCustomOutcomeIdControl.value;

        if (!this.availableParents) {
            return '';
        }

        if (parentCustomOutcomeId) {
            const projectOutcomes = this.availableParents.flatMap(ap => ap.projectOutcomes);
            const projectOutcome = projectOutcomes.find(po => po.customOutcome?.customOutcomeId === parentCustomOutcomeId);
            return projectOutcome?.statement || '';
        }

        return '';
    }


}
