<!-- HIDE IF NO ADDITIONAL ATTRIBUTES TO DISPLAY -->
<div  *ngIf="!!donorKeywords?.length || !!levers?.length || !!(attributes | keyvalue)?.length " class="view-section" id="attributes">
    <h3 class="view-section-header">Additional information</h3>
    <div class="details">

        <!-- DONOR KEYWORDS -->
        <div class="row" *ngIf="donorKeywords.length > 0">
            <div class="col-md-12" >
                <h4 class="text-diesel">Donor Keywords</h4>
                <div>
                    <app-attribute #parent [isDonor]="true" [keywords]="donorKeywords">
                    </app-attribute>
                </div>
            </div>
        </div>

        <!-- Levers -->
        <div class="row mt-2" *ngIf="!isStrategy && !isProgramManagement && !isWholeSystem && !!levers?.length">
            <div class="col-md-12" >
                <h4 class="text-success">Project Levers</h4>
                <div>
                    <app-attribute #parent [keywords]="levers">
                    </app-attribute>
                </div>
            </div>
        </div>

        <!-- Instruments -->
        <div class="row mt-2" *ngIf="!isStrategy && !isProgramManagement && !!(attributes | keyvalue)?.length">
            <div class="col-md-12">
                <h4 class="text-success mb-0">Science-based Instruments</h4>
                <div class="mt-0 mb-0" *ngFor="let item of sortInstruments(attributes | keyvalue)">
                    <h5 class="text-indigo mb-0">{{item.key}}</h5>
                    <app-attribute *ngIf="Array.isArray(item.value)" #parent [title]="item.key" [keywords]="item.value">
                    </app-attribute>

                    <div *ngIf="!Array.isArray(item.value)">
                        <div *ngFor="let obj of item.value | keyvalue">
                            <h6 class="text-success mb-0">{{obj.key}}</h6>
                            <app-attribute #parent [title]="obj.key" [keywords]="obj.value">
                            </app-attribute>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
