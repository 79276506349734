import { Component } from '@angular/core';

@Component({
  selector: 'app-record-status-edit',
  templateUrl: './record-status-edit.component.html',
  styleUrls: ['./record-status-edit.component.scss']
})
export class RecordStatusEditComponent {

}
