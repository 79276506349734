import { Component, Input } from '@angular/core';
import { HubRecordPresenterBase } from '../../../shared/components/_hub_record_presenter_base';
import { ProjectModel } from '../../../../hub_schema/hubTypes';

@Component({
    selector: 'app-location-container',
    templateUrl: './location-container.component.html',
})
export class LocationContainerComponent extends HubRecordPresenterBase {

    @Input() public override project: ProjectModel;

    filterRegions(array) {
        if (array) {
            const flags = [];
            const output = [];
            const l = array.length;
            let i: number;
            for (i = 0; i < l; i++) {
                if (flags[array[i].businessUnit.region.name]) {
                    continue;
                }
                flags[array[i].businessUnit.region.name] = true;
                output.push(array[i].businessUnit.region.name);
            }
            return output;
        }
    }

    filterDivisions(array) {
        if (array) {
            const flags = [];
            const output = [];
            const l = array.length;
            let i: number;
            for (i = 0; i < l; i++) {
                if (flags[array[i].businessUnit.division.name]) {
                    continue;
                }
                flags[array[i].businessUnit.division.name] = true;
                output.push(array[i].businessUnit.division.name);
            }
            return output;
        }
    }
}
