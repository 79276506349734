import { Injectable, inject } from '@angular/core';
import { HttpService } from '../../../../../core/services/http.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { NcsGeographyInterventionModel, NcsInterventionModel } from '../../../../../../hub_schema/hubTypes';

@Injectable()
export class NcsEditService {
    private httpService: HttpService = inject(HttpService);

    public getNcsInterventionDetail(interventionId: number): Observable<NcsInterventionModel> {
        const url = environment.endpoints.base + '/hubLov/ncsIntervention/' + interventionId;
        return this.httpService.get<NcsInterventionModel>(url);
    }    

    public getNcsGeographyInterventions(interventionId: number): Observable<NcsGeographyInterventionModel[]> {
        const url = environment.endpoints.base + '/hubLov/ncsGeographyInterventions/' + interventionId;
        return this.httpService.get<NcsGeographyInterventionModel[]>(url);
    }

    public saveNcsData(intervention: NcsInterventionModel): Observable<NcsInterventionModel>{
        const url = environment.endpoints.base + '/hubLov/ncsIntervention'
        return this.httpService.patch<NcsInterventionModel>(url, intervention);
    }
}
