import { Filter } from './filter';

export class RadioButtonFilter extends Filter {
    public isChecked: boolean;

    public override  isActive() {
        return this.isChecked;
    }
    public override  makeActive(): void {
        this.isChecked = true;
    }
    public override  makeInactive(): void {
        this.isChecked = false;
    }
}
