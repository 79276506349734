import { AbstractControl } from '@angular/forms';

export const getValidationErrorMessages = (formControl: AbstractControl) => {
    const getControlName = (c: AbstractControl): string | null => {
        const formGroup = c.parent.controls;
        return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
    }
    if (!formControl.touched) {
        return '';
    }
    const formErrors = formControl.errors;

    if (formErrors) {
        const errorMessages = [];

        for (const key of Object.keys(formErrors)) {
            if (key === 'required') {
                errorMessages.push(key);
            }
            else if (key === 'pattern') {
                errorMessages.push('Please enter valid ' + getControlName(formControl));
            }
            else if (key === 'min') {
                const controlName = getControlName(formControl);
                let errorMessage = 'You have entered a value that is below the minimum acceptable value.';
                // tslint:disable-next-line: no-small-switch
                switch (controlName) {
                    case 'targetValue':
                        errorMessage = 'Value must be 0 or greater.';
                }
                errorMessages.push(errorMessage);
            }
            else if (key === 'email') {
                errorMessages.push('invalid email');
            }
            else if (key === 'maxlength') {
                const maxLength = formControl.errors[key].requiredLength;
                const actualLength = formControl.errors[key].actualLength;

                errorMessages.push(
                    `Max length of ${maxLength} characters exceeded by ${actualLength - maxLength}.`,
                );
            }
            else {
                errorMessages.push(formErrors[key]);
            }
        }
        return errorMessages.join('<br />');
    }

    return '';
};
