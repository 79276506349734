<div class="details">

    <div class="row">
        <div class="col-md-6">
            <h5 class="hub-level-1-label">Core Projects</h5>

            <div class="hub-pill-list hub-custom-scrollbar relations-list"
                *ngIf="coreRelatedProjects.length || coreProposedProjects.length">
                <span *ngFor="let relation of coreRelatedProjects; trackBy: trackByFn;">
                    <a [routerLink]="'/portfolio/' + relation.relatedProjectId"
                        class="badge-primary badge font-size-lg mr-1 mb-1">
                        <i class="icon-loop3 mr-2"
                            [ngClass]="{'d-none' : !isVerified(relation, backwardRelations, true)}"></i>{{relation.relatedProject?.name}}
                    </a>
                </span>

                <span *ngFor="let relation of coreProposedProjects; trackBy: trackByFn;" class="proposed-relation">
                    <a [routerLink]="'/portfolio/' + relation.projectId"
                        class="badge-primary badge font-size-lg mr-1 mb-1" tooltip="This relation is not confirmed"
                        placement="top">
                        {{relation.project?.name}}
                    </a>
                </span>
            </div>

        <div class="no-relations-warning" *ngIf="!coreRelatedProjects.length && !coreProposedProjects.length">
            This strategy does not have any confirmed core relations to projects.
        </div>


        <h5 class="hub-level-1-label">Additional Projects</h5>

        <div class="hub-pill-list hub-custom-scrollbar relations-list"
            *ngIf="additionalRelatedProjects.length || additionalProposedProjects.length">

            <span *ngFor="let relation of additionalRelatedProjects; trackBy: trackByFn;">
                <a [routerLink]="'/portfolio/' + relation.relatedProject?.projectId"
                    class="badge-primary badge font-size-lg mr-1 mb-1">
                    <i class="icon-loop3 mr-2"
                        [ngClass]="{'d-none' : !isVerified(relation, backwardRelations, false)}"></i>{{relation.relatedProject?.name}}
                </a>
            </span>

            <span *ngFor="let relation of additionalProposedProjects; trackBy: trackByFn;"
                class="proposed-relation">
                <a [routerLink]="'/portfolio/' + relation.project?.projectId"
                    class="badge-primary badge font-size-lg mr-1 mb-1" tooltip="This relation is not confirmed"
                    placement="top">
                    {{relation.project?.name}}
                </a>
            </span>

        </div>

        <div class="no-relations-warning"
            *ngIf="!additionalRelatedProjects.length && !additionalProposedProjects.length">
            This strategy does not have any confirmed additional relations to projects.
        </div>

        <div class="mt-1 text-muted font-size-sm" *ngIf="relations.length">
            <i class="icon-loop3 mr-1 ml-1 text-muted round-rounded"></i> indicates a reciprocal relationship
            between Project and Strategy
        </div>

    </div>

    <div class="col-md-6">

        <h5 class="hub-level-1-label">Region(s)</h5>

            <ul class="hub-bulleted-list">
                <li *ngFor="let regionName of regionNames">
                    {{ regionName }}
                </li>
            </ul>

        <h5 class="hub-level-1-label">Division(s)</h5>

            <ul class="hub-bulleted-list">
                <li *ngFor="let divisionName of divisionNames">
                    {{ divisionName }}
                </li>
            </ul>

        <h5 class="hub-level-1-label">Business Unit(s)</h5>

        <div class="hub-pill-list">
            <span *ngFor="let bu of projectBusinessUnits">
                <a *ngIf="bu.isLeadBusinessUnit">
                    <span
                        class="badge badge-info font-size-lg mr-1 mb-1">{{ bu.businessUnit.name }}</span>
                </a>
                <a *ngIf="!bu.isLeadBusinessUnit">
                    <span class="badge badge-info-secondary font-size-lg mr-1 mb-1">{{ bu.businessUnit.name }}</span>
                </a>
            </span>
        </div>

        <h5 class="hub-level-1-label">Related Program Management</h5>

        <div *ngIf="programManagementRecords">
            <span *ngFor="let pm of programManagementRecords">
                <a [routerLink]="'/portfolio/' + pm.projectId">
                    <span class="badge badge-primary font-size-lg mr-1 mb-1">{{ pm.name }}</span>
                </a>
            </span>
        </div>

        <div *ngIf="programManagementRecords.length === 0" class="no-relations-warning" style="color: #999;">
            A Program Management has not identified this record as part of their portfolio.
        </div>

        <h5 class="hub-level-1-label">Related Whole Systems</h5>

        <div *ngIf="wholeSystemRecords">
            <span *ngFor="let ws of wholeSystemRecords">
                <a [routerLink]="'/portfolio/' + ws.projectId">
                    <span class="badge badge-primary font-size-lg mr-1 mb-1">{{ ws.name }}</span>
                </a>
            </span>
        </div>

        <div *ngIf="wholeSystemRecords.length === 0" class="no-relations-warning" style="color: #999;">
            A Whole System has not identified this record as part of their portfolio.
        </div>


    </div>

</div>
