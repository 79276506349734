import { NgModule } from '@angular/core';
import { RoleGuard } from '../core/guards/role-guard';
import { UserProfileComponent } from './user-profile.component';
import { ChangeUserComponent } from './change-user/change-user.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CollectionCardComponent } from './collection-card/collection-card.component';
import { BulkEditWizardComponent } from './bulk-edit-wizard/bulk-edit-wizard.component';

const routes: Routes = [
  { path: 'user', component: UserProfileComponent, pathMatch: 'full', canActivate: [RoleGuard] },
  { path: 'user/:id', component: UserProfileComponent, pathMatch: 'full', canActivate: [RoleGuard] },
];


@NgModule({
  declarations: [
    UserProfileComponent, ChangeUserComponent, CollectionCardComponent, BulkEditWizardComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ]
})
export class UserProfileModule { }
