import { SearchResultStatistics } from './search-result-statistics';

export class HitPager {
  public numberOfHits: number;
  public hitsPerPage: number;
  public numberOfPages: number;
  public currentPageIndex: number;
  public pages: number[];
  public pagesPerGroup = 10;
  public currentGroupIndex = 0;

  public update(stats: SearchResultStatistics): void {
    this.numberOfHits = stats.numberOfHits;
    this.hitsPerPage = 15;
    this.numberOfPages = Math.ceil(this.numberOfHits / this.hitsPerPage);
    this.currentPageIndex = 0;
    this.currentGroupIndex = 0;
    this.pages = [];

    for (let i = 0; i < this.numberOfPages; i++) {
      this.pages.push(i);
    }
  }

  public getPageBounds() {
    const startIndex = this.currentPageIndex * this.hitsPerPage;
    let endIndex = startIndex + this.hitsPerPage;

    if (endIndex > this.numberOfHits - 1) {
      endIndex = this.numberOfHits;
    }

    return {
      start: startIndex,
      end: endIndex
    };
  }
}
