import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { OutcomesEditService } from '../../services/outcomes-edit.service';
import { ErrorService } from '../../../../../core/services/error.service';
import { OutcomeTargetModel, ProjectOutcomeModel } from '../../../../../../hub_schema/hubTypes';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { take } from 'rxjs';

export enum helperSelectorType {
    attribution = 'attribution',
    date = 'date',
    description = 'description',
    value = 'value',
    units = 'units',
}

@Component({
  selector: 'app-add-edit-project-outcome-target',
  templateUrl: './add-edit-project-outcome-target.component.html',
  styleUrls: ['./add-edit-project-outcome-target.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class AddEditProjectOutcomeTargetComponent implements OnInit, AfterViewChecked {
    // dependencies
    private outcomesEditService: OutcomesEditService = inject(OutcomesEditService);
    private dialogService: MatDialog = inject(MatDialog);
    private dialogRef: MatDialogRef<AddEditProjectOutcomeTargetComponent> = inject(MatDialogRef<AddEditProjectOutcomeTargetComponent>);
    private errorService: ErrorService = inject(ErrorService);
    private data: any = inject(MAT_DIALOG_DATA);
    private changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

    private existingTarget: OutcomeTargetModel;
    private projectOutcome: ProjectOutcomeModel;
    public editTargetForm: FormGroup;
    
    public isSaving: boolean = false;

    public ngOnInit(): void {
        this.existingTarget = this.data.existingTarget;
        this.projectOutcome = this.data.projectOutcome;

        this.editTargetForm = new FormGroup({
            outcomeTargetId: new FormControl({
                value: this.existingTarget ? this.existingTarget.outcomeTargetId : undefined, 
                disabled: !this.existingTarget}),
            projectOutcomeId: new FormControl(this.projectOutcome.projectOutcomeId),
            targetDate: new FormControl(this.existingTarget ? this.existingTarget.targetDate : null, [Validators.required, this.validateTargetDate.bind(this)]),
            targetValue: new FormControl(this.existingTarget ? this.existingTarget.targetValue : null, Validators.required)
        });              
    }

    // This just prevents the ExpressionChangedAfterItHasBeenCheckedError console error 
    public ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    private validateTargetDate(targetDateControl: FormControl): ValidationErrors | null {
        if (targetDateControl.pristine) {
          return null;
        }
        
        const targetDate = targetDateControl.value as Date;

        // validation rule - targets must be after existing starts
        const start = this.projectOutcome.progress?.find(p => p.isStart);
        if (start) {
            if (targetDate.getTime() < start.progressDate.getTime()) {
                return {
                    'invalid': 'The date entered is before the Outcome start date.'
                };
            }
        }
        
        return null;
      }
    
    public cancelDialog(): void {
        this.dialogRef.close();
    }

    public saveTarget(): void {
        this.isSaving = true;
        const target: OutcomeTargetModel = this.editTargetForm.value;

        if (!this.existingTarget) {
            this.outcomesEditService.createTarget(target).pipe(take(1)).subscribe({
                next: (savedTarget) => {
                    this.dialogRef.close(savedTarget);
                },
                error: (err) => {
                    this.errorService.addError(err, true);
                    this.dialogRef.close();
                }
            });
        }
        else {
            this.outcomesEditService.updateTarget(target).pipe(take(1)).subscribe({
                next: (savedTarget: OutcomeTargetModel) => {
                    this.dialogRef.close(savedTarget);
                },
                error: (err) => {
                    this.errorService.addError(err, true);
                    this.dialogRef.close();
                }
            });
        }
    }

    // #region NCS

    private isNcsRecalculationPending: boolean = false;

    public pendingNcsRecalculationChanged(isRecalcPending: boolean): void {
        this.isNcsRecalculationPending = isRecalcPending;
    }

    public isSaveDisabled() {
        return this.editTargetForm.invalid || this.isSaving || this.isNcsRecalculationPending;
    }

    // #endregion
}
