import { Component, Input, OnInit, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NcsInterventionModel, NcsPathwayModel } from '../../../../../../../../hub_schema/hubTypes';
import { HubLovService } from '../../../../../../../core/services/hub-lov.service';
import { take } from 'rxjs';
import { CreateOutcomeStateService } from '../../../services/create-outcome-state.service';
import { OutcomeState } from '../../../types/outcome-state';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-ncs-intervention-panel',
  templateUrl: './ncs-intervention-panel.component.html',
  styleUrls: ['./ncs-intervention-panel.component.scss']
})
export class NcsInterventionPanelComponent implements OnInit {
    private hubLovService: HubLovService = inject(HubLovService);
    private createOutcomeStateService: CreateOutcomeStateService = inject(CreateOutcomeStateService);
    public outcomeState: OutcomeState;

    public get outcomeForm(): FormGroup {
        return this.outcomeState.outcomeWizardForm!.controls.outcome as FormGroup;
    }

    public get interventionPlaceholderText(): string {
        if (!this.outcomeState.selectedNcsPathway) {
            return 'Select an NCS Pathway to enable this dropdown'
        }
        return 'Select an Intervention Type';
    }

    public ncsPathways: NcsPathwayModel[];

    // public selectedNcsPathway: NcsPathwayModel | undefined;

    public ngOnInit(): void {
        this.outcomeState = this.createOutcomeStateService.outcomeState;

        this.hubLovService.getNcsPathways().pipe(take(1)).subscribe((ncsPathways: NcsPathwayModel[]) => {
            this.ncsPathways = ncsPathways;
        });
    }

    public pathwaySelectionChanged(e: MatSelectChange): void {
        this.outcomeState.selectedNcsPathway = this.ncsPathways.find(p => p.pathwayId === e.value);
    }

    public getNcsInterventionErrorMessage(): string {
        const formControl = this.outcomeForm.controls.ncsInterventionId;

        if (formControl?.touched && formControl?.errors) {
            return 'required';
        }
        
        return '';
    }
}
