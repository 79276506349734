<div class="output">
    <div class="row">
        <div class="col-md-12">
            <div class="number">
                <span class="btn bg-indigo-300 rounded-round btn-icon btn-sm legitRipple mr-3"><span
                        class="letter-icon">{{outputIndex + 1}}</span></span>
            </div>
            <div class="title">
                <h5 class="text-indigo"><span class="font-weight-bold">Output - </span> {{output?.statement}}</h5>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-7">
          <p class="pl-5" *ngIf="output?.lead?.fullName">Owned by: <a [routerLink]="'/user/' + output?.lead?.userId"
                         class="font-weight-bold">{{output?.lead?.fullName}}</a></p>
            <p class="pl-5" *ngIf="output?.due">Due by: <strong style="font-weight: bold;">{{output.due | date: 'M/d/yyyy'}}</strong>
            </p>
        </div>
        <div class="col-md-5">
            <div *ngIf="output.outputStatistics.hasProgress">
                <div class="d-flex flex-nowrap pr-4" style="justify-content: flex-start">
                    <span class="font-weight-bold" style="flex: 1 1 50%">Latest status</span>

                    <label class="hub-checkbox-container" style="flex: 1 1 50%;">
                        <input type="checkbox" [(ngModel)]="showDetails" />
                        <span class="hub-checkmark"></span>
                        <span>Show Details</span>
                    </label>

                </div>
                <p class="element-line record-description">On <span
                        class="text-indigo">{{output.outputStatistics.latestProgressDate | date}}</span>, <span
                        class="text-indigo">{{output.outputStatistics.latestProgressPercent}}%</span> complete with status
                    <span
                        [ngClass]="[getProgressStatusClass(output.outputStatistics.latestProgressStatus)]">{{output.outputStatistics.latestProgressStatus}}</span>.
                </p>
            </div>
            <p class="text-warning" *ngIf="!output.outputStatistics.hasProgress">No progress reported (yet).</p>
        </div>
    </div>

    <!-- OUTPUT PROGRESS REPORTS TABLE -->

    <div class="row pl-5 pr-3" *ngIf="showDetails">
        <div class="col-md-12">
            <h5 class="text-success">Output Progress Reports <span
                    class="text-muted">({{output.outputProgress?.length}})</span></h5>
            <div class="table-responsive mb-2 mr-3">
                <table class="table table-bordered table-border-dashed metric-table">
                    <thead>
                        <tr class="table-border-double">
                            <th width="20%" class="text-right">Date reported</th>
                            <th width="30%">Output Status</th>
                            <th>Progress report</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let progressRecord of output.outputProgress; let i = index">
                        <tr>
                            <td class="text-right">
                                <span>{{progressRecord.progressDate | date}}</span>
                            </td>
                            <td>
                                <span class="d-block">{{progressRecord.progress.label}} complete</span>
                                <span class="d-block"
                                    [ngClass]="[getProgressStatusClass(progressRecord.status.name)]">{{progressRecord.status.name}}</span>
                            </td>
                            <td>
                                <p class="record-description">{{progressRecord.description}}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!-- OUTPUT LINKS -->

    <div class="row pl-5 pr-3" *ngIf="output?.outputLinks?.length > 0">
        <div class="col-md-12">
            <h5 class="text-success">Output Links <span class="text-muted">({{output?.outputLinks.length}})</span></h5>
            <ul class="media-list">
                <li class="media" *ngFor="let link of output.outputLinks">
                    <div class="media-body">
                        <a [href]="link.url" target="_blank">{{link.name}}</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
