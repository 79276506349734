import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddProjectToCollectionDialogComponent } from '../add-project-to-collection-dialog/add-project-to-collection-dialog.component';
import { ProjectModel } from 'src/hub_schema/hubTypes';

@Component({
  selector: 'app-add-project-to-collection-button',
  templateUrl: './add-project-to-collection-button.component.html',
  styleUrls: ['./add-project-to-collection-button.component.scss']
})
export class AddProjectToCollectionButtonComponent {
    
    constructor(private dialogService: MatDialog) {}

    @Input()
    public projectId: number;

    @Input()
    public projectName: string;

    @Input()
    public isButton: boolean = false;

    public openAddProjectToCollectionDialog(): void {
        const dialogRef = this.dialogService.open(AddProjectToCollectionDialogComponent, {
            width: '800px',
            data: {
                projectId: this.projectId,
                projectName: this.projectName
            }
        });
    }


}
