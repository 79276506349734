import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titleCaseAbbreviation',
})
export class TitlecaseAbbreviationFriendlyPipe extends TitleCasePipe implements PipeTransform {

    public override transform(value: string): any {
        let isAbbreviation = true;
        ('' + value).split('').forEach((character) => {
            if (character !== character.toUpperCase()) {
                isAbbreviation = false;
            }
        });
        return isAbbreviation ? value : super.transform(value);
    }
}
