<div class="project-edit-full-size" aria-hidden="false" tabindex="0">
    <div style="display: flex; flex-direction: row;">
        <div class="left-side project-edit-left-side hub-custom-scrollbar" style="height: calc(100vh - 257px); overflow-y: auto; overflow-x: hidden;">
            <div class="d-md-flex justify-content-between p-2 pl-3">

                <h5 class="text-success">
                    Strategic Pillars ({{project?.projectStrategicPillars?.length}})
                    <app-help-selector [selector]="1185"></app-help-selector>
                </h5>

                <button type="button" class="btn-outline bg-primary-300 text-primary btn-icon btn rounded-round ml-2"
                        (click)="openStrategicPillarCreateDialog()" tabindex="0">
                    <i class="icon-plus-circle2 mr-2"></i> Create Pillar
                </button>

            </div>

            <div class="p-2 pl-3">
                <div *ngIf="project.projectStrategicPillars?.length === 0" class="d-block alert alert-info alert-styled-left shadow-0">
                    There are no strategic pillars defined yet.
                </div>
            </div>

            <ul class="section-list left ml-4" id="lstResults">

                <app-strategic-pillar-list-item *ngFor="let strategicPillar of project.projectStrategicPillars"
                                                [strategicPillar]="strategicPillar" (click)="selectStrategicPillar(strategicPillar)"
                                                (deleteStrategicPillarRequested)="deleteStrategicPillarRequested(strategicPillar)"
                                                [isSelected]="strategicPillar === selectedStrategicPillar">
                </app-strategic-pillar-list-item>

            </ul>
        </div>

        <div class="right-side" style="flex: 1 1 50%; background-color: #f6f6f6;" *ngIf="selectedStrategicPillar">
            <app-strategic-pillar-detail [selectedStrategicPillar]="selectedStrategicPillar" (strategicPillarChanged)="strategicPillarChanged($event)"></app-strategic-pillar-detail>
        </div>
    </div>

</div>
