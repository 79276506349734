<div class="hub-close-page-modal">
    <div class="content">
        <div class="unsaved-changes-dialog-container">
            <div class="dialog-icon">!</div>

            <div class="dialog-content">
                <div class="dialog-header">
                    <div class="title">
                        Are you sure?
                    </div>
                </div>

                <div class="dialog-body hub-custom-scrollbar">
                    You have unsaved changes, do you want to Save?
                </div>

                <div class="dialog-footer">
                    <button (click)="cancel()" class="btn cancel-button">Cancel</button>

                    <button (click)="closeWithoutSaving()" class="btn">
                        Close without Saving
                    </button>

                    <button class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
                        [disabled]="isSaving || isSaveProjectDisabled()" (click)="saveProject()"
                        [matTooltip]="getSaveButtonTooltip()" matTooltipShowDelay="500"
                        >
                    <b>
                        <i class="icon-floppy-disk"></i>
                        <i class="icon-spinner4"></i>
                    </b>
                    Save Now
                </button>
        

                </div>
            </div>
        </div>
    </div>

</div>