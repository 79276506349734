import { Injectable } from '@angular/core';
import { ApplicationRoleType } from '../../../../shared/types/role';
import { AuthService } from '../auth.service';
import { ProjectModel } from '../../../../../hub_schema/hubTypes';

@Injectable({
    providedIn: 'root'
})
export class GraphqlAuthorizationService {

    constructor(private authService: AuthService) { }

    public isAllowedToEdit(project: ProjectModel): boolean {

        // todo (ACE 12/1/2021).  This method is sometimes passed a project graph type, sometimes a Project Info.

        // Are they on this project's team?
        const userId = parseInt(this.authService.getClaimDataValue('UserId'), 10);

        // are they an IT Admin or Business Administrator
        if (this.authService.userIsAdmin()) {
            return true;
        }

        // User is a member of the Project Team
        if (project.team && project.team.length && project.team.find((tm) => tm.user.userId === userId)) {
            return true;
        }

        const roles = this.authService.getUserRoles();
        const businessUnits = project.projectBusinessUnits 
            ? project.projectBusinessUnits.map(pbu => pbu.businessUnit) 
            : project.projectBusinessUnits.map(pbu => pbu.businessUnit);

        if (roles.includes(ApplicationRoleType.BusinessUnitEditor)) {

            // same BU?
            const authorizedBuIds = this.authService.getClaimDataValue('BuEditorFor');
            for (let buId of authorizedBuIds) {
                if (businessUnits.some(bu => bu.businessUnitId === parseInt(buId))) {
                    return true;
                }
            }
        }

        if (roles.includes(ApplicationRoleType.RegionEditor)) {

            // Same Region:
            const userRegionId = parseInt(this.authService.getClaimDataValue('RegionId'), 10);
            if (businessUnits.some((bu) => bu.region.regionId === userRegionId)) {
                return true;
            }
        }

        if (roles.includes(ApplicationRoleType.DivisionEditor)) {

            // same division?
            const userDivisionId = parseInt(this.authService.getClaimDataValue('DivisionId'), 10);
            if (businessUnits.some((bu) => bu.division.divisionId === userDivisionId)) {
                return true;
            }
        }
    }
}
