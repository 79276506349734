import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Breadcrumb } from '../../../shared/types/algolia-api/breadcrumb';
import { CheckboxFilter } from '../../../shared/types/algolia-api/filter-checkbox';
import { Outcome2030GoalsMetricFacet } from '../../types/outcome-metric-facet';

@Component({
    selector: 'app-outcome-2030-goals-metric-facet-display',
    templateUrl: './outcome-2030-goals-metric-facet-display.component.html',
    styleUrls: ['./outcome-2030-goals-metric-facet-display.component.scss']
})
export class Outcome2030GoalsMetricFacetDisplayComponent {

    @Input()
    public facet: Outcome2030GoalsMetricFacet;

    @Output()
    public filterChanged: EventEmitter<Breadcrumb> = new EventEmitter<Breadcrumb>();

    constructor() { }

    public userChangedMetricSelection(subArea, metric): void {
        const matchingFilter = this.facet.filters.find((f) => f.title === subArea.name + ' > ' + metric.name) as CheckboxFilter;
        matchingFilter.isChecked = metric.isChecked;
        const breadcrumb = new Breadcrumb(this.facet, metric);
        this.filterChanged.emit(breadcrumb);
    }

    public getNumberOfHits(subArea, metric): number {
        const matchingFilter = this.facet.filters.find((f) => f.title === subArea.name + ' > ' + metric.name) as CheckboxFilter;

        if (matchingFilter) {
            return matchingFilter.numberOfHits;
        }
        return 0;
    }
}
