<div class="mb-2 p-1 sb-b body" role="tabpanel">
    <div class="row">
        <div class="col-md-12 clearfix">
            <span class="select-label text-info-800 clearfix">
                Select an Outcome Type
                <app-help-selector selector="1190"></app-help-selector></span>
            <div class="metric-type-panel">
                <button class="title" (click)="goals2030OutcomeTypeClicked($event)">2030 Goals Outcome</button>
                <br />
                <button class="title" (click)="customOutcomeTypeClicked($event)">Custom Outcome</button>
            </div>
        </div>
    </div>
</div>
