import { Outcome2030GoalsMetricFacet } from "../../../portfolio/types/outcome-metric-facet";
import { Facet } from "./facet";
import { Filter } from "./filter";

export class Breadcrumb {
    public title: string;
    public facet: Facet;
    public filter: Filter;

    constructor(facet: Facet, filter: Filter) {
        this.facet = facet;
        this.filter = filter;

        if (facet.isYesNoFacet) {
            this.title = this.facet.title + ': ' + filter.title;
        }
        else if (facet instanceof Outcome2030GoalsMetricFacet) {
            this.title = (filter as any).name;
        }
        else {
            this.title = filter.title;
        }
    }
}
