import { Injectable, inject } from '@angular/core';
import { HttpService } from '../../../../core/services/http.service';
import { Observable, of } from 'rxjs';
import { ProjectInfoModel } from '../../../../../hub_schema/hubTypes';
import { environment } from '../../../../../environments/environment';

enum projectType {
    strategy = 1000,
    project = 1001,
    program = 1002,
    wholeSystem = 1003
}

@Injectable()
export class RelationsTypeaheadService {
    private httpService: HttpService = inject(HttpService);

    public getStrategiesByNamePrefix(prefix: string): Observable<ProjectInfoModel[]> {
        if (prefix.length < 1) {
            return of([]);
        }

        const url = environment.endpoints.base + `/projects/search/${prefix}/${projectType.strategy}`;
        return this.httpService.get<ProjectInfoModel[]>(url);
    }

    public getProjectsByNamePrefix(prefix: string): Observable<ProjectInfoModel[]> {
        if (prefix.length < 1) {
            return of([]);
        }

        const url = environment.endpoints.base + `/projects/search/${prefix}/${projectType.project}`
        return this.httpService.get<ProjectInfoModel[]>(url);
    }

    public getWholeSystemsByNamePrefix(prefix: string): Observable<ProjectInfoModel[]> {
        if (prefix.length < 1) {
            return of([]);
        }

        const url = environment.endpoints.base + `/projects/search/${prefix}/${projectType.wholeSystem}`
        return this.httpService.get<ProjectInfoModel[]>(url);
    }

    public getProgramsByNamePrefix(prefix: string): Observable<ProjectInfoModel[]> {
        if (prefix.length < 1) {
            return of([]);
        }

        const url = environment.endpoints.base + `/projects/search/${prefix}/${projectType.program}`
        return this.httpService.get<ProjectInfoModel[]>(url);
    }

}
