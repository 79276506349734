import { Component, Input, inject } from '@angular/core';
import { progressStatusCssClasses } from 'src/app/shared/types/output-progress-statuses';
import { OutputDetailsModel, OutputProgressModel, ProjectOutputModel } from '../../../../../hub_schema/hubTypes';
import { OutputDetailService } from '../../../../core/services/output-detail.service';
import { take } from 'rxjs';

@Component({
    selector: 'app-output',
    templateUrl: './output.component.html',
    styleUrls: ['./output.component.scss']
})
export class OutputComponent {
    private outputDetailService: OutputDetailService = inject(OutputDetailService);

    @Input()
    public output: ProjectOutputModel;

    @Input()
    public outputIndex: number;

    private _showDetails: boolean = false;
    
    public get showDetails() {
        return this._showDetails;
    }
    
    public set showDetails(value: boolean) {
        this._showDetails = value;

        // selecting a project Output will fetch its details if they're not already present
        // null means not fetched yet, empty means fetched, but none present
        if (this.output.outputLinks == null || this.output.outputProgress == null) {
            this.outputDetailService.getProjectOutputDetails(this.output.projectOutputId!)
                .pipe(take(1)).subscribe((outputDetails: OutputDetailsModel) => {
                    this.output.outputLinks = outputDetails.links;
                    this.output!.outputProgress = outputDetails.progress;
                });
        }
    }

    public getProgressStatusClass(status: string): string {
        return progressStatusCssClasses[status.toLowerCase()] ? progressStatusCssClasses[status.toLowerCase()]
            : progressStatusCssClasses.completed;
    }
}
