import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { IntermediateResultDetailsModel } from '../../../../../hub_schema/hubTypes';
import { HttpService } from '../../../../core/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class IntermediateResultDetailService {

    constructor(private httpService: HttpService) { }

    public getIntermediateResultDetails(intermediateResultId: number, isStrategy: boolean): Observable<IntermediateResultDetailsModel> {
        const url = environment.endpoints.base + `/intermediateResults/intermediateResultDetails/${intermediateResultId}/${isStrategy}`;
        return this.httpService.get<IntermediateResultDetailsModel>(url);
    }
}
