import { Injectable, inject } from '@angular/core';
import { HttpService } from './http.service';
import { AncillaryTypeModel, AttributionLevelModel, ApplicationRoleModel, BusinessRoleModel, BusinessUnitModel, ElementTypeModel, FinanceMetricModel, GeographyModel, LeverModel, NcsInterventionModel, NcsPathwayModel, OutcomeListModel, OutcomeListOptionModel, OutcomeListOptionSubListModel, OutcomeMetricModel, OutputProgressValueModel, OutputUpdateStatusModel, PartnerCategoryModel, PartnerModel, PartnerRoleModel, ProjectTypeModel, ScaleMeasureItemModel, ScaleMeasureModel, SpatialExtentGroupModel, SpatialExtentModel, StatusModel, RecordCollectionTypeModel } from '../../../hub_schema/hubTypes';
import { Observable, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class HubLovService {
    private httpService: HttpService = inject(HttpService);

    private ancillaryTypes: AncillaryTypeModel[];
    private attributionLevels: AttributionLevelModel[];
    private applicationRoles: ApplicationRoleModel[];
    private scaleMeasureItems: ScaleMeasureItemModel[];
    private statuses: StatusModel[];
    private businessRoles: BusinessRoleModel[];
    private financeMetrics: FinanceMetricModel[];
    private businessUnits: BusinessUnitModel[];
    private projectTypes: ProjectTypeModel[];
    private elementTypes: ElementTypeModel[];
    private levers: LeverModel[];
    private outcomeMetrics: OutcomeMetricModel[];
    private scaOutcomeLists: OutcomeListModel[];
    private scaOutcomeListOptions: OutcomeListOptionModel[];
    private scaOutcomeListOptionSubLists: OutcomeListOptionSubListModel[];
    private scaOrganizationalMetrics: OutcomeMetricModel[];
    private countries: GeographyModel[];
    private states: GeographyModel[];
    private spatialExtents: SpatialExtentModel[];
    private spatialExtentGroups: SpatialExtentGroupModel[];
    private partners: PartnerModel[];
    private partnerCategories: PartnerCategoryModel[];
    private partnerRoles: PartnerRoleModel[];
    private outputUpdateStatuses: OutputUpdateStatusModel[];
    private outputProgressValues: OutputProgressValueModel[];
    private ncsPathways: NcsPathwayModel[];
    private ncsInterventions: NcsInterventionModel[];
    private projectCollectionTypes: RecordCollectionTypeModel[];
    
    public getAncillaryTypes(): Observable<AncillaryTypeModel[]> {
        if (this.ancillaryTypes) {
            return of(this.ancillaryTypes);
        }

        const url = environment.endpoints.base + '/hubLOV/ancillaryTypes';

        return this.httpService.get<AncillaryTypeModel[]>(url).pipe(tap((ancillaryTypes: AncillaryTypeModel[]) => {
            this.ancillaryTypes = ancillaryTypes;
        }));
    }

    public getAttributionLevels(): Observable<AttributionLevelModel[]> {
        if (this.attributionLevels) {
            return of(this.attributionLevels);
        }

        const url = environment.endpoints.base + '/hubLOV/attributionLevels';

        return this.httpService.get<AttributionLevelModel[]>(url).pipe(tap((attributionLevels) => {
            this.attributionLevels = attributionLevels;
        }));
    }

    public getApplicationRoles(): Observable<ApplicationRoleModel[]> {
        if (this.applicationRoles) {
            return of(this.applicationRoles);
        }
        const url = environment.endpoints.base + '/hubLOV/applicationRoles';

        return this.httpService.get<ApplicationRoleModel[]>(url).pipe(tap((applicationRoles: ApplicationRoleModel[]) => {
            this.applicationRoles = applicationRoles;
        }));
    }

    public getBusinessRoles(): Observable<BusinessRoleModel[]> {
        if (this.businessRoles) {
            return of (this.businessRoles);
        }

        const url = environment.endpoints.base + '/hubLOV/businessRoles';

        return this.httpService.get<BusinessRoleModel[]>(url).pipe(tap((businessRoles) => {
            this.businessRoles = businessRoles;
        }));
    }

    public getFinanceMetrics(): Observable<FinanceMetricModel[]> {
        if (this.financeMetrics) {
            return of(this.financeMetrics);
        }

        const url = environment.endpoints.base + '/hubLOV/financeMetrics';

        return this.httpService.get<FinanceMetricModel[]>(url).pipe(tap((financeMetrics) => {
            this.financeMetrics = financeMetrics;
        }));
    }

    public getElementTypes(): Observable<ElementTypeModel[]> {
        if (this.elementTypes) {
            return of(this.elementTypes);
        }
        const url = environment.endpoints.base + '/hubLOV/elementTypes';

        return this.httpService.get<ElementTypeModel[]>(url).pipe(tap((elementTypes: ElementTypeModel[]) => {
            this.elementTypes = elementTypes;
        }));
    }

    public getLevers(): Observable<LeverModel[]> {
        if (this.levers) {
            return of (this.levers);
        }
        const url = environment.endpoints.base + '/hubLov/levers';

        return this.httpService.get<LeverModel[]>(url).pipe(tap((levers: LeverModel[]) => {
            this.levers = levers;
        }));
    }

    public getOutcomeMetrics(): Observable<OutcomeMetricModel[]> {
        if (this.outcomeMetrics) {
            return of(this.outcomeMetrics);
        }
        const url = environment.endpoints.base + '/hubLOV/outcomeMetrics';

        return this.httpService.get<OutcomeMetricModel[]>(url).pipe(tap((outcomeMetrics: OutcomeMetricModel[]) => {
            this.outcomeMetrics = outcomeMetrics;
        }));
    }

    public getScaOutcomeLists(): Observable<OutcomeListModel[]> {
        if (this.scaOutcomeLists) {
            return of(this.scaOutcomeLists);
        }
        const url = environment.endpoints.base + '/hubLOV/scaOutcomeLists';

        return this.httpService.get<OutcomeListModel[]>(url).pipe(tap((scaOutcomeLists: OutcomeListModel[]) => {
            this.scaOutcomeLists = scaOutcomeLists;
        }));
    }

    public getScaOutcomeListOptions(): Observable<OutcomeListOptionModel[]> {
        if (this.scaOutcomeListOptions) {
            return of(this.scaOutcomeListOptions);
        }
        const url = environment.endpoints.base + '/hubLOV/scaOutcomeListOptions';

        return this.httpService.get<OutcomeListOptionModel[]>(url).pipe(tap((listOptions: OutcomeListOptionModel[]) => {
            this.scaOutcomeListOptions = listOptions;
        }));
    }

    public getScaOutcomeListOptionSubLists(): Observable<OutcomeListOptionSubListModel[]> {
        if (this.scaOutcomeListOptionSubLists) {
            return of(this.scaOutcomeListOptionSubLists);
        }
        const url = environment.endpoints.base + '/hubLOV/scaOutcomeListOptionSubLists';

        return this.httpService.get<OutcomeListOptionSubListModel[]>(url).pipe(tap((sublists: OutcomeListOptionSubListModel[]) => {
            this.scaOutcomeListOptionSubLists = sublists;
        }));
    }

    public getScaOrganizationalMetrics(): Observable<OutcomeMetricModel[]> {
        const url = environment.endpoints.base + '/HubLOV/scaOrganizationalMetrics';

        if (this.scaOrganizationalMetrics) {
            return of(this.scaOrganizationalMetrics);
        }
        else {
            return this.httpService.get<OutcomeMetricModel[]>(url).pipe(
                tap((organizationalMetrics: OutcomeMetricModel[]) => {
                    this.scaOrganizationalMetrics = organizationalMetrics;
                })
            );
        }
    }

    public getStatuses(): Observable<StatusModel[]> {
        if (this.statuses) {
            return of(this.statuses);
        }
        const url = environment.endpoints.base + '/hubLOV/projectStatuses';
        return this.httpService.get<StatusModel[]>(url).pipe(tap({
            next: (statuses: StatusModel[]) => {
                this.statuses = statuses;
            }
        }));
    }

    public getBusinessUnits(): Observable<BusinessUnitModel[]> {
        if (this.businessUnits) {
            return of(this.businessUnits);
        }
        const url = environment.endpoints.base + '/hubLOV/businessUnits';

        return this.httpService.get<BusinessUnitModel[]>(url).pipe(tap({
            next: (businessUnits: BusinessUnitModel[]) => {
                this.businessUnits = businessUnits;
            }
        }));
    }

    public getProjectTypes(): Observable<ProjectTypeModel[]> {
        if (this.projectTypes) {
            return of(this.projectTypes);
        }
        const url = environment.endpoints.base + '/hubLOV/projectTypes';

        return this.httpService.get<ProjectTypeModel[]>(url).pipe(tap((projectTypes: ProjectTypeModel[]) => {
            this.projectTypes = projectTypes;
        }));
    }

    public getScaleMeasureItems(): Observable<ScaleMeasureItemModel[]> {

        if (this.scaleMeasureItems) {
            return of(this.scaleMeasureItems);
        }

        const url = environment.endpoints.base + '/hubLOV/scaleMeasureItems';

        return this.httpService.get<ScaleMeasureItemModel[]>(url).pipe(tap((scaleMeasureItems: ScaleMeasureItemModel[]) => {
            this.scaleMeasureItems = scaleMeasureItems;
        }));
    }

    public getCountries(): Observable<GeographyModel[]> {
        if (this.countries) {
            return of(this.countries);
        }
        const url = environment.endpoints.base + '/hubLOV/countries';

        return this.httpService.get<GeographyModel[]>(url).pipe(tap((countries: GeographyModel[]) => {
            this.countries = countries;
        }));
    }

    public getCountry(countryId): Observable<GeographyModel> {
        if (this.countries) {
            const country = this.countries.find(c => c.geographyId === countryId);
            return of(country);
        }
        const url = environment.endpoints.base + '/hubLOV/countries/' + countryId;

        return this.httpService.get<GeographyModel>(url);

    }

    public getSpatialExtents(): Observable<SpatialExtentModel[]> {
        if (this.spatialExtents) {
            return of(this.spatialExtents);
        }
        const url = environment.endpoints.base + '/hubLOV/spatialExtents';

        return this.httpService.get<SpatialExtentModel[]>(url).pipe(tap((spatialExtents: SpatialExtentModel[]) => {
            this.spatialExtents = spatialExtents;
        }));
    }

    public getSpatialExtentGroups(): Observable<SpatialExtentGroupModel[]> {
        if (this.spatialExtentGroups) {
            return of(this.spatialExtentGroups);
        }
        const url = environment.endpoints.base + '/hubLOV/spatialExtentGroups';

        return this.httpService.get<SpatialExtentGroupModel[]>(url).pipe(tap((spatialExtentGroups: SpatialExtentGroupModel[]) => {
            this.spatialExtentGroups = spatialExtentGroups;
        }));
    }

    public getStates(): Observable<GeographyModel[]> {
        if (this.states) {
            return of(this.states);
        }
        const url = environment.endpoints.base + '/hubLOV/states';

        return this.httpService.get<GeographyModel[]>(url).pipe(tap((states: GeographyModel[]) => {
            this.states = states;
        }));
    }

    public getPartners(): Observable<PartnerModel[]> {
        if (this.partners) {
            return of(this.partners);
        }

        const url = environment.endpoints.base + '/hubLov/partners';

        return this.httpService.get<PartnerModel[]>(url).pipe(tap((partners: PartnerModel[]) => {
            this.partners = partners;
        }));
    }

    public getPartnerCategories(): Observable<PartnerCategoryModel[]> {
        if (this.partnerCategories) {
            return of(this.partnerCategories);
        }

        const url = environment.endpoints.base + '/hubLov/partnerCategories';

        return this.httpService.get<PartnerCategoryModel[]>(url).pipe(tap((partnerCategories: PartnerCategoryModel[]) => {
            this.partnerCategories = partnerCategories;
        }))
    }

    public getPartnerRoles(): Observable<PartnerRoleModel[]> {
        if (this.partnerRoles) {
            return of(this.partnerRoles);
        }
        const url = environment.endpoints.base + '/hubLov/partnerRoles';

        return this.httpService.get<PartnerRoleModel[]>(url).pipe(tap((partnerRoles: PartnerRoleModel[]) => {
            this.partnerRoles = partnerRoles;
        }));
    }

    public getOutputProgressValues(): Observable<OutputProgressValueModel[]> {
        if (this.outputProgressValues) {
            return of(this.outputProgressValues);
        }
        const url = environment.endpoints.base + '/hubLOV/outputProgressValues';

        return this.httpService.get<OutputProgressValueModel[]>(url).pipe(tap((outputProgressValues: OutputProgressValueModel[]) => {
            this.ancillaryTypes,outputProgressValues = outputProgressValues;
        }));
    }

    public getOutputUpdateStatuses(): Observable<OutputUpdateStatusModel[]> {
        if (this.outputUpdateStatuses) {
            return of(this.outputUpdateStatuses);
        }
        const url = environment.endpoints.base + '/hubLOV/outputUpdateStatuses';

        return this.httpService.get<OutputUpdateStatusModel[]>(url).pipe(tap((outputUpdateStatuses: OutputUpdateStatusModel[]) => {
            this.outputUpdateStatuses = outputUpdateStatuses;
        }));
    }

    public getNcsPathways(): Observable<NcsPathwayModel[]> {
        if (this.ncsPathways) {
            return of(this.ncsPathways);
        }
        
        const url =  environment.endpoints.base + '/hubLov/ncsPathways';
        return this.httpService.get<NcsPathwayModel[]>(url).pipe(tap((ncsPathways: NcsPathwayModel[]) => {
            this.ncsPathways = ncsPathways;
        }));
    }

    public getNcsInterventions(): Observable<NcsInterventionModel[]> {
        if (this.ncsInterventions) {
            return of(this.ncsInterventions);
        }
        
        const url = environment.endpoints.base + '/hubLov/ncsInterventions'
        return this.httpService.get<NcsInterventionModel[]>(url).pipe(tap((ncsInterventions: NcsInterventionModel[]) => {
            this.ncsInterventions = ncsInterventions;
        }));
    }

    public getNcsIntervention(interventionId: number): Observable<NcsInterventionModel> {
        if (this.ncsInterventions) {
            const intervention = this.ncsInterventions.find(i => i.interventionId === interventionId);
            return of(intervention);
        }
        const url = environment.endpoints.base + '/hubLOV/ncsIntervention/' + interventionId;
        return this.httpService.get<NcsInterventionModel>(url);
    }

    public getAllRecordCollectionTypes(): Observable<RecordCollectionTypeModel[]> {
        if (this.projectCollectionTypes) {
            return of(this.projectCollectionTypes);
        }
        else {
            const url = environment.endpoints.base + '/hublov/recordCollectionTypes';

            return this.httpService.get<RecordCollectionTypeModel[]>(url).pipe(tap((collectionTypes) => {
                this.projectCollectionTypes = collectionTypes;
            }));
        }
    }
}
