import { Injectable, inject } from '@angular/core';
import { HttpService } from '../../../../core/services/http.service';
import { AttributionLevelModel, ScaleMeasureItemModel } from '../../../../../hub_schema/hubTypes';
import { Observable, of, tap } from 'rxjs';
import { HubLovService } from '../../../../core/services/hub-lov.service';

@Injectable()
export class IntermediateResultsLovService {
    private hubLovService: HubLovService = inject(HubLovService);

    public getAttributionLevels(): Observable<AttributionLevelModel[]> {
        return this.hubLovService.getAttributionLevels();
    }

    public getScaleMeasureItems(): Observable<ScaleMeasureItemModel[]> {
        return this.hubLovService.getScaleMeasureItems();
    }
}
