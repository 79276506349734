import { Component, OnInit, inject } from '@angular/core';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';
import { InsightRecordModel, ProjectAnchorRecordWorkflowModel, ProjectModel } from '../../../../../hub_schema/hubTypes';
import { ApplicationRoleType } from '../../../../shared/types/role';
import { AlgoliaKeyService } from '../../../../core/services/algolia-key.service';
import { AlgoliaApiService } from '../../../../core/services/algolia-api.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-anchor-record-selection-panel',
  templateUrl: './anchor-record-selection-panel.component.html',
  styleUrls: ['./anchor-record-selection-panel.component.scss']
})
export class AnchorRecordSelectionPanelComponent extends HubRecordEditorBase implements OnInit {
    private algoliaKeyService: AlgoliaKeyService = inject(AlgoliaKeyService);
    private algoliaApiService: AlgoliaApiService = inject(AlgoliaApiService);
    private authService: AuthService = inject(AuthService);

    public anchorRecord: InsightRecordModel | undefined;    
    public pendingAnchorRecordSelection: any;
    
    public anchorSelectionActions: any = [
        {
            value: 'Create',
            title: 'Request new Insight Anchor Record (P-CONSERVE)',
            isDisabled: false
        },
        {
            value: 'SELECT',
            title: 'Select an existing Insight Record to convert to Anchor for this Hub-Record',
            isDisabled: false
        }
    ];

    public anchorRecordSelectionForm: FormGroup;

    public ngOnInit(): void {
        this.setAnchorInformation();

        this.hubRecordEditService.projectUpdated.subscribe((savedProject: ProjectModel) => {
            this.setAnchorInformation();
        });
    }

    private setAnchorInformation(): void {
        this.anchorRecord = this.project.insightRecords?.find(ir => ir.anchorRecordFlag);

        this.anchorRecordSelectionForm = new FormGroup({
            anchorRecordAction: new FormControl(''),
            selectedInsightRecord: new FormControl(null)
        });

        this.anchorRecordSelectionForm.controls.anchorRecordAction.valueChanges.subscribe((val) => {
            if (val === 'Create') {
                this.selectAnchorRecord(null);
            }
        });

        if (!this.isUserAllowedToSelectAnchor()) {
            this.anchorRecordSelectionForm.disable();
        }

        // There are special rules regarding Strategies that are for Lobbying projects.
        if (this.isLobbyStrategy()) {
            this.anchorSelectionActions[0].isDisabled = true;
        }
        else {
            this.anchorSelectionActions[0].isDisabled = false;
        }

        const anchorRecord = this.getAnchorRecord();

        // none of this matters if there is already an anchor
        if (anchorRecord) {
            this.pendingAnchorRecordSelection = null;
            return;
        }

        // Get the existing workflow record
        const workflowRecord = this.project.projectAnchorRecordWorkflow;
        if (workflowRecord == null) {
            this.pendingAnchorRecordSelection = null;
            return;
        }

        if (!workflowRecord.mdsCode) {
            // the workflow record was automatically created when the project was activated
            // the user will see the radio buttons
            this.pendingAnchorRecordSelection = null;
            return;
        }
        else if (workflowRecord.mdsCode === 'Create') {
            this.pendingAnchorRecordSelection = {
                pendingCreateNew: true,
                hasMdsCode: false
            };
        }
        else {
            this.algoliaApiService.initialize(this.algoliaKeyService.indexVocabularyKeys.projectAtTncSearch, () => {
                this.algoliaApiService.getObject(this.algoliaKeyService.indexVocabularyKeys.projectAtTncSearch, workflowRecord.mdsCode, false)
                    .subscribe(
                        (result) => {
                            this.pendingAnchorRecordSelection = {
                                pendingCreateNew: false,
                                hasMdsCode: true,
                                name: result.Name,
                                businessUnitName: result.BusinessUnits && result.BusinessUnits.length ? result.BusinessUnits[0].Name : '',
                                recordTypeCode: result.RecordTypeCode,
                                insightStatus: result.InsightStatus,
                                insightId: result.InsightID
                            };
                        },
                        (err) => {
                            this.pendingAnchorRecordSelection = {
                                pendingCreateNew: true,
                                hasMdsCode: false
                            };
                        }
                    );
            });
        }        

    }

    public getAnchorRecord(): any {
        return this.project.insightRecords!.find((ir: InsightRecordModel) => ir.anchorRecordFlag);
    }

    public isLobbyStrategy(): boolean {
        return this.project.projectType!.name.toLocaleLowerCase() === 'strategy' && this.project.isLobbyingRecord == true;
    }

    private isUserAllowedToSelectAnchor(): boolean {
        const allowedApplicationRoles = [
            ApplicationRoleType.ItAdmin,
            ApplicationRoleType.BusinessAdministrator,
            ApplicationRoleType.FinanceAdministrator,
            ApplicationRoleType.FinanceEditor
        ];
        const userApplicationRoles = this.authService.getUserRoles();

        for (const appRole of allowedApplicationRoles) {
            if (userApplicationRoles.includes(appRole)) {
                return true;
            }
        }

        return false;
    }

    public isAnchorRecordActionNewSelected(): boolean {
        const action = this.anchorRecordSelectionForm.controls.anchorRecordAction.value;
        return action === 'Create';
    }

    public isAnchorRecordActionSelectSelected(): boolean {
        const action = this.anchorRecordSelectionForm.controls.anchorRecordAction.value;
        return action === 'SELECT';
    }

    public isInsightRecordSelectionEnabled(): boolean {
        // enabled if action is SELECT and an insight record hasn't already been selected
        const action = this.anchorRecordSelectionForm.controls.anchorRecordAction.value;
        return action != null && action === 'SELECT' && !this.isInsightRecordSelected();
    }

    public isInsightRecordSelected(): boolean {
        return this.selectedInsightRecord != null;
    }

    public get selectedInsightRecord(): any {
        let formControl = this.anchorRecordSelectionForm.controls.selectedInsightRecord;
        return formControl.value;
    }

    public selectAnchorRecord(insightRecord: any | null) {

        if (insightRecord == null) {
            this.anchorRecordSelectionForm.controls.selectedInsightRecord.setValue(null);
    
            const anchorRecordWorkflowControl = this.projectEditForm?.controls.projectAnchorRecordWorkflow as FormControl;
            let anchorRecordWorkflow: ProjectAnchorRecordWorkflowModel = anchorRecordWorkflowControl.value;
    
            if (anchorRecordWorkflow) {
                anchorRecordWorkflow.mdsCode = 'Create';            
            }
            else {
                anchorRecordWorkflow = {
                    projectId: this.project.projectId,
                    mdsCode: 'Create'
                };
                anchorRecordWorkflowControl.setValue(anchorRecordWorkflow);
            }
            
            anchorRecordWorkflowControl.markAsDirty();
    
        }
        else {
            this.anchorRecordSelectionForm.controls.anchorRecordAction.setValue("SELECT");
            this.anchorRecordSelectionForm.controls.selectedInsightRecord.setValue(insightRecord);
    
            const anchorRecordWorkflowControl = this.projectEditForm?.controls.projectAnchorRecordWorkflow as FormControl;
            let anchorRecordWorkflow: ProjectAnchorRecordWorkflowModel = anchorRecordWorkflowControl.value;
    
            if (anchorRecordWorkflow) {
                anchorRecordWorkflow.mdsCode = insightRecord.objectID;            
            }
            else {
                anchorRecordWorkflow = {
                    projectId: this.project.projectId,
                    mdsCode: insightRecord.objectID
                };
                anchorRecordWorkflowControl.setValue(anchorRecordWorkflow);
            }
            
            anchorRecordWorkflowControl.markAsDirty();
            }
    }

    public clearSelectedInsightRecord(): void {
        let formControl = this.anchorRecordSelectionForm.controls.selectedInsightRecord;
        formControl.setValue(null);
        formControl.markAsPristine();
    }
}
