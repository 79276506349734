<div class="create-intermediate-result-metric-container" *ngIf="targetForm">
    <div class="hub-modal-header-text">
        <h5 class="modal-title text-success">{{!existingTarget ? 'Add' : 'Edit'}} Intermediate Result Target</h5>
    </div>
    <div class="content">
        <div [formGroup]="targetForm">
            <div class="row">
                <div class="col-md-12">

                    <p class="text-center font-weight-bold sb-b p-1">
                        Set a Target Value for your Intermediate Result
                    </p>
                    <h5 class="text-success text-center p-1">{{intermediateResult.indicatorName}}
                        ({{intermediateResult.unit}})</h5>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="target-container">

                        <div class="target-date-column">
                            <div>
                                <span class="select-label text-info-800">
                                    Target Date
                                    <app-help-selector [selector]="1094"></app-help-selector>
                                </span>
                                <span class="alpaca-icon-required text-danger"></span>
                            </div>
        
                            <mat-form-field>                    
                                <input matInput readonly (click)="targetDatePicker.open()" [matDatepicker]="targetDatePicker" formControlName="targetDate" placeholder="Target Date">
                                <mat-datepicker-toggle matIconSuffix [for]="targetDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #targetDatePicker></mat-datepicker>
                            </mat-form-field>
        
                            <small class="hub-validation-error-message">
                                {{getValidationErrorMessages('targetDate')}}
                            </small>
        
                        </div>

                        <div class="target-value-column text-right">
                            <div>
                                <span class="select-label text-info-800 pb-2">
                                    Target Value
                                    <app-help-selector [selector]="1096"></app-help-selector>
                                </span>
                                <span class="alpaca-icon-required text-danger"></span>
        
                            </div>
        
                            <mat-form-field *ngIf="isScaleMeasure" >
                                <input matInput type="number" class="form-control"
                                [formControl]="targetForm.controls.targetValue"
                                [value]="targetForm.controls.targetValue.value" placeholder="{{type | titlecase}} Value"
                                [min]="getScaleDefinitions()[0].scaleMeasureItem.value"
                                [max]="getScaleDefinitions()[getScaleDefinitions().length - 1].scaleMeasureItem.value"
                                step="1" />
                            </mat-form-field>

                            <mat-form-field  *ngIf="!isScaleMeasure">
                                <input matInput type="number" class="form-control"
                                formControlName="targetValue" placeholder="{{type | titlecase}} Value" />
                            </mat-form-field>
        
                            <small class="hub-validation-error-message">
                                {{getValidationErrorMessages('targetValue')}}
                            </small>        
                        </div>
        
                        <div class="unit-name-column">
                            <span class="select-label text-info-800 d-block">
                                Units<app-help-selector [selector]="1099"></app-help-selector>
                            </span>
                            <div class="form-control" disabled="disabled" style="padding-top: 13px; padding-bottom: 19px">
                                {{ intermediateResult.unit }}
                            </div>
                        </div>
        

                    </div>
                </div>
            </div>

            <div class="row" *ngIf="isScaleMeasure">
                <div class="col-md-12">
                    <div class="select-label text-info-800 mt-3 mb-2"> Target Value:</div>
                </div>
                <div class="scale-slider-panel">
                    <div class="scale-slider-line"></div>

                    <mat-radio-group [formControl]="targetForm.controls.targetValue"
                        [value]="targetForm.controls.targetValue.value"
                        style="display: flex; flex-direction: row; justify-content: space-between;">

                        <mat-radio-button [matTooltip]="scaleDefinition.description" matTooltipPosition="above"
                            matTooltipShowDelay="500" matTooltipHideDelay="200"
                            *ngFor="let scaleDefinition of getScaleDefinitions()" color="primary"
                            [value]="scaleDefinition.scaleMeasureItem.value"></mat-radio-button>
                    </mat-radio-group>

                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                        <div class="scale-label"
                            *ngFor="let scaleDefinition of getScaleDefinitions(); index as i"
                            [ngClass]="{'first': i === 0, 'last': i === getScaleDefinitions().length - 1 }">
                            {{scaleDefinition.scaleMeasureItem.label}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="hub-modal-footer-text">
        <button class="cancel-button mr-1" (click)="cancel()">Cancel</button>

        <button type="button" class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
            [disabled]="targetForm.invalid || isSaving" (click)="save()">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>
            Save
        </button>

    </div>

</div>