import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionsComponent } from './components/collections/collections.component';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '../core/guards/role-guard';
import { SharedModule } from '../shared/shared.module';
import { RecordCollectionComponent } from './components/collections/record-collection/record-collection.component';
import { RecordCollectionDetailComponent } from './components/collections/record-collection-detail/record-collection-detail.component';

const routes: Routes = [
    { path: 'collections', component: CollectionsComponent, pathMatch: 'full', canActivate: [RoleGuard] },
    { path: 'collection/:id', component: RecordCollectionDetailComponent, pathMatch: 'full', canActivate: [RoleGuard] }
  ];
  

@NgModule({
  declarations: [
    CollectionsComponent,
    RecordCollectionComponent,
    RecordCollectionDetailComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes)    
  ]
})
export class CollectionsModule { }
