<div [formGroup]="editTeamMemberForm" *ngIf="editTeamMemberForm && businessRoles" class="team-member-dialog-container">

    <div class="hub-modal-header-text">
        <h5 class="modal-title text-success">
            {{teamMember ? 'Edit Team Member' : 'Add Team Member'}}
        </h5>
    </div>

    <div class="hub-team-member-dialog content">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <div class="dialog-label">Choose Role for Team Member <app-help-selector [selector]="1169">
                            </app-help-selector>
                        </div>
                        <div class="hub-select-container">
                            <mat-form-field>
                                <mat-select placeholder="Select a Role" disableOptionCentering formControlName="role">
                                    <mat-option *ngFor="let role of businessRoles" [value]="role"
                                        [disabled]="isRoleDisabled(role)"
                                        [matTooltip]="getRoleTooltip(role)"
                                        matTooltipShowDelay="500"
                                        matTooltipHideDelay="200">
                                        {{role.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="hub-validation-error-message">
                            {{getValidationErrorMessages("role")}}
                        </div>
                        <div class="text-warning team-member-warning" *ngIf="roleWarningText">
                            <div [innerHTML]="roleWarningText"></div>
                        </div>
                        <div class="text-warning team-member-warning"
                            *ngIf="hasAnchorRecord && isRoleLead">Team Lead will update the Insight Anchor Record Project Lead.</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <div class="dialog-label">{{teamMember ? 'Team Member' : isFinanceContactRoleSelected ? 'Select Finance Contact' : 'Select Team Member'}}
                            <app-help-selector [selector]="1170"></app-help-selector>
                        </div>

                        <ng-container *ngIf="!teamMember; else readOnlyEmployee">

                            <div class="hub-select-container" *ngIf="!isFinanceContactRoleSelected">
                                <mat-form-field>
                                    <input type="text" matInput [formControl]="userTypeahead"
                                        [matAutocomplete]="auto" placeholder="Type User Name">
                                </mat-form-field>

                                <mat-autocomplete #auto="matAutocomplete"
                                    [displayWith]="displayUser"
                                    (optionSelected)="user_clicked($event)">
                                    <mat-option *ngFor="let user of filteredUsers$ | async" [value]="user"
                                        [disabled]="isUserDisabled(user)"
                                        [matTooltip]="getUserTooltip(user)"
                                        matTooltipShowDelay="500"
                                        matTooltipHideDelay="200">
                                        {{user.fullName}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>

                            <div class="hub-select-container" *ngIf="isFinanceContactRoleSelected">
                                <mat-form-field>
                                    <input type="text" matInput [formControl]="financeContactTypeahead"
                                        [matAutocomplete]="autoFinanceContact" placeholder="Type Finance Contact Name">
                                </mat-form-field>

                                <mat-autocomplete #autoFinanceContact="matAutocomplete"
                                    [displayWith]="displayUser"
                                    (optionSelected)="user_clicked($event)">
                                    <mat-option *ngFor="let user of filteredFinanceContacts$ | async" [value]="user">
                                        {{user.fullName}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>

                        </ng-container>

                        <ng-template #readOnlyEmployee>
                            <div>
                                {{teamMember.user.fullName}}
                            </div>
                        </ng-template>

                        <div class="hub-validation-error-message">
                            {{getValidationErrorMessages("employee")}}
                        </div>

                        <div *ngIf="selectedUser">
                            <div>{{selectedUser.businessUnit?.name}}</div>
                            <div class="text-muted">{{selectedUser.office}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-bottom: 20px;">
                <div class="col-12">
                        <div class="select-label text-info-700 pb-2 d-block">Primary Point of Contact</div>

                        <div class="poc-checkbox-panel">
                            <mat-checkbox color="primary" formControlName="isPoc"></mat-checkbox>
                            <div class="poc-label">
                                This is the primary POC for this {{project.projectType.name}}.
                            </div>
                        </div>

                </div>
            </div>
        </div>
    </div>

    <div class="hub-modal-footer-text">
        <button (click)="cancel()" class="cancel-button mr-1 legitRipple">Cancel</button>

        <button *ngIf="teamMember" (click)="submitTeamMember()" class="hub-icon-button"
            [disabled]="editTeamMemberForm.invalid || editTeamMemberForm.pristine">
            <b><i class="icon-checkmark3"></i></b>
            Done
        </button>

        <button *ngIf="!teamMember" (click)="submitTeamMember()" class="hub-icon-button"
            [disabled]="!editTeamMemberForm.valid">
            <b><i class="icon-plus-circle2"></i></b>
            Add to Team
        </button>
    </div>

</div>
