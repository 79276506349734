import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProjectModel, ProjectOutcomeModel} from '../../../../hub_schema/hubTypes';
import { HubRecordPresenterBase } from '../../../shared/components/_hub_record_presenter_base';

@Component({
    selector: 'app-outcomes-container',
    templateUrl: './outcomes-container.component.html',
    styleUrls: ['./outcomes-container.component.scss']
})
export class OutcomesContainerComponent extends HubRecordPresenterBase {
    @Input() public override project: ProjectModel;

    @Output()
    projectDataAreLoading = new EventEmitter<boolean>();

    public get outcomes(): ProjectOutcomeModel[] {
        if (this.project) {
            return this.project.projectOutcomes!.filter(o => o.outcome);
        }
        return [];
    }

    public get customOutcomes(): ProjectOutcomeModel[] {
        if (this.project) {
            return this.project.projectOutcomes!.filter(o => o.customOutcome);
        }
        return [];
    }

    public showDetails = false;
    public showCharts = false;
}
