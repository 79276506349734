import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { ManageNotificationsAndEmailsService } from './services/manage-notifications-and-emails.service';
import { UserDataService } from '../../../core/services/user-data.service';
import { switchMap, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ManageNotificationsUnsavedDialog } from './manage-notifications-unsaved-dialog/manage-notifications-unsaved-dialog.component';

enum emailFrequencyIds {
    daily = 1000,
    weekly = 1001,
    none = 1002,
}

@Component({
    selector: 'app-manage-notifications-and-emails',
    templateUrl: './manage-notifications-and-emails.component.html',
    styleUrls: ['./manage-notifications-and-emails.component.scss'],
})
export class ManageNotificationsAndEmailsComponent implements OnInit, OnDestroy {
    constructor(private userNotificationsService: ManageNotificationsAndEmailsService, private userInfoService: UserDataService, private dialogService: MatDialog) { }

    private formValueChangesSubscription: Subscription;
    public notificationsPreferencesForm: FormGroup;
    public savingState: string = '';

    public emailFrequencies: any[];

    public ngOnInit(): void {
        const userId = this.userInfoService.getUserId();

        forkJoin({
            emailFrequencies: this.userNotificationsService.getEmailFrequencies(),
            userNotificationPreference: this.userNotificationsService.getUserNotificationPreferences(userId),
        })
            .pipe(take(1))
            .subscribe((result: any) => {
                this.emailFrequencies = result.emailFrequencies;
                var pref = result.userNotificationPreference;

                this.notificationsPreferencesForm = new FormGroup({
                    userNotificationPreferenceId: new FormControl(0),
                    userId: new FormControl(0),
                    newRecordLedByMyRegion: new FormControl(false),
                    newRecordLedByMyDivision: new FormControl(false),
                    newRecordLedByMyBusinessUnit: new FormControl(false),
                    recordStatusChangeInMyBusinessUnit: new FormControl(false),
                    projectOutcomeProgressValueAddedInMyBusinessUnit: new FormControl(false),
                    intermediateResultProgressValueAddedInMyBusinessUnit: new FormControl(false),
                    recordStatusChangedInMyStrategy: new FormControl(false),
                    projectCreateRelationshipInMyStrategy: new FormControl(false),
                    projectRemovesRelationshipInMyStrategy: new FormControl(false),
                    projectRelatedToMyStrategyChangeStatus: new FormControl(false),
                    recordStatusChangedInMyProject: new FormControl(false),
                    strategyCreatesRelationshipInMyProject: new FormControl(false),
                    strategyRemovesRelationshipInMyProject: new FormControl(false),
                    strategyRelatedToMyProjectChangedStatus: new FormControl(false),
                    emailFrequencyId: new FormControl({ value: emailFrequencyIds.none, disabled: true }),
                });

                this.formValueChangesSubscription = this.notificationsPreferencesForm.valueChanges.subscribe((formValue: any) => {
                    // enable email frequency if any notification is active
                    if (this.areAnyNotificationsActive()) {
                        this.notificationsPreferencesForm.controls.emailFrequencyId.enable({ emitEvent: false });
                    } else {
                        this.notificationsPreferencesForm.controls.emailFrequencyId.setValue(emailFrequencyIds.none, { emitEvent: false });
                        this.notificationsPreferencesForm.controls.emailFrequencyId.disable({ emitEvent: false });
                    }
                });

                if (pref) {
                    this.notificationsPreferencesForm.setValue(pref);
                } else {
                    // new user preference row
                    this.notificationsPreferencesForm.controls.userId.setValue(userId, { emitEvent: false });
                    this.notificationsPreferencesForm.controls.emailFrequencyId.setValue(emailFrequencyIds.none, { emitEvent: false });
                }
            });
    }

    private areAnyNotificationsActive(): boolean {
        const formValue = this.notificationsPreferencesForm.value;

        if (!formValue) {
            return false;
        }

        return (
            formValue.newRecordLedByMyRegion ||
            formValue.newRecordLedByMyDivision ||
            formValue.newRecordLedByMyBusinessUnit ||
            formValue.recordStatusChangeInMyBusinessUnit ||
            formValue.projectOutcomeProgressValueAddedInMyBusinessUnit ||
            formValue.intermediateResultProgressValueAddedInMyBusinessUnit ||
            formValue.recordStatusChangedInMyStrategy ||
            formValue.projectCreateRelationshipInMyStrategy ||
            formValue.projectRemovesRelationshipInMyStrategy ||
            formValue.projectRelatedToMyStrategyChangeStatus ||
            formValue.recordStatusChangedInMyProject ||
            formValue.strategyCreatesRelationshipInMyProject ||
            formValue.strategyRemovesRelationshipInMyProject ||
            formValue.strategyRelatedToMyProjectChangedStatus
        );
    }

    public showUnsavedWarning(): Observable<boolean> {
        const dialogRef = this.dialogService.open(ManageNotificationsUnsavedDialog);
        dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe((action) => {
                if (action === 'save') {
                    this.saveChanges();
                }
            });

        return dialogRef.afterClosed().pipe(
            switchMap((result) => {
                if (result === 'save') {
                    result = true;
                } else if (result === 'cancel') {
                    result = false;
                } else if (result === 'dismiss') {
                    result = true;
                }
                return of(result);
            })
        );
    }

    public saveChanges(): void {
        this.savingState = 'saving';
        const pref = this.notificationsPreferencesForm.getRawValue();

        this.userNotificationsService
            .saveUserNotificationPreferences(pref)
            .pipe(take(1))
            .subscribe(() => {
                this.savingState = 'saved';
                this.notificationsPreferencesForm.markAsPristine();

                setTimeout(() => {
                    this.savingState = '';
                }, 1500);
            });
    }

    @HostListener('window:beforeunload', ['$event'])
    public beforeUnloadHandler(event) {
        if (this.notificationsPreferencesForm.dirty) {
            event.returnValue = false;
            event.preventDefault();
            return false;
        }
    }

    public ngOnDestroy(): void {
        if (this.formValueChangesSubscription) {
            this.formValueChangesSubscription.unsubscribe();
        }
    }
}
