<div class="parent-height flex-contatiner" [formGroup]="bulkEditForm">
    <div class="search-results-area hub-custom-scrollbar">
        <div class="hub-select-container">
            <span class="select-label form-label">Current User <app-help-selector selector="1200">
                </app-help-selector></span>

            <mat-form-field appearance="fill">
                <input type="text" matInput [matAutocomplete]="currentUserAutoComplete"
                    formControlName="currentUserTypeInput" placeholder="Select a User" maxlength="100">
            </mat-form-field>

            <mat-autocomplete #currentUserAutoComplete="matAutocomplete" [displayWith]="displayUser"
                (optionSelected)="currentUserSelected($event)">
                <mat-option *ngFor="let user of currentFilteredUsers | async" [value]="user">
                    {{user.isActive ? (!!user.preferredName ? user.preferredName : user.fullName) : (!!user.preferredName ? user.preferredName + ' (Inactive)' : user.fullName + ' (Inactive)')}}
                </mat-option>
            </mat-autocomplete>
            <div class="hub-validation-error-message">
                {{getValidationErrorMessages("currentUserTypeInput")}}
            </div>
        </div>

        <div class="hub-select-container">
            <span class="select-label form-label">Team Role(s) To Replace <app-help-selector
                    selector="1201"></app-help-selector></span>
            <mat-form-field appearance="fill">
                <mat-select placeholder="Select Team Role(s)" formControlName="rolesSelect"
                    (selectionChange)="rolesSelectChange($event)" multiple>
                    <mat-option *ngFor="let role of roles" [value]="role">
                        {{role.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="hub-validation-error-message">
                {{getValidationErrorMessages("rolesSelect")}}
            </div>
        </div>

        <div class="hub-select-container">
            <span class="select-label form-label">Lead Business Unit(s) of Records <app-help-selector
                    selector="1202"></app-help-selector></span>
            <div class="tokenfield form-control">
                    <div>
                        <div class="token"
                            *ngFor="let bu of bulkEditForm.controls.businessUnitSelect.value">
                            <span class="token-label">{{bu.name}}</span>
                            <span class="close" (click)="unselectBu(bu)" aria-hidden="true">×</span>
                        </div>
                    </div>
            </div>
            <mat-form-field appearance="fill">
                <input matInput #businessUnitTypeAheadInputBox
                    [matAutocomplete]="businessUnitAutoComplete" placeholder="Search Business Units"
                    formControlName="businessUnitTypeAheadInput"
                    />
            </mat-form-field>

            <mat-autocomplete #businessUnitAutoComplete="matAutocomplete"
                [displayWith]="displayBU"
                (optionSelected)="addBu($event, businessUnitTypeAheadInputBox)">
                <mat-option *ngFor="let bu of filteredBusinessUnits | async" [value]="bu"
                    [disabled]="isParticipatingBuDisabled(bu)">
                    {{bu?.name}}
                </mat-option>
            </mat-autocomplete>
        </div>

        <div class="hub-select-container" *ngIf="!isFinancialEditSelected">
            <span class="select-label form-label">New User to Replace <app-help-selector
                    selector="1203"></app-help-selector></span>
            <mat-form-field appearance="fill">
                <input type="text" matInput [matAutocomplete]="newUserAutoComplete"
                    formControlName="newUserTypeInput" placeholder="Select a User" maxlength="100">
            </mat-form-field>

            <mat-autocomplete #newUserAutoComplete="matAutocomplete" [displayWith]="displayUser"
                (optionSelected)="newUserSelected($event)">
                <mat-option *ngFor="let user of newFilteredUsers | async" [value]="user">
                    {{user.preferredName ? user.preferredName : user.fullName}}
                </mat-option>
            </mat-autocomplete>
            <div class="hub-validation-error-message">
                {{getValidationErrorMessages("selectedNewUser")}}
            </div>
        </div>

        <div class="hub-select-container" *ngIf="isFinancialEditSelected">
            <span class="select-label form-label">New Finance User to Replace <app-help-selector
                    selector="1203"></app-help-selector></span>
            <mat-form-field appearance="fill">
                <input type="text" matInput [matAutocomplete]="newFinanceUserAutoComplete"
                    formControlName="newUserFinanceTypeInput" placeholder="Select a User"
                    maxlength="100">
            </mat-form-field>

            <mat-autocomplete #newFinanceUserAutoComplete="matAutocomplete" [displayWith]="displayUser"
                (optionSelected)="newUserSelected($event)">
                <mat-option *ngFor="let user of newUserFilteredFinance | async" [value]="user">
                    {{user.preferredName ? user.preferredName : user.fullName}}
                </mat-option>
            </mat-autocomplete>
            <div class="hub-validation-error-message">
                {{getValidationErrorMessages("selectedNewUser")}}
            </div>
        </div>
        <div class="flex-button-container">
            <button  mat-raised-button (click)="onSubmit($event)" [disabled]="isDisabled"
                color="primary" class="button-complete">COMPLETE</button>
        </div>
        <div class="flex-button-container-start">
            <button type="button" (click)="cancel()" class="cancel-button"
                style="margin-right: 10px;">Cancel</button>
        </div>


    </div>
    <div class="hub-audit-container" >
        <div class="show-hide-button">
            <a href="javascript: void(0)" (click)="toggleAuditDetails()">{{showAuditDetails ? 'Hide' : 'Show'}} Audit Log</a>
        </div>

        <div class="hub-audit-panel" [ngClass]="{'not-showing': !showAuditDetails, 'your-audit-expanded': showingYourFiltersPanel}">
            <div class="audit-header">

                <h4>Audit Log</h4>
            </div>
            <div class="hub-custom-scrollbar">
                <div class="line"></div>
                <div class="table-container">
                    <table mat-table [dataSource]="dataSource" class="fable mat-elevation-z0 clear">

                        <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->

                        <!-- Date/Time Column -->
                        <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef> Date/Time </th>
                        <td mat-cell *matCellDef="let element"> {{element.date.toLocaleString()}} </td>
                        </ng-container>

                        <!-- User Column -->
                        <ng-container matColumnDef="user">
                        <th mat-header-cell *matHeaderCellDef> User </th>
                        <td mat-cell *matCellDef="let element">
                                <a [routerLink]="'/user/' + element.initiatedBy.userId" >
                                {{element.user?.preferredName ? element.initiatedBy.preferredName : element.initiatedBy.fullName}}
                                </a>
                        </td>
                        </ng-container>

                        <!-- Update Column -->
                        <ng-container matColumnDef="update">
                        <th mat-header-cell *matHeaderCellDef> Update </th>
                        <td mat-cell *matCellDef="let element"> {{element.message}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
