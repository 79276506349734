import { Component, inject, OnInit } from '@angular/core';
import { CreateOutcomeStateService } from '../../services/create-outcome-state.service';
import { OutcomeState } from '../../types/outcome-state';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ncs-panel',
  templateUrl: './ncs-panel.component.html',
  styleUrls: ['./ncs-panel.component.scss']
})
export class NcsPanelComponent implements OnInit {
    private createOutcomeStateService: CreateOutcomeStateService = inject(CreateOutcomeStateService);
    public outcomeState: OutcomeState;
    private climateMitigationMetricDbId: number = 1014;

    public ngOnInit(): void {
        this.outcomeState = this.createOutcomeStateService.outcomeState;
    }

    public get outcomeForm(): FormGroup {
        return this.outcomeState.outcomeWizardForm.controls.outcome as FormGroup;
    }

    // #region NCS Calculator

    public get isNcsCalculatorToggleShowing(): boolean {
        if (this.outcomeForm!.controls.outcomeMetricId.value) {
            return this.outcomeForm.controls.outcomeMetricId.value === this.climateMitigationMetricDbId;
        }
        else {
            return false;
        }
    }

    public get areNcsCalculatorControlsShowing(): boolean {
        return this.outcomeState.usingNcsCalculator && this.isNcsCalculatorToggleShowing;
    }

    public get isUsingNcsCalculator(): boolean {
        return this.outcomeState.usingNcsCalculator;
    }

    public set isUsingNcsCalculator(value: boolean) {
        this.outcomeState.usingNcsCalculator = value;

        if (this.outcomeState.usingNcsCalculator) {
            this.outcomeForm.controls.ncsInterventionId.addValidators(Validators.required);
            this.outcomeForm.controls.ncsCountryId.addValidators(Validators.required);

            this.outcomeForm.controls.spatialExtent.addValidators([
                Validators.required,
                Validators.min(0.000001)
            ]);
        }
        else {            
            // clear out NCS stuff
            this.outcomeForm.controls.ncsInterventionId.clearValidators();
            this.outcomeForm.controls.ncsInterventionId.updateValueAndValidity();

            this.outcomeForm.controls.ncsCountryId.clearValidators();
            this.outcomeForm.controls.ncsCountryId.updateValueAndValidity();

            this.outcomeForm.controls.spatialExtent.clearValidators();
            this.outcomeForm.controls.spatialExtent.updateValueAndValidity();
        }
    }

    // #endregion NCS Calculator


}
