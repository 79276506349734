<div class="add-non-tnc-employee-dialog-container" [formGroup]="addNonTncUserForm" *ngIf="addNonTncUserForm">
    <div class="mat-dialog-title">
        <h2>Add Non-TNC User</h2>
    </div>

    <mat-dialog-content>
        <div class="dialog-content">

            <div class="select-label text-info-700 pb-2 mt-2 d-block">User's Email Address
                <small class="hub-validation-error-message ng-star-inserted" title="required"> * </small>
            </div>
            <div *ngIf="nonTncUserInfo">
                <div>{{nonTncUserInfo.firstName}} {{nonTncUserInfo.lastName}}
                    {{showDisplayNameIfDifferent(nonTncUserInfo)}}</div>
                <div *ngIf="nonTncUserInfo.department">Department: {{nonTncUserInfo.department}}</div>
                <div *ngIf="nonTncUserInfo.office">Office: {{nonTncUserInfo.office}}</div>
            </div>
            <div class="email-text-container">
                <mat-form-field>
                    <input matInput placeholder="TNC Email Address" formControlName="email" type="email" />
                </mat-form-field>
            </div>
            <ng-container *ngIf="addNonTncUserForm.get('email').invalid && addNonTncUserForm.get('email').touched">
                <small class="hub-validation-error-message">
                    {{getValidationErrorMessages(addNonTncUserForm.get('email'))}}
                </small>
            </ng-container>

            <span class="select-label text-info-700 pb-2 mt-2 d-block">Supervisor
                <small class="hub-validation-error-message ng-star-inserted" title="required"> * </small>
            </span>

            <div class="hub-select-container">
                <mat-form-field>
                    <input type="text" matInput formControlName="supervisorTypeahead" [matAutocomplete]="auto"
                        placeholder="Select Supervisor" />
                </mat-form-field>

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayUser"
                    (optionSelected)="selectSupervisor($event)">
                    <mat-option *ngFor="let user of filteredSupervisors | async" [value]="user">
                        {{user.fullName}}
                    </mat-option>
                </mat-autocomplete>

                <ng-container
                    *ngIf="addNonTncUserForm.get('supervisor').invalid && addNonTncUserForm.get('supervisor').touched">
                    <small class="hub-validation-error-message">
                        {{getValidationErrorMessages(addNonTncUserForm.get('supervisor'))}}
                    </small>
                </ng-container>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button (click)="close()" class="cancel-button mr-1">Cancel</button>
        <button class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}"
            [disabled]="!(addNonTncUserForm.get('email').status === 'VALID' && addNonTncUserForm.get('supervisor').status === 'VALID')"
            (click)="saveUser()">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>
            Save
        </button>
    </mat-dialog-actions>
</div>
