import { Component, OnInit, inject } from '@angular/core';
import { HubLovService } from 'src/app/core/services/hub-lov.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';
import { NcsInterventionModel } from 'src/hub_schema/hubTypes';

@Component({
  selector: 'app-calculations-info-dialog',
  templateUrl: './calculations-info-dialog.component.html',
  styleUrls: ['./calculations-info-dialog.component.scss']
})
export class CalculationsInfoDialogComponent implements OnInit {
    private hubLovService: HubLovService = inject(HubLovService);
    private dialogRef: MatDialogRef<CalculationsInfoDialogComponent> = inject(MatDialogRef<CalculationsInfoDialogComponent>);

    private dialogData: any = inject(MAT_DIALOG_DATA);

    public intervention: NcsInterventionModel;

    public ngOnInit(): void {
        const interventionId = this.dialogData.ncsInterventionId;

        this.hubLovService.getNcsIntervention(interventionId).pipe(take(1)).subscribe((intervention: NcsInterventionModel) => {
            this.intervention = intervention;
        });
    }

    public cancelDialog(): void {
        this.dialogRef.close();
    }

}
