export enum ApplicationRoleType {
    ItAdmin = 'ItAdmin',
    BusinessAdministrator = 'Business Administrator',
    FinanceAdministrator = 'Finance Administrator',
    FinanceEditor = 'Finance Editor', // Formerly known as 'Finance Contact.'
    BusinessUnitEditor = 'Business Unit Editor',
    DivisionEditor = 'Division Editor',
    RegionEditor = 'Region Editor',
    NcsEditor = "NCS Editor",
    
}
