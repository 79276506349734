import { Component, OnInit, inject } from '@angular/core';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';
import { FormControl } from '@angular/forms';
import { ProjectInfoModel, ProjectModel, ProjectRelationModel } from '../../../../../hub_schema/hubTypes';
import { CdkDrag, CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { RelationsTypeaheadService } from '../services/relations-typeahead.service';
import { HubRecordTypes } from '../../../../shared/types/hub-record-types';

@Component({
    selector: 'app-project-relations-edit',
    templateUrl: './project-relations-edit.component.html',
    styleUrls: ['./project-relations-edit.component.scss']
})
export class ProjectRelationsEditComponent extends HubRecordEditorBase implements OnInit {
    private relationsTypeaheadService: RelationsTypeaheadService = inject(RelationsTypeaheadService);

    public ngOnInit(): void {
        const relations = this.projectEditForm?.controls.relations.value as ProjectRelationModel[];
        this.primaryStrategy = relations.find(r => r.isPrimary)?.relatedProject!;        
        this.additionalStrategies = relations.filter(r => !r.isPrimary).map(r => r.relatedProject!);

        // proposed relations are backward relations that are not reciprocal
        const relatedProjectIds = relations.map(r => r.relatedProjectId);

        this.proposedRelations = (this.projectEditForm?.controls.backwardRelations.value as ProjectRelationModel[])
            .filter(br => !relatedProjectIds.includes(br.projectId) && br.project?.projectTypeName === HubRecordTypes.Strategy )
            .map(br =>br.project!);

        this.programManagementRelations = (this.projectEditForm?.controls.backwardRelations.value as ProjectRelationModel[])
            .filter(br => br.project!.projectTypeName === HubRecordTypes.ProgramManagement)
            .map(br => br.project!);

        this.wholeSystemRelations = (this.projectEditForm?.controls.backwardRelations.value as ProjectRelationModel[])
            .filter(br => br.project!.projectTypeName === HubRecordTypes.WholeSystem)
            .map(br => br.project!);

        // wireup typeaheads
        this.primaryStrategySuggestions$ = this.primaryStrategyTypeahead.valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                switchMap((term: string) =>
                    this.relationsTypeaheadService.getStrategiesByNamePrefix(term))
            );

        this.additionalStrategiesSuggestions$ = this.additionalStrategiesTypeahead.valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                switchMap((term: string) =>
                    this.relationsTypeaheadService.getStrategiesByNamePrefix(term))
            );
    }

    // #region Primary Strategy

    public primaryStrategy: ProjectInfoModel | undefined;

    public deletePrimaryStrategy(): void {
        if (this.isBackwardRelation(this.primaryStrategy!)) {
            this.addProposedRelation(this.primaryStrategy!);
        }        
        
        this.removePrimaryStrategy();
        this.rebuildRelationsFormValue();
    }

    private removePrimaryStrategy(): void {
        this.primaryStrategy = undefined;        
    }


    // #region Primary Strategy Drag/Drop

    public relationDroppedOnPrimaryStrategy(e: CdkDragDrop<any>): void {
        if (e.previousContainer !== e.container) { 
            const existingPrimaryStrategy = this.primaryStrategy;

            // don't allow dragging primary to itself
            if (this.primaryStrategy?.projectId == e.item.data.projectId) {
                return;
            }
    
            this.primaryStrategy =(e.item.data);
    
            // if there already was a primary strategy, make it additional
            if (existingPrimaryStrategy) {
                this.addAdditionalStrategy(existingPrimaryStrategy);            
            }
    
            // remove from source list (additional or proposed)
            if (e.previousContainer.data === this.additionalStrategies) {
                this.removeAdditionalStrategy(e.item.data);
            }
            else {
                this.removeProposedRelation(e.item.data);
            }
    
            this.rebuildRelationsFormValue();
        }
    }

    // #endregion Primary Strategy Drag/Drop

    // #region Primary Strategy Autocomplete

    public primaryStrategyTypeahead: FormControl = new FormControl('');

    public primaryStrategySuggestions$: Observable<ProjectInfoModel[]>;

    public primaryStrategySelected(e: MatAutocompleteSelectedEvent): void {
        this.primaryStrategy = e.option.value;

        // if they just added a relation that was proposed, remove from proposed
        const existingProposedRelation = this.proposedRelations.find(r => r.projectId === e.option.value.projectId);

        if (existingProposedRelation) {
            this.removeProposedRelation(existingProposedRelation);
        }

        this.primaryStrategyTypeahead.setValue('');
        this.rebuildRelationsFormValue();
    }

    // #endregion Primary Strategy Autocomplete
    
    // #endregion Primary Strategy

    // #region Additional Strategies

    public additionalStrategies: ProjectInfoModel[];

    private addAdditionalStrategy(strategy: ProjectInfoModel): void {
        this.additionalStrategies.push(strategy);
    }

    public deleteAdditionalStrategy(strategy: ProjectInfoModel) {
        this.removeAdditionalStrategy(strategy);

        if (this.isBackwardRelation(strategy) && strategy !== this.primaryStrategy) {
            this.addProposedRelation(strategy);
        }

        this.rebuildRelationsFormValue();
    }

    private removeAdditionalStrategy(strategy: ProjectInfoModel) {
        this.additionalStrategies = this.additionalStrategies.filter(s =>s.projectId !== strategy.projectId);
    }

    // #region Additional Strategies Drag/Drop

    public relationDroppedOnAdditionalStrategies(e: CdkDragDrop<any>): void {
        if (e.previousContainer !== e.container) {
            this.addAdditionalStrategy(e.item.data);

            // remove from source
            if (e.previousContainer.id === 'primaryStrategyList') {
                this.removePrimaryStrategy();                
            }
            else {
                this.removeProposedRelation(e.item.data);
            }

            this.rebuildRelationsFormValue();
        }
    }

    // #endregion Additional Strategies Drag/Drop

    // #region Additional Strategies Autocomplete

    public additionalStrategiesTypeahead: FormControl = new FormControl('');

    public additionalStrategiesSuggestions$: Observable<ProjectInfoModel[]>;

    public additionalStrategySelected(e: MatAutocompleteSelectedEvent): void {
        const strategy = e.option.value as ProjectInfoModel;
        this.addAdditionalStrategy(strategy);

        // if they just added a relation that was proposed, remove from proposed
        const existingProposedRelation = this.proposedRelations.find(r => r.projectId === e.option.value.projectId);

        if (existingProposedRelation) {
            this.removeProposedRelation(existingProposedRelation);
        }

        this.additionalStrategiesTypeahead.setValue('');
        this.rebuildRelationsFormValue();
    }

    // #endregion Additional Strategies Autocomplete

    // #endregion Additional Strategies
    
    // #region Proposed Strategy Relations

    public proposedRelations: ProjectInfoModel[];

    private addProposedRelation(strategy: ProjectInfoModel): void {
        this.proposedRelations.push(strategy);        
    }

    private removeProposedRelation(strategy: ProjectInfoModel): void {
        this.proposedRelations = this.proposedRelations.filter(s => s.projectId !== strategy.projectId);
    }

    // #region Proposed Relation Drag/Drop

    public relationDroppedOnProposedRelations(e: CdkDragDrop<any>): void {
        if (e.previousContainer !== e.container) {
            this.addProposedRelation(e.item.data);

            if (e.previousContainer.id == 'primaryStrategyList') {
                this.removePrimaryStrategy();
            }
            else {
                this.removeAdditionalStrategy(e.item.data);
            }

            this.rebuildRelationsFormValue();
        }
    }

    public isBackwardRelatedPredicate(item: CdkDrag<any>) {    
        const strategy: ProjectInfoModel = item.data;
        
        // you have to bind this function to this, for the following method to work.
        return this.isBackwardRelation(strategy);
    }   

    // #endregion Proposed Relation Drag/Drop

    // #endregion Proposed Stratey Relations

    // #region Programs and Whole Systems

    public programManagementRelations: ProjectInfoModel[];
    public wholeSystemRelations: ProjectInfoModel[];

    // #endregion Programs and Whole Systems

    // #region Helpers

    public isRelationReciprocal(relation, primary): boolean {
        const match = this.project.backwardRelations!.find((itm) => itm.project!.projectId === relation.projectId && (primary ? itm.isPrimary : !itm.isPrimary));
        return match !== undefined;
    }

    private isBackwardRelation(strategy: ProjectInfoModel) {
        return this.project.backwardRelations!.find(br => br.projectId === strategy.projectId) !== undefined;
    }

    public isRelationOptionDisabled(strategy: ProjectInfoModel): boolean {        
        // it's disabled if it's already include in either list        
        if (strategy.projectId === this.primaryStrategy?.projectId) {
            return true;
        }

        return this.additionalStrategies.find(as => as.projectId === strategy.projectId) !== undefined;
    }

    private rebuildRelationsFormValue() {
        // set the form control's value to the current state
        const newRelations: ProjectRelationModel[] = [];

        if (this.primaryStrategy) {
            const primaryStrategyRelation: ProjectRelationModel = this.createProjectRelation(this.primaryStrategy, true);
            newRelations.push(primaryStrategyRelation);
        }

        for (let strategy of this.additionalStrategies) {
            const additionalStrategyRelation: ProjectRelationModel = this.createProjectRelation(strategy, false);
            newRelations.push(additionalStrategyRelation);
        }

        this.projectEditForm?.controls.relations.setValue(newRelations);
        this.projectEditForm?.controls.relations.markAsDirty();
    }

    private createProjectRelation(strategy: ProjectInfoModel, isPrimary: boolean): ProjectRelationModel {
        const relation: ProjectRelationModel = {
            projectId: this.project.projectId!,
            relatedProjectId: strategy.projectId!,
            relatedProject: strategy,
            isPrimary: isPrimary
        };

        // if this relation already exists, get its projectProjectId

        const existingRelation: ProjectRelationModel | undefined = this.project.relations!.find(r => r.relatedProjectId === strategy.projectId);

        if (existingRelation) {
            relation.projectProjectId =  existingRelation.projectProjectId;
        }

        return relation;
    }
 
    // #endregion Helpers
}
