import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class ErrorService {

    public isErrorIconVisible: boolean = false;
    public isErrorPanelVisible: boolean = false;
    public areControlButtonsVisible: boolean = false;

    public errors: any[] = [];
    public visibleErrors: any[] = [];
    private timeouts: any[] = [];

    addError(errorInfo: any, isServiceError: boolean) {
        errorInfo.timestamp = new Date();
        this.errors.push(errorInfo);

        if (environment.errorHandling.display) {
            this.visibleErrors.push(errorInfo);

            this.isErrorIconVisible = true;
            this.isErrorPanelVisible = true;

            if (environment.errorHandling.display.autohide && !this.areControlButtonsVisible) {
                let to = setTimeout(() => {
                    this.closeError(errorInfo);
                }, environment.errorHandling.display.autohide.delayInSeconds * 1000);
                this.timeouts.push(to);
            }
        }

        if (environment.errorHandling.logging && !isServiceError) {
            // todo: log the error on the server
        }
    }

    closeError(errorInfo) {
        this.visibleErrors = this.visibleErrors.filter(e => e != errorInfo);
        this.isErrorPanelVisible = this.visibleErrors.length > 0;
    }

    showAllErrors() {
        this.clearTimeouts();
        this.visibleErrors = this.errors.filter(e => true);
        this.isErrorPanelVisible = true;
        this.areControlButtonsVisible = true;
    }

    private clearTimeouts() {
        for (let to of this.timeouts) {
            clearTimeout(to);
        }
        this.timeouts = [];
    }

    closeErrorPanel() {
        this.visibleErrors = [];
        this.isErrorPanelVisible = false;
        this.areControlButtonsVisible = false;
    }

    clearErrors() {
        this.errors = [];
        this.visibleErrors = [];
        this.isErrorIconVisible = false;
        this.closeErrorPanel();
    }
}
