<div class="edit-user-dialog-container" *ngIf="applicationRoleCollection">

    <div mat-dialog-title>
        <h2>{{user.fullName}} - Roles</h2>
    </div>

    <mat-dialog-content>
        <div class="dialog-content">
            <div class="user-info">
                <div class="bu-name">{{user.businessUnit.name}}</div>
                <div>{{user.businessUnit.division.name}}</div>
            </div>

            <div class="label">Roles</div>

            <div class="application-role-list">
                <div class="application-role" *ngFor="let role of applicationRoleCollection; let i = index">
                    <label>
                        <mat-checkbox
                            [disabled]="role.hasRole.disabled"    
                            [matTooltip]="role.hasRole.disabled ? 'You do not have sufficient permissions to assign this role.' : ''"
                            [(ngModel)]="role.hasRole.value"
                            (change)="roleSelectedChanged($event)"
                            [name]="role.role.name" color="primary">
                        </mat-checkbox>
                        <div class="role-name">{{role.role.name}}</div>
                    </label>
                </div>
            </div>

            <!-- BU Editor BU Widget -->
            <div class="label" *ngIf="hasBusinessUnitEditorRoles">Business Units For Editing
                <small class="hub-validation-error-message ng-star-inserted" title="required"> * </small>
            </div>
            <div *ngIf="hasBusinessUnitEditorRoles" class="hub-select-container">

                <div class="tokenfield form-control">
                    
                    <div *ngIf="selectedBusinessUnits">
                        <div class="token" *ngFor="let bu of selectedBusinessUnits">                            
                            <span class="token-label">{{bu.name}}</span>
                            <span class="close" (click)="deleteBu(bu)" aria-hidden="true">×</span>
                        </div>
                    </div>
                </div>

                <mat-form-field>
                    <input matInput
                        [matAutocomplete]="businessUnitsAutoComplete" placeholder="Add a Business Unit..."
                        [formControl]="businessUnitsTypeAhead" />
                </mat-form-field>

                <mat-autocomplete #businessUnitsAutoComplete="matAutocomplete" [displayWith]="getBuName"
                    (optionSelected)="addBu($event.option.value)">
                    <mat-option *ngFor="let bu of businessUnitSuggestions$ | async" [value]="bu"
                        [disabled]="disableBuOption(bu)">
                        {{bu?.name}}
                    </mat-option>
                </mat-autocomplete>
            </div>

            <!-- Blocked Widget -->
            <div class="label">Blocked</div>
            <mat-checkbox [(ngModel)]="user.isBlocked" color="primary"></mat-checkbox>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button (click)="close()" class="cancel-button mr-1">Cancel</button>
        <button class="hub-icon-button SaveButton" [ngClass]="{'saving': isSaving}" [disabled]="isSaving" (click)="saveUser()">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>
            Save
        </button>
    </mat-dialog-actions>
</div>
