import { Component, Input} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { OpenCreateRecordDialogService } from 'src/app/core/services/open-create-record-dialog.service';
import { SessionStorageService } from 'src/app/core/services/session-storage.service';
import { UserDataService } from 'src/app/core/services/user-data.service';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';

@Component({
  selector: 'app-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss']
})

export class SidebarNavComponent {

  showApplications = false;

  @Input()
  public isSidebarExpanded: boolean;

  public get isSidebarCollapsed(): boolean {
      return !this.isSidebarExpanded;
  }

  public get userFullName(): string {
    return this.userDataService.getUserFullName();
  }

  public get department(): string {
    return this.userDataService.getUserDepartment();
  }

  constructor(
    public router: Router,
    public userDataService: UserDataService,
    private openCreateRecordDialogService: OpenCreateRecordDialogService,
    private authService: AuthService
  ) { }

  public isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  login() {
    this.authService.logout();
  }

  logout() {
    this.authService.logout();
  }

  toggle() {
    this.showApplications = !this.showApplications;
  }

  public shouldAdminMenuShow(): boolean {
    return this.authService.userIsAdmin() 
      || this.authService.userIsFinanceAdmin() 
      || this.authService.userIsBusinessUnitEditor() 
      || this.authService.userIsDivisionEditor() 
      || this.authService.userIsRegionEditor()
      || this.authService.userIsNcsEditor();
  }

  public requestHubRecordCreateDialog(e: Event): void {

    if (!this.authService.isLoggedIn()) {
      this.authService.login();
      return;
    }

    this.openCreateRecordDialogService.requestHubRecordCreateDialog();
    e.preventDefault();
  }
}
