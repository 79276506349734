import { Component } from '@angular/core';
import { HubRecordEditorBase } from '../_hub_record_editor_base';

@Component({
  selector: 'app-team-partners-edit-container',
  templateUrl: './team-partners-edit-container.component.html',
  styleUrls: ['./team-partners-edit-container.component.scss']
})
export class TeamPartnersEditContainerComponent extends HubRecordEditorBase {
    public isTeamVisible: boolean = true;

    public activateTeam(e: Event): void {
        this.isTeamVisible = true;
        e.preventDefault();
    }

    public activatePartners(e: Event): void {
        this.isTeamVisible = false;
        e.preventDefault();
    }

    public teamPocSelected(): void {
        for (let partner of this.projectEditForm?.controls.projectPartners.value) {
            partner.isPoc = false;
        }
    }

    public partnerPocSelected(): void {
        for (let teamMember of this.projectEditForm?.controls.team.value) {
            teamMember.isPoc = false;
        }
    }
}
