import { Component, Input, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HelpSelectorDialogComponent } from './help-selector-dialog/help-selector-dialog.component';

@Component({
    selector: 'app-help-selector',
    templateUrl: './help-selector.component.html',
    styleUrls: ['./help-selector.component.scss'],
})

export class HelpSelectorComponent {
    @Input()
    public selector: number;
    private dialogService = inject(MatDialog);

    public openHelpDialog(): void {
        this.dialogService.open(HelpSelectorDialogComponent, {
            data: this.selector
        });
    }
}
