<div aria-hidden="false" tabindex="0">
    <div class="p-3">
        <div class="d-md-flex justify-content-between">
            <h5 class="edit-section-header">
                Associated Files ({{project.projectLinks.length}})<app-help-selector
                    [selector]="1139">
                </app-help-selector>
            </h5>
            <button type="button"
                class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round ml-2 legitRipple"
                data-toggle="modal" data-target="#mdlEditFileLink" data-popup="tooltip" title="" data-placement="top"
                data-original-title="Add a new File" tabindex="0" (click)="openAddEditFileDialog(null)">
                <i class="icon-plus-circle2 mr-2"></i> Add a File Link
            </button>
        </div>

        <div class=" p-2 pl-3">
            <div *ngIf="!project.projectLinks.length"
                class="d-block alert alert-info alert-styled-left shadow-0">
                There are no associated files linked yet.
            </div>
        </div>

        <ul class="section-list left ml-1 mr-2" id="lstOutputLink">
            <li class="sb-b" *ngFor="let file of project.projectLinks; let i = index;">
                <div class="mr-2">
                    <a [href]="file.url" target="_new" class="btn btn-primary rounded-round btn-icon legitRipple">
                        <i class="icon-link2"></i>
                    </a><br>
                </div>
                <div class="media-body">
                    <div class="d-md-flex justify-content-between">
                        <div class="media-title">
                            <a [href]="file.url" class="d-block font-weight-semibold text-primary" target="_new"
                                tabindex="0">{{file.title}}</a>
                            <span class="text-muted d-block">{{file.ancillaryType.name}}</span>
                        </div>

                        <div class="align-self-center ml-3">
                            <button type="button" (click)="openAddEditFileDialog(file)"
                                class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                data-popup="tooltip" title="" data-placement="top" data-original-title="Edit File Link"
                                data-toggle="modal" data-target="#mdlEditFileLink" tabindex="0"><i
                                    class="icon-pencil"></i>
                            </button>
                            <button type="button"
                                class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                data-popup="tooltip" title="" data-placement="top"
                                data-original-title="Delete File Link" (click)="openDeleteAssociatedFileDialog(file)"
                                tabindex="0"><i class="icon-trash"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </li>
        </ul>
    </div>

</div>
