<div class="helper-dialog-container">
    <div class="dialog-icon">?</div>

    <div class="dialog-content" *ngIf="helpResult">
        <div class="dialog-header">
            <div class="title">
                {{helpResult.label}}
            </div>
        </div>

        <div class="dialog-body hub-custom-scrollbar">
            <div *ngIf="helpResult.contentHtmlShort">
                <div [innerHTML]="helpResult.contentHtmlShort"></div>
                <div *ngIf="helpResult.link && helpResult.link.length" class="content-link"><a target="_blank"
                        href="{{helpResult.link}}">Read more</a></div>
            </div>
        </div>

        <div class="dialog-footer">
            <button *ngIf="helpResult.contentHtmlShort; else loading" (click)="closeDialog()" type="button"
                class="btn btn-primary">Ok</button>
            <ng-template #loading>
                <button type="button" class="btn btn-primary" disabled>
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </button>
            </ng-template>

        </div>
    </div>
</div>
