import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { escapeQuotesAndEnters } from '../../../shared/helpers/escape';
import { UserDataService } from '../../../core/services/user-data.service';
import { PortfolioViewModel } from '../../types/portfolio-view-model';
import { UserPortfolioFilterModel } from '../../../../hub_schema/hubTypes';
import { ErrorService } from '../../../core/services/error.service';

@Component({
  selector: 'app-save-filters-dialog',
  templateUrl: './save-filters-dialog.component.html',
  styleUrls: ['./save-filters-dialog.component.scss']
})
export class SaveFiltersDialogComponent implements OnInit {

  public saveFiltersForm: FormGroup;

  constructor(
    private userDataService: UserDataService,
    private dialogRef: MatDialogRef<SaveFiltersDialogComponent>,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) private portfolioViewModel: PortfolioViewModel) { }

  public ngOnInit(): void {
    this.saveFiltersForm = new FormGroup({
      name: new FormControl('', Validators.required),
    });
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public save(): void {
    const userId = this.userDataService.getUserId();
    const jsonString = this.portfolioViewModel.stringify();
    const name = escapeQuotesAndEnters(this.saveFiltersForm.controls.name.value);

    // encode the JSON string for saving in graphQL.
    // this will generate a console warning in development mode.  It's OK
    const encodedJsonString = encodeURI(jsonString);

    this.userDataService.savePortfolioFilter(name, encodedJsonString).pipe(take(1)).subscribe({
        next: (savedFilter: UserPortfolioFilterModel) => {
            this.dialogRef.close(savedFilter);
        },
        error: (err) => {
            this.errorService.addError(err, true);
        }
    });
  }
}
