<div class="p-3" *ngIf="projectEditForm" [formGroup]="projectEditForm">
    <h5 class="edit-section-header">Relations</h5>

    <div class="row">
        <div class="col-md-6">
            <span class="select-label text-info-700 mt-4 d-block">
                Primary Strategy<app-help-selector [selector]="1044">
                </app-help-selector>
            </span>

            <!-- Primary Strategy Pills -->

            <div class="tokenfield form-control hub-custom-scrollbar"
                 style="overflow-x: hidden; overflow-y: auto; max-height: 200px;" cdkDropList
                 cdkDropListOrientation="horizontal" id="primaryStrategyList" #primaryStrategyList="cdkDropList"
                 [cdkDropListConnectedTo]="[proposedRelationsList, additionalStrategiesList]" [cdkDropListData]="[]"
                 (cdkDropListDropped)="relationDroppedOnPrimaryStrategy($event)">

                <div *ngIf="primaryStrategy" class="token" cdkDrag [cdkDragData]="primaryStrategy">
                    <span class="token-label">
                        <i class="icon-loop3 mr-1"
                           [ngClass]="{'d-none' : !isRelationReciprocal(primaryStrategy, true)}"></i>{{primaryStrategy.name}}
                    </span>
                    <span class="close" (click)="deletePrimaryStrategy()"
                          aria-hidden="true">×</span>

                    <div *cdkDragPreview class="token-preview">
                        <span class="add-icon">
                            <i class="icon-add"></i>
                        </span>
                        <span class="token-label">
                            {{primaryStrategy.name}}
                        </span>
                    </div>

                    <div *cdkDragPlaceholder class="token-placeholder">
                        <span class="token-label">
                            {{primaryStrategy.name}}
                        </span>
                    </div>
                </div>

            </div>

            <!--Primary Strategy Auto-Complete-->
            <div class="hub-select-container">
                <mat-form-field>
                    <input type="text" matInput [formControl]="primaryStrategyTypeahead"
                           [matAutocomplete]="primaryStrategyAutocomplete"
                           placeholder="Enter one Primary Strategy" />
                </mat-form-field>

                <mat-autocomplete #primaryStrategyAutocomplete="matAutocomplete"
                                  (optionSelected)="primaryStrategySelected($event)">
                    <mat-option [disabled]="isRelationOptionDisabled(strategy)"
                        *ngFor="let strategy of primaryStrategySuggestions$ | async" [value]="strategy">
                        {{strategy.name}}
                    </mat-option>
                </mat-autocomplete>
            </div>

            <div class="mt-1 text-muted font-size-sm" style="margin-bottom: 10px;">
                <i class="icon-loop3 mr-1 ml-1 text-muted round-rounded"></i>
                : indicates a reciprocal relationship between Project and Strategy
            </div>

            <!-- Additional Strategies Pills -->

            <span class="select-label text-info-700 mt-4 d-block">
                Additional Strategies<app-help-selector [selector]="1045"></app-help-selector>
            </span>

            <div class="tokenfield form-control hub-custom-scrollbar"
                 style="overflow-x: hidden; overflow-y: auto; max-height: 200px;" cdkDropList
                 cdkDropListOrientation="horizontal" id="additionalStrategiesList"
                 #additionalStrategiesList="cdkDropList"
                 [cdkDropListConnectedTo]="[proposedRelationsList, primaryStrategyList]"
                 [cdkDropListData]="additionalStrategies"
                 (cdkDropListDropped)="relationDroppedOnAdditionalStrategies($event)">

                <div class="token" *ngFor="let item of additionalStrategies" cdkDrag
                     [cdkDragData]="item">
                    <span class="token-label">
                        <i class="icon-loop3 mr-1"
                           [ngClass]="{'d-none' : !isRelationReciprocal(item, false)}"></i>{{item.name}}
                    </span>
                    <span class="close" (click)="deleteAdditionalStrategy(item)" aria-hidden="true">×</span>

                    <div *cdkDragPreview class="token-preview">
                        <span class="add-icon">
                            <i class="icon-add"></i>
                        </span>
                        <span class="token-label">
                            {{item.name}}
                        </span>
                    </div>

                    <div *cdkDragPlaceholder class="token-placeholder">
                        <span class="token-label">
                            {{item.name}}
                        </span>
                    </div>
                </div>

            </div>

            <!-- Additional Strategies Auto-Complete -->

            <div class="hub-select-container">
                <mat-form-field>
                    <input type="text" matInput [formControl]="additionalStrategiesTypeahead"
                           [matAutocomplete]="additionalStrategiesAutocomplete"
                           placeholder="Enter one or multiple Additional Strategies here" />
                </mat-form-field>

                <mat-autocomplete #additionalStrategiesAutocomplete="matAutocomplete"
                                  (optionSelected)="additionalStrategySelected($event)">
                    <mat-option [disabled]="isRelationOptionDisabled(strategy)"
                                *ngFor="let strategy of additionalStrategiesSuggestions$ | async" [value]="strategy">
                        {{strategy.name}}
                    </mat-option>
                </mat-autocomplete>
            </div>

            <div class="mt-1 text-muted font-size-sm">
                <i class="icon-loop3 mr-1 ml-1 text-muted round-rounded"></i>
                : indicates a reciprocal relationship between Project and Strategy
            </div>

            <div class="select-label text-info-700 mt-4">
                Related Program Management
                <app-help-selector [selector]="1199"></app-help-selector>
            </div>
            <ul *ngIf="programManagementRelations.length">
                <li *ngFor="let pm of programManagementRelations">{{pm.name}}</li>
            </ul>

            <div *ngIf="programManagementRelations.length === 0" class="no-relations-warning" style="color: #999;">
                A Program Management has not identified this record as part of their portfolio.
            </div>

            <div class="select-label text-info-700 mt-4">
                Related Whole System
                <app-help-selector [selector]="1198"></app-help-selector>
            </div>

            <ul *ngIf="wholeSystemRelations.length">
                <li *ngFor="let ws of wholeSystemRelations">{{ws.name}}</li>
            </ul>

            <div *ngIf="wholeSystemRelations.length === 0" class="no-relations-warning" style="color: #999;">
                A Whole System has not identified this record as part of their portfolio.
            </div>

        </div>

         <div class="col-md-6">
            <span class="select-label text-info-700 mt-4 d-block">Relations Proposed by Strategies</span>

            <div class="mt-1 text-muted font-size-sm">
                The strategies below have proposed a relationship to this project. To confirm the relation, click a
                project and drag it to the Primary or Additional section.
            </div>

            <div class="tokenfield proposed-relations" style="height: calc(100% - 94px)" cdkDropList
                cdkDropListOrientation="horizontal" id="proposedRelations" #proposedRelationsList="cdkDropList"
                [cdkDropListConnectedTo]="[primaryStrategyList, additionalStrategiesList]"
                [cdkDropListData]="proposedRelations"
                [cdkDropListEnterPredicate]="isBackwardRelatedPredicate.bind(this)"
                (cdkDropListDropped)="relationDroppedOnProposedRelations($event)">

                <div class="token" *ngFor="let item of proposedRelations" cdkDrag [cdkDragData]="item">
                    <span class="token-label">
                        {{item.name}}
                    </span>

                    <div *cdkDragPreview class="token-preview">
                        <span class="add-icon">
                            <i class="icon-add"></i>
                        </span>
                        <span class="token-label">
                            {{item.name}}
                        </span>
                    </div>

                    <div *cdkDragPlaceholder class="token-placeholder">
                        <span class="token-label">
                            {{item.name}}
                        </span>
                    </div>

                </div>

                <div>
                    <div *ngIf="!proposedRelations.length"
                        class="mt-2 d-block alert alert-info alert-styled-left shadow-0">
                        There are no proposed relations for this project.
                    </div>
                </div>

            </div>

        </div>

    </div>

    <div class="row">
        <div class="col-md-12">
            <span class="select-label text-info-700 d-block mt-4">Relations Notes</span>
            <textarea rows="3" cols="50" placeholder="Enter relations notes here..." formControlName="relationsNotes"
                maxlength="500" class="form-control mb-2 record-description"></textarea>
            <div class="hub-validation-error-message">
                {{getValidationErrorMessages('relationsNotes')}}
            </div>
        </div>
    </div>

</div>
