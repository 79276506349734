<div class="hub-partners-content">

    <div class="partners-content-header">
        <h5 class="edit-section-header" style="font-weight: bold;">Partners<app-help-selector [selector]="1019">
            </app-help-selector>
        </h5>

        <button type="button"
            class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round ml-2 legitRipple"
            tooltip="Add a Partner" data-original-title="Add a new Partners" tabindex="0"
            (click)="openPartnerDialog(null)">
            <i class="icon-plus-circle2 mr-2"></i>
            Add Partner
        </button>
    </div>

    <div class="hub-partners-panel" @list>

        <div class="hub-partner" *ngFor="let projectPartner of projectPartners; trackBy: trackByPartner" @partners>

            <div class="icon-and-info">
                <div class="partner-icon">
                    <i class="icon-collaboration btn btn-success rounded-round btn-icon legitRipple"></i>
                </div>

                <div class="partner-info">
                    <div class="partner-name-line">

                        <div class="partner-organization">
                            {{projectPartner.name}}
                            <span class="partner-poc" *ngIf="projectPartner.isPoc">
                                (Point of Contact)
                            </span>
                        </div>

                    </div>
                    <div class="partner-contact text-success" *ngIf="!projectPartner.email">
                        {{projectPartner.contactInfo}}
                    </div>
                    <div class="partner-contact" *ngIf="projectPartner.email">
                        <a href="mailto: {{projectPartner.email}}">
                            {{projectPartner.contactInfo}}
                        </a>
                    </div>

                    <div class="partner-category">
                        {{projectPartner.partnerCategory.name}}
                    </div>
                    <div class="partner-role">
                        {{projectPartner.partnerRole.name}}
                    </div>
                </div>
            </div>

            <div class="partner-action-buttons">
                <button (click)="openPartnerDialog(projectPartner)" tooltip="Edit partner">
                    <i class="icon-pencil"></i>
                </button>
                <button (click)="removeProjectPartner(projectPartner)" tooltip="Remove partner">
                    <i class="icon-trash"></i>
                </button>
            </div>

        </div>

    </div>

</div>

<div *ngIf="project.projectPartners.length == 0"
    class="alert alert-info alert-styled-left shadow-0">
    There are no partners defined yet.
</div>
