import { Component, inject } from '@angular/core';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';
import { RelationsTypeaheadService } from '../services/relations-typeahead.service';
import { ProjectInfoModel, ProjectRelationModel } from '../../../../../hub_schema/hubTypes';
import { FormControl } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-whole-system-relations-edit',
  templateUrl: './whole-system-relations-edit.component.html',
  styleUrls: ['./whole-system-relations-edit.component.scss']
})
export class WholeSystemRelationsEditComponent extends HubRecordEditorBase {
    private relationsTypeaheadService: RelationsTypeaheadService = inject(RelationsTypeaheadService);
    
    public relatedStrategies: ProjectInfoModel[];
    public relatedProjects: ProjectInfoModel[];
    public relatedPrograms: ProjectInfoModel[];

    public strategyTypeahead: FormControl = new FormControl('');
    public strategySuggestions$: Observable<ProjectInfoModel[]>;

    public projectTypeahead: FormControl = new FormControl('');
    public projectsSuggestions$: Observable<ProjectInfoModel[]>;

    public programTypeahead: FormControl = new FormControl('');
    public programSuggestions$: Observable<ProjectInfoModel[]>;

    public ngOnInit(): void {
        const relations = this.projectEditForm?.controls.relations.value as ProjectRelationModel[];
        this.relatedStrategies = relations.filter(r => r.relatedProject?.projectTypeName === 'Strategy').map(r => r.relatedProject!);
        this.relatedProjects = relations.filter(r => r.relatedProject?.projectTypeName === 'Project').map(r => r.relatedProject!);
        this.relatedPrograms = relations.filter(r => r.relatedProject?.projectTypeName === 'Program Management').map(r => r.relatedProject!);

        // wireup typeaheads
        this.strategySuggestions$ = this.strategyTypeahead.valueChanges
        .pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap((term: string) =>
                this.relationsTypeaheadService.getStrategiesByNamePrefix(term))
        );

        this.projectsSuggestions$ = this.projectTypeahead.valueChanges
        .pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap((term: string) =>
                this.relationsTypeaheadService.getProjectsByNamePrefix(term))
        );

        this.programSuggestions$ = this.programTypeahead.valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                switchMap((term: string) =>
                    this.relationsTypeaheadService.getProgramsByNamePrefix(term))    
            );
    }

    // #region Related Strategies

    public isStrategyDisabled(strategy: ProjectInfoModel): boolean {
        const existingStrategyIds = this.projectEditForm?.controls.relations.value.map(s => s.relatedProjectId);
        return existingStrategyIds.includes(strategy.projectId) || this.isRelationDisabledDueToStatus(strategy);
    }

    public addRelatedStrategy(e: MatAutocompleteSelectedEvent) {
        const project = e.option.value as ProjectInfoModel;

        const newRelation = {
            projectId: this.project.projectId,
            relatedProjectId: project.projectId,            
        };

        this.relatedStrategies.push(project);
        this.projectEditForm?.controls.relations.value.push(newRelation);

        this.projectEditForm?.controls.relations.markAsDirty();
        this.strategyTypeahead.setValue('');
    }

    public deleteRelatedStrategy(project: ProjectInfoModel): void {
        // remove from UI
        this.relatedStrategies = this.relatedStrategies.filter(p => p.projectId !== project.projectId);

        // remove from Project
        const indexToRemove = this.projectEditForm?.controls.relations.value.findIndex(r => r.relatedProjectId === project.projectId);
        this.projectEditForm?.controls.relations.value.splice(indexToRemove, 1);
        this.projectEditForm?.controls.relations.markAsDirty();
    }

    // #endregion Related Strategies

    // #region Related Projects

    public isRelatedProjectDisabled(project: ProjectInfoModel): boolean {        
        const existingProjectIds = this.projectEditForm?.controls.relations.value.map((p) => p.relatedProjectId);
        return existingProjectIds.includes(project.projectId) || this.isRelationDisabledDueToStatus(project);
    }

    public addRelatedProject(e: MatAutocompleteSelectedEvent) {
        const project = e.option.value as ProjectInfoModel;

        const newRelation = {
            projectId: this.project.projectId,
            relatedProjectId: project.projectId,            
        };

        this.relatedProjects.push(project);
        this.projectEditForm?.controls.relations.value.push(newRelation);

        this.projectEditForm?.controls.relations.markAsDirty();
        this.projectTypeahead.setValue('');
    }

    public deleteRelatedProject(project: ProjectInfoModel): void {
        // remove from UI
        this.relatedProjects = this.relatedProjects.filter(p => p.projectId !== project.projectId);

        // remove from Project
        const indexToRemove = this.projectEditForm?.controls.relations.value.findIndex(r => r.relatedProjectId === project.projectId);
        this.projectEditForm?.controls.relations.value.splice(indexToRemove, 1);
        this.projectEditForm?.controls.relations.markAsDirty();
    }

    // #endregion Related Projects


    // #region Related Program Management

    public isProgramDisabled(project: ProjectInfoModel): boolean {        
        const existingProjectIds = this.projectEditForm?.controls.relations.value.map((p) => p.relatedProjectId);
        return existingProjectIds.includes(project.projectId) || this.isRelationDisabledDueToStatus(project);
    }

    public addProgram(e: MatAutocompleteSelectedEvent): void {
        const project = e.option.value as ProjectInfoModel;

        const newRelation = {
            projectId: this.project.projectId,
            relatedProjectId: project.projectId,            
        };

        this.relatedPrograms.push(project);
        this.projectEditForm?.controls.relations.value.push(newRelation);

        this.projectEditForm?.controls.relations.markAsDirty();
        this.programTypeahead.setValue('');
    }

    public deleteProgram(project: ProjectInfoModel): void {
        // remove from UI
        this.relatedPrograms = this.relatedPrograms.filter(p => p.projectId !== project.projectId);

        // remove from Project
        const indexToRemove = this.projectEditForm?.controls.relations.value.findIndex(r => r.relatedProjectId === project.projectId);
        this.projectEditForm?.controls.relations.value.splice(indexToRemove, 1);
        this.projectEditForm?.controls.relations.markAsDirty();
    }



    // #endregion Related Program Management

    protected isRelationDisabledDueToStatus(option: ProjectInfoModel): boolean {
        const unacceptableStatuses: string[] = ['Completed', 'Canceled'];
        return unacceptableStatuses.includes(option.statusName!);
    }
}
