<div class="intresult" *ngIf="intermediateResult">
    <div class="row mb-2">
            <div class="number">
                <span class="btn bg-indigo-300 rounded-round btn-icon btn-sm legitRipple mr-3"><span
                        class="letter-icon">{{irIndex + 1}}</span></span>
            </div>
            <div class="title">
                <h5 class="text-indigo">
                    <span class="font-weight-bold">
                        Intermediate Result -
                    </span> {{intermediateResult?.indicatorName}} <span
                        class="text-muted font-size-sm">({{intermediateResult.unit}})</span>
                    <div *ngIf="intermediateResult?.hasLeveragedIntermediateResult" class="intermediate-leverage">Leverage Indicator: <span class="text-muted font-size-sm">{{intermediateResultLeverage}}</span></div>
                </h5>
            </div>
    </div>

    <div class="row mb-2">
        <div [class]="intermediateResult.irStatistics.hasTargets ? 'col-md-7' : 'col'">

            <div *ngIf="intermediateResult.isKeyResult" class="badge badge-flat border-primary text-primary-600 mb-2 ml-5">
                Key Result
            </div>

            <p class="record-description ml-5">{{intermediateResult?.statement}}</p>

            <div class="font-size-lg m-1 ml-5 text-indigo-300"
                *ngIf="intermediateResult?.parentIntermediateResult?.project">
                <i class="align-baseline mr-1 icon-tree7"></i>
                <span class="font-weight-bold">Linked to:</span>
                {{intermediateResult?.parentIntermediateResult?.project?.name}}
            </div>
        </div>
        <div
            [class]="intermediateResult.irStatistics.hasTargets || intermediateResult.irStatistics.hasProgress ? 'col-md-5 pr-4' : 'd-none'">

            <div *ngIf="intermediateResult.irStatistics.hasTargets || intermediateResult.irStatistics.hasProgress"
                class="d-flex flex-nowrap mt-2 pr-4" style="justify-content: flex-start;">

                <mat-checkbox [(ngModel)]="areDetailsShowing" color="primary">Show Details
                </mat-checkbox>
            </div>

            <!-- PROGRESS BAR -->
            <div *ngIf="!(isStrategy && intermediateResult.scaleMeasureDefinitions.length)">
                <div *ngIf="intermediateResult.irStatistics.hasTargets">

                    <div class="progress mt-1 mb-2" style="height: 0.5rem;">
                        <div class="progress-bar bg-success"
                            [style.width.%]="intermediateResult.irStatistics.totalProgressPercent * 100">
                        </div>
                    </div>

                    <div>
                        <span class="font-weight-bold">
                            {{intermediateResult.irStatistics.totalProgressPercent | percent}}</span> achieved towards target of
                        <span class="font-weight-bold">{{intermediateResult.irStatistics.lastTargetValue | number: '0.0-0'}}
                            {{intermediateResult.unit}}</span>
                    </div>

                </div>

                <div style="margin-top: 10px;" [innerHtml]="getCurrentPeriodProgressInfo(intermediateResult)">
                </div>
            </div>

        </div>
    </div>

    <!-- IR Details -->

    <div class="row pl-5 pr-3" *ngIf="areDetailsShowing" #detailsPanel>
        <div class="col-md-12" *ngIf="!detailsLoading">
            <h5 class="text-success">Intermediate Result Start, Progress and Target Values</h5>
            <div class="table-responsive mb-2 mr-3">
                <table class="table table-bordered table-border-dashed metric-table">
                    <thead>
                        <tr class="table-border-double">
                            <th width="20%" class="text-right">Value Type</th>
                            <th width="30%" class="text-right">Date</th>
                            <th class="text-right">Value</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let target of intermediateResult.targets;">
                            <td class="text-right">
                                <span class="date-label text-success">Target</span>
                            </td>
                            <td class="text-right">
                                <span class="d-block">
                                    {{target?.targetDate | date}}
                                </span>
                            </td>
                            <td class="text-right">
                                <div>
                                    <span class="value" *ngIf="intermediateResult.scaleMeasureDefinitions.length">
                                        {{getScaleLabel(target?.targetValue)}}
                                    </span>
                                    <span class="value" *ngIf="!intermediateResult.scaleMeasureDefinitions.length">
                                        {{target?.targetValue | number:'':'en-US'}}
                                    </span>
                                    <span class="unit">
                                        {{intermediateResult.unit}}
                                    </span>
                                </div>
                                <div *ngIf="intermediateResult.scaleMeasureDefinitions.length">
                                    {{getScaleDescription(target?.targetValue)}}
                                </div>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngFor="let progress of cummulativeProgress">

                        <tr *ngIf="!progress.isStart">
                            <td class="text-right">
                                <span class="date-label text-dark">Progress</span>
                            </td>
                            <td class="text-right">
                                <span class="d-block">
                                    {{progress?.progressDate | date}}
                                </span>
                            </td>
                            <td class="text-right">
                                <span class="value" *ngIf="intermediateResult.scaleMeasureDefinitions.length">
                                    {{getScaleLabel(progress?.progressValue)}}
                                </span>
                                <span class="value" *ngIf="!intermediateResult.scaleMeasureDefinitions.length">
                                    {{progress?.progressValue | number:'':'en-US'}}
                                </span>
                                <span class="unit">
                                    {{intermediateResult.unit}}
                                </span>

                                <div *ngIf="intermediateResult.scaleMeasureDefinitions.length">
                                    {{getScaleDescription(progress?.progressValue)}}
                                </div>

                                <div class="attribution" *ngIf="progress.attributionLevel">
                                    <span>{{progress.attributionLevel.name}}</span>
                                </div>

                                <div class="attribution-text">
                                    {{progress?.attributionDescription}}
                                </div>

                                <div *ngIf="isStrategy" class="mt-1">
                                    <a class="badge-primary badge font-size-lg"
                                        [routerLink]="'/portfolio/' + progress.childProjectId">{{progress.childProjectName}}</a>
                                </div>

                            </td>
                        </tr>

                        <tr *ngIf="progress.isStart">
                            <td class="text-right">
                                <span class="date-label text-primary">Start</span>
                            </td>
                            <td class="text-right">
                                <span class="d-block">
                                    {{progress?.progressDate | date}}
                                </span>
                            </td>
                            <td class="text-right">
                                <span class="value" *ngIf="intermediateResult.scaleMeasureDefinitions.length">
                                    {{getScaleLabel(progress?.progressValue)}}
                                </span>
                                <span class="value" *ngIf="!intermediateResult.scaleMeasureDefinitions.length">
                                    {{progress?.progressValue | number:'':'en-US'}}
                                </span>
                                <span class="unit">
                                    {{intermediateResult.unit}}
                                </span>

                                <div *ngIf="intermediateResult.scaleMeasureDefinitions.length">
                                    {{getScaleDescription(progress?.progressValue)}}
                                </div>

                                <div class="attribution" *ngIf="progress.attributionLevel">
                                    <span>{{progress.attributionLevel.name}}</span>
                                </div>
                                <div class="attribution-text">
                                    {{progress?.attributionDescription}}
                                </div>

                                <div *ngIf="isStrategy" class="mt-1">
                                    <a class="badge-primary badge font-size-lg"
                                        [routerLink]="'/portfolio/' + progress.childProjectId">{{progress.childProjectName}}</a>
                                </div>

                            </td>
                        </tr>

                    </tbody>

                </table>
            </div>


        </div>

        <app-modal-spinner *ngIf="detailsLoading"></app-modal-spinner>
    </div>

</div>
