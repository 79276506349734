import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { OpenCreateRecordDialogService } from '../../core/services/open-create-record-dialog.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateHubRecordDialogComponent } from '../create-hub-record-dialog/create-hub-record-dialog.component';
import { LogoutNotificationComponent } from '../logout-notification/logout-notification.component';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from './layout/header/header.component';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
// import { NotificationComponent } from '../notification/notification.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    public gaTrackingId: string;
    public title: string = 'Hub - Home';
    public isSidebarOpened: boolean = true;
    public toggleQuickLinksSidebar: boolean = false;
    private createHubRecordRequestedSubscription: Subscription;
    private autoLogoutDialogReference: MatDialogRef<LogoutNotificationComponent, any>;
    public showBanner: boolean = false;

    @ViewChild("header")
    public header: HeaderComponent;

    constructor(
        private authService: AuthService,
        private openCreateRecordDialogService: OpenCreateRecordDialogService,
        private dialogService: MatDialog
    ) {
        this.authService.showAutoLogoutNotification.subscribe(() => {
            this.autoLogoutDialogReference = this.dialogService.open(LogoutNotificationComponent, {
                width: '640px',
                disableClose: true
            });
        });
        this.authService.closeAutoLogoutNotification.subscribe(() => {
            if (this.autoLogoutDialogReference) {
                this.dialogService.closeAll();
            }
        });
    }

    ngOnInit() {
        this.authService.setupAuthorizationCallback();
        this.showBanner = environment.showNonProdNotification;

        this.createHubRecordRequestedSubscription = this.openCreateRecordDialogService.createHubRecordRequested.subscribe(() => {
            this.dialogService.open(CreateHubRecordDialogComponent, {
                width: '800px'
            });
        });
    }

    ngAfterViewInit() {
        //M.B. 06/22/2023 I removed the pop-up saying the hub has been updated.  Wanted to be sure that the
        //LocalStorage cache setters/getters for that welcome page can be safely removed
        // const hasSeen44WelcomeItem = LocalStorageService.getItem('has-seen-4-4-welcome');

        setTimeout(() => {
            const isSidebarNavExpandedItem = LocalStorageService.getItem('isSidebarNavOpen');

            if (isSidebarNavExpandedItem) {
                const isSidebarNavExpanded: boolean = isSidebarNavExpandedItem == 'true';
                this.header.isSidebarExpanded = isSidebarNavExpanded;
            }
            else {
                this.header.isSidebarExpanded = true;
                LocalStorageService.setItem('isSidebarNavOpen', true);
            }
        });
    }

    public ngOnDestroy() {
        if (this.createHubRecordRequestedSubscription) {
            this.createHubRecordRequestedSubscription.unsubscribe();
        }
        if (this.authService.showAutoLogoutNotification) {
            this.authService.showAutoLogoutNotification.unsubscribe();
        }
        if (this.authService.closeAutoLogoutNotification) {
            this.authService.closeAutoLogoutNotification.unsubscribe();
        }
    }

    public isLoggedIn(): boolean {
        return this.authService.isLoggedIn();
    }

}
