<div class="hub-content-panel" >
    <ul class="nav">
        <li class="nav-item" [ngClass]="{'active': isTeamVisible}" (click)="activateTeam($event)">
            <a class="nav-link" href="#">
                <i class="icon-users"></i>
                <span>Team</span>
            </a>
        </li>
        <li class="nav-item" [ngClass]="{'active': !isTeamVisible}" (click)="activatePartners($event)">
            <a class="nav-link" href="#">
                <i class="icon-collaboration"></i>
                <span>Partners</span>
            </a>
        </li>
    </ul>

    <app-team-panel *ngIf="isTeamVisible" (teamPocSelected)="teamPocSelected()"></app-team-panel>
    <app-partners-panel *ngIf="!isTeamVisible" (partnerPocSelected)="partnerPocSelected()"></app-partners-panel>
</div>
