<app-modal-spinner *ngIf="isBusy"></app-modal-spinner>

<div class="hub-record-edit-container" *ngIf="project && projectEditForm">
    <div class="record-edit-navs hub-custom-scrollbar">
        <ul class="section-list left" id="listRecordMenu"
            data-intro="Use the navigation to browse through the various property tabs for this record-type"
            data-step="4">
            <li data-view-setting="1" data-detail-panel="none" (click)="activateTab('summary')"
                [class.selected]="activeTab === 'summary'">
                <div class="media-body">
                    <span class="link font-size-lg">Summary</span>

                    <span *ngIf="!isSummaryValid || !isProjectNameValid"
                          matTooltipShowDelay="500"
                          matTooltip="This tab contains invalid data that must be corrected before the record can be saved.">
                        <i class="icon-exclamation"></i>
                    </span>

                </div>
            </li>

            <li data-view-setting="1" data-detail-panel="none" (click)="activateTab('team')"
                [class.selected]="activeTab === 'team'">
                <div class="media-body">
                    <span class="link font-size-lg">Team</span>

                    <span *ngIf="!isTeamValid"
                          matTooltipShowDelay="500"
                          matTooltip="This tab contains invalid data that must be corrected before the record can be saved.">
                        <i class="icon-exclamation"></i>
                    </span>

                </div>
            </li>

            <li data-view-setting="1" data-detail-panel="none" (click)="activateTab('location')"
                [class.selected]="activeTab === 'location'">
                <div class="media-body">
                    <span class="link font-size-lg">Location</span>

                    <span *ngIf="!isLocationValid" matTooltipShowDelay="500"
                          matTooltip="This tab contains invalid data that must be corrected before the record can be saved.">
                        <i class="icon-exclamation"></i>
                    </span>

                </div>
            </li>

            <li data-view-setting="1" data-detail-panel="none" (click)="activateTab('relations')"
                [class.selected]="activeTab === 'relations'">
                <div class="media-body">
                    <span href="#" class="link font-size-lg">Relations</span>

                    <!-- <span *ngIf="hasValidationErrors()"
                          matTooltipShowDelay="500"
                          matTooltip="This tab contains invalid data that must be corrected before the record can be saved.">
                        <i class="icon-exclamation"></i>
                    </span> -->

                </div>
            </li>


            <li data-view-setting="2" data-detail-panel="strategic-pillars" (click)="activateTab('strategic-pillars')"
                [class.selected]="activeTab === 'strategic-pillars'"
                *ngIf="project?.projectType?.name?.toLowerCase() === 'strategy'">
                <div class="media-body">
                    <span class="link font-size-lg">Strategic Pillars</span>
                </div>
            </li>

            <li *ngIf="!isProgramManagement && !isWholeSystem" data-view-setting="2"
                data-detail-panel="outcomes" (click)="activateTab('outcomes')"
                [class.selected]="activeTab === 'outcomes'">
                <div class="media-body">
                    <span class="link font-size-lg">Outcomes</span>
                </div>
            </li>

            <li *ngIf="!isProgramManagement && !isWholeSystem" data-view-setting="2"
                data-detail-panel="int-results" (click)="activateTab('intermediate')"
                [class.selected]="activeTab === 'intermediate'">
                <div class="media-body">
                    <span class="link font-size-lg">Intermediate Results</span>
                </div>
            </li>

            <li data-view-setting="2" data-detail-panel="outputs" (click)="activateTab('outputs')"
                [class.selected]="activeTab === 'outputs'">
                <div class="media-body">
                    <span class="link font-size-lg">Outputs</span>
                </div>
            </li>

            <li data-view-setting="1" data-detail-panel="none" (click)="activateTab('financials')"
                [class.selected]="activeTab === 'financials'">
                <div class="media-body">
                    <span class="link font-size-lg">Financials</span>

                    <span *ngIf="!isFinancialsValid" matTooltipShowDelay="500"
                          matTooltip="This tab contains invalid data that must be corrected before the record can be saved.">
                        <i class="icon-exclamation"></i>
                    </span>

                </div>
            </li>

            <li data-view-setting="1" data-detail-panel="none" (click)="activateTab('attributes')"
                *ngIf="!isProgramManagement" [class.selected]="activeTab === 'attributes'">
                <div class="media-body">
                    <span class="link font-size-lg">Additional Information</span>

                    <!-- <span *ngIf="hasValidationErrors()" matTooltipShowDelay="500"
                          matTooltip="This tab contains invalid data that must be corrected before the record can be saved.">
                        <i class="icon-exclamation"></i>
                    </span> -->

                </div>
            </li>

            <li data-view-setting="1" data-detail-panel="none" (click)="activateTab('associatedFiles')"
                [class.selected]="activeTab === 'associatedFiles'">
                <div class="media-body">
                    <span class="link font-size-lg">Associated Files</span>
                </div>
            </li>

        </ul>
    </div>

    <div class="record-edit-heading" [formGroup]="projectEditForm"
        [ngClass]="{'project': project.projectType.name === 'Project', 'strategy': project.projectType.name === 'Strategy'}">

        <div class="record-name">
            <div class="labl">{{project.projectType.name | titlecase}}</div>
            <div class="val">{{project.name}}</div>
        </div>

        <div class="divider"></div>

        <div class="record-number">
            <div class="labl">{{project.projectType.name | titlecase}} ID</div>
            <div class="val">{{project.projectId}}</div>
        </div>

        <div class="divider"></div>

        <div class="record-status" *ngIf="isUserAllowedToEditStatus(); else statusReadOnly">
            <div class="labl">
                <span>Record Status</span>
                <mat-icon *ngIf="isUserAllowedToEditStatus()" (click)="statusDropdown.open()">edit</mat-icon>
            </div>

            <mat-select #statusDropdown formControlName="statusId">
                <mat-option *ngFor="let status of projectStatuses" [value]="status.statusId" [disabled]="isStatusDisabled(status)">{{status.name}}</mat-option>
            </mat-select>

        </div>

        <ng-template #statusReadOnly>
            <div class="record-status">
                <div class="labl">
                    <span>Record Status</span>
                </div>

                <div class="val">{{project.status.name}}</div>
            </div>
        </ng-template>


        <div class="record-actions">
            <a routerLink="/portfolio/{{ project?.projectId }}" matTooltip="View record" matTooltipShowDelay="500">
                <mat-icon>visibility</mat-icon>
                <span>VIEW RECORD</span>
            </a>

            <button (click)="saveProject()" [disabled]="projectEditForm.pristine || !isProjectValid || !isProjectNameValid"
                class="save-button  {{savingState}}"
                [ngClass]="{ invalid: !isValid }"
                [matTooltip]="getSaveButtonTooltip()" matTooltipShowDelay="500">
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
                <i class="icon-checkmark4"></i>
                <span>SAVE</span>
            </button>

        </div>

    </div>

    <div class="record-edit-body hub-custom-scrollbar">

        <div [ngSwitch]="activeTab" class="record-edit-component-panel">

            <div *ngSwitchCase="projectTabs.summary" class="p-3" style="position: relative;">

                <a class="delete-button-link" (click)="deleteHubRecordRequested()" *ngIf="canUserDeleteRecord()"
                        data-popup="tooltip" data-placement="bottom">
                    Delete Record
                </a>

                <app-summary-edit></app-summary-edit>

            </div>

            <app-intermediate-results-edit-container *ngSwitchCase="projectTabs.intermediate">
            </app-intermediate-results-edit-container>

            <app-team-partners-edit-container *ngSwitchCase="projectTabs.team"></app-team-partners-edit-container>

            <app-location-edit-container #locationEditContainer *ngSwitchCase="projectTabs.location"></app-location-edit-container>

            <app-relations-edit-container *ngSwitchCase="projectTabs.relations"></app-relations-edit-container>

            <app-strategic-pillars-edit-container *ngSwitchCase="projectTabs.strategicPillars">
            </app-strategic-pillars-edit-container>

            <app-additional-information-edit-container *ngSwitchCase="projectTabs.attributes"></app-additional-information-edit-container>

            <app-outcomes-edit-container *ngSwitchCase="projectTabs.outcomes"></app-outcomes-edit-container>

            <app-outputs-edit-container *ngSwitchCase="projectTabs.outputs"></app-outputs-edit-container>

            <app-financials-edit-container *ngSwitchCase="projectTabs.financials"></app-financials-edit-container>

            <app-associated-files-edit-container *ngSwitchCase="projectTabs.associatedFiles">
            </app-associated-files-edit-container>

        </div>

    </div>

</div>

<app-unauthorized [project]="project" *ngIf="unauthorized"></app-unauthorized >

