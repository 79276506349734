<div class="collections-detail-container" *ngIf="goals">
    <div class="heading">
        <h4>
            <span class="font-weight-semibold text-success-800">Collection</span>
        </h4>
    </div>
    <div class="collections-detail-content" *ngIf="recordCollection">
        <div class="row collections-detail-media">
            <div class="col-md-12">
                <div class="media flex-column flex-sm-row mt-0 mb-3 prj-collection">
                    <div class="mr-sm-3 mb-2 mb-sm-0">
                        <i class="icon-books icon-3x text-primary"></i>
                    </div>
                    <div class="media-body">
                        <div class="d-md-flex justify-content-between">
                            <h3>
                                {{recordCollection.name}}
                            </h3>
                            <span>
                                <a class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                    routerLink="/collections" placement="left" tooltip="Show all Collections">
                                    <i class="icon-grid"></i>
                                </a>
                                <button type="button" *ngIf="canUserEdit()"
                                    class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                    tooltip="Edit Collection" placement="left"
                                    (click)="openEditCollectionDialog()"><i class="icon-pencil"></i></button>

                                <button type="button" *ngIf="canUserEdit()"
                                    class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                    tooltip="Delete Collection" placement="left"
                                    (click)="deleteProjectCollectionRequested()"><i class="icon-trash"></i></button>
                            </span>
                        </div>

                        <ul class="list-inline list-inline-dotted text-muted mb-2">
                            <li class="list-inline-item font-weight-semibold">{{recordCollection.recordCollectionType.name}}</li>
                            <li class="list-inline-item">
                                <i class="icon-user mr-2"></i>{{recordCollection.owner.fullName}}
                            </li>
                        </ul>
                        <span class="d-block text-info-800 font-weight-semi-bold pb-2 mb-2 sb-b">{{recordCollection?.recordCollectionProjects?.length}}
                            Projects</span>
                        <div [innerHtml]="recordCollection.description"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="collections-detail-content-panel hub-custom-scrollbar">
            <div class="cardsView card-columns align-content-lg-start mt-2 pl-4 pr-2 card-metrics profile-cards"
                style="column-count: 4!important;" *ngIf="recordCollection?.recordCollectionProjects?.length > 0">

                <div *ngFor="let recordCollectionProject of recordCollection?.recordCollectionProjects" 
                    class="card card-record card-shadow status-{{recordCollectionProject.relatedProject.status.name}} rounded p-1">

                    <app-project-card-graphql 
                        [projectCollectionId]="recordCollection.recordCollectionId"
                        [project]="recordCollectionProject.relatedProject" [showCardDetails]="true"
                        [userIsCreator]="canUserEdit()" [addToCollection]="false"
                        [goals]="goals"
                        (deleteRecordCollectionProjectRequested)="deleteRecordCollectionProjectRequested($event)">
                    </app-project-card-graphql>

                </div>
            </div>

            <div class="col-md-12 p-3" *ngIf="collectionProjects?.length === 0">
                <div class="alert alert-info alert-styled-left shadow-0">                    
                    There are no projects in this Collection yet.
                </div>
            </div>
        </div>
    </div>
</div>

