import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'innerHtml', pure: false })
export class InnerHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(content): SafeHtml {
    let str = content;

    if (content.match(/\&.*\;/g) != null) {
      const div = document.createElement('DIV');
      div.innerHTML = content;
      str = (div.textContent || div.innerText);
    }

    return this.sanitizer.bypassSecurityTrustHtml(str);
  }
}
