<div class="row">
    <div class="col-md-12">
        <p class="text-center font-weight-bold sb-b p-1">Create a Custom Outcome</p>
    </div>
</div>

<!-- * Show option to create Outcome from scratch or from parent -->
<div class="current mb-2 p-1 body sb-b" role="tabpanel" aria-hidden="true" *ngIf="!outcomeState.isStrategy">
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex justify-content-between" *ngIf="!isStrategy">
                <div class="select-label text-info-800 d-block">
                    Will progress roll up to an outcome of a related strategy?
                </div>
                <div class="toggle-panel">
                    <span>NO</span>
                    <mat-slide-toggle color="primary" [(ngModel)]="isRollupSelected"></mat-slide-toggle>
                    <span>YES</span>
                </div>
            </div>
        </div>
    </div>
</div>

<app-custom-outcome-standalone-panel *ngIf="!isRollupSelected"></app-custom-outcome-standalone-panel>

<!-- (HUB-511) We are using [hidden] here instead of ngIf to force the related outcomes to preload, in order to avoid a UI flash -->
<app-custom-outcome-related-panel [hidden]="!isRollupSelected"></app-custom-outcome-related-panel>
