import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { Component } from '@angular/core';

@Component({
    selector: 'app-hub-stepper',
    templateUrl: './hub-stepper.component.html',
    styleUrls: ['./hub-stepper.component.scss'],
    providers: [{ provide: CdkStepper, useExisting: HubStepperComponent }]
})
export class HubStepperComponent extends CdkStepper {
    selectStepByIndex(index: number): void {
        this.selectedIndex = index;
    }
}
