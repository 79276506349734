import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '../core/guards/role-guard';
import { SharedModule } from '../shared/shared.module';
import { HubAdministrationComponent } from './components/hub-administration/hub-administration.component';
import { HubAdministrationService } from './services/hub-administration.service';
import { HubAdminTypeaheadService } from './services/hub-admin-typeahead.service';
import { AddNonTncEmployeeDialogComponent } from './components/hub-administration/add-non-tnc-employee/add-non-tnc-employee.component';
import { SystemPermissionsTabComponent } from './components/hub-administration/system-permissions-tab/system-permissions-tab.component';
import { UserBulkEditTabComponent } from './components/hub-administration/user-bulk-edit-tab/user-bulk-edit-tab.component';
import { NcsDefaultValuesTabComponent } from './components/hub-administration/ncs-default-values-tab/ncs-default-values-tab.component';
import { NcsEditService } from './components/hub-administration/ncs-default-values-tab/services/ncs-edit.service';
import { EditUserDialogComponent } from './components/hub-administration/system-permissions-tab/edit-user-dialog/edit-user-dialog.component';

const routes: Routes = [
    { path: 'administration', component: HubAdministrationComponent, canActivate: [RoleGuard] }
];

@NgModule({
    declarations: [

    HubAdministrationComponent,
    AddNonTncEmployeeDialogComponent,
    SystemPermissionsTabComponent,
    UserBulkEditTabComponent,
    NcsDefaultValuesTabComponent,
    EditUserDialogComponent,
  ],
    imports: [
        SharedModule,
        RouterModule.forChild(routes)
    ],
    providers: [
        HubAdministrationService,
        HubAdminTypeaheadService,
        NcsEditService
    ],
})
export class HubAdministrationModule { }
