import { Component } from '@angular/core';
import { style, animate, transition, trigger, query as q } from '@angular/animations';
import { ToastService } from '../../core/services/toast.service';
import { ToastMessage } from '../../shared/types/toast-message';

@Component({
  selector: 'app-hub-toast',
  templateUrl: './hub-toast.component.html',
  styleUrls: ['./hub-toast.component.scss'],
  animations: [
      trigger('panel', [
          transition(':enter', [
              style({ transform: 'translateY(-120px)', opacity: 0 }),  // initial
              animate('600ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),  // final
          ]),
          transition(':leave', [
              style({ transform: 'translateY(0)', opacity: 1 }),  // initial
              animate('600ms ease-out', style({ transform: 'translateY(-120px)', opacity: 0 })),  // final
          ])
      ])
  ]
})
export class HubToastComponent {

    constructor(
        private toastService: ToastService
    ) { }

    public get toastMessage(): ToastMessage | null {
        return this.toastService.toastMessage;
    }

    public get isToastShowing(): boolean {
        return this.toastMessage != null;
    }

    public goto(e: Event): void {
        this.toastService.requestGoto();
        e.stopPropagation();
    }

}
