export enum ViewModes {
    cards = 'cards',
    map = 'map',
}

export enum ViewModeKeys {
    showCardDetails = 'showCardDetails',
    showFinanceDetails = 'showFinanceDetails',
    showFilterDetails = 'showFilterDetails',
    showListView = 'showListView',
    viewMode = 'portfolioViewMode',
}
