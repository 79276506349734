import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { GeographyModel, NcsInterventionModel, ProjectOutcomeModel } from '../../../../../hub_schema/hubTypes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { HubLovService } from '../../../../core/services/hub-lov.service';
import { CalculationsInfoDialogComponent } from './calculations-info-dialog/calculations-info-dialog.component';
import { take } from 'rxjs';
import { OutcomeState } from '../create-outcome-dialog/types/outcome-state';

@Component({
    selector: 'app-outcome-target-panel',
    templateUrl: './outcome-target-panel.component.html',
    styleUrls: ['./outcome-target-panel.component.scss']
})
export class OutcomeTargetPanelComponent implements OnInit {
    private dialogService: MatDialog = inject(MatDialog);
    private hubLovService: HubLovService = inject(HubLovService);
    
    @Input()
    public projectOutcome: ProjectOutcomeModel;

    @Input()
    public editTargetForm: FormGroup;

    @Input()
    public showSpatialExtent: boolean = true;

    @Input()
    public showOutcomeStatement: boolean = true;

    @Input()
    public ncsExtentControl: FormControl;

    @Input()
    public outcomeState: OutcomeState;

    @Input()
    public is2030Goals: boolean;

    public get isNcsOutcome(): boolean {
        if (this.outcomeState) {
            return this.outcomeState.usingNcsCalculator;
        }

        return !!this.projectOutcome.outcome?.ncsInterventionId;
    }

    @Output()
    public pendingNcsRecalculationChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    public ncsPathwayName: string;
    public ncsIntervention: NcsInterventionModel;
    public ncsCountry: GeographyModel;
    
    public ngOnInit(): void {
        // Note: this component is used for both adding a standalone target, and also as the last step of creating a project outcome
        if (this.isNcsOutcome) {
            if (this.projectOutcome.outcome!.ncsIntervention) {
                this.ncsIntervention = this.projectOutcome.outcome!.ncsIntervention;
                this.ncsPathwayName = this.ncsIntervention.pathwayName;
            }
            else {
                this.hubLovService.getNcsIntervention(this.projectOutcome.outcome!.ncsInterventionId!).pipe(take(1)).subscribe((intervention: NcsInterventionModel) => {
                    this.ncsIntervention = intervention;
                    this.ncsPathwayName = intervention.pathwayName;
                });
            }

            if (this.projectOutcome.outcome!.ncsCountry) {
                this.ncsCountry = this.projectOutcome.outcome!.ncsCountry;
            }
            else {
                this.hubLovService.getCountry(this.projectOutcome.outcome!.ncsCountryId).pipe(take(1)).subscribe((country: GeographyModel) => {
                    this.ncsCountry = country;
                });
            }
        }

        
        // Will be passed in as input from create outcome dialog, but will be standalone everywhere else
        if (!this.ncsExtentControl) {
            this.ncsExtentControl = new FormControl(null, Validators.required)
        }
    }

    public getValidationErrorMessages(formControlName: string): string {
        const formControl = this.editTargetForm!.controls[formControlName];

        if (!formControl.touched) {
            return '';
        }

        if (formControl.errors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(formControl.errors)) {
                if (key == 'required') {
                    errorMessages.push(key);
                }
                else {
                    errorMessages.push(formControl.errors[key]);
                }
            }
            return errorMessages.join(', ');
        }

        return '';
    }

    // #region NCS

    

    public getExtentValidationErrorMessage(): string {
        if (!this.ncsExtentControl.touched) {
            return '';
        }

        if (this.ncsExtentControl.errors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(this.ncsExtentControl.errors!)) {
                if (key == 'required') {
                    errorMessages.push(key);
                }
                else {
                    errorMessages.push('You have entered a value that is below the minimum threshhold value.');
                }
            }
            return errorMessages.join(', ');
        }
        return '';
    }

    public get targetValue(): number {
        return this.editTargetForm.controls.targetValue.value;
    }

    public targetValueChanged(targetValue: number) {
        this.editTargetForm.controls.targetValue.setValue(targetValue);
        this.editTargetForm.controls.targetValue.markAsTouched();
    }

    public pendingRecalculationChanged(isRecalcPending: boolean): void {
        this.pendingNcsRecalculationChanged.emit(isRecalcPending);
    }

    public showCalculations(): void {
        this.dialogService.open(CalculationsInfoDialogComponent, {
            width: '600px',
            data: {
                ncsInterventionId: this.projectOutcome.outcome!.ncsInterventionId
            }
        });
    }

    // #endregion NCS

    public getUnitName(): string | undefined {  
        if (this.is2030Goals) {
            return this.projectOutcome.outcome!.outcomeUnit?.name;                        
        }
        
        return this.projectOutcome.customOutcome!.unit;
    }

    public isTargetValueInvalid(): boolean {
        const formControl: FormControl = this.editTargetForm.controls.targetValue as FormControl;
        return formControl.touched && formControl.invalid;
    }


}
