<div class="paginator">
    <div class="page-item">
        <button [disabled]="isFirstPageDisabled()" (click)="goToFirstPage()"
            class="page-link legitRipple minWidthNextPrev">
            &lt;&lt;
        </button>
    </div>
    <div class="page-item">
        <button [disabled]="isPreviousPageDisabled()" (click)="goToPreviousPage()"
            class="page-link legitRipple minWidthNextPrev">
            &lt;
        </button>
    </div>
    <ul class="pagesContainer pagination align-self-end">
        <li *ngFor="let page of pages" class="page-item"
            [ngClass]="{active: page === currentPage}">
            <button (click)="goToPage(page)" class="page-link legitRipple">
                {{page + 1}}
            </button>
        </li>
    </ul>
    <div class="page-item">
        <button [disabled]="isGotoNextPageDisabled()" (click)="goToNextPage()" 
            class="page-link legitRipple minWidthNextPrev">
            &gt;
        </button>
    </div>
    <div class="page-item">
        <button [disabled]="isGotoLastPageDisabled()" (click)="goToLastPage()" 
            class="page-link legitRipple minWidthNextPrev">
            &gt;&gt;
        </button>
    </div>

</div>

