import { Component } from '@angular/core';
import { HubRecordEditorBase } from '../_hub_record_editor_base';

enum tabs {
    donorkeywords = 1,
    levers
  }
  
@Component({
  selector: 'app-additional-information-edit-container',
  templateUrl: './additional-information-edit-container.component.html',
  styleUrls: ['./additional-information-edit-container.component.scss']
})
export class AdditionalInformationEditContainerComponent extends HubRecordEditorBase {
    public tabs = tabs;
    public activeTab: tabs = tabs.donorkeywords;

    public setTabTo(tab: tabs, e): void {
        this.activeTab = tab;
        e.preventDefault();
      }
    
    
}
