import { Component, inject } from '@angular/core';
import { GeographyModel, NcsGeographyInterventionModel, NcsInterventionModel, NcsPathwayModel } from '../../../../../hub_schema/hubTypes';
import { NcsEditService } from './services/ncs-edit.service';
import { HubLovService } from '../../../../core/services/hub-lov.service';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { forkJoin, take } from 'rxjs';
import { ErrorService } from '../../../../core/services/error.service';

@Component({
  selector: 'app-ncs-default-values-tab',
  templateUrl: './ncs-default-values-tab.component.html',
  styleUrls: ['./ncs-default-values-tab.component.scss']
})
export class NcsDefaultValuesTabComponent {
    private ncsEditService: NcsEditService = inject(NcsEditService);
    private hubLovService: HubLovService = inject(HubLovService);
    private errorService: ErrorService = inject(ErrorService);

    private monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    public ncsPathways: NcsPathwayModel[];

    private _selectedPathway: NcsPathwayModel | null;

    public get selectedPathway(): NcsPathwayModel | null {
        return this._selectedPathway;
    }

    public set selectedPathway(value: NcsPathwayModel | null) {
        this._selectedPathway = value;
        this.selectedIntervention = null;
    }

    private _selectedIntervention: NcsInterventionModel | null;

    public get selectedIntervention(): NcsInterventionModel | null {
        return this._selectedIntervention;
    }

    public set selectedIntervention(value: NcsInterventionModel | null) {
        this._selectedIntervention = value;
        if (!value) {
            return;
        }

        this.populateGeographyInterventions();
    }

    private countries: GeographyModel[];
    public interventionForm: FormGroup;
    public isSaving: boolean = false;

    public ngOnInit(): void {

        forkJoin({
            pathways: this.hubLovService.getNcsPathways(),
            countries: this.hubLovService.getCountries()
        })
        .pipe(take(1)).subscribe((results: any) => {
            this.ncsPathways = results.pathways;
            this.countries = results.countries;
        });
    }

    public populateGeographyInterventions(): void {
        if (this.selectedIntervention) {
            
            forkJoin({
                intervention: this.ncsEditService.getNcsInterventionDetail(this.selectedIntervention!.interventionId),
                geographyInterventions: this.ncsEditService.getNcsGeographyInterventions(this.selectedIntervention!.interventionId!)
            })
            .pipe(take(1)).subscribe((results: any) => {
                this.selectedIntervention!.pools = results.intervention.pools;
                const countryForms: FormArray<any> = new FormArray<any>([]);

                for (let country of this.countries) {
                    let geoIntervention = results.geographyInterventions.find(gi => gi.geographyId === country.geographyId);

                    if (!geoIntervention) {
                        // mockup a blank geo-intervention based on intervention.pools
                        geoIntervention = new NcsGeographyInterventionModel();
                        geoIntervention.geographyId = country.geographyId;
                        geoIntervention.interventionId = this.selectedIntervention!.interventionId;
                        
                        geoIntervention.geographyInterventionPools = this.selectedIntervention!.pools!.map(p => {
                            const geoInfoPool = {
                                geographyInterventionPoolId: undefined,
                                geographyInterventionId: geoIntervention!.geographyInterventionId!,
                                poolId: p.poolId,
                                defaultValue: undefined
                            };

                            return geoInfoPool;
                        });

                    }
                    const poolsArray: FormArray<any> = new FormArray<any>([]);

                    for (let geoInfoPool of geoIntervention.geographyInterventionPools!) {
                        // there may be missing pools?
                        const poolForm = new FormGroup({
                            geographyInterventionPoolId: new FormControl({
                                value: geoInfoPool.geographyInterventionPoolId ? geoInfoPool.geographyInterventionPoolId : undefined,
                                disabled: !geoInfoPool.geographyInterventionPoolId
                            }),
                            poolId: new FormControl(geoInfoPool.poolId),
                            poolValue: new FormControl(geoInfoPool.defaultValue, this.validateNumericOrEmpty)
                        });
                        poolsArray.push(poolForm);
                    }

                    let updatedInfo = '';

                    if (geoIntervention.updatedOn) {
                        const updatedOn = new Date(geoIntervention.updatedOn);
                        const updateDate = this.monthNames[updatedOn.getMonth()] + ' ' + updatedOn.getDate() + ', ' + updatedOn.getFullYear();
                        updatedInfo = `This row was modified by ${geoIntervention.updatedBy!.fullName} on ${updateDate} at ${updatedOn.toLocaleTimeString()}`
                    }

                    const countryForm = new FormGroup({
                        geographyInterventionId: new FormControl({
                            value: geoIntervention.geographyInterventionId ? geoIntervention.geographyInterventionId : undefined,
                            disabled: !geoIntervention.geographyInterventionId
                        }),
                        geographyId: new FormControl(country.geographyId),
                        countryCode: new FormControl((country as any).iso3Code),
                        countryName: new FormControl(country.name),
                        baselineValue: new FormControl(geoIntervention.baselineValue, [this.validateNumericOrEmpty, Validators.min(0), Validators.max(100)]),
                        lastModifiedByInfo: new FormControl(updatedInfo),
                        pools: poolsArray
                    });
                    countryForms.push(countryForm);
                }

                this.interventionForm = new FormGroup({
                    interventionId: new FormControl(this.selectedIntervention!.interventionId),
                    countries: countryForms
                });

            });
        }
    }

    private validateNumericOrEmpty(control): any {
        const value = control.value;

        if (!value) {
            return null;
        }

        if (isNaN(value)) {
            return { 'invalid': 'Must be numeric' }
        }
    }

    public get isSaveButtonDisabled(): boolean {
        if (!this.interventionForm) {
            return true;
        }
        return this.interventionForm.pristine || this.interventionForm.invalid;
    }

    public reset(): void {
        this.selectedIntervention = null;
        this.selectedPathway = null;
    }

    public saveAll(): void {

        this.isSaving = true;
        const formData = this.interventionForm.value;

        const intervention: any = {
            interventionId: this.interventionForm.controls.interventionId.value,
            geographyInterventions: []
        };

        for (let countryForm of (this.interventionForm.controls.countries as FormArray).controls) {
            if (countryForm.dirty) {
                const country = countryForm.value;

                const gi: any = {
                    geographyInterventionId: country.geographyInterventionId,
                    interventionId: intervention.interventionId,
                    geographyId: country.geographyId,
                    baselineValue: country.baselineValue,
                    geographyInterventionPools: country.pools.map(p => {
                        const pool = {
                            geographyInterventionPoolId: p.geographyInterventionPoolId,
                            poolId: p.poolId,
                            defaultValue: p.poolValue
                        };
                        return pool;
                    })
                };

                intervention.geographyInterventions.push(gi);
            }
        }

        this.ncsEditService.saveNcsData(intervention).pipe(take(1)).subscribe({
            next: (result) => {
                this.reset();
                this.isSaving = false;
            },
            error: (err) => {
                this.errorService.addError(err, true);
                this.isSaving = false;
            }
        });

    }
}
