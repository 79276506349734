import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OutputLinkModel } from '../../../../../../hub_schema/hubTypes';
import { getValidationErrorMessages } from '../../../../../shared/helpers/getValidationErrorMessages';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OutputsEditService } from '../../services/outputs-edit.service';
import { urlPattern } from '../../../../../shared/helpers/urlPattern';
import { take } from 'rxjs';

@Component({
  selector: 'app-create-update-output-link-dialog',
  templateUrl: './create-update-output-link-dialog.component.html',
  styleUrls: ['./create-update-output-link-dialog.component.scss']
})
export class CreateUpdateOutputLinkDialogComponent implements OnInit {
    private dialogRef: MatDialogRef<CreateUpdateOutputLinkDialogComponent> = inject(MatDialogRef<CreateUpdateOutputLinkDialogComponent>);
    private dialogData: any = inject(MAT_DIALOG_DATA);
    private outputsEditService: OutputsEditService = inject(OutputsEditService);

    public outputLinkForm: FormGroup;
    public existingLink: OutputLinkModel;
    private projectOutputId: number;
    public isSaving: boolean = false;

    public getValidationErrorMessages = getValidationErrorMessages;

    public ngOnInit(): void {
        this.existingLink = this.dialogData.existingOutputLink;
        this.projectOutputId = this.dialogData.projectOutputId;

        this.outputLinkForm = new FormGroup({            
            outputLinkId: new FormControl({
                value: this.existingLink ? this.existingLink.outputLinkId : undefined,
                disabled: !this.existingLink
            }),
            projectOutputId: new FormControl(this.projectOutputId),
            name: new FormControl(this.existingLink ? this.existingLink.name : null, [Validators.required, Validators.maxLength(255)]),
            url: new FormControl(this.existingLink ? this.existingLink.url : null, [Validators.required, Validators.pattern(urlPattern), Validators.maxLength(2048)])
        });
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public saveLink(): void {
        this.isSaving = true;
        const link = this.outputLinkForm.value;

        if (this.existingLink) {
            this.outputsEditService.updateOutputLink(link).pipe(take(1)).subscribe((savedLink: OutputLinkModel) => {
                this.dialogRef.close(savedLink);
            });
        }
        else {
            this.outputsEditService.createOutputLink(link).pipe(take(1)).subscribe((savedLink: OutputLinkModel) => {
                this.dialogRef.close(savedLink);
            });
        }
    }
}
