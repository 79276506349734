import { Component } from '@angular/core';
import { HubRecordEditorBase } from '../_hub_record_editor_base';

enum tabs {
    financials = 1,
    notes,
    insightRecords,
    anchorRecordSelection,
}

@Component({
  selector: 'app-financials-edit-container',
  templateUrl: './financials-edit-container.component.html',
  styleUrls: ['./financials-edit-container.component.scss']
})
export class FinancialsEditContainerComponent extends HubRecordEditorBase {
    public tabs = tabs;
    activeTab: tabs = tabs.financials;

    public setTabTo(tab: tabs, $e): void {
        this.activeTab = tab;
        $e.preventDefault();
    }

    public isAnchorRecordSelectionTabVisible(): boolean {
        return this.project.status!.name.toLowerCase() === 'active' && this.project.projectType!.name.toLowerCase() !== 'program management';
    }

}
