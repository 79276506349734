<div class="d-flex project-edit-full-size" aria-hidden="false" tabindex="0">
    <div class="project-edit-items-list">
  
      <div class="project-edit-left-side hub-custom-scrollbar" style="height: calc(100vh - 257px); overflow-y: auto; overflow-x: hidden;">
          <app-output-list #outputList></app-output-list>
      </div>
  
      <div class="right-side project-edit-items-details">
         <app-output-detail [projectOutput]="outputList.selectedOutput" ></app-output-detail>
      </div>
    </div>
  </div>
  