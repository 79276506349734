export const escapeQuotesAndEnters = (input) => {
    if (!input) {
        return '';
    }

    return input.replace(/["\\]/g,
        (itm) => ({
            '"': '&quot;',
            '\\': '&#92;',
        }[itm] || itm)).replace(/(?:\r\n|\r|\n)/g, ' ');
}
