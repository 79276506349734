import { Component, Input } from '@angular/core';
import { getCurrentFiscalYear } from '../../../shared/helpers/get-current-fiscal-year';
import { InsightFinancialModel, InsightRecordModel, ProjectFinancialModel } from '../../../../hub_schema/hubTypes';

@Component({
    selector: 'app-financials-container',
    templateUrl: './financials-container.component.html',
})
export class FinancialsContainerComponent {

    public Math = Math;
    public insightFinancials;
    public getCurrentFiscalYear = getCurrentFiscalYear;

    private _financials: ProjectFinancialModel[];

    public get financials(): ProjectFinancialModel[] {
        return this._financials;
    }

    @Input()
    public set financials(newValue) {
        this._financials = newValue;
        if (!this._financials) {
            return;
        }
        const financialsAfter2019 = this._financials.filter((finance) => {
            if (finance.year >= 2019) {
                return finance;
            }
        });
        if (financialsAfter2019.length > 0) {
            this.financialsPresent = true;
        }

        const financialYears: number[] = this._financials
            .filter(f => f.year >= 2019)
            .map(f => f.year);

        this.years = this.arrayUnique(financialYears.concat(this.years)).sort((a, b) => (a - b));
    }

    @Input()
    set setInsightFinancials(newValue) {
        this.insightFinancials = newValue;
        if (!this.insightFinancials) {
            return;
        }
        const insightCount = this.insightFinancials.filter((insight) => {
            if (insight.fiscalYear >= 2019) {
                return insight;
            }
        });
        if (insightCount.length > 0) {
            this.financialsPresent = true;
        }
        const insightYears = this.insightFinancials.filter((insight) => {
            if (insight.fiscalYear >= 2019) {
                return insight;
            }
        }).map((insight) => {
            return insight.fiscalYear;
        });
        this.years = this.arrayUnique(insightYears.concat(this.years)).sort((a, b) => (a - b));
    }

    private _insightRecords: InsightRecordModel[];

    public get insightRecords(): InsightRecordModel[] {
        return this._insightRecords;
    }

    @Input()
    public set insightRecords(value: InsightRecordModel[]) {
        if (value) {
            this._insightRecords = value.sort((a: InsightRecordModel, b: InsightRecordModel) => {
                if (a.anchorRecordFlag) {
                    return -2;
                }
                if (b.anchorRecordFlag) {
                    return 2;
                }
                if (a.insightProjectName < b.insightProjectName) {
                    return -1;
                }
                if (a.insightProjectName > b.insightProjectName) {
                    return 1;
                }
                return 0;
            });
        }
    }

    @Input() financialNotes;

    public years: number[] = [];

    public ids = [
        '-public-agency-revenue',
        '-utility-revenue',
        '-multi-lateral',
        '-non-profit-partners',
        '-corporate-partners',
        '-fund-endowment',
        '-private-gift',
        '-other'
    ];
    public financialsPresent = false;

    public financialsAreNotEmpty() {
        return (this.financials && this.financials.length) || (this.insightFinancials && this.insightFinancials.length);
    }

    public isCurrentFiscalYear(year): boolean {
        if (year == getCurrentFiscalYear()) {
            return true;
        } else {
            return false;
        }

    }

    private arrayUnique(array) {
        const a = array.concat();
        for (let i = 0; i < a.length; ++i) {
            for (let j = i + 1; j < a.length; ++j) {
                if (a[i] === a[j]) {
                    a.splice(j--, 1);
                }
            }
        }

        return a;
    }

    public getFinance(type, year): any {
        const finance = this.financials.filter((finance) => {
            const financeMetric = finance.financeMetric.code.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
            if (financeMetric == type && finance.year == year) {
                return finance;
            }
        });
        return finance.length > 0 ? this.numberWithoutCommas(finance[0].value) : 0;
    }

    public getAllocatedBudget(year): any {
        const insightFinancial = this.insightFinancials.find(f => f.fiscalYear === year);
        return this.numberWithoutCommas(insightFinancial?.budget || 0);
    }

    public getFundingGap(year): number {
        const insightFinancial = this.getInsightFinancialRecord(year);

        if (insightFinancial) {
            return this.numberWithoutCommas(insightFinancial.budget - insightFinancial.fundingSecured);
        }

        return 0;
    }

    public getRevenue(year): number {
        const insightFinancial = this.getInsightFinancialRecord(year);
        return insightFinancial?.fundingSecured || 0;
    }

    private getInsightFinancialRecord(year: number): InsightFinancialModel {
        return this.insightFinancials.find((insightFinancial: InsightFinancialModel) => insightFinancial.fiscalYear == year);
    }

    public getRevenuePercentage(year): any {
        const insightFinancial = this.insightFinancials.find(f => f.fiscalYear === year);
        return this.numberAsPerc(insightFinancial?.percentFundingSecuredtoBudget || 0);
    }

    public getExpense(year): any {
        const insightFinancial = this.insightFinancials.find(f => f.fiscalYear === year);
        return this.numberWithoutCommas(insightFinancial?.expense || 0);
    }

    public getExpensePercentage(year): any {
        const insightFinancial = this.insightFinancials.find(f => f.fiscalYear === year);
        return this.numberAsPerc(insightFinancial?.percentExpensetoBudget || 0);
    }

    public calculateTypeSum(type): any {
        const filtered = [];
        const years = this.years;
        switch (type) {
            case 'allocated-working-budget-insight': {
                this.insightFinancials.filter(function (insight) {
                    if (years.includes(insight.fiscalYear)) {
                        filtered.push(insight.budget);
                    }
                });
                break;
            }
            case 'revenue': {
                this.insightFinancials.filter(function (insight) {
                    if (years.includes(insight.fiscalYear)) {
                        filtered.push(insight.fundingSecured);
                    }
                });
                break;
            }
            case 'expense': {
                this.insightFinancials.filter(function (insight) {
                    if (years.includes(insight.fiscalYear)) {
                        filtered.push(insight.expense);
                    }
                });
                break;
            }
            default: {
                this.financials.filter(function (finance) {
                    const financeMetric = finance.financeMetric.code.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
                    if (financeMetric == type && years.includes(finance.year)) {
                        filtered.push(finance.value);
                    }
                });
            }
        }

        const sum = this.sumAmount(filtered);
        const formattedSum = this.numberWithoutCommas(sum);
        const formattedSumString = String(formattedSum);
        return formattedSumString;
    }

    private sumAmount(amounts): number {
        let sum = 0;
        for (let i = 0; i < amounts.length; i++) {
            sum += amounts[i];
        }
        return Math.round(sum * 100) / 100;
    }

    public calculateYearSum(type, year): any {
        let sum = 0;
        const filtered = this.financials.filter(function (finance) {
            if (finance.financeMetric.code.includes(type) && finance.year == year && finance.financeMetric.code != 'costs-of-implementation-actual-cost') {
                return finance;
            }
        });
        filtered.forEach((finance) => {
            if (finance) {
                sum += +finance.value;
            }
        });
        return String(this.numberWithoutCommas(sum));
    }

    public calculateTotalSum(type): any {
        let sum = 0;
        const filtered = this.financials.filter((finance) => {
            if (finance.financeMetric.code.includes(type) && finance.financeMetric.code !== 'costs-of-implementation-actual-cost') {
                return finance;
            }
        });
        filtered.forEach((finance) => {
            if (finance) {
                sum += +finance.value;
            }
        });
        return String(this.numberWithoutCommas(sum));
    }

    // TODO: This method is in the code base at 3 different locations. Consolidate them! (ecoffman - 7/13/21)
    private numberWithCommas(x) {
        if (x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        return '0';
    }

    private numberWithoutCommas(x) {
        if (x) {
            return Math.round(x).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        }
        return x;
    }

    private numberAsPerc(x) {
        return x * 100;
    }
}
