import { Injectable, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, map, share, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { environment } from '../../../environments/environment';
import { FavoriteProjectModel, ProjectModel, UserApplicationRoleModel, UserPortfolioFilterModel } from '../../../hub_schema/hubTypes';
import { EmployeeService } from './employee.service';
import { HttpService } from './http.service';
import { RecordCollectionService } from './record-collection.service';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class UserDataService {

    // DEVELOPERS NOTE: This service is for getting and setting info about the currently active User - the one running the Hub

    constructor(
        private authService: AuthService,
        private httpService: HttpService,
        private employeeService: EmployeeService,
        public recordCollectionService: RecordCollectionService, 
        private sanitizer: DomSanitizer) {

        // if logged in, go ahead and initialize, otherwise wait for login
        const userId = this.getUserId();

        if (userId != null && !isNaN(userId) ) {
            this.initialize();
        }

        this.authService.authorizationComplete.pipe(take(1)).subscribe(() => {
            this.initialize();
        });
    }

    private initialize(): void {
        this.populateFavorites();
    }

    public myProjects: ProjectModel[] = [];
    public favorites: FavoriteProjectModel[];
    private myFavoritesIndex: any; // a Dictionary<number, boolean> (used to avoid an N^2 algorithm when checking to see if a project is a favorite).

    @Output()
    public favoritesChanged: EventEmitter<void> = new EventEmitter<void>();

    // #region User Claim Information

    public getUserId(): number {
        const userIdString = this.authService.getClaimDataValue('UserId');
        return parseInt(userIdString, 10);
    }

    public getUserEmail() {
        return this.authService.getClaimDataValue('http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name');
    }

    public getUserOffice() {
        return this.authService.getClaimDataValue('Office');
    }

    public getUserFullName() {
        return this.authService.getClaimDataValue('PreferredFullName');
    }

    public getUserInitials() {
        return this.authService.getClaimDataValue('Initials');
    }

    public getUserDepartment() {
        return this.authService.getClaimDataValue('Department');
    }

    public getUserBusinessUnitId() {
        return this.authService.getClaimDataValue('BusinessUnitId');
    }

    // #endregion

    // #region Favorites

    private populateFavorites(): void {
        // todo: do we need to preload these?
        const myUserId = this.getUserId();
        this.employeeService.getEmployeeFavorites(myUserId).pipe(take(1)).subscribe((favs: FavoriteProjectModel[]) => {
            this.favorites = favs;
            this.rebuildFavoriteIndex();
        });
    }

    private rebuildFavoriteIndex(): void {
        this.myFavoritesIndex = this.favorites
            .reduce((acc, project, i) => {
                acc[project.projectId] = project.favoriteProjectId;
                return acc;
            }, {});
    }

    public toggleFavorite(projectId: number): void {
        const favorite = this.favorites.find(fav => fav.projectId === projectId);

        if (favorite) {
            this.deleteFavoriteProject(favorite.favoriteProjectId);
        }
        else {
            this.addFavoriteProject(projectId);
        }
    }

    private addFavoriteProject(projectId): void {
        const fav: FavoriteProjectModel = {
            userId: this.getUserId(),
            projectId: projectId
        };

        const url = environment.endpoints.base + '/user/favorites';

        this.httpService.post<FavoriteProjectModel>(url, fav).pipe(take(1)).subscribe((savedFavorite) => {
            this.favorites.push(savedFavorite);
            this.rebuildFavoriteIndex();
            this.favoritesChanged.emit();   // todo: is this still necessary?
        });
    }

    private deleteFavoriteProject(favoriteId): void {
        const url = environment.endpoints.base + '/user/favorites/' + favoriteId;
        this.httpService.delete(url).pipe(take(1)).subscribe(() => {
            const removeIndex = this.favorites.findIndex(result => result.favoriteProjectId === favoriteId);
            this.favorites.splice(removeIndex, 1);
            this.rebuildFavoriteIndex();
            this.favoritesChanged.emit();
        });
    }

    public isFavorite(projectId: number): boolean {
        if (!this.myFavoritesIndex) {
            return false;
        }
        return projectId != null && this.myFavoritesIndex[projectId] !== undefined;
    }

    // #endregion

    // #region Saved Portfolio Filters

    public getPortfolioSavedFilters(): Observable<UserPortfolioFilterModel[]> {
        const url = environment.endpoints.base + '/user/portfolioFilters/' + this.getUserId();
        return this.httpService.get<UserPortfolioFilterModel[]>(url);
    }

    public savePortfolioFilter(name: string, jsonString: string): Observable<UserPortfolioFilterModel> {
        const url = environment.endpoints.base + '/user/portfolioFilters';

        const portfolioFilter: UserPortfolioFilterModel = {
            userId: this.getUserId(),
            name: name,
            uri: jsonString
        };

        return this.httpService.post<UserPortfolioFilterModel>(url, portfolioFilter);
    }

    public deleteSavedPortfolioFilter(savedFilterId: number): Observable<void> {
        const url = environment.endpoints.base + '/user/portfolioFilters/' + savedFilterId;
        return this.httpService.delete(url);
    }

    // #endregion

    public getUserProfilePicture(userEmail: string, defaultPhoto = null): Observable<any> {
        return this.httpService.get(environment.endpoints.base + '/user/photo/' + userEmail)
            .pipe(
                share(),
                catchError((error) => of(`Error: ${error}`)),
                map((obj: { src: string }) => obj?.src ? obj.src : defaultPhoto),
            );
    }

    public generateTeamsUri(email) {
        const uri = `https://teams.microsoft.com/l/chat/0/0?users=${email}`;
        return this.sanitizer.bypassSecurityTrustResourceUrl(uri);
    }

}
