import { Component } from '@angular/core';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';

@Component({
  selector: 'app-insight-records-panel',
  templateUrl: './insight-records-panel.component.html',
  styleUrls: ['./insight-records-panel.component.scss']
})
export class InsightRecordsPanelComponent extends HubRecordEditorBase {

}
