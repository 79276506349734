import { Component, Input, OnInit, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { take } from 'rxjs';
import { AvailableParentProjectOutcomesModel, ProjectOutcomeModel } from '../../../../../../../hub_schema/hubTypes';
import { OutcomesEditService } from '../../../services/outcomes-edit.service';
import { CreateOutcomeStateService } from '../../services/create-outcome-state.service';
import { OutcomeState } from '../../types/outcome-state';

@Component({
  selector: 'app-custom-outcome-related-panel',
  templateUrl: './custom-outcome-related-panel.component.html',
  styleUrls: ['./custom-outcome-related-panel.component.scss']
})
export class CustomOutcomeRelatedPanelComponent implements OnInit {
    private outcomesEditService: OutcomesEditService = inject(OutcomesEditService);
    private createOutcomeStateService: CreateOutcomeStateService = inject(CreateOutcomeStateService);
    public outcomeState: OutcomeState;

    private get customOutcomeForm(): FormGroup {
        return this.outcomeState.outcomeWizardForm!.controls.customOutcome as FormGroup;
    }

    public availableParents: AvailableParentProjectOutcomesModel[];

    public ngOnInit(): void {
        this.outcomeState = this.createOutcomeStateService.outcomeState!;

        this.outcomesEditService.getAvailableParentCustomOutcomes(this.outcomeState.projectId).pipe(take(1)).subscribe((results: AvailableParentProjectOutcomesModel[]) => {
            this.availableParents = results;
        });
    }

    public relatedCustomOutcomeSelected(projectOutcome: ProjectOutcomeModel): void {
        this.customOutcomeForm.controls.indicatorName.setValue(projectOutcome.customOutcome!.indicatorName);
        this.customOutcomeForm.controls.unit.setValue(projectOutcome.customOutcome!.unit);
        this.customOutcomeForm.controls.parentCustomOutcomeId.setValue(projectOutcome.customOutcome?.customOutcomeId);
        this.outcomeState.outcomeWizardForm!.controls.isPolicyLeverageOutcome.setValue(projectOutcome.isPolicyLeverageOutcome);
    }

}
