import { Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, map, startWith, take } from 'rxjs';
import { GeographyModel } from '../../../../../../../../../hub_schema/hubTypes';
import { HubLovService } from '../../../../../../../../core/services/hub-lov.service';
import { CreateOutcomeStateService } from '../../../../services/create-outcome-state.service';
import { OutcomeState } from '../../../../types/outcome-state';

@Component({
  selector: 'app-location-and-spatial-extent-panel',
  templateUrl: './location-and-spatial-extent-panel.component.html',
  styleUrls: ['./location-and-spatial-extent-panel.component.scss']
})
export class LocationAndSpatialExtentPanelComponent implements OnInit {
    // dependencies
    private hubLovService: HubLovService = inject(HubLovService);
    private createOutcomeStateService: CreateOutcomeStateService = inject(CreateOutcomeStateService);
    public outcomeState: OutcomeState;

    public get spatialExtent(): FormControl {
        return (this.outcomeState.outcomeWizardForm.controls.outcome as FormGroup).controls.spatialExtent as FormControl;
    }

    public countries: GeographyModel[];
    public countriesTypeahead: FormControl;
    public filteredCountries$: Observable<GeographyModel[]>;

    public ngOnInit(): void {
        this.outcomeState = this.createOutcomeStateService.outcomeState;

        this.hubLovService.getCountries().pipe(take(1)).subscribe((countries: GeographyModel[]) => {
            this.countries = countries;

            // if they have entered a country, moved forward and then gone back, display their currently selected country in auto-complete textbox
            const currentCountryId = (this.outcomeState.outcomeWizardForm.controls.outcome as FormGroup).controls.ncsCountryId.value;

            const currentCountryName = currentCountryId 
                ? this.countries.find(c => c.geographyId === currentCountryId)?.name 
                : '';
            
            this.countriesTypeahead = new FormControl(currentCountryName);
            this.setupCountryAutocomplete();
        });        
    }

    private setupCountryAutocomplete(): void {
        this.filteredCountries$ = this.countriesTypeahead.valueChanges
            .pipe(
                startWith(''),
                map((value) => this.filterCountries(value)),
            );
    }

    private filterCountries(value: string): GeographyModel[] {
        if (typeof value !== 'string') {
            return [];
        }
        return this.countries.filter((country) => country.name.toLowerCase().includes(value.toLowerCase()));
    }

    public getCountryName(country: any) {
        // leave country as an any.  It is sometimes a string (thanks, MatAutoComplete!)
        if (country) {
            if (country.name) {
                return country.name;
            }
            else {
                return country;
            }
        }        
    }

    public countryLostFocus() {
        const currentCountryText = this.countriesTypeahead.value;

        if (typeof currentCountryText !== 'string') {
            return;
        }

        const currentCountries = this.filterCountries(currentCountryText);

        if (currentCountries.length !== 1) {
            (this.outcomeState.outcomeWizardForm.controls.outcome as FormGroup).controls.ncsCountryId.setValue(null);
        }
    }

    public setCountry(e): void {
        (this.outcomeState.outcomeWizardForm.controls.outcome as FormGroup).controls.ncsCountryId.setValue(e.option.value.geographyId);
    }

}
