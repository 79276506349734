<div class="card-header p-1 border-adriatic"
    [ngClass]="{strategy: projectInfo.recordType === 'Strategy', project: projectInfo.recordType === 'Project' }">
    <div>
        <span class="text-success-800 font-weight-bold">{{recordTypeName}}</span><span
            class="text-muted ml-1">({{statusName}})</span>
    </div>

    <h4 class="hub-project-title mb-0">
        <a [routerLink]="'/portfolio/' + hubRecordId" class="font-weight-bold">{{projectInfo?.name}}</a>
    </h4>

</div>

<div class="card-body m-1 p-1" *ngIf="showCardDetails">
    <div class="description">
        <p class="record-description" [innerHTML]="projectInfo?.description"></p>
    </div>
    <p class="text-muted record-description" *ngIf="!projectInfo?.description">This record does not have a description.</p>
    <hr>

    <div style="margin-top: 20px;">
        <span *ngIf="projectInfo?.lead">
            Led by
            <a [routerLink]="'/user/' + projectInfo.lead?.userId" class="led-by-link">
                {{projectInfo?.lead?.name}}
            </a>
        </span>
    </div>
    <div>
        <span *ngIf="projectInfo.leadBusinessUnit">
            Managed by {{projectInfo.leadBusinessUnit}}
        </span>
    </div>

    <div class="supported-by-label"
        *ngIf="(projectInfo.recordType === 'Strategy') && projectInfo.relations?.length > 0">
        Supported by
        {{projectInfo?.relations.length}} {{projectInfo?.relations.length === 1 ? 'Project' : 'Projects'}}:
    </div>
    <div class="supported-by-label" *ngIf="(projectInfo.recordType === 'Project') && projectInfo.relations?.length > 0">
        Supporting
        {{projectInfo?.relations.length}} {{projectInfo?.relations.length === 1 ? 'Strategy' : 'Strategies'}}:
    </div>
    <div *ngIf="(projectInfo.recordType === 'Strategy' || projectInfo.recordType === 'Project') && projectInfo.relations?.length > 0"
        class="hub-custom-scrollbar" style="max-height: 200px; overflow-y: visible; overflow-x: hidden;">
        <a [routerLink]="'/portfolio/' + project.hubId" *ngFor="let project of projectInfo?.relations"
            class="badge badge-primary mr-1 mb-1">{{project.name}}</a>
    </div>
    <div class="details" *ngIf="showFinanceDetails">
        <h4 class="text-indigo mt-0 mb-0 p-0">
            $ {{ numberWithCommas(projectInfo?.financials?.currentFyBudget) }}
        </h4>
        <span class="text-muted d-block m-0">FY{{ currentFiscalYear }} Working Budget</span>
        <div class="progress mt-1 mb-2" style="height: 0.5rem;">
            <div class="progress-bar"
                [ngStyle]="{'width.%': projectInfo?.financials?.fundingPercent | numberWithoutCommas}"></div>
        </div>
        <span class="text-muted">
            {{ projectInfo?.financials?.fundingPercent | numberWithoutCommas }}% Funded (of Working Budget)
        </span>
    </div>
</div>

<div class="goals-panel">
    <div *ngFor="let goal of getGoals()" class="goal-icon {{'goal-background-' + goal.goal2030Id}}"
        [matTooltip]="goal.theme" [matTooltipShowDelay]="500" [matTooltipHideDelay]="200"></div>
</div>

<div class="card-footer d-flex justify-content-between align-items-center p-1">
    <div>
        <div class="card-footer-buttons">
            <a href="#" (click)="toggleFavorite(hubRecordId, $event)" class="list-inline-item text-primary">
                <span *ngIf = "userDataService.isFavorite(hubRecordId)" class="material-icons-outlined">favorite</span>
                <span *ngIf = "!userDataService.isFavorite(hubRecordId)" class="material-icons-outlined">favorite_border</span>
            </a>

            <a [routerLink]="'/portfolio/' + hubRecordId + '/edit'" *ngIf="isUserCanEdit"
                class="list-inline-item text-primary">
                <span class="material-icons-outlined">edit</span>
            </a>

            <app-add-project-to-collection-button [projectId]="projectInfo.objectID" [projectName]="projectInfo.name"></app-add-project-to-collection-button>

            <a href="#" *ngIf="userIsCreator" class="list-inline-item"
                (click)="deleteRecordCollectionProjectClicked()"><i class="icon-trash"></i></a>
        </div>
        <span class="text-muted font-size-sm ml-3">
            Updated {{ getDistanceInWordsToNow() }}
        </span>
    </div>
</div>
