import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { PortfolioComponent } from '../components/portfolio.component';

@Injectable()
export class LeavePortfolioGuard implements CanDeactivate<PortfolioComponent> {
    canDeactivate(component: PortfolioComponent) {
        component.onLeavePortfolio();
        return true;
    }
}
