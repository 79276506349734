<div class="hub-facet-filter" *ngFor="let subArea of facet.metricTree.subAreas">

    <h6>{{subArea.name}}</h6>

    <div class="hub-facet-filter-content" *ngFor="let metric of subArea.metrics">
        <div>
            <mat-checkbox [(ngModel)]="metric.isChecked" color="primary"
                [disabled]="getNumberOfHits(subArea, metric) === 0"
                (change)="userChangedMetricSelection(subArea, metric)">
            </mat-checkbox>
        </div>
        <div class="filter-title">
            {{metric.name}}&nbsp;<span class="hub-number-of-hits">{{getNumberOfHits(subArea, metric) | number}}</span>
        </div>

    </div>
</div>
