import { Component, OnInit, Inject, inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RecordCollectionModel, RecordCollectionTypeModel } from '../../../../../hub_schema/hubTypes';
import { RecordCollectionService } from '../../../../core/services/record-collection.service';
import { take } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HubLovService } from '../../../../core/services/hub-lov.service';

@Component({
    selector: 'app-add-edit-collection-dialog',
    templateUrl: './add-edit-collection-dialog.component.html',
    styleUrls: ['./add-edit-collection-dialog.component.scss']
})
export class AddEditCollectionDialogComponent implements OnInit {
    public collectionForm: FormGroup;
    public recordCollectionTypes: RecordCollectionTypeModel[];
    public existingCollection: RecordCollectionModel;
    public isSaving: boolean = false;

    constructor(
        private recordCollectionService: RecordCollectionService,
        private dialogRef: MatDialogRef<AddEditCollectionDialogComponent>,
        private hubLovService: HubLovService,
        @Inject(MAT_DIALOG_DATA) private data
    ) { }

    public ngOnInit(): void {
        this.hubLovService.getAllRecordCollectionTypes().pipe(take(1)).subscribe((recordCollectionTypes: RecordCollectionTypeModel[]) => {
            this.recordCollectionTypes = recordCollectionTypes;
        });

        this.existingCollection = this.data.existingCollection || null;

        this.collectionForm = new FormGroup({
            recordCollectionId: new FormControl({
                value: undefined,
                disabled: !this.existingCollection
            }),
            recordCollectionTypeId: new FormControl(null, Validators.required),
            name: new FormControl('', [Validators.required, Validators.maxLength(150)]),
            description: new FormControl('', Validators.maxLength(500)),
            isPrivate: new FormControl(false),
        });

        if (this.existingCollection) {
            this.collectionForm.patchValue(this.existingCollection);
        }
    }

    public getValidationErrorMessages(formControlName: string): string {
        const formControl = this.collectionForm.get(formControlName)!;

        if (!formControl.touched) {
            return '';
        }

        const formErrors = formControl.errors;

        if (formErrors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(formErrors)) {
                if (key === 'required') {
                    errorMessages.push(key);
                } else if (key === 'maxlength') {
                    errorMessages.push(`Max length of ${formErrors[key].requiredLength} characters exceeded.`);
                } else {
                    errorMessages.push(formErrors[key]);
                }
            }

            return errorMessages.join('<br />');
        }
        return '';
    }

    public save(): void {

        this.isSaving = true;

        if (this.existingCollection) {
            this.recordCollectionService.updateRecordCollection(this.collectionForm.value).pipe(take(1)).subscribe((recordCollection: RecordCollectionModel) => {
                this.isSaving = false;
                this.dialogRef.close(recordCollection);
            });
        }
        else {
            this.recordCollectionService.createRecordCollection(this.collectionForm.value).pipe(take(1)).subscribe((recordCollection: RecordCollectionModel) => {
                this.isSaving = false;
                this.dialogRef.close(recordCollection);
            });
        }
    }

    public close(): void {
        this.dialogRef.close();
    }
}
