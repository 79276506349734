import { Injectable, inject } from '@angular/core';
import { HttpService } from '../../../../core/services/http.service';
import { Observable, forkJoin } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AvailableParentProjectOutcomesModel, NcsGeographyInterventionModel, NcsInterventionModel, OutcomeDetailsModel, OutcomeProgressModel, OutcomeTargetModel, ProjectOutcomeModel } from '../../../../../hub_schema/hubTypes';

@Injectable()
export class OutcomesEditService {
    private httpService: HttpService = inject(HttpService);

    // #region Outcome CRUD methods

    public createProjectOutcome(projectOutcome: ProjectOutcomeModel): Observable<ProjectOutcomeModel> {
        const url = environment.endpoints.base + '/projectOutcomes';
        return this.httpService.post<ProjectOutcomeModel>(url, projectOutcome);
    }

    public updateProjectOutcome(projectOutcome: ProjectOutcomeModel): Observable<ProjectOutcomeModel> {
        const url = environment.endpoints.base + '/projectOutcomes';
        return this.httpService.patch<ProjectOutcomeModel>(url, projectOutcome);
    }

    public deleteProjectOutcome(projectOutcome: ProjectOutcomeModel): Observable<void> {
        const url = environment.endpoints.base + '/projectOutcomes/' + projectOutcome.projectOutcomeId;
        return this.httpService.delete(url);
    }

    // #endregion Outcome CRUD methods

    // #region Outcome Target CRUDS

    public createTarget(target: OutcomeTargetModel): Observable<OutcomeTargetModel> {
        const url = environment.endpoints.base + '/projectOutcomes/outcomeTarget';
        return this.httpService.post<OutcomeTargetModel>(url, target);
    }

    public updateTarget(target: OutcomeTargetModel): Observable<OutcomeTargetModel> {
        const url = environment.endpoints.base + '/projectOutcomes/outcomeTarget';
        return this.httpService.patch<OutcomeTargetModel>(url, target);
    }

    public deleteTarget(targetId: number): Observable<boolean> {
        const url = environment.endpoints.base + '/projectOutcomes/outcomeTarget/' + targetId;
        return this.httpService.delete(url);
    }

    // #endregion Outcome Target CRUDS

    // #region Outcome Progress CRUDS

    public createProgress(progress: OutcomeProgressModel): Observable<OutcomeProgressModel> {
        const url = environment.endpoints.base + '/projectOutcomes/outcomeProgress/';
        return this.httpService.post<OutcomeProgressModel>(url, progress);
    }

    updateProgress(progress: OutcomeProgressModel): Observable<OutcomeProgressModel> {
        const url = environment.endpoints.base + '/projectOutcomes/outcomeProgress/';
        return this.httpService.patch<OutcomeProgressModel>(url, progress);
    }

    public deleteProgress(progressId: number): Observable<boolean> {
        const url = environment.endpoints.base + '/projectOutcomes/outcomeProgress/' + progressId;
        return this.httpService.delete(url);
    }

    // #endregion Outcome Progress CRUDS

    // #region NCS Calculator

    public getNcsIntervention(interventionId: number) {
        const url = environment.endpoints.base + '/hubLOV/ncsIntervention/' + interventionId; 
        return this.httpService.get<NcsInterventionModel>(url);
    }

    public getNcsGeographyIntervention(geographyId: number, interventionId: number) {
        const url = environment.endpoints.base + `/hubLOV/ncsGeographyIntervention/${geographyId}/${interventionId}`;
        return this.httpService.get<NcsGeographyInterventionModel>(url);
    }

    public getNcsGeographyInterventionData(ncsCountryId: number, ncsInterventionId: number) {
        return forkJoin({
            ncsIntervention: this.getNcsIntervention(ncsInterventionId),
            ncsGeographyIntervention: this.getNcsGeographyIntervention(ncsCountryId, ncsInterventionId)
        });
    }

    // #endregion NCS Calculator

    // #region Utilities

    public getProjectOutcomeDetails(projectOutcomeId: number): Observable<OutcomeDetailsModel> {
        const url = environment.endpoints.base + `/projectOutcomes/outcomeDetails/${projectOutcomeId}/false`;
        return this.httpService.get<OutcomeDetailsModel>(url);
    }

    public updateOutcomeOrder(projectId: number, orderedProjectOutcomeIds: number[]): Observable<boolean> {
        const url = environment.endpoints.base + '/projectOutcomes/projectOutcomeSortOrder/' + projectId;
        return this.httpService.patch<boolean>(url, orderedProjectOutcomeIds);
    }

    public getAvailableParentOutcomes(projectId: number, outcomeId: number | null = null): Observable<AvailableParentProjectOutcomesModel[]> {
        let url = environment.endpoints.base + '/projectOutcomes/availableParentOutcomes/' + projectId;

        if (outcomeId) {
            url += '/' + outcomeId;
        }

        return this.httpService.get<AvailableParentProjectOutcomesModel[]>(url);
    }

    public getAvailableParentCustomOutcomes(projectId: number, customOutcomeId: number | null = null): Observable<AvailableParentProjectOutcomesModel[]> {
        let url = environment.endpoints.base + '/projectOutcomes/availableParentCustomOutcomes/' + projectId;

        if (customOutcomeId) {
            url += '/' + customOutcomeId;
        }

        return this.httpService.get<AvailableParentProjectOutcomesModel[]>(url);
    }

    

    // #endregion Utilities
}
