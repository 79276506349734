<div class="ncs-estimates-panel" *ngIf="estimatesForm">
    <h5>Estimates <span class="wrapper-relative"><app-help-selector [selector]="1210"></app-help-selector></span></h5>

    <table [formGroup]="estimatesForm">
        <ng-container formArrayName="pools">
            <tr *ngFor="let poolForm of poolsArray.controls">
                <td class="pool-title-column">{{poolForm.controls.poolTitle.value}}</td>
                <td class="user-input-column">
                    <mat-form-field>
                        <input matInput type="number" step="any" [formControl]="poolForm.controls.estimate">
                    </mat-form-field>
                </td>
                <td class="button-column">
                    <button class="revert-button"
                        *ngIf="isEstimateRevertButtonVisible(poolForm)"
                        (click)="revertEstimate(poolForm)">REVERT TO DEFAULT</button>
                </td>
            </tr>
        </ng-container>

        <tr>
            <td class="pool-title-column">Baseline % <span class="wrapper-relative"><app-help-selector [selector]="1211"></app-help-selector></span></td>
            <td class="user-input-column percent-column">
                <mat-form-field>
                    <input matInput type="number" step="any" formControlName="baselinePercent">
                    <span>%</span>
                </mat-form-field>
            </td>
            <td class="button-column">
                <button class="revert-button"
                    *ngIf="isBaselineRevertButtonVisible()"
                    (click)="revertBaseline()">REVERT TO DEFAULT</button>
            </td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td class="recalculate-button-column" style="padding-top: 20px;">
                <button [disabled]="isRecalculateButtonDisabled" (click)="recalculate()">{{calculateMessage}}</button>
            </td>
        </tr>
    </table>

    <hr>

</div>

