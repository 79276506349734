import { Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { HelpLovService } from '../services/help-lov.service';

@Component({
    selector: 'app-help-selector-dialog',
    templateUrl: './help-selector-dialog.component.html',
    styleUrls: ['./help-selector-dialog.component.scss']
})
export class HelpSelectorDialogComponent implements OnInit {

    public helpResult: any;

    public title: string;
    public content: string;

    private helperLovService: HelpLovService = inject(HelpLovService);
    private dialogRef:MatDialogRef<HelpSelectorDialogComponent> = inject(MatDialogRef<HelpSelectorDialogComponent>)
    private data: any = inject(MAT_DIALOG_DATA);

    ngOnInit() {
        const helperId: number = this.data;

        this.helperLovService.getHelpTextById(helperId).pipe(take(1))
            .subscribe((result: any) => {
                this.helpResult = {
                    helpTextId: result.helpTextId,
                    label: result.label,
                    contentHtmlShort: result.contentHtmlShort,
                    link: result.link
                };
            });
    }

    public closeDialog() {
        this.dialogRef.close();
    }

}
