<div [formGroup]="outcomeState.outcomeWizardForm" class="current mb-2 p-1 sb-b body hub-custom-scrollbar" *ngIf="countries">
    <div class="row" formGroupName="outcome" >
        <div class="col-md-12" style="margin-bottom: 40px;">
            <h5>Location and Spatial Extent</h5>

            <div style="margin-left: 20px">
                <div class="select-label text-info-800">
                    Country
                </div>

                <mat-form-field style="width: 100%">
                    <input matInput #countriesTypeaheadInputBox [matAutocomplete]="countriesAutocomplete"
                        placeholder="Enter a name of Country here..."
                        [formControl]="countriesTypeahead" autocomplete="do-not-autofill"
                        (blur)="countryLostFocus()" />
                </mat-form-field>

                <mat-autocomplete #countriesAutocomplete="matAutocomplete"
                    [displayWith]="getCountryName"
                    (optionSelected)="setCountry($event)">
                    <mat-option *ngFor="let country of filteredCountries$ | async"
                        [value]="country">
                        {{country.name}}
                    </mat-option>
                </mat-autocomplete>

                <div class="spatial-extent-panel">
                    <div class="spatial-extent-label">
                        Spatial Extent
                    </div>

                    <mat-form-field >
                        <input matInput type="number" min="0.00000001" [formControl]="spatialExtent">
                    </mat-form-field>

                    <div class="unit-label">hectares</div>
                </div>
            </div>

        </div>
    </div>
</div>
