import { Component, Input } from '@angular/core';
import { ProjectStrategicPillarModel } from '../../../../hub_schema/hubTypes';

@Component({
    selector: 'app-strategic-pillar-container',
    templateUrl: './strategic-pillar-container.component.html',
})
export class StrategicPillarContainerComponent {
    @Input()
    public strategicPillars: ProjectStrategicPillarModel[];
}
