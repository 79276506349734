import { Component, Input } from '@angular/core';
import { HubRecordTypes } from '../../../../shared/types/hub-record-types';
import { ProjectBusinessUnitModel, ProjectRelationModel, ProjectInfoModel } from '../../../../../hub_schema/hubTypes';
import { RelationsContainerBase } from '../relations-container-base';

@Component({
    selector: 'app-project-relations-container',
    templateUrl: './project-relations-container.component.html',
    styleUrls: ['./project-relations-container.component.scss']
})
export class ProjectRelationsContainerComponent extends RelationsContainerBase {

    @Input()
    public relations: ProjectRelationModel[];

    @Input()
    public backwardRelations: ProjectRelationModel[]; 

    private _proposedRelations: any[];

    public get proposedRelations(): any[] {
        if (!this._proposedRelations) {

            if (!this.relations || !this.backwardRelations) {
                return [];
            }

            const relatedProjectIds = this.relations.map(r => r.relatedProject!.projectId);
            this._proposedRelations = this.backwardRelations.filter(br => br.project!.projectTypeName === HubRecordTypes.Strategy && !relatedProjectIds.includes(br.projectId));
        }
        return this._proposedRelations;
    }

    public get primaryRelatedStrategies(): ProjectRelationModel[] {
        return this.relations.filter(r => r.isPrimary);
    }

    public get additionalRelatedStrategies(): ProjectRelationModel[] {
        return this.relations.filter(r => !r.isPrimary);
    }

    public get primaryProposedStrategies(): ProjectRelationModel[] {
        return this.proposedRelations.filter(r => r.isPrimary);
    }

    public get additionalProposedStrategies(): ProjectRelationModel[] {
        return this.proposedRelations.filter(r => !r.isPrimary);
    }

    private _programManagementRecords: ProjectInfoModel[];

    public get programManagementRecords(): ProjectInfoModel[] {
        if (!this._programManagementRecords) {
            const allRelationsProjects: ProjectInfoModel[] = [...this.relations.map(r => r.relatedProject!), ...this.backwardRelations.map(br => br.project!)];
            this._programManagementRecords = allRelationsProjects.filter(arp => arp.projectTypeName === HubRecordTypes.ProgramManagement);
        }

        return this._programManagementRecords;
    }

    public get wholeSystems(): ProjectInfoModel[] {
        const allRelationsProjects: ProjectInfoModel[] = [...this.relations.map(r => r.relatedProject!), ...this.backwardRelations.map(br => br.project!)];
        const wholeSystemRelatedProjects = allRelationsProjects.filter(arp => arp.projectTypeName === HubRecordTypes.WholeSystem);
        return wholeSystemRelatedProjects;
    }

    private _projectBusinessUnits: ProjectBusinessUnitModel[];

    public get projectBusinessUnits(): ProjectBusinessUnitModel[] {
        return this._projectBusinessUnits;
    }

    @Input()
    public set projectBusinessUnits(value: ProjectBusinessUnitModel[]) {
        this._projectBusinessUnits = value.sort((a: ProjectBusinessUnitModel, b: ProjectBusinessUnitModel) => {
            if (!a.isLeadBusinessUnit && b.isLeadBusinessUnit) {
                return 1;
            } else if (a.isLeadBusinessUnit && !b.isLeadBusinessUnit){
                return -1;
            } else if (a.businessUnit!.name < b.businessUnit!.name){
                return -1;
            } else if (a.businessUnit!.name > b.businessUnit!.name){
                return 1;
            }
            return 0;
        });
    }

    private _regionNames: string[];

    public get regionNames(): string[] {
        if (!this._regionNames) {

            if (this.projectBusinessUnits) {
                const distinctRegionPbus = this.projectBusinessUnits.filter(
                    (pbu: ProjectBusinessUnitModel, i, arr) => arr.findIndex(t => t.businessUnit!.region?.regionId === pbu.businessUnit!.region?.regionId) === i
                );
                this._regionNames = distinctRegionPbus
                    .map((r: ProjectBusinessUnitModel) => r.businessUnit!.region.name)
                    .sort();
            }

        }
        return this._regionNames;
    }

    private _divisionNames: string[];

    public get divisionNames(): string[] {
        if (!this._divisionNames) {

            if (this.projectBusinessUnits) {
                const distinctDivisionPbus = this.projectBusinessUnits.filter(
                    (pbu: ProjectBusinessUnitModel, i, arr) => arr.findIndex(t => t.businessUnit!.division?.divisionId === pbu.businessUnit!.division?.divisionId) === i

                );
                this._divisionNames = distinctDivisionPbus
                    .map((r: ProjectBusinessUnitModel) => r.businessUnit!.division.name)
                    .sort();
            }

        }
        return this._divisionNames;
    }

    @Input()
    public projectType: string;

    trackByFn(index) {
        return index;
    }
}
