<div class="detail-panel p-2 pl-3" style="height: calc(100vh - 257px);">
    <h3 class="text-success"><b>Output</b> - Details</h3>

    <!-- M.B. 9-19-2022 We have to differentiate between active/archive and if either array is empty there is not point is displaying anything besides the header -->
    <div *ngIf="projectOutput"
        class="d-flex flex-wrap align-content-stretch" style="bottom: 0px;">
        <div class="bg-white pl-2 pr-2 sb-t" style="flex: 100%; overflow: hidden;">

            <ul class="nav nav-tabs nav-tabs-bottom">
                <li class="nav-item">
                    <a href="#" (click)="setTabTo(tabs.progressReports, $event)"
                        class="nav-link" [ngClass]="{active: activeTab === tabs.progressReports}">Progress Reports</a>
                </li>
                <li class="nav-item">
                    <a href="#" (click)="setTabTo(tabs.fileLinks, $event)"
                        class="nav-link" [ngClass]="{active: activeTab === tabs.fileLinks}">File Links</a>
                </li>
            </ul>

            <div class="tab-content">
                <div class="tab-pane fade" [ngClass]="{active: activeTab === tabs.progressReports, show: activeTab === tabs.progressReports}">
                    <div class="d-md-flex justify-content-between">
                        <h5 class="text-success">
                            Progress Reports ({{projectOutput?.outputProgress?.length}}) <app-help-selector [selector]="1120"></app-help-selector>
                        </h5>
                        <button *ngIf="!projectOutput?.isArchived" type="button" (click)="openCreateUpdateOutputProgressDialog(null)"
                                class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round ml-2 legitRipple">
                            <i class="icon-plus-circle2 mr-2"></i>Create Progress Report
                        </button>
                    </div>

                    <ul class="section-list left ml-1 mr-2 " id="lstProgressReports">
                        <li class="sb-b" *ngFor="let progress of projectOutput.outputProgress; let i = index;">
                            <div class="mr-2">
                                <a href="#" class="btn btn-success rounded-round btn-icon legitRipple">
                                    <i class="icon-file-check"></i>
                                </a><br>
                            </div>
                            <div class="media-body">
                                <h6 class="text-success-800 font-weight-bold p-0">
                                    Progress Report ({{progress.progressDate | date}})
                                </h6>
                                <button *ngIf="!projectOutput?.isArchived"
                                        type="button"
                                        class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple float-right"
                                        data-popup="tooltip" title="" data-placement="right" data-original-title="Delete Progress Report"
                                        (click)="removeOutputProgress(progress)">
                                    <i class="icon-trash"></i>
                                </button>
                                <button *ngIf="!projectOutput?.isArchived"
                                        type="button"
                                        class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple float-right"
                                        title="" (click)="openCreateUpdateOutputProgressDialog(progress)"
                                        data-original-title="Edit Progress Report Output" data-toggle="modal"
                                        data-target="#mdlEditProgressReport">
                                    <i class="icon-pencil"></i>
                                </button>
                                <h5 class="mb-2 {{ getProgressStatusClass(progress.status?.name) }}">

                                    {{progress.status?.name}} ({{progress.progress.label}})
                                </h5>
                                <app-text-truncate class="record-description mt-2">{{progress.description}}</app-text-truncate>
                            </div>
                        </li>
                    </ul>

                    <ng-container *ngIf="isProgressLoaded()">
                        <div *ngIf="!doesProgressExist()"
                            class="alert alert-info alert-styled-left shadow-0">
                            There are no output progress reports created{{projectOutput?.isArchived ? '.' : ' yet.' }}
                        </div>
                    </ng-container>

                </div>

                <div class="tab-pane fade" [ngClass]="{active: activeTab === tabs.fileLinks, show: activeTab === tabs.fileLinks}">

                    <div class="d-md-flex justify-content-between">
                        <h5 class="text-success">
                            Web/File Links ({{projectOutput.outputLinks?.length}})<app-help-selector [selector]="1125"></app-help-selector>
                        </h5>
                        <button *ngIf="!projectOutput?.isArchived" type="button" class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round ml-2 legitRipple"
                                (click)="openCreateUpdateLinkDialog(null)">
                            <i class="icon-plus-circle2 mr-2"></i>Create Output Link
                        </button>
                    </div>


                    <ul class="section-list left ml-1 mr-2" id="lstOutputLink">
                        <li class="sb-b" *ngFor="let link of projectOutput.outputLinks; let i = index;">
                            <div class="mr-2">
                                <a [href]="link.url" target="_blank" class="btn btn-primary rounded-round btn-icon legitRipple">
                                    <i class="icon-link2"></i>
                                </a><br>
                            </div>
                            <div class="media-body row" style="overflow: hidden;">
                                <div class="col-md-7">
                                    <h6 class="text-success-800 font-weight-bold mt-1">
                                        <a [href]="link.url" target="_blank" class="d-block text-truncate w-auto">{{link.name}}</a>
                                    </h6>
                                </div>
                                <div class="col-md-5">
                                    <button *ngIf="!projectOutput?.isArchived" type="button" class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple float-right"
                                            data-popup="tooltip" title="" (click)="removeOutputLink(link)"
                                            data-placement="right" data-original-title="Edit Output Link" data-toggle="modal"
                                            data-target="#mdlEditOutputLink">
                                        <i class="icon-trash"></i>
                                    </button>
                                    <button *ngIf="!projectOutput?.isArchived" type="button" class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple float-right"
                                            data-popup="tooltip" title="" (click)="openCreateUpdateLinkDialog(link)">
                                        <i class="icon-pencil"></i>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <ng-container *ngIf="areLinksLoaded()">
                        <div *ngIf="!doLinksExist()"
                            class="alert alert-info alert-styled-left shadow-0">
                            There are no output links created{{projectOutput?.isArchived ? '.' : ' yet.' }}
                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!projectOutput" class="p-2 pl-3">
        <div class="alert alert-info alert-styled-left shadow-0">
            An output must be defined before adding output details.
        </div>
    </div>
</div>
