import { Component, OnInit, inject } from '@angular/core';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';
import { RelationsTypeaheadService } from '../services/relations-typeahead.service';
import { ProjectInfoModel, ProjectRelationModel } from '../../../../../hub_schema/hubTypes';
import { FormControl } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { CdkDrag, CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HubRecordTypes } from '../../../../shared/types/hub-record-types';

@Component({
  selector: 'app-strategy-relations-edit',
  templateUrl: './strategy-relations-edit.component.html',
  styleUrls: ['./strategy-relations-edit.component.scss']
})
export class StrategyRelationsEditComponent extends HubRecordEditorBase implements OnInit {
    private relationsTypeaheadService: RelationsTypeaheadService = inject(RelationsTypeaheadService);

    public ngOnInit(): void {
        const relations = this.projectEditForm?.controls.relations.value as ProjectRelationModel[];
        this.coreProjects = relations.filter(r => r.isPrimary).map(r => r.relatedProject!);
        this.additionalProjects = relations.filter(r => !r.isPrimary).map(r => r.relatedProject!);

        // proposed relations are backward relations that are not reciprocal
        const relatedProjectIds = relations.map(r => r.relatedProjectId);

        this.proposedRelations = (this.projectEditForm?.controls.backwardRelations.value as ProjectRelationModel[])
            .filter(br => !relatedProjectIds.includes(br.projectId) && br.project?.projectTypeName === HubRecordTypes.Project )
            .map(br =>br.project!);

            this.programManagementRelations = (this.projectEditForm?.controls.backwardRelations.value as ProjectRelationModel[])
            .filter(br => br.project!.projectTypeName === HubRecordTypes.ProgramManagement)
            .map(br => br.project!);

        this.wholeSystemRelations = (this.projectEditForm?.controls.backwardRelations.value as ProjectRelationModel[])
            .filter(br => br.project!.projectTypeName === HubRecordTypes.WholeSystem)
            .map(br => br.project!);
        
        // wireup typeaheads
        this.coreProjectSuggestions$ = this.coreProjectsTypeahead.valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                switchMap((term: string) =>
                    this.relationsTypeaheadService.getProjectsByNamePrefix(term))
            );

        this.additionalProjectsSuggestions$ = this.additionalProjectsTypeahead.valueChanges
            .pipe(
                debounceTime(200),
                distinctUntilChanged(),
                switchMap((term: string) =>
                    this.relationsTypeaheadService.getProjectsByNamePrefix(term))
            );

    }

    // #region Core Projects

    public coreProjects: ProjectInfoModel[];

    private addCoreProject(project: ProjectInfoModel) {
        this.coreProjects.push(project);
    }

    public deleteCoreProject(project: ProjectInfoModel): void {
        this.removeCoreProject(project);

        if (this.isBackwardRelation(project)) {
            this.addProposedRelation(project);
        }

        this.rebuildRelationsFormValue();
    }

    private removeCoreProject(project: ProjectInfoModel): void {
        this.coreProjects = this.coreProjects.filter(s =>s.projectId !== project.projectId);
    }

    // #region Core Projects Drag/Drop

    public relationDroppedOnCoreProjects(e: CdkDragDrop<any>): void {
        if (e.previousContainer !== e.container) {
            this.addCoreProject(e.item.data);

            // remove from source
            if (e.previousContainer.id === 'selectedAdditionalProjects') {
                this.removeAdditionalProject(e.item.data);
            }
            else {
                this.removeProposedRelation(e.item.data);
            }

            this.rebuildRelationsFormValue();
        }
    }

    // #endregion Core Projects Drag/Drop

    // #region Core Projects Autocomplete

    public coreProjectsTypeahead: FormControl = new FormControl('');
    public coreProjectSuggestions$: Observable<ProjectInfoModel[]>;

    public coreProjectSelected(e: MatAutocompleteSelectedEvent): void {
        const project = e.option.value as ProjectInfoModel;
        this.addCoreProject(project);

        // if they just added a relation that was proposed, remove from proposed
        const existingProposedRelation = this.proposedRelations.find(r => r.projectId === e.option.value.projectId);

        if (existingProposedRelation) {
            this.removeProposedRelation(existingProposedRelation);
        }

        this.coreProjectsTypeahead.setValue('');
        this.rebuildRelationsFormValue();
    }

    // #endregion Core Projects Autocomplete

    // #endregion Core Projects

    // #region Additional Projects

    public additionalProjects: ProjectInfoModel[];

    private addAdditionalProject(project: ProjectInfoModel): void {
        this.additionalProjects.push(project);
    }

    public deleteAdditionalProject(project: ProjectInfoModel): void {
        this.removeAdditionalProject(project);

        if (this.isBackwardRelation(project)) {
            this.addProposedRelation(project);
        }

        this.rebuildRelationsFormValue();
    }

    private removeAdditionalProject(project: ProjectInfoModel): void {
        this.additionalProjects = this.additionalProjects.filter(s =>s.projectId !== project.projectId);
    }

    // #region Additional Projects Drag/Drop

    public relationDroppedOnAdditionalProjects(e: CdkDragDrop<any>): void {
        if (e.previousContainer !== e.container) {
            this.addAdditionalProject(e.item.data);

            // remove from source
            if (e.previousContainer.id === 'selectedCoreProjects') {
                this.removeCoreProject(e.item.data);
            }
            else {
                this.removeProposedRelation(e.item.data);
            }

            this.rebuildRelationsFormValue();
        }
    }

    // #endregion Additional ProjectsDrag/Drop

    // #region Additional Projects Autocomplete

    public additionalProjectsTypeahead: FormControl = new FormControl('');
    public additionalProjectsSuggestions$: Observable<ProjectInfoModel[]>;

    public additionalProjectSelected(e: MatAutocompleteSelectedEvent): void {
        const project = e.option.value as ProjectInfoModel;
        this.addAdditionalProject(project);

        // if they just added a relation that was proposed, remove from proposed
        const existingProposedRelation = this.proposedRelations.find(r => r.projectId === e.option.value.projectId);

        if (existingProposedRelation) {
            this.removeProposedRelation(existingProposedRelation);
        }

        this.additionalProjectsTypeahead.setValue('');
        this.rebuildRelationsFormValue();
    }

    // #endregion Additional Projects Autocomplete

    // #endregion Additional Projects

    // #region Proposed Project Relations

    public proposedRelations: ProjectInfoModel[];

    private addProposedRelation(project: ProjectInfoModel): void {
        this.proposedRelations.push(project);        
    }

    private removeProposedRelation(project: ProjectInfoModel): void {
        this.proposedRelations = this.proposedRelations.filter(s => s.projectId !== project.projectId);
    }

    // #region Proposed Relation Drag/Drop

    public relationDroppedOnProposedRelations(e: CdkDragDrop<any>): void {
        if (e.previousContainer !== e.container) {
            this.addProposedRelation(e.item.data);

            if (e.previousContainer.id == 'selectedCoreProjects') {
                this.removeCoreProject(e.item.data);
            }
            else {
                this.removeAdditionalProject(e.item.data);
            }
    
            this.rebuildRelationsFormValue();
        }
    }

    public isBackwardRelatedPredicate(item: CdkDrag<any>) {        
        const project: ProjectInfoModel = item.data;
        
        // you have to bind this function to this, for the following method to work.
        return this.isBackwardRelation(project);
    }   

    // #endregion Proposed Relation Drag/Drop

    // #endregion Proposed Project Relations

    // #region Programs and Whole Systems

    public programManagementRelations: ProjectInfoModel[];
    public wholeSystemRelations: ProjectInfoModel[];

    // #endregion Programs and Whole Systems

    // #region Helpers

    public isRelationReciprocal(relation, primary): boolean {
        const match = this.project.backwardRelations!.find((itm) => itm.project!.projectId === relation.projectId && (primary ? itm.isPrimary : !itm.isPrimary));
        return match !== undefined;
    }

    private isBackwardRelation(strategy: ProjectInfoModel) {
        return this.project.backwardRelations!.find(br => br.projectId === strategy.projectId) !== undefined;
    }

    public isRelationOptionDisabled(strategy: ProjectInfoModel): boolean {        
        // it's disabled if it's already include in either list        

        return this.coreProjects.find(as => as.projectId === strategy.projectId) !== undefined ||
        this.additionalProjects.find(as => as.projectId === strategy.projectId) !== undefined;
    }

    private rebuildRelationsFormValue() {
        // set the form control's value to the current state
        const newRelations: ProjectRelationModel[] = [];

        for (let strategy of this.coreProjects) {
            const additionalProjectRelation: ProjectRelationModel = this.createProjectRelation(strategy, true);
            newRelations.push(additionalProjectRelation);
        }

        for (let strategy of this.additionalProjects) {
            const additionalProjectRelation: ProjectRelationModel = this.createProjectRelation(strategy, false);
            newRelations.push(additionalProjectRelation);
        }

        this.projectEditForm?.controls.relations.setValue(newRelations);
        this.projectEditForm?.controls.relations.markAsDirty();
    }

    private createProjectRelation(strategy: ProjectInfoModel, isPrimary: boolean): ProjectRelationModel {
        const relation: ProjectRelationModel = {
            projectId: this.project.projectId!,
            relatedProjectId: strategy.projectId!,
            relatedProject: strategy,
            isPrimary: isPrimary
        };

        // if this relation already exists, get its projectProjectId

        const existingRelation: ProjectRelationModel | undefined = this.project.relations!.find(r => r.relatedProjectId === strategy.projectId);

        if (existingRelation) {
            relation.projectProjectId =  existingRelation.projectProjectId;
        }

        return relation;
    }

    // #endregion Helpers
}
