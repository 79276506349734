import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AttributionLevelModel, OutcomeProgressModel, OutcomeTargetModel, ProjectOutcomeModel } from '../../../../../../hub_schema/hubTypes';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { OutcomesEditService } from '../../services/outcomes-edit.service';
import { OutcomesLovService } from '../../services/outcomes-lov.service';
import { take } from 'rxjs';
import { CalculationsInfoDialogComponent } from '../../outcome-target-panel/calculations-info-dialog/calculations-info-dialog.component';

enum helperSelectorType {
    attribution = 'attribution',
    date = 'date',
    description = 'description',
    value = 'value',
    units = 'units',
}

@Component({
    selector: 'app-add-edit-outcome-progress-dialog',
    templateUrl: './add-edit-outcome-progress-dialog.component.html',
    styleUrls: ['./add-edit-outcome-progress-dialog.component.scss'],
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class AddEditOutcomeProgressDialogComponent implements OnInit {

    // injected dependencies
    private outcomesEditService: OutcomesEditService = inject(OutcomesEditService);
    private outcomesLovService: OutcomesLovService = inject(OutcomesLovService);
    private dialogService: MatDialog = inject(MatDialog);

    private dialogRef: MatDialogRef<AddEditOutcomeProgressDialogComponent> = inject(MatDialogRef<AddEditOutcomeProgressDialogComponent>);
    private data: any = inject(MAT_DIALOG_DATA);

    // page properties
    public projectOutcome: ProjectOutcomeModel;
    public existingProgress: OutcomeProgressModel | null;
    public attributionLevels: AttributionLevelModel[];
    public editProgressForm: FormGroup;
    public ncsExtentControl: FormControl;
    public isStart: boolean;
    public isSaving: boolean = false;

    public get progressType(): string {
        return this.isStart ? 'Start' : 'Progress';
    }

    public ngOnInit(): void {
        this.existingProgress = this.data.existingProgress;
        this.projectOutcome = this.data.projectOutcome;
        this.isStart = this.data.isStart;

        this.editProgressForm = new FormGroup({
            outcomeProgressId: new FormControl({
                value: this.existingProgress ? this.existingProgress.outcomeProgressId : undefined,
                disabled: !this.existingProgress}),
            projectOutcomeId: new FormControl(this.projectOutcome.projectOutcomeId),
            progressDate: new FormControl(this.existingProgress ? new Date(this.existingProgress.progressDate) : undefined, [Validators.required, this.validateProgressDate.bind(this)]),
            progressValue: new FormControl(this.existingProgress ? this.existingProgress.progressValue : null, Validators.required),
            attributionDescription: new FormControl(this.existingProgress ? this.existingProgress.attributionDescription : '', Validators.required),
            attributionLevelId: new FormControl(this.existingProgress ? this.existingProgress.attributionLevelId : null, Validators.required)
        });

        this.ncsExtentControl = new FormControl(null, [Validators.required, Validators.min(0.00001)]);

        this.outcomesLovService.getAttributionLevels().pipe(take(1)).subscribe({
            next: (attributionLevels: AttributionLevelModel[]) => {
                this.attributionLevels = attributionLevels;
            }
        });
    }

    public get progressValue(): number | null {
        return this.editProgressForm.controls.progressValue.value;
    }

    private validateProgressDate(progressDateControl: FormControl): ValidationErrors | null {
        if (progressDateControl.pristine) {
            return null;
        }

        const progressDate = new Date(progressDateControl.value);
        const today = new Date();

        if (this.isStart) {

            // Validation Rule 1 - Start/Progress dates must be in the past 
            if (progressDate.getTime() > today.getTime()) {
                return {
                    'invalid': 'Start dates must be in the past.'
                };
            }

            // Validation Rule 2 - Starts must be before existing progress
            if (this.projectOutcome.progress?.some(p => p !== this.existingProgress && p.progressDate.getTime() < progressDate.getTime())) {
                return {
                    'invalid': 'Start dates must occur before existing progress dates.'
                }
            }

            // Validation Rule 3 - Starts must be before existing targets
            if (this.projectOutcome.targets?.some(t => t.targetDate.getTime() < progressDate.getTime())) {
                return {
                    'invalid': 'Start dates must occur before existing target dates.'
                }
            }
        }
        else {
            // Validation Rule 1 - Start/Progress dates must be in the past 
            if (progressDate.getTime() > today.getTime()) {
                return {
                    'invalid': 'Progress dates must be in the past.'
                };
            }
            
            // Validation Rule 4 - Progress must be after Start
            const start = this.projectOutcome.progress?.find(p => p.isStart);

            if (!!start) {
                if (progressDate.getTime() < start.progressDate.getTime()) {
                    return {
                        'invalid': 'The date entered is before the Outcome start date.'
                    };
                }
            }
        }

        return null;
    }

    public isProgressValueInvalid(): boolean {
        const formControl: FormControl = this.editProgressForm.controls.progressValue as FormControl;
        return formControl.touched && formControl.invalid;
    }

    public getValidationErrorMessages(formControlName: string): string {
        const formControl = this.editProgressForm!.controls[formControlName];

        if (!formControl.touched) {
            return '';
        }

        if (formControl.errors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(formControl.errors)) {
                if (key == 'required') {
                    errorMessages.push(key);
                }
                else {
                    errorMessages.push(formControl.errors[key]);
                }
            }
            return errorMessages.join(', ');
        }

        return '';
    }

    // #region NCS

    private isNcsRecalculationPending: boolean;

    public get isNcsOutcome(): boolean {
        if (this.projectOutcome.outcome?.ncsInterventionId) {
            return true;
        }
        return false;
    }

    public getExtentValidationErrorMessage(): string {
        if (!this.ncsExtentControl.touched) {
            return '';
        }

        if (this.ncsExtentControl.errors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(this.ncsExtentControl.errors!)) {
                if (key == 'required') {
                    errorMessages.push(key);
                }
                else {
                    errorMessages.push('You have entered a value that is below the minimum threshhold value.');
                }
            }
            return errorMessages.join(', ');
        }
        return '';
    }

    public progressValueChanged(progressValue: number) {
        this.editProgressForm.controls.progressValue.setValue(progressValue);
        this.editProgressForm.controls.progressValue.markAsTouched();
    }

    public pendingNcsRecalculationChanged(isRecalcPending: boolean): void {
        this.isNcsRecalculationPending = isRecalcPending;
    }

    public showCalculations(): void {
        this.dialogService.open(CalculationsInfoDialogComponent, {
            width: '600px',
            data: {
                ncsInterventionId: this.projectOutcome.outcome!.ncsInterventionId
            }
        });

    }

    // #endregion NCS

    public getUnitName(): string {
        if (this.projectOutcome.outcome) {
            return this.projectOutcome.outcome.outcomeUnit!.name;
        }
        return this.projectOutcome.customOutcome!.unit;
    }

    public cancel(): void {
        this.dialogRef.close();
    }

    public isSaveDisabled(): boolean {
        if (this.isNcsOutcome) {
            return this.editProgressForm.invalid || this.ncsExtentControl.invalid || this.isNcsRecalculationPending;
        }
        return this.editProgressForm.invalid;
    }

    public save(): void {
        const outcomeProgress: OutcomeProgressModel = this.editProgressForm.value;
        outcomeProgress.isStart = this.isStart;
        this.isSaving = true;

        if (!this.existingProgress) {
            this.outcomesEditService.createProgress(outcomeProgress).subscribe((savedOutcomeProgress: OutcomeProgressModel) => {
                this.isSaving = false;
                this.dialogRef.close(savedOutcomeProgress);
            });
        }
        else {
            this.outcomesEditService.updateProgress(outcomeProgress).subscribe((savedOutcomeProgress: OutcomeProgressModel) => {
                this.isSaving = false;
                this.dialogRef.close(savedOutcomeProgress);
            });
        }
    }
}
