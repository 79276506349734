<div class="hub-notifications-container">
    <h5 class="dialog-header">Notifications</h5>

    <ng-container *ngIf="notifications">
        <div class="notifications-panel hub-custom-scrollbar" *ngIf="notifications.length; else noNotifications">
            <div *ngFor="let notification of notifications" class="hub-notification">
                <div class="notification">

                    <div class="employee-image">
                        <a *ngIf="(notification.initiatorUser.avatarObservable | async) === null || (notification.initiatorUser.avatarObservable | async) === undefined"
                            [href]="'/user/' + notification.initiatorUser.userId"
                            class="empty-icon-flex btn btn-success rounded-round btn-icon legitRipple">
                            {{notification.initiatorUser.initials}}
                        </a>
    
                        <a *ngIf="notification.initiatorUser.avatarObservable | async"
                            [href]="'/user/' + notification.initiatorUser.userId"
                            class="rounded-round btn-icon legitRipple">
                            <img [src]="notification.initiatorUser.avatarObservable | async" class="rounded-circle"
                                alt="image" style="height: 50px; width: 50px;" />
                        </a>
                    </div>
                    <div class="notification-content-panel">
                        <div class="name">
                            <a [href]="'/user/' + notification.initiatorUser.userId">
                                {{notification.initiatorUser.fullName}}
                            </a>
                        </div>
                        <div [innerHtml]="notification.bodyHtml"></div>
                    </div>
                    <div class="notification-date">
                        {{notification.dateTime | date:'MMM-dd-yyyy'}} {{convertUTCtoLocal(notification.dateTime) |
                        date:'hh:mma'}}
                    </div>
                    <div class="notification-actions-panel">
                        <div href="javascript: void(0)" class="delete-button" (click)="deleteNotification(notification)">
                            <span>x</span>
                        </div>
                        <div class="notification-context-menu">
                            <mat-icon [matMenuTriggerFor]="menu">menu</mat-icon>
                            <mat-menu #menu="matMenu">

                                <a href="mailto:{{notification.initiatorUser?.email}}?subject={{notification.initiatorUser.fullName + ' ' + notification.bodyText}}"
                                    mat-menu-item>
                                    <mat-icon>mail</mat-icon>

                                    <span style="color: black !important;">
                                        Send an e-mail
                                    </span>
                                </a>

                                <a [href]="userDataService.generateTeamsUri(notification.initiatorUser.email)" target="chat"
                                    mat-menu-item>
                                    <mat-icon>chat</mat-icon>

                                    <span style="color: black !important;">
                                        Start a Teams Chat
                                    </span>
                                </a>

                            </mat-menu>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>


    <ng-template #noNotifications>
        <div class="no-notifications-panel">
            <div class="alert alert-info alert-styled-left">
                You currently have no notifications. If you have not enabled any notifications or emails select the
                Manage Notifications and Emails button below to set them up.
            </div>
        </div>
    </ng-template>
    <div class="notifications-button-panel">
        <button (click)="gotoNotificationsManagementPage()">Manage Notifications and Emails</button>
    </div>
</div>