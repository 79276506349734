import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpService } from 'src/app/core/services/http.service';
import { environment } from 'src/environments/environment';
import { HelpTextModel } from 'src/hub_schema/hubTypes';

@Injectable()
export class HelpLovService {
    private httpService: HttpService = inject(HttpService);
    private helpTexts: HelpTextModel[] = [];

    public getHelpTextById(helpTextId: number): Observable<HelpTextModel> {
        const existingHelpText = this.helpTexts.find(ht => ht.helpTextId === helpTextId);

        if (existingHelpText) {
            return of(existingHelpText);
        }

        const url = environment.endpoints.base + '/hublov/helpText/' + helpTextId;

        return this.httpService.get<HelpTextModel>(url).pipe(
            tap((helpText: HelpTextModel) => {
                this.helpTexts.push(helpText);
            }));
    }
}
