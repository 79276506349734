import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserDataService } from './user-data.service';

declare var gtag; // This global variable is defined in index.html.

@Injectable({ providedIn: 'root' })
export class GoogleAnalyticsService {

    constructor(
        private router: Router,
        private userDataService: UserDataService) {
        this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
            gtag('event', 'page_view', {
                page_path: e.urlAfterRedirects
            })
        });

    }

    public logHubEvent(action: string, recordType: string, recordName: string, pageTitle: string, businessUnitId: number, hubUserId: number, recordId: number | null = null): void {
        gtag('event', 'hub-event', {
            hub_action: action,
            hub_user_business_unit_id: businessUnitId,
            hub_record_type: recordType,
            hub_record_name: recordName,
            hub_page_title: pageTitle,
            hub_record_id: recordId,
            hub_user_id: hubUserId
        });
    }
}
