<div class="outcome-detail-container">

    <div class="outcome-detail-panel hub-custom-scrollbar">
        <div class="metric-and-statement-panel">

            <div *ngIf="projectOutcome.isPolicyLeverageOutcome" class="heading">Leverage Outcome</div>

            <div *ngIf="projectOutcome.isPolicyLeverageOutcome" class="value record-description">
                Policy
            </div>

            <div class="heading">Outcome Statement</div>

            <div class="value record-description">
                {{projectOutcome.statement}}
            </div>
        </div>

        <div class="metric-values-panel">

            <div class="bg-white pl-2 pr-2" style="flex: 100%; margin-top: 20px;">

                <!-- METRIC VALUES -->
                <div class="active tab-pane fade show p-0" id="values">

                    <!-- VALUES -->
                    <div class="d-md-flex justify-content-between">
                        <h5 class="heading mt-2">Outcome {{isStrategy ? '' : 'Start, Progress and '}}Target Values
                            ({{projectOutcome.targets?.length + projectOutcome.progress?.length}})</h5>
                        <div class="btn-group" ngbDropdown>
                            <div ngbDropdownToggle class="hub-dropdown-toggle">
                                <b>
                                    <i class="icon-plus-circle2"></i>
                                </b>
                            </div>
                            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu
                                aria-labelledby="dropdownBasic1">
                                <button (click)="openAddEditTargetDialog(null)" class="dropdown-item">
                                    <i class="icon-plus-circle2"></i> Target
                                </button>
                                <button (click)="openAddEditProgressDialog(null, false)" class="dropdown-item"
                                    *ngIf="!isStrategy">
                                    <i class="icon-plus-circle2"></i> Progress
                                </button>
                                <button *ngIf="!isStrategy" (click)="openAddEditProgressDialog(null, true)"
                                    class="dropdown-item" [disabled]="selectedOutcomeHasStart()">
                                    <i class="icon-plus-circle2"></i> Start
                                </button>
                            </div>
                        </div>

                    </div>

                    <div class="table-responsive mb-2 metric-values-entries">
                        <table class="table table-columned table-xs metric-table">
                            <thead>
                                <tr class="font-weight-bold">
                                    <th class="text-right" width="20%">Value Type / Date</th>
                                    <th class="text-right">Metric Value</th>
                                    <th width="20%">Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- Targets on Top -->
                                <tr *ngFor="let target of projectOutcome.targets" class="target-value">
                                    <td class="text-right">
                                        <span class="text-success date-label">Target</span>
                                        <span class="d-block">{{target.targetDate | date:'yyyy-MM-dd'}}</span>
                                    </td>
                                    <td class="text-right">

                                        <div class="value">
                                            {{target.targetValue | number : '0.0-20'}}
                                            <span class="unit">
                                                {{projectOutcome?.outcome?.outcomeId ? projectOutcome.outcome.outcomeUnit.name : projectOutcome.customOutcome.unit}}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <button type="button" (click)="openAddEditTargetDialog(target)"
                                            [disabled]="projectOutcome.outcome?.ncsInterventionId"
                                            class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                            data-popup="tooltip" title="Edit value">
                                            <i class="icon-pencil"></i>
                                        </button>
                                        <button type="button" (click)="deleteTargetRequested(target)"
                                            class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                            title="Delete value">
                                            <i class="icon-trash"></i>
                                        </button>
                                    </td>

                                </tr>

                                <!-- Then Progress -->
                                <tr *ngFor="let progress of projectOutcome.progress"
                                    [class]="progress.isStart ? 'start-value' : 'progress-value'">
                                    <td class="text-right">
                                        <span *ngIf="progress.isStart" class="text-primary date-label">Start</span>
                                        <span *ngIf="!progress.isStart" class="text-dark date-label">Progress</span>
                                        <span class="d-block">
                                            {{progress.progressDate | date:'yyyy-MM-dd'}}
                                        </span>
                                    </td>
                                    <td class="text-right">

                                        <div class="value">
                                            {{progress.progressValue | number}}
                                            <span class="unit">
                                                {{projectOutcome?.outcome?.outcomeId ? projectOutcome.outcome.outcomeUnit.name : projectOutcome.customOutcome.unit}}
                                            </span>
                                        </div>

                                        <div class="attribution record-description">
                                            {{progress.attributionLevel?.name}}
                                        </div>
                                        <div class="attribution-text record-description">
                                            {{progress.attributionDescription}}
                                        </div>
                                    </td>

                                    <td>
                                        <button type="button"
                                            [disabled]="projectOutcome.outcome?.ncsInterventionId"
                                            (click)="openAddEditProgressDialog(progress, progress.isStart)"
                                            class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                            data-popup="tooltip" title="Edit value">
                                            <i class="icon-pencil"></i>
                                        </button>
                                        <button type="button"
                                            (click)="openDeleteProgressDialog(progress, progress.isStart)"
                                            class="btn btn-outline bg-primary-300 text-primary btn-icon rounded-round legitRipple"
                                            title="Delete value">
                                            <i class="icon-trash"></i>
                                        </button>
                                    </td>


                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <ng-container *ngIf="areDetailsLoaded()">
                        <div *ngIf="!doDetailsExist()"
                            class="alert alert-info alert-styled-left shadow-0">
                            There are no metric values defined yet.
                        </div>
                    </ng-container>

                </div>

            </div>

        </div>
    </div>

</div>
