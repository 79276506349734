<div class="partner-edit-dialog-container" [formGroup]="editPartnerForm" *ngIf="editPartnerForm">
    <div class="hub-modal-header-text">
      <h5 class="modal-title text-success">{{projectPartner ? 'Edit': 'Add'}} Partner</h5>
    </div>

    <div class="content">
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 col-12 form-group">
              <span class="select-label text-info-700 pb-2 d-block">Role<app-help-selector [selector]="1020"></app-help-selector></span>

              <div class="hub-select-container">
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Select a Role" disableOptionCentering formControlName="partnerRoleId">
                    <mat-option *ngFor="let partnerRole of partnerRoles" [value]="partnerRole.partnerRoleId">
                      {{partnerRole.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="hub-validation-error-message"  style="margin-top: 5px;">
                {{getValidationErrorMessages("partnerRoleId")}}
              </div>

            </div>
            <div class="col-md-6 col-12 form-group">
              <span class="select-label text-info-700 pb-2 d-block">Category<app-help-selector [selector]="1021"></app-help-selector></span>

              <div class="hub-select-container">
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Select a Category" disableOptionCentering formControlName="partnerCategoryId">
                    <mat-option *ngFor="let partnerCategory of partnerCategories" [value]="partnerCategory.partnerCategoryId">
                      {{partnerCategory.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="hub-validation-error-message" style="margin-top: 5px;">
                {{getValidationErrorMessages("partnerCategoryId")}}
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-12 form-group">
              <span class="select-label text-info-700 pb-2 d-block">{{organizationNameLabel}}</span>

              <input type="text" class="form-control" formControlName="name" placeholder="Add an Organization name" *ngIf="isOrganizationNameTextboxVisible">

              <div class="hub-select-container" *ngIf="!isOrganizationNameTextboxVisible">
                <mat-form-field appearance="outline">
                  <mat-select placeholder="Select Partner..." disableOptionCentering formControlName="name">
                    <mat-option *ngFor="let partner of filteredPartners" [value]="partner.name">
                      {{partner.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div *ngIf="isOrganizationNameTextboxVisible" class="hub-validation-error-message" >
                {{getValidationErrorMessages("name")}}
              </div>

              <div *ngIf="!isOrganizationNameTextboxVisible" class="hub-validation-error-message" style="margin-top: 5px;">
                {{getValidationErrorMessages("name")}}
              </div>

            </div>

            <div class="col-md-6 col-12 form-group">
              <span class="select-label text-info-700 pb-2 d-block">Contact Name<app-help-selector [selector]="1024"></app-help-selector></span>
              <input type="text" class="form-control" formControlName="contactInfo" placeholder="Contact name">

              <div class="hub-validation-error-message">
                {{getValidationErrorMessages("contactInfo")}}
              </div>
            </div>

          </div>

          <div class="row">

            <div class="col-md-6 col-12 form-group">
              <span class="select-label text-info-700 pb-2 d-block">Contact Email</span>
              <input type="text" class="form-control" formControlName="email" placeholder="E-mail for Contact">

              <div class="hub-validation-error-message">
                {{getValidationErrorMessages("email")}}
              </div>

            </div>

            <div class="col-md-6 col-12 form-group">
              <div class="select-label text-info-700 pb-2 d-block">Primary Point of Contact</div>
              <div class="poc-checkbox-panel">
                  <mat-checkbox color="primary" formControlName="isPoc"></mat-checkbox>
                  <div class="poc-label">
                      This is the primary POC for this project.
                  </div>
              </div>

              <div class="hub-validation-error-message" style="margin-top: 5px;" *ngIf="isEmailErrorMessageVisible">
                  A valid email is required for this Partner to be the POC
              </div>

            </div>

          </div>

        </div>
      </div>

    </div>

    <div class="hub-modal-footer-text">
      <button (click)="cancel()" class="cancel-button" style="margin-right: 5px;">Close</button>

      <button (click)="submitPartner()" class="hub-icon-button"
          [disabled]="!editPartnerForm.valid || editPartnerForm.pristine">
          <b>
              <i class="icon-plus-circle2 ml-2"></i>
          </b>
          {{projectPartner ? 'Update Partner' : 'Add Partner'}}
      </button>
    </div>

  </div>
