import { Injectable, inject } from '@angular/core';
import { HttpService } from '../../../../core/services/http.service';
import { Observable, of } from 'rxjs';
import { AvailableParentIntermediateResultsModel, IntermediateResultDetailsModel, IntermediateResultProgressModel, IntermediateResultTargetModel, ProjectIntermediateResultModel } from '../../../../../hub_schema/hubTypes';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class IntermediateResultsEditService {
    private httpService: HttpService = inject(HttpService);

    // #region IR CRUDs

    public getIrMetricNameSuggestions(prefix: string) {
        if (prefix.length === 0) {
            return of([]);
        }
        const url = environment.endpoints.base + '/intermediateResults/metricNames/' + prefix;
        return this.httpService.get<string[]>(url);
    }

    public getIrUnitNameSuggestions(prefix: string) {
        if (prefix.length === 0) {
            return of([]);
        }
        const url = environment.endpoints.base + '/intermediateResults/unitNames/' + prefix;
        return this.httpService.get<string[]>(url);
    }

    public createIntermediateResult(intermediateResult: ProjectIntermediateResultModel): Observable<ProjectIntermediateResultModel> {
        const url = environment.endpoints.base + '/intermediateResults';
        return this.httpService.post<ProjectIntermediateResultModel>(url, intermediateResult);
    }

    public updateIntermediateResult(intermediateResult: ProjectIntermediateResultModel): Observable<ProjectIntermediateResultModel> {
        const url = environment.endpoints.base + '/intermediateResults';
        return this.httpService.patch<ProjectIntermediateResultModel>(url, intermediateResult);
    }

    public deleteProjectIntermediateResult(projectIntermediateResultId: number): Observable<void> {
        const url = environment.endpoints.base + '/intermediateResults/' + projectIntermediateResultId;
        return this.httpService.delete(url);
    }

    // #endregion IR CRUDs

    // #region Archiving and Activating

    public archiveIntermediateResults(ids: number[]): Observable<void> {
        const url = environment.endpoints.base + '/intermediateResults/archive/';        
        return this.httpService.post(url, ids);
    }

    public activateIntermediateResults(ids: number[]): Observable<void> {
        const url = environment.endpoints.base + '/intermediateResults/activate/';
        return this.httpService.post(url, ids);
    }

    // #endregion

    // #region Utils

    public getIntermediateResultDetails(projectIntermediateResultId: number): Observable<IntermediateResultDetailsModel> {
        const url = environment.endpoints.base + `/intermediateResults/intermediateResultDetails/${projectIntermediateResultId}/false`;
        return this.httpService.get<IntermediateResultDetailsModel>(url);
    }

    public getAvailableParentIRs(projectId: number, isStrategy: boolean, existingIrId: number | undefined = undefined): Observable<AvailableParentIntermediateResultsModel[]> {
        if (isStrategy) {
            return of([]);
        }
        
        let url = environment.endpoints.base + '/intermediateResults/availableParentIntermediateResults/' + projectId;

        if (existingIrId) {
            url += '/' + existingIrId;
        }

        return this.httpService.get<AvailableParentIntermediateResultsModel[]>(url);
    }

    public updateIntermediateResultOrder(projectId: number, orderedIrIds: number[]): Observable<boolean> {
        const url = environment.endpoints.base + '/intermediateResults/sortOrder/' + projectId;
        return this.httpService.patch<boolean>(url, orderedIrIds);
    }


    // #endregion Utils

    // #region targets

    public createTarget(target: IntermediateResultTargetModel): Observable<IntermediateResultTargetModel> {
        const url = environment.endpoints.base + '/intermediateResults/targets';
        return this.httpService.post<IntermediateResultTargetModel>(url, target);
    }

    public updateTarget(target: IntermediateResultTargetModel): Observable<IntermediateResultTargetModel> {
        const url = environment.endpoints.base + '/intermediateResults/targets';
        return this.httpService.patch<IntermediateResultTargetModel>(url, target);
    }

    public deleteIrTarget(targetId: number): Observable<void> {
        const url = environment.endpoints.base + '/intermediateResults/targets/' + targetId;
        return this.httpService.delete(url);
    }

    // #endregion targets

    // #region Progress

    public createProgress(progress: IntermediateResultProgressModel): Observable<IntermediateResultProgressModel> {
        const url = environment.endpoints.base + '/intermediateResults/progress';
        return this.httpService.post<IntermediateResultProgressModel>(url, progress);
    }

    public updateProgress(progress: IntermediateResultProgressModel): Observable<IntermediateResultProgressModel> {
        const url = environment.endpoints.base + '/intermediateResults/progress';
        return this.httpService.patch<IntermediateResultProgressModel>(url, progress);
    }

    public deleteIrProgress(progressId: number): Observable<void> {
        const url = environment.endpoints.base + '/intermediateResults/progress/' + progressId;
        return this.httpService.delete(url);
    }

    // #endregion Progress
}
