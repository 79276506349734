import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-dashboards',
    templateUrl: './dashboards.component.html',
    styleUrls: ['./dashboards.component.scss']
})
export class DashboardsComponent implements OnInit {
    private titleService: Title = inject(Title);

    public ngOnInit(): void {
        let title = 'Hub - Dashboards';
        this.titleService.setTitle(title);
    }
}
