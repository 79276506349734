<div class="hub-logged-out-container">

    <!-- Page header -->
    <div class="page-header">
        <div class="page-header-content header-elements-md-inline">
            <div class="page-title d-flex">
                <h4><span class="font-weight-semibold text-success-800" id="PageTitle">Logged Out</span></h4>
                <a href="#" class="header-elements-toggle text-default d-md-none"><i
                        class="icon-close2 icon-3x"></i></a>
            </div>
        </div>
    </div>
    <!-- /page header -->

    <!-- Content area -->
    <div class="content p-0 mt-0 pt-0 d-flex flex-wrap align-content-stretch" style="bottom: 0px;">
        <div class="col-lg-12 p-2 pl-4 pr-4 bg-white">

            <div class="media">
                <div class="mr-3">
                    <i class="icon-close2 icon-3x text-danger"></i>
                </div>
                <div class="media-body">
                    <h3 class="media-title text-danger">Logged Out</h3>
                    <p class="narrative">You have logged out successfully. To log back in, click <a
                            routerLink="/">here</a>.
                    </p>
                </div>
            </div>

        </div>
    </div>


</div>