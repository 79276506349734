import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { OutputDetailsModel } from '../../../hub_schema/hubTypes';
import { HttpService } from './http.service';

@Injectable()
export class OutputDetailService {
    private httpService: HttpService = inject(HttpService);
    
    public getProjectOutputDetails(projectOutputId: number): Observable<OutputDetailsModel> {
        const url = environment.endpoints.base + '/outputs/outputDetails/' + projectOutputId;
        return this.httpService.get<OutputDetailsModel>(url);
    }
}
