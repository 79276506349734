import { Injectable } from '@angular/core';
import { ManageNotificationsAndEmailsComponent } from '../manage-notifications-and-emails.component';

@Injectable({
    providedIn: 'root',
})
export class CloseNotificationsPreferencesGuard {
    canDeactivate(component: ManageNotificationsAndEmailsComponent) {
        return component.notificationsPreferencesForm.dirty ? component.showUnsavedWarning() : true;
    }
}
