import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GoogleAnalyticsService } from './google-analytics.service';
import { UserDataService } from './user-data.service';

@Injectable({
    providedIn: 'root'
})
export class UsageService {

    constructor(
        private googleAnalyticsService: GoogleAnalyticsService,
        private userDataService: UserDataService) { }

    public logHubUsage(action: string, recordType: string, recordName: string, pageTitle: string, recordId: number | null = null) {
        const userId = this.userDataService.getUserId();
        const businessUnitId = this.userDataService.getUserBusinessUnitId();

        // log to google-analytics
        this.googleAnalyticsService.logHubEvent(action, recordType, recordName, pageTitle, businessUnitId, userId, recordId);

        // log to app-insights
        const properties = {
            action: action,
            recordType: recordType,
            recordId: recordId,
            recordName: recordName,
            pageTitle: pageTitle,
            userId: userId,
            userBusinessUnitId: businessUnitId
        };

        // log to SQL table
        const url = environment.endpoints.base + '/usage';
        const customMeasurements = {
            recordId: recordId
        };
        const data = {
            customDimensions: JSON.stringify(properties),
            customMeasurements: JSON.stringify(customMeasurements)
        };

        // Todo (ACE 1/9/2023) Delete this when we know it's no longer needed.
        // this.httpService.put(url, data).pipe(take(1)).subscribe();
    }
}
