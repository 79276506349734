import { Component, OnInit, inject } from '@angular/core';
import { MapLocation } from '../../../shared/types/location';
import { HubRecordEditorBase } from '../_hub_record_editor_base';
import { BusinessUnitModel, GeographyModel, ProjectBusinessUnitModel, ProjectGeographyModel, ProjectSpatialExtentModel, SpatialExtentGroupModel, SpatialExtentModel } from '../../../../hub_schema/hubTypes';
import { LocationLovService } from './services/location-lov-service';
import { Observable, map, startWith, take } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-location-edit-container',
    templateUrl: './location-edit-container.component.html',
    styleUrls: ['./location-edit-container.component.scss']
})
export class LocationEditContainerComponent extends HubRecordEditorBase implements OnInit {
    // dependencies
    private locationLovService: LocationLovService = inject(LocationLovService);

    // LOV tables
    public businessUnits: BusinessUnitModel[];
    public countries: GeographyModel[];
    public states: GeographyModel[];
    public spatialExtentGroups: SpatialExtentGroupModel[];
    public spatialExtents: SpatialExtentModel[];

    // typeaheads
    public participatingBuTypeahead: FormControl = new FormControl('');
    public filteredBusinessUnits$: Observable<BusinessUnitModel[]>;

    public countriesTypeahead: FormControl = new FormControl('');
    public filteredCountries$: Observable<GeographyModel[]>;

    public statesTypeahead: FormControl = new FormControl('');
    public filteredStates$: Observable<GeographyModel[]>;

    public isBusy: boolean = false;

    public ngOnInit(): void {
        this.isBusy = true;

        this.locationLovService.getLocationLovs().pipe(take(1)).subscribe((locationLovs: any) => {
            this.businessUnits = locationLovs.businessUnits;
            this.countries = locationLovs.countries;
            this.states = locationLovs.states;
            this.spatialExtents = locationLovs.spatialExtents;
            this.spatialExtentGroups = locationLovs.spatialExtentGroups;
            this.isBusy = false;

            // these 2 properties compensate for the mismatch between the project model and the UI
            this._leadBusinessUnitId = this.project.projectBusinessUnits?.find(pbu => pbu.isLeadBusinessUnit)!.businessUnitId!;
            this.participatingBusinessUnits = this.project.projectBusinessUnits?.filter(pbu => !pbu.isLeadBusinessUnit);

            this._selectedSpatialExtentGroupId = this.project.projectSpatialExtents?.length
                ? this.spatialExtentGroups.find(seg => seg.spatialExtentGroupId === this.project.projectSpatialExtents![0].spatialExtent!.spatialExtentGroupId)!
                    .spatialExtentGroupId
                : this.getDefaultSpatialExtentGroupId();

            this._selectedSpatialExtentIds = this.project.projectSpatialExtents!.map(pse => pse.spatialExtentId);

            // wire up typeaheads
            this.filteredBusinessUnits$ = this.participatingBuTypeahead.valueChanges.pipe(
                startWith(''),
                map((value) => this.filterBUs(value))
            );

            this.filteredCountries$ = this.countriesTypeahead.valueChanges.pipe(
                startWith(''),
                map((value) => this.filterCountries(value))
            );
            this.filteredStates$ = this.statesTypeahead.valueChanges.pipe(
                startWith(''),
                map((value) => this.filterStates(value))
            );

        });
    }


    public override getValidationErrorMessages(formControlName: string): string {
        const formControl = this.projectEditForm!.controls[formControlName];

        if (formControl.errors) {
            const errorMessages: string[] = [];

            for (const key of Object.keys(formControl.errors)) {
                let errorMessage = key;
                if (key !== 'required') {
                    errorMessage = formControl.errors[key];
                }
                if (key === 'maxlength') {
                    const maxLength = formControl.errors[key].requiredLength;
                    const actualLength = formControl.errors[key].actualLength;

                    errorMessage = `Max length of ${maxLength} characters exceeded by ${actualLength - maxLength}.`;
                }
                errorMessages.push(errorMessage);
            }
            return errorMessages.join('<br />');
        }

        return '';
    }

    // #region Business Units

    public buIdentifier(index, pbu: ProjectBusinessUnitModel) {
        return pbu.businessUnitId;
    }

    public getBuName(bu: BusinessUnitModel) {
        if (bu && bu.name) {
            return bu.name;
        }
    }

    private filterBUs(value: string): BusinessUnitModel[] {
        if (typeof value !== 'string') {
            return [];
        }
        return this.businessUnits.filter((bu) => bu.name.toLowerCase().includes(value.toLowerCase()));
    }

    // #region Lead BU

    private _leadBusinessUnitId: number;

    public get leadBusinessUnitId(): number {
        return this._leadBusinessUnitId;
    }
    public set leadBusinessUnitId(value: number) {
        this._leadBusinessUnitId = value;

        // replace in project BUs
        const leadProjectBusinessUnit = this.projectEditForm!.controls.projectBusinessUnits.value.find(pbu => pbu.isLeadBusinessUnit);

        leadProjectBusinessUnit.businessUnitId = value;
        leadProjectBusinessUnit.businessUnit = this.businessUnits.find(bu => bu.businessUnitId === value);
        this.projectEditForm!.controls.projectBusinessUnits.markAsDirty();
        this.projectEditForm!.controls.projectBusinessUnits.updateValueAndValidity();
    }

    public isLeadBuDisabled(bu: BusinessUnitModel): boolean {
        const existingParticipatingBU = this.participatingBusinessUnits!.find((pbu) => pbu.businessUnitId === bu.businessUnitId);
        return existingParticipatingBU != null;
    }

    // #endregion Lead BU

    // #region Participating BUs

    public participatingBusinessUnits: ProjectBusinessUnitModel[] | undefined;

    public isParticipatingBuDisabled(bu: BusinessUnitModel): boolean {
        if (bu.businessUnitId === this.leadBusinessUnitId) {
            return true;
        }

        const existingBU = this.participatingBusinessUnits!.find((exBu) => exBu.businessUnitId === bu.businessUnitId);
        return existingBU !== undefined;
    }

    public addParticipatingBusinessUnit(e, typeaheadInputBox): void {
        const bu = e.option.value as BusinessUnitModel;

        const pbu: ProjectBusinessUnitModel = {
            projectBusinessUnitId: 0,
            projectId: this.project.projectId,
            businessUnitId: bu.businessUnitId,
            businessUnit: bu,
            isLeadBusinessUnit: false
        };

        this.participatingBusinessUnits!.push(pbu);

        this.projectEditForm!.controls.projectBusinessUnits.value.push(pbu);
        this.projectEditForm!.controls.projectBusinessUnits.markAsDirty();
        this.projectEditForm!.controls.projectBusinessUnits.updateValueAndValidity();
        this.participatingBuTypeahead.setValue('');
        typeaheadInputBox.blur();
    }

    public removeParticipatingBusinessUnit(projectBusinessUnit: ProjectBusinessUnitModel): void {
        // first remove from display list
        this.participatingBusinessUnits = this.participatingBusinessUnits?.filter(pbu => pbu.businessUnitId !== projectBusinessUnit.businessUnitId);

        // then remove from form value
        const participatingProjectBUs = this.projectEditForm!.controls.projectBusinessUnits.value
            .filter(bu => bu.businessUnitId !== projectBusinessUnit.businessUnitId);

        this.projectEditForm!.controls.projectBusinessUnits.setValue(participatingProjectBUs);
        this.projectEditForm!.controls.projectBusinessUnits.markAsDirty();
        this.projectEditForm!.controls.projectBusinessUnits.updateValueAndValidity();
    }


    // #endregion Participating BUs

    // #endregion Business Units

    // #region Countries

    private filterCountries(value: string): GeographyModel[] {
        if (typeof value !== 'string') {
            return [];
        }
        return this.countries.filter((geography) => geography.name.toLowerCase().startsWith(value.toLowerCase()));
    }

    public getCountryName(country: GeographyModel) {
        if (country && country.name) {
            return country.name;
        }
    }

    public addCountry(e, typeaheadInputBox): void {
        const geoegraphy: GeographyModel = e.option.value;

        const projectGeography: ProjectGeographyModel = {
            projectGeographyId: 0,
            projectId: this.project.projectId,
            geographyId: geoegraphy.geographyId,
            geography: this.countries.find(c => c.geographyId === geoegraphy.geographyId)
        };

        this.projectEditForm!.controls.countries.value.push(projectGeography);
        this.projectEditForm!.controls.countries.markAsDirty();
        this.countriesTypeahead.setValue('');
        typeaheadInputBox.blur();
    }

    public deleteCountry(country: ProjectGeographyModel): void {
        const countries = this.projectEditForm!.controls.countries.value
            .filter(pg => pg.geographyId !== country.geographyId);

        this.projectEditForm!.controls.countries.setValue(countries);
        this.projectEditForm!.controls.countries.markAsDirty();
    }

    public isCountryDisabled(country: GeographyModel): boolean {
        const existingCountries: ProjectGeographyModel[] = this.projectEditForm!.controls.countries.value;
        const existingCountry = existingCountries.find((pg: ProjectGeographyModel) => pg.geographyId === country.geographyId);
        return existingCountry != null;
    }

    // #endregion Countries

    // #region States

    private filterStates(value: string): GeographyModel[] {
        if (typeof value !== 'string') {
            return [];
        }
        return this.states.filter((geography) => geography.name.toLowerCase().startsWith(value.toLowerCase()));
    }

    public getStateName(state: GeographyModel) {
        if (state && state.name) {
            return state.name;
        }
    }

    public addState(e, typeaheadInputBox): void {
        const geoegraphy: GeographyModel = e.option.value;

        const projectGeography: ProjectGeographyModel = {
            projectGeographyId: 0,
            projectId: this.project.projectId,
            geographyId: geoegraphy.geographyId,
            geography: this.states.find(c => c.geographyId === geoegraphy.geographyId)
        };

        this.projectEditForm!.controls.states.value.push(projectGeography);
        this.projectEditForm!.controls.states.markAsDirty();
        this.statesTypeahead.setValue('');
        typeaheadInputBox.blur();
    }

    public deleteState(state: GeographyModel): void {
        const states = this.projectEditForm!.controls.states.value
            .filter(geo => geo.geographyId !== state.geographyId);

        this.projectEditForm!.controls.states.setValue(states);
        this.projectEditForm!.controls.states.markAsDirty();
    }

    public isStateDisabled(state: GeographyModel): boolean {
        const existingStates: GeographyModel[] = this.projectEditForm!.controls.states.value;
        const existingState = existingStates.find((s) => s.geographyId === state.geographyId);
        return existingState != null;
    }

    // #endregion State4s

    // #region Spatial Extents

    private _selectedSpatialExtentIds: number[];

    public get selectedSpatialExtentIds(): number[] {
        return this._selectedSpatialExtentIds;
    }

    public set selectedSpatialExtentIds(newSpatialExtentIds: number[]) {
        const seFormControl = this.projectEditForm!.controls.projectSpatialExtents as FormControl;
        const formSpatialExtentIds = seFormControl.value.map(se => se.spatialExtentId);

        // first remove any spatial extents not in new selection
        let selectedProjectSpatialExtents = seFormControl.value
            .filter(pse => newSpatialExtentIds.includes(pse.spatialExtentId));

        // now add any spatial extents not in form control already
        const spatialExtentIdsToAdd = newSpatialExtentIds.filter(id => !formSpatialExtentIds.includes(id));

        for (let id of spatialExtentIdsToAdd) {
            const existingProjectSpatialExtent = this.project.projectSpatialExtents?.find(pse => pse.spatialExtentId === id);

            if (existingProjectSpatialExtent) {
                // it was removed, then re-added, so we preserve the original object
                selectedProjectSpatialExtents.push(existingProjectSpatialExtent);
            }
            else {
                const newProjectSpatialExtent: ProjectSpatialExtentModel = {
                    spatialExtentId: id,
                    projectId: this.project.projectId,
                    spatialExtent: this.spatialExtents.find(se => se.spatialExtentId === id)
                };

                selectedProjectSpatialExtents.push(newProjectSpatialExtent);
            }
        }

        seFormControl.setValue(selectedProjectSpatialExtents);
        seFormControl.markAsDirty();
        this._selectedSpatialExtentIds = newSpatialExtentIds;
    }

    public isSpatialExtentPanelVisible(): boolean {
        const selectedGroupId = this.selectedSpatialExtentGroupId;
        return selectedGroupId !== this.getDefaultSpatialExtentGroupId();
    }

    public getFilteredSpatialExtents(): SpatialExtentModel[] {
        return this.spatialExtents
            .filter((se) => se.spatialExtentGroupId === this.selectedSpatialExtentGroupId)
            .sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
    }

    public isClearAllSpatialExtentsVisible(): boolean {
        const selectedSpatialExtents = this.projectEditForm!.controls.projectSpatialExtents.value as SpatialExtentModel[];
        return selectedSpatialExtents?.length > 0;
    }

    public clearAllSpatialExtents(e: Event): void {
        const selectedSpatialExtentsControl = this.projectEditForm!.controls.projectSpatialExtents;
        this.selectedSpatialExtentIds = [];
        selectedSpatialExtentsControl.setValue([]);
        selectedSpatialExtentsControl.markAsDirty();
        e.stopPropagation();
    }


    // #endregion Spatial Extents

    // #region Spatial Extent Groups

    private _selectedSpatialExtentGroupId: number;

    public get selectedSpatialExtentGroupId(): number {
        return this._selectedSpatialExtentGroupId;
    }

    public set selectedSpatialExtentGroupId(value: number) {
        this._selectedSpatialExtentGroupId = value;
    }

    public getDefaultSpatialExtentGroup(): SpatialExtentGroupModel {
        return this.spatialExtentGroups.find((seg) => seg.name === 'None of These')!;
    }

    public getDefaultSpatialExtentGroupId(): number {
        return this.getDefaultSpatialExtentGroup().spatialExtentGroupId;
    }

    public getSelectedSpatialExtentGroupName(): string {
        const selectedSpatialExtentGroup = this.spatialExtentGroups.find((seg) => seg.spatialExtentGroupId === this.selectedSpatialExtentGroupId);

        if (selectedSpatialExtentGroup) {
            return selectedSpatialExtentGroup.name;
        }

        return '';
    }

    public getSpatialExtentGroupHelperTextId(): number {
        // todo (ACE 9/29/2023): Don't think this is a safe way of doing this.
        // LOV tables (including LOV.SpatialExtent) all start at 1000, but our helper text had 31 entries in it before we got around to spatial extent helper text
        if (this.selectedSpatialExtentGroupId === this.getDefaultSpatialExtentGroupId()) {
            return 0;
        }
        return this.selectedSpatialExtentGroupId + 32;
    }

    // #endregion Spatial Extent Groups

    public mapLocationSelected(mapLocation: MapLocation) {
        this.projectEditForm!.controls.longitude.setValue(mapLocation.longitude);
        this.projectEditForm!.controls.longitude.markAsDirty();

        this.projectEditForm!.controls.latitude.setValue(mapLocation.latitude);
        this.projectEditForm!.controls.latitude.markAsDirty();
    }
}
