import { clone } from './clone';

export enum SortOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}

export const sortDate = (arr, attr, order) => {
    return clone(arr).sort((a, b) => {
        return (order === SortOrder.DESC) ? new Date(b[attr]).getTime() - new Date(a[attr]).getTime() :
            new Date(a[attr]).getTime() - new Date(b[attr]).getTime();
    });
};
