<div class="collections-container" *ngIf="recordCollectionsSearchResults">

    <div class="page-header">
        <h4>
            <span class="font-weight-semibold text-success-800">Collections
                <app-help-selector selector="1152">
                </app-help-selector>
            </span>
        </h4>
    </div>

    <div class="search-and-filter-panel" [formGroup]="collectionsFilterForm" *ngIf="collectionsFilterForm">

        <!--FREE TEXT SEARCH-->
        <h3 class="text-success">Search and Filter</h3>

        <div class="ais-SearchBox">
            <div class="ais-SearchBox-form">
                <input formControlName="searchText" class="ais-SearchBox-input" placeholder="Search">
                <button class="ais-SearchBox-submit">
                    <svg viewBox="0 0 40 40" width="40" height="40" class="ais-SearchBox-submitIcon">
                        <path d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z"></path></svg></button><button type="reset" class="ais-SearchBox-reset" title="Reset" hidden=""><svg viewBox="0 0 20 20" width="20" height="20" class="ais-SearchBox-resetIcon"><path d="M8.114 10L.944 2.83 0 1.885 1.886 0l.943.943L10 8.113l7.17-7.17.944-.943L20 1.886l-.943.943-7.17 7.17 7.17 7.17.943.944L18.114 20l-.943-.943-7.17-7.17-7.17 7.17-.944.943L0 18.114l.943-.943L8.113 10z"></path>
                    </svg>
                </button>
            </div>
        </div>

        <!--Collection Type-->
        <div class="d-flex flex-wrap align-content-stretch mt-3">
            <label class="d-block font-weight-bold font-size-lg ml-1">
            Collection Type<app-help-selector selector="1153"></app-help-selector>
            </label>
        </div>

        <div class="form-group" formArrayName="collectionTypeFilters">

            <div class="filters-panel" *ngFor="let filterGroup of filtersArray.controls; let i = index">
                <ng-container [formGroupName]="i">
                    <mat-checkbox color="primary" formControlName="isSelected"></mat-checkbox>
                    <span>{{ getCollectionTypeName(filterGroup) }}</span>
                    <span class="number-of-hits">{{ getNumberOfResults(filterGroup) }}</span> 
                </ng-container>
            </div>

        </div>

        <div class="d-flex flex-wrap align-content-stretch">
            <label class="d-block font-weight-bold font-size-lg ml-1">
            Private<app-help-selector selector="1154"></app-help-selector>
            </label>
        </div>

        <mat-checkbox color="primary" formControlName="isPrivate"></mat-checkbox>
        <span>My Collections</span>
    </div>

    <div class="results-panel">

        <div class="results-heading">

            <h3 class="align-self-center mb-0 text-success">
                Collections <span class="text-muted">({{recordCollectionsSearchResults.filteredCollectionsCount}})</span>
            </h3>

            <app-hub-paginator 
                [currentPage]="recordCollectionsSearchResults.page"
                [totalItems]="recordCollectionsSearchResults.filteredCollectionsCount"
                [totalPages]="recordCollectionsSearchResults.filteredPagesCount"
                (pageRequested)="pageRequested($event)">
            </app-hub-paginator>

            <button type="button"
                    class="btn-outline bg-primary-300 text-primary btn-icon btn rounded-round ml-2"
                    (click)="openProjectCollectionCreateDialog()">
            <i class="icon-plus-circle2 mr-1"></i>
            Create Collection
            </button>

        </div>

        <div class="results-hits-panel hub-custom-scrollbar">
            <div class="container-fluid" *ngIf="recordCollectionsSearchResults.recordCollections.length">
                <div class="row">
                    <div class="col-4" *ngFor="let recordCollection of recordCollectionsSearchResults.recordCollections">                        
                        <app-record-collection
                             [recordCollection]="recordCollection"
                            (deleteRecordCollectionRequested)="deleteRecordCollectionRequested($event)">
                        </app-record-collection>
                    </div>
                </div>
            </div>

            <div class="container-fluid" *ngIf="recordCollectionsSearchResults.recordCollections?.length === 0">
                <div class="row">
                    <div class="col-12 p-2">
                        <div class="alert alert-info alert-styled-left shadow-0 mr-3">
                            There are no collections available yet, click Create New Collection to create one.
                        </div>
                    </div>
                </div>

            </div>

        </div>



    </div>

</div>
