<div class="confirm-anchor-record-selection-modal">
    <div class="content">
        <div class="dialog-container">
            <div class="dialog-icon">!</div>

            <div class="dialog-content">
                <div class="dialog-header">
                    <div class="title" style="font-size: 22px;">
                        Confirm Anchor Record Selection
                    </div>
                </div>

                <div class="dialog-body hub-custom-scrollbar">
                    <p *ngIf="isFinanceContactMismatched">
                        Selected Insight Finance Contact does not match the Hub Finance Contact.
                    </p>
                    <p *ngIf="isBusinessUnitMismatched">
                        Selected Insight Business Unit does not match the Lead Business Unit of the Hub Record.
                    </p>
                </div>

                <div class="dialog-footer">
                    <button (click)="cancel()" type="button"
                        class="btn cancel-button mr-1">
                        Cancel
                    </button>
                    <button class="btn btn-labeled legitRipple" (click)="confirm()">
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>