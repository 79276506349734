<div class="add-edit-target-dialog-container" *ngIf="editTargetForm">
    <div mat-dialog-title class="hub-modal-header-text">
        <h5 class="modal-title text-success">
            {{ !existingTarget ? "Add" : "Edit" }} Outcome Target
        </h5>
    </div>

    <mat-dialog-content class="hub-custom-scrollbar">
        <div [formGroup]="editTargetForm">
            <div class="content">
                <fieldset class="p-1">
                    <app-outcome-target-panel 
                        [editTargetForm]="editTargetForm" 
                        [projectOutcome]="projectOutcome" 
                        [is2030Goals]="projectOutcome.outcome"
                        (pendingNcsRecalculationChanged)="pendingNcsRecalculationChanged($event)"
                        >
                    </app-outcome-target-panel>                                    
                </fieldset>
            </div>
        </div>
    
    </mat-dialog-content>

    <div class="hub-modal-footer-text">
        <button (click)="cancelDialog()" type="button" class="cancel-button">
            Cancel
        </button>
        
        <button type="button" class="hub-icon-button SaveButton" [ngClass]="{ saving: isSaving }"
            [disabled]="isSaveDisabled()" (click)="saveTarget()">
            <b>
                <i class="icon-floppy-disk"></i>
                <i class="icon-spinner4"></i>
            </b>
            Save
        </button>
    </div>


</div>