<div class="hub-edit-summary-container">
    <form [formGroup]="projectEditForm" *ngIf="projectEditForm">

        <h5 class="edit-section-header">
            Summary<app-help-selector [selector]="1000"></app-help-selector>
        </h5>

        <app-project-name-suggestions 
            [initialValue]="project.name"
            [formGroup]="projectEditForm"
            (projectNameChanged)="projectNameChanged($event)" 
            (projectNameValidityChanged)="projectNameValidityChanged($event)">
        </app-project-name-suggestions>

        <span class="select-label text-info-700 pb-1 mt-3 d-block" style="margin-top: 40px !important;">
            Brief Description<app-help-selector [selector]="1003">
            </app-help-selector>
        </span>
        <div class="note-editor note-frame card">

            <ckeditor [formControl]="projectEditForm.controls['briefDescription']" [editor]="Editor"
                [config]="{ toolbar: ckTabConfig }"></ckeditor>
        </div>
        <div class="hub-validation-error-message">
            {{getValidationErrorMessages("briefDescription")}}
        </div>
        <div class="mt-1 text-muted font-size-sm" *ngIf="isOfRecordTypeForAnchorRecord && anchorRecord">
            The first 400 characters of the Brief Description will update the Insight Anchor Record Description.
        </div>


        <div class="row" *ngIf="isStrategy">
            <div class="col-md-3 col-sm-6">
                <span class="select-label text-info-700 mt-3 d-block">
                    Lobbying Strategy<app-help-selector [selector]="1175"></app-help-selector>
                </span>

                <div class="hub-select-container">
                    <mat-form-field>
                        <mat-select formControlName="isLobbyingRecord" disableOptionCentering
                            placeholder="Is this a Lobbying Strategy?">
                            <mat-option [value]="true">Yes</mat-option>
                            <mat-option [value]="false">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="hub-validation-error-message">
                    {{getValidationErrorMessages("isLobbyingRecord")}}
                </div>
            </div>
        </div>


        <div class="row" *ngIf="!isWholeSystem && !isProgramManagement">
            <div class="col-md-12">
                <span class="select-label text-info-700 pb-1 mt-3 d-block" style="margin-top: 20px;">
                    Goal
                    <app-help-selector [selector]="1004"></app-help-selector>
                </span>
                <div class="note-editor note-frame card">
                    <ckeditor [formControl]="projectEditForm.controls['goal']" [editor]="Editor"
                        [config]="{ toolbar: ckTabConfig }"></ckeditor>
                </div>

                <div class="hub-validation-error-message">
                    {{getValidationErrorMessages("goal")}}
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-md-4 col-sm-6 summary-date">
                <span class="select-label text-info-700 pb-1 mt-3 d-block">
                    Start Date (Actual)<app-help-selector [selector]="1005"></app-help-selector>
                </span>

                <mat-form-field class="hub-date-field">
                    <input matInput readonly (click)="startDatePicker.open()" [matDatepicker]="startDatePicker" formControlName="startDate" placeholder="Start Date">
                    <mat-datepicker-toggle matIconSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker disabled="false" #startDatePicker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-md-4 col-sm-6 summary-date">
                <span class="select-label text-info-700 pb-1 mt-3 d-block">
                    Completion Date (Planned)<app-help-selector [selector]="1006"></app-help-selector>
                </span>
                
                <mat-form-field class="hub-date-field">
                    <input matInput readonly (click)="completionDatePlannedPicker.open()" [matDatepicker]="completionDatePlannedPicker" placeholder="Completion Date (Planned)" formControlName="completionDate">
                    <mat-datepicker-toggle matIconSuffix [for]="completionDatePlannedPicker"></mat-datepicker-toggle>
                    <mat-datepicker #completionDatePlannedPicker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-md-4 col-sm-6 summary-date">
                <span class="select-label text-info-700 pb-1 mt-3 d-block">
                    Completion Date (Actual)<app-help-selector [selector]="1007"></app-help-selector>
                </span>

                <mat-form-field class="hub-date-field">
                    <input matInput readonly (click)="completionDateActualPicker.open()" [matDatepicker]="completionDateActualPicker" placeholder="Completion Date (Actual)" formControlName="completionDateActual">
                    <mat-datepicker-toggle matIconSuffix [for]="completionDateActualPicker"></mat-datepicker-toggle>
                    <mat-datepicker #completionDateActualPicker></mat-datepicker>
                </mat-form-field>

            </div>
        </div>

        <div class="row" *ngIf="!isProgramManagement && !isWholeSystem && !isStrategy">
            <div class="form-group col-md-9">
                <span class="select-label text-info-700 pb-1 mt-3 d-block">
                    Local Project Name<app-help-selector [selector]="1008"></app-help-selector>
                </span>
                <input formControlName="localName" type="text" class="form-control"
                    placeholder="Add a Local Project Name" tabindex="0">

                <div class="form-control-feedback"></div>

                <div class="hub-validation-error-message">
                    {{getValidationErrorMessages("localName")}}
                </div>

            </div>
        </div>

    </form>
</div>