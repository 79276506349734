<div class="ir-container">
    <div class="ikon">
        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 14V12H2V14H0ZM4 14V12H6V14H4ZM8 14V12H10V14H8ZM0 10V8H2V10H0ZM4 10V8H6V10H4ZM8 10V8H10V10H8ZM0
                6V4H2V6H0ZM4 6V4H6V6H4ZM8 6V4H10V6H8ZM0 2V0H2V2H0ZM4 2V0H6V2H4ZM8 2V0H10V2H8Z" fill="#0073A8" />
        </svg>
    </div>

    <div class="ir-content" [ngClass]="{'selected-dark': isSelected}">
        <div class="ir-description">
            <h5 class="text-teal mb-2 mt-1">
                {{intermediateResult.indicatorName}} <span class="text-muted">({{intermediateResult.unit}})</span>
            </h5>
    
            <span *ngIf="intermediateResult.isKeyResult" class="badge badge-flat border-primary text-primary-600 mb-2">
                Key Result
            </span>
    
            <div *ngIf="intermediateResult.parentIntermediateResultId">
                <i class="align-baseline mr-1 icon-tree7"></i>
                Linked to: {{intermediateResult.parentIntermediateResultProjectName}}
            </div>        
        </div>

        <div class="ir-actions">
            <mat-checkbox [(ngModel)]="intermediateResult.isChecked" color="primary"></mat-checkbox>
        </div>
    
    </div>

</div>
