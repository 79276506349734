import { Injectable, inject } from '@angular/core';
import { HttpService } from '../../../../../core/services/http.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { ProjectDonorKeywordModel } from '../../../../../../hub_schema/hubTypes';

@Injectable({
    providedIn: 'root'
})
export class KeywordTypeaheadService {
    private httpService: HttpService = inject(HttpService);

    public getKeywordSuggestions(prefix: string): Observable<string[]> {
        const url = environment.endpoints.base + '/additionalInformation/donorKeywords/' + prefix;
        return this.httpService.get<string[]>(url);
    }

}
