import { Component, OnInit, inject } from '@angular/core';
import { HubRecordEditorBase } from '../../_hub_record_editor_base';
import { ProjectDonorKeywordModel } from '../../../../../hub_schema/hubTypes';
import { FormControl } from '@angular/forms';
import { Observable, Subscription, catchError, debounceTime, distinctUntilChanged, map, of, switchMap } from 'rxjs';
import { KeywordTypeaheadService } from './services/keyword-typeahead.service';
import { escapeQuotesAndEnters } from '../../../../shared/helpers/escape';

@Component({
  selector: 'app-donor-keywords-edit',
  templateUrl: './donor-keywords-edit.component.html',
  styleUrls: ['./donor-keywords-edit.component.scss']
})
export class DonorKeywordsEditComponent extends HubRecordEditorBase implements OnInit {
    // dependencies
    private keywordTypeaheadService = inject(KeywordTypeaheadService);

    public keywordTypeahead: FormControl;
    public keywordSuggestions$: Observable<any>;

    public ngOnInit(): void {
        this.keywordTypeahead = new FormControl('');

        this.keywordSuggestions$ = this.keywordTypeahead.valueChanges.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            switchMap((term: string) => {
                if (term.length < 2) {
                    return of([]);
                }

                return this.keywordTypeaheadService.getKeywordSuggestions(term).pipe(map((results: string[]) => {
                    const exists = results.some(keyword => keyword.toLowerCase() === escapeQuotesAndEnters(term.toLowerCase()));

                    if (!exists) {
                        results.push(escapeQuotesAndEnters(term));
                    }

                    return results;                    
                }));
            })
        );
    }

    public displayKeywordFn(option?: any): string | undefined {
        if (option && option.keyword) {
            return option.keyword;
        }
        return undefined;
    }

    public keywordSelected(e) {
        this.addKeyword(e.option.value);
    }

    public get projectDonorKeywords(): ProjectDonorKeywordModel[] {
        return this.projectEditForm?.controls.projectDonorKeywords.value as ProjectDonorKeywordModel[];
    }

    public addKeyword(keyword: string): void {

        // don't add it if it already exists
        const existingKeyword = this.projectDonorKeywords.find(pdk => pdk.keyword.toLowerCase() === keyword.toLowerCase());

        if (!existingKeyword) {
            const newProjectDonorKeyword = {
                projectId: this.project.projectId,
                keyword: keyword
            };

            this.projectEditForm?.controls.projectDonorKeywords.value.push(newProjectDonorKeyword);
            this.projectEditForm?.controls.projectDonorKeywords.markAsDirty();
        }
    }

    public removeKeyword(keywordParam: any) {
        const indexToRemove = this.projectDonorKeywords.findIndex(pdk => pdk.keyword == keywordParam.keyword);
        this.projectDonorKeywords.splice(indexToRemove, 1);
        this.projectEditForm?.controls.projectDonorKeywords.markAsDirty();
    }
}
