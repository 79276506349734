import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectOutcomeModel } from '../../../../../hub_schema/hubTypes';

@Component({
  selector: 'app-custom-outcome-display',
  templateUrl: './custom-outcome-display.component.html',
  styleUrls: ['./custom-outcome-display.component.scss']
})
export class CustomOutcomeDisplayComponent {
    @Input()
    public projectOutcome: ProjectOutcomeModel;

    @Input() isSelected: boolean;

    @Output()
    public deleteOutcomeRequested: EventEmitter<void> = new EventEmitter();

    @Output()
    public editOutcomeRequested: EventEmitter<ProjectOutcomeModel> = new EventEmitter();

    public deleteOutcomeClicked(): void {
        this.deleteOutcomeRequested.emit();        
    }

    public editOutcomeClicked(): void {
        this.editOutcomeRequested.emit(this.projectOutcome);
    }
}
