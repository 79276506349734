import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-delete-confirmation-dialog',
    templateUrl: './delete-confirmation-dialog.component.html',
    styleUrls: ['./delete-confirmation-dialog.component.scss']
})
export class DeleteConfirmationDialogComponent implements OnInit {

    public warningMessage: string;
    public isBusy: boolean;
    public actionConfirmed: EventEmitter<void> = new EventEmitter();
    public actionCanceled: EventEmitter<void> = new EventEmitter();
    public confirmButtonText: string = 'Delete';
    public showSaveIcon: boolean = true;

    constructor(
        private dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) { }

    ngOnInit() {
        this.warningMessage = this.data.warningMessage;

        if (this.data.confirmButtonText) {
            this.confirmButtonText = this.data.confirmButtonText;
        }

        if (this.data.showSaveIcon != undefined) {
            this.showSaveIcon = this.data.showSaveIcon;
        }

        this.isBusy = false;
    }

    public confirmAction(): void {
        this.actionConfirmed.emit();
    }

    public close(): void {
        this.actionCanceled.emit();
        this.dialogRef.close();
    }
}
