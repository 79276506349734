import { Component, Input } from '@angular/core';
import { AncillaryTypeModel, ProjectModel } from '../../../../hub_schema/hubTypes';
import { HubRecordPresenterBase } from '../../../shared/components/_hub_record_presenter_base';

@Component({
  selector: 'app-summary-container',
  templateUrl: './summary-container.component.html',
  styleUrls: ['./summary-container.component.scss'],
})
export class SummaryContainerComponent extends HubRecordPresenterBase {

  @Input() brief: string;
  @Input() goal: string;
  @Input() theoryUrl: AncillaryTypeModel;
  @Input() localProjectName: string;

  public startDate: Date;
  // todo: change these names, get rid of 'set'.  Whoever wrote this didn't know how getters/setters work.
  @Input() set setStartDate(newVal) {
    this.startDate = newVal;
  }

  public plannedCompletionDate: Date;
  @Input() set setPlannedCompletionDate(newVal) {
    this.plannedCompletionDate = newVal;
  }

  public actualCompletionDate: Date;
  @Input() set setActualCompletionDate(newVal) {
    this.actualCompletionDate = newVal;
  }

  @Input() public override project: ProjectModel;
}
