import { Component, OnInit, inject } from '@angular/core';
import { HubRecordEditorBase } from '../_hub_record_editor_base';
import { ProjectStrategicPillarModel } from '../../../../hub_schema/hubTypes';
import { StrategicPillarEditService } from './services/strategic-pillar-edit.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateStrategicPillarDialogComponent } from './create-strategic-pillar-dialog/create-strategic-pillar-dialog.component';
import { DeleteConfirmationDialogComponent } from '../../../shared/components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { take } from 'rxjs';

@Component({
  selector: 'app-strategic-pillars-edit-container',
  templateUrl: './strategic-pillars-edit-container.component.html',
  styleUrls: ['./strategic-pillars-edit-container.component.scss']
})
export class StrategicPillarsEditContainerComponent extends HubRecordEditorBase implements OnInit {

    private strategicPillarEditService: StrategicPillarEditService = inject(StrategicPillarEditService);
    private dialogService: MatDialog = inject(MatDialog);

    public selectedStrategicPillar: ProjectStrategicPillarModel | undefined; 

    public ngOnInit(): void {
        if (this.project.projectStrategicPillars?.length) {
            this.selectedStrategicPillar = this.project.projectStrategicPillars[0];
        }
    }

    public selectStrategicPillar(pillar: ProjectStrategicPillarModel): void {
        this.selectedStrategicPillar = pillar;
    }

    public openStrategicPillarCreateDialog(): void {
       const dialogRef = this.dialogService.open(CreateStrategicPillarDialogComponent, {
            width: '800px'
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe((savedPillar) => {
            if (savedPillar) {
                this.project.projectStrategicPillars?.push(savedPillar);
                this.selectStrategicPillar(savedPillar);
            }
        });
    }

    public deleteStrategicPillarRequested(pillar: ProjectStrategicPillarModel) {
        const dialogRef = this.dialogService.open(DeleteConfirmationDialogComponent, {
            data: {
                warningMessage: 'You will not be able to undo deleting this Strategic Pillar'
            }
        });

        const deleteConfirmationDialog: DeleteConfirmationDialogComponent = dialogRef.componentInstance;

        deleteConfirmationDialog.actionConfirmed
            .pipe(take(1)).subscribe(() => {
                deleteConfirmationDialog.isBusy = true;

                this.strategicPillarEditService.deleteProjectStrategicPillar(pillar).subscribe({
                        next: () => {
                            const index = this.project.projectStrategicPillars!.findIndex(sp => sp === pillar);
                            this.project.projectStrategicPillars?.splice(index, 1);

                            if (this.project.projectStrategicPillars!.length) {
                                this.selectedStrategicPillar = this.project.projectStrategicPillars![0];
                            }
                            else {
                                this.selectedStrategicPillar = undefined;
                            }
                            dialogRef.close();
                        },
    
                        error: (err) => {
                            dialogRef.close();
                        }
    
                    }
                );

            });
    }

    public strategicPillarChanged(pillar: ProjectStrategicPillarModel): void {
        // replace in project
        const index = this.project.projectStrategicPillars?.findIndex(sp => sp.projectStrategicPillarId === pillar.projectStrategicPillarId)!;

        if (index >= 0) {
            this.project.projectStrategicPillars![index] = pillar;
        }        
    }
}
