<div class="view-section" id="intermediateResults" *ngIf="project">
    <h3 class="view-section-header">Intermediate Results <span class="text-muted">({{project.intermediateResults?.length}})</span></h3>
    
    <ul class="nav nav-tabs nav-tabs-bottom mb-1">
        <li class="nav-item">
            <a class="nav-link legitRipple" data-toggle="tab" (click)="setTabTo(tabs.active, $event)"
                [ngClass]="{active: activeTab === tabs.active}">Active Intermediate Results
                ({{activeIntermediateResults?.length}})
            </a>
        </li>

        <li class="nav-item">
            <a class="nav-link legitRipple" data-toggle="tab" (click)="setTabTo(tabs.archive, $event)"
                [ngClass]="{active: activeTab === tabs.archive}">
                Archived Intermediate Results ({{archivedIntermediateResults?.length}})
            </a>
        </li>
    </ul>    
    
    <div class="tab-content parent-min">

        <div class="tab-pane" [ngClass]="{active: activeTab === tabs.active, show: activeTab === tabs.active}" id="active">
            <div *ngFor="let intermediateResult of activeIntermediateResults; let i = index">
                <app-intermediate-result
                    [projectId]="project.projectId"
                    [intermediateResult] = "intermediateResult" 
                    (projectDataAreLoading)="projectDataAreLoading.emit($event)"
                    [isStrategy]="isStrategy" [irIndex]="i">
                </app-intermediate-result>
            </div>

            <!-- SHOW IF NO ACTIVE INTERMEDIATE RESULTS DEFINED -->
            <div class="record-description alert alert-info alert-styled-left shadow-0 mr-3" *ngIf="activeIntermediateResults?.length === 0">
                This record has not defined any Intermediate Results yet. Contact the Lead on the record to inquire
                about Intermediate Results.
            </div>

        </div>

        <div class="tab-pane" [ngClass]="{active: activeTab === tabs.archive, show: activeTab === tabs.archive}" id="archive">
            <div *ngFor="let intermediateResult of archivedIntermediateResults; let i = index">
                <app-intermediate-result
                    [projectId]="project.projectId"
                    [intermediateResult] = "intermediateResult" 
                    (projectDataAreLoading)="projectDataAreLoading.emit($event)"
                    [isStrategy]="isStrategy" [irIndex]="i">
                </app-intermediate-result>
            </div>

            <!-- SHOW IF NO ARCHIVED INTERMEDIATE RESULTS DEFINED -->
            <div class="record-description alert alert-info alert-styled-left shadow-0 mr-3" *ngIf="archivedIntermediateResults?.length === 0">
                This record has not defined any Intermediate Results yet. Contact the Lead on the record to inquire
                about Intermediate Results.
            </div>

        </div>

    </div>
</div>
