import { Component, Input, OnInit } from '@angular/core';
import { sortDate } from '../../../shared/helpers/date';
import { AuthService } from '../../../core/services/auth/auth.service';
import { AuditLogService } from './services/audit-log.service';
import { take } from 'rxjs/operators';
import { AuditLogModel } from '../../../../hub_schema/hubTypes';

@Component({
  selector: 'app-audit-log-container',
  templateUrl: './audit-log-container.component.html',
  styleUrls: ['./audit-log-container.component.scss']
})
export class AuditLogContainerComponent implements OnInit {
  auditLogs: object[];
  isUserGeneral = true;

  static groupBy(collection, callback) {
    const output = {};
    
    for (const collectionItem of collection) {
      const key = callback(collectionItem);
      if (!Array.isArray(output[key])) {
        output[key] = [];
      }
      output[key].push(collectionItem);
    }

    return output;
  }

  @Input() set projectId(id: number) {
    if (!id) {
      return;
    }

    this.auditLogService.getAuditLogs(id).pipe(take(1)).subscribe((auditLog: AuditLogModel[]) => {
      const grouped = AuditLogContainerComponent.groupBy(auditLog, (singleLog) => {
        const action = singleLog.action;
        const actionDateObj = new Date(singleLog.actionDate);
        const actionDateStr = `${actionDateObj.getMonth()}-${actionDateObj.getDate()}-${actionDateObj.getFullYear()}`;
        const userId = singleLog.user.userId;
        return JSON.stringify({ action, actionDateStr, userId });
      });
      let finalAuditLogs = Object.values(grouped).map((val) => val[0]);
      finalAuditLogs = sortDate(finalAuditLogs, 'actionDate', 'DESC');
      this.auditLogs = [ ...finalAuditLogs ];
      this.auditLogs = finalAuditLogs.map(log => {
        log.actionDateStr = log.actionDate.split('T')[0];
        return log;
    }
    )
    });
  }

  constructor(private auditLogService: AuditLogService, private auth: AuthService) {}

  static trackByFn(index) {
    return index;
  }

  ngOnInit() {
    this.isUserGeneral = this.auth.userIsGeneral();
  }
}
