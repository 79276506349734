export enum GoalColors {
    'goal_1000' = '#7249A7',
    'goal_1001' = '#EE8728',
    'goal_1002' = '#185876',
    'goal_1003' = '#11860F',
    'goal_1004' = '#59ADD0',
    'goal_1006' = '#C4117D'
}

export enum GoalSecondaryColors {
    'goal_1000' = '#AB92CA',
    'goal_1001' = '#F8CFA9',
    'goal_1002' = '#B8DCED',
    'goal_1003' = '#C3E0C3',
    'goal_1004' = '#C1DBE7',
    'goal_1006' = '#F0C3DE'
}
