export class Filter {
    public title: string;
    public numberOfHits: number;
    public isHidden: boolean;

    public isActive(): boolean {
        return true;
    }

    public stringify(): string {
        const state = {
            title: this.title,
            isActive: this.isActive
        };

        return JSON.stringify(state);
    }

    public makeActive(): void { }

    public makeInactive(): void { }
}
