import { Component } from '@angular/core';

@Component({
    selector: 'app-modal-spinner',
    templateUrl: './modal-spinner.component.html',
    styleUrls: ['./modal-spinner.component.scss']
})
export class ModalSpinnerComponent {
    public showSpinner = false;

    constructor() { }
}
